import { Button, Result, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { injectGlobalStyles, styles } from "./styles";
import { get } from "../../services/Apiclient";

const successIcon = `${process.env.PUBLIC_URL}/images/logo/success-icon.png`;

const CashierEInvoiceLayout4: React.FC = () => {
  const navigate = useNavigate();
  const { salesNo } = useParams<{ salesNo: string }>();
  const [invoiceId, setInvoiceId] = useState<string | null>(null);
  const [invoiceUuid, setInvoiceUuid] = useState<string | null>(null);

  useEffect(() => {
    injectGlobalStyles();

    // Retrieve invoiceId from LocalStorage (Stored in Layout3)
    const storedInvoiceId = localStorage.getItem("invoiceId");
    if (storedInvoiceId) {
      setInvoiceId(storedInvoiceId);
    } else {
      message.error("Invoice ID not found!");
    }

    // Retrieve UUID from LocalStorage (Stored in Layout3 when invoice was generated)
    const storedUuid = localStorage.getItem("invoiceUuid");
    if (storedUuid) {
      setInvoiceUuid(storedUuid);
    } else {
      message.error("Invoice UUID not found!");
    }
  }, []);

  const handleDownload = () => {
    if (!invoiceUuid) {
      message.error("No UUID found!");
      return;
    }

    message.success(`Your Invoice number successfully generated.`); // Show UUID instead of calling API
  };

  const handleBack = () => {
    localStorage.removeItem("cashierFormData");
    navigate(`/cashiereinvoiceslayout3/${salesNo}`);
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 0px",
        backgroundColor: "#fff",
        minHeight: "100vh",
      }}
    >
      <div>
        <div
          style={{
            ...styles.layoutContainer,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Result
            icon={
              <img
                src={successIcon}
                alt="Success Icon"
                style={{ width: "100px", height: "100px" }}
              />
            }
            title={
              <div
                style={{ fontSize: "20px", fontWeight: "bold", color: "#333" }}
              >
                Invoice Validated!
              </div>
            }
            subTitle={
              <>
                <div
                  style={{
                    fontSize: "13px",
                    color: "#777",
                    marginBottom: "20px",
                    lineHeight: "1.4",
                  }}
                >
                  Invoice has been validated. You can download it as a
                  statement.
                </div>
                <div
                  style={{
                    fontSize: "13px",
                    color: "black",
                    marginBottom: "20px",
                    lineHeight: "1.4",
                    fontWeight: "bold",
                  }}
                >
                  Invoice no: {invoiceUuid}
                </div>
              </>
            }
            extra={
              <>
                <Button
                  type="primary"
                  onClick={handleDownload}
                  style={{
                    width: "70%",
                    height: "40px",
                    padding: "10px",
                    backgroundColor: "white",
                    borderRadius: "5px",
                    color: "#55adff",
                    margin: 0,
                    border: "1px solid #55adff",
                    marginBottom: "10px",
                    boxShadow: "0 0 0 rgba(0, 0, 0, 0)",
                  }}
                >
                  DOWNLOAD
                </Button>
                <Button onClick={handleBack} style={styles.backButton}>
                  <span style={{ borderBottom: "1px solid #55adff" }}>
                    BACK
                  </span>
                </Button>
              </>
            }
          />
        </div>
      </div>
    </div>
  );
};

export default CashierEInvoiceLayout4;
