import React from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Divider,
  Descriptions,
  Tag,
  Badge,
  Button,
} from "antd";
import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  LeftOutlined,
} from "@ant-design/icons";

const reportFileIcon = `${process.env.PUBLIC_URL}/images/logo/report-file-icon.png`;
const { Title, Text } = Typography;

const NotificationDetails = () => {
  return (
    <div style={{ background: "#f2f5f5", minHeight: "100vh" }}>
      <div
        style={{
          background: "#f2f5f5",
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
          padding: "0px 0px 20px 0px",
          position: "relative",
        }}
      >
        {/* Header */}
        <div
          style={{
            background: "linear-gradient(135deg, #0d47a1, #1976d2)",
            color: "#fff",
            padding: "16px",
            height: "140px",
            position: "relative",
            borderRadius: "0px 0px 5% 5%",
          }}
        >
          <Row
            align="middle"
            justify="space-between"
            style={{ marginBottom: 16 }}
          >
            <Col span={5}>
              <Button
                type="text"
                icon={<LeftOutlined style={{ color: "#fff" }} />}
              />
            </Col>
            <Col span={19}>
              <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                <Title level={5} style={{ margin: 0, color: "#fff" }}>
                  Stock out Details
                </Title>
              </div>
            </Col>
          </Row>
          <Row
            justify="space-between"
            style={{
              marginTop: "50px",
              display: "flex",
              justifyContent: "space-between",
              width: "inherit",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
              <img
                src={reportFileIcon}
                alt="Ref icon"
                style={{
                  width: 27,
                  height: 31,
                }}
              />
              <Text style={{ color: "#fff" }}>Ref#: 202502150057</Text>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
              <ClockCircleOutlined />
              <Text style={{ color: "#fff" }}>15/02/2025</Text>
            </div>
          </Row>
        </div>

        {/* Qty and Amount */}
        <div
          style={{
            margin: "-40px 12px 0px 12px",
            position: "relative",
            zIndex: "10",
            borderRadius: "12px",
          }}
        >
          <Row
            style={{
              padding: "16px 0",
              background: "#fff",
              borderRadius: "12px 12px 0px 0px",
            }}
          >
            <Col span={12} style={{ borderRight: "1px solid #d2d2d2" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Text>Qty.</Text>
                <Title level={3} style={{ margin: 0 }}>
                  1
                </Title>
              </div>
            </Col>
            <Col span={12}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Text>Amount</Text>
                <Title level={3} style={{ margin: 0, color: "red" }}>
                  1688
                </Title>
              </div>
            </Col>
          </Row>
          <div
            style={{
              background: "#ebf5ff",
              padding: "16px",
              position: "relative",
              borderRadius: "0px 0px 12px 12px",
            }}
          >
            <Row>
              <Col span={12}>
                <Text
                  style={{ color: "#7c7f84", zIndex: 10, position: "relative" }}
                  className="normalbreak"
                >
                  Storage
                </Text>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Text
                  style={{ zIndex: 10, position: "relative", color: "#454747" }}
                  className="normalbreak"
                >
                  Central warehouse
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Text
                  style={{ color: "#7c7f84", zIndex: 10, position: "relative" }}
                  className="normalbreak"
                >
                  Stock out method
                </Text>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Text
                  style={{ zIndex: 10, position: "relative", color: "#454747" }}
                  className="normalbreak"
                >
                  Sales
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Text
                  style={{ color: "#7c7f84", zIndex: 10, position: "relative" }}
                  className="normalbreak"
                >
                  Method of collection
                </Text>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Text
                  style={{ zIndex: 10, position: "relative", color: "#454747" }}
                  className="normalbreak"
                >
                  Staff
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Text
                  style={{ color: "#7c7f84", zIndex: 10, position: "relative" }}
                  className="normalbreak"
                >
                  Staff
                </Text>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Text
                  style={{ zIndex: 10, position: "relative", color: "#454747" }}
                  className="normalbreak"
                >
                  ANNIE LEW TZE YIN
                </Text>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Text
                  style={{ color: "#7c7f84", zIndex: 10, position: "relative" }}
                  className="normalbreak"
                >
                  Note
                </Text>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Text
                  style={{ zIndex: 10, position: "relative", color: "#454747" }}
                  className="normalbreak"
                >
                  Automatically stock out after product sales
                </Text>
              </Col>
            </Row>

            {/* Approved Stamp */}
            <div
              style={{
                position: "absolute",
                top: "5%",
                left: "10%",
                zIndex: 1,
              }}
            >
              <svg width="250" height="150" viewBox="0 0 250 150">
                {/* Outer ellipse */}
                <ellipse
                  cx="125"
                  cy="75"
                  rx="40"
                  ry="20"
                  stroke="#dbf0f5"
                  strokeWidth="2"
                  fill="none"
                  transform="rotate(-10 125 75)"
                  opacity="0.5"
                />

                {/* Inner ellipse */}
                <ellipse
                  cx="125"
                  cy="75"
                  rx="60"
                  ry="30"
                  stroke="#bbe7e0"
                  strokeWidth="4"
                  fill="none"
                  transform="rotate(-10 125 75)"
                  opacity="0.5"
                />

                {/* Background Rectangle behind the text */}
                <rect
                  x="50" // Positioning the rectangle (adjust as needed)
                  y="65"
                  width="150"
                  height="20"
                  fill="#ebf5ff"
                  transform="rotate(-15 125 75)"
                  opacity="0.8"
                  rx="5" // rounded corners (optional)
                />

                {/* Approved Text */}
                <text
                  x="125"
                  y="85"
                  textAnchor="middle"
                  fontFamily="cursive"
                  fontSize="32"
                  fontWeight="bold"
                  fill="#6acdae"
                  transform="rotate(-15 125 75)"
                  opacity="0.8"
                >
                  Approved
                </text>
              </svg>
            </div>
          </div>
          <div
            style={{
              padding: "16px",
              marginTop: "10px",
              background: "#fff",
              borderRadius: "12px",
            }}
          >
            <div>
              <Row justify="space-between" align="middle">
                <Col span={12}>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "flex-start",
                    }}
                  >
                    {/* Number Box */}
                    <div
                      style={{
                        backgroundColor: "#2d3434",
                        color: "#dc9864",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "20px",
                        height: "20px",
                        fontSize: "12px",
                        fontWeight: "bold",
                        borderRadius: "4px",
                        flexShrink: 0, // don't let it shrink when text is long
                        marginTop: "4px", // aligns it vertically with first line of text
                      }}
                    >
                      1
                    </div>

                    {/* Text Area */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Text
                        strong
                        style={{ lineHeight: 1.5, color: "#2c2c2c" }}
                        className="normalbreak"
                      >
                        Luxurious Pigmentation Correction 5 Set
                      </Text>
                    </div>
                  </div>
                </Col>
                <Col span={4} style={{ textAlign: "center" }}>
                  <Text strong style={{ color: "#dedee1" }}>
                    X 1
                  </Text>
                </Col>
                <Col span={8} style={{ textAlign: "right" }}>
                  <Text strong style={{ color: "#2c2c2c" }}>
                    RM1,688
                  </Text>
                </Col>
              </Row>
              <Divider style={{ margin: "10px 0px" }} />

              <Row>
                <Col span={12}>
                  <Text
                    style={{
                      color: "#7c7f84",
                      zIndex: 10,
                      position: "relative",
                    }}
                    className="normalbreak"
                  >
                    Stock out unit
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Text
                    style={{
                      zIndex: 10,
                      position: "relative",
                      color: "#454747",
                    }}
                    className="normalbreak"
                  >
                    Box
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Text
                    style={{
                      color: "#7c7f84",
                      zIndex: 10,
                      position: "relative",
                    }}
                    className="normalbreak"
                  >
                    Stock out unit price
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Text
                    style={{
                      zIndex: 10,
                      position: "relative",
                      color: "#454747",
                    }}
                    className="normalbreak"
                  >
                    1688
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Text
                    style={{
                      color: "#7c7f84",
                      zIndex: 10,
                      position: "relative",
                    }}
                    className="normalbreak"
                  >
                    Inventory before stock out
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Text
                    style={{
                      zIndex: 10,
                      position: "relative",
                      color: "#454747",
                    }}
                    className="normalbreak"
                  >
                    2Box(2Box)
                  </Text>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Text
                    style={{
                      color: "#7c7f84",
                      zIndex: 10,
                      position: "relative",
                    }}
                    className="normalbreak"
                  >
                    Supplier
                  </Text>
                </Col>
                <Col span={12} style={{ textAlign: "right" }}>
                  <Text
                    style={{
                      zIndex: 10,
                      position: "relative",
                      color: "#454747",
                    }}
                    className="normalbreak"
                  >
                    Beahub
                  </Text>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/* Product Details */}
      </div>
    </div>
  );
};

export default NotificationDetails;
