import { Button, Col, Input, message, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "../../../services/Apiclient";
import Datatable from "../../Datatable";
// import { currencySymbol } from "../../../constants/currencySymbol";
import { useCurrency } from "../../../contexts/CurrencyContext";

const { Option } = Select;

interface SelectProductItemModalProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  onSave: (selectedData: any[]) => void;
  selectedServices: any[];
  footer?: React.ReactNode;
}

const SelectProductItemModal: React.FC<SelectProductItemModalProps> = ({
  title,
  visible,
  onCancel,
  onSave,
  selectedServices,
  footer,
}) => {
  const { currencySymbol } = useCurrency();
  const navigate = useNavigate();
  const [categoryData, setCategoryData] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [suppliercode, setSuppliercode] = useState("");
  const [categorycode, setCategorycode] = useState("");
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  // Sample data for the table
  // Correct typing for selectedRowKeys
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const handleCategoryChange = (value: string) => {
    setCategorycode(value);
  };

  const handleSave = () => {
    if (selectedRowKeys.length > 0) {
      // Get the selected data based on selectedRowKeys
      const selectedData = serviceData.filter((item: any) =>
        selectedRowKeys.includes(item.key)
      );

      // Call the onSave function and pass the selected data to the parent
      onSave(selectedData);

      // Close the modal after saving
      onCancel();
    } else {
      onSave([]);

      // Close the modal after saving
      onCancel();
    }
  };

  const getProductCategoryDropdown = async () => {
    try {
      let url = `/category/dropdown/type/${localStorage.getItem(
        "outlet_id"
      )}/Product`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: "null",
            name: "All Categories",
          });
          setCategoryData(data);
          setCategorycode("null");
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getSupplierDropdown = async () => {
    try {
      let url = `/supplier/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: "null",
            name: "All Supplier",
          });
          setSupplierData(data);
          setSuppliercode("null");
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getProductdata = async () => {
    try {
      // let url = `/product/${categorycode}/${suppliercode}/${currentPage}/${pageSize}/created_at/ASC/${searchText}`;
      let searchtext = searchText == "" ? null : searchText;
      //let url = `/service/list/${currentPage}/${pageSize}/servicename/ASC`;
      // let url = `/service/${categorycode}/null/null/servicename/DESC/${searchText}`;
      let url = `/product/${categorycode == "" ? null : categorycode}/${
        suppliercode == "" ? null : suppliercode
      }/${localStorage.getItem(
        "outlet_id"
      )}/${currentPage}/${pageSize}/created_at/DESC/${
        searchText ? searchText : "null"
      }`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.products.map(
            (service: any, index: number) => ({
              ...service,
              key: service.productid || index,
            })
          );
          setServiceData(data);
          setTotalRecords(response.data.totalRecords);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  useEffect(() => {
    if (visible) {
      getProductdata();
    }
  }, [currentPage, pageSize, visible, categorycode, searchText]);

  useEffect(() => {
    if (visible) {
      getProductCategoryDropdown();
      getSupplierDropdown();
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      if (selectedServices && selectedServices.length > 0) {
        console.log(selectedServices);
        const keys = selectedServices.map((service) => service.key);
        setSelectedRowKeys(keys);
      } else {
        setSelectedRowKeys([]);
      }
    }
  }, [selectedServices, visible]);

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      footer={footer}
      width={800}
      style={{
        top: "10px",
      }}
      bodyStyle={{
        height: "calc(100vh - 110px)",
      }}
    >
      {/* Filters and Search */}
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Select
            placeholder="Please select category"
            value={categorycode}
            onChange={(value) => handleCategoryChange(value)}
            style={{ width: "100%" }}
            dropdownStyle={{ textAlign: "center" }}
          >
            {categoryData.length > 0 &&
              categoryData.map((cate: any) => (
                <Option value={cate.id} key={cate.id}>
                  {cate.name}
                </Option>
              ))}
          </Select>
        </Col>
        <Col span={8}>
          <Select
            placeholder="Please select supplier"
            value={suppliercode}
            onChange={(value) => handleCategoryChange(value)}
            style={{ width: "100%" }}
            dropdownStyle={{ textAlign: "center" }}
          >
            {supplierData.length > 0 &&
              supplierData.map((cate: any) => (
                <Option value={cate.id} key={cate.id}>
                  {cate.name}
                </Option>
              ))}
          </Select>
        </Col>

        <Col span={8}>
          <Input
            placeholder="Select by Product name / code"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)} // Update search text on input change
            style={{ width: "100%" }}
            allowClear
          />
        </Col>
      </Row>
      <Datatable<any>
        columns={[
          { title: "Product Code", dataIndex: "productcode" },
          { title: "Product Name", dataIndex: "productname" },
          { title: "Category", dataIndex: "categoryname" },
          {
            title: "Price",
            dataIndex: "price",
            render: (sellprice: number) =>
              `${currencySymbol.CURRENCY_SYMBOL}${sellprice}`,
          },
          { title: "Unit", dataIndex: "unit" },
          { title: "Supplier", dataIndex: "supplier" },
        ]}
        dataSource={serviceData}
        currentPage={currentPage}
        pageSize={pageSize}
        totalRecords={totalRecords}
        onPageChange={handlePageChange}
        // scroll={{ y: "calc(100vh - 290px)" }}
        dynamicTableHeight="calc(100vh - 260px)"
        showCheckbox
        rowSelection={rowSelection}
        showPagination={true}
      />
      <div
        style={{
          marginTop: 16,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <strong>Selected:</strong> {selectedRowKeys.length} items
        </div>
        <div>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default SelectProductItemModal;
