import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, Row, Select, Tag } from "antd";
import { Content } from "antd/es/layout/layout";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import React, { useEffect, useState } from "react";
import { Pie } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import Datatable from "../../components/Datatable";
import AttendanceReviewDetailsModal from "../../components/model/staff/attendance/AttendanceReviewDetailsModal";
import { get } from "../../services/Apiclient";

ChartJS.register(ArcElement, Tooltip, Legend);

const AttendanceReview: React.FC = () => {
  const navigate = useNavigate();
  const outletId = localStorage.getItem("outlet_id");
  const [servicesData, setServicesData] = useState<any[]>([]);
  const [reviewData, setReviewData] = useState<any[]>([]);
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState<any>(null);

  const getServicesData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/service/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setServicesData(response.data.data);
        setSelectedService(response.data.data[0]?.id || null);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getReviewData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/attendance/${outletId}`);
      if (response?.data?.success && response.data.data) {
        console.log("response", response.data);
        // Assuming the API returns an array of objects with keys matching the columns
        const formattedData = response.data.data.map((item: any) => ({
          ...item,
          key: item.id || item.staffid, // Ensure each row has a unique key
        }));
        setReviewData(formattedData);
      }
    } catch (error) {
      console.error("Error fetching reviews:", error);
    }
  };

  useEffect(() => {
    getServicesData();
    getReviewData();
  }, []);

  const columns = [
    { title: "Staff", dataIndex: "staffname", key: "staffname", width: 150 },
    {
      title: "Shift",
      dataIndex: "shiftstarttime",
      key: "shift",
      width: 200,
      render: (_: any, record: any) => (
        <span>
          {record.shiftstarttime} - {record.shiftendtime}
        </span>
      ),
    },
    {
      title: "Attendance",
      dataIndex: "attendance",
      key: "attendance",
      width: 150,
      render: (text: string) => (
        <span style={{ color: "#1890ff", cursor: "pointer" }}>
          {text || "Missing record"}
        </span>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (_: any, record: any) => (
        <Button
          type="link"
          onClick={() => {
            setSelectedStaff(record);
            setIsModalVisible(true);
          }}
        >
          View
        </Button>
      ),
    },
  ];

  // Calculate summary data dynamically from reviewData instead of static tableData
  const summaryData = {
    normal: reviewData.filter((item) => item.attendance === "Normal").length,
    timeOff: reviewData.filter((item) => item.attendance === "Time off").length,
    lateArrival: reviewData.filter((item) => item.attendance === "Late arrival")
      .length,
    earlyDeparture: reviewData.filter(
      (item) => item.attendance === "Early departure"
    ).length,
    onSiteService: reviewData.filter(
      (item) => item.attendance === "On-site service"
    ).length,
    absenteeism: reviewData.filter((item) => item.attendance === "Absenteeism")
      .length,
    missingRecord: reviewData.filter(
      (item) => item.attendance === "Missing record" || !item.attendance
    ).length,
  };

  const pieChartData = {
    labels: [
      "Normal",
      "Time off",
      "Late arrival",
      "Early departure",
      "On-site service",
      "Absenteeism",
      "Missing record",
    ],
    datasets: [
      {
        data: Object.values(summaryData),
        backgroundColor: [
          "#2e55ef",
          "#6e86dc",
          "#ff8335",
          "#ffc400",
          "#4fc5ff",
          "#d8446e",
          "#c4d8d6",
        ],
        borderWidth: 0,
      },
    ],
  };

  return (
    <Content style={{ padding: 24, background: "#f5f7fb" }}>
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              style={{
                marginRight: 12,
                color: "#325DF2",
                fontSize: 18,
                cursor: "pointer",
              }}
            />
            <h2 style={{ margin: 0, fontSize: 20, fontWeight: 600 }}>
              Attendance Review
            </h2>
          </div>
        </Col>
        <Col>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              // alignItems: "center",
              gap: 12,
            }}
          >
            <Select
              value={selectedService}
              style={{ width: 200, height: 40 }}
              onChange={setSelectedService}
            >
              {servicesData.map((service) => (
                <Select.Option key={service.id} value={service.id}>
                  {service.servicename}
                </Select.Option>
              ))}
            </Select>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "0 10px",
                height: 44,
                border: "1px solid #d9d9d9",
                borderRadius: 4,
                background: "white",
              }}
            >
              <Button
                icon={<ArrowLeftOutlined />}
                style={{ border: "none", color: "#2e56f2" }}
              />
              <span style={{ fontSize: 16, fontWeight: 500 }}>
                Friday 14 Feb, 2025
              </span>
              <Button
                icon={<ArrowRightOutlined />}
                style={{ border: "none", color: "#2e56f2" }}
              />
            </div>
          </div>
        </Col>
      </Row>

      <Content
        style={{
          background: "#f6f7f9",
          borderRadius: 12,
          padding: "24px",
          border: "1px solid #e0e7fe",
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Datatable
              columns={columns}
              dataSource={reviewData}
              showPagination={false}
            />
          </Col>
          <Col span={12}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  width: 300,
                  height: 300,
                  marginBottom: 40,
                  marginTop: 30,
                }}
              >
                <Pie
                  data={pieChartData}
                  options={{
                    plugins: {
                      legend: { display: false },
                      tooltip: { enabled: false },
                    },
                    maintainAspectRatio: false,
                  }}
                />
              </div>
              <div>
                <div style={{ display: "flex", gap: 10, marginBottom: 8 }}>
                  <div style={{ display: "flex", gap: 6 }}>
                    <Tag color="#2e55ef" style={{ margin: 0 }} />
                    Normal: {summaryData.normal}
                  </div>
                  <div style={{ display: "flex", gap: 6 }}>
                    <Tag color="#6e86dc" style={{ margin: 0 }} />
                    Time off: {summaryData.timeOff}
                  </div>
                </div>
                <div style={{ display: "flex", gap: 10, marginBottom: 8 }}>
                  <div style={{ display: "flex", gap: 6 }}>
                    <Tag color="#ff8335" style={{ margin: 0 }} />
                    Late arrival: {summaryData.lateArrival}
                  </div>
                  <div style={{ display: "flex", gap: 6 }}>
                    <Tag color="#ffc400" style={{ margin: 0 }} />
                    Early departure: {summaryData.earlyDeparture}
                  </div>
                </div>
                <div style={{ display: "flex", gap: 10, marginBottom: 8 }}>
                  <div style={{ display: "flex", gap: 6 }}>
                    <Tag color="#4fc5ff" style={{ margin: 0 }} />
                    On-site service: {summaryData.onSiteService}
                  </div>
                  <div style={{ display: "flex", gap: 6 }}>
                    <Tag color="#d8446e" style={{ margin: 0 }} />
                    Absenteeism: {summaryData.absenteeism}
                  </div>
                </div>
                <div style={{ display: "flex", gap: 10 }}>
                  <div style={{ display: "flex", gap: 6 }}>
                    <Tag color="#c4d8d6" style={{ margin: 0 }} />
                    Missing record: {summaryData.missingRecord}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Content>

      <AttendanceReviewDetailsModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        staffData={selectedStaff}
      />
    </Content>
  );
};

export default AttendanceReview;
