import { Button, Col, Divider, Modal, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { get } from "../../../../services/Apiclient";
import AttendanceAddTimeOff from "./AttendanceAddTimeOff";
import AttendanceReviewEditModal from "./AttendanceReviewEditModal"; // Import the Edit modal
const Text = Typography;

interface AttendanceModalProps {
  visible: boolean;
  onCancel: () => void;
  staffData: any;
}

const AttendanceReviewDetailsModal: React.FC<AttendanceModalProps> = ({
  visible,
  onCancel,
  staffData,
}) => {
  const outletId = localStorage.getItem("outlet_id");
  const [reviewData, setReviewData] = useState<any>(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isTimeOffModalVisible, setIsTimeOffModalVisible] = useState(false);

  const getReviewByIdData = async () => {
    if (!outletId || !staffData) return;
    try {
      const { id, shiftid, staffid } = staffData; // Adjust field names based on your API response
      const response = await get(
        `/attendance/attendancedetails/${id}/${shiftid}/${staffid}`
      );
      if (response?.data?.success && response.data.data) {
        setReviewData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching selected review:", error);
      setReviewData(null); // Reset on error
    }
  };

  useEffect(() => {
    if (visible && staffData) {
      getReviewByIdData();
    }
  }, [visible, staffData]);

  // Handle opening the Edit modal
  const handleEditClick = () => {
    setIsEditModalVisible(true);
  };

  // Handle closing the Edit modal
  const handleEditModalClose = () => {
    setIsEditModalVisible(false);
  };

  // Handle opening the Time Off modal
  const handleTimeOffClick = () => {
    setIsTimeOffModalVisible(true);
  };

  // Handle closing the Time Off modal
  const handleTimeOffModalClose = () => {
    setIsTimeOffModalVisible(false);
  };

  const handleSaveSuccess = () => {
    getReviewByIdData(); // Refresh data when save is successful
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        bodyStyle={{ height: "450px", overflowY: "auto" }}
        width={400}
        centered
        footer={[
          <>
            <Divider />
            <Button key="request" type="default" onClick={handleTimeOffClick}>
              Request time off
            </Button>
            <Button key="edit" type="primary" onClick={handleEditClick}>
              Edit
            </Button>
          </>,
        ]}
      >
        <div>
          <h3 style={{ margin: 0, fontSize: 16, fontWeight: 600 }}>
            Attendance details
          </h3>
          <div
            style={{
              marginTop: 16,
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#24bee2",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "5px",
              }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <Text style={{ color: "#a7e7ff" }}>Staff: </Text>
                <Text style={{ color: "white" }}>{reviewData?.staff_name}</Text>
              </div>
              <Text style={{ color: "white" }}>
                {reviewData?.starttime} - {reviewData?.endtime}
              </Text>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", gap: "10px" }}>
                <Text style={{ color: "#a7e7ff" }}>Date: </Text>
                <Text style={{ color: "white" }}>{reviewData?.date}</Text>
              </div>
              <Text style={{ color: "#a7e7ff" }}>
                {reviewData?.starttime || "-"} - {reviewData?.endtime || "-"}
              </Text>
            </div>
          </div>
          <div
            style={{
              color: "#737373",
              marginTop: "30px",
              display: "grid",
              gap: "10px",
            }}
          >
            {/* Clock-in */}
            <Row align="middle">
              <Col span={8}>
                <Text>Clock-in:</Text>
              </Col>
              <Col span={16}>
                <Text style={{ textAlign: "left", fontWeight: 500 }}>
                  {reviewData?.clockin}
                </Text>
              </Col>
            </Row>

            {/* Clock-out */}
            <Row align="middle">
              <Col span={8}>
                <Text>Clock-out:</Text>
              </Col>
              <Col span={16}>
                <Text style={{ textAlign: "left", fontWeight: 500 }}>
                  {reviewData?.clockout}
                </Text>
              </Col>
            </Row>

            {/* Note */}
            <Row align="middle">
              <Col span={8}>
                <Text>Note:</Text>
              </Col>
              <Col span={16}>
                <Text style={{ textAlign: "left", fontWeight: 500 }}>
                  {reviewData?.note}
                </Text>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>

      {/* Render the Edit Modal */}
      <AttendanceReviewEditModal
        visible={isEditModalVisible}
        onCancel={handleEditModalClose}
        staffData={staffData}
        onSaveSuccess={handleSaveSuccess}
      />

      {/* Render the Time Off Modal */}
      <AttendanceAddTimeOff
        visible={isTimeOffModalVisible}
        onCancel={handleTimeOffModalClose}
        staffData={staffData}
        onSaveSuccess={handleSaveSuccess}
      />
    </>
  );
};

export default AttendanceReviewDetailsModal;
