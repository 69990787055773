import { ArrowRightOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Card } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../headerfooter/Header";

const AppointmentDetail: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        title="Appointment Details"
        onBack={() => navigate(`/app/staff/booking/list`)}
        backgroundColor="#fff"
      />
      <div style={{ flex: 1, marginTop: "80px" }}>
        <Card
          style={{
            borderRadius: "12px",
            backgroundColor: "#FFD59E",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src="https://via.placeholder.com/50"
                alt="User Avatar"
                style={{ borderRadius: "50%", marginRight: "12px" }}
              />
              <div>
                <strong style={{ fontSize: "16px" }}>LIZA</strong>
                <div style={{ fontSize: "14px", color: "#555" }}>
                  <PhoneOutlined style={{ marginRight: "6px" }} /> 01••••8106
                </div>
              </div>
            </div>
            <ArrowRightOutlined style={{ fontSize: "18px", color: "#555" }} />
          </div>
        </Card>

        <Card style={{ borderRadius: "12px", marginTop: "16px" }}>
          <p style={{ fontWeight: "bold", color: "green" }}>
            Individual appointment
          </p>
          <p style={{ fontSize: "16px", fontWeight: "bold" }}>
            NEW SET EYELASH EXTENSION
          </p>
          <p style={{ color: "#555" }}>21/02/2025 10:00 - 11:30</p>
          <p style={{ fontWeight: "bold" }}>FANNY LEE</p>
          <p style={{ color: "red", fontWeight: "bold", fontSize: "16px" }}>
            RM 198
          </p>
        </Card>

        <Card style={{ borderRadius: "12px", marginTop: "16px" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <strong>Store note</strong>
            <a href="#" style={{ color: "blue" }}>
              Edit
            </a>
          </div>
          <p style={{ color: "#888" }}>-</p>
        </Card>

        <Button
          type="primary"
          danger
          block
          style={{ marginTop: "16px", height: "45px", fontSize: "16px" }}
        >
          Cancel arrival
        </Button>
      </div>
    </div>
  );
};

export default AppointmentDetail;
