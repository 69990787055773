import {
  ArrowLeftOutlined,
  LogoutOutlined,
  SettingFilled,
  ShopFilled,
  UserOutlined,
} from "@ant-design/icons";
import { Avatar, Dropdown, Layout, Menu, Space } from "antd";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getPagePermissions } from "./permissionsHelper";

const { Header, Content } = Layout;

const HeaderOnlyLayout: React.FC = () => {
  let userRole: any = null;
  try {
    const token: any = localStorage.getItem("token");
    if (token) {
      const decodedToken: any = jwtDecode(token);
      userRole = decodedToken?.tokendata?.role;
    }
  } catch (error) {
    console.error("Token decoding failed:", error);
    localStorage.setItem("token", "");
  }

  const navigate = useNavigate();

  const [hasOnlyMyInvoicePage, sethasOnlyMyInvoicePage] = useState(false);

  const handleLogout = () => {
    let merchantid = localStorage.getItem("merchantid");
    let userlogin = localStorage.getItem("outletlogin");
    localStorage.removeItem("customer_id");
    localStorage.removeItem("store_name");
    localStorage.removeItem("token");
    localStorage.removeItem("timeout");
    localStorage.removeItem("enableonlineshop");
    localStorage.removeItem("enableretailshop");
    localStorage.removeItem("einvoiceenable");
    localStorage.removeItem("staffid");
    localStorage.removeItem("permissions");
    localStorage.removeItem("userRole");
    localStorage.removeItem("currencySymbol");
    if (userlogin === "true" && merchantid !== "") {
      navigate(`/login?id=${merchantid}`);
    } else {
      navigate("/login");
    }
  };

  useEffect(() => {
    const pagepermissions = getPagePermissions();
    console.log(pagepermissions, "pagepermissions");
    sethasOnlyMyInvoicePage(
      pagepermissions.myinvoicepage == true &&
        pagepermissions.bookingpage == null &&
        pagepermissions.customerpage == null &&
        pagepermissions.einvoicepage == null &&
        pagepermissions.homepage == null &&
        pagepermissions.inventorypage == null &&
        pagepermissions.noticeboardpage == null &&
        pagepermissions.onlinestorepage == null &&
        pagepermissions.reportpage == null &&
        pagepermissions.salespage == null
    );
  });

  const handleSettings = () => {
    navigate("/settings");
  };

  const handleOutlet = () => {
    navigate("/outlet");
  };

  const userMenu = (
    <Menu>
      {/* {(userRole === "Outletadmin" || userRole === "Merchant") && (
        <Menu.Item key="1" onClick={handleOutlet}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <ShopFilled /> Outlet
          </div>
        </Menu.Item>
      )}
      {(userRole === "Outletadmin" || userRole === "Merchant") && (
        <Menu.Item key="2" onClick={handleSettings}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <SettingFilled /> Settings
          </div>
        </Menu.Item>
      )} */}
      <Menu.Item key="3" onClick={handleLogout}>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <LogoutOutlined /> Logout
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Header
        style={{
          position: "fixed",
          width: "100%",
          padding: "0 20px",
          backgroundColor: "#fff",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <div>
          {!hasOnlyMyInvoicePage && (
            <span>
              <ArrowLeftOutlined
                onClick={() => navigate(-1)}
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
              />
            </span>
          )}
          <span
            style={{
              fontSize: "14px",
              lineHeight: "32px",
              fontWeight: "700",
              color: "#333333",
            }}
          >
            {localStorage.getItem("store_name")}
          </span>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <Dropdown
            overlay={userMenu}
            placement="bottomCenter"
            trigger={["click"]}
          >
            <Space style={{ cursor: "pointer" }}>
              <Avatar icon={<UserOutlined />} />
              <span>{userRole}</span>
            </Space>
          </Dropdown>
        </div>
      </Header>
      <Content
        style={{
          marginTop: 64,
          padding: 16,
          overflowY: "auto",
          height: "calc(100vh - 64px)",
        }}
      >
        <Outlet />
      </Content>
    </Layout>
  );
};

export default HeaderOnlyLayout;
