import { Button, DatePicker, Divider, Form, Modal, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";

interface AttendanceTimeOffModalProps {
  visible: boolean;
  onCancel: () => void;
  staffData: any;
  onSaveSuccess?: () => void; // Callback to refresh data in parent modal
}

const AttendanceAddTimeOff: React.FC<AttendanceTimeOffModalProps> = ({
  visible,
  onCancel,
  staffData,
  onSaveSuccess,
}) => {
  const [form] = Form.useForm();

  const timeofftype = [
    { value: "Sick Leave", label: "Sick Leave" },
    { value: "Personal Leave", label: "Personal Leave" },
    { value: "Compassionate Leave", label: "Compassionate Leave" },
    { value: "Maternity/Paternity Leave", label: "Maternity/Paternity Leave" },
    { value: "Unpaid Leave", label: "Unpaid Leave" },
    { value: "Work From Home", label: "Work From Home" },
    { value: "Bereavement Leave", label: "Bereavement Leave" },
    { value: "Half Day Leave", label: "Half Day Leave" },
    { value: "Other", label: "Other" },
  ];

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      bodyStyle={{ height: "450px", overflowY: "auto" }}
      width={400}
      centered
      footer={[
        <>
          <Divider />
          <Button key="request" type="default" onClick={onCancel}>
            Cancel
          </Button>
          <Button key="save" type="primary" onClick={() => form.submit()}>
            Save
          </Button>
        </>,
      ]}
    >
      <div>
        <h3 style={{ margin: 0, fontSize: 16, fontWeight: 600 }}>
          Add time off
        </h3>
        <Form
          style={{ marginTop: 10 }}
          form={form}
          layout="vertical"
          // onFinish={onFinish}
        >
          <Form.Item
            label="Start time"
            name="starttime"
            rules={[{ required: true, message: "Please select start time" }]}
          >
            <DatePicker
              style={{ width: "100%", height: "46px" }}
              placeholder="Select date and time"
              showTime={{ format: "HH:mm" }}
              format="DD-MM-YYYY HH:mm"
            />
          </Form.Item>
          <Form.Item
            label="End time"
            name="endtime"
            rules={[{ required: true, message: "Please select end time" }]}
          >
            <DatePicker
              style={{ width: "100%", height: "46px" }}
              placeholder="Select date and time"
              showTime={{ format: "HH:mm" }}
              format="DD-MM-YYYY HH:mm"
            />
          </Form.Item>
          <Form.Item
            name="timeofftype"
            label="Time off type"
            rules={[
              { required: true, message: "Please select a time off type" },
            ]}
          >
            <Select
              style={{ width: "100%", height: "46px" }}
              placeholder="Please select"
            >
              {timeofftype.map((type) => (
                <Select.Option key={type.value} value={type.value}>
                  {type.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Reason"
            name="note"
            rules={[{ required: true, message: "Please enter reason" }]}
          >
            <TextArea rows={3} placeholder="Please enter" />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default AttendanceAddTimeOff;
