import React, { useEffect, useState } from "react";
import { Modal, Button, Select, Input } from "antd";
import "./ConfirmDeleteModal.css";
import { get } from "../../services/Apiclient";

const { Option } = Select;

interface GenerateEInvoiceModalProps {
  visible: boolean;
  onCancel: () => void;
  onConfirm: (tinNumber: string) => void; // Pass TIN number
  message: string;
  header?: string;
}

const GenerateEInvoiceModal: React.FC<GenerateEInvoiceModalProps> = ({
  visible,
  onCancel,
  onConfirm,
  message,
  header = "Prompt",
}) => {
  const [selectedTin, setSelectedTin] = useState<string | undefined>(undefined);
  const [manualTin, setManualTin] = useState<string | undefined>(undefined);
  const [tinOptions, setTinOptions] = useState<string[]>([]); // Store TIN options from API
  const [defaultTin, setDefaultTin] = useState<string | undefined>(undefined); // Default TIN from API

  useEffect(() => {
    const fetchMerchantData = async () => {
      const merchantId = localStorage.getItem("merchantid");
      if (merchantId) {
        try {
          const response = await get(`/merchant/${merchantId}`);
          if (response?.data?.data?.tin) {
            const tinFromApi = response.data.data.tin;
            setDefaultTin(tinFromApi); // Store default TIN
            setManualTin(tinFromApi); // Set input field with default TIN
          }
        } catch (error) {
          console.error("Error fetching merchant data:", error);
        }
      }
    };

    fetchMerchantData();
  }, [visible]); // Refetch when modal opens

  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      width={370}
      bodyStyle={{ textAlign: "center" }}
      maskClosable={false}
      closable={false}
      className="delete-modal"
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          padding: "20px",
        }}
      >
        <h3 className="modal-title">{header}</h3>
        <p className="modal-message">{message}</p>

        {/* Select Dropdown */}
        <Select
          style={{
            width: "100%",
            marginTop: 10,
            textAlign: "start",
            height: 46,
            marginBottom: "5px",
          }}
          placeholder="Select TIN number"
          value={selectedTin}
          onChange={(value) => {
            setSelectedTin(value);
            setManualTin(undefined);
          }}
          allowClear
          disabled={manualTin ? true : false}
        >
          <Option value="E100000000010">E100000000010</Option>
          <Option value="E100000000020">E100000000020</Option>
          <Option value="E100000000030">E100000000030</Option>
          <Option value="E100000000040">E100000000040</Option>
        </Select>

        {/* Input Field */}
        <Input
          style={{ width: "100%", marginTop: 10, height: 46 }}
          placeholder="Enter TIN number"
          value={manualTin}
          disabled={selectedTin ? true : false}
          onChange={(e) => {
            setManualTin(e.target.value);
            setSelectedTin(undefined);
          }}
          allowClear
        />

        {/* Remove Default TIN Button */}
        {/* {defaultTin && (
          <Button
            type="link"
            onClick={() => {
              setManualTin("");
              setDefaultTin(undefined);
            }}
            style={{ marginTop: 10, color: "red" }}
          >
            Remove TIN
          </Button>
        )} */}
      </div>

      <div className="modal-divider"></div>
      <div className="modal-footer">
        <Button
          className="cancel-button"
          type="link"
          onClick={onCancel}
          style={{ color: "#000", fontSize: "16px", fontWeight: "500" }}
        >
          Cancel
        </Button>
        <div className="vl"></div>
        <Button
          className="confirm-button"
          type="link"
          onClick={() => onConfirm(selectedTin || manualTin!)}
          style={{ color: "#1890ff", fontSize: "16px", fontWeight: "500" }}
          disabled={!selectedTin && !manualTin} // Disable if both are empty
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default GenerateEInvoiceModal;
