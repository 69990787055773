import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Typography,
  Input,
  Checkbox,
  Button,
  Form,
  message,
  Modal,
  List,
} from "antd";
import {
  EyeInvisibleOutlined,
  EyeTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { get, post } from "../../services/Apiclient";
import { jwtDecode } from "jwt-decode";
import CryptoJS from "crypto-js"; // Importing CryptoJS for encryption
import { useCurrency } from "../../contexts/CurrencyContext";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;

const heroImage = `${process.env.PUBLIC_URL}/images/logo/Def_Image_Login_Welcome_V2.png`;

const { Content } = Layout;
const { Title, Text } = Typography;

const SignInForm = () => {
  const location = useLocation();
  const [id, setId] = useState("");
  const { setCurrencySymbol } = useCurrency();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({ email: "", password: "" });
  const navigate = useNavigate();

  const [showStoreModal, setShowStoreModal] = useState(false);
  const [role, setRole] = useState("");

  const [storeData, setStoreData] = useState([]);
  const [pagepermissionData, setpermissionData] = useState<any>({});

  const [title, setTitle] = useState("Welcome to Daco");
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);

  useEffect(() => {
    const handleResize = () => setCollapsed(window.innerWidth < 992);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredStores, setFilteredStores] = useState(storeData);

  const validateEmail = (email: any) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validatePassword = (password: any) => password.length >= 6;

  const encryptPassword = (password: any) => {
    const secretKey = "G7HqA5VCbMMi3KQN2anRckza";
    return CryptoJS.AES.encrypt(password, secretKey).toString();
  };

  const fetchMerchantTitle = async (id: string) => {
    try {
      const response = await get(`/merchant/gettitle/${id}`);

      if (response.data.success) {
        setTitle(response.data.data); // Update state with fetched time zone options
      } else {
        console.error("Failed to fetch title:", response.message);
      }
    } catch (error) {
      console.error("Error fetching title:", error);
    }
  };

  useEffect(() => {
    // Create a URLSearchParams object from the query string
    const queryParams = new URLSearchParams(location.search);

    // Get the first key-value pair, as the URL is just `http://localhost:3000/login?Bz4voIfV`
    const idParam: any = queryParams.get("id");
    setId(idParam);
    if (idParam) {
      fetchMerchantTitle(idParam);
    }
  }, [location.search]); //

  const fetchDayInfo = async (outletid: any) => {
    try {
      const response = await get(`/appointment/dayinfo/${outletid}`);
      if (response.data.success) {
        const convertTime = (time: any) => {
          if (time === "closed" || time === "null" || !time) return time;
          if (time.length === 8) return time;
          return `${time}:00`;
        };

        const convertedData = response.data.data.map((item: any) => {
          let starttime = convertTime(item.starttime);
          let endtime = convertTime(item.endtime);

          starttime =
            starttime && starttime !== "closed" && starttime !== "null"
              ? adjustTime(starttime, -1)
              : starttime;

          endtime =
            endtime && endtime !== "closed" && endtime !== "null"
              ? adjustTime(endtime, 1)
              : endtime;

          return {
            ...item,
            starttime,
            endtime,
          };
        });

        await localStorage.setItem(
          "workinghours",
          JSON.stringify(convertedData)
        );
      } else {
        message.error("Failed to fetch services");
      }
    } catch (error) {
      console.error("Error fetching services: ", error);
      message.error("Error fetching services");
    }
  };

  const getBookingColors = async (outletid: any) => {
    try {
      let url = `/bookingstatus/getall/${outletid}`;
      let response = await get(url);

      if (response?.data?.success) {
        let colorData = response.data.data;

        await localStorage.setItem("colordata", JSON.stringify(colorData));
      } else {
        console.error("Error fetching staff:", response.data.message);
      }
    } catch (error) {
      console.error("Error in staff API call:", error);
    }
  };

  // Helper function to adjust time by adding/subtracting hours
  const adjustTime = (timeStr: string, hours: number): string => {
    if (!timeStr || timeStr === "closed" || timeStr === "null") return timeStr;

    const [hoursPart, minutesPart, secondsPart] = timeStr
      .split(":")
      .map(Number);

    const date = new Date();
    date.setHours(hoursPart);
    date.setMinutes(minutesPart);
    date.setSeconds(secondsPart || 0);

    date.setHours(date.getHours() + hours);

    const pad = (n: number) => n.toString().padStart(2, "0");

    return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
      date.getSeconds()
    )}`;
  };

  const signIn = async () => {
    try {
      const encryptedPassword = encryptPassword(password);
      const response = await post("/user/login", {
        id: id,
        email,
        password: encryptedPassword,
      });

      if (response?.data?.success) {
        const token = response?.data?.token;
        await localStorage.setItem("token", token);

        const timeoutValue = Date.now() + 24 * 60 * 60 * 1000; // 24 hours from now
        localStorage.setItem(`timeout`, timeoutValue.toString());

        const decodedToken: any = jwtDecode(token);
        await localStorage.setItem("merchantid", decodedToken?.tokendata?.id);
        const userRole = decodedToken?.tokendata?.role;
        setRole(userRole);
        localStorage.setItem("userRole", userRole);
        setCurrencySymbol({
          CURRENCY_SYMBOL: decodedToken?.tokendata?.currencysymbol,
          CURRENCY_CODE: decodedToken?.tokendata?.currencycode,
        });
        if (userRole == "Merchant") {
          if (
            decodedToken?.tokendata?.storeData &&
            decodedToken?.tokendata?.storeData.length > 0
          ) {
            setStoreData(decodedToken?.tokendata?.storeData);
            setpermissionData(decodedToken?.tokendata?.pages);
            setFilteredStores(decodedToken?.tokendata?.storeData);
            localStorage.setItem("outletlogin", JSON.stringify(true));
            localStorage.setItem(
              "pagepermission",
              JSON.stringify(decodedToken?.tokendata?.pages)
            );
            // await localStorage.setItem(
            //   "outlet_id",
            //   decodedToken?.tokendata?.storeData[0].id
            // );
            // let store_name =
            //   decodedToken?.tokendata?.storeData[0].code +
            //   " - " +
            //   decodedToken?.tokendata?.storeData[0].name;
            // localStorage.setItem("store_name", store_name);
            // let enableonlineshop =
            //   decodedToken?.tokendata?.storeData[0].enableonlineshop == 0
            //     ? false
            //     : true;
            // let enableretailshop =
            //   decodedToken?.tokendata?.storeData[0].enableretailshop == 0
            //     ? false
            //     : true;
            // localStorage.setItem(
            //   "enableonlineshop",
            //   JSON.stringify(enableonlineshop)
            // );
            // localStorage.setItem(
            //   "enableretailshop",
            //   JSON.stringify(enableretailshop)
            // );
            setShowStoreModal(true);
          } else {
            navigate("/", { replace: true });
          }
        } else if (userRole == "Outletadmin") {
          await localStorage.setItem(
            "outlet_id",
            decodedToken?.tokendata?.outletid
          );
          let store_name =
            decodedToken?.tokendata?.storecode +
            " - " +
            decodedToken?.tokendata?.name;
          localStorage.setItem("store_name", store_name);
          let enableonlineshop =
            decodedToken?.tokendata?.enableonlineshop == 0 ? false : true;
          let enableretailshop =
            decodedToken?.tokendata?.enableretailshop == 0 ? false : true;
          let einvoiceenable =
            decodedToken?.tokendata?.einvoiceenable == 0 ? false : true;
          localStorage.setItem(
            "enableonlineshop",
            JSON.stringify(enableonlineshop)
          );
          localStorage.setItem(
            "enableretailshop",
            JSON.stringify(enableretailshop)
          );
          localStorage.setItem(
            "einvoiceenable",
            JSON.stringify(einvoiceenable)
          );
          localStorage.setItem(
            "pagepermission",
            JSON.stringify(decodedToken?.tokendata?.pages)
          );
          setpermissionData(decodedToken?.tokendata?.pages);
          localStorage.setItem("outletlogin", JSON.stringify(true));
          await fetchDayInfo(decodedToken?.tokendata?.outletid);
          await getBookingColors(decodedToken?.tokendata?.outletid);
          message.success("Sign-in successful!");
          if (userRole === "Superadmin") {
            localStorage.setItem("outletlogin", JSON.stringify(false));
            await localStorage.setItem("merchantid", "");
            navigate("/merchant/list", { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        } else if (userRole == "Staff") {
          await localStorage.setItem(
            "outlet_id",
            decodedToken?.tokendata?.outletid
          );
          let store_name =
            decodedToken?.tokendata?.storecode +
            " - " +
            decodedToken?.tokendata?.name;
          localStorage.setItem("store_name", store_name);
          let enableonlineshop =
            decodedToken?.tokendata?.enableonlineshop == 0 ? false : true;
          let enableretailshop =
            decodedToken?.tokendata?.enableretailshop == 0 ? false : true;
          let einvoiceenable =
            decodedToken?.tokendata?.einvoiceenable == 0 ? false : true;
          localStorage.setItem(
            "enableonlineshop",
            JSON.stringify(enableonlineshop)
          );
          localStorage.setItem(
            "enableretailshop",
            JSON.stringify(enableretailshop)
          );
          localStorage.setItem(
            "einvoiceenable",
            JSON.stringify(einvoiceenable)
          );
          localStorage.setItem("outletlogin", JSON.stringify(true));
          localStorage.setItem("staffid", decodedToken?.tokendata?.staffid);
          localStorage.setItem(
            "permissions",
            JSON.stringify(decodedToken?.tokendata?.permissions)
          );
          localStorage.setItem(
            "pagepermission",
            JSON.stringify(decodedToken?.tokendata?.pages)
          );
          setpermissionData(decodedToken?.tokendata?.pages);
          await fetchDayInfo(decodedToken?.tokendata?.outletid);
          await getBookingColors(decodedToken?.tokendata?.outletid);
          message.success("Sign-in successful!");
          if (userRole === "Superadmin") {
            localStorage.setItem("outletlogin", JSON.stringify(false));
            await localStorage.setItem("merchantid", "");
            navigate("/merchant/list", { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        } else {
          message.success("Sign-in successful!");
          if (userRole === "Superadmin") {
            localStorage.setItem("outletlogin", JSON.stringify(false));
            await localStorage.setItem("merchantid", "");
            navigate("/merchant/list", { replace: true });
          } else {
            navigate("/", { replace: true });
          }
        }

        // if (userRole === "Superadmin") {
        //   navigate("/merchant/list", { replace: true });
        // } else {
        //   navigate("/", { replace: true });
        // }
      } else {
        message.error("Sign-in failed. Please check your credentials.");
      }
    } catch (error) {
      console.error("API error:", error);
      message.error("An error occurred during sign-in.");
    }
  };

  const setActiveStore = async (store: any) => {
    await localStorage.setItem("outlet_id", store.id);
    let selectedStore = storeData
      .filter((x: any) => x.id == store.id)
      .map((x: any) => {
        return x;
      })[0];
    let store_name = selectedStore.code + " - " + selectedStore.name;
    localStorage.setItem("store_name", store_name);
    let enableonlineshop = selectedStore.enableonlineshop == 0 ? false : true;
    let enableretailshop = selectedStore.enableretailshop == 0 ? false : true;
    let einvoiceenable = selectedStore.einvoiceenable == 0 ? false : true;
    localStorage.setItem("enableonlineshop", JSON.stringify(enableonlineshop));
    localStorage.setItem("enableretailshop", JSON.stringify(enableretailshop));
    localStorage.setItem("einvoiceenable", JSON.stringify(einvoiceenable));
    const timeoutValue = Date.now() + 24 * 60 * 60 * 1000; // 24 hours from now
    localStorage.setItem(`timeout`, timeoutValue.toString());
    await fetchDayInfo(store.id);
    await getBookingColors(store.id);
    message.success("Sign-in successful!");
    if (role === "Superadmin") {
      navigate("/merchant/list", { replace: true });
    } else {
      if (pagepermissionData.homepage) {
        navigate("/", { replace: true });
      } else if (pagepermissionData.customerpage) {
        navigate("/customer", { replace: true });
      } else if (pagepermissionData.salespage) {
        navigate("/sales", { replace: true });
      } else if (pagepermissionData.inventorypage) {
        navigate("/inventory", { replace: true });
      } else if (pagepermissionData.bookingpage) {
        navigate("/booking", { replace: true });
      } else if (pagepermissionData.onlinestorepage) {
        navigate("/onlinesales", { replace: true });
      } else if (pagepermissionData.noticeboardpage) {
        navigate("/noticeboard", { replace: true });
      } else if (pagepermissionData.reportpage) {
        navigate("/reports", { replace: true });
      } else if (pagepermissionData.einvoicepage) {
        navigate("/einvoices", { replace: true });
      } else if (pagepermissionData.myinvoicepage) {
        navigate("/cashiereinvoiceslayout1", { replace: true });
      }
    }
    // setShowStoreModal(false);
  };

  const handleSubmit = () => {
    const emailError = validateEmail(email)
      ? ""
      : "Please enter a valid email.";
    const passwordError = validatePassword(password)
      ? ""
      : "Password must be at least 6 characters long.";

    setErrors({ email: emailError, password: passwordError });

    if (!emailError && !passwordError) {
      signIn();
    }
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const term = e.target.value;
    setSearchTerm(term);
    setFilteredStores(
      storeData.filter((store: any) =>
        store.name.toLowerCase().includes(term.toLowerCase())
      )
    );
  };

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Layout style={{ minHeight: "100vh", backgroundColor: "#f0f2f5" }}>
        <Row style={{ minHeight: "100vh" }}>
          <Col
            span={12}
            sm={24}
            xs={24}
            md={24}
            lg={12}
            xl={12}
            style={{
              backgroundColor: "#e0f7fa",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ textAlign: "center", padding: "20px" }}>
              <img
                src={heroImage}
                alt="Empowering Beauty Professionals"
                style={{ width: "80%", borderRadius: "8px", height: "auto" }}
              />
            </div>
          </Col>
          <Col
            span={12}
            sm={24}
            xs={24}
            md={24}
            lg={12}
            xl={12}
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            <div
              style={{
                textAlign: "center",
                marginTop: collapsed ? "40px" : "0px",
              }}
            >
              <Title level={2} className="normalbreak" style={{ margin: 0 }}>
                {/* Welcome to Daco */}
                {title}
              </Title>
            </div>
            <div style={{ maxWidth: "400px", width: "100%", padding: "40px" }}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Text>Sign in with email</Text>
                </Col>
                <Col span={24}>
                  <Form
                    layout="vertical"
                    style={{ width: "100%" }}
                    onFinish={handleSubmit}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={24}>
                        <Form.Item
                          label="E-mail"
                          validateStatus={errors.email ? "error" : ""}
                          help={errors.email}
                          required
                          style={{ margin: 0 }}
                        >
                          <Input
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          label="Password"
                          validateStatus={errors.password ? "error" : ""}
                          help={errors.password}
                          required
                          style={{ margin: 0 }}
                        >
                          <Input.Password
                            placeholder="Enter your password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            iconRender={(visible) =>
                              visible ? (
                                <EyeTwoTone />
                              ) : (
                                <EyeInvisibleOutlined />
                              )
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item style={{ margin: 0 }}>
                          <Checkbox>Remember password</Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item style={{ margin: 0 }}>
                          <Button type="primary" htmlType="submit" block>
                            Sign In
                          </Button>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        {/* <StoreSelectionModal visible={true} onClose={() => {}} /> */}
        {/* <Content style={{ textAlign: "center", padding: "10px 0" }}>
        <Text style={{ color: "#aaa" }}>
          © 2024 BOKA. All rights reserved.{" "}
          <a href="/privacy-policy" style={{ color: "#1890ff" }}>
            Privacy Policy
          </a>
        </Text>
      </Content> */}
        <Modal
          title="Store Selection"
          visible={showStoreModal}
          onCancel={() => {
            setShowStoreModal(false);
          }}
          footer={null}
          width={400}
        >
          <Input
            placeholder="Enter Store name/code"
            prefix={<SearchOutlined />}
            value={searchTerm}
            onChange={handleSearch}
            style={{ marginBottom: 16 }}
          />
          <List
            dataSource={filteredStores}
            renderItem={(store: any) => (
              <List.Item>
                <Row
                  style={{ width: "100%", cursor: "pointer" }}
                  onClick={() => setActiveStore(store)}
                >
                  <Col span={6}>
                    <span>{store.code}</span>
                  </Col>
                  <Col span={18}>
                    <span>{store.name}</span>
                  </Col>
                </Row>
              </List.Item>
            )}
            locale={{ emptyText: "No stores found" }}
          />
        </Modal>
      </Layout>
    </>
  );
};

export default SignInForm;
