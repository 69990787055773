import { Button } from "antd";
import Title from "antd/es/typography/Title";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "../headerFooter/footer";
const successIcon = `${process.env.PUBLIC_URL}/images/logo/success-icon.png`;

const AppointmentSuccess: React.FC = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();

  // Scroll to the top of the page with smooth behavior on component mount
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: "80vh",
        position: "relative",
      }}
    >
      <img style={{ borderRadius: "50px" }} src={successIcon} alt="" />
      <Title level={4}>Appointment successful.</Title>
      <div
        style={{
          width: "95%",
          textAlign: "center",
          position: "fixed",
          zIndex: 1000,
          bottom: "80px",
          left: "50%",
          transform: "translateX(-50%)",
        }}
      >
        <Button
          type="primary"
          size="large"
          block
          htmlType="submit" // This is necessary for form submission
          style={{
            backgroundColor: "orange",
            color: "white",
            fontSize: "16px",
            borderRadius: "8px",
          }}
          onClick={() => navigate(`/app/appointment/list/${merchantId}`)}
        >
          Got it
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default AppointmentSuccess;
