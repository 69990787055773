import {
  ArrowLeftOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Layout,
  message,
  Pagination,
  Row,
  Select,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Datatable from "../../components/Datatable";
import AddTimeOffModal from "../../components/model/staff/timeoff/AddTimeOffModal";
import { get } from "../../services/Apiclient";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const { RangePicker } = DatePicker;

// Add custom CSS for styling to match the image
const customStyles = `
  .ant-table-thead > tr > th {
    background: #fafafa;
    font-weight: 500;
    font-size: 14px;
    color: #000;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .ant-table-tbody > tr > td {
    font-size: 14px;
    color: #000;
  }
  .ant-table-placeholder {
    text-align: center;
    font-size: 14px;
    color: #8c8c8c;
  }
  .ant-modal-content {
    border-radius: 8px;
  }
  .ant-modal-header {
    border-radius: 8px 8px 0 0;
  }
  .ant-modal-title {
    font-weight: 600;
    font-size: 16px;
  }
  .ant-form-item-label > label {
    font-weight: 500;
    color: #000;
  }
`;

const TimeOff = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [dates, setDates] = useState<
    [dayjs.Dayjs | null, dayjs.Dayjs | null] | null
  >(null);
  const [searchText, setSearchText] = useState<string>("");
  const outletId = localStorage.getItem("outlet_id");
  const [servicesData, setServicesData] = useState<any[]>([]);
  const [departmentData, setDepartmentData] = useState<any[]>([]);
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<string | null>(
    null
  );
  const [timeoffData, setTimeoffData] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);

  const getServicesData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/service/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setServicesData(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedService(response.data.data[0].id);
        }
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getDepartmentData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/department/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setDepartmentData(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedDepartment(response.data.data[0].id);
        }
      }
    } catch (error) {
      console.error("Error fetching department:", error);
    }
  };

  const fetchTimeoffData = async (
    page: number = currentPage,
    limit: number = pageSize
  ) => {
    if (!outletId) return;

    const startDate = dates && dates[0] ? dates[0].format("DD-MM-YYYY") : null;
    const endDate = dates && dates[1] ? dates[1].format("DD-MM-YYYY") : null;
    const search = searchText || null;

    try {
      const response = await get(
        `/timeoff/${outletId}/${selectedService}/${selectedDepartment}/${startDate}/${endDate}/${search}`
      );
      if (response?.data?.success && response.data.data) {
        setTimeoffData(response.data.data);
        // TODO: Update this to use response.data.total when the API provides it
        setTotalRecords(response.data.data.length);
      } else {
        setTimeoffData([]);
        setTotalRecords(0);
        message.error("Failed to fetch timeoff records.");
      }
    } catch (error) {
      console.error("Error fetching timeoff records:", error);
      setTimeoffData([]);
      setTotalRecords(0);
      message.error("Error fetching timeoff records. Please try again.");
    }
  };

  useEffect(() => {
    getServicesData();
    getDepartmentData();
  }, []);

  useEffect(() => {
    if (selectedService && selectedDepartment) {
      // Call API if dates is null or if both dates are selected
      if (!dates || (!dates[0] && !dates[1])) {
        fetchTimeoffData(1);
        setCurrentPage(1);
      } else if (dates[0] && dates[1]) {
        fetchTimeoffData(1);
        setCurrentPage(1);
      }
    }
  }, [selectedService, selectedDepartment, dates]);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
    fetchTimeoffData(page, pageSize);
  };

  const columns = [
    {
      title: "Request time",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: string) => dayjs(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: "Staff name",
      dataIndex: "staffName",
      key: "staffName",
    },
    {
      title: "Department",
      dataIndex: "departmentname",
      key: "departmentname",
    },
    {
      title: "Time off type",
      dataIndex: "timeoftype",
      key: "timeoftype",
    },
    {
      title: "Start Time",
      dataIndex: "starttime",
      key: "starttime",
    },
    {
      title: "End Time",
      dataIndex: "endtime",
      key: "endtime",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const handleAddTimeOff = (newRecord: any) => {
    fetchTimeoffData(1);
    setCurrentPage(1);
    setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Layout
        style={{
          background: "#f5f7fa",
          padding: "12px 20px",
          minHeight: "100vh",
        }}
      >
        <style>{customStyles}</style>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <Button
            type="link"
            icon={
              <ArrowLeftOutlined
                style={{ fontSize: "16px", color: "#2e56f2" }}
              />
            }
            onClick={() => navigate(-1)}
            style={{ color: "#000", fontWeight: "700", fontSize: "18px" }}
          >
            Time Off
          </Button>
          <Button
            className="add-button"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => setIsModalVisible(true)}
          >
            Add
          </Button>
        </div>

        <div
          style={{
            backgroundColor: "#f6f7f9",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
          }}
        >
          <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
            <Col>
              <Select
                value={selectedService}
                style={{ width: 200, height: "40px", borderRadius: "4px" }}
                onChange={(value) => setSelectedService(value)}
              >
                {servicesData.map((service) => (
                  <Select.Option key={service.id} value={service.id}>
                    {service.servicename}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                value={selectedDepartment}
                style={{ width: 200, height: "40px", borderRadius: "4px" }}
                onChange={(value) => setSelectedDepartment(value)}
              >
                {departmentData.map((department) => (
                  <Select.Option key={department.id} value={department.id}>
                    {department.departmenttype}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col>
              <RangePicker
                value={dates}
                onChange={(values: any) => setDates(values)}
                format="YYYY-MM-DD"
                style={{ height: "46px", borderRadius: "4px" }}
                disabledDate={(current) =>
                  current && current < dayjs().startOf("day")
                }
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
            <Col span={12}>
              <Input
                placeholder="Search by staff name"
                style={{ height: "40px", borderRadius: "4px" }}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </Col>
            <Col>
              <Button
                type="primary"
                style={{
                  height: "40px",
                  borderRadius: "4px",
                  background: "#2e56f2",
                  border: "none",
                  fontWeight: "500",
                }}
                onClick={() => fetchTimeoffData(1)}
              >
                Search
              </Button>
            </Col>
          </Row>

          <div
            style={{
              marginBottom: "16px",
              fontWeight: "600",
              fontSize: "16px",
            }}
          >
            Time Off Record
          </div>
          <Datatable
            columns={columns}
            dataSource={timeoffData}
            showPagination={false}
          />

          <Row
            style={{
              marginTop: "10px",
              padding: "20px 20px 0px 20px",
              alignItems: "center",
              float: "right",
            }}
          >
            <Col>
              <Row
                justify="space-between"
                style={{ alignItems: "center", gap: "10px" }}
              >
                <Col>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "rgba(0,0,0,.85)",
                    }}
                  >
                    Total {totalRecords} items
                  </span>
                </Col>
                <Col>
                  <Pagination
                    current={currentPage}
                    pageSize={pageSize}
                    total={totalRecords}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    itemRender={(page, type, originalElement) => {
                      if (type === "prev") {
                        const isDisabled = currentPage === 1;
                        return (
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                              color: isDisabled ? "#bbb" : "#2e56f2",
                              pointerEvents: isDisabled ? "none" : "auto",
                            }}
                          >
                            <LeftOutlined />
                            <span>Previous</span>
                          </a>
                        );
                      }
                      if (type === "next") {
                        const isDisabled =
                          currentPage === Math.ceil(totalRecords / pageSize);
                        return (
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                              color: isDisabled ? "#bbb" : "#2e56f2",
                              pointerEvents: isDisabled ? "none" : "auto",
                            }}
                          >
                            <span>Next</span>
                            <RightOutlined />
                          </a>
                        );
                      }
                      return originalElement;
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <AddTimeOffModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onAdd={handleAddTimeOff}
          serviceId={selectedService}
          departmentId={selectedDepartment}
        />
      </Layout>
    </>
  );
};

export default TimeOff;
