import { Button, Col, Input, message, Modal, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { get } from "../../../services/Apiclient";
import Datatable from "../../Datatable"; // Using existing Datatable component
import { useCurrency } from "../../../contexts/CurrencyContext";
const { Option } = Select;

interface SelectedServiceCategoryModelProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  onSave: (selectedData: any[]) => void;
  selectedServices: any[];
  passedServiceData?: any[];
}

const SelectedServiceItemModel: React.FC<SelectedServiceCategoryModelProps> = ({
  title,
  visible,
  onCancel,
  onSave,
  selectedServices,
  passedServiceData,
}) => {
  const { currencySymbol } = useCurrency();
  const [categoryData, setCategoryData] = useState([]);
  const [categorycode, setCategorycode] = useState("");
  const [searchText, setSearchText] = useState("");
  const [serviceData, setServiceData] = useState<any[]>([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  const handleCategoryChange = (value: string) => {
    setCategorycode(value);
  };

  const handleSave = () => {
    if (selectedRowKeys.length > 0) {
      const selectedData = serviceData.filter((item: any) =>
        selectedRowKeys.includes(item.id)
      );
      onSave(selectedData);
    } else {
      onSave([]);
    }
    onCancel();
  };

  const getProductCategoryDropdown = async () => {
    try {
      const url = `/category/dropdown/type/${localStorage.getItem(
        "outlet_id"
      )}/Service`;
      const response = await get(url);
      if (response && response.data && response.data.success) {
        const data = response.data.data;
        data.unshift({ id: "null", name: "All Categories" });
        setCategoryData(data);
        setCategorycode("null");
      } else {
        console.error("Error fetching category data:", response.data.message);
      }
    } catch (error: any) {
      message.error(error.message);
    }
  };

  useEffect(() => {
    if (visible) {
      getProductCategoryDropdown();
      if (passedServiceData && passedServiceData.length > 0) {
        const keys = selectedServices.map((service) => service.serviceid);
        setSelectedRowKeys(keys);
        setServiceData(passedServiceData);
      } else {
        setSelectedRowKeys([]);
      }
    }
  }, [visible, selectedServices, passedServiceData]);

  const columns = [
    {
      title: "Service Code",
      dataIndex: "servicecode",
      key: "servicecode",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Service Name",
      dataIndex: "servicename",
      key: "servicename",
      width: 350,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 100,
      render: (sellprice: number) =>
        `${currencySymbol.CURRENCY_SYMBOL}${sellprice}`,
    },
  ];

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      footer={
        <div
          style={{
            marginTop: 16,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <strong>Selected:</strong> {selectedRowKeys.length} items
          </div>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      }
      width={"100%"}
      style={{
        top: "10px",
        maxWidth: "1240px",
      }}
      bodyStyle={{
        height: "calc(100vh - 160px)",
        scrollbarWidth: "thin",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Select
            placeholder="Please select category"
            value={categorycode}
            // onChange={(value) => handleCategoryChange(value)}
            onChange={handleCategoryChange}
            style={{ width: "100%" }}
            dropdownStyle={{ textAlign: "center" }}
          >
            {categoryData.length > 0 &&
              categoryData.map((cate: any) => (
                <Option value={cate.id} key={cate.id}>
                  {cate.name}
                </Option>
              ))}
          </Select>
        </Col>
        <Col span={8}>
          <Input
            placeholder="Select by Service name / code"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)} // Update search text on input change
            style={{ width: "100%" }}
            allowClear
          />
        </Col>
      </Row>
      {/* <Datatable<any>
        columns={[
          { title: "Service Code", dataIndex: "servicecode" },
          { title: "Service Name", dataIndex: "servicename" },
          {
            title: "Price",
            dataIndex: "price",
            // render: (sellprice: number) => `$${sellprice}`,
          },
        ]}
        dataSource={serviceData}
        scroll={{ y: 500 }}
        showCheckbox
        rowSelection={rowSelection}
        showPagination={false}
      /> */}
      <Datatable
        columns={columns}
        dataSource={serviceData.map((item) => ({ ...item, key: item.id }))} // Ensure each row has a key
        scroll={{ y: "calc(100vh - 260px)" }}
        rowSelection={{
          selectedRowKeys,
          onChange: (keys: React.Key[]) => setSelectedRowKeys(keys as string[]),
        }}
        showCheckbox={true}
        showPagination={false}
      />
    </Modal>
  );
};

export default SelectedServiceItemModel;
