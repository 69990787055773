import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  DownOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Flex, message, Row, Typography } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get } from "../../services/Apiclient";
import Header from "../headerFooter/Header";
import Footer from "../headerFooter/footer";

const { Text } = Typography;
const defaultStoreImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Store.png`;

const imgStyle: React.CSSProperties = {
  display: "block",
  width: "70px",
  height: "70px",
  borderRadius: "30px",
};

const buttonStyle = (isActive: boolean): React.CSSProperties => ({
  backgroundColor: isActive ? "black" : "pink",
  color: isActive ? "orange" : "black",
  border: "none",
  fontWeight: isActive ? "bold" : "normal",
  width: "120px",
  height: "40px",
});

const ServiceStaffSelection: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const selectedOutlet = localStorage.getItem("selectedOutlet");
  const selectedOutletId = localStorage.getItem("selectedOutletId");
  const [activeSection, setActiveSection] = useState<"Service" | "Staff">(
    "Service"
  );
  const [expandedCategory, setExpandedCategory] = useState<string | null>(null);
  const [selectedService, setSelectedService] = useState<{
    id: string;
    name: string;
    rating: number;
    sellprice: string;
    duration: string;
  } | null>(location.state?.selectedService || null);
  const [selectedStaff, setSelectedStaff] = useState<{
    id: string;
    name: string;
    lastname: string;
    department_name: string;
  } | null>(location.state?.selectedStaff || null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs>(
    location.state?.selectedDate
      ? dayjs(location.state.selectedDate, "DD/MM/YYYY")
      : dayjs() // Default to today
  );
  const [selectedMonth, setSelectedMonth] = useState<dayjs.Dayjs>(
    location.state?.selectedDate
      ? dayjs(location.state.selectedDate, "DD/MM/YYYY")
      : dayjs()
  );
  const [selectedTime, setSelectedTime] = useState<string | null>(
    location.state?.selectedTime || null
  );
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [serviceData, setServiceData] = useState<any[]>([]);
  const [staffData, setStaffData] = useState<any[]>([]);

  const now = dayjs(); // Current date and time
  const startOfWeek = selectedDate.startOf("week");
  const weekDays = Array.from({ length: 7 })
    .map((_, index) => startOfWeek.add(index, "day"))
    .filter((day) => !day.isBefore(now, "day")); // Filter out past days

  const handleDateChange = (date: dayjs.Dayjs) => {
    if (!date.isBefore(now, "day")) {
      setSelectedDate(date);
      setSelectedMonth(date);
      // Reset selected time if switching to today and previously selected time is in the past
      if (date.isSame(now, "day") && selectedTime) {
        const [startTime] = selectedTime.split(" - ");
        const slotTime = dayjs(startTime, "hh:mma");
        if (slotTime.isBefore(now)) {
          setSelectedTime(null);
        }
      }
    }
  };

  const changeWeek = (direction: "prev" | "next") => {
    const newDate =
      direction === "next"
        ? selectedDate.add(1, "week")
        : selectedDate.subtract(1, "week");
    if (!newDate.isBefore(now, "week")) {
      setSelectedDate(newDate);
      setSelectedMonth(newDate);
    }
  };

  const timeSlots = {
    Morning: ["09:00am", "09:30am", "10:00am", "10:30am", "11:00am", "11:30am"],
    Afternoon: [
      "12:00pm",
      "12:30pm",
      "01:00pm",
      "01:30pm",
      "02:00pm",
      "02:30pm",
      "03:00pm",
      "03:30pm",
    ],
    Evening: [
      "04:00pm",
      "04:30pm",
      "05:00pm",
      "05:30pm",
      "06:00pm",
      "06:30pm",
      "07:00pm",
    ],
  };

  // Calculate the time range (start + 30 minutes)
  const getTimeRange = (startTime: string) => {
    const start = dayjs(startTime, "hh:mma");
    const end = start.add(30, "minute");
    return `${start.format("hh:mma")} - ${end.format("hh:mma")}`;
  };

  // Check if a time slot is in the past
  const isTimeSlotPast = (time: string) => {
    if (!selectedDate.isSame(now, "day")) return false; // Future dates are valid
    const slotTime = dayjs(time, "hh:mma");
    return slotTime.isBefore(now);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getCategoryData();
    getServiceData();
    getStaffData();

    if (location.state?.selectedDate) {
      const dateFromState = dayjs(location.state.selectedDate, "DD/MM/YYYY");
      if (!dateFromState.isBefore(now, "day")) {
        setSelectedDate(dateFromState);
        setSelectedMonth(dateFromState);
      }
    }
    if (location.state?.selectedTime) {
      setSelectedTime(location.state.selectedTime); // Expecting format "hh:mma - hh:mma"
    }
    if (location.state?.selectedService) {
      setSelectedService(location.state.selectedService);
    }
    if (location.state?.selectedStaff) {
      setSelectedStaff(location.state.selectedStaff);
    }
    setShowCalendar(
      !!location.state?.selectedDate || !!location.state?.selectedTime
    );
  }, [location.state]);

  const getCategoryData = async () => {
    try {
      const url = `/appointmentmob/getcategory/${selectedOutletId}/${merchantId}/Service`;
      const response = await get(url);
      const data = response?.data;
      if (data?.success) setCategoryData(data.data);
    } catch (error) {
      console.error("Error while retrieving category list:", error);
    }
  };

  const getServiceData = async () => {
    try {
      const url = `/appointmentmob/servicedetails/${selectedOutletId}/${merchantId}?categoryid=null`;
      const response = await get(url);
      const data = response?.data;
      if (data?.success) {
        const groupedServices = data.data[0].services.reduce(
          (acc: any, service: any) => {
            if (!acc[service.categorycode]) acc[service.categorycode] = [];
            acc[service.categorycode].push(service);
            return acc;
          },
          {}
        );
        setServiceData(groupedServices);
      }
    } catch (error) {
      console.error("Error while retrieving service list:", error);
    }
  };

  const getStaffData = async () => {
    try {
      const url = `/appointmentmob/allstaff/${selectedOutletId}/${merchantId}`;
      const response = await get(url);
      const data = response?.data;
      if (data?.success && data.data.length > 0) {
        setStaffData(data.data[0].staffList || []);
      }
    } catch (error) {
      console.error("Error while retrieving staff list:", error);
    }
  };

  const toggleCategory = (category: string) => {
    setExpandedCategory((prev) => (prev === category ? null : category));
  };

  const handleNextClick = () => {
    if (!selectedDate || !selectedTime) {
      message.error("Please select a date and time.");
      return;
    }
    if (!selectedStaff) {
      message.error("Please select a staff.");
      setShowCalendar(false);
      setActiveSection("Staff");
      return;
    }

    let existingBookings = location.state?.selectedBookings || [];
    const customerId =
      location.state?.customerId || localStorage.getItem("customer_id");
    const customerName = location.state?.customerName || "Customer 1";
    const selectedCustomers = location.state?.selectedCustomers || [];
    const validityType = location.state?.validityType || "custom";
    const bookingIndex = location.state?.bookingIndex;

    let updatedBookings = [...existingBookings];
    const newBooking = {
      customerId,
      customerName,
      selectedDate: selectedDate.format("DD/MM/YYYY"),
      selectedTime, // Now includes the range, e.g., "09:30am - 10:00am"
      selectedService,
      selectedStaff,
      selectedOutlet,
    };

    if (bookingIndex !== undefined) {
      const customerBookings = updatedBookings.filter(
        (b) => b.customerId === customerId && b.customerName === customerName
      );
      const otherBookings = updatedBookings.filter(
        (b) => !(b.customerId === customerId && b.customerName === customerName)
      );
      if (customerBookings[bookingIndex]) {
        customerBookings[bookingIndex] = newBooking;
      }
      updatedBookings = [...otherBookings, ...customerBookings];
    } else {
      updatedBookings.push(newBooking);
    }

    navigate(`/app/booking/${merchantId}`, {
      state: {
        selectedBookings: updatedBookings,
        selectedCustomers,
        validityType,
        customerId,
        customerName,
      },
    });
  };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
      <Header
        title="Booking"
        onBack={() => navigate(`/app/outlet/list/${merchantId}`)}
        backgroundColor={"#fff"}
      />
      <div
        style={{
          padding: "16px",
          paddingTop: "70px",
          paddingBottom: selectedService ? "160px" : "80px",
        }}
      >
        <Row
          style={{
            padding: "5px",
            marginBottom: "14px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <Col style={{ padding: "12px" }} span={24}>
            <Flex align="center">
              <img alt="avatar" src={defaultStoreImage} style={imgStyle} />
              <Col>
                <Text
                  style={{
                    fontWeight: 500,
                    display: "block",
                    marginLeft: "10px",
                  }}
                >
                  {selectedOutlet || "No outlet selected"}
                </Text>
              </Col>
            </Flex>
          </Col>
          {selectedStaff && (
            <>
              <Divider style={{ margin: 0 }} />
              <Col
                style={{ padding: "12px", cursor: "pointer" }}
                span={24}
                onClick={() => {
                  setActiveSection("Staff");
                  setShowCalendar(false);
                }}
              >
                <Flex align="center" justify="space-between">
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      alt="avatar"
                      src={defaultStoreImage}
                      style={imgStyle}
                    />
                    <div style={{ flexGrow: 1 }}>
                      <Text style={{ fontWeight: 500, display: "block" }}>
                        {selectedStaff?.name} {selectedStaff?.lastname}
                      </Text>
                      <Text style={{ display: "block" }}>
                        {selectedStaff?.department_name}
                      </Text>
                    </div>
                  </Col>
                  <RightOutlined />
                </Flex>
              </Col>
            </>
          )}
          {selectedService && (
            <>
              <Divider style={{ margin: 0 }} />
              <Col
                style={{ padding: "12px", cursor: "pointer" }}
                span={24}
                onClick={() => {
                  setActiveSection("Service");
                  setShowCalendar(false);
                }}
              >
                <Flex align="center" justify="space-between">
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      alt="avatar"
                      src={defaultStoreImage}
                      style={imgStyle}
                    />
                    <div style={{ flexGrow: 1 }}>
                      <Text style={{ fontWeight: 500, display: "block" }}>
                        {selectedService?.name}
                      </Text>
                      <div style={{ display: "block" }}>
                        <Text style={{ color: "orange", fontWeight: 500 }}>
                          RM {selectedService.sellprice}{" "}
                        </Text>
                        <Text style={{ fontSize: "12px" }}>
                          {selectedService.duration}min
                        </Text>
                      </div>
                    </div>
                  </Col>
                </Flex>
              </Col>
            </>
          )}
          {selectedDate && selectedTime && (
            <>
              <Divider style={{ margin: 0 }} />
              <Col
                style={{ padding: "12px", cursor: "pointer" }}
                span={24}
                onClick={() => setShowCalendar(true)}
              >
                <Flex align="center" justify="space-between">
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <img
                      alt="avatar"
                      src={defaultStoreImage}
                      style={imgStyle}
                    />
                    <div style={{ flexGrow: 1 }}>
                      <Text style={{ fontWeight: 500, display: "block" }}>
                        {selectedDate.format("DD/MM/YYYY")}
                      </Text>
                      <Text style={{ color: "orange", fontWeight: 500 }}>
                        {selectedTime} {/* Now displays range */}
                      </Text>
                    </div>
                  </Col>
                  <RightOutlined />
                </Flex>
              </Col>
            </>
          )}
        </Row>

        {!showCalendar && (
          <Flex gap="small" wrap={false} style={{ width: "max-content" }}>
            <Button
              size="large"
              onClick={() => setActiveSection("Service")}
              style={buttonStyle(activeSection === "Service")}
            >
              Service
            </Button>
            <Button
              size="large"
              onClick={() => setActiveSection("Staff")}
              style={buttonStyle(activeSection === "Staff")}
            >
              Staff
            </Button>
          </Flex>
        )}

        {!showCalendar && activeSection === "Service" && (
          <div style={{ marginBottom: "20px" }}>
            {categoryData.map((category: any) => (
              <div key={category.id} style={{ marginTop: "20px" }}>
                <Text
                  onClick={() => toggleCategory(category.id)}
                  style={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    cursor: "pointer",
                    display: "block",
                  }}
                >
                  {category.name}{" "}
                  {expandedCategory === category.id ? (
                    <UpOutlined />
                  ) : (
                    <DownOutlined />
                  )}
                </Text>
                {expandedCategory === category.id &&
                  (serviceData[category.id]?.length > 0 ? (
                    serviceData[category.id].map((service: any) => (
                      <Row
                        key={service.id}
                        style={{
                          borderRadius: "8px",
                          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                          backgroundColor:
                            selectedService?.id === service.id
                              ? "#f0f0f0"
                              : "#fff",
                          marginTop: "10px",
                          padding: "12px",
                          cursor: "pointer",
                        }}
                        onClick={() => setSelectedService(service)}
                      >
                        <Col span={24}>
                          <Flex align="center">
                            <img
                              alt="service"
                              src={defaultStoreImage}
                              style={imgStyle}
                            />
                            <Col style={{ marginLeft: "10px", flexGrow: 1 }}>
                              <Text
                                style={{ fontWeight: 500, display: "block" }}
                              >
                                {service.name}
                              </Text>
                              <Text style={{ display: "block" }}>
                                ⭐ {service.rating || "4.8"}
                              </Text>
                              <div style={{ display: "block" }}>
                                <Text
                                  style={{ color: "orange", fontWeight: 500 }}
                                >
                                  RM {service.sellprice}{" "}
                                </Text>
                                <Text style={{ fontSize: "12px" }}>
                                  {service.duration}min
                                </Text>
                              </div>
                            </Col>
                            <div style={{ width: "24px", textAlign: "center" }}>
                              {selectedService?.id === service.id && (
                                <CheckOutlined
                                  style={{ fontSize: "24px", color: "green" }}
                                />
                              )}
                            </div>
                          </Flex>
                        </Col>
                      </Row>
                    ))
                  ) : (
                    <Text style={{ color: "gray", fontSize: "14px" }}>
                      No services available
                    </Text>
                  ))}
              </div>
            ))}
          </div>
        )}

        {!showCalendar && activeSection === "Staff" && (
          <div style={{ marginBottom: "20px" }}>
            {staffData.map((staff: any) => (
              <Row
                key={staff.id}
                style={{
                  marginTop: "10px",
                  borderRadius: "8px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                }}
                onClick={() => setSelectedStaff(staff)}
              >
                <Col
                  style={{
                    padding: "12px",
                    borderRadius: "8px",
                    backgroundColor:
                      selectedStaff?.id === staff.id ? "#f0f0f0" : "#fff",
                  }}
                  span={24}
                >
                  <Flex justify="space-between" align="center">
                    <img
                      alt="staff"
                      src={
                        staff.profile_pic
                          ? staff.profile_pic
                          : defaultStoreImage
                      }
                      style={imgStyle}
                    />
                    <Col style={{ flexGrow: 1, marginLeft: "10px" }}>
                      <Text style={{ fontWeight: 500, display: "block" }}>
                        {staff.name} {staff.lastname}
                      </Text>
                      <Text>{staff.department_name || "No Department"}</Text>
                    </Col>
                    <Button
                      style={{
                        backgroundColor:
                          selectedStaff?.id === staff.id ? "green" : "orange",
                        color: "white",
                      }}
                    >
                      {selectedStaff?.id === staff.id ? "Booked" : "Book"}
                    </Button>
                  </Flex>
                </Col>
              </Row>
            ))}
          </div>
        )}

        {showCalendar && (
          <>
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "10px" }}
            >
              <Button
                style={{ border: "none", color: "orange" }}
                onClick={() => changeWeek("prev")}
                disabled={selectedDate.startOf("week").isBefore(now, "day")}
              >
                <ArrowLeftOutlined />
              </Button>
              <Typography.Title
                style={{ textAlign: "center", marginTop: "5px" }}
                level={5}
              >
                {selectedMonth.format("MMMM YYYY")}
              </Typography.Title>
              <Button
                style={{ border: "none", color: "orange" }}
                onClick={() => changeWeek("next")}
              >
                <ArrowRightOutlined />
              </Button>
            </Row>
            <Row justify="space-between">
              {weekDays.map((day) => (
                <Col key={day.format("DD")} style={{ textAlign: "center" }}>
                  <Typography.Text
                    style={{ display: "block", marginBottom: "5px" }}
                  >
                    {day.format("ddd")}
                  </Typography.Text>
                  <Button
                    style={{
                      width: "2.5rem",
                      height: "2.5rem",
                      fontWeight: "bold",
                      border: "none",
                      backgroundColor: selectedDate.isSame(day, "day")
                        ? "orange"
                        : "#f0f0f0",
                      color: selectedDate.isSame(day, "day")
                        ? "white"
                        : "black",
                    }}
                    onClick={() => handleDateChange(day)}
                  >
                    {day.format("DD")}
                  </Button>
                </Col>
              ))}
            </Row>
            <Divider />
            {Object.entries(timeSlots).map(([period, slots]) => (
              <div key={period}>
                <Typography.Title level={5} style={{ fontWeight: "bold" }}>
                  {period}
                </Typography.Title>
                <Row gutter={[10, 8]}>
                  {slots.map((time) => {
                    const isPast = isTimeSlotPast(time);
                    return (
                      <Col key={time} span={6}>
                        <Button
                          type={
                            selectedTime?.startsWith(time)
                              ? "primary"
                              : "default"
                          }
                          style={{
                            border: "none",
                            backgroundColor: isPast
                              ? "#d9d9d9" // Gray for past times
                              : selectedTime?.startsWith(time)
                              ? "orange"
                              : "#f0f0f0",
                            color: isPast
                              ? "#999" // Lighter text for past times
                              : selectedTime?.startsWith(time)
                              ? "white"
                              : "black",
                            cursor: isPast ? "not-allowed" : "pointer",
                          }}
                          disabled={isPast}
                          onClick={() =>
                            !isPast && setSelectedTime(getTimeRange(time))
                          }
                        >
                          {time}
                        </Button>
                      </Col>
                    );
                  })}
                </Row>
              </div>
            ))}
            <Divider />
          </>
        )}
      </div>

      {selectedDate && selectedTime && (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            position: "fixed",
            bottom: "60px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#fff",
            padding: "12px 16px",
            zIndex: 1000,
            maxWidth: "90%",
          }}
        >
          <Divider style={{ marginTop: 0 }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ textAlign: "left", flex: 1 }}>
              <Text
                style={{ display: "block", fontSize: "14px", color: "#666" }}
              >
                Time Selected
              </Text>
              <Text
                style={{
                  display: "block",
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                {selectedDate.format("DD/MM/YYYY")} {selectedTime}
              </Text>
            </div>
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "orange",
                color: "white",
                fontSize: "16px",
                borderRadius: "8px",
                maxWidth: "150px",
                width: "40%",
              }}
              onClick={handleNextClick}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {selectedStaff && activeSection === "Staff" && !showCalendar && (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            position: "fixed",
            bottom: "60px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "white",
            padding: "20px 16px",
            zIndex: 1000,
            maxWidth: "95%",
          }}
        >
          <Divider style={{ marginTop: 0 }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ textAlign: "left", flex: 1 }}>
              <Text
                style={{ display: "block", fontSize: "14px", color: "#666" }}
              >
                Staff Selected
              </Text>
              <Text
                style={{
                  display: "block",
                  fontSize: "16px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "200px",
                }}
              >
                {selectedStaff.name}
              </Text>
            </div>
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "orange",
                color: "white",
                fontSize: "16px",
                borderRadius: "8px",
                maxWidth: "150px",
                width: "40%",
              }}
              onClick={() => setShowCalendar(true)}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      {selectedService && activeSection === "Service" && !showCalendar && (
        <div
          style={{
            width: "100%",
            textAlign: "center",
            position: "fixed",
            bottom: "60px",
            left: "50%",
            transform: "translateX(-50%)",
            backgroundColor: "#fff",
            padding: "20px 16px",
            zIndex: 1000,
            maxWidth: "95%",
          }}
        >
          <Divider style={{ marginTop: 0 }} />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ textAlign: "left", flex: 1 }}>
              <Text
                style={{ display: "block", fontSize: "14px", color: "#666" }}
              >
                Service Selected
              </Text>
              <Text
                style={{
                  display: "block",
                  fontSize: "16px",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "200px",
                }}
              >
                {selectedService.name}
              </Text>
            </div>
            <Button
              type="primary"
              size="large"
              style={{
                backgroundColor: "orange",
                color: "white",
                fontSize: "16px",
                borderRadius: "8px",
                maxWidth: "150px",
                width: "40%",
              }}
              onClick={() => setActiveSection("Staff")}
            >
              Next
            </Button>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default ServiceStaffSelection;
