import {
  HomeFilled,
  HomeOutlined,
  ProductFilled,
  ProductOutlined,
  ScheduleFilled,
  ScheduleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ChildComponentFooter from "./ChildComponentFooter";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { merchantId } = useParams();
  const [activeIndex, setActiveIndex] = React.useState<number>(0);

  // Determine the active index based on the current path
  useEffect(() => {
    let newIndex = 0;

    if (location.pathname === `/app/staff/dashboard`) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  }, [location.pathname, merchantId]);

  const handleNavigation = (path: string) => {
    navigate(path);
  };

  const footerItems = [
    {
      label: "Home",
      icon: <HomeFilled />,
      onClick: () => handleNavigation(`/app/staff/dashboard`),
    },
    {
      label: "Appointment",
      icon: <ScheduleFilled />,
      onClick: () => handleNavigation(`/app/staff/booking/list`),
    },
    {
      label: "Management",
      icon: <ProductFilled />,
      onClick: () => handleNavigation(``),
    },
    {
      label: "Me",
      icon: <UserOutlined />, // Updated to UserOutlined
      onClick: () => handleNavigation(``),
    },
  ];

  return (
    <>
      <ChildComponentFooter
        items={footerItems}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </>
  );
};

export default Footer;
