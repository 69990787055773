import {
  Form,
  Input,
  Button,
  DatePicker,
  Select,
  InputNumber,
  Row,
  Col,
  Typography,
  Card,
  Modal,
  message,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { get, post, put } from "../../services/Apiclient";
import ProductModel from "./ProductModel";
import dayjs from "dayjs";
// import { currencySymbol } from "../../constants/currencySymbol";
import { useCurrency } from "../../contexts/CurrencyContext";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;
const { Text } = Typography;
const InventoryIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_Code.png`;

interface StorageOption {
  id: string;
  type: string;
}
interface StaffOption {
  id: string;
  name: string;
}

export default function StockInAdd() {
  const { currencySymbol } = useCurrency();
  const [charCount, setCharCount] = useState(0);
  const [storageOptions, setStorageOptions] = useState<StorageOption[]>([]);
  const [staffOptions, setStaffOptions] = useState<StaffOption[]>([]);
  const [stockOutMethodOptions, setStockOutMethodOptions] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [randomId, setRandomId] = useState("");
  const [form] = Form.useForm();
  const { id } = useParams();
  const handleTextChange = (e: any) => {
    setCharCount(e.target.value.length);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchStorageOptions = async () => {
    const response = await get(
      `/storage/dropdown/${localStorage.getItem("outlet_id")}`
    ); // Adjust endpoint as needed
    if (response?.data?.success) {
      setStorageOptions(response.data.data); // Set storage options from API
    }
  };

  const fetchStaffOptions = async () => {
    const response = await get(
      `/staff/dropdown/${localStorage.getItem("outlet_id")}`
    ); // Adjust endpoint as needed
    if (response?.data?.success) {
      setStaffOptions(response.data.data);
    }
  };

  const fetchStockOutMethods = async () => {
    const response = await get(
      `/stockout/dropdown/${localStorage.getItem("outlet_id")}`
    ); // Adjust endpoint as needed
    if (response?.data?.success) {
      setStockOutMethodOptions(response.data.data);
    }
  };

  useEffect(() => {
    if (stockOutMethodOptions.length > 0) {
      if (id == undefined || id == null) {
        form.setFieldsValue({
          method: stockOutMethodOptions[0]?.id, // Set the initial value to the first option's id
        });
      }
    }
  }, [stockOutMethodOptions]);

  const randomgenerated = async () => {
    const response = await get("/user/randomgenerated"); // Adjust endpoint as needed

    if (response?.data?.success) {
      setRandomId(response?.data?.result);
    }
  };

  const fetchStockOutData = async (stockId: string) => {
    try {
      const response = await get(`/stock/stockoutdetails/${stockId}`);
      if (response?.data?.success) {
        const stockData = response.data.data;
        const products = stockData.product.map((product: any) => ({
          key: product.product_id,
          product_id: product.product_id,
          productname: product.product_name,
          productcode: product.productcode,
          supplier: product.supplier_name,
          quantity: product.quantity,
          unit_price: product.unit_price,
          amount: product.amount,
          manufacture_date: product.manufacture_date,
          expiry_date: product.expiry_date,
          unit_type: product.product_unit,
        }));
        form.setFieldsValue({
          stockDate: dayjs(stockData.stock_in_date),
          method: stockData.stock_method,
          storage: stockData.storage_id,
          staff: stockData.staff_id,
          note: stockData.note,
        });
        setSelectedProducts(products);
        setRandomId(stockData.ref_no);
      } else {
        message.error("Failed to fetch stock-in data.");
      }
    } catch (error) {
      message.error("Error fetching stock-in data: " + error);
    }
  };

  const handleSaveProducts = (selectedData: any[]) => {
    const updatedProducts = selectedData.map((product, index) => ({
      ...product,
      key: product.id,
      product_id: product.id,
      quantity: 0,
      unit_price: product.sellprice,
      amount: 0 * product.sellprice,
      manufacture_date: null,
      expiry_date: null,
    }));

    setSelectedProducts(updatedProducts);
    setIsModalVisible(false); // Close the modal after saving products
  };

  const handleExpiryDateChange = (date: dayjs.Dayjs | null, index: number) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index].expiry_date = date
      ? date.format("YYYY-MM-DD")
      : null; // ✅ Store as string
    setSelectedProducts(updatedProducts);
  };

  const handleManufacturingDateChange = (
    date: dayjs.Dayjs | null,
    index: number
  ) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index].manufacture_date = date
      ? date.format("YYYY-MM-DD")
      : null; // ✅ Store as string
    setSelectedProducts(updatedProducts);
  };

  const handleQuantityChange = (value: any, index: any) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index].quantity = value;
    updatedProducts[index].amount = value * updatedProducts[index].unit_price;
    setSelectedProducts(updatedProducts);
  };

  const handlePriceChange = (value: any, index: any) => {
    const updatedProducts = [...selectedProducts];
    updatedProducts[index].unit_price = parseFloat(value) || 0;
    updatedProducts[index].amount =
      updatedProducts[index].quantity * updatedProducts[index].unit_price;
    setSelectedProducts(updatedProducts);
  };

  const handleRemoveProduct = (key: any) => {
    setSelectedProducts((prevProducts) =>
      prevProducts.filter((product) => product.key !== key)
    );
  };

  const totalAmount =
    selectedProducts &&
    selectedProducts.length > 0 &&
    selectedProducts.reduce((sum, product) => sum + product.amount, 0);

  const handleSubmit = async (values: any) => {
    console.log("this is calling");
    const total_qty = selectedProducts.reduce(
      (sum, product) => sum + product.quantity,
      0
    );
    const total_amount = selectedProducts.reduce(
      (sum, product) => sum + product.amount,
      0
    );

    const products = selectedProducts.map((product) => ({
      product_id: product.product_id,
      quantity: product.quantity,
      unit_price: product.unit_price,
      amount: product.amount,
      manufacture_date: product.manufacture_date,
      expiry_date: product.expiry_date,
    }));

    const payload = {
      ref_no: randomId,
      total_qty,
      total_amount,
      stock_out_date: values.stockDate.format("YYYY-MM-DD"),
      stock_method: values.method,
      storage_id: values.storage,
      method_collection: "Staff",
      staff_id: values.staff,
      note: values.note,
      outlet_id: localStorage.getItem("outlet_id"),
      type_of_stock: "Stock-out",
      products,
    };

    try {
      let response;
      if (id) {
        // Update API call when id exists
        response = await put(`/stock/stockoutupdate/${id}`, payload);
      } else {
        // Create API call when id does not exist
        response = await post("/stock/addstocks", payload);
      }
      if (response?.data?.success) {
        message.success("Stock In details added successfully!");
        navigate("/inventory/stockout/list");
      } else {
        message.error("Failed to save stock entry: " + response?.data?.message);
      }
    } catch (error) {
      const errorMessage = error || "Unknown error occurred";
      message.error("Error saving stock entry: " + errorMessage);
    }
  };

  useEffect(() => {
    fetchStorageOptions();
    fetchStaffOptions();
    fetchStockOutMethods();
    if (id) {
      fetchStockOutData(id);
    } else {
      randomgenerated();
    }
  }, [id]);

  // useEffect(() => {
  //   fetchStorageOptions();
  //   fetchStaffOptions();
  //   randomgenerated();
  // }, []);
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Content className="mainContainer">
        {/* Header Section */}
        <Row justify="space-between" align="middle">
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div
                onClick={() => navigate("/inventory/stockout/list")}
                style={{ cursor: "pointer" }}
              >
                <ArrowLeftOutlined
                  style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
                />
              </div>
              <h2 style={{ margin: 0 }}>Product Stock Out</h2>
            </div>
          </Col>
        </Row>
        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#FAFAFA",
            borderRadius: "12px",
            border: "1px solid #E0E7FE",
            boxSizing: "border-box",
            height: "calc(100vh - 70px)",
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            overflowY: "auto",
          }}
        >
          <Form layout="vertical" form={form} onFinish={handleSubmit}>
            <div className="side-content">
              <Row gutter={24}>
                {/* Left Side: Receipt Section with scrollbar */}
                <Col
                  span={8}
                  style={{
                    height: "calc(100vh - 140px)", // Set a height limit for the scrollbar
                    overflowY: "auto", // Enable vertical scrolling
                  }}
                >
                  <div
                    className="bk-view display-def view-leftWrap"
                    style={{
                      height: "inherit",
                      border: "1px solid #E6E6E6",
                      paddingLeft: "15px",
                      paddingRight: "15px",
                      borderRadius: "8px",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <Title
                      level={4}
                      className="bk-title"
                      style={{ marginBottom: "16px" }}
                    >
                      <span className="bk-lang stockIOUpdate_title_receipt">
                        Receipt
                      </span>
                    </Title>
                    <Card
                      style={{
                        border: "1px solid #E6E6E6",
                        padding: "16px",
                        borderRadius: "8px",
                      }}
                    >
                      <div
                        className="bk-view display-def view-info"
                        style={{ marginBottom: "16px" }}
                      >
                        <Row align="middle">
                          <Col>
                            <img
                              src={InventoryIcon}
                              alt="icon"
                              style={{ width: "24px", marginRight: "8px" }}
                            />
                          </Col>
                          <Col>
                            <Typography.Text className="bk-lang stockIOUpdate_ref lang-code">
                              Ref#
                            </Typography.Text>
                          </Col>
                        </Row>
                        <Typography.Text
                          strong
                          className="bk-title title-code"
                          style={{ marginTop: "8px", display: "block" }}
                        >
                          {randomId}
                        </Typography.Text>
                      </div>
                      <hr />
                      <div
                        className="bk-view display-def view-info-item"
                        style={{ marginBottom: "16px" }}
                      >
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="bk-lang stockIOUpdate_qty">
                              Qty.
                            </Typography.Text>
                          </Col>
                          <Col>
                            <Typography.Text>
                              {selectedProducts.reduce(
                                (sum, product) => sum + product.quantity,
                                0
                              )}
                            </Typography.Text>
                          </Col>
                        </Row>
                      </div>
                      <hr />
                      <div
                        className="bk-view display-def view-info-item"
                        style={{ marginBottom: "16px" }}
                      >
                        <Row justify="space-between">
                          <Col>
                            <Typography.Text className="bk-lang stockIOUpdate_amount">
                              Amount
                            </Typography.Text>
                          </Col>
                          <Col>
                            <Typography.Text>
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              {totalAmount}
                            </Typography.Text>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                    {/* Form Section */}
                    <Form.Item
                      label="Stock out date"
                      name="stockDate"
                      initialValue={dayjs()}
                      rules={[
                        { required: true, message: "Please select a date" },
                      ]}
                    >
                      <DatePicker
                        className="center" // Custom class to match your reference
                        style={{ width: "100%", height: "42px" }}
                        placeholder="Select date"
                      />
                    </Form.Item>
                    <Form.Item
                      label="Stock out method"
                      name="method"
                      rules={[
                        { required: true, message: "Please select a method" },
                      ]}
                    >
                      <Select>
                        {stockOutMethodOptions.map((option) => (
                          <Option key={option.id} value={option.id}>
                            {option.stockouttype}
                          </Option>
                        ))}
                        {/* <Option value="Transfer">Transfer</Option>
                      <Option value="Standard">Standard</Option>
                      <Option value="Adjustment">Adjustment</Option> */}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Storage"
                      name="storage"
                      rules={[
                        { required: true, message: "Please select a storage" },
                      ]}
                    >
                      <Select placeholder="Please select storage">
                        {storageOptions.map((option) => (
                          <Option key={option.id} value={option.id}>
                            {option.type}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="Staff"
                      name="staff"
                      rules={[
                        { required: true, message: "Please select staff" },
                      ]}
                    >
                      <Select placeholder="Please select">
                        {staffOptions.map((staff) => (
                          <Option key={staff.id} value={staff.id}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Note" name="note">
                      <TextArea
                        rows={3}
                        maxLength={200}
                        placeholder="Please enter"
                        onChange={handleTextChange}
                      />
                      <div
                        style={{
                          textAlign: "right",
                          fontSize: "12px",
                          color: "#888",
                        }}
                      >
                        {charCount}/200
                      </div>
                    </Form.Item>
                  </div>
                </Col>
                {/* Right Side: Product Section with scrollbar */}
                <Col
                  span={16}
                  style={{
                    height: "calc(100vh - 140px)", // Set a height limit for the scrollbar
                    overflowY: "auto", // Enable vertical scrolling
                  }}
                >
                  <div
                    className="bk-view display-def view-rightWrap"
                    style={{
                      padding: "8px 16px 8px 16px",
                      borderRadius: "8px",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <Row
                      style={{ display: "flex" }}
                      justify="space-between"
                      align="middle"
                    >
                      <Typography.Title level={4}>Product</Typography.Title>
                      <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        style={{
                          backgroundColor: "#FF7A00",
                          borderColor: "#FF7A00",
                        }}
                        onClick={() => showModal()}
                      >
                        Add product
                      </Button>
                    </Row>
                    {selectedProducts &&
                      selectedProducts.length > 0 &&
                      selectedProducts.map((product, index) => (
                        <Card
                          className="product-card"
                          key={product.key}
                          title={`${product.productname} (${product.productcode})`}
                          extra={
                            <Button
                              onClick={() => handleRemoveProduct(product.key)}
                              type="link"
                              style={{
                                border: "none",
                                boxShadow: "none",
                                textDecoration: "underline",
                                color: "rgba(255, 255, 255, 0.7)",
                                textUnderlineOffset: "4px",
                              }}
                            >
                              Remove
                            </Button>
                          }
                          style={{
                            marginTop: "16px",
                            border: "1px solid #E6E6E6",
                            borderRadius: "8px",
                          }}
                        >
                          <Row gutter={16}>
                            <Col span={12}>
                              <label>Quantity</label>
                              <InputNumber
                                type="number"
                                min={0}
                                style={{ width: "100%" }}
                                value={product.quantity}
                                onChange={(value) =>
                                  handleQuantityChange(value, index)
                                }
                              />
                            </Col>
                            <Col span={12}>
                              <label>Purchase unit price</label>
                              <Input
                                prefix={`${currencySymbol.CURRENCY_SYMBOL}`}
                                value={product.unit_price}
                                onChange={(e) =>
                                  handlePriceChange(e.target.value, index)
                                }
                              />
                            </Col>
                          </Row>
                          <Row gutter={16} style={{ marginTop: "16px" }}>
                            <Col span={12}>
                              <Typography.Text>
                                Amount: {currencySymbol.CURRENCY_SYMBOL}
                                {product.amount || 0}
                              </Typography.Text>
                            </Col>
                            <Col span={12}>
                              <Typography.Text>
                                Stock out unit: {product.unit_type || "-"}
                              </Typography.Text>
                            </Col>
                          </Row>
                          <Row gutter={16} style={{ marginTop: "16px" }}>
                            <Col span={12}>
                              <Typography.Text>
                                Stock: {product.quantity || "-"}
                              </Typography.Text>
                            </Col>
                            <Col span={12}>
                              <Typography.Text>
                                Supplier: {product.supplier || "-"}
                              </Typography.Text>
                            </Col>
                          </Row>
                          <Row gutter={16} style={{ marginTop: "16px" }}>
                            <Col span={12}>
                              <Typography.Text>
                                <Form.Item label="Manufacture date">
                                  <DatePicker
                                    value={
                                      product.manufacture_date
                                        ? dayjs(
                                            product.manufacture_date,
                                            "YYYY-MM-DD"
                                          )
                                        : null
                                    } // ✅ Convert string back to dayjs
                                    onChange={(date) =>
                                      handleManufacturingDateChange(date, index)
                                    }
                                    format="YYYY-MM-DD"
                                    disabledDate={(current) =>
                                      current && current > dayjs().endOf("day")
                                    }
                                    className="center" // Custom class to match your reference
                                    style={{ width: "100%", height: "42px" }}
                                    placeholder="Select date"
                                  />
                                </Form.Item>
                                {/* Manufacture date: {product.manufacture_date} */}
                              </Typography.Text>
                            </Col>
                            <Col span={12}>
                              <Typography.Text>
                                <Form.Item label="Expiry date">
                                  <DatePicker
                                    value={
                                      product.expiry_date
                                        ? dayjs(
                                            product.expiry_date,
                                            "YYYY-MM-DD"
                                          )
                                        : null
                                    } // ✅ Convert string back to dayjs
                                    onChange={(date) =>
                                      handleExpiryDateChange(date, index)
                                    }
                                    format="YYYY-MM-DD"
                                    disabledDate={(current) =>
                                      current &&
                                      current < dayjs().startOf("day")
                                    }
                                    className="center" // Custom class to match your reference
                                    style={{ width: "100%", height: "42px" }}
                                    placeholder="Select date"
                                  />
                                </Form.Item>
                                {/* <DatePicker
                                                        value={product.expiry_date}
                                                        className="center" // Custom class to match your reference
                                                        style={{ width: "100%", height: "42px" }}
                                                        placeholder="Select date"
                                                      /> */}
                                {/* Expiry date: {product.expiry_date} */}
                              </Typography.Text>
                            </Col>
                          </Row>
                        </Card>
                      ))}
                  </div>
                </Col>
              </Row>
              <hr style={{ border: "1px solid #e0e7fe" }}></hr>
              {/* <Row
              style={{
                marginTop: "24px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                style={{ marginRight: "8px", height: "40px", fontSize: "16px" }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ height: "40px", fontSize: "16px" }}
              >
                Save
              </Button>
            </Row> */}
              <div className="save-section removemargin">
                <Button
                  style={{
                    marginRight: "8px",
                    height: "40px",
                    fontSize: "16px",
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ height: "40px", fontSize: "16px" }}
                >
                  Save
                </Button>
              </div>
            </div>
          </Form>

          <ProductModel
            title="Product"
            visible={isModalVisible}
            onCancel={handleCancel}
            onSave={handleSaveProducts}
            selectedProducts={selectedProducts}
          />
        </Content>
      </Content>
    </>
  );
}
