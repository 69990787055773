import Title from "antd/es/typography/Title";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Image_Login_Welcome_V2.png`;

const LoadingPage: React.FC = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/app/staff/dashboard");
    }, 5000);

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
        backgroundColor: "#fff",
        padding: "20px",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <img
        src={logoIcon} // Replace with the actual Daco logo URL
        alt="Daco Logo"
        style={{ width: 100, height: 100 }}
      />
      <Title
        level={2}
        style={{
          fontWeight: "bold",
          marginBottom: "20px",
          color: "#214f9d",
        }}
      >
        Daco Staff
      </Title>
      <p style={{ marginBottom: "20px" }}>Welcome, ANNIE LEW TZE YIN!</p>
      <p>Staff loading</p>
    </div>
  );
};

export default LoadingPage;
