import { Divider, Form, Input, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "../headerfooter/Header";

const { Text } = Typography;

const StaffOutletSelection: React.FC = () => {
  const navigate = useNavigate();

  const handleChainSelect = () => {
    navigate("/app/staff/loading");
  };

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Header */}
      <Header
        title="Chain Selection"
        onBack={() => navigate(`/app/staff/signin`)}
        backgroundColor={"#fff"}
      />

      {/* Content */}
      <div style={{ flex: 1, marginTop: "80px", padding: "20px" }}>
        <Form layout="vertical">
          <Form.Item name="store">
            <Input placeholder="Enter store name/code" autoComplete="off" />
          </Form.Item>
          <Divider
            style={{
              borderWidth: "8px",
              marginTop: 12,
              marginBottom: 12,
              color: "#f4f4f4",
            }}
          />

          <Form.Item>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                color: "#000",
                border: "none",
                height: "48px",
                fontSize: "16px",
                justifyContent: "space-between",
                cursor: "pointer",
              }}
              onClick={handleChainSelect}
            >
              <Text>001</Text>
              <Text>LamLam Eyelash & Eyebrows</Text>
            </div>
            <Divider style={{ margin: 5 }} />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default StaffOutletSelection;
