import React from "react";
import { Card, Row, Col, Typography, Button, Divider } from "antd";
import {
  LeftOutlined,
  DownOutlined,
  UserOutlined,
  ShopOutlined,
  FundOutlined,
  WalletOutlined,
  DollarOutlined,
} from "@ant-design/icons";

const customerPerformanceIcon = `${process.env.PUBLIC_URL}/images/logo/customer-performance-icon.png`;
const actualTurnoverPerformanceIcon = `${process.env.PUBLIC_URL}/images/logo/turnover-performance-icon.png`;
const netPerformanceIcon = `${process.env.PUBLIC_URL}/images/logo/net-performance-icon.png`;
const tipsPerformanceIcon = `${process.env.PUBLIC_URL}/images/logo/tips-performance-icon.png`;
const turnoverPerformanceIcon = `${process.env.PUBLIC_URL}/images/logo/turnover-wallet-icon.png`;
const performanceIcon = `${process.env.PUBLIC_URL}/images/logo/performance-icon.png`;
const commissionPerformanceIcon = `${process.env.PUBLIC_URL}/images/logo/commission-performance-icon.png`;
const reportFileIcon = `${process.env.PUBLIC_URL}/images/logo/report-file-icon.png`;

const { Title, Text } = Typography;

const PerformanceDetails = () => {
  return (
    <div
      style={{
        backgroundColor: "#f8f8f8",
        minHeight: "100vh",
      }}
    >
      {/* Header */}
      <div style={{ padding: 16, marginBottom: 16, background: "#fff" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          <Button
            type="text"
            icon={<LeftOutlined />}
            style={{ marginRight: 8 }}
          />
          <Title level={5} style={{ margin: 0 }}>
            Performance Details
          </Title>
        </div>

        {/* Date Range & Filter */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 16,
          }}
        >
          <Button
            style={{
              backgroundColor: "transparent",
              border: "none",
              color: "#333",
              display: "flex",
              alignItems: "center",
              boxShadow: "none",
            }}
          >
            <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              01/02/2025 ~ 28/02/2025
            </span>{" "}
            <DownOutlined style={{ fontSize: 12, marginLeft: 5 }} />
          </Button>

          <Button
            style={{
              backgroundColor: "#f6f5fa",
              borderRadius: 20,
              border: "1px solid #f6f5fa",
              color: "#333",
              display: "flex",
              alignItems: "center",
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
            }}
          >
            <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
              All
            </span>{" "}
            <DownOutlined style={{ fontSize: 12, marginLeft: 5 }} />
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: 16,
          }}
        >
          <Title level={5} style={{ margin: 0 }}>
            Total(RM)
          </Title>
        </div>

        {/* Total Section */}
        <div>
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Card style={styles.cardStyle} bodyStyle={styles.bodyStyle}>
                <Row gutter={16} style={{ margin: 0 }}>
                  <Col span={18} style={{ padding: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Text className="normalbreak">Customers</Text>
                      <Title level={4} style={{ margin: 0 }}>
                        11
                      </Title>
                    </div>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      padding: 0,
                    }}
                  >
                    <img
                      src={customerPerformanceIcon}
                      alt="customer performance"
                      style={{
                        width: 30,
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={styles.cardStyle} bodyStyle={styles.bodyStyle}>
                <Row gutter={16} style={{ margin: 0 }}>
                  <Col span={18} style={{ padding: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Text className="normalbreak">Turnover</Text>
                      <Title level={4} style={{ margin: 0 }}>
                        2,776.00
                      </Title>
                    </div>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      padding: 0,
                    }}
                  >
                    <img
                      src={turnoverPerformanceIcon}
                      alt="turnover performance"
                      style={{
                        width: 30,
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={styles.cardStyle} bodyStyle={styles.bodyStyle}>
                <Row gutter={16} style={{ margin: 0 }}>
                  <Col span={18} style={{ padding: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Text className="normalbreak">Actual turnover</Text>
                      <Title level={4} style={{ margin: 0 }}>
                        2,776.00
                      </Title>
                    </div>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      padding: 0,
                    }}
                  >
                    <img
                      src={actualTurnoverPerformanceIcon}
                      alt="actual turnover performance"
                      style={{
                        width: 30,
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={styles.cardStyle} bodyStyle={styles.bodyStyle}>
                <Row gutter={16} style={{ margin: 0 }}>
                  <Col span={18} style={{ padding: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Text className="normalbreak">Performance</Text>
                      <Title level={4} style={{ margin: 0 }}>
                        0.00
                      </Title>
                    </div>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      padding: 0,
                    }}
                  >
                    <img
                      src={performanceIcon}
                      alt="performance"
                      style={{
                        width: 30,
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={styles.cardStyle} bodyStyle={styles.bodyStyle}>
                <Row gutter={16} style={{ margin: 0 }}>
                  <Col span={18} style={{ padding: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Text className="normalbreak">Net performance</Text>
                      <Title level={4} style={{ margin: 0 }}>
                        0.00
                      </Title>
                    </div>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      padding: 0,
                    }}
                  >
                    <img
                      src={netPerformanceIcon}
                      alt="Net performance"
                      style={{
                        width: 30,
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={styles.cardStyle} bodyStyle={styles.bodyStyle}>
                <Row gutter={16} style={{ margin: 0 }}>
                  <Col span={18} style={{ padding: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Text className="normalbreak">Commission</Text>
                      <Title level={4} style={{ margin: 0 }}>
                        0.00
                      </Title>
                    </div>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      padding: 0,
                    }}
                  >
                    <img
                      src={commissionPerformanceIcon}
                      alt="commission performance"
                      style={{
                        width: 30,
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col span={12}>
              <Card style={styles.cardStyle} bodyStyle={styles.bodyStyle}>
                <Row gutter={16} style={{ margin: 0 }}>
                  <Col span={18} style={{ padding: 0 }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      <Text className="normalbreak">Tips</Text>
                      <Title level={4} style={{ margin: 0 }}>
                        0.00
                      </Title>
                    </div>
                  </Col>
                  <Col
                    span={6}
                    style={{
                      position: "absolute",
                      top: 10,
                      right: 10,
                      padding: 0,
                    }}
                  >
                    <img
                      src={tipsPerformanceIcon}
                      alt="Tips"
                      style={{
                        width: 30,
                      }}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

      {/* Transaction / Performance History */}
      <Card
        style={{
          borderRadius: 0,
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
          marginBottom: 16,
        }}
        bodyStyle={{ padding: "12px 0px" }}
      >
        {/* Top row: Name & Date */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 8,
          }}
        >
          <Text style={{ display: "flex", gap: 5 }}>
            <span
              style={{
                background: "#2d3333",
                color: "#f78e3f",
                display: "flex",
                justifyContent: "center",
                width: 25,
                borderRadius: "0px 8px 8px 0px",
                fontSize: 14,
                fontWeight: "700",
              }}
            >
              1
            </span>
            <span style={{ color: "#161616", fontWeight: "700", fontSize: 14 }}>
              Penny Tay
            </span>
          </Text>
          <Text
            type="secondary"
            style={{ fontSize: 14, color: "#b1b1b1", marginRight: 12 }}
          >
            23/02 16:02
          </Text>
        </div>

        {/* Service name & price */}
        <div
          style={{
            background: "#f6f5fa",
            margin: 12,
            padding: 12,
            borderRadius: 12,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 8,
            }}
          >
            <Text style={{ color: "#161616", fontWeight: "700", fontSize: 14 }}>
              Elight remove hair upper lip
            </Text>
            <Text style={{ fontSize: 14, fontWeight: "700", color: "#e4484c" }}>
              RM 60
            </Text>
          </div>

          {/* Divider */}
          <Divider style={{ margin: "8px 0" }} />

          {/* Bottom row: values */}
          <Row>
            {/* LEFT COLUMN */}
            <Col
              span={12}
              style={{
                borderRight: "1px solid #E0E0E0", // vertical divider
                padding: "12px",
              }}
            >
              {/* Turnover */}
              <Row
                style={{
                  marginBottom: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Col span={12} style={{ padding: 0 }}>
                  <Text
                    className="normalbreak"
                    style={{ color: "#6a6a69", fontSize: "14px" }}
                  >
                    Turnover
                  </Text>
                </Col>
                <Col span={12} style={{ padding: 0, textAlign: "right" }}>
                  <Text className="normalbreak" strong style={{ color: "#111111", fontSize: "14px" }}>
                    RM 60.00
                  </Text>
                </Col>
              </Row>

              {/* Performance */}
              <Row
                style={{
                  marginBottom: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Col span={12} style={{ padding: 0 }}>
                  <Text className="normalbreak" style={{ color: "#6a6a69", fontSize: "14px" }}>
                    Performance
                  </Text>
                </Col>
                <Col span={12} style={{ padding: 0, textAlign: "right" }}>
                  <Text className="normalbreak" strong style={{ color: "#111111", fontSize: "14px" }}>
                    RM 0.00
                  </Text>
                </Col>
              </Row>

              {/* Commission */}
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Col span={12} style={{ padding: 0 }}>
                  <Text className="normalbreak" style={{ color: "#6a6a69", fontSize: "14px" }}>
                    Commission
                  </Text>
                </Col>
                <Col span={12} style={{ padding: 0, textAlign: "right" }}>
                  <Text className="normalbreak" strong style={{ color: "#111111", fontSize: "14px" }}>
                    RM 0.00
                  </Text>
                </Col>
              </Row>
            </Col>

            {/* RIGHT COLUMN */}
            <Col span={12} style={{ padding: "12px" }}>
              {/* Actual Turnover */}
              <Row
                style={{
                  marginBottom: "12px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Col span={12} style={{ padding: 0 }}>
                  <Text className="normalbreak" style={{ color: "#6a6a69", fontSize: "14px" }}>
                    Actual turnover
                  </Text>
                </Col>
                <Col span={12} style={{ padding: 0, textAlign: "right" }}>
                  <Text className="normalbreak" strong style={{ color: "#111111", fontSize: "14px" }}>
                    RM 60.00
                  </Text>
                </Col>
              </Row>

              {/* Net Performance */}
              <Row
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Col span={12} style={{ padding: 0 }}>
                  <Text className="normalbreak" style={{ color: "#6a6a69", fontSize: "14px" }}>
                    Net performance
                  </Text>
                </Col>
                <Col span={12} style={{ padding: 0, textAlign: "right" }}>
                  <Text className="normalbreak" strong style={{ color: "#111111", fontSize: "14px" }}>
                    RM 0.00
                  </Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        {/* Ref */}
        <div
          style={{
            margin: "8px 12px 12px 12px",
            display: "flex",
            alignItems: "center",
            gap: 5,
          }}
        >
          <img
            src={reportFileIcon}
            alt="Ref icon"
            style={{
              width: 18,
              height: 18,
            }}
          />
          <Text type="secondary" style={{ fontSize: 12, color: "#6c6c6c" }}>
            Ref#: 250223004
          </Text>
        </div>
      </Card>
    </div>
  );
};

const styles = {
  cardStyle: {
    borderRadius: 12,
    backgroundColor: "#f0f0fa",
    height: "100%",
  },
  bodyStyle: {
    padding: 12,
    height: "100%",
  },
  cardContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 8,
  },
  iconStyle: {
    fontSize: 20,
    color: "#888",
  },
};

export default PerformanceDetails;
