import React, { useState, useRef, useEffect, useCallback } from "react";
import moment, { Moment } from "moment";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

interface InfiniteCalendarProps {
  visible: boolean;
  initialStartDate?: Moment;
  initialEndDate?: Moment;
  onDateChange?: (startDate: Moment | null, endDate: Moment | null) => void;
  onCancel: () => void;
}

const InfiniteCalendar: React.FC<InfiniteCalendarProps> = ({
  visible,
  initialStartDate,
  initialEndDate,
  onDateChange,
  onCancel,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [months, setMonths] = useState<Moment[]>(() => {
    let baseDate = initialStartDate ? initialStartDate.clone() : moment();
    let monthSet = new Set<string>();

    const initialMonths: Moment[] = [];

    // Add the month of initialStartDate (if it exists)
    if (initialStartDate) {
      const startMonth = initialStartDate.clone().startOf("month");
      monthSet.add(startMonth.format("YYYY-MM"));
      initialMonths.push(startMonth);
    }

    // Add the month of initialEndDate (if it's different)
    if (initialEndDate) {
      const endMonth = initialEndDate.clone().startOf("month");
      if (!monthSet.has(endMonth.format("YYYY-MM"))) {
        monthSet.add(endMonth.format("YYYY-MM"));
        initialMonths.push(endMonth);
      }
    }

    // Add previous and next months around the base date
    const prev = baseDate.clone().subtract(1, "month");
    const next = baseDate.clone().add(1, "month");

    if (!monthSet.has(prev.format("YYYY-MM"))) {
      monthSet.add(prev.format("YYYY-MM"));
      initialMonths.unshift(prev);
    }

    if (!monthSet.has(next.format("YYYY-MM"))) {
      monthSet.add(next.format("YYYY-MM"));
      initialMonths.push(next);
    }

    // Sort months in order
    initialMonths.sort((a, b) => a.diff(b));

    return initialMonths;
  });

  const onClose = () => {
    onCancel();
  };

  const [startDate, setStartDate] = useState<Moment | null>(
    initialStartDate || null
  );
  const [endDate, setEndDate] = useState<Moment | null>(initialEndDate || null);

  const prependMonths = (count: number) => {
    const firstMonth = months[0];
    const newMonths: Moment[] = [];

    for (let i = count; i > 0; i--) {
      newMonths.push(firstMonth.clone().subtract(i, "months"));
    }

    setMonths((prev) => [...newMonths, ...prev]);
  };

  const appendMonths = (count: number) => {
    const lastMonth = months[months.length - 1];
    const newMonths: Moment[] = [];

    for (let i = 1; i <= count; i++) {
      newMonths.push(lastMonth.clone().add(i, "months"));
    }

    setMonths((prev) => [...prev, ...newMonths]);
  };

  const handleScroll = useCallback(() => {
    const container = containerRef.current;
    if (!container) return;

    const { scrollTop, scrollHeight, clientHeight } = container;

    // Scroll Up (Top)
    if (scrollTop < 100) {
      prependMonths(1);
      container.scrollTop = 150; // adjust scroll position to avoid jumps
    }

    // Scroll Down (Bottom)
    if (scrollTop + clientHeight >= scrollHeight - 100) {
      appendMonths(1);
    }
  }, [months]);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    container.addEventListener("scroll", handleScroll);
    return () => container.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const handleDateClick = (day: Moment) => {
    if (!startDate || (startDate && endDate)) {
      setStartDate(day);
      setEndDate(null);
      onDateChange?.(day, null);
    } else if (!endDate) {
      if (day.isBefore(startDate, "day")) {
        setEndDate(startDate);
        setStartDate(day);
        onDateChange?.(day, startDate);
      } else {
        setEndDate(day);
        onDateChange?.(startDate, day);
      }
    }
  };

  useEffect(() => {
    setStartDate(initialStartDate || null);
    setEndDate(initialEndDate || null);
  }, [initialStartDate, initialEndDate]);

  if (!visible) return null;

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          zIndex: 999,
        }}
        onClick={onClose}
      />
      <div
        ref={containerRef}
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100vw",
          background: "#fff",
          borderRadius: "40px 40px 0 0",
          boxShadow: "0px -4px 12px rgba(0, 0, 0, 0.1)",
          height: "65vh",
          display: "flex",
          flexDirection: "column",
          zIndex: 1000,
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            position: "fixed",
            top: "63vh",
            right: "0",
            width:"100vw",
            zIndex: 10,
          }}
        >
          Calendar
          <div
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              zIndex: 1,
            }}
          >
            <Button
              type="text"
              icon={<CloseOutlined style={{ fontSize: 18, color: "#fff" }} />}
              onClick={onClose}
              style={{
                backgroundColor: "#cecece",
                boxShadow: "none",
                borderRadius: "50%",
              }}
            />
          </div>
        </div>
        {months.map((month, idx) => (
          <div key={idx} style={{ marginBottom: "32px" }}>
            <h3 style={{ textAlign: "center" }}>{month.format("YYYY/MM")}</h3>
            <MonthGrid
              monthDate={month}
              startDate={startDate}
              endDate={endDate}
              onDateClick={handleDateClick}
            />
          </div>
        ))}
      </div>
    </>
  );
};

interface MonthGridProps {
  monthDate: Moment;
  startDate: Moment | null;
  endDate: Moment | null;
  onDateClick: (date: Moment) => void;
}

const MonthGrid: React.FC<MonthGridProps> = ({
  monthDate,
  startDate,
  endDate,
  onDateClick,
}) => {
  const startOfMonth = monthDate.clone().startOf("month");
  const endOfMonth = monthDate.clone().endOf("month");

  // Start weekday (0: Sunday, 1: Monday, etc.)
  const startDayOfWeek = startOfMonth.day();
  const daysInMonth = monthDate.daysInMonth();

  // Empty slots before first day
  const blanks = Array.from({ length: startDayOfWeek });

  const days: Moment[] = [];
  for (let d = 1; d <= daysInMonth; d++) {
    days.push(moment(monthDate).date(d));
  }

  const isInRange = (day: Moment) => {
    if (!startDate || !endDate) return false;
    return day.isAfter(startDate, "day") && day.isBefore(endDate, "day");
  };

  const getLabel = (day: Moment) => {
    if (
      startDate &&
      endDate &&
      day.isSame(startDate, "day") &&
      day.isSame(endDate, "day")
    ) {
      return "Start/End";
    }
    if (startDate && day.isSame(startDate, "day")) {
      return "Start";
    }
    if (endDate && day.isSame(endDate, "day")) {
      return "End";
    }
    return "";
  };

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(7, 1fr)",
        gap: "4px",
      }}
    >
      {/* Weekday Labels */}
      {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
        <div
          key={day}
          style={{
            textAlign: "center",
            fontWeight: "bold",
            padding: "8px 0",
          }}
        >
          {day}
        </div>
      ))}

      {/* Blanks for days before the 1st of the month */}
      {blanks.map((_, i) => (
        <div key={`blank-${i}`} />
      ))}

      {/* Calendar Days */}
      {days.map((day) => {
        const label = getLabel(day);
        const isSelected = !!label;
        const inRange = isInRange(day);

        return (
          <div
            key={day.format("YYYY-MM-DD")}
            onClick={() => onDateClick(day)}
            style={{
              height: "60px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
              cursor: "pointer",
              backgroundColor: isSelected
                ? "#6B46C1"
                : inRange
                ? "#E9D8FD"
                : "#f0f0f0",
              color: isSelected ? "#fff" : "#000",
              border: isSelected ? "2px solid #6B46C1" : "1px solid #ccc",
            }}
          >
            <div>{day.date()}</div>
            {label && (
              <div style={{ fontSize: "12px", marginTop: "4px" }}>{label}</div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default InfiniteCalendar;
