import React, { useEffect, useState } from "react";
import { Modal, Select, Input, Button, Row, Col, message } from "antd";
import { get } from "../../../services/Apiclient";
import Datatable from "../../Datatable";
import { useCurrency } from "../../../contexts/CurrencyContext";

const { Option } = Select;

interface SelectedServiceCategoryModelProps {
  title: string;
  visible: boolean;
  onCancel: () => void;
  onSave: (selectedData: any[]) => void;
  selectedServices: any[];
  passedProductData?: any[];
}

const SelectedProductItemModel: React.FC<SelectedServiceCategoryModelProps> = ({
  title,
  visible,
  onCancel,
  onSave,
  selectedServices,
  passedProductData,
}) => {
  const { currencySymbol } = useCurrency();
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [serviceData, setServiceData] = useState<any[]>([]);
  const [categorycode, setCategorycode] = useState("null");
  const [searchText, setSearchText] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);

  // Fetch categories
  const getProductCategoryDropdown = async () => {
    try {
      const url = `/category/dropdown/type/${localStorage.getItem(
        "outlet_id"
      )}/Product`;
      const response = await get(url);
      if (response?.data?.success) {
        const data = [
          { id: "null", name: "All Categories" },
          ...response.data.data,
        ];
        setCategoryData(data);
      } else {
        console.error("Error fetching categories:", response.data.message);
      }
    } catch (error: any) {
      message.error("Error fetching categories.");
    }
  };

  // Fetch product items
  const getProductItems = async () => {
    try {
      const url = `/product/withoutpagesize/null/null/${localStorage.getItem(
        "outlet_id"
      )}/created_at/DESC/null`;
      const response = await get(url);
      if (response?.data?.success) {
        const formattedData = response.data.products.map((cat: any) => ({
          ...cat,
          key: cat.productid,
          category: cat.categoryid,
          servicecode: cat.productcode,
          servicename: cat.productname,
        }));
        setServiceData(formattedData);

        // Restore selected items if `selectedServices` is provided
        if (selectedServices && selectedServices.length > 0) {
          const keys = selectedServices.map((service) => service.productid);
          setSelectedRowKeys(keys);
        }
      } else {
        console.error("Error fetching products:", response.data.message);
      }
    } catch (error: any) {
      message.error("Error fetching products.");
    }
  };

  // Handle category change
  const handleCategoryChange = (value: string) => {
    setCategorycode(value);
  };

  // Handle save button click
  const handleSave = () => {
    const selectedData = serviceData.filter((item) =>
      selectedRowKeys.includes(item.key)
    );
    onSave(selectedData);
    onCancel();
  };

  // Effect to fetch data when modal is opened
  useEffect(() => {
    if (visible) {
      getProductItems();
      getProductCategoryDropdown();
    }
  }, [visible]);

  // Ensure selected items persist after modal reopens
  useEffect(() => {
    if (visible && selectedServices && selectedServices.length > 0) {
      const keys = selectedServices.map((service) => service.productid);
      setSelectedRowKeys(keys);
    } else {
      setSelectedRowKeys([]);
    }
  }, [visible, selectedServices]);

  // Table columns
  const columns = [
    {
      title: "Product Code",
      dataIndex: "productcode",
      key: "productcode",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Product Name",
      dataIndex: "productname",
      key: "productname",
      width: 350,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Category",
      dataIndex: "categoryname",
      key: "categoryname",
      width: 200,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 100,
      render: (sellprice: number) =>
        `${currencySymbol.CURRENCY_SYMBOL}${sellprice}`,
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      width: 200,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
  ];

  return (
    <Modal
      title={title}
      visible={visible}
      onCancel={onCancel}
      footer={
        <div
          style={{
            marginTop: 16,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <strong>Selected:</strong> {selectedRowKeys.length} items
          </div>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      }
      width={"100%"}
      style={{ top: "10px", maxWidth: "1240px" }}
      bodyStyle={{
        height: "calc(100vh - 160px)",
        scrollbarWidth: "thin",
        overflowX: "hidden",
        overflowY: "auto",
      }}
    >
      {/* Filters and Search */}
      <Row gutter={16} style={{ marginBottom: 16 }}>
        <Col span={8}>
          <Select
            placeholder="Please select category"
            value={categorycode}
            onChange={handleCategoryChange}
            style={{ width: "100%" }}
            dropdownStyle={{ textAlign: "center" }}
          >
            {categoryData.map((cate) => (
              <Option value={cate.id} key={cate.id}>
                {cate.name}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>
          <Input
            placeholder="Select by Service name / code"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ width: "100%" }}
            allowClear
          />
        </Col>
      </Row>

      {/* Data Table */}
      <Datatable<any>
        columns={columns}
        dataSource={serviceData}
        scroll={{ y: "calc(100vh - 260px)" }}
        rowSelection={{
          selectedRowKeys,
          onChange: (keys) => setSelectedRowKeys(keys as string[]),
        }}
        showCheckbox
        showPagination={false}
      />
    </Modal>
  );
};

export default SelectedProductItemModel;
