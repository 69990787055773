import React, { useState, useEffect } from "react";
import {
  Table,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
  Tag,
  Space,
  Typography,
  Card,
  Pagination,
  Input,
} from "antd";
import {
  ArrowLeftOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  UndoOutlined,
  UnorderedListOutlined,
  UpOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ColumnsReportsModal from "../../../components/model/ColumnsReportsModal";
import { useCurrency } from "../../../contexts/CurrencyContext";

const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title, Text } = Typography;

const GiftcardSalesReport = () => {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const [filters, setFilters] = useState({
    outlet: null,
    dateRange: [],
    billType: null,
    paymentType: null,
    customerSource: null,
    department: null,
    searchRef: "",
    searchCustomer: "",
  });

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });

  const outletId = localStorage.getItem("outlet_id");
  const [selectedOutlet, setSelectedOutlet] = useState<string | null>(outletId);
  const [selectedStaff, setSelectedStaff] = useState<string | null>(null);
  const [outlet, setOutlet] = useState<any[]>([]);
  const [staff, setStaff] = useState<any[]>([]);
  const [departments, setDepartments] = useState<any[]>([]);
  const [designations, setDesignations] = useState<any[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string | null>(
    null
  );
  const [selectedDesignation, setSelectedDesignation] = useState<string | null>(
    null
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);

  const currentWeekStart = dayjs().startOf("isoWeek"); // Monday
  const currentWeekEnd = dayjs().endOf("isoWeek"); // Sunday
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    currentWeekStart,
    currentWeekEnd,
  ]);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const [isFolded, setIsFolded] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);

  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1);
  };

  const handlePageSizeChange = (value: number) => {
    setPageSize(value);
    setCurrentPage(0);
  };

  const handleReload = () => {
    console.log("Reload triggered");
    setDateRange([currentWeekStart, currentWeekEnd]);
    setSelectedStaff(null);
    setSelectedDepartment(null);
    setSelectedDesignation(null);
    setPaymentMethod(null);
    setSelectedOutlet(outletId);
    setSearchValue(null);
    setCurrentPage(0);
    setPageSize(10);
  };

  const handleRunFilter = () => {
    console.log("Filters applied:", {
      selectedOutlet,
      dateRange,
      paymentMethod,
      selectedDepartment,
      selectedDesignation,
      selectedStaff,
      searchValue,
      pageSize,
      offset: currentPage,
    });
    setCurrentPage(0);
  };

  const handleFold = () => {
    setIsFolded(!isFolded);
  };

  const handleColumnsClick = () => {
    setIsModalVisible(true);
  };

  // Simulated API call
  const fetchData = () => {
    const result: any = mockData.slice(
      (pagination.current - 1) * pagination.pageSize,
      pagination.current * pagination.pageSize
    );
    setData(result);
    setPagination((prev) => ({ ...prev, total: mockData.length }));
  };

  useEffect(() => {
    fetchData();
  }, [pagination.current, filters]);

  const initialColumns = [
    {
      title: "Bill Type",
      dataIndex: "bill_type",
      key: "bill_type",
      fixed: "left" as "left",
      width: 150,
    },
    {
      title: "Ref No",
      dataIndex: "ref_no",
      key: "ref_no",
      fixed: "left" as "left",
      width: 150,
      render: (text: any) => <a>{text}</a>,
    },
    { title: "Customer", dataIndex: "customer", key: "customer", width: 150 },
    {
      title: "Customer Code",
      dataIndex: "customer_code",
      key: "customer_code",
      width: 150,
    },
    {
      title: "Customer Source",
      dataIndex: "customer_source",
      key: "customer_source",
      width: 150,
    },
    { title: "Referrer", dataIndex: "referrer", key: "referrer", width: 150 },
    {
      title: "Total Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      width: 150,
    },
    {
      title: "Accounting Date",
      dataIndex: "accounting_date",
      key: "accounting_date",
      width: 150,
    },
    { title: "Cashier", dataIndex: "cashier", key: "cashier", width: 150 },
    {
      title: "Items",
      key: "items",
      width: 200,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items.map((item: any, i: any) => (
            <div
              key={i}
              style={{
                padding: "15px",
                borderBottom:
                  i !== record.items.length - 1 ? "1px solid #e0e0e0" : "none",
              }}
            >
              <strong>{item.item_name}</strong>
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: "Unit Price",
      key: "unit_price",
      width: 200,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items.map((item: any, i: any) => (
            <div
              key={i}
              style={{
                padding: "15px",
                borderBottom:
                  i !== record.items.length - 1 ? "1px solid #e0e0e0" : "none",
              }}
            >
              {item.unit_price}
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: "Qty",
      key: "qty",
      width: 150,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items.map((item: any, i: any) => (
            <div
              key={i}
              style={{
                padding: "15px",
                borderBottom:
                  i !== record.items.length - 1 ? "1px solid #e0e0e0" : "none",
              }}
            >
              {item.qty}
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: "Paid",
      key: "paid",
      width: 150,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items.map((item: any, i: any) => (
            <div
              key={i}
              style={{
                padding: "15px",
                borderBottom:
                  i !== record.items.length - 1 ? "1px solid #e0e0e0" : "none",
              }}
            >
              {item.paid}
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: "Payment Method",
      key: "payment_method",
      width: 200,
      render: (_: any, record: any) => (
        <Space
          direction="vertical"
          style={{ width: "100%", background: "#f6f5fa" }}
        >
          {record.items.map((item: any, i: any) => (
            <div
              key={i}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "15px",
                borderBottom:
                  i !== record.items.length - 1 ? "1px solid #e0e0e0" : "none",
              }}
            >
              <span>{item.payment_method}</span>
              <span>({item.collected_payment})</span>
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: "Collected Payment",
      key: "collected_payment",
      width: 250,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items.map((item: any, i: any) => (
            <div
              key={i}
              style={{
                padding: "15px",
                borderBottom:
                  i !== record.items.length - 1 ? "1px solid #e0e0e0" : "none",
              }}
            >
              {item.collected_payment}
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: "Privilege Payment",
      key: "privilege_payment",
      width: 250,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items.map((item: any, i: any) => (
            <div
              key={i}
              style={{
                padding: "15px",
                borderBottom:
                  i !== record.items.length - 1 ? "1px solid #e0e0e0" : "none",
              }}
            >
              {item.privilege_payment}
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: "Staff",
      key: "staff",
      width: 250,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items.map((item: any, i: any) => (
            <div
              key={i}
              style={{
                padding: "15px",
                borderBottom:
                  i !== record.items.length - 1 ? "1px solid #e0e0e0" : "none",
              }}
            >
              {item.staff}
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: "Department",
      key: "department",
      width: 150,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items.map((item: any, i: any) => (
            <div
              key={i}
              style={{
                padding: "15px",
                borderBottom:
                  i !== record.items.length - 1 ? "1px solid #e0e0e0" : "none",
              }}
            >
              {item.department}
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: "Taxes",
      key: "taxes",
      width: 150,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items.map((item: any, i: any) => (
            <div
              key={i}
              style={{
                padding: "15px",
                borderBottom:
                  i !== record.items.length - 1 ? "1px solid #e0e0e0" : "none",
              }}
            >
              {item.taxes}
            </div>
          ))}
        </Space>
      ),
    },
    {
      title: "Note",
      key: "note",
      width: 200,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items.map((item: any, i: any) => (
            <div
              key={i}
              style={{
                padding: "15px",
                borderBottom:
                  i !== record.items.length - 1 ? "1px solid #e0e0e0" : "none",
              }}
            >
              {item.note}
            </div>
          ))}
        </Space>
      ),
    },
  ];

  const [columns, setColumns] = useState(initialColumns);

  const mandatoryColumns = ["bill_type", "ref_no"];

  // Initialize selectedColumns with all column keys by default
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key)
  );

  const [lastSelectedColumns, setLastSelectedColumns] = useState<string[]>([]);

  const displayedColumns = columns.filter((col) =>
    selectedColumns.includes(col.key)
  );

  const handleModalSave = (
    selectedCols: string[],
    reorderedColumns: { key: string; title: string }[]
  ) => {
    console.log(reorderedColumns, "reorderedColumns");
    setSelectedColumns(selectedCols);
    setLastSelectedColumns(selectedCols);
    setColumns(
      reorderedColumns.map((col: any) => ({
        ...col,
        dataIndex: col.key, // Assuming `dataIndex` can be derived from `key`
      }))
    );
  };

  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      background: "linear-gradient(90deg, #6a11cb, #2575fc)",
      color: "#fff",
      borderRadius: "10px",
      marginBottom: "16px",
      height: "60px",
      display: "flex",
      alignItems: "center",
      padding: "0 50px",
      position: "relative",
      gap: "40px",
      overflowX: "scroll",
      whiteSpace: "nowrap",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      zIndex: 2,
      textAlign: "center",
    },
    label: {
      fontSize: "12px",
      fontWeight: "500",
      opacity: 0.9,
      color: "#caa2ff",
    },
    amount: {
      margin: 0,
      fontSize: "16px",
      color: "#fff",
      // animation: "fadeIn 1s ease-in-out infinite alternate",
    },
    transparentText: {
      position: "fixed",
      right: "60px",
      fontSize: "40px",
      fontWeight: "lighter",
      color: "rgba(255, 255, 255, 0.2)",
      textTransform: "uppercase",
      letterSpacing: "2px",
      zIndex: 1,
    },
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value || null);
  };

  return (
    <div style={{ padding: 24 }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate("/reports")}
          >
            <ArrowLeftOutlined
              style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
            />
            <Title level={4} style={{ margin: 0 }}>
              Gift Card Sales Report
            </Title>
          </div>
        </Col>
        <Col>
          <Space>
            {/* <Button style={{ height: 40 }} icon={<ExportOutlined />}>Export as</Button> */}
            <Button
              icon={<UnorderedListOutlined />}
              onClick={handleColumnsClick}
              style={{ height: 40 }}
            >
              Columns
            </Button>
          </Space>
        </Col>
      </Row>

      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#f6f7f9",
          borderRadius: "12px",
          padding: "24px 24px 10px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
        }}
      >
        <>
          <div style={{ marginBottom: "16px", borderRadius: "8px" }}>
            <Row gutter={16} justify="start" style={{ marginBottom: "16px" }}>
              <Col>
                <Select
                  value={selectedOutlet === null ? "all" : selectedOutlet}
                  onChange={(value) =>
                    setSelectedOutlet(value === "all" ? null : value)
                  }
                  placeholder="Select outlet"
                  style={{ width: 200, height: "46px", borderRadius: "4px" }}
                  allowClear
                >
                  <Option value="all">All outlets</Option>
                  {outlet.map((outlet) => (
                    <Option key={outlet.id} value={outlet.id}>
                      {outlet.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col>
                <RangePicker
                  value={dateRange}
                  onChange={(dates) =>
                    dates && setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])
                  }
                  format="DD/MM/YYYY"
                  style={{
                    width: "416px",
                    height: "46px",
                    borderRadius: "4px",
                  }}
                  allowClear={false}
                  className="custom-range-picker"
                />
              </Col>
              <Col>
                <Select
                  value={paymentMethod === null ? "all" : paymentMethod}
                  onChange={(value) =>
                    setPaymentMethod(value === "all" ? null : value)
                  }
                  placeholder="Select Bill type"
                  style={{
                    width: "200px",
                    height: "46px",
                    borderRadius: "4px",
                  }}
                  allowClear
                >
                  <Option value="all">All Bill types</Option>
                  <Option value="Sales">Sales Bill</Option>
                  <Option value="Repayment">Repayment Bill</Option>
                </Select>
              </Col>
              <Col>
                <Select
                  value={paymentMethod === null ? "all" : paymentMethod}
                  onChange={(value) =>
                    setPaymentMethod(value === "all" ? null : value)
                  }
                  placeholder="Select payment method"
                  style={{
                    width: "200px",
                    height: "46px",
                    borderRadius: "4px",
                  }}
                  allowClear
                >
                  <Option value="all">All payments</Option>
                  <Option value="Cash">Cash</Option>
                  <Option value="Package">Package</Option>
                  <Option value="Billing on account">Billing on account</Option>
                  <Option value="Discount">Discount</Option>
                  <Option value="Giftcard">Giftcard</Option>
                  <Option value="kiv">kiv</Option>
                  <Option value="Card">Card</Option>
                  <Option value="Course">Course</Option>
                  <Option value="Prepaid">Prepaid</Option>
                  <Option value="Visit-Based">Visit-Based</Option>
                </Select>
              </Col>
            </Row>
            {!isFolded && (
              <>
                <Row
                  gutter={16}
                  justify="start"
                  style={{ marginBottom: "16px" }}
                >
                  <Col>
                    <Select
                      value={
                        selectedDepartment === null ? "all" : selectedDepartment
                      }
                      onChange={(value) =>
                        setSelectedDepartment(value === "all" ? null : value)
                      }
                      placeholder="Select customer source"
                      style={{
                        width: 200,
                        height: "46px",
                        borderRadius: "4px",
                      }}
                      allowClear
                    >
                      <Option value="all">All customer sources</Option>
                      {departments.map((dept) => (
                        <Option key={dept.id} value={dept.id}>
                          {dept.departmenttype}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col>
                    <Select
                      value={
                        selectedDepartment === null ? "all" : selectedDepartment
                      }
                      onChange={(value) =>
                        setSelectedDepartment(value === "all" ? null : value)
                      }
                      placeholder="Select department"
                      style={{
                        width: 200,
                        height: "46px",
                        borderRadius: "4px",
                      }}
                      allowClear
                    >
                      <Option value="all">All departments</Option>
                      {departments.map((dept) => (
                        <Option key={dept.id} value={dept.id}>
                          {dept.departmenttype}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col>
                    <Select
                      value={
                        selectedDesignation === null
                          ? "all"
                          : selectedDesignation
                      }
                      onChange={(value) =>
                        setSelectedDesignation(value === "all" ? null : value)
                      }
                      placeholder="Select designation"
                      style={{
                        width: 200,
                        height: "46px",
                        borderRadius: "4px",
                      }}
                      allowClear
                    >
                      <Option value="all">All designations</Option>
                      {designations.map((designation) => (
                        <Option key={designation.id} value={designation.id}>
                          {designation.designationtype}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <Select
                      value={selectedStaff === null ? "all" : selectedStaff}
                      onChange={(value) =>
                        setSelectedStaff(value === "all" ? null : value)
                      }
                      placeholder="Select staff"
                      style={{
                        width: 200,
                        height: "46px",
                        borderRadius: "4px",
                      }}
                      allowClear
                    >
                      <Option value="all">All staff</Option>
                      {staff.map((staff) => (
                        <Option key={staff.id} value={staff.id}>
                          {staff.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <Select
                      value={selectedStaff === null ? "all" : selectedStaff}
                      onChange={(value) =>
                        setSelectedStaff(value === "all" ? null : value)
                      }
                      placeholder="Select cashiers"
                      style={{
                        width: 200,
                        height: "46px",
                        borderRadius: "4px",
                      }}
                      allowClear
                    >
                      <Option value="all">All cashiers</Option>
                      <Option value="admin">Admin</Option>
                    </Select>
                  </Col>
                </Row>
              </>
            )}
            <Row gutter={16} justify="start" align="middle">
              {!isFolded && (
                <>
                  <Col>
                    <Input
                      placeholder="Search by item name / code..."
                      style={{ width: 200, height: "46px", borderRadius: 4 }}
                      value={searchValue || ""}
                      onChange={handleSearchChange}
                      onPressEnter={handleRunFilter} // Trigger search on Enter key
                      allowClear
                    />
                  </Col>
                  <Col>
                    <Input
                      placeholder="Search by ref#"
                      style={{ width: 200, height: "46px", borderRadius: 4 }}
                      value={searchValue || ""}
                      onChange={handleSearchChange}
                      onPressEnter={handleRunFilter} // Trigger search on Enter key
                      allowClear
                    />
                  </Col>
                  <Col>
                    <Input
                      placeholder="Search by customer code"
                      style={{ width: 200, height: "46px", borderRadius: 4 }}
                      value={searchValue || ""}
                      onChange={handleSearchChange}
                      onPressEnter={handleRunFilter} // Trigger search on Enter key
                      allowClear
                    />
                  </Col>
                  <Col style={{ display: "flex", justifyContent: "center" }}>
                    <Select
                      value={selectedStaff === null ? "all" : selectedStaff}
                      onChange={(value) =>
                        setSelectedStaff(value === "all" ? null : value)
                      }
                      placeholder="Select referer"
                      style={{
                        width: 200,
                        height: "46px",
                        borderRadius: "4px",
                      }}
                      allowClear
                    >
                      <Option value="all">All referers</Option>
                    </Select>
                  </Col>
                </>
              )}
              <Col style={{ marginLeft: "auto" }}>
                <Space>
                  <Button
                    style={{ height: "46px", width: "80px" }}
                    type="primary"
                    onClick={handleRunFilter}
                  >
                    Run
                  </Button>
                  <Button
                    style={{
                      border: "none",
                      background: "#f6f7f9",
                      color: "#717ef1",
                      fontWeight: 600,
                      height: "46px",
                      width: "80px",
                    }}
                    onClick={handleFold}
                  >
                    {isFolded ? (
                      <>
                        Expand{" "}
                        <span
                          style={{
                            display: "inline-flex",
                            flexDirection: "column",
                          }}
                        >
                          <DownOutlined style={{ marginBottom: "-7px" }} />
                          <DownOutlined />
                        </span>
                      </>
                    ) : (
                      <>
                        Fold{" "}
                        <span
                          style={{
                            display: "inline-flex",
                            flexDirection: "column",
                          }}
                        >
                          <UpOutlined style={{ marginBottom: "-7px" }} />
                          <UpOutlined />
                        </span>
                      </>
                    )}
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>

          <div style={styles.container} className="total-container">
            <div style={styles.textContainer}>
              <Text style={styles.label}>Number of items</Text>
              <Text style={styles.amount}>2</Text>
            </div>
            <div style={styles.textContainer}>
              <Text style={styles.label}>Paid</Text>
              <Text style={styles.amount}>{currencySymbol.CURRENCY_SYMBOL}{totalAmount.toFixed(2)}</Text>
            </div>
            <div style={styles.textContainer}>
              <Text style={styles.label}>Services & Product</Text>
              <Text style={styles.amount}>{currencySymbol.CURRENCY_SYMBOL}{totalAmount.toFixed(2)}</Text>
            </div>
            <div style={styles.textContainer}>
              <Text style={styles.label}>Card sales</Text>
              <Text style={styles.amount}>{currencySymbol.CURRENCY_SYMBOL}{totalAmount.toFixed(2)}</Text>
            </div>
            <div style={styles.textContainer}>
              <Text style={styles.label}>Taxes</Text>
              <Text style={styles.amount}>{currencySymbol.CURRENCY_SYMBOL}{totalAmount.toFixed(2)}</Text>
            </div>
            <div style={styles.textContainer}>
              <Text style={styles.label}>Tips</Text>
              <Text style={styles.amount}>{currencySymbol.CURRENCY_SYMBOL}{totalAmount.toFixed(2)}</Text>
            </div>
            <div style={styles.textContainer}>
              <Text style={styles.label}>Service charge</Text>
              <Text style={styles.amount}>{currencySymbol.CURRENCY_SYMBOL}{totalAmount.toFixed(2)}</Text>
            </div>
            <div style={styles.textContainer}>
              <Text style={styles.label}>Collected payment</Text>
              <Text style={styles.amount}>{currencySymbol.CURRENCY_SYMBOL}{totalAmount.toFixed(2)}</Text>
            </div>
            <div style={styles.textContainer}>
              <Text style={styles.label}>Privilege payment</Text>
              <Text style={styles.amount}>{currencySymbol.CURRENCY_SYMBOL}{totalAmount.toFixed(2)}</Text>
            </div>
            <div style={styles.textContainer}>
              <Text style={styles.label}>Free</Text>
              <Text style={styles.amount}>{currencySymbol.CURRENCY_SYMBOL}{totalAmount.toFixed(2)}</Text>
            </div>
            <div style={styles.textContainer}>
              <Text style={styles.label}>ONLINE TRANSFER</Text>
              <Text style={styles.amount}>{currencySymbol.CURRENCY_SYMBOL}{totalAmount.toFixed(2)}</Text>
            </div>
            <Text style={styles.transparentText}>TOTAL</Text>
          </div>

          {/* Table */}
          <Table
            columns={displayedColumns}
            dataSource={data}
            rowKey="ref_no"
            className="billsummary-table"
            // pagination={{
            //   total: pagination.total,
            //   current: pagination.current,
            //   pageSize: pagination.pageSize,
            //   showSizeChanger: true,
            //   showQuickJumper: false,
            //   pageSizeOptions: ["10", "20", "50", "100"],
            //   showTotal: (total, range) => `Total ${total} items`,
            //   onChange: (page, pageSize) => {
            //     setPagination((prev) => ({
            //       ...prev,
            //       current: page,
            //       pageSize,
            //     }));
            //   },
            // }}
            pagination={false}
            scroll={{ x: 1600 }}
          />
          <Row
            justify="space-between"
            style={{
              alignItems: "center",
              marginTop: "40px",
              marginBottom: "16px",
            }}
          >
            <Col>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
              >
                <Option value={10}>10 entries/page</Option>
                <Option value={20}>20 entries/page</Option>
                <Option value={50}>50 entries/page</Option>
                <Option value={100}>100 entries/page</Option>
              </Select>
            </Col>

            {totalRecords >= 0 && (
              <Col>
                <Row justify="end" align="middle" style={{ gap: "16px" }}>
                  <Col>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(0,0,0,.85)",
                      }}
                    >
                      Total {totalRecords} items
                    </span>
                  </Col>
                  <Col>
                    <Pagination
                      current={currentPage + 1}
                      pageSize={pageSize}
                      total={totalRecords}
                      onChange={handlePageChange}
                      showSizeChanger={false}
                      itemRender={(page, type, originalElement) => {
                        if (type === "prev") {
                          const isDisabled = currentPage === 0;
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <LeftOutlined />
                              <span>Previous</span>
                            </a>
                          );
                        }
                        if (type === "next") {
                          const isDisabled =
                            currentPage ===
                            Math.ceil(totalRecords / pageSize) - 1;
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <span>Next</span>
                              <RightOutlined />
                            </a>
                          );
                        }
                        return originalElement;
                      }}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </>
      </Content>
      <ColumnsReportsModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onSave={handleModalSave}
        availableColumns={columns}
        initialSelectedColumns={selectedColumns}
        lastSelectedColumns={lastSelectedColumns}
        mandatoryColumns={mandatoryColumns}
      />
    </div>
  );
};

export default GiftcardSalesReport;

// ✅ Updated Mock Data: Each bill has multiple items
const mockData = [
  {
    bill_type: "Sales Bill",
    ref_no: "202527008",
    customer: "Elizabeth",
    customer_code: "250272004",
    customer_source: "Walk-in",
    referrer: "-",
    total_amount: "RM20",
    accounting_date: "27/02/2025",
    cashier: "admin",
    items: [
      {
        item_name: "TYJH Radiant",
        unit_price: "RM578",
        qty: 2,
        paid: "RM0",
        payment_method: "Free",
        collected_payment: "RM0",
        privilege_payment: "RM0",
        staff: "LAM.LAM",
        department: "Skin",
        taxes: "RM0",
        note: "-",
      },
      {
        item_name: "Nano Needle",
        unit_price: "RM2",
        qty: 10,
        paid: "RM20",
        payment_method: "Cash",
        collected_payment: "RM20",
        privilege_payment: "RM0",
        staff: "LAM.LAM",
        department: "Skin",
        taxes: "RM0",
        note: "-",
      },
    ],
  },
  {
    bill_type: "Sales Bill",
    ref_no: "202527007",
    customer: "CHONG SUI KEN",
    customer_code: "250272003",
    customer_source: "Walk-in",
    referrer: "-",
    total_amount: "RM0",
    accounting_date: "27/02/2025",
    cashier: "admin",
    items: [
      {
        item_name: "Meiffe Silky 100 30ml",
        unit_price: "RM233",
        qty: 1,
        paid: "RM0",
        payment_method: "Free",
        collected_payment: "RM0",
        privilege_payment: "RM0",
        staff: "LAM.LAM",
        department: "Skin",
        taxes: "RM0",
        note: "-",
      },
    ],
  },
];
