import * as React from "react";
import { Button, Row, Col, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;

const { Title, Text } = Typography;

export default function NotFound(): React.JSX.Element {
  const navigate = useNavigate();

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Row
        justify="center"
        align="middle"
        style={{
          minHeight: "100vh",
          textAlign: "center",
        }}
      >
        <Col span={12}>
          <Title level={3}>
            404: The page you are looking for isn&apos;t here
          </Title>
          <Text type="secondary">
            You either tried some shady route or you came here by mistake.
            Whichever it is, try using the navigation.
          </Text>
          <br />
          <Button
            type="primary"
            onClick={() => navigate("/")}
            style={{ marginTop: "20px" }}
          >
            Go back to home
          </Button>
        </Col>
      </Row>
    </>
  );
}
