import { Col, Layout, Row, Typography } from "antd";
import { Helmet } from "react-helmet";
import { getPermissions } from "../../layouts/permissionsHelper";
import SettingCard from "./SettingCard";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;

const { Content } = Layout;
const { Title } = Typography;

const commondata = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_CommonData.png`;
const businessdetails = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_BusinessDetails.png`;
const productmanagement = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_ProductManagement.png`;
const servicemanagement = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_ServiceManagement.png`;
const roommanagement = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_RoomManagement.png`;
const staffmanagement = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_StaffList.png`;
const giftcard = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_GiftCards.png`;
const memberships = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_Memberships.png`;
const packages = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SettingMenu_Packages.png`;
const worktags = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Setting_Work_Tag.png`;

const settingsSections = [
  {
    title: "Basic Settings",
    settings: [
      {
        title: "Common Data",
        Image: commondata,
        link: "/common-data",
        bgColor: "#1e90ff",
        permissionKey: "commonData",
      },
      {
        title: "Business Details",
        Image: businessdetails,
        link: "/settings/business-details",
        bgColor: "#1e90ff",
        permissionKey: "businessDetails",
      },
      {
        title: "Product Management",
        Image: productmanagement,
        link: "product/list",
        bgColor: "#1e90ff",
        permissionKey: "productManagement",
      },
      {
        title: "Service Management",
        Image: servicemanagement,
        link: "service/list",
        bgColor: "#1e90ff",
        permissionKey: "serviceManagement",
      },
      {
        title: "Room Management",
        Image: roommanagement,
        link: "room/list",
        bgColor: "#1e90ff",
        permissionKey: "roomManagement",
      },
    ],
  },
  {
    title: "Privilege Settings",
    settings: [
      {
        title: "Memberships",
        Image: memberships,
        link: "membership/list",
        bgColor: "#ff6347",
        permissionKey: "memberships",
      },
      {
        title: "Gift Cards",
        Image: giftcard,
        link: "giftCards/list",
        bgColor: "#ff6347",
        permissionKey: "giftCards",
      },
      {
        title: "Packages",
        Image: packages,
        link: "packages/list",
        bgColor: "#ff6347",
        permissionKey: "packages",
      },
    ],
  },
  {
    title: "Staff Management",
    settings: [
      {
        title: "Staff List",
        Image: staffmanagement,
        link: "staff/list",
        bgColor: "#ff6347",
        permissionKey: "staffList",
      },
      {
        title: "Shift Scheduling",
        Image: staffmanagement,
        link: "staffScheduling/list",
        bgColor: "#ff6347",
        permissionKey: "shiftScheduling",
      },
      {
        title: "Time off",
        Image: staffmanagement,
        link: "timeOff/list",
        bgColor: "#ff6347",
        permissionKey: "timeOff",
      },
      {
        title: "Overtime",
        Image: staffmanagement,
        link: "overTime/list",
        bgColor: "#ff6347",
        permissionKey: "overTime",
      },
      {
        title: "Attendance",
        Image: staffmanagement,
        link: "attendance/list",
        bgColor: "#ff6347",
        permissionKey: "attendance",
      },
      {
        title: "Performance",
        Image: staffmanagement,
        link: "performance/list",
        bgColor: "#ff6347",
        permissionKey: "performance",
      },
    ],
  },
  {
    title: "Personalization",
    settings: [
      {
        title: "Work Tags",
        Image: worktags,
        link: "worktags",
        bgColor: "#ff6347",
        permissionKey: "workTags",
      },
    ],
  },
];

function Settings() {
  const userRole = localStorage.getItem("userRole");

  // Function to check if card should be displayed
  const isCardVisible = (permissionKey: string) => {
    // Allow all cards if not Staff
    if (userRole !== "Staff") return true;
    // Check permission for Staff
    return getPermissions()[permissionKey] || false;
  };

  // Function to check if a section has visible cards
  const isSectionVisible = (section: any) => {
    return section.settings.some((setting: any) =>
      isCardVisible(setting.permissionKey)
    );
  };

  // Render Settings Sections
  const renderSettingsSection = ({ title, settings }: any, index: any) => (
    <div key={index}>
      <Title level={4} style={{ marginBottom: "16px" }}>
        {title}
      </Title>
      <Row gutter={[12, 12]}>
        {settings
          .filter((setting: any) => isCardVisible(setting.permissionKey))
          .map((setting: any, idx: any) => (
            <Col
              key={idx}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <SettingCard
                icon={setting.icon}
                title={setting.title}
                link={setting.link}
                bgColor={setting.bgColor}
                Image={setting.Image}
              />
            </Col>
          ))}
      </Row>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Layout>
        <Row justify="space-between" align="middle">
          <Col>
            <h2>Settings</h2>
          </Col>
        </Row>

        <Content
          style={{
            backgroundColor: "#f6f7f9",
            borderRadius: "12px",
            padding: "0px 24px 24px 24px",
            border: "1px solid #e0e7fe",
            height: "calc(100vh - 180px)",
            overflowY: "auto",
          }}
        >
          {settingsSections
            .filter(isSectionVisible) // Filter out sections without visible cards
            .map(renderSettingsSection)}
        </Content>
      </Layout>
    </>
  );
}

export default Settings;
