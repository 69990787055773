import {
  ArrowLeftOutlined,
  DollarOutlined,
  ExportOutlined,
  LeftOutlined,
  RightOutlined,
  UndoOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Pagination,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Datatable from "../../../components/Datatable";
import { get } from "../../../services/Apiclient";
import ColumnsReportsModal from "../../../components/model/ColumnsReportsModal";
import { useCurrency } from "../../../contexts/CurrencyContext";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface DataSourceItem {
  isSummaryRow?: boolean;
  key: string;
  staffCode?: string;
  staffName?: string;
  shift_duration?: string;
  timeoff_duration?: string;
  overtime_duration?: string;
  attendancedays?: number;
  totalworkinghours?: string;
  basesalary?: string;
}

const WorkingHoursSummary: React.FC = () => {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const outletId = localStorage.getItem("outlet_id");
  const currentMonthStart = dayjs().startOf("month"); // Changed to month start
  const currentMonthEnd = dayjs().endOf("month");     // Changed to month end
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    currentMonthStart,
    currentMonthEnd,
  ]);
  const [outlet, setOutlet] = useState<any[]>([]);
  const [staff, setStaff] = useState<any[]>([]);
  const [departments, setDepartments] = useState<any[]>([]);
  const [designations, setDesignations] = useState<any[]>([]);
  const [selectedOutlet, setSelectedOutlet] = useState<string | null>(null);
  const [selectedStaff, setSelectedStaff] = useState<string | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<string | null>(
    null
  );
  const [selectedDesignation, setSelectedDesignation] = useState<string | null>(
    null
  );
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState<DataSourceItem[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const initialColumns = [
    {
      title: "Staff code",
      dataIndex: "staffCode",
      key: "staffCode",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? <div>Total</div> : value,
    },
    {
      title: "Staff name",
      dataIndex: "staffName",
      key: "staffName",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? <div></div> : value,
    },
    {
      title: "Scheduled",
      dataIndex: "shift_duration",
      key: "shift_duration",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? <div>{value}</div> : value,
    },
    {
      title: "Time off",
      dataIndex: "timeoff_duration",
      key: "timeoff_duration",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? <div>{value}</div> : value,
    },
    {
      title: "Overtime",
      dataIndex: "overtime_duration",
      key: "overtime_duration",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? <div>{value}</div> : value,
    },
    {
      title: "Attendance days",
      dataIndex: "attendancedays",
      key: "attendancedays",
      width: 150,
      render: (value: number, record: DataSourceItem) =>
        record.isSummaryRow ? <div>{value}</div> : value,
    },
    {
      title: "Total working hours",
      dataIndex: "totalworkinghours",
      key: "totalworkinghours",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? <div>{value}</div> : value,
    },
    {
      title: (
        <span>
          Base salary <DollarOutlined style={{ marginLeft: 4 }} />
        </span>
      ),
      dataIndex: "basesalary",
      key: "basesalary",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? <div>{value}</div> : value,
    },
  ];

  // Define mandatory columns that cannot be deselected
  const mandatoryColumns = ["staffName"];

  // State for columns order
  const [columns, setColumns] = useState<
    {
      title: string | JSX.Element;
      dataIndex: string;
      key: string;
      width: number;
      render: (value: any, record: DataSourceItem) => React.ReactNode;
    }[]
  >(initialColumns);

  // Initialize selectedColumns with all column keys by default
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key)
  );

  // Track the last saved column selection
  const [lastSelectedColumns, setLastSelectedColumns] = useState<string[]>([]);

  // Transform columns for ColumnsReportsModal to match expected type
  const availableColumnsForModal = columns.map((col) => ({
    key: col.key,
    title: col.key === "basesalary" ? "Base salary" : (col.title as string), // Convert JSX title to string for "Base salary"
  }));

  // Filter columns based on selectedColumns and maintain order
  const displayedColumns = columns.filter((col) =>
    selectedColumns.includes(col.key)
  );

  // Row selection for Datatable
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys: React.Key[]) => setSelectedRowKeys(selectedKeys),
  };

  // Fetch dropdown data
  const getOutletData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/outlet/dropdown`);
      if (response?.data?.success && response.data.data) {
        setOutlet(response.data.data);
        const outletExists = response.data.data.some(
          (item: any) => item.id === outletId
        );
        if (outletExists) {
          setSelectedOutlet(outletId);
        } else {
          setSelectedOutlet(response.data.data[0]?.id || null);
        }
      }
    } catch (error) {
      console.error("Error fetching outlet:", error);
    }
  };

  const getStaffData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/staff/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setStaff(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching staff:", error);
    }
  };

  const getDepartmentData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/department/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setDepartments(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching department:", error);
    }
  };

  const getDesignationData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/designation/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setDesignations(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching designation:", error);
    }
  };

  // Fetch working hours summary data
  const fetchWorkingHoursSummary = async () => {
    try {
      const startDate = dateRange[0]
        ? dateRange[0].format("DD-MM-YYYY")
        : "NULL";
      const endDate = dateRange[1] ? dateRange[1].format("DD-MM-YYYY") : "NULL";

      const outletParam =
        selectedOutlet === "all" || !selectedOutlet ? "NULL" : selectedOutlet;
      const staffParam =
        selectedStaff === "all" || !selectedStaff ? "NULL" : selectedStaff;
      const designationParam =
        selectedDesignation === "all" || !selectedDesignation
          ? "NULL"
          : selectedDesignation;
      const departmentParam =
        selectedDepartment === "all" || !selectedDepartment
          ? "NULL"
          : selectedDepartment;

      const offset = currentPage;

      const endpoint = `/report/workinghoursummary/${startDate}/${endDate}/${outletParam}/${staffParam}/${designationParam}/${departmentParam}/${pageSize}/${offset}`;

      const response = await get(endpoint);

      const formatDuration = (value: number) => {
        if (value === 0) return "0";
        if (Number.isInteger(value)) return value.toString();
        return Number(value).toFixed(2);
      };

      if (response?.data?.success && response.data.data) {
        const data = response.data.data.map((item: any, index: number) => ({
          key: index.toString(),
          staffCode: item.staffcode || "N/A",
          staffName: item.staff_name || "N/A",
          shift_duration: `${item.shift_duration || 0}h`,
          timeoff_duration: `${item.timeoff_duration || 0}h`,
          overtime_duration: `${item.overtime_duration || 0}h`,
          attendancedays: item.attendancedays || 0,
          totalworkinghours: `${item.totalworkinghours || 0}h`,
          basesalary: `${currencySymbol.CURRENCY_SYMBOL}${item.totalbasesalary || 0}`,
        }));

        const totals = data.reduce(
          (acc: any, item: any) => ({
            shift_duration: acc.shift_duration + (parseFloat(item.shift_duration) || 0),
            timeoff_duration: acc.timeoff_duration + (parseFloat(item.timeoff_duration) || 0),
            overtime_duration: acc.overtime_duration + (parseFloat(item.overtime_duration) || 0),
            attendancedays: acc.attendancedays + (item.attendancedays || 0),
            totalworkinghours:
              acc.totalworkinghours + (parseFloat(item.totalworkinghours) || 0),
            basesalary:
              acc.basesalary +
              (parseFloat(item.basesalary.replace(`${currencySymbol.CURRENCY_SYMBOL}`, "")) || 0),
          }),
          {
            shift_duration: 0,
            timeoff_duration: 0,
            overtime_duration: 0,
            attendancedays: 0,
            totalworkinghours: 0,
            basesalary: 0,
          }
        );

        const totalRowData = {
          key: "total",
          isSummaryRow: true,
          staffCode: "Total",
          staffName: "",
          shift_duration: `${totals.shift_duration}h`,
          timeoff_duration: `${totals.timeoff_duration.toLocaleString()}h`,
          overtime_duration: `${totals.overtime_duration.toLocaleString()}h`,
          attendancedays: totals.attendancedays,
          totalworkinghours: `${totals.totalworkinghours}h`,
          basesalary: `${currencySymbol.CURRENCY_SYMBOL}${totals.basesalary}`,
        };

        setDataSource([...data, totalRowData]);
        setTotalRecords(response.data.totalRecords);
      } else {
        setDataSource([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching working hours summary:", error);
      setDataSource([]);
      setTotalRecords(0);
    }
  };

  // Initial data fetch
  useEffect(() => {
    getOutletData();
    getStaffData();
    getDepartmentData();
    getDesignationData();
  }, []);

  // Fetch data when page or page size changes
  useEffect(() => {
    if (selectedOutlet !== null) {
      fetchWorkingHoursSummary();
    }
  }, [selectedOutlet, currentPage, pageSize]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1);
  };

  const handlePageSizeChange = (value: number) => {
    setPageSize(value);
    setCurrentPage(0);
  };

  const handleRunFilter = () => {
    setCurrentPage(0);
    fetchWorkingHoursSummary();
  };

  const handleReload = () => {
    setDateRange([currentMonthStart, currentMonthEnd]);
    setSelectedOutlet(null);
    setSelectedStaff(null);
    setSelectedDepartment(null);
    setSelectedDesignation(null);
    setCurrentPage(0);
    setPageSize(10);
    fetchWorkingHoursSummary();
  };

  const handleColumnsClick = () => {
    setIsModalVisible(true);
  };

  const handleModalSave = (
    selectedCols: string[],
    reorderedColumns: { key: string; title: string }[]
  ) => {
    setSelectedColumns(selectedCols);
    setLastSelectedColumns(selectedCols);
    setColumns(
      reorderedColumns.map((col) => {
        const originalCol = initialColumns.find((c) => c.key === col.key);
        return {
          ...originalCol,
          key: col.key, // Ensure the key property is included
          title:
            col.key === "basesalary" ? (
              <span>
                Base salary <DollarOutlined style={{ marginLeft: 4 }} />
              </span>
            ) : (
              col.title
            ),
          dataIndex: col.key,
          width: 150,
          render: (value: any, record: DataSourceItem) =>
            record.isSummaryRow ? <div>{value}</div> : value,
        };
      })
    );
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* Header */}
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowLeftOutlined
              style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
            />
            <Title level={4} style={{ margin: 0 }}>
              Working Hours Summary
            </Title>
          </div>
        </Col>
        <Col>
          <Space>
            {/* <Button style={{ height: "46px" }}>Recalculate</Button>
            <Button style={{ height: "46px" }} icon={<ExportOutlined />}>
              Export as
            </Button> */}
            <Button
              onClick={handleColumnsClick}
              style={{ height: "46px" }}
              icon={<UnorderedListOutlined />}
            >
              Columns
            </Button>
          </Space>
        </Col>
      </Row>

      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#f6f7f9",
          borderRadius: "12px",
          padding: "24px 24px 10px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
        }}
      >
        {/* Filters */}
        <div style={{ marginBottom: "16px", borderRadius: "8px" }}>
          <Row
            gutter={[8, 8]}
            justify="start"
            wrap
            style={{ marginBottom: "16px", display: "flex", flexWrap: "wrap" }}
          >
            <Col flex="1 1 130px" style={{ minWidth: 130 }}>
              <Select
                value={selectedOutlet === null ? "all" : selectedOutlet}
                onChange={(value) =>
                  setSelectedOutlet(value === "all" ? null : value)
                }
                placeholder="Outlet"
                style={{ width: "100%", height: "46px" }}
                allowClear
              >
                <Option value="all">All outlets</Option>
                {outlet.map((outlet) => (
                  <Select.Option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col flex="1 1 130px" style={{ minWidth: 130 }}>
              <Select
                value={selectedStaff === null ? "all" : selectedStaff}
                onChange={(value) =>
                  setSelectedStaff(value === "all" ? null : value)
                }
                placeholder="Staff"
                style={{ width: "100%", height: "46px" }}
                allowClear
              >
                <Option value="all">All staff</Option>
                {staff.map((staff) => (
                  <Select.Option key={staff.id} value={staff.id}>
                    {staff.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col flex="1 1 130px" style={{ minWidth: 130 }}>
              <Select
                value={selectedDepartment === null ? "all" : selectedDepartment}
                onChange={(value) =>
                  setSelectedDepartment(value === "all" ? null : value)
                }
                placeholder="Department"
                style={{ width: "100%", height: "46px" }}
                allowClear
              >
                <Option value="all">All departments</Option>
                {departments.map((dept) => (
                  <Select.Option key={dept.id} value={dept.id}>
                    {dept.departmenttype}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col flex="1 1 130px" style={{ minWidth: 130 }}>
              <Select
                value={
                  selectedDesignation === null ? "all" : selectedDesignation
                }
                onChange={(value) =>
                  setSelectedDesignation(value === "all" ? null : value)
                }
                placeholder="Designation"
                style={{ width: "100%", height: "46px" }}
                allowClear
              >
                <Option value="all">All designations</Option>
                {designations.map((designation) => (
                  <Select.Option key={designation.id} value={designation.id}>
                    {designation.designationtype}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            {/* Date Picker spans full width */}
            <Col flex="1 1 auto" style={{ minWidth: 130 }}>
              <RangePicker
                value={dateRange}
                onChange={(dates) =>
                  dates && setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])
                }
                format="DD/MM/YYYY"
                style={{ width: "100%", height: "46px" }}
                allowClear={false}
              />
            </Col>

            {/* Buttons aligned right */}
            <Col
              flex="1 1 auto"
              style={{ minWidth: 100, textAlign: "right", height: "46px" }}
            >
              <Space>
                <Button
                  icon={<UndoOutlined />}
                  style={{
                    border: "none",
                    background: "#f6f7f9",
                    height: "46px",
                  }}
                  onClick={handleReload}
                />
                <Button
                  style={{ height: "46px" }}
                  type="primary"
                  onClick={handleRunFilter}
                >
                  Run
                </Button>
              </Space>
            </Col>
          </Row>
        </div>

        {/* Table */}
        <Datatable
          columns={displayedColumns}
          dataSource={dataSource}
          scroll={{ y: "calc(100vh - 260px)" }}
          showCheckbox={false}
          rowSelection={rowSelection}
          showPagination={false}
          dynamicTableHeight={"calc(100vh - 240px)"}
        />

        {/* Action Buttons and Pagination */}
        <Row
          justify="space-between"
          style={{
            alignItems: "center",
            marginTop: "30px",
            marginBottom: "16px",
          }}
        >
          <Col>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              style={{ width: 200, height: "46px", borderRadius: "4px" }}
            >
              <Option value={10}>10 entries/page</Option>
              <Option value={20}>20 entries/page</Option>
              <Option value={50}>50 entries/page</Option>
              <Option value={100}>100 entries/page</Option>
            </Select>
          </Col>

          {totalRecords >= 0 && (
            <Col>
              <Row justify="end" align="middle" style={{ gap: "16px" }}>
                <Col>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "rgba(0,0,0,.85)",
                    }}
                  >
                    Total {totalRecords} items
                  </span>
                </Col>
                <Col>
                  <Pagination
                    current={currentPage + 1}
                    pageSize={pageSize}
                    total={totalRecords}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    itemRender={(page, type, originalElement) => {
                      if (type === "prev") {
                        const isDisabled = currentPage === 0;
                        return (
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                              color: isDisabled ? "#bbb" : "#2e56f2",
                              pointerEvents: isDisabled ? "none" : "auto",
                            }}
                          >
                            <LeftOutlined />
                            <span>Previous</span>
                          </a>
                        );
                      }
                      if (type === "next") {
                        const isDisabled =
                          currentPage ===
                          Math.ceil(totalRecords / pageSize) - 1;
                        return (
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                              color: isDisabled ? "#bbb" : "#2e56f2",
                              pointerEvents: isDisabled ? "none" : "auto",
                            }}
                          >
                            <span>Next</span>
                            <RightOutlined />
                          </a>
                        );
                      }
                      return originalElement;
                    }}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Content>

      <ColumnsReportsModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onSave={handleModalSave}
        availableColumns={availableColumnsForModal} // Use the transformed columns
        initialSelectedColumns={selectedColumns}
        lastSelectedColumns={lastSelectedColumns}
        mandatoryColumns={mandatoryColumns}
      />
    </div>
  );
};

export default WorkingHoursSummary;
