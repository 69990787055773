import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Button,
  Input,
  Space,
  Select,
  message,
  Row,
  Col,
} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ProductUnits from "./ProductUnits";
import Storages from "./Storages";
import Taxes from "./Taxes";
import Designations from "./Designations";
import Departments from "./Departments";
import { get } from "../../services/Apiclient";
import RoomTypes from "./RoomTypes";
import Titles from "./Titles";
import CustomerSource from "./CustomerSource";
import StockIn from "./StockIn";
import StockOut from "./StockOut";
import StoreLevel from "./StoreLevel";
import StaffService from "./StaffService";
import PaymentMethod from "./PaymentMethod";
import Tips from "./Tips";
import ServiceCategory from "./ServiceCategory";
import NoticeBoard from "./NoticeBoard";
import ShippingMethod from "./ShippingMethod";
import LogisticsCompany from "./LogisticsCompany";
import Status from "./Status";
import { Helmet } from "react-helmet";
import BookingStatus from "./BookingStatus";
import EInvoiceDescription from "./EInvoiceDescription";

const { Sider, Content } = Layout;
const { Option } = Select;
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;

const menuItems = [
  { key: "Customer sources", label: "Customer sources" },
  { key: "Departments", label: "Departments" },
  { key: "Designations", label: "Designations" },
  // { key: "Follow-up plans", label: "Follow-up plans" },
  { key: "Payment methods", label: "Payment methods" },
  { key: "Product units", label: "Product units" },
  { key: "Stock in methods", label: "Stock in methods" },
  { key: "Stock out methods", label: "Stock out methods" },
  { key: "Storages", label: "Storages" },
  { key: "Store levels", label: "Store levels" },
  { key: "Taxes", label: "Taxes" },
  // { key: "Time off Types", label: "Time off Types" },
  { key: "Tips", label: "Tips" },
  { key: "Titles", label: "Titles" },
  { key: "Service Category", label: "Service Category" },
  // { key: "Rewards & Penalties", label: "Rewards & Penalties" },
  { key: "Room types", label: "Room types" },
  // { key: "Expenses", label: "Expenses" },
  // { key: "Other income", label: "Other income" },
  { key: "Staff service types", label: "Staff service types" },
  { key: "Noticeboard Tag", label: "Noticeboard Tag" },
  { key: "Shipping method", label: "Shipping method" },
  { key: "Logistic Company", label: "Logistic Company" },
  { key: "Status", label: "Status" },
  { key: "Booking Status", label: "Booking Status" },
  { key: "EInvoice Description", label: "EInvoice Description" },
];

const CommonData = () => {
  const navigate = useNavigate();
  const [selectedKey, setSelectedKey] = useState("Customer sources");
  const [searchText, setSearchText] = useState("");

  const [outletData, setOutletData] = useState<any[]>([]);

  const [outletCode, setoutletCode] = useState<any>(
    localStorage.getItem("outlet_id") ? localStorage.getItem("outlet_id") : null
  );

  const handleOutletChange = (value: string) => {
    setoutletCode(value);
  };

  const renderContent = () => {
    switch (selectedKey) {
      case "Customer sources":
        return <CustomerSource selectedOutlet={outletCode} />;
      case "Departments":
        return <Departments selectedOutlet={outletCode} />;
      case "Designations":
        return <Designations selectedOutlet={outletCode} />;
      case "Product units":
        return <ProductUnits selectedOutlet={outletCode} />;
      case "Stock in methods":
        return <StockIn selectedOutlet={outletCode} />;
      case "Stock out methods":
        return <StockOut selectedOutlet={outletCode} />;
      case "Store levels":
        return <StoreLevel selectedOutlet={outletCode} />;
      case "Storages":
        return <Storages selectedOutlet={outletCode} />;
      case "Taxes":
        return <Taxes selectedOutlet={outletCode} />;
      case "Room types":
        return <RoomTypes selectedOutlet={outletCode} />;
      case "Titles":
        return <Titles selectedOutlet={outletCode} />;
      case "Staff service types":
        return <StaffService selectedOutlet={outletCode} />;
      case "Payment methods":
        return <PaymentMethod selectedOutlet={outletCode} />;
      case "Tips":
        return <Tips selectedOutlet={outletCode} />;
      case "Service Category":
        return <ServiceCategory selectedOutlet={outletCode} />;
      case "Noticeboard Tag":
        return <NoticeBoard selectedOutlet={outletCode} />;
      case "Shipping method":
        return <ShippingMethod selectedOutlet={outletCode} />;
      case "Logistic Company":
        return <LogisticsCompany selectedOutlet={outletCode} />;
      case "Status":
        return <Status selectedOutlet={outletCode} />;
      case "Booking Status":
        return <BookingStatus selectedOutlet={outletCode} />;
      case "EInvoice Description":
        return <EInvoiceDescription selectedOutlet={outletCode} />;
    }
  };

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setOutletData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    getOutletDropdown();
  }, []);

  // Filter menu items based on search input
  const filteredMenuItems = menuItems.filter((item) =>
    item.label.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Layout style={{ flexDirection: "column", height: "100vh" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 20px",
            backgroundColor: "transparent",
          }}
        >
          {/* Back Button */}
          <Button
            type="link"
            icon={
              <ArrowLeftOutlined
                style={{
                  fontSize: "16px",
                  color: "#2e56f2",
                  cursor: "pointer",
                }}
              />
            }
            onClick={() => navigate("/settings")}
            style={{
              color: "#000",
              fontWeight: "700",
              fontSize: "18px",
              fontFamily:
                "SourceHanSansCN-Bold,PingFangSC-Semibold,PingFang SC",
              cursor: "text",
            }}
          >
            Common Data
          </Button>

          <Space>
            <Select
              value={outletCode}
              onChange={(value) => handleOutletChange(value)}
              style={{
                width: "224px",
                textAlign: "left",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              disabled={localStorage.getItem("userRole") !== "Merchant"}
            >
              {outletData.length > 0 &&
                outletData.map((outdata: any) => (
                  <Option value={outdata.id} key={outdata.id}>
                    {outdata.name}
                  </Option>
                ))}
            </Select>
            {/* <Button
            style={{
              fontWeight: "700",
              height: "40px",
              marginLeft: "40px",
              padding: "5px 12px",
              borderColor: "#e0e7fe",
              color: "#333",
              borderRadius: "6px",
              background: "#fff",
              minWidth: "72px",
              width: "192px",
            }}
          >
            Duplicate & Distribute
          </Button> */}
          </Space>
        </div>

        {/* Sidebar menu with search functionality */}
        <Row
          gutter={[16, 16]}
          style={{
            padding: "0px 20px",
            margin: 0,
            width: "100%",
            display: "flex",
            height: "calc(100vh - 80px)",
          }}
        >
          <Col span={6} xs={24} lg={6} style={{ height: "inherit" }}>
            <Sider
              width={"100%"}
              style={{
                height: "inherit",
                background: "#fff",
                padding: "0px",
                borderRadius: "8px",
                overflowY: "hidden",
                overflowX: "hidden",
              }}
            >
              <div
                style={{
                  padding: "8px 16px 16px 16px",
                  display: "flex",
                  position: "sticky",
                  top: 0,
                  background: "#fff",
                  width: "350px",
                  zIndex: "10",
                  color: "#333",
                  fontFamily:
                    "SourceHanSansCN-Bold,PingFangSC-Semibold,PingFang SC",
                }}
              >
                <h3>Types</h3>
              </div>

              <div
                style={{
                  overflowY: "hidden",
                  height: "calc(100vh - 160px)",
                  width: "100%",
                }}
                className="custom-scrollbar"
                onMouseEnter={(e) => (e.currentTarget.style.overflowY = "auto")} // Show scrollbar on hover
                onMouseLeave={(e) =>
                  (e.currentTarget.style.overflowY = "hidden")
                } // Hide scrollbar on leave
              >
                <Input
                  placeholder="Please enter"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)} // Update search text on input change
                  style={{
                    margin: "16px 16px 0px 16px",
                    width: "calc(100% - 32px)",
                  }}
                  allowClear
                />

                {/* Filtered Menu Items */}
                <Menu
                  mode="inline"
                  selectedKeys={[selectedKey]}
                  onSelect={(item) => setSelectedKey(item.key)}
                  style={{ margin: "16px", width: "calc(100% - 32px)" }}
                >
                  {filteredMenuItems.map((item) => (
                    <Menu.Item
                      key={item.key}
                      style={{
                        paddingRight: "16px",
                        color: item.key == selectedKey ? "#fff" : "#666",
                        background:
                          item.key == selectedKey ? "#2e56f2" : "#fff",
                        fontWeight: "500",
                        borderRadius: item.key == selectedKey ? "6px" : "0px",
                      }}
                    >
                      {item.label}
                    </Menu.Item>
                  ))}
                </Menu>
              </div>
            </Sider>
          </Col>

          <Col span={18} xs={24} lg={18} style={{ height: "inherit" }}>
            <Layout>
              <Content
                style={{
                  padding: "8px 24px",
                  background: "#fff",
                  borderRadius: "8px",
                  width: "100%",
                  height: "calc(100vh - 80px)",
                }}
              >
                {renderContent()}
              </Content>
            </Layout>
          </Col>
        </Row>
      </Layout>
    </>
  );
};

export default CommonData;
