import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Tag,
  Steps,
  Modal,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { deleteData, get, put } from "../../services/Apiclient";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const InventoryIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_Code.png`;
const RejectedIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_Detail_Rejected.png`;
const ApprovedIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_Detail_Approved.png`;
const PendingIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_Detail_Pending.png`;

const { Title, Text } = Typography;
const { Step } = Steps;

interface StockDetails {
  id: string;
  ref_no: string;
  total_qty: number;
  total_amount: number;
  stock_out_date: string;
  status: string;
  storage_id: string;
  created_at: string;
  storage_name: string;
  staff_id: string;
  staff_name: string;
  note?: string;
  stock_method: string;
  stockout_type: string;
  type_of_stock: string;
  product: any;
  previousData: string | null;
  nextData: string | null;
}

const StockOutDetailView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [stockDetails, setStockDetails] = useState<StockDetails | null>(null);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [retractModalVisible, setRetractModalVisible] = useState(false);

  const isRejected = stockDetails?.status === "Rejected";

  const handleConfirmDelete = async () => {
    if (id) {
      try {
        const url = `/stock/remove/stockout/${id}`;
        const response = await deleteData(url);

        if (response?.status === 200 || response?.status === 204) {
          // Assuming the room was successfully deleted
          console.log("Deleted Stock Out details");
          navigate("/inventory/stockout/list");
        } else {
          console.error("Error deleting Stock out details:", response);
        }
      } catch (error) {
        console.error("Error during delete operation:", error);
      }
    }
    setDeleteModalVisible(false);
  };

  const handleDeleteClick = () => {
    setDeleteModalVisible(true); // Show the delete modal
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false); // Close the modal without deleting
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);

    // Format date to DD/MM/YYYY
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();

    // Format time to HH:mm:ss
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  const openReviewModal = () => {
    setReviewModalVisible(true);
  };

  const onCancel = () => {
    setReviewModalVisible(false);
  };

  const openRetractModal = () => {
    setRetractModalVisible(true);
  };

  const onCancelRetractModal = () => {
    setRetractModalVisible(false);
  };

  const onConfirmRetract = async () => {
    let payload = {
      status: "Pending",
    };
    await put(`/stock/statuschange/${id}`, payload);
    fetchStockDetails(id);
    setRetractModalVisible(false);
  };

  const onReviewReject = async () => {
    let payload = {
      status: "Rejected",
    };
    await put(`/stock/statuschange/${id}`, payload);
    fetchStockDetails(id);
    setReviewModalVisible(false);
  };

  const onReviewApproved = async () => {
    let payload = {
      status: "Approved",
    };
    await put(`/stock/statuschange/${id}`, payload);
    fetchStockDetails(id);
    setReviewModalVisible(false);
  };

  const fetchStockDetails = async (id: any) => {
    try {
      const response = await get(`/stock/stockoutdetails/${id}`);
      if (response?.data?.success) {
        setStockDetails(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching stock details:", error);
    }
  };

  useEffect(() => {
    fetchStockDetails(id);
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <div style={{ padding: "20px", backgroundColor: "#f0f2f5" }}>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: "16px" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              icon={<ArrowLeftOutlined />}
              type="link"
              style={{ marginRight: "8px" }}
              onClick={() => {
                navigate("/inventory/stockout/list");
              }}
            ></Button>
            <Title level={4} style={{ margin: 0 }}>
              Stock out Details
            </Title>
          </div>
          <div>
            {stockDetails?.status !== "Rejected" &&
              stockDetails?.status !== "Approved" && (
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  style={{ marginRight: "8px", fontWeight: "500" }}
                  onClick={handleDeleteClick}
                >
                  Delete
                </Button>
              )}
            {stockDetails?.status !== "Approved" && (
              <Button
                icon={<EditOutlined />}
                type="primary"
                style={{ marginRight: "8px", fontWeight: "500" }}
                onClick={() => {
                  navigate(`/inventory/stockout/edit/${id}`);
                }}
              >
                Edit
              </Button>
            )}
            <Button
              icon={<LeftOutlined />}
              style={{ marginRight: "8px", fontWeight: "500" }}
              onClick={() =>
                navigate(
                  `/inventory/stockoutdetails/view/${stockDetails?.previousData}`
                )
              }
              disabled={stockDetails?.previousData == null}
            >
              Previous
            </Button>
            <Button
              icon={<RightOutlined />}
              iconPosition={"end"}
              style={{ fontWeight: "500" }}
              onClick={() =>
                navigate(
                  `/inventory/stockoutdetails/view/${stockDetails?.nextData}`
                )
              }
              disabled={stockDetails?.nextData == null}
            >
              Next
            </Button>
          </div>
        </Row>

        <Card
          style={{
            borderRadius: "8px",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            overflowY: "auto",
            margin: "0px 100px",
            height: "calc(100vh - 80px)",
            background: "#fafafa",
            scrollbarWidth: "thin",
          }}
        >
          <Card
            style={{
              borderRadius: "8px",
              background: "#fafafa",
            }}
            bodyStyle={{ padding: 0 }}
          >
            <Card style={{ marginBottom: "20px" }} bodyStyle={{ padding: 0 }}>
              <Row
                gutter={16}
                justify="space-between"
                style={{ padding: "24px 24px 0px 24px" }}
              >
                <Col span={20}>
                  <img
                    src={InventoryIcon}
                    alt="icon"
                    style={{ width: "24px", marginRight: "8px" }}
                  />
                  <Text>Ref#</Text>
                  <Title
                    level={5}
                    style={{
                      margin: "8px 0px 0px 0px",
                      fontSize: "20px",
                      lineHeight: "24px",
                      fontWeight: "700",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    {stockDetails?.ref_no}
                  </Title>
                </Col>
                <Col span={2}>
                  <Text>Quantity:</Text>
                  <Text
                    strong
                    style={{
                      display: "block",
                      margin: "8px 0px 0px 0px",
                      fontSize: "20px",
                      lineHeight: "24px",
                      fontWeight: "700",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    {stockDetails?.total_qty}
                  </Text>
                </Col>
                <Col span={2}>
                  <Text>Amount:</Text>
                  <Text
                    strong
                    style={{
                      display: "block",
                      margin: "8px 0px 0px 0px",
                      fontSize: "20px",
                      lineHeight: "24px",
                      fontWeight: "700",
                      color: "rgb(51,51,51)",
                    }}
                  >
                    RM{stockDetails?.total_amount}
                  </Text>
                </Col>
              </Row>
              <Divider />

              <Row
                gutter={16}
                style={{ marginBottom: "16px", padding: "0px 24px" }}
              >
                <Col span={8}>
                  <Text>Stock out date: {stockDetails?.stock_out_date}</Text>
                </Col>
                <Col span={8}>
                  <Text>Storage: {stockDetails?.storage_name}</Text>
                </Col>
                <Col span={8}>
                  <Text>Staff: {stockDetails?.staff_name}</Text>
                </Col>
              </Row>

              <Row
                gutter={16}
                style={{ marginBottom: "16px", padding: "0px 24px" }}
              >
                <Col span={24}>
                  <Text>Stock out method: {stockDetails?.stockout_type}</Text>
                </Col>
              </Row>
              <Row
                gutter={16}
                style={{ marginBottom: "16px", padding: "0px 24px" }}
              >
                <Col span={24}>
                  <Text>Note:</Text>
                  <Text style={{ display: "block" }}>
                    {stockDetails?.note !== null ? stockDetails?.note : ""}
                  </Text>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={24}>
                  {stockDetails?.status == "Pending" && (
                    <img
                      src={PendingIcon}
                      alt="icon"
                      style={{
                        width: "auto",
                        height: "60px",
                        marginRight: "8px",
                        position: "absolute",
                        bottom: "0px",
                        right: "0px",
                        transform: "rotate(-5deg)",
                      }}
                    />
                  )}
                  {stockDetails?.status == "Approved" && (
                    <img
                      src={ApprovedIcon}
                      alt="icon"
                      style={{
                        width: "auto",
                        height: "60px",
                        marginRight: "8px",
                        position: "absolute",
                        bottom: "0px",
                        right: "0px",
                        transform: "rotate(-5deg)",
                      }}
                    />
                  )}
                  {stockDetails?.status == "Rejected" && (
                    <img
                      src={RejectedIcon}
                      alt="icon"
                      style={{
                        width: "auto",
                        height: "60px",
                        marginRight: "8px",
                        position: "absolute",
                        bottom: "0px",
                        right: "0px",
                        transform: "rotate(-5deg)",
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Card>

            <Card style={{ marginBottom: "20px", height: "270px" }}>
              <div style={{ padding: "16px 0" }}>
                <Steps
                  style={{ marginTop: "80px" }}
                  current={stockDetails?.status == "Pending" ? 1 : 2}
                  progressDot
                  labelPlacement="vertical"
                  className={`custom-steps`}
                >
                  <Step
                    title="Created"
                    className="step-creation"
                    description={
                      <div>
                        <Text>Creator: {stockDetails?.staff_name}</Text>
                        <br />
                        <Text>
                          Creation time: {formatDate(stockDetails?.created_at)}
                        </Text>
                      </div>
                    }
                  />
                  <Step
                    title="Pending"
                    className={`step-pending ${
                      isRejected ? "reject-line" : ""
                    }`}
                    description={
                      <div>
                        <Text>Quantity: {stockDetails?.total_qty}</Text>
                        <br />
                        <Text>Amount: {stockDetails?.total_amount}</Text>
                      </div>
                    }
                  />
                  <Step
                    title={
                      stockDetails?.status == "Rejected" ? (
                        <span style={{ color: "rgb(224, 0, 24)" }}>
                          {stockDetails?.status}
                        </span>
                      ) : stockDetails?.status == "Approved" ? (
                        <span style={{ color: "rgb(46, 86, 242)" }}>
                          {stockDetails?.status}
                        </span>
                      ) : null
                    }
                    className={`${
                      stockDetails?.status == "Rejected"
                        ? "step-reject"
                        : stockDetails?.status == "Approved"
                        ? "step-approved"
                        : "step-pended"
                    }`}
                    description={
                      stockDetails?.status == "Pending" ? (
                        <Button type="primary" onClick={openReviewModal}>
                          Review
                        </Button>
                      ) : stockDetails?.status == "Approved" ? (
                        <>
                          <Button
                            type="default"
                            style={{
                              color: "#2e56f2",
                              background: "#fff",
                              borderColor: "#2e56f2",
                              marginTop: "12px",
                            }}
                            onClick={openRetractModal}
                          >
                            Retract
                          </Button>
                        </>
                      ) : null
                    }
                  />
                </Steps>
              </div>
            </Card>

            {/* Product Information */}
            {stockDetails?.product &&
              stockDetails?.product.length > 0 &&
              stockDetails?.product.map((prod: any, index: any) => (
                <Card
                  key={index}
                  title={
                    <Text>
                      <span className="vertical-line"></span>
                      {prod.product_name} ({prod.productcode})
                    </Text>
                  }
                  className="product-card-view"
                >
                  <Row gutter={16} style={{ marginBottom: "16px" }}>
                    <Col span={8}>
                      <Text style={{ marginRight: "8px" }}>Quantity:</Text>
                      <Text>{prod.quantity}</Text>
                    </Col>
                    <Col span={8}>
                      <Text style={{ marginRight: "8px" }}>Purchase unit:</Text>
                      <Text>{prod.product_unit}</Text>
                    </Col>
                    <Col span={8}>
                      <Text style={{ marginRight: "8px" }}>
                        Purchase unit price:{" "}
                      </Text>
                      <Text>RM{prod.unit_price}</Text>
                    </Col>
                  </Row>

                  <Row gutter={16} style={{ marginBottom: "16px" }}>
                    <Col span={8}>
                      <Text style={{ marginRight: "8px" }}>Amount:</Text>
                      <Text>RM{prod.amount}</Text>
                    </Col>
                    <Col span={8}>
                      <Text style={{ marginRight: "8px" }}>
                        Manufacture date:
                      </Text>
                      <Text>{prod.manufacture_date}</Text>
                    </Col>
                    <Col span={8}>
                      <Text style={{ marginRight: "8px" }}>Expiry date:</Text>
                      <Text>{prod.expiry_date}</Text>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={8}>
                      <Text style={{ marginRight: "8px" }}>Supplier:</Text>
                      <Text>{prod.supplier_name}</Text>
                    </Col>
                  </Row>
                </Card>
              ))}
          </Card>
        </Card>
        <Modal
          title={
            <p
              className="modal-message"
              style={{
                fontWeight: "700",
                lineHeight: "22px",
                fontSize: "16px",
              }}
            >
              Stock out review
            </p>
          }
          visible={reviewModalVisible}
          footer={null}
          onCancel={onCancel}
          centered
          width={370}
          bodyStyle={{ textAlign: "center" }}
          maskClosable={false}
          closable={true}
          className="stock-review-modal"
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
              padding: "20px 12px",
            }}
          >
            <p
              className="modal-message"
              style={{ textAlign: "justify", fontSize: "14px", color: "#999" }}
            >
              Please review the inventory details below and take appropriate
              action
            </p>
          </div>
          <div className="modal-divider"></div>
          <div
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              padding: "12px",
            }}
          >
            <div>
              <Button
                className="cancel-button"
                type="default"
                onClick={onCancel}
                style={{
                  color: "rgb(51,51,51)",
                  borderColor: "rgb(224,231,254)",
                  fontSize: "14px",
                  fontWeight: "500",
                  height: "32px",
                  padding: "5px 12px",
                }}
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                className="cancel-button"
                type="default"
                onClick={onReviewReject}
                style={{
                  color: "rgb(224,0,24)",
                  borderColor: "rgb(224,0,24)",
                  fontSize: "14px",
                  fontWeight: "500",
                  height: "32px",
                  padding: "5px 12px",
                  marginRight: "12px",
                }}
              >
                Reject
              </Button>
              <Button
                className="confirm-button"
                type="default"
                onClick={onReviewApproved}
                style={{
                  color: "rgb(255,255,255)",
                  background: "rgb(46,86,242)",
                  fontSize: "14px",
                  fontWeight: "500",
                  padding: "5px 12px",
                  height: "32px",
                }}
              >
                Approve
              </Button>
            </div>
          </div>
        </Modal>
        <Modal
          title={null}
          visible={retractModalVisible}
          onCancel={onCancelRetractModal}
          footer={null}
          centered
          width={370}
          bodyStyle={{ textAlign: "center" }}
          maskClosable={false}
          closable={false}
          className="delete-modal"
        >
          <div
            style={{
              height: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <h3 className="modal-title">Prompt</h3>
            <p className="modal-message">Are you sure to retract the review?</p>
          </div>
          <div className="modal-divider"></div>
          <div className="modal-footer">
            <Button
              className="cancel-button"
              type="link"
              onClick={onCancelRetractModal}
              style={{ color: "#000", fontSize: "16px", fontWeight: "500" }}
            >
              Cancel
            </Button>
            <div className="vl"></div>
            <Button
              className="confirm-button"
              type="link"
              onClick={onConfirmRetract}
              style={{ color: "#1890ff", fontSize: "16px", fontWeight: "500" }}
            >
              Yes, retract
            </Button>
          </div>
        </Modal>
        <ConfirmDeleteModal
          visible={deleteModalVisible}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          message="Are you sure to delete?"
        />
      </div>
    </>
  );
};

export default StockOutDetailView;
