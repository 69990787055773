// src/helpers/permissionsHelper.ts
export const getPermissions = () => {
  try {
    const permissions = localStorage.getItem("permissions");
    return permissions ? JSON.parse(permissions) : {};
  } catch (error) {
    console.error("Failed to parse permissions:", error);
    return {};
  }
};

export const getPagePermissions = () => {
  try {
    const permissions = localStorage.getItem("pagepermission");
    return permissions ? JSON.parse(permissions) : {};
  } catch (error) {
    console.error("Failed to parse permissions:", error);
    return {};
  }
};

// Check if a permission is allowed (only for Staff)
export const hasPagePermission = (key: string) => {
  const permissions = getPagePermissions();
  return permissions[key] || false;
};

export const hasPermission = (role: string, key: string) => {
  if (role === "Staff") {
    const permissions = getPermissions();
    return permissions[key] || false;
  }
  return true; // Allow access for Superadmin and Merchant
};
