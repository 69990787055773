import {
  Modal,
  InputNumber,
  Button,
  message,
  Typography,
  Row,
  Col,
} from "antd";
import React, { useEffect, useState } from "react";
import "../billing/ModalStyles.css";
import { useCurrency } from "../../../contexts/CurrencyContext";

const { Text } = Typography;

interface CustomerKivModalProps {
  visible: boolean;
  kivData: any;
  onClose: () => void;
  onSubmit: (selectedObject: any) => void;
}

const CustomerKivModal: React.FC<CustomerKivModalProps> = ({
  visible,
  kivData,
  onClose,
  onSubmit,
}) => {
  const { currencySymbol } = useCurrency();
  const [payoffAmount, setPayoffAmount] = useState<number>(1);

  useEffect(() => {
    if (visible) {
      setPayoffAmount(
        kivData?.payoff_amount ? parseFloat(kivData.payoff_amount) : 1
      );
    }
  }, [visible, kivData]);

  const handlePayoffChange = (value: number | null) => {
    const maxAmount = kivData?.remaining_balance || 1;

    if (value !== null && value > maxAmount) {
      message.error(`The reedem cannot exceed ${maxAmount}`);
      value = maxAmount; // Reset to max allowed
    }

    setPayoffAmount(value || 1);
  };

  const handleSubmitPayment = async () => {
    if (payoffAmount <= 0) {
      message.warning("Please enter a valid reedem greater than 0");
      return;
    }

    const selectedPayment = {
      ...kivData,
      payoff_amount: payoffAmount.toString(),
    };

    onSubmit(selectedPayment);
  };

  return (
    <Modal
      open={visible}
      title="KIV Details"
      onCancel={onClose}
      footer={
        <div style={{ textAlign: "right", marginTop: "16px" }}>
          <Button type="primary" onClick={handleSubmitPayment}>
            Enter
          </Button>
        </div>
      }
      width="600px"
      style={{ top: "10px" }}
      bodyStyle={{ height: "auto" }}
    >
      <Row gutter={[16, 16]} style={{ display: "flex", alignItems: "center" }}>
        <Col span={12}>
          <Text strong>Item:</Text> <Text>{kivData?.item_name}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Ref#:</Text> <Text>{kivData?.ref_no}</Text>
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{ display: "flex", alignItems: "center", marginTop: "20px" }}
      >
        <Col span={12}>
          <Text strong>Remaining:</Text>{" "}
          <Text>{kivData?.remaining_balance}</Text>
        </Col>
        <Col span={12}>
          <Text strong>Enter KIV Qty to redeem:</Text>
          <InputNumber
            min={1}
            type="number"
            max={kivData?.remaining_balance || 1}
            value={payoffAmount}
            onChange={handlePayoffChange}
            style={{ width: "100%", marginTop: "8px" }}
          />
        </Col>
      </Row>
    </Modal>
  );
};

export default CustomerKivModal;
