export const styles: { [key: string]: React.CSSProperties } = {
  appContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 0px 20px 0px",
    backgroundColor: "#f0f2f5",
  },
  mobileFrame: {
    width: "100%", // Use full width for responsiveness
    maxWidth: "90vw", // Use 90% of viewport width
    height: "auto", // Let height adjust to content
    minHeight: "90vh", // Use 90% of viewport height as a minimum
    overflow: "auto", // Keep overflow for scrolling
    border: "2px solid blue",
    borderRadius: "20px",
    backgroundColor: "#fff",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    // Hide scrollbar but keep scroll functionality
    scrollbarWidth: "none", // For Firefox
    // "-ms-overflow-style": "none", // For Internet Explorer and Edge
    // "&::-webkit-scrollbar": {
    //   display: "none", // For Chrome, Safari, and Opera
    // },
  },
  layoutContainer: {
    textAlign: "center",
    fontSize: "12px",
  },
  title: {
    fontSize: "clamp(16px, 5vw, 18px)", // Scale font size between 16px and 18px based on viewport
    fontWeight: "bold",
    marginBottom: "5vw", // Relative margin
    margin: "15px",
  },
  title2: {
    fontSize: "clamp(16px, 5vw, 18px)",
    fontWeight: "bold",
    backgroundColor: "#f0f0f0",
    borderRadius: "8px",
    height: "clamp(36px, 10vw, 40px)", // Scale height
    textAlign: "start",
    alignContent: "center",
    padding: "8px",
    margin: "15px",
  },
  companyName: {
    fontSize: "30px",
    fontWeight: "bold",
    margin: "20px 20px 10px 20px",
  },
  itemText: {
    fontSize: "clamp(12px, 4vw, 14px)",
    color: "#888",
    margin: "0px 0px 20px 0px",
    display: "block",
    textAlign: "left",
  },
  salesNo: {
    fontSize: "clamp(20px, 6vw, 24px)",
    fontWeight: "bold",
    lineHeight: "clamp(26px, 8vw, 30px)",
    margin: "0px 0px 12px 0px",
  },
  amount: {
    fontSize: "clamp(20px, 6vw, 24px)",
    fontWeight: "bold",
    color: "#000",
    margin: "0px 0px 5px 0px",
  },
  itemCount: {
    fontSize: "clamp(12px, 4vw, 14px)",
    color: "#888",
    margin: "0px",
    textAlign: "right",
  },
  logoContainer: {
    position: "absolute",
    top: "-30px",
    left: "50%",
    transform: "translateX(-50%)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
    borderRadius: "50%",
    border: "2px solid #3f3d48",
  },
  logoImage: {
    width: "clamp(60px, 20vw, 80px)",
    height: "clamp(60px, 20vw, 80px)",
    objectFit: "cover",
    borderRadius: "50%",
    backgroundColor: "white",
  },
  footerText: {
    fontSize: "clamp(14px, 4vw, 16px)",
    fontWeight: "bold",
    marginTop: "40px",
    marginBottom: "7px",
    color: "#333",
  },
  footerText2: {
    fontSize: "clamp(11px, 3vw, 13px)",
    color: "#777",
    marginBottom: "20px",
    lineHeight: "1.4",
  },
  downloadButton: {
    width: "100%",
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "5px",
    fontWeight: "bold",
    color: "black",
    margin: 0,
    boxShadow: "0 0 0 rgba(0, 0, 0, 0)",
  },
  tabContainer: {
    display: "flex",
    backgroundColor: "#f0f0f0",
    borderRadius: "0px",
    overflow: "hidden",
    margin: "15px",
    height: "40px", // Scale height
    marginBottom: "20px",
  },
  tabButton: {
    flex: 1,
    border: "none",
    padding: "10px 20px",
    backgroundColor: "#f0f0f0",
    color: "black",
    fontSize: "clamp(10px, 3vw, 12px)",
    cursor: "pointer",
    textAlign: "center",
    borderRadius: "0px",
    height: 40,
    transition: "background 0.3s, color 0.3s",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  tabButtonActive: {
    backgroundColor: "#1890ff",
    color: "#fff",
    fontWeight: "bold",
    borderRadius: "0px",
    height: 40,
  },
  primaryButton: {
    width: "100%",
    padding: "10px",
    backgroundColor: "#4787e7",
    borderColor: "#4787e7",
    borderRadius: "5px",
    fontWeight: "bold",
    height: "clamp(36px, 10vw, 40px)",
    // marginBottom: "10px",
  },
  whatsappButton: {
    width: "100%",
    padding: "10px",
    backgroundColor: "#34a853",
    borderColor: "#52c41a",
    color: "#fff",
    height: "40px",
    fontWeight: "bold",
    borderRadius: "5px",
  },
  backButton: {
    width: "70%",
    padding: "2vw",
    borderRadius: "5px",
    border: "none",
    color: "#1890ff",
    boxShadow: "0 0 0 rgba(0, 0, 0, 0)",
  },
  checkboxContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  formContainer: {
    margin: "0px 20px 0px 20px",
    maxHeight: "560px",
    minHeight: "80vh",
    overflowY: "auto",
  },
};

export const injectGlobalStyles = () => {
  const styleElement = document.createElement("style");
  styleElement.innerHTML = `
    :where(.css-dev-only-do-not-override-161f05s) .ant-form-item .ant-form-item-explain-error {
      color: #ff4d4f !important;
      text-align: start !important;
    } 
    :where(.css-tjsggz).ant-form-item .ant-form-item-explain-error {
      color: #ff4d4f !important;
      text-align: start !important;
    }
    .react-tel-input .country-list .search-box {
      border: 1px solid #cacaca;
      border-radius: 3px;
      font-size: 15px;
      line-height: 15px;
      margin-left: 6px;
      padding: 3px 8px 5px;
      outline: none;
      width: 180px;
    }
    .react-tel-input .country-list .country {
      padding: 7px 9px;
      text-align: start;
    }
    .react-tel-input .country-list {
      outline: none;
      z-index: 1;
      list-style: none;
      position: absolute;
      padding: 0;
      margin: 10px 0 10px -1px;
      box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
      background-color: white;
      width: 300px;
      max-height: 160px;
      overflow-y: scroll;
      border-radius: 0 0 3px 3px;
    }

    /* Ensure the Card's borderRadius is applied */
    .ant-card {
      border-radius: 10px !important;
    }
    .ant-card-body {
      border-radius: 10px !important;
    }
    .country-list {
      width: 240% !important;
    }
    .ant-form-item-required::before {
      display: none !important;
    }

    /* Small phones (e.g., iPhone SE: 320px width, 568px height) */
    @media (max-width: 359px) {
      .ant-form-item {
        margin-bottom: 12px !important; /* Tighter spacing for small screens */
      }
      .ant-form-item-label > label {
        font-size: 12px !important;
        color: #333 !important;
      }
      .ant-btn {
        font-size: 12px !important;
        height: 36px !important;
      }
      .ant-card-body {
        padding: 8px !important;
      }
    }

    /* Medium phones (e.g., iPhone 14 Pro: 393px width, 852px height) */
    @media (min-width: 360px) and (max-width: 479px) {
      .ant-form-item {
        margin-bottom: 16px !important;
      }
      .ant-form-item-label > label {
        font-size: 14px !important;
        color: #333 !important;
      }
      .ant-btn {
        font-size: 14px !important;
        height: 40px !important;
      }
      .ant-card-body {
        padding: 10px !important;
      }
    }

    /* Large phones (e.g., Samsung Galaxy S23 Ultra: 412px width, 915px height) */
    @media (min-width: 480px) and (max-width: 767px) {
      .ant-form-item {
        margin-bottom: 20px !important;
      }
      .ant-form-item-label > label {
        font-size: 16px !important;
        color: #333 !important;
      }
      .ant-btn {
        font-size: 16px !important;
        height: 44px !important;
      }
      .ant-card-body {
        padding: 12px !important;
      }
    }

    /* Web-specific styles (e.g., >768px width) */
    @media (min-width: 768px) {
      .mobile-frame {
        width: 320px !important; /* Fixed width for web view */
        height: 650px !important; /* Fixed height for web view */
        max-width: 320px !important; /* Override the 90vw */
        min-height: 650px !important; /* Override the 80vh */
      }
      .ant-form-item {
        margin-bottom: 24px !important;
      }
      .ant-form-item-label > label {
        font-size: 16px !important;
      }
      .ant-btn {
        font-size: 16px !important;
        height: 48px !important;
      }
      .ant-card-body {
        padding: 20px !important;
      }
    }
  `;
  document.head.appendChild(styleElement);
};
