import { LeftOutlined } from "@ant-design/icons";
import { Button, Typography } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

interface HeaderProps {
  title: string;
  onBack?: () => void;
  extraContent?: React.ReactNode;
  backgroundColor?: string;
}

const Header: React.FC<HeaderProps> = ({
  title,
  onBack,
  extraContent,
  backgroundColor,
}) => {
  const navigate = useNavigate();

  return (
    <div
      style={{
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "16px",
        backgroundColor: backgroundColor || "#fff",
        color: "#000",
        // boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
        boxSizing: "border-box",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        {onBack && (
          <Button
            type="text"
            style={{
              background: "none",
              border: "none",
              color: "#000",
              fontSize: "16px",
              cursor: "pointer",
            }}
            icon={<LeftOutlined />}
            onClick={onBack || (() => navigate(-1))}
          />
        )}
        <Title
          level={5}
          style={{ margin: 0, marginLeft: onBack ? "10px" : "0" }}
        >
          {title}
        </Title>
      </div>
      {extraContent && <div>{extraContent}</div>}
    </div>
  );
};

export default Header;
