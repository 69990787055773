import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row, Switch, Table, Tabs, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import TabPane from "antd/es/tabs/TabPane";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MembershipEditModel from "../../components/model/membership/MembershipEditModel";
import { useCurrency } from "../../contexts/CurrencyContext";
import { get, put } from "../../services/Apiclient";
import "../../styles/global.css";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
// import { currencySymbol } from "../../constants/currencySymbol";

const { Title, Text } = Typography;

interface MembershipDetails {
  key: React.Key;
  id: string;
  membership_code: string;
  membership_name: string;
  usage_status: boolean;
  description: string;
  membership_type: string;
  selling_price: number;
  tax: string | null;
  taxname: string;
  value: number;
  product_discount: number;
  service_discount: number;
  privilege_discount: number;
  product_sales_performance: number;
  service_sales_performance: number;
  privilege_card_sales_performance: number;
  product_sales_commission: number;
  service_sales_commission: number;
  privilege_card_sales_commission: number;
  conversion_rate_service_points: number;
  conversion_rate_product_points: number;
  bonuse: number;
  samewith_value: boolean;
  sellingprice: number;
  limited_use: boolean;
  max_usage: number;
  usage_limit: boolean;
  buyprice: number;
  giveaway: number;
  price: number;
  typeof_validity: string;
  valid_enddate: string;
  valid_startdate: string;
  selling_enddate: string;
  selling_startdate: string;
  sellingperiod_startdate: string;
  sellingperiod_enddate: string;
  note: string;
  loyalty_pointvalue: number;
  loyalty_pointtype: string | null;
  valid_count: number;
  valid_countperiod: string;
  service_applicable: boolean;
  allservice_applicable: boolean;
  servicecategory_applicable: boolean;
  serviceitem_applicable: boolean;
  servicenot_applicable: boolean;
  product_applicable: boolean;
  allproduct_applicable: boolean;
  productcategory_applicable: boolean;
  productitem_applicable: boolean;
  productnot_applicable: boolean;
  privilege_applicable: boolean;
  allprivilege_applicable: boolean;
  privilegeitem_applicable: boolean;
  privilegenot_applicable: boolean;
  store_applicable: boolean;
  allstore_applicable: boolean;
  bystore_applicable: boolean;
  currentstore_applicable: boolean;
  service_categories: any;
  product_categories: any;
  services: any;
  products: any;
  storeid: any;
  privileges: any;
  exclusive_services: any;
  exclusive_products: any;
  exclusive_privileges: any;
  outlet_id: string | null;
  serviceid: string;
  servicecode: string;
  servicename: string;
  totalprice: string;
}

export default function MembershipView() {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const { id } = useParams();
  const [membershipDetails, setMembershipDetails] =
    useState<MembershipDetails | null>(null);

  const [ismembershipaddmodel, setIsmembershipaddmodel] = useState(false);

  const columns = [
    {
      title: "Code",
      dataIndex: "servicecode",
      key: "servicecode",
      width: 100,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "servicename",
      key: "servicename",
      width: 250,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Category",
      dataIndex: "categoryname",
      key: "categoryname",
      width: 100,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 100,
      render: (sellprice: number) =>
        `${currencySymbol.CURRENCY_SYMBOL}${sellprice}`,
    },
    {
      title: "Exclusive price",
      dataIndex: "exclusiveprice",
      key: "exclusiveprice",
      width: 100,
      render: (sellprice: number) =>
        `${currencySymbol.CURRENCY_SYMBOL}${sellprice}`,
    },
  ];

  const productColumns = [
    {
      title: "Code",
      dataIndex: "productcode",
      key: "productcode",
      width: 100,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "productname",
      key: "productname",
      width: 250,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Category",
      dataIndex: "categoryname",
      key: "categoryname",
      width: 100,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 100,
      render: (sellprice: number) =>
        `${currencySymbol.CURRENCY_SYMBOL}${sellprice}`,
    },
    {
      title: "Exclusive price",
      dataIndex: "exclusiveprice",
      key: "exclusiveprice",
      width: 100,
      render: (sellprice: number) =>
        `${currencySymbol.CURRENCY_SYMBOL}${sellprice}`,
    },
  ];

  const mapMembershipData = (data: any) => {
    const isPrepaid = data.membership_type === "Prepaid";
    const isDiscount = data.membership_type === "Discount" ? true : false;

    const source = isPrepaid
      ? data.prepaids
      : isDiscount
      ? data.discounts
      : data.visits;

    return {
      membership_code: data.membership_code || "",
      membership_name: data.membership_name || "",
      usage_status: data.usage_status || false,
      description: data.description || "",
      membership_type: data.membership_type || "",
      selling_price: parseFloat(source?.selling_price) || 0,
      tax: source?.tax || null,
      taxname: source?.taxname || "",
      value: source?.value || 0,
      product_discount: source?.product_discount || 0,
      service_discount: source?.service_discount || 0,
      privilege_discount: source?.privilege_discount || 0,
      product_sales_performance: source?.product_sales_performance || 0,
      service_sales_performance: source?.service_sales_performance || 0,
      privilege_card_sales_performance:
        source?.privilege_card_sales_performance || 0,
      product_sales_commission: source?.product_sales_commission || 0,
      service_sales_commission: source?.service_sales_commission || 0,
      privilege_card_sales_commission:
        source?.privilege_card_sales_commission || 0,
      conversion_rate_service_points:
        source?.conversion_rate_service_points || 0,
      conversion_rate_product_points:
        source?.conversion_rate_product_points || 0,
      bonuse: source?.bonuse || 0,
      totalprice: source?.totalprice || 0,
      serviceid: source?.serviceid || "",
      servicecode: source?.servicecode || "",
      servicename: source?.servicename || "",
      samewith_value: !!source?.samewith_value,
      buyprice: source?.buyprice || 0,
      giveaway: source?.giveaway || 0,
      price: source?.price || 0,
      usage_limit: !!source?.usage_status,
      sellingprice: parseFloat(source?.sellingprice) || 0,
      limited_use: !!data.usage_status,
      max_usage: data.valid_count ? parseInt(data.valid_count, 10) : 0,
      typeof_validity: data.typeof_validity || "",
      valid_enddate: data.valid_enddate || "",
      valid_startdate: data.valid_startdate || "",
      selling_enddate: data.selling_enddate || "",
      selling_startdate: data.selling_startdate || "",
      sellingperiod_startdate: data.sellingperiod_startdate || "",
      sellingperiod_enddate: data.sellingperiod_enddate || "",
      note: data.note || "",
      loyalty_pointvalue: data.loyalty_pointvalue || 0,
      loyalty_pointtype: data.loyalty_pointtype || null,
      valid_count: data.valid_count ? parseInt(data.valid_count, 10) : 0,
      valid_countperiod: data.valid_countperiod || "",
      service_applicable: !!data.service_applicable,
      allservice_applicable: !!data.allservice_applicable,
      servicecategory_applicable: !!data.servicecategory_applicable,
      serviceitem_applicable: !!data.serviceitem_applicable,
      servicenot_applicable: !!data.servicenot_applicable,
      product_applicable: !!data.product_applicable,
      allproduct_applicable: !!data.allproduct_applicable,
      productcategory_applicable: !!data.productcategory_applicable,
      productitem_applicable: !!data.productitem_applicable,
      productnot_applicable: !!data.productnot_applicable,
      privilege_applicable: !!data.privilege_applicable,
      allprivilege_applicable: !!data.allprivilege_applicable,
      privilegeitem_applicable: !!data.privilegeitem_applicable,
      privilegenot_applicable: !!data.privilegenot_applicable,
      store_applicable: !!data.store_applicable,
      allstore_applicable: !!data.allstore_applicable,
      bystore_applicable: !!data.bystore_applicable,
      currentstore_applicable: !!data.currentstore_applicable,
      service_categories: data.service_categories || [],
      product_categories: data.product_categories || [],
      services: data.services || [],
      products: data.products || [],
      storeid: data.storeid || [],
      privileges: data.privileges || [],
      exclusive_services: (data.exclusive_services || []).map(
        (product: any, index: any) => ({
          ...product,
          key: index,
        })
      ),
      exclusive_products: (data.exclusive_products || []).map(
        (product: any, index: any) => ({
          ...product,
          key: index,
        })
      ),
      exclusive_privileges: [], // Default value as not present in input
      outlet_id: data.outlet_id || null,
    };
  };

  const fetchMembershipDetails = async (id: any) => {
    try {
      const response = await get(`/membership/${id}`);
      if (response?.data?.success) {
        const membershipdata = response.data.data;
        if (membershipdata) {
          const mappedData = membershipdata.membership_type
            ? mapMembershipData(membershipdata)
            : membershipdata;

          setMembershipDetails(mappedData);
        }
      }
    } catch (error) {
      console.error("Error fetching membership details:", error);
    }
  };

  console.log("calll", membershipDetails?.membership_name);

  const handleSwitchChange = async (id: any, checked: boolean) => {
    try {
      let url = `/membership/usagestatus/${id}`;

      let body = {
        usage_status: checked,
      };
      const response = await put(url, body);

      if (response && response.data) {
        if (response.data.success) {
          fetchMembershipDetails(id);
        } else {
          console.log("Error in api call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (error) {
      console.error("Error updating usage status:", error);
    }
  };

  const handleMemberShipAdd = () => {
    fetchMembershipDetails(id);
  };

  useEffect(() => {
    fetchMembershipDetails(id);
  }, [id]);

  // Fetch membership details when the modal is opened
  useEffect(() => {
    if (ismembershipaddmodel && id) {
      fetchMembershipDetails(id);
    }
  }, [ismembershipaddmodel, id]);

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Content className="mainContainer">
        {/* Header Section */}
        <Row justify="space-between" align="middle">
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                <ArrowLeftOutlined
                  style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
                />
              </div>
              <h2 style={{ margin: 0 }}>
                {membershipDetails?.membership_type}
              </h2>
            </div>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {/* <Button
              icon={<SortAscendingOutlined />}
              style={{
                height: "32px !important",
                background: "#fff",
                borderRadius: "6px 6px 6px 6px",
                border: "1px solid #2e56f2",
                fontWeight: 700,
                fontSize: "13px",
                color: "#2e56f2",
                marginRight: "8px",
              }}
            >
              Sync to purchased customers
            </Button> */}
              {/* <Button className="quick-settings">Quick Settings</Button> */}
              <Button
                type="primary"
                icon={<EditOutlined />}
                // onClick={showModal}
                style={{
                  height: "32px !important",
                  background: "#fff",
                  borderRadius: "6px 6px 6px 6px",
                  border: "1px solid #2e56f2",
                  fontWeight: 700,
                  fontSize: "13px",
                  color: "#2e56f2",
                }}
                onClick={() => setIsmembershipaddmodel(true)}
              >
                Edit
              </Button>
            </div>
          </Col>
        </Row>

        {/* Content Section */}
        <Content
          style={{
            margin: "auto",
            left: 0,
            right: 0,
            marginTop: "10px",
            borderRadius: "12px",
            boxSizing: "border-box",
            maxWidth: "1280px",
            minWidth: "920px",
            height: "calc(100%-40px)",
            padding: "24px",
            overflowY: "auto",
            border: ".5px solid #e0e7fe",
            backgroundColor: "hsla(0, 0%, 100%, .5)",
          }}
        >
          <div
            // bordered={false}
            style={{
              // width: "100%",
              // height: "96px",
              // display: "flex",
              padding: "12px 24px",
              // justifyContent: "space-between",
              borderRadius: "12px 12px 12px 12px",
              background: `${
                membershipDetails?.membership_type == "Prepaid"
                  ? "#fff linear-gradient(161deg,#d8d6fe,#958ff8)"
                  : membershipDetails?.membership_type == "Discount"
                  ? "#fff linear-gradient(161deg,#e2e8ed,#a7b7d1)"
                  : "#fff linear-gradient(161deg,#fae2bd,#f1ae5e)"
              } `,
            }}
          >
            <Row
              justify="space-between"
              align="middle"
              style={{ width: "100%", padding: "0px !important" }}
            >
              <Col style={{ display: "flex", gap: "10px" }}>
                <Title
                  level={5}
                  style={{
                    margin: 0,
                    fontWeight: 700,
                    height: "26px",
                    fontSize: "18px",
                    lineHeight: "26px",
                    color: `${
                      membershipDetails?.membership_type == "Prepaid"
                        ? "rgba(93,74,140,0.8)"
                        : membershipDetails?.membership_type == "Discount"
                        ? "rgba(45,73,127,0.8)"
                        : "rgba(139,88,59,0.8)"
                    }`,
                  }}
                >
                  {membershipDetails?.membership_name}
                </Title>
                <Text
                  style={{
                    marginTop: "5px",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "16px",
                    marginLeft: "12px",
                    color: `${
                      membershipDetails?.membership_type == "Prepaid"
                        ? "rgba(93,74,140,0.8)"
                        : membershipDetails?.membership_type == "Discount"
                        ? "rgba(45,73,127,0.8)"
                        : "rgba(139,88,59,0.8)"
                    }`,
                  }}
                >
                  No: {membershipDetails?.membership_code}
                </Text>
              </Col>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Text
                  style={{
                    marginLeft: "10px",
                    verticalAlign: "top",
                    color: `${
                      membershipDetails?.membership_type == "Prepaid"
                        ? "rgba(93,74,140,0.8)"
                        : membershipDetails?.membership_type == "Discount"
                        ? "rgba(45,73,127,0.8)"
                        : "rgba(139,88,59,0.8)"
                    }`,
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  Usage Status
                </Text>
                <Switch
                  checked={membershipDetails?.usage_status}
                  onChange={(checked) => handleSwitchChange(id, checked)}
                />
              </Col>
            </Row>
            <Text
              style={{
                display: "flex",
                marginTop: "25px",
                justifyContent: "flex-end",
                fontSize: "12px",
                fontWeight: 400,
                lineHeight: "18px",
                color: `${
                  membershipDetails?.membership_type == "Prepaid"
                    ? "rgba(93,74,140,0.8)"
                    : membershipDetails?.membership_type == "Discount"
                    ? "rgba(45,73,127,0.8)"
                    : "rgba(139,88,59,0.8)"
                }`,
              }}
            >
              Valid for:{" "}
              {membershipDetails?.typeof_validity === "Custom" ? (
                `${membershipDetails?.valid_startdate}-${membershipDetails?.valid_enddate}`
              ) : (
                <></>
              )}
              {membershipDetails?.typeof_validity === "Immediately" ? (
                `${membershipDetails?.valid_count} ${membershipDetails?.valid_countperiod}`
              ) : (
                <></>
              )}
              {membershipDetails?.typeof_validity ===
              "EffectiveOnConsumption" ? (
                `${membershipDetails?.valid_count} ${membershipDetails?.valid_countperiod}`
              ) : (
                <></>
              )}
            </Text>
            {membershipDetails?.sellingperiod_startdate === "" ||
            membershipDetails?.sellingperiod_enddate === "" ? (
              <></>
            ) : (
              <Text
                style={{
                  display: "flex",
                  marginTop: "px",
                  justifyContent: "flex-end",
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "18px",
                  color: `${
                    membershipDetails?.membership_type == "Prepaid"
                      ? "rgba(93,74,140,0.8)"
                      : membershipDetails?.membership_type == "Discount"
                      ? "rgba(45,73,127,0.8)"
                      : "rgba(139,88,59,0.8)"
                  }`,
                }}
              >
                Selling period:{" "}
                {membershipDetails?.typeof_validity === "Custom" ? (
                  `${membershipDetails?.sellingperiod_startdate}-${membershipDetails?.sellingperiod_enddate}`
                ) : (
                  <></>
                )}
                {membershipDetails?.typeof_validity === "Immediately" ? (
                  `${membershipDetails?.sellingperiod_startdate}-${membershipDetails?.sellingperiod_enddate}`
                ) : (
                  <></>
                )}
                {membershipDetails?.typeof_validity ===
                "EffectiveOnConsumption" ? (
                  `${membershipDetails?.sellingperiod_startdate}-${membershipDetails?.sellingperiod_enddate}`
                ) : (
                  <></>
                )}
              </Text>
            )}
          </div>

          <div style={{ padding: "16px" }}>
            {membershipDetails?.membership_type == "Discount" && (
              <Card>
                <Row gutter={16}>
                  <Col span={16}>
                    <span>
                      <b>Basic Info.</b>
                    </span>
                  </Col>
                  <Col span={8} style={{ textAlign: "right" }}>
                    <span>
                      Selling price:{" "}
                      <b style={{ color: "red" }}>
                        {currencySymbol.CURRENCY_SYMBOL}{" "}
                        {membershipDetails?.selling_price}
                      </b>
                    </span>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <p>Max usage: {membershipDetails?.max_usage}</p>
                    <p>
                      Privilege discount:{" "}
                      {membershipDetails?.privilege_discount}%
                    </p>
                  </Col>
                  <Col span={8}>
                    <p>
                      Product discount: {membershipDetails?.product_discount}%
                    </p>
                  </Col>
                  <Col span={8}>
                    <p>
                      Service discount: {membershipDetails?.service_discount}%
                    </p>
                  </Col>
                </Row>
              </Card>
            )}
            {membershipDetails?.membership_type == "Visit-based" && (
              <Card>
                <Row gutter={16}>
                  <Col span={8}>
                    <span>
                      <b>Basic Info.</b>
                    </span>
                  </Col>
                  <Col span={16} style={{ textAlign: "right" }}>
                    <span>
                      Total price:{" "}
                      <b style={{ color: "red" }}>
                        {currencySymbol.CURRENCY_SYMBOL}{" "}
                        {membershipDetails?.totalprice}
                      </b>
                    </span>
                    <span style={{ marginLeft: "40px" }}>
                      Tax: {membershipDetails?.taxname}
                    </span>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={8}>
                    <p>Service: {membershipDetails?.servicename}</p>
                    <p>Buy: {membershipDetails?.buyprice}</p>
                  </Col>
                  <Col span={8}>
                    <p>Price: {membershipDetails?.price}</p>
                    <p>Giveaway: {membershipDetails?.giveaway}</p>
                  </Col>
                  <Col span={8}>
                    <p>
                      Usage limit:{" "}
                      {membershipDetails?.usage_limit ? "OFF" : "ON"}
                    </p>
                  </Col>
                </Row>
              </Card>
            )}
            {membershipDetails?.membership_type == "Prepaid" && (
              <Card>
                <Tabs
                  defaultActiveKey="1"
                  tabBarExtraContent={
                    <p>
                      Selling price:{" "}
                      <b style={{ color: "red" }}>
                        {currencySymbol.CURRENCY_SYMBOL}{" "}
                        {membershipDetails?.selling_price}
                      </b>
                    </p>
                  }
                >
                  <TabPane tab="Value" key="1">
                    <Row gutter={16}>
                      <Col span={8}>
                        <p>
                          Value: {currencySymbol.CURRENCY_SYMBOL}{" "}
                          {membershipDetails?.value}
                        </p>
                        <p>
                          Privilege discount:{" "}
                          {membershipDetails?.privilege_discount}%
                        </p>
                        <p>
                          Privilege card sales performance:{" "}
                          {membershipDetails?.privilege_card_sales_performance}%
                        </p>
                        <p>
                          Privilege card sales commission:{" "}
                          {membershipDetails?.privilege_card_sales_commission}%
                        </p>
                      </Col>
                      <Col span={8}>
                        <p>
                          Product discount:{" "}
                          {membershipDetails?.product_discount}%
                        </p>
                        <p>
                          Product sales performance:{" "}
                          {membershipDetails?.product_sales_performance}%
                        </p>
                        <p>
                          Product sales commission:{" "}
                          {membershipDetails?.product_sales_commission}%
                        </p>
                        <p>
                          Conversion rate for service points:{" "}
                          {membershipDetails?.conversion_rate_service_points}%
                        </p>
                      </Col>
                      <Col span={8}>
                        <p>
                          Service discount:{" "}
                          {membershipDetails?.service_discount}%
                        </p>
                        <p>
                          Service sales performance:{" "}
                          {membershipDetails?.service_sales_performance}%
                        </p>
                        <p>
                          Service sales commission:{" "}
                          {membershipDetails?.service_sales_commission}%
                        </p>
                        <p>
                          Conversion rate for product points:{" "}
                          {membershipDetails?.conversion_rate_product_points}%
                        </p>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tab="Bonus" key="2">
                    <Row gutter={16}>
                      <Col span={8}>
                        <p>
                          Bonus value: {currencySymbol.CURRENCY_SYMBOL}
                          {membershipDetails?.bonuse}
                        </p>
                        <p>
                          Privilege discount:{" "}
                          {membershipDetails?.privilege_discount}%
                        </p>
                        <p>
                          Privilege card sales performance:{" "}
                          {membershipDetails?.privilege_card_sales_performance}%
                        </p>
                        <p>
                          Privilege card sales commission:{" "}
                          {membershipDetails?.privilege_card_sales_commission}%
                        </p>
                      </Col>
                      <Col span={8}>
                        <p>
                          Product discount:{" "}
                          {membershipDetails?.product_discount}%
                        </p>
                        <p>
                          Product sales performance:{" "}
                          {membershipDetails?.product_sales_performance}%
                        </p>
                        <p>
                          Product sales commission:{" "}
                          {membershipDetails?.product_sales_commission}%
                        </p>
                        <p>
                          Conversion rate for service points:{" "}
                          {membershipDetails?.conversion_rate_service_points}%
                        </p>
                      </Col>
                      <Col span={8}>
                        <p>
                          Service discount:{" "}
                          {membershipDetails?.service_discount}%
                        </p>
                        <p>
                          Service sales performance:{" "}
                          {membershipDetails?.service_sales_performance}%
                        </p>
                        <p>
                          Service sales commission:{" "}
                          {membershipDetails?.service_sales_commission}%
                        </p>
                        <p>
                          Conversion rate for product points:{" "}
                          {membershipDetails?.conversion_rate_product_points}%
                        </p>
                      </Col>
                    </Row>
                  </TabPane>
                </Tabs>
              </Card>
            )}

            {membershipDetails?.membership_type !== "Visit-based" && (
              <Card style={{ marginTop: "16px" }}>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Service" key="1">
                    <p>Applicable to</p>
                    {membershipDetails?.allservice_applicable == true ? (
                      <span style={{ color: "#333", fontSize: "14px" }}>
                        All services
                      </span>
                    ) : null}

                    {membershipDetails?.servicecategory_applicable ? (
                      membershipDetails.service_categories.length > 0 ? (
                        membershipDetails.service_categories.map(
                          (category: any) => (
                            <span
                              key={category.id}
                              style={{
                                height: "24px",
                                padding: "0 8px",
                                fontSize: "12px",
                                fontWeight: 400,
                                marginRight: "8px",
                                lineHeight: "24px",
                                marginBottom: "8px",
                                display: "inline-block",
                                border: "1px solid #e0e7fe",
                                color: "rgba(8, 48, 201, .4)",
                                borderRadius: "4px",
                                background: "rgba(194, 202, 221, .1)",
                              }}
                            >
                              {category.categoryname}
                            </span>
                          )
                        )
                      ) : (
                        <>-</>
                      )
                    ) : null}

                    {membershipDetails?.serviceitem_applicable ? (
                      membershipDetails.services.length > 0 ? (
                        membershipDetails.services.map((service: any) => (
                          <span
                            key={service.id}
                            style={{
                              height: "24px",
                              padding: "0 8px",
                              fontSize: "12px",
                              fontWeight: 400,
                              marginRight: "8px",
                              lineHeight: "24px",
                              marginBottom: "8px",
                              display: "inline-block",
                              border: "1px solid #e0e7fe",
                              color: "rgba(8, 48, 201, .4)",
                              borderRadius: "4px",
                              background: "rgba(194, 202, 221, .1)",
                            }}
                          >
                            {service.servicename}
                          </span>
                        ))
                      ) : (
                        <>-</>
                      )
                    ) : null}

                    {membershipDetails?.servicenot_applicable ? (
                      <span style={{ color: "#333", fontSize: "14px" }}>
                        Not applicable
                      </span>
                    ) : null}

                    {membershipDetails?.exclusive_services &&
                      membershipDetails.exclusive_services.length > 0 && (
                        <Table
                          style={{ marginTop: "20px" }}
                          columns={columns}
                          dataSource={membershipDetails?.exclusive_services} // Display selected products in the table
                          locale={{
                            emptyText: (
                              <div
                                style={{ textAlign: "center", height: "auto" }}
                              >
                                <img
                                  src="https://static-global.boka.vc/mase/def/Def_Empty_noData.png"
                                  alt="No Data"
                                  style={{
                                    width: "80px",
                                    marginBottom: "10px",
                                  }}
                                />
                                <p>No data available</p>
                              </div>
                            ),
                          }}
                          pagination={false}
                        />
                      )}
                  </TabPane>
                  <TabPane tab="Product" key="2">
                    <p>Applicable to</p>
                    {membershipDetails?.allproduct_applicable == true ? (
                      <span style={{ color: "#333", fontSize: "14px" }}>
                        All products
                      </span>
                    ) : null}

                    {membershipDetails?.productcategory_applicable ? (
                      membershipDetails.product_categories.length > 0 ? (
                        membershipDetails.product_categories.map(
                          (category: any) => (
                            <span
                              key={category.id}
                              style={{
                                height: "24px",
                                padding: "0 8px",
                                fontSize: "12px",
                                fontWeight: 400,
                                marginRight: "8px",
                                lineHeight: "24px",
                                marginBottom: "8px",
                                display: "inline-block",
                                border: "1px solid #e0e7fe",
                                color: "rgba(8, 48, 201, .4)",
                                borderRadius: "4px",
                                background: "rgba(194, 202, 221, .1)",
                              }}
                            >
                              {category.categoryname}
                            </span>
                          )
                        )
                      ) : (
                        <>-</>
                      )
                    ) : null}

                    {membershipDetails?.productitem_applicable ? (
                      membershipDetails.products.length > 0 ? (
                        membershipDetails.products.map((product: any) => (
                          <span
                            key={product.id}
                            style={{
                              height: "24px",
                              padding: "0 8px",
                              fontSize: "12px",
                              fontWeight: 400,
                              marginRight: "8px",
                              lineHeight: "24px",
                              marginBottom: "8px",
                              display: "inline-block",
                              border: "1px solid #e0e7fe",
                              color: "rgba(8, 48, 201, .4)",
                              borderRadius: "4px",
                              background: "rgba(194, 202, 221, .1)",
                            }}
                          >
                            {product.productname}
                          </span>
                        ))
                      ) : (
                        <>-</>
                      )
                    ) : null}

                    {membershipDetails?.productnot_applicable ? (
                      <span style={{ color: "#333", fontSize: "14px" }}>
                        Not applicable
                      </span>
                    ) : null}
                    {membershipDetails?.exclusive_products &&
                      membershipDetails.exclusive_products.length > 0 && (
                        <Table
                          style={{ marginTop: "20px" }}
                          columns={productColumns}
                          dataSource={membershipDetails?.exclusive_products} // Display selected products in the table
                          locale={{
                            emptyText: (
                              <div
                                style={{ textAlign: "center", height: "auto" }}
                              >
                                <img
                                  src="https://static-global.boka.vc/mase/def/Def_Empty_noData.png"
                                  alt="No Data"
                                  style={{
                                    width: "80px",
                                    marginBottom: "10px",
                                  }}
                                />
                                <p>No data available</p>
                              </div>
                            ),
                          }}
                          pagination={false}
                        />
                      )}
                  </TabPane>
                  <TabPane tab="Privilege" key="3">
                    <p>Applicable to</p>
                    {membershipDetails?.allprivilege_applicable == true ? (
                      <span style={{ color: "#333", fontSize: "14px" }}>
                        All privileges
                      </span>
                    ) : null}

                    {membershipDetails?.privilegeitem_applicable ? (
                      membershipDetails.privileges.length > 0 ? (
                        membershipDetails.privileges.map((privilege: any) => (
                          <span
                            key={privilege.id}
                            style={{
                              height: "24px",
                              padding: "0 8px",
                              fontSize: "12px",
                              fontWeight: 400,
                              marginRight: "8px",
                              lineHeight: "24px",
                              marginBottom: "8px",
                              display: "inline-block",
                              border: "1px solid #e0e7fe",
                              color: "rgba(8, 48, 201, .4)",
                              borderRadius: "4px",
                              background: "rgba(194, 202, 221, .1)",
                            }}
                          >
                            {privilege.categoryname}
                          </span>
                        ))
                      ) : (
                        <>-</>
                      )
                    ) : null}

                    {membershipDetails?.privilegenot_applicable ? (
                      <span style={{ color: "#333", fontSize: "14px" }}>
                        Not applicable
                      </span>
                    ) : null}
                  </TabPane>
                  <TabPane tab="Store" key="4">
                    <p>Applicable to</p>
                    {membershipDetails?.allstore_applicable == true ? (
                      <span style={{ color: "#333", fontSize: "14px" }}>
                        All stores
                      </span>
                    ) : null}

                    {membershipDetails?.bystore_applicable ? (
                      membershipDetails.storeid &&
                      membershipDetails.storeid.length > 0 ? (
                        membershipDetails.storeid.map((store: any) => (
                          <span
                            key={store.id}
                            style={{
                              height: "24px",
                              padding: "0 8px",
                              fontSize: "12px",
                              fontWeight: 400,
                              marginRight: "8px",
                              lineHeight: "24px",
                              marginBottom: "8px",
                              display: "inline-block",
                              border: "1px solid #e0e7fe",
                              color: "rgba(8, 48, 201, .4)",
                              borderRadius: "4px",
                              background: "rgba(194, 202, 221, .1)",
                            }}
                          >
                            {store.categoryname}
                          </span>
                        ))
                      ) : (
                        <>-</>
                      )
                    ) : null}

                    {membershipDetails?.currentstore_applicable ? (
                      <span style={{ color: "#333", fontSize: "14px" }}>
                        Not applicable
                      </span>
                    ) : null}
                  </TabPane>
                </Tabs>
              </Card>
            )}
          </div>
        </Content>
        <MembershipEditModel
          visible={ismembershipaddmodel}
          onClose={() => setIsmembershipaddmodel(false)}
          onSelect={handleMemberShipAdd}
          selectedData={membershipDetails}
          selectedId={id}
          editMembership={true}
        />
      </Content>
    </>
  );
}
