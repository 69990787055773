import { Button, Card, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; // Added useParams
import { get, post } from "../../services/Apiclient";
import { injectGlobalStyles, styles } from "./styles";

const companyName = `${process.env.PUBLIC_URL}/images/logo/Def_Image_Login_Welcome_V2.png`;

interface EInvoiceData {
  id: string;
  salesno: string;
  description: string;
  amount: string;
  to: string;
  outlet_id: string;
  created_at: string;
  updated_at: string;
  userName: string;
  storelogo: string;
}

const CashierEInvoiceLayout2: React.FC = () => {
  const navigate = useNavigate();
  const [einvoiceData, setEinvoiceData] = useState<EInvoiceData | null>(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const [merchantid, salesNo] = id?.split("-") || [];
  localStorage.setItem("merchantid", merchantid);

  useEffect(() => {
    injectGlobalStyles();
    fetchEinvoiceData();
  }, [salesNo]); // Re-fetch when salesNo changes

  const fetchEinvoiceData = async () => {
    if (!salesNo) {
      message.error("No sales number provided in URL");
      return;
    }

    try {
      setLoading(true);
      const response = await get(`/message/${salesNo}`);

      if (response?.data?.success) {
        setEinvoiceData(response.data.einvoice);
      } else {
        message.error("Failed to fetch einvoice data");
      }
    } catch (error) {
      message.error("Error loading einvoice data");
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = () => {
    navigate(`/cashiereinvoiceslayout3/${salesNo}`);
  };

  // const handleDownload = async () => {
  //   try {
  //     setLoading(true);
  //     const tokenResponse = await post(`/message/generatetoken`);
  //     if (tokenResponse?.data?.access_token) {
  //       localStorage.setItem("invoice_token", tokenResponse.data.access_token);
  //       navigate(`/cashiereinvoiceslayout3/${salesNo}`);
  //     } else {
  //       message.error("Failed to generate token");
  //     }
  //   } catch (error) {
  //     message.error("Error generating token");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        padding: "20px 0px 20px 0px",
        backgroundColor: "#fff",
        minHeight: "100vh",
      }}
    >
      <div>
        <div style={styles.layoutContainer}>
          <h2 style={styles.companyName}>{einvoiceData?.userName}</h2>
          <Card
            style={{
              border: "none",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              margin: "20px 20px 50px 20px",
            }}
            bodyStyle={{ margin: 0 }}
          >
            <p style={styles.itemText}>Let us know how can we help</p>
            <p style={styles.salesNo}>
              Sales No. <br /> {einvoiceData?.salesno || salesNo}
            </p>
            <p
              style={{
                fontSize: "clamp(20px, 6vw, 24px)",
                fontWeight: "bold",
                lineHeight: "clamp(26px, 8vw, 30px)",
                margin: "0px 0px 12px 0px",
              }}
            >
              Description: <br /> {einvoiceData?.description || "N/A"}
            </p>
            <p style={styles.amount}>RM{einvoiceData?.amount || "0.00"}</p>
          </Card>
          <Card
            style={{
              position: "relative",
              border: "none",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
              borderTop: "4px solid #3f3d48",
              margin: "20px 20px 20px 20px",
            }}
            bodyStyle={{ margin: 0 }}
          >
            <div style={styles.logoContainer}>
              <img
                src={einvoiceData?.storelogo || companyName}
                alt="Store Logo"
                style={styles.logoImage}
              />
            </div>
            <p style={styles.footerText}>Request Malaysia E-Invoice</p>
            <p style={styles.footerText2}>
              Submit your TIN and request for your E-Invoice for this sales
            </p>
            <Button
              type="primary"
              onClick={handleDownload}
              style={styles.downloadButton}
              loading={loading}
            >
              Download
            </Button>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CashierEInvoiceLayout2;
