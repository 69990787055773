import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Typography,
  Badge,
  Card,
  Button,
  Divider,
  Tag,
  Spin,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import AppointmentDetailsModal from "./AppointmentDetailsModal";
import { get } from "../../../services/Apiclient";

const { Title, Text } = Typography;

interface AppointmentListModalProps {
  visible: boolean;
  selectedappointmentInfo: any;
  staffid: string;
  onCancel: () => void;
  onView: (appointment: any) => void;
  status: string;
}

const AppointmentListModal: React.FC<AppointmentListModalProps> = ({
  visible,
  onCancel,
  staffid,
  selectedappointmentInfo,
  status,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [appointments, setAppointments] = useState<any>([]);
  const [selappointmentInfo, setSelappointmentInfo] = useState<any>(null);
  const handleView = (appointment: any) => {
    let data = {
      id: appointment.id,
      date: dayjs(appointment.date, "DD/MM/YYYY").format("DD-MM-YYYY"),
      eventinfodate: dayjs(appointment.date, "DD/MM/YYYY").format("DD-MM-YYYY"),
      time: appointment.time,
      staffid: appointment.staffid,
    };
    setSelappointmentInfo(data);
    setIsModalOpen(true);
    onCancel();
  };

  const getStatusColor = (statusName: string) => {
    let bookingStatusColorData: any = localStorage.getItem("colordata");
    const colorData: any = JSON.parse(bookingStatusColorData) || [];
    const normalizedStatusName = statusName.replace(/\s+/g, "").toLowerCase();

    const status = colorData.find((item: any) => {
      const normalizedItemName = item.name.replace(/\s+/g, "").toLowerCase();
      return normalizedItemName === normalizedStatusName;
    });

    return status ? status.color : "#ccc";
  };

  const getCalendarData = async () => {
    try {
      setLoading(true);
      let startdate: any = selectedappointmentInfo.eventinfodate;
      let enddate: any = selectedappointmentInfo.eventinfodate;
      let url = `/appointment/list/${localStorage.getItem(
        "outlet_id"
      )}/${staffid}/${startdate}/${enddate}`;

      let response = await get(url);
      if (response?.data?.success) {
        console.log(
          selectedappointmentInfo.date,
          "selectedappointmentInfo.date"
        );
        const formattedEvents = response.data.data.appointmentDetails
          .filter(
            (appointment: any) =>
              dayjs(appointment.appointment_time, "DD/MM/YYYY hh:mma").format(
                "DD-MM-YYYY"
              ) == selectedappointmentInfo.date
          ) // Ignore if no staff assigned
          .map((appointment: any) => {
            return {
              id: appointment.appointmentid,
              customername: appointment.customername,
              title: appointment.servicename,
              date: dayjs(
                appointment.appointment_time,
                "DD/MM/YYYY hh:mma"
              ).format("DD/MM/YYYY"),
              time: dayjs(
                appointment.appointment_time,
                "DD/MM/YYYY hh:mma"
              ).format("hh:mma"),
              duration: appointment.duration,
              staffid: appointment.staffid,
              staffname: appointment.staffname,
              status: appointment.status,
            };
          });
        let formatedData = formattedEvents.filter(
          (x: any) => x.status == status
        );
        setAppointments(formatedData);
      } else {
        console.log("Error fetching appointments:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    } finally {
      setLoading(false);
    }
  };

  const statusLower = status.toLowerCase();

  const getBorderColor = () => {
    if (statusLower === "cancel") return "#c2c4c3";
    if (statusLower === "noshow") return "#d00000";
    if (statusLower === "paid") return "#2e56f1";
    if (statusLower === "arrived") return "#1bbf00";
    return "#ffd83b"; // default color (for other statuses)
  };

  useEffect(() => {
    if (visible) {
      setAppointments([]);
      getCalendarData();
    }
  }, [visible]);
  return (
    <>
      <Modal
        open={visible}
        onCancel={onCancel}
        className="normal-blue-modal"
        footer={null}
        closeIcon={
          <CloseOutlined style={{ fontSize: "18px", color: "#666" }} />
        }
        width={900}
        style={{ top: "10px" }}
        bodyStyle={{
          height: "calc(100vh - 180px)",
          overflow: "hidden",
          overflowY: "auto",
        }}
        centered
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "200px", // optional: to ensure there's space
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            {/* Header Section */}
            <Row
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                marginBottom: "10px",
              }}
            >
              <Title level={4} style={{ margin: 0 }}>
                Appointment
              </Title>
              <Tag
                color="gold"
                style={{
                  fontWeight: "bold",
                  color: `${getStatusColor(status)}`,
                  background: "rgb(252,236,163, 0.3)",
                  border: `1px solid ${getStatusColor(status)}`,
                  borderRadius: "20px",
                  textTransform: "capitalize",
                }}
              >
                {status}
              </Tag>
            </Row>

            {/* Appointment List */}
            {appointments &&
              appointments.length > 0 &&
              appointments.map((appointment: any, index: any) => (
                <Card
                  key={index}
                  style={{
                    background: "#fff",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    border: "1px solid #e5e5e5",
                    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  }}
                  bodyStyle={{ padding: "12px" }}
                >
                  <Row justify="space-between" align="middle">
                    <Text strong>{appointment.customername}</Text>
                    <Button type="link" onClick={() => handleView(appointment)}>
                      View
                    </Button>
                  </Row>

                  <Card
                    style={{
                      background: "#f5f7fa",
                      borderRadius: "8px",
                      marginTop: "10px",
                    }}
                    bodyStyle={{ padding: "10px" }}
                  >
                    <Row style={{ marginTop: "6px" }}>
                      <Col span={8}>
                        <Text className="normalbreak">
                          Service: {appointment.title}
                        </Text>
                      </Col>
                      <Col span={6}>
                        <Text>
                          Date: {appointment.date}{" "}
                          {dayjs(appointment.time, "HH:mm").format("hh:mm a")}
                        </Text>
                      </Col>
                      <Col span={4}>
                        <Text>Duration: {appointment.duration} min</Text>
                      </Col>
                      <Col span={6} className="normalbreak">
                        <Text>Staff: {appointment.staffname}</Text>
                      </Col>
                    </Row>
                  </Card>
                </Card>
              ))}
          </>
        )}
      </Modal>
      <AppointmentDetailsModal
        visible={isModalOpen}
        eventData={selappointmentInfo}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default AppointmentListModal;
