import { CalendarOutlined, LoadingOutlined } from "@ant-design/icons";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import {
  Button,
  Card,
  Col,
  Divider,
  Flex,
  Input,
  Row,
  Tag,
  Typography,
} from "antd";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../services/Apiclient";
import Header from "../headerFooter/Header";
import Footer from "../headerFooter/footer";

const { Text } = Typography;
const defaultStoreImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Store.png`;

const imgStyle: React.CSSProperties = {
  display: "block",
  width: "50px",
  height: "50px",
  borderRadius: "12px",
  objectFit: "cover",
};

const AppointmentDetails: React.FC = () => {
  const navigate = useNavigate();
  const { appointmentId, merchantId, serviceId } = useParams();
  const [appointmentData, setAppointmentData] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const customerId = localStorage.getItem("customerId");
  const [googleApiKey, setGoogleApiKey] = useState<string | null>(null);
  const [mapCenter, setMapCenter] = useState<{ lat: number; lng: number }>({
    lat: 0,
    lng: 0,
  });
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getAppointmentData(appointmentId, merchantId, serviceId);
  }, [appointmentId, merchantId, serviceId]);

  const getAppointmentData = async (
    appointmentId: string | undefined,
    merchantId: string | undefined,
    serviceId: string | undefined
  ) => {
    if (!appointmentId || !merchantId || !serviceId) {
      setError("Missing appointment ID, merchant ID, or service ID");
      setLoading(false);
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const url = `/appointmentmob/${appointmentId}/${merchantId}/${serviceId}`;
      console.log("Calling API with URL:", url);
      const response = await get(url);
      console.log("API Response:", response);

      const data = response?.data;
      if (data?.success && data.data.length > 0) {
        const appointment = data.data[0];

        setAppointmentData(appointment);
        setGoogleApiKey(appointment.googleMapKey);
        let lat = Number(appointment.latitude);
        let lng = Number(appointment.longitude);

        setMapCenter({ lat, lng });
        setMarkerPosition({ lat, lng });
      } else {
        setError("No appointment data found");
      }
    } catch (error) {
      console.error("Error while retrieving appointment:", error);
      setError("Failed to load appointment details");
    } finally {
      setLoading(false);
    }
  };

  // Map API status to display text
  const getDisplayStatus = (status: string) => {
    switch (status.toLowerCase()) {
      case "paid":
        return "Finish";
      case "upcoming":
        return "Upcoming";
      case "cancel":
        return "Cancel";
      default:
        return status; // Fallback to raw status if unknown
    }
  };

  // Loading state
  if (loading) {
    return (
      <div style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
        <Header
          title="Appointment Details"
          onBack={() => navigate(`/app/appointment/list/${merchantId}`)}
          backgroundColor={"#fff"}
        />
        <div
          style={{ padding: "16px", paddingTop: "70px", textAlign: "center" }}
        >
          <LoadingOutlined style={{ fontSize: "24px" }} />
        </div>
        <Footer />
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
        <Header
          title="Appointment Details"
          onBack={() => navigate(`/app/appointment/list/${merchantId}`)}
          backgroundColor={"#fff"}
        />
        <div
          style={{ padding: "16px", paddingTop: "70px", textAlign: "center" }}
        >
          <Text style={{ color: "red" }}>{error}</Text>
        </div>
        <Footer />
      </div>
    );
  }

  // No data state
  if (!appointmentData) {
    return (
      <div style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
        <Header
          title="Appointment Details"
          onBack={() => navigate(`/app/appointment/list/${merchantId}`)}
          backgroundColor={"#fff"}
        />
        <div
          style={{ padding: "16px", paddingTop: "70px", textAlign: "center" }}
        >
          <Text>No appointment data available</Text>
        </div>
        <Footer />
      </div>
    );
  }

  const status = appointmentData.status.toLowerCase();

  // Utility function to add minutes to time (e.g., "09:30" + 60 minutes = "10:30")
  // const addMinutes = (time: string, minutes: number) => {
  //   const [hours, mins] = time.split(":").map(Number);
  //   const totalMinutes = hours * 60 + mins + minutes;
  //   const newHours = Math.floor(totalMinutes / 60) % 24;
  //   const newMins = totalMinutes % 60;
  //   return `${newHours.toString().padStart(2, "0")}:${newMins.toString().padStart(2, "0")}`;
  // };

  // const handleReschedule = () => {
  //   // Extract the date and time from appointmentData
  //   const appointmentDate = appointmentData.appointment_time.split(" ")[0]; // Assuming format is "YYYY-MM-DD HH:MM"
  //   const appointmentTime = appointmentData.appointment_time.split(" ")[1]; // Extract time part

  //   navigate(`/app/booking/${merchantId}`, {
  //     state: {
  //       appointmentId, // Pass the appointment ID for rescheduling
  //       selectedBookings: [
  //         {
  //           customerId: appointmentData.customerid || customerId, // Assuming customer ID is available
  //           customerName: "Customer 1",
  //           selectedService: {
  //             id: serviceId,
  //             name: appointmentData.servicename,
  //             duration: appointmentData.duration || "60", // Default to 60 minutes if not available
  //             sellprice: appointmentData.costprice,
  //           },
  //           selectedStaff: {
  //             id: appointmentData.staffid || "",
  //             name: appointmentData.staffname || "Unknown Staff",
  //             lastname: "",
  //             department_name: "",
  //           },
  //           selectedDate: appointmentDate,
  //           selectedTime: `${appointmentTime} - ${addMinutes(appointmentTime, appointmentData.duration || 60)}`, // Assuming duration is in minutes
  //           selectedOutlet: appointmentData.outletname,
  //         },
  //       ],
  //       validityType: "custom", // Default to individual appointment
  //       allergiesData: {
  //         faceType: appointmentData.que1 || "",
  //         allergyDetails: appointmentData.que2 || "",
  //       },
  //     },
  //   });
  // };

  return (
    <div style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
      <Header
        title="Appointment Details"
        onBack={() => navigate(`/app/appointment/list/${merchantId}`)}
        backgroundColor={"#fff"}
      />

      <div
        style={{ padding: "16px", paddingTop: "70px", paddingBottom: "80px" }}
      >
        {/* Google Maps Section */}
        {googleApiKey ? (
          <div
            style={{
              marginBottom: "80px",
              borderRadius: "8px",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <LoadScript googleMapsApiKey={googleApiKey}>
              <GoogleMap
                mapContainerStyle={{
                  width: "100%",
                  height: "300px",
                  borderRadius: "8px",
                }}
                center={mapCenter}
                zoom={14}
              >
                <Marker position={markerPosition} />
              </GoogleMap>
            </LoadScript>
          </div>
        ) : (
          <div
            style={{
              marginBottom: "80px",
              borderRadius: "8px",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <iframe
              title="Google Map"
              width="100%"
              height="300"
              style={{ border: 0, borderRadius: "8px" }}
              loading="lazy"
              allowFullScreen
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15955.521051076528!2d101.6820085363744!3d3.1390036689395366!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc362bf39ed5df%3A0x403a36dd55d13a14!2sKuala%20Lumpur!5e0!3m2!1sen!2smy!4v1708838394891!5m2!1sen!2smy"
            />
          </div>
        )}

        {/* Store Information - Floating on top of Map */}
        <Card
          style={{
            backgroundColor: "orange",
            borderRadius: "12px",
            border: "none",
            position: "absolute",
            top: "330px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "85%",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
            zIndex: 10,
          }}
        >
          <Flex align="center">
            <img alt="avatar" src={defaultStoreImage} style={imgStyle} />
            <Col>
              <Text
                style={{
                  fontWeight: 500,
                  display: "block",
                  marginLeft: "10px",
                  marginBottom: "5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "200px",
                  color: "white",
                }}
              >
                {appointmentData.outletname || "Unknown Outlet"}
              </Text>
              <Text
                style={{
                  fontWeight: 500,
                  display: "block",
                  marginLeft: "10px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: "220px",
                  color: "white",
                }}
              >
                {appointmentData.address || "Address Unavailable"}
              </Text>
            </Col>
          </Flex>
        </Card>

        {/* Service Information */}
        <Card
          bodyStyle={{ padding: "15px", margin: 0 }}
          style={{
            marginTop: "30px",
            borderRadius: "12px",
            padding: 0,
            margin: 0,
          }}
        >
          <Flex align="center">
            <img
              alt="service"
              src={defaultStoreImage}
              style={{
                display: "block",
                width: "70px",
                height: "70px",
                borderRadius: "12px",
                objectFit: "cover",
              }}
            />
            <Col style={{ marginLeft: "12px", flexGrow: 1 }}>
              <Title
                level={5}
                style={{
                  fontWeight: 600,
                  display: "block",
                  marginBottom: "5px",
                  marginTop: 0,
                }}
              >
                {appointmentData.servicename || "No Service Name"}
              </Title>
              <Text
                style={{
                  display: "block",
                  color: "#666",
                  backgroundColor: "#f5f5f5",
                  padding: "5px",
                  borderRadius: "8px",
                }}
              >
                <CalendarOutlined
                  style={{ color: "orange", marginRight: "10px" }}
                />
                <span>
                  {appointmentData.appointment_time || "Time Unavailable"}
                </span>
              </Text>
              <Text style={{ display: "block" }}>
                Staff: {appointmentData.staffname || "Staff Unavailable"}
              </Text>
            </Col>
          </Flex>
          <Tag
            style={{
              color:
                status === "upcoming"
                  ? "blue"
                  : status === "paid"
                  ? "green"
                  : "gray",
              border: `2px solid ${
                status === "upcoming"
                  ? "blue"
                  : status === "paid"
                  ? "green"
                  : "gray"
              }`,
              background:
                status === "upcoming"
                  ? "#eef3ff"
                  : status === "paid"
                  ? "#e6ffe6"
                  : "#f5f5f5",
              fontWeight: "bold",
              transform: "rotate(-5deg)",
              display: "inline-block",
              padding: "5px 15px",
              fontSize: "14px",
              borderRadius: "5px",
              textTransform: "uppercase",
              float: "right",
            }}
          >
            {getDisplayStatus(status)}
          </Tag>
        </Card>

        {/* Total Price */}
        <div style={{ marginTop: "16px", textAlign: "right" }}>
          <Text style={{ color: "gray", display: "block" }}>Total</Text>
          <Text
            style={{ color: "black", fontWeight: "bold", fontSize: "18px" }}
          >
            RM {appointmentData.costprice || "N/A"}
          </Text>
        </div>

        <Divider />

        {/* Note Input */}
        <Text>Note</Text>
        <Input.TextArea
          rows={2}
          style={{ marginTop: "16px", borderRadius: "8px" }}
          placeholder="Add a note (optional)"
        />

        {/* Dynamic Buttons Based on Status */}
        <Row gutter={12} style={{ marginTop: "16px" }}>
          {status === "upcoming" && (
            <>
              <Col span={12}>
                <Button
                  type="primary"
                  size="large"
                  block
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "black",
                    fontWeight: 600,
                    borderRadius: "8px",
                  }}
                >
                  Cancel
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  type="primary"
                  size="large"
                  block
                  style={{
                    backgroundColor: "orange",
                    border: "none",
                    fontWeight: 600,
                    borderRadius: "8px",
                  }}
                  // onClick={handleReschedule}
                >
                  Reschedule
                </Button>
              </Col>
            </>
          )}
          {status === "paid" && (
            <>
              <Col span={12}>
                <Button
                  type="primary"
                  size="large"
                  block
                  style={{
                    backgroundColor: "#f5f5f5",
                    color: "black",
                    fontWeight: 600,
                    borderRadius: "8px",
                  }}
                >
                  Leave a Review
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  type="primary"
                  size="large"
                  block
                  style={{
                    backgroundColor: "orange",
                    border: "none",
                    fontWeight: 600,
                    borderRadius: "8px",
                  }}
                  onClick={() => navigate(`/app/outlet/list/${merchantId}`)}
                >
                  Book Again
                </Button>
              </Col>
            </>
          )}
          {status === "cancel" && (
            <Col span={24}>
              <Button
                type="primary"
                size="large"
                block
                style={{
                  backgroundColor: "orange",
                  border: "none",
                  fontWeight: 600,
                  borderRadius: "8px",
                }}
                onClick={() => navigate(`/app/outlet/list/${merchantId}`)}
              >
                Book Again
              </Button>
            </Col>
          )}
        </Row>
      </div>

      <Footer />
    </div>
  );
};

export default AppointmentDetails;
