import { Card, Col, Divider, Row, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get } from "../../services/Apiclient";
import Header from "../headerFooter/Header";
const { Title, Text } = Typography;

const cardStyle: React.CSSProperties = {
  borderRadius: "8px",
  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
  // padding: "16px",
  backgroundColor: "#fff",
  maxWidth: "360px",
  margin: "16px",
};

const headerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  marginBottom: "16px",
};

const receiptRowStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "8px",
};

const headertopStyle: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  padding: "16px",
};

const containerStyle: React.CSSProperties = {
  minHeight: "100vh",
  padding: "80px 16px 16px 16px",
  backgroundColor: "#fff",
};

interface Purchase {
  id: string;
  created_at: string;
  ref_no: string;
  items: { item_name: string; item_qty: number; item_price: number }[];
  bill_status: string;
  sub_total: number;
  taxes: number;
  final_total: number;
  tip: number;
}

const AppPurchaseHistoryDetails: React.FC = () => {
  const navigate = useNavigate();
  const [purchaseDetails, setPurchaseDetails] = useState<any>(null);
  const location = useLocation();
  const { merchantId } = useParams();
  const customerId = localStorage.getItem("customer_id");
  const outletId = localStorage.getItem("outlet_id");
  const [loading, setLoading] = useState(false);
  // const { purchaseHistory, purchaseId } = location.state;

  // Find the purchase using the id
  const { purchaseId } = location.state || {};

  const fetchPurchaseDetails = async () => {
    if (!purchaseId) return;

    try {
      const response = await get(
        `/customerprofilemob/mobile/purchasehistory/${customerId}/${outletId}/${merchantId}`
      );
      if (response?.data?.success && response.data.data) {
        // Find the specific purchase by ID
        const purchase: Purchase | undefined = response.data.data.find(
          (p: Purchase) => p.id === purchaseId
        );
        setPurchaseDetails(purchase);
      }
    } catch (error) {
      console.error("Error fetching purchase details:", error);
    } finally {
      setLoading(false);
    }
  };

  // Scroll to the top of the page with smooth behavior on component mount
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
  }, []);

  useEffect(() => {
    fetchPurchaseDetails(); // Fetch data when component mounts
  }, [purchaseId]);

  return (
    <>
      {/* header */}
      {/* <div style={headertopStyle}>
        <div
          onClick={() => navigate(`/app/purchasehistory/${merchantId}`)}
          style={{ cursor: "pointer" }}
        >
          <ArrowLeftOutlined
            style={{ marginRight: 12, color: "rgb(255, 165, 0)", fontSize: 15 }}
          />
        </div>
        <h5
          style={{
            margin: 0,
            color: "rgba(0, 0, 0, 0.88)",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: 1.5,
          }}
        >
          Purchase History
        </h5>
      </div> */}
      <Header
        title="Purchase History"
        onBack={() => navigate(`/app/purchasehistory/${merchantId}`)}
        backgroundColor={"#fff"}
      />

      {/* Show Loading Spinner */}
      <div style={containerStyle}>
        {loading ? (
          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <Spin size="large" />
          </div>
        ) : purchaseDetails ? (
          <Card style={cardStyle}>
            <Row style={headerStyle}>
              <Col>
                <Title level={5} style={{ margin: 0 }}>
                  {new Date(purchaseDetails.created_at).toLocaleDateString()}|{" "}
                  {purchaseDetails.status}
                </Title>
              </Col>
              {/* <Col>
            <Title level={5} style={{ margin: 0 }}>
            </Title>
          </Col> */}
            </Row>

            {/* Receipt Number */}
            <Row style={receiptRowStyle}>
              <Col>
                <Text type="secondary">No.{purchaseDetails.ref_no}</Text>
              </Col>
              <Col>
                <Text type="secondary">
                  {new Date(purchaseDetails.created_at).toLocaleString()}
                </Text>
              </Col>
            </Row>

            <Divider />
            {/* Item Details */}
            {purchaseDetails.items.map((item: any) => (
              <Row style={receiptRowStyle}>
                <Col>
                  <Text>{item.item_name}</Text>
                </Col>
                <Col>
                  <Text>x{item.item_qty}</Text>
                </Col>
                <Col>
                  <Text>{item.item_price}</Text>
                </Col>
              </Row>
            ))}
            {/* <Row>
          <Col span={12}>
            <Text type="secondary">Cash</Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Text type="secondary">Membership 84.15</Text>
          </Col>
        </Row> */}
            <Row>
              <Col span={12}>
                <Text type="secondary" style={{ margin: 0 }}>
                  {purchaseDetails.bill_status}
                </Text>
              </Col>
            </Row>

            <Divider />

            {/* Subtotal, Taxes, Total, Tips */}
            <div style={{ width: "100px", float: "right" }}>
              <Row style={receiptRowStyle}>
                <Col>
                  <Text type="secondary">Subtotal</Text>
                </Col>
                <Col>
                  <Title level={5} style={{ margin: 0 }}>
                    {purchaseDetails.sub_total}
                  </Title>
                </Col>
              </Row>
              <Row style={receiptRowStyle}>
                <Col>
                  <Text type="secondary">Taxes</Text>
                </Col>
                <Col>
                  <Title level={5} style={{ margin: 0 }}>
                    {purchaseDetails.taxes || "0"}
                  </Title>
                </Col>
              </Row>
              <Row style={receiptRowStyle}>
                <Col>
                  <Text type="secondary" style={{ margin: 0 }}>
                    Total
                  </Text>
                </Col>
                <Col>
                  <Title level={5} style={{ margin: 0 }}>
                    {purchaseDetails.final_total}
                  </Title>
                </Col>
              </Row>
              <Row style={receiptRowStyle}>
                <Col>
                  <Text type="secondary">Tips</Text>
                </Col>
                <Col>
                  <Title level={5} style={{ margin: 0 }}>
                    {purchaseDetails.tip || "0"}
                  </Title>
                </Col>
              </Row>
            </div>
          </Card>
        ) : (
          <Text
            style={{ textAlign: "center", display: "block", marginTop: 20 }}
          >
            No purchase details found.
          </Text>
        )}
      </div>
    </>
  );
};

export default AppPurchaseHistoryDetails;
