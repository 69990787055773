import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Checkbox,
  Typography,
  Spin,
} from "antd";
import {
  CloseOutlined,
  DownOutlined,
  PlusOutlined,
  RightOutlined,
  SearchOutlined,
  UpOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import SearchCustomerModel from "../billing/SearchCustomerModel";
import dayjs from "dayjs";
import { get, post, put } from "../../../services/Apiclient";
import AppointmentTimeModal from "./AppointmentTimeModal";
import { useCurrency } from "../../../contexts/CurrencyContext";
import ConfirmDeleteModal from "../DeleteModal";

const { Text } = Typography;

interface AppointmentModelProps {
  visible: boolean;
  appintmentdata?: any;
  onCancel: () => void;
}

interface Service {
  id: number;
  name: string;
  price: string;
  time?: string;
  staff?: string;
  duration?: string;
  staffname?: string;
}

interface Customer {
  id?: string | null;
  name?: string | null;
  phone?: string | null;
  email?: string | null;
  services: Service[];
}

interface Staff {
  id: string;
  name: string;
}

const StaffIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_Avatar_EmpM.png`;
const memberInfoPrivilege = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_MemberInfoPrivilege.png`;
const memberInfoGiftCard = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_MemberInfoGiftCard.png`;
const MemberInfoPackage = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_MemberInfoPackage.png`;

const AddAppointment: React.FC<AppointmentModelProps> = ({
  visible,
  appintmentdata,
  onCancel,
}) => {
  const { currencySymbol } = useCurrency();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isServiceModalVisible, setIsServiceModalVisible] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>(null);
  const [appointmentType, setAppointmentType] = useState("individual"); // State for appointment type
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [selectedServices, setSelectedServices] = useState<Service[]>([]);
  const [showServiceModal, setShowServiceModal] = useState(false);

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [selectedCustomerIndex, setSelectedCustomerIndex] = useState<
    number | null
  >(null);

  const [showDetailsModal, setShowDetailsModal] = useState(false);

  const [form] = Form.useForm();
  const [showErrors, setShowErrors] = useState(false);

  const [currentServiceId, setCurrentServiceId] = useState<number | null>(null);
  const [isStaffModalVisible, setIsStaffModalVisible] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState<Staff | null>(null);
  const [staffData, setStaffData] = useState<any[]>([]);
  const [isTimeModalVisible, setIsTimeModalVisible] = useState(false);

  const [serviceCategory, setServiceCategory] = useState<any[]>([]);
  const [selectedServiceTab, setSelectedServiceTab] = useState<any>("null");
  const [serviceItems, setServiceItems] = useState<any[]>([]);
  const [tempServiceItems, setTempServiceItems] = useState<any[]>([]);
  const [searchServiceValue, setSearchServiceValue] = useState("");

  const [appointmentNote, setAppointmentNote] = useState("");

  const [appointmentModalTitle, setAppointmentModalTitle] =
    useState("Add Appointment");

  const [isCustomerPrivilegeVisible, setIsCustomerPrivilegeVisible] =
    useState(false);
  const [customerCount, setCustomerCount] = useState<any[]>([]);
  const [isDeleteMemberModalVisible, setIsDeleteMemberModalVisible] =
    useState(false);

  const closeDeleteMemberModal = () => {
    setIsDeleteMemberModalVisible(false);
  };

  const [loading, setLoading] = useState(false);

  const handleConfirmMember = () => {
    setIsDeleteMemberModalVisible(false);
    setSelectedCustomer(null);
    const updatedCustomers = [...customers];
    updatedCustomers[selectedCustomerIndex as number] = {
      id: null,
      name: null,
      phone: null,
      email: null,
      services: [],
    };

    setCustomers(updatedCustomers);
    setCustomerCount([]);
  };

  const handleOpenDetailModal = () => {
    setIsDetailModalVisible(true);
  };

  const handleAddService = () => {
    setIsServiceModalVisible(true);
    setShowDetailsModal(false);
  };

  const getStaffDropdown = async () => {
    try {
      let url = `/staff/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data.map((staff: any) => ({
            id: staff.id,
            name: staff.name,
            profile_pic: staff.profile_pic || StaffIcon,
          }));

          data.sort((a: any, b: any) => {
            return a.name.localeCompare(b.name);
          });

          // Add "All" for dropdown selection only
          setStaffData(data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleAddNewCustomer = () => {
    setCustomers([...customers, { services: [] }]);
  };

  const handleSelectService = (service: {
    id: number;
    name: string;
    price: string;
  }) => {
    setSelectedServices((prevSelected) =>
      prevSelected.some((s) => s.id === service.id)
        ? prevSelected.filter((s) => s.id !== service.id)
        : [...prevSelected, service]
    );
  };

  const fetchCoustomerCountData = async (id: any) => {
    setLoading(true);
    const outlateid = localStorage.getItem("outlet_id");
    // const id = selectedCustomer?.id;
    if (outlateid && id) {
      try {
        const url = `/customer/privilege/${outlateid}/${id}`;
        const response = await get(url);
        if (response.data.success) {
          const countData = response.data.data;
          await setCustomerCount(countData);
        } else {
          console.error("Failed to fetch customer count.");
        }
      } catch (error) {
        console.error("Error fetching customer count: ", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSelectCustomer = (customer: any) => {
    const updatedCustomers = [...customers];
    updatedCustomers[selectedCustomerIndex as number] = {
      id: customer.id,
      name: customer.name,
      phone: `+${customer.countrycode}${customer.phonenumber}`,
      email: customer.email,
      services: [],
    };

    setCustomers(updatedCustomers);
    fetchCoustomerCountData(customer.id);

    let customerData = {
      customerid: customer.id,
      name: customer.name,
      phone: `+${customer.countrycode}${customer.phonenumber}`,
      email: customer.email,
      lastArrivedDate: customer.lastArrivedDate,
      dob: customer.dob,
    };
    setSelectedCustomer(customerData);

    setIsModalVisible(false);
  };

  const showServiceModalPopup = (index: number) => {
    setSelectedCustomerIndex(index);
    setIsServiceModalVisible(true);
    setSelectedServices([]);
  };

  const formatAppointmentPayload = (
    customers: Customer[],
    appointmentType: string
  ) => {
    if (customers.length === 0) {
      message.error("Please select at least one customer.");
      return null;
    }

    let outletId = localStorage.getItem("outlet_id");

    return {
      date: dayjs().format("YYYY-MM-DD"),
      time: "09:00",
      appointment_type:
        appointmentType === "group"
          ? "group appointment"
          : "individual appointment",
      outlet_id: outletId,
      note: appointmentNote || "",
      Customers: customers.map((customer) => ({
        customerid: selectedCustomer.customerid || null,
        services: customer.services.map((service) => ({
          serviceid: service.id,
          staffid: service.staff || null,
          duration: service.duration,
          time: service.time
            ? dayjs(
                service.time,
                ["YYYY-MM-DD HH:mm", "DD/MM/YYYY hh:mm A"],
                true
              ).format("DD/MM/YYYY hh:mma")
            : null,
        })),
      })),
    };
  };

  const handleSubmit = async () => {
    if (!selectedCustomer?.name || !selectedCustomer?.phone) {
      message.error("Please select a customer name and phone.");
      return;
    }

    if (
      customers.length === 0 ||
      !customers.some((c) => c.services.length > 0)
    ) {
      message.error("Please select at least one service before submitting.");
      return;
    }

    const payload = formatAppointmentPayload(customers, appointmentType);
    if (!payload) return;

    if (!payload) {
      console.error("Invalid payload");
      return;
    }

    if (appintmentdata) {
      const response = await put(`/appointment/${appintmentdata.id}`, payload);
      if (response.data.success) {
        message.success("Appointment Updated successfully!");
        onCancel(); // Close modal AFTER success message
      } else {
        message.error("Failed to update appointment.");
      }
    } else {
      const response = await post("/appointment/addappointment", payload);
      if (response.data.success) {
        message.success("Appointment created successfully!");
        onCancel(); // Close modal AFTER success message
      } else {
        message.error(response.data.message || "Failed to create appointment.");
      }
    }
  };

  const handleAppointTypeChange = () => {
    if (appointmentType == "individual") {
      setAppointmentType("group");
      setCustomers(
        selectedCustomer
          ? [{ ...selectedCustomer, services: [] }]
          : [{ services: [] }]
      );
    } else {
      setAppointmentType("individual");

      setCustomers(
        selectedCustomer
          ? [{ ...selectedCustomer, services: [] }]
          : [{ services: [] }]
      );
    }
  };

  const handleDeleteCustomer = (index: number) => {
    setCustomers((prevCustomers) => {
      const isOnlyOneCustomer = prevCustomers.length === 1;

      if (isOnlyOneCustomer) {
        // Handle both individual and group with one customer
        return [{ ...prevCustomers[0], services: [] }];
      } else {
        // Group with multiple customers: remove the customer at index
        return prevCustomers.filter((_, i) => i !== index);
      }
    });
  };

  const handleEditCustomer = (index: number) => {
    setSelectedCustomerIndex(index);
    setSelectedServices(customers[index].services);
    setShowDetailsModal(true);
  };

  const getAppointmentDetails = async () => {
    try {
      setLoading(true);
      const response = await get(
        `/appointment/${appintmentdata?.id}/${appintmentdata?.staffid}/${appintmentdata?.date}/${appintmentdata?.time}`
      );
      if (response.data.success) {
        const appointmentData = response.data.data;
        setAppointmentType(
          appointmentData.appointment_type === "group appointment"
            ? "group"
            : "individual"
        );

        // Map customers from API response
        const mappedCustomers = appointmentData.customers.map(
          (customer: any) => ({
            customerid: customer.customerid,
            name: `${customer.firstname} ${customer.lastname}`,
            phone: `+${customer.countrycode}${customer.phonenumber}`,
            email: customer.email,
            dob: customer.dob,
            lastArrivedDate: customer.lastArrivedDate,
            services: customer.services.map((service: any) => ({
              id: service.serviceid,
              name: service.servicename,
              staff: service.staffid,
              staffname: service.staffname,
              duration: service.duration,
              time: service.time
                ? dayjs(service.time, "DD/MM/YYYY hh:mma").format(
                    "DD/MM/YYYY hh:mm A"
                  ) // ✅ Convert to proper format
                : null,
            })),
          })
        );

        setAppointmentNote(appointmentData.note);

        fetchCoustomerCountData(appointmentData.customers[0].customerid);

        setCustomers(mappedCustomers);
        setSelectedCustomer(
          mappedCustomers.length > 0 ? mappedCustomers[0] : null
        );
      } else {
        console.error(
          "Failed to fetch merchant details:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error fetching merchant details:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      setIsCustomerPrivilegeVisible(false);
      setCustomers([{ services: [] }]);
      setAppointmentNote("");
      setSelectedCustomer(null);
      setAppointmentType("individual");
      getStaffDropdown();
      setSelectedServices([]);
      fetchServiceCategory();
      fetchServices();

      if (appintmentdata) {
        getAppointmentDetails();
        setAppointmentModalTitle("Edit Appointment");
      }
    }
  }, [visible]);

  const onDetailsCancel = () => {
    setShowDetailsModal(false);
  };

  const handleCancelDetailModal = () => {
    setShowDetailsModal(false);
  };

  const handleValidation = () => {
    return selectedServices.every(
      (service: any) => service.time && service.staffname
    );
  };

  const handleSave = () => {
    setShowErrors(true); // ✅ Show errors only when Save is clicked

    if (handleValidation()) {
      // onSelect(selectedServices);
      setIsServiceModalVisible(false);

      if (selectedCustomerIndex !== null) {
        setCustomers((prevCustomers) =>
          prevCustomers.map((customer, index) =>
            index === selectedCustomerIndex
              ? { ...customer, services: selectedServices }
              : customer
          )
        );
        setSelectedServices([]);
      }
      setShowDetailsModal(false);
      setIsServiceModalVisible(false);
    }
  };

  const handleServicesSubmit = () => {
    // onSelect(selectedServices);
    setIsServiceModalVisible(false);
    setShowDetailsModal(true);
    setShowErrors(false);
  };

  const showStaffModal = (serviceId: number) => {
    setCurrentServiceId(serviceId);
    setIsStaffModalVisible(true);
    setSelectedStaff(null);
  };

  const handleTimeSelected = (time: string) => {
    if (currentServiceId !== null) {
      setSelectedServices((prev) =>
        prev.map((service) =>
          service.id === currentServiceId ? { ...service, time } : service
        )
      );
      setIsTimeModalVisible(false);
    }
  };

  const handleRemoveService = (id: number) => {
    setSelectedServices((prev) => prev.filter((service) => service.id !== id));
  };

  const showTimeModal = (serviceId: number) => {
    setCurrentServiceId(serviceId);
    setIsTimeModalVisible(true);
  };

  const handleSaveStaff = () => {
    if (currentServiceId !== null && selectedStaff) {
      const currentService = selectedServices.find(
        (service) => service.id === currentServiceId
      );

      // ✅ Check for staff conflict in group appointments using `customers` array
      if (appointmentType === "group") {
        for (let customer of customers) {
          for (let service of customer.services) {
            if (
              service.staff === selectedStaff.id &&
              service.time === currentService?.time
            ) {
              message.error(
                `Staff ${selectedStaff.name} is already booked for another customer at this time.`
              );
              return;
            }
          }
        }
      }

      setSelectedServices((prev) =>
        prev.map((service) =>
          service.id === currentServiceId
            ? {
                ...service,
                staff: selectedStaff.id,
                staffname: selectedStaff.name,
              }
            : service
        )
      );
      setIsStaffModalVisible(false);
      setSelectedStaff(null);
    }
  };

  const handleServiceItemSearch = (e: any) => {
    setSearchServiceValue(e.target.value);
    let filteredServices = [];

    if (e.target.value !== "") {
      filteredServices = serviceItems.filter((service) =>
        service.name.includes(e.target.value)
      );
    } else {
      filteredServices = serviceItems;
    }

    setTempServiceItems(filteredServices);
  };

  const handleClearServiceSearch = () => {
    setSearchServiceValue("");
  };

  const fetchServiceCategory = async () => {
    try {
      const response = await get(
        `/category/getlistwithoutpagination/${localStorage.getItem(
          "outlet_id"
        )}/Service`
      );
      if (response.data.success) {
        let data = response.data.data;
        data.unshift({
          id: "null",
          name: "All ",
        });
        setServiceCategory(data);
        setSelectedServiceTab("null");
      } else {
        message.error("Failed to fetch service category");
      }
    } catch (error) {
      console.error("Error fetching service category: ", error);
      message.error("Error fetching service category");
    }
  };

  const fetchServices = async () => {
    try {
      const response = await get(
        `/billing/items/${localStorage.getItem(
          "outlet_id"
        )}?type=Service&categoryid=null`
      );
      if (response.data.success) {
        setServiceItems(response.data.data);
        setTempServiceItems(response.data.data);
      } else {
        message.error("Failed to fetch services");
      }
    } catch (error) {
      console.error("Error fetching services: ", error);
      message.error("Error fetching services");
    }
  };

  const handleCategorySelect = (categoryId: string) => {
    setSelectedServiceTab(categoryId);

    if (categoryId === "null") {
      setTempServiceItems(serviceItems); // Show all services when "All" is selected
    } else {
      setTempServiceItems(
        serviceItems.filter((service) => service.categorycode === categoryId)
      );
    }
  };

  const handleCustomerToggle = () => {
    // setIsCustomerPrivilegeVisible(!isCustomerPrivilegeVisible);
    setIsCustomerPrivilegeVisible((prevState) => {
      const newState = !prevState;
      // Call the API only when the toggle is opened (newState becomes true)
      return newState;
    });
  };

  // const handleSelectService = (service: {
  //   id: number;
  //   name: string;
  //   price: string;
  // }) => {
  //   setSelectedServices((prevSelected) =>
  //     prevSelected.some((s) => s.id === service.id)
  //       ? prevSelected.filter((s) => s.id !== service.id)
  //       : [...prevSelected, service]
  //   );
  // };

  return (
    <>
      <Modal
        title={appointmentModalTitle}
        visible={visible}
        onCancel={onCancel}
        className="normal-blue-modal"
        footer={[
          <>
            <Divider style={{ margin: "10px 0px" }} key="divider" />
            <Button onClick={onCancel}>Cancel</Button>
            <Button key="save" type="primary" onClick={handleSubmit}>
              Save
            </Button>
          </>,
        ]}
        style={{ top: "10px" }}
        bodyStyle={{
          height: "calc(100vh - 180px)",
          overflow: "hidden",
          overflowY: "auto",
        }}
        width={900}
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "200px", // optional: to ensure there's space
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            <Form layout="vertical">
              {!selectedCustomer && (
                <>
                  <Row gutter={16}>
                    <Col span={9}>
                      <Form.Item label={null}>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "8px",
                          }}
                        >
                          <label>{"Customer name"}</label>
                          <a onClick={showModal} style={{ color: "#ff9d43" }}>
                            + select existing customer
                          </a>
                        </div>
                        <Input
                          value={selectedCustomer?.name || ""}
                          placeholder="Please enter"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col span={9}>
                      <Form.Item label={"Phone"}>
                        <Input
                          value={selectedCustomer?.phone || ""}
                          placeholder="Please enter"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={16}>
                    <Col span={9}>
                      <Form.Item label={"Email"}>
                        <Input
                          value={selectedCustomer?.email || ""}
                          placeholder="Please enter"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              {selectedCustomer && (
                <div
                  style={{
                    // background: `transparent url(${OrderCustomerIcon}) 50% / 100% 100% no-repeat`,
                    backgroundImage:
                      "radial-gradient(circle, rgba(126,130,153,1) 0%, rgba(85,90,110,1) 100%)",
                    color: "white",
                    marginTop: "10px",
                    borderRadius: "8px",
                    padding: "10px",
                    overflow: "hidden", // ✅ Required for radius effect!
                    border: "1px solid rgba(255, 255, 255, 0.2)", // Optional
                    position: "relative",
                  }}
                >
                  <Row
                    gutter={[16, 16]}
                    style={{
                      position: "relative",
                      marginTop: "0px",
                      marginBottom: "10px",
                    }}
                  >
                    <Col span={23} xs={23} lg={23} sm={23} md={23}>
                      <Text style={{ color: "#fff", display: "block" }}>
                        <strong>Name:</strong> {selectedCustomer?.name}
                      </Text>
                    </Col>
                    <Col
                      span={1}
                      style={{
                        position: "absolute",
                        top: "-10px",
                        right: "0px",
                      }}
                    >
                      <div
                        onClick={() => setIsDeleteMemberModalVisible(true)}
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                        }}
                      >
                        <CloseOutlined
                          style={{
                            fontSize: "16px",
                            color: "#c2cadd",
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={12} xs={24} lg={12} sm={24} md={24}>
                      <Text style={{ color: "#fff", display: "block" }}>
                        <strong>Email:</strong> {selectedCustomer.email || "-"}
                      </Text>
                    </Col>
                    <Col span={12} xs={24} lg={12} sm={24} md={24}>
                      <Text style={{ color: "#fff", display: "block" }}>
                        <strong>Birthday:</strong> {selectedCustomer.dob || "-"}
                      </Text>
                    </Col>
                    <Col span={12} xs={24} lg={12} sm={24} md={24}>
                      <Text style={{ color: "#fff", display: "block" }}>
                        <strong>Phone:</strong>
                        {selectedCustomer?.phone}
                      </Text>
                    </Col>
                    <Col span={12} xs={24} lg={12} sm={24} md={24}>
                      <Text style={{ color: "#fff", display: "block" }}>
                        <strong>Last Arrival:</strong>{" "}
                        {selectedCustomer?.lastArrivedDate || "-"}
                      </Text>
                    </Col>
                  </Row>
                  <div
                    style={{
                      marginTop: "5px",
                      borderTop: ".5px solid hsla(0,0%,100%,.3)",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      cursor: "pointer",
                    }}
                    onClick={handleCustomerToggle}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "2px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        className=""
                        style={{
                          width: "25%",
                          height: "25%",
                          objectFit: "contain",
                        }}
                        src={memberInfoPrivilege}
                        alt="img"
                      ></img>
                      <div>
                        <p
                          style={{
                            fontWeight: 700,
                          }}
                        >
                          {!isCustomerPrivilegeVisible
                            ? "Privilege"
                            : "Membership"}
                        </p>
                      </div>
                    </div>

                    {!isCustomerPrivilegeVisible ? (
                      <DownOutlined
                        style={{
                          fontSize: "12px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <UpOutlined
                        style={{
                          fontSize: "12px",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                      />
                    )}
                  </div>
                  {isCustomerPrivilegeVisible && (
                    <>
                      {/* ------ Membership details ------ */}
                      <Row>
                        <Col span={8}>
                          <Text
                            style={{
                              fontSize: "14px",
                              color: "hsla(0,0%,100%,.65)",
                            }}
                          >
                            Stored / Bonus balance:
                          </Text>
                        </Col>
                        <Col span={8}>
                          <div
                            style={{
                              marginLeft: "10px",
                              display: "flex",
                              alignItems: "center",
                              color: "#c0c5cf",
                              gap: "2px",
                              cursor: "pointer",
                            }}
                            // onClick={handleCustomerPrepaidModal}
                          >
                            <Text
                              style={{
                                fontSize: "14px",
                                color: "#fff",
                                fontWeight: 600,
                              }}
                            >
                              {currencySymbol.CURRENCY_SYMBOL}
                              {customerCount?.find(
                                (item) => item.type === "Prepaid"
                              )?.remaining_balance
                                ? Number.isInteger(
                                    customerCount.find(
                                      (item) => item.type === "Prepaid"
                                    )?.remaining_balance
                                  )
                                  ? customerCount.find(
                                      (item) => item.type === "Prepaid"
                                    )?.remaining_balance
                                  : customerCount
                                      .find((item) => item.type === "Prepaid")
                                      ?.remaining_balance.toFixed(2)
                                : 0}
                              /{currencySymbol.CURRENCY_SYMBOL}
                              {customerCount?.find(
                                (item) => item.type === "Prepaid"
                              )?.remaining_bouns
                                ? Number.isInteger(
                                    customerCount.find(
                                      (item) => item.type === "Prepaid"
                                    )?.remaining_bouns
                                  )
                                  ? customerCount.find(
                                      (item) => item.type === "Prepaid"
                                    )?.remaining_bouns
                                  : customerCount
                                      .find((item) => item.type === "Prepaid")
                                      ?.remaining_bouns.toFixed(2)
                                : 0}
                            </Text>
                          </div>
                        </Col>
                      </Row>

                      <div
                        style={{
                          display: "flex",
                          marginTop: "5px",
                        }}
                      >
                        <Col span={8}>
                          <Text
                            style={{
                              fontSize: "14px",
                              color: "hsla(0,0%,100%,.65)",
                            }}
                          >
                            Visit-based:
                          </Text>
                        </Col>
                        <Col span={4}>
                          <div
                            style={{
                              marginLeft: "10px",
                              display: "flex",
                              alignItems: "center",
                              color: "#c0c5cf",
                              gap: "2px",
                              cursor: "pointer",
                            }}
                            // onClick={handleCustomerVistbasedModal}
                          >
                            <Text
                              style={{
                                fontSize: "14px",
                                color: "#fff",
                              }}
                            >
                              {customerCount?.find(
                                (item) => item.type === "Visit-based"
                              )?.total_count || 0}
                            </Text>
                          </div>
                        </Col>
                        <Col span={4}>
                          <Text
                            style={{
                              fontSize: "14px",
                              color: "hsla(0,0%,100%,.65)",
                            }}
                          >
                            Discount:
                          </Text>
                        </Col>
                        <Col span={4}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#c0c5cf",
                              gap: "2px",
                              cursor: "pointer",
                            }}
                            // onClick={handleCustomerDiscountModal}
                          >
                            <Text
                              style={{
                                fontSize: "14px",
                                color: "#fff",
                              }}
                            >
                              {customerCount?.find(
                                (item) => item.type === "Discount"
                              )?.total_count || 0}
                            </Text>
                          </div>
                        </Col>
                      </div>

                      {/* ------ Debt details ------ */}
                      <Row
                        gutter={16}
                        style={{
                          marginTop: "10px",
                          marginLeft: "1px",
                        }}
                      >
                        <Col span={8}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              className=""
                              style={{
                                width: "16px",
                                height: "16px",
                                objectFit: "contain",
                              }}
                              src={memberInfoGiftCard}
                              alt="img"
                            ></img>
                            <div>
                              <Text
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "14px",
                                  color: "#fff",
                                  fontWeight: "700",
                                }}
                              >
                                Gift Card
                              </Text>
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#c0c5cf",
                              gap: "2px",
                              cursor: "pointer",
                            }}
                            // onClick={handleCustomerGiftCardModal}
                          >
                            <Text
                              style={{
                                fontSize: "14px",
                                color: "#fff",
                              }}
                            >
                              {/* {selectedCustomer?.all_giftcard} */}
                              {customerCount?.find(
                                (item) => item.type === "Giftcard"
                              )?.total_count || 0}
                            </Text>
                          </div>
                        </Col>
                      </Row>

                      {/* ------ Package details ------ */}
                      <Row
                        gutter={16}
                        style={{
                          marginLeft: "1px",
                        }}
                      >
                        <Col span={8}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              className=""
                              style={{
                                width: "16px",
                                height: "16px",
                                objectFit: "contain",
                              }}
                              src={MemberInfoPackage}
                              alt="img"
                            ></img>
                            <div>
                              <Text
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "14px",
                                  color: "#fff",
                                  fontWeight: "700",
                                }}
                              >
                                Package
                              </Text>
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#c0c5cf",
                              gap: "2px",
                              cursor: "pointer",
                            }}
                            // onClick={handleCustomerPackageModal}
                          >
                            <Text
                              style={{
                                fontSize: "14px",
                                color: "#fff",
                              }}
                            >
                              {customerCount?.find(
                                (item) => item.type === "Package"
                              )?.total_count || 0}
                            </Text>
                          </div>
                        </Col>
                      </Row>
                      {/* ------ Gift card details ------ */}
                      <Row
                        gutter={16}
                        style={{
                          marginLeft: "1px",
                        }}
                      >
                        <Col span={8}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              className=""
                              style={{
                                width: "16px",
                                height: "16px",
                                objectFit: "contain",
                              }}
                              src={memberInfoGiftCard}
                              alt="img"
                            ></img>
                            <div>
                              <Text
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "14px",
                                  color: "#fff",
                                  fontWeight: "700",
                                }}
                              >
                                Due Balance
                              </Text>
                            </div>
                          </div>
                        </Col>
                        <Col span={8}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              color: "#c0c5cf",
                              gap: "2px",
                              cursor: "pointer",
                            }}
                            // onClick={handleCustomerDebtModal}
                          >
                            <Text
                              style={{
                                fontSize: "14px",
                                color: "#fff",
                              }}
                            >
                              {/* {selectedCustomer?.all_giftcard} */}
                              {customerCount?.find(
                                (item) => item.type === "Debt"
                              )?.total_count
                                ? Number.isInteger(
                                    customerCount.find(
                                      (item) => item.type === "Debt"
                                    )?.total_count
                                  )
                                  ? customerCount.find(
                                      (item) => item.type === "Debt"
                                    )?.total_count
                                  : parseFloat(
                                      customerCount.find(
                                        (item) => item.type === "Debt"
                                      )?.total_count
                                    ).toFixed(2)
                                : 0}
                            </Text>
                          </div>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>
              )}

              {/* Custom Tab-Like Selection */}
              {/* <Row gutter={16}>
            <Col span={14}>
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                }}
              >
                <div
                  onClick={handleAppointTypeChange}
                  style={{
                    padding: "10px 20px",
                    borderRadius:
                      appointmentType === "individual"
                        ? "6px"
                        : "6px 0px 0px 6px",
                    backgroundColor:
                      appointmentType === "individual" ? "#1890ff" : "#f0f0f0",
                    color: appointmentType === "individual" ? "#fff" : "#000",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  Individual Appointment
                </div>

                <div
                  onClick={handleAppointTypeChange}
                  style={{
                    padding: "10px 20px",
                    borderRadius:
                      appointmentType === "group" ? "6px" : "0px 6px 6px 0px",
                    backgroundColor:
                      appointmentType === "group" ? "#1890ff" : "#f0f0f0",
                    color: appointmentType === "group" ? "#fff" : "#000",
                    cursor: "pointer",
                    transition: "background-color 0.3s ease",
                  }}
                >
                  Group Appointment
                </div>
              </div>
            </Col>
          </Row> */}
              {customers.map((customer, index) => (
                <div key={index} style={{ marginTop: "10px" }}>
                  <>
                    {appointmentType === "group" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <strong>Service - Customer {index + 1}</strong>
                          <div>
                            {customer.services &&
                              customer.services.length > 0 && (
                                <Button
                                  type="link"
                                  danger
                                  onClick={() => handleEditCustomer(index)}
                                >
                                  Edit
                                </Button>
                              )}
                            {(customers.length > 1 ||
                              (customer.services &&
                                customer.services.length > 1)) && (
                              <Button
                                type="link"
                                danger
                                onClick={() => handleDeleteCustomer(index)}
                              >
                                Delete
                              </Button>
                            )}
                          </div>
                        </div>
                      </>
                    )}
                    {appointmentType === "individual" && (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <strong>Services</strong>
                          {customer.services &&
                            customer.services.length > 0 && (
                              <div>
                                <Button
                                  type="link"
                                  danger
                                  onClick={() => handleEditCustomer(index)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  type="link"
                                  danger
                                  onClick={() => handleDeleteCustomer(index)}
                                >
                                  Delete
                                </Button>
                              </div>
                            )}
                        </div>
                      </>
                    )}
                    {customer.services && customer.services.length > 0 && (
                      <>
                        <div>
                          {customer.services &&
                            customer.services.length > 0 &&
                            customer.services.map((service) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                  borderRadius: "8px",
                                }}
                              >
                                <div
                                  key={service.id}
                                  style={{
                                    display: "flex",
                                    alignItems: "flex-start",
                                    flexDirection: "column",
                                    padding: "10px",
                                    marginBottom: "10px",
                                    background: "#f7f8fa",
                                    gap: "10px",
                                  }}
                                >
                                  {/* Service Name */}
                                  <div>
                                    <span
                                      style={{
                                        fontWeight: "700",
                                        color: "#000",
                                      }}
                                    >
                                      {service.name}
                                    </span>
                                  </div>

                                  {/* Date & Time */}
                                  <Row gutter={16} style={{ width: "100%" }}>
                                    <Col
                                      span={5}
                                      style={{
                                        color: "#9e9e9e",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {service.time
                                        ? dayjs(
                                            service.time,
                                            [
                                              "YYYY-MM-DD HH:mm",
                                              "DD/MM/YYYY hh:mm A",
                                            ],
                                            true
                                          ).format("DD/MM/YYYY h:mma")
                                        : "Select Time"}
                                    </Col>

                                    {/* Duration */}
                                    <Col
                                      span={3}
                                      style={{
                                        color: "#9e9e9e",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {`${service.duration} min`}
                                    </Col>

                                    {/* Assigned Staff */}
                                    <Col
                                      span={3}
                                      style={{
                                        color: "#9e9e9e",
                                        fontSize: "14px",
                                      }}
                                    >
                                      {service.staffname || "No Staff"}
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            ))}
                        </div>
                      </>
                    )}
                    {customer.services.length == 0 && (
                      <div
                        style={{
                          background: "#f7f8fa",
                          padding: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <Button
                          style={{ color: "#ff9038" }}
                          type="link"
                          onClick={() => showServiceModalPopup(index)}
                        >
                          + Add Service
                        </Button>
                      </div>
                    )}
                  </>
                </div>
              ))}

              {/* Show "Add customer" button only for Group appointment */}
              {appointmentType === "group" && (
                <Row gutter={16}>
                  <Col span={9} style={{ marginTop: "10px" }}>
                    <span
                      style={{
                        color: "#ff9038",
                      }}
                      onClick={handleAddNewCustomer}
                    >
                      + Add customer
                    </span>
                  </Col>
                </Row>
              )}
              <Row gutter={16} style={{ marginTop: "20px" }}>
                <Col span={24}>
                  <Form.Item label="Notes">
                    <Input.TextArea
                      placeholder="Add notes"
                      value={appointmentNote}
                      onChange={(e) => setAppointmentNote(e.target.value)}
                      rows={4}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </Modal>

      <SearchCustomerModel
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onSelect={handleSelectCustomer}
      />

      {/* <SelectServicesIndividualAppointmentModal
        visible={isServiceModalVisible}
        type={appointmentType}
        customers={customers}
        onCancel={() => setIsServiceModalVisible(false)}
        onSelect={handleSelectService}
      /> */}
      <Modal
        title="Select Service"
        open={isServiceModalVisible}
        onCancel={() => setIsServiceModalVisible(false)}
        footer={[
          <>
            {" "}
            <Divider style={{ margin: "10px 0px" }} />
            <Row justify="space-between">
              <Text strong>
                Selected:{" "}
                <span style={{ color: "blue" }}>{selectedServices.length}</span>{" "}
                item(s)
              </Text>
              <div>
                <Button onClick={() => setIsServiceModalVisible(false)}>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  style={{ background: "#2e56f2", marginLeft: "10px" }}
                  onClick={handleServicesSubmit}
                >
                  Save
                </Button>
              </div>
            </Row>
          </>,
        ]}
        width={900}
        style={{ top: "10px" }}
        className="service-category-modal"
        bodyStyle={{
          height: "calc(100vh - 170px)",
          overflow: "hidden",
          overflowY: "auto",
        }}
      >
        <Form layout="vertical">
          <Row gutter={10} align="middle" style={{ marginBottom: "15px" }}>
            <Col span={6}></Col>
            <Col span={18}>
              <Input
                placeholder="Search by service name"
                allowClear
                value={searchServiceValue}
                onChange={handleServiceItemSearch}
                onClear={handleClearServiceSearch}
                prefix={<SearchOutlined />}
                style={{ width: "50%" }}
              />
            </Col>
          </Row>

          <Row gutter={0} style={{ borderTop: "1px solid #ebeffc" }}>
            {/* Service Categories */}
            <Col
              span={6}
              style={{
                padding: "15px",
                maxHeight: "calc(100vh - 230px)",
                minHeight: "calc(100vh - 230px)",
                overflowY: "auto",
                borderRight: "1px solid #ebeffc",
              }}
            >
              {serviceCategory.map((category) => (
                <Button
                  key={category.id}
                  type="text"
                  onClick={() => handleCategorySelect(category.id)}
                  style={{
                    width: "100%",
                    justifyContent: "flex-start",
                    textAlign: "left",
                    marginBottom: "8px",
                    padding: "12px",
                    fontSize: "16px",
                    borderRadius: "0px",
                    fontWeight:
                      selectedServiceTab === category.id ? "bold" : "normal",
                    color:
                      selectedServiceTab === category.id ? "#0052CC" : "#000",
                    backgroundColor:
                      selectedServiceTab === category.id
                        ? "#F0F5FF"
                        : "transparent",
                    borderLeft:
                      selectedServiceTab === category.id
                        ? "4px solid #0052CC"
                        : "4px solid transparent",
                    transition: "all 0.3s ease-in-out",
                    whiteSpace: "normal",
                    height: "auto",
                  }}
                >
                  {category.name}
                </Button>
              ))}
            </Col>

            {/* Service List */}
            <Col
              span={18}
              flex="auto"
              style={{
                padding: "15px",
                maxHeight: "calc(100vh - 230px)",
                minHeight: "calc(100vh - 230px)",
                overflowY: "auto",
              }}
            >
              {tempServiceItems.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {tempServiceItems.map((service) => (
                    <div
                      key={service.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px",
                        background: selectedServices.some(
                          (s) => s.id === service.id
                        )
                          ? "#e6f7ff"
                          : "white",
                      }}
                    >
                      <Checkbox
                        className="service-checkbox"
                        checked={selectedServices.some(
                          (s) => s.id === service.id
                        )}
                        onChange={() => handleSelectService(service)}
                        style={{ marginRight: "10px" }}
                      />
                      <span style={{ flex: 1 }}>{service.name}</span>
                      <span style={{ fontWeight: 600, color: "#ff5733" }}>
                        {currencySymbol.CURRENCY_SYMBOL}
                        {parseFloat(service.sellprice).toFixed(2)}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <Text
                  type="secondary"
                  style={{ textAlign: "center", marginTop: "20px" }}
                >
                  No services available
                </Text>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Appointment Details"
        open={showDetailsModal}
        onCancel={onDetailsCancel}
        className="normal-blue-modal"
        footer={[
          <>
            <Divider style={{ margin: "10px 0px" }} />
            <Row justify="end">
              <Button onClick={handleCancelDetailModal}>Cancel</Button>
              <Button
                type="primary"
                onClick={handleSave}
                style={{ background: "#2e56f2", marginLeft: "10px" }}
              >
                Save
              </Button>
            </Row>
          </>,
        ]}
        style={{ top: "10px" }}
        bodyStyle={{
          height: "calc(100vh - 180px)",
          overflow: "hidden",
          overflowY: "auto",
        }}
        width={900}
      >
        <Form layout="vertical" form={form}>
          {selectedServices.map((service: any) => (
            <div
              key={service.id}
              style={{
                padding: "15px",
                backgroundColor: "#f6f7fb",
                marginBottom: "10px",
                borderRadius: "8px",
              }}
            >
              <Row gutter={16} style={{ position: "relative" }}>
                <Col span={9}>
                  <Form.Item label="Service">
                    <Input
                      value={service.name}
                      readOnly
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item label="Duration (min)">
                    <Input
                      placeholder="Enter duration"
                      value={service.duration}
                      readOnly
                    />
                  </Form.Item>
                </Col>
                <Col span={6} style={{ textAlign: "right", top: 0 }}>
                  <Button
                    style={{
                      position: "absolute",
                      top: "-20px",
                      right: "-10px",
                      borderRadius: "50%",
                      height: "30px",
                      width: "30px",
                    }}
                    type="primary"
                    danger
                    onClick={() => handleRemoveService(service.id)}
                  >
                    -
                  </Button>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={9}>
                  <Form.Item
                    label="Appointment Time"
                    validateStatus={showErrors && !service.time ? "error" : ""}
                    help={
                      showErrors && !service.time ? "Please select a time" : ""
                    }
                  >
                    <Input
                      placeholder="Please select"
                      value={
                        service.time
                          ? dayjs(service.time, "DD/MM/YYYY hh:mm A").format(
                              "DD/MM/YYYY hh:mm A"
                            )
                          : ""
                      }
                      onClick={() => showTimeModal(service.id)}
                      readOnly
                      suffix={<RightOutlined />}
                    />
                  </Form.Item>
                </Col>
                <Col span={9}>
                  <Form.Item
                    label="Staff"
                    validateStatus={
                      showErrors && !service.staffname ? "error" : ""
                    }
                    help={
                      showErrors && !service.staffname
                        ? "Please select a staff member"
                        : ""
                    }
                  >
                    <Input
                      placeholder="Please select"
                      value={service.staffname || ""}
                      onClick={() => showStaffModal(service.id)}
                      readOnly
                      suffix={<RightOutlined />}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ))}
          <div style={{ gap: "10px" }}>
            <Button
              type="primary"
              style={{
                color: "#ff5733",
                background: "#fff",
                border: "none",
                boxShadow: "none",
              }}
              onClick={handleAddService}
            >
              <PlusOutlined />
              Add service
            </Button>
          </div>
        </Form>
      </Modal>

      <Modal
        title="Select Staff"
        className="normal-blue-modal"
        open={isStaffModalVisible}
        onCancel={() => setIsStaffModalVisible(false)}
        footer={[
          <>
            <Divider style={{ margin: "10px 0px" }} />
            <Row justify="end">
              <Button onClick={() => setIsStaffModalVisible(false)}>
                Cancel
              </Button>
              <Button
                type="primary"
                style={{ background: "#2e56f2", marginLeft: "10px" }}
                onClick={handleSaveStaff}
              >
                Save
              </Button>
            </Row>
          </>,
        ]}
        style={{ top: "10px" }}
        bodyStyle={{
          height: "calc(100vh - 180px)",
          overflow: "hidden",
          overflowY: "auto",
        }}
        width={900}
      >
        <Row gutter={[16, 16]} justify="start">
          {/* Align to Left */}
          {staffData.map((staff) => (
            <Col key={staff.id} style={{ gap: "10px" }} span={4}>
              {/* 5 Columns per Row */}
              <Button
                type={selectedStaff?.id === staff.id ? "primary" : "default"}
                onClick={() => setSelectedStaff(staff)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  width: "120px",
                  padding: "10px",
                  borderRadius: "8px",
                  textAlign: "center",
                }}
              >
                <img
                  src={staff.profile_pic}
                  alt="staff"
                  height={60}
                  width={60}
                  style={{ borderRadius: "50%" }}
                />
                <Text
                  strong
                  style={{
                    marginTop: "5px",
                    wordBreak: "break-word", // Breaks long words
                    overflowWrap: "break-word", // Ensures text wraps within button
                    whiteSpace: "normal", // Allows multiple lines
                    display: "block", // Ensures text takes full width inside button
                    maxWidth: "100%", // Ensures it stays within the button
                  }}
                >
                  {staff.name}
                </Text>
              </Button>
            </Col>
          ))}
        </Row>
      </Modal>

      <AppointmentTimeModal
        visible={isTimeModalVisible}
        onCancel={() => setIsTimeModalVisible(false)}
        onTimeSelect={handleTimeSelected}
      />
      <ConfirmDeleteModal
        visible={isDeleteMemberModalVisible}
        onCancel={closeDeleteMemberModal}
        // onConfirm={() => handleDeleteApi(editingRecord.key)}
        onConfirm={handleConfirmMember}
        message="Are you sure you want to remove the customer?"
      />
    </>
  );
};

export default AddAppointment;
