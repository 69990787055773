import { Button, Card, Col, Divider, Flex, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons"; // Import check icon
import Header from "../headerFooter/Header";
import Footer from "../headerFooter/footer";
import { get } from "../../services/Apiclient";

const { Text } = Typography;
const defaultStoreImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Store.png`;

const imgStyle: React.CSSProperties = {
  display: "block",
  width: "70px",
  height: "70px",
  borderRadius: "30px",
};

const OutletAppList: React.FC = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const [outletData, setOutletData] = useState<any[]>([]);
  const [selectedOutlet, setSelectedOutlet] = useState<string | null>(null);
  const [selectedOutletId, setSelectedOutletId] = useState<string | null>(null);

  useEffect(() => {
    // Scroll to the top of the page with smooth behavior on component mount
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Makes the scroll smooth
    });
    // Retrieve selected outlet from localStorage
    // const storedOutlet = localStorage.getItem("selectedOutlet");
    // const storedOutletId = localStorage.getItem("selectedOutletId");
    // if (storedOutlet && storedOutletId) {
    //   setSelectedOutlet(storedOutlet);
    //   setSelectedOutletId(storedOutletId);
    // }
    getOutelet();
  }, []);

  const getOutelet = async () => {
    try {
      const url = `/appointmentmob/mobile/${merchantId}`;
      const response = await get(url);
      const data = response?.data;
      if (data?.success) {
        setOutletData(data.data);
      }
    } catch (error) {
      console.error("Error while retriving outlet list:", error);
    }
  };

  const handleSelectOutlet = (outlet: any) => {
    setSelectedOutlet(outlet.name);
    setSelectedOutletId(outlet.id);
    localStorage.setItem("selectedOutlet", outlet.name);
    localStorage.setItem("selectedOutletId", outlet.id);
  };

  return (
    <>
      <div style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
        <Header
          title="Booking"
          onBack={() => navigate(`/app/appointment/list/${merchantId}`)}
          backgroundColor={"#fff"}
        />
        {/* Main container with dynamic padding-bottom */}
        <div
          style={{
            padding: "16px",
            paddingTop: "70px",
            paddingBottom: selectedOutlet ? "190px" : "80px", // Extra space when extra footer is shown
          }}
        >
          <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
            {outletData &&
              outletData.map((outlet) => (
                <Col span={24} key={outlet.id}>
                  <Card
                    hoverable
                    style={{
                      overflow: "hidden",
                      borderRadius: "8px",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      position: "relative", // Needed for icon positioning
                      backgroundColor:
                        selectedOutlet === outlet.name ? "#f0f0f0" : "#fff", // Change background when selected
                    }}
                    onClick={() => handleSelectOutlet(outlet)}
                    // onClick={() => setSelectedOutlet(outlet.name)} // Only selects one outlet at a time
                  >
                    <Flex align="center">
                      <img
                        alt="avatar"
                        src={defaultStoreImage}
                        style={imgStyle}
                      />
                      <Col style={{ flexGrow: 1, marginLeft: "5px" }}>
                        <Text style={{ fontWeight: 500, display: "block" }}>
                          {outlet.name}
                        </Text>
                        <Text
                          style={{
                            display: "block",
                            marginTop: "5px",
                            fontWeight: 500,
                          }}
                        >
                          {outlet.address}
                        </Text>
                      </Col>
                      {/* Keep space for the check icon, but only show it when selected */}
                      <div style={{ width: "24px", textAlign: "center" }}>
                        {selectedOutlet === outlet.name && (
                          <CheckOutlined
                            style={{ fontSize: "24px", color: "green" }}
                          />
                        )}
                      </div>
                    </Flex>
                  </Card>
                </Col>
              ))}
          </Row>
        </div>

        {/* Extra footer only if an outlet is selected */}
        {selectedOutlet && (
          <div
            style={{
              width: "100%",
              textAlign: "center",
              position: "fixed",
              bottom: "60px", // Adjusted position to be above Footer
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "#fff",
              padding: "12px 16px",
              zIndex: 1000,
              maxWidth: "90%", // Ensure it fits well
              //   boxShadow: "0 -2px 8px rgba(0, 0, 0, 0.1)", // Slight shadow effect
            }}
          >
            <Divider style={{ marginTop: 0 }} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ textAlign: "left", flex: 1 }}>
                <Text
                  style={{ display: "block", fontSize: "14px", color: "#666" }}
                >
                  Outlet Selected
                </Text>
                <Text
                  style={{
                    display: "block",
                    fontSize: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {selectedOutlet}
                </Text>
              </div>
              <Button
                type="primary"
                size="large"
                style={{
                  backgroundColor: "orange",
                  color: "white",
                  fontSize: "16px",
                  borderRadius: "8px",
                  maxWidth: "150px",
                  width: "40%",
                }}
                onClick={() =>
                  navigate(`/app/servicestaff/list/${merchantId}`, {
                    state: { selectedOutlet, selectedOutletId },
                  })
                }
              >
                Next
              </Button>
            </div>
          </div>
        )}

        <Footer />
      </div>
    </>
  );
};

export default OutletAppList;
