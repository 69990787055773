import { CalendarOutlined, ShopOutlined } from "@ant-design/icons";
import { Button, Card, Col, Empty, Flex, Row, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get } from "../../services/Apiclient";
import Footer from "../headerFooter/footer";
const { Text } = Typography;
const defaultStoreImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Store.png`;
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;

const imgStyle: React.CSSProperties = {
  display: "block",
  width: "70px",
  height: "70px",
  padding: "12px",
  paddingTop: 0,
  borderRadius: "20px",
};

const buttonStyle = (isActive: boolean): React.CSSProperties => ({
  backgroundColor: isActive ? "black" : "pink",
  color: isActive ? "orange" : "black",
  border: isActive ? "0px solid black" : "0px solid orange",
  fontWeight: isActive ? "bold" : "normal",
});

type Section = "Upcoming" | "Finished" | "Cancelled";

const AppointmentList: React.FC = () => {
  const { merchantId } = useParams();
  const [activeSection, setActiveSection] = useState<Section>("Upcoming");
  const navigate = useNavigate();
  const [appointmentListData, setAppointmentListData] = useState<any[]>([]);

  const sectionMap: Record<Section, string> = {
    Upcoming: "upcoming",
    Finished: "paid",
    Cancelled: "cancel",
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    getAppointmentData();
  }, [activeSection]);

  const getAppointmentData = async () => {
    try {
      const mappedSection = sectionMap[activeSection] || activeSection;
      const url = `/appointmentmob/all/${merchantId}/${mappedSection}`;
      const response = await get(url);
      console.log("response", response);
      const data = response?.data;
      if (data?.success) {
        setAppointmentListData(data.data);
      }
    } catch (error) {
      console.error("Error while retrieving appointment list:", error);
    }
  };

  return (
    <>
      <Row
        justify="center"
        style={{
          minHeight: "100vh",
          backgroundColor: "#f0f2f5",
          paddingBottom: "50px",
        }}
      >
        <Col
          span={24}
          style={{
            background: "#fff",
            padding: "16px",
            borderRadius: "8px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <div
            style={{
              overflowX: "auto",
              whiteSpace: "nowrap",
              paddingBottom: "10px",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
            className="hide-scrollbar"
          >
            <Flex gap="small" wrap={false} style={{ width: "max-content" }}>
              {["Upcoming", "Finished", "Cancelled"].map((section) => (
                <Button
                  key={section}
                  size="large"
                  onClick={() => setActiveSection(section as Section)}
                  style={buttonStyle(activeSection === section)}
                >
                  {section}
                </Button>
              ))}
            </Flex>
          </div>
          <>
            {appointmentListData.length > 0 ? (
              <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                {appointmentListData.map((appointment) => (
                  <Col span={24} key={appointment.appointmentid}>
                    <Card
                      hoverable
                      style={{
                        overflow: "hidden",
                        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      }}
                      bodyStyle={{ padding: "10px" }}
                      onClick={() =>
                        navigate(
                          `/app/appointment/details/${appointment.appointmentid}/${merchantId}/${appointment.serviceid}`
                        )
                      }
                    >
                      <Row>
                        <Col span={24}>
                          <Text
                            style={{
                              fontSize: "16px",
                              padding: "12px",
                              paddingBottom: 0,
                              fontWeight: "bold",
                            }}
                          >
                            <ShopOutlined
                              style={{ fontSize: "20px", color: "orange" }}
                            />{" "}
                            {appointment.outletname || "Unknown Outlet"}
                          </Text>
                        </Col>
                      </Row>

                      <Row align="middle" style={{ marginTop: "10px" }}>
                        <Col>
                          <img
                            alt="avatar"
                            src={defaultStoreImage}
                            style={imgStyle}
                          />
                        </Col>
                        <Col>
                          <Text
                            style={{
                              fontWeight: 500,
                              // display: "block",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              display: "-webkit-box",
                              WebkitLineClamp: 2, // Limits text to 2 lines
                              WebkitBoxOrient: "vertical",
                              wordBreak: "break-word",
                              maxWidth: "200px", // Adjust width if needed
                            }}
                          >
                            {appointment.servicename || "No Service Name"}
                          </Text>

                          <Text
                            style={{
                              display: "block",
                              backgroundColor: "#f0f0f0",
                              padding: "8px",
                              width: "100%",
                              borderRadius: "8px",
                              marginTop: "5px",
                              fontWeight: 500,
                            }}
                          >
                            <CalendarOutlined
                              style={{ fontSize: "20px", color: "orange" }}
                            />{" "}
                            {appointment.appointment_time || "Time Unavailable"}
                          </Text>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                ))}
              </Row>
            ) : (
              <Empty
                image={emptyImageUrl}
                imageStyle={{
                  height: 120,
                  top: 500,
                }}
                description={<span>No appointment currently available</span>}
              />
            )}
          </>
          <div
            style={{
              width: "100%",
              textAlign: "center",
              position: "fixed",
              zIndex: 1000,
              bottom: "80px",
              left: "50%",
              transform: "translateX(-50%)",
              marginTop: "20px",
            }}
          >
            <Button
              type="primary"
              size="large"
              block
              style={{
                backgroundColor: "orange",
                color: "white",
                fontSize: "16px",
                borderRadius: "8px",
                maxWidth: "400px",
                width: "70%",
              }}
              onClick={() => navigate(`/app/outlet/list/${merchantId}`)}
            >
              Make Appointment
            </Button>
          </div>
          <Footer />
        </Col>
      </Row>
    </>
  );
};

export default AppointmentList;
