import { Modal, Table, InputNumber, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import "../billing/ModalStyles.css";
import { useCurrency } from "../../../contexts/CurrencyContext";
import { get, post } from "../../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";

interface CustomerDebtModalProps {
  visible: boolean;
  debt?: string | null;
  onClose: () => void;
}

interface DebtItem {
  key: string;
  ref_no: string;
  order_date: string;
  item_name: string;
  item_original_price: string;
  item_price: string;
}

const CustomerDebtModal: React.FC<CustomerDebtModalProps> = ({
  visible,
  onClose,
  debt,
}) => {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const { id } = useParams();
  const [customerDebtData, setCustomerDebtData] = useState<DebtItem[]>([]);
  const [payoffAmounts, setPayoffAmounts] = useState<Record<string, number>>(
    {}
  );
  const [selectedPayments, setSelectedPayments] = useState<DebtItem[]>([]);

  const fetchCustomerDebtData = async () => {
    try {
      const url = `/billing/customer/debtdetails/${id}/${localStorage.getItem(
        "outlet_id"
      )}`;
      const response = await get(url);
      if (response?.data?.success) {
        setCustomerDebtData(
          response?.data?.debts.map((item: DebtItem, index: number) => ({
            ...item,
            key: `${index}`,
          }))
        );
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Error while fetching customer debts: ", error);
    }
  };

  useEffect(() => {
    if (visible) {
      setPayoffAmounts({});
      setSelectedPayments([]);
      fetchCustomerDebtData();
    }
  }, [visible]);

  const handlePayoffChange = (value: number | null, record: DebtItem) => {
    const maxAmount = parseFloat(record.item_price);
    if (value !== null && value > maxAmount) {
      message.error(
        `The payoff amount cannot exceed ${currencySymbol.CURRENCY_SYMBOL} ${maxAmount}`
      );
      setPayoffAmounts((prev) => ({ ...prev, [record.key]: maxAmount }));
      const validValue = maxAmount || 0;
      const updatedPayments = customerDebtData
        .filter(
          (item) =>
            (payoffAmounts[item.key] ?? 0) > 0 ||
            (item.key === record.key && validValue > 0)
        )
        .map((item) => ({
          ...item,
          payoff_amount:
            item.key === record.key
              ? validValue.toString()
              : payoffAmounts[item.key].toString(),
        }));
      setSelectedPayments(updatedPayments);
      return;
    }
    const validValue = value || 0;
    setPayoffAmounts((prev) => ({ ...prev, [record.key]: validValue }));

    const updatedPayments = customerDebtData
      .filter(
        (item) =>
          (payoffAmounts[item.key] ?? 0) > 0 ||
          (item.key === record.key && validValue > 0)
      )
      .map((item) => ({
        ...item,
        payoff_amount:
          item.key === record.key
            ? validValue.toString()
            : payoffAmounts[item.key].toString(),
      }));
    setSelectedPayments(updatedPayments);
  };

  const handleSubmitPayment = async () => {
    if (selectedPayments.length === 0) {
      message.warning(
        "Please enter at least one valid payoff amount greater than 0"
      );
      return;
    }

    if (selectedPayments.length > 0) {
      const totalPayoffAmount = selectedPayments.reduce(
        (sum: any, item: any) => sum + parseFloat(item.payoff_amount),
        0
      );
      localStorage.setItem(
        "selecteddebtdetails",
        JSON.stringify(selectedPayments)
      );
      navigate(`/sales/billing?customerid=${id}&debt=${totalPayoffAmount}`);
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "order_date",
      key: "order_date",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Ref#",
      dataIndex: "ref_no",
      key: "ref_no",
      width: 200,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Item Name",
      dataIndex: "item_name",
      key: "item_name",
      width: 250,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Original Price",
      dataIndex: "item_original_price",
      key: "item_original_price",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {currencySymbol.CURRENCY_SYMBOL} {text}
        </span>
      ),
    },
    {
      title: "Due Amount",
      dataIndex: "final_price",
      key: "final_price",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {currencySymbol.CURRENCY_SYMBOL} {text}
        </span>
      ),
    },
    {
      title: "Payoff Amount",
      key: "payoff_amount",
      render: (_: any, record: DebtItem) => (
        <InputNumber
          min={0}
          value={payoffAmounts[record.key] || 0}
          onChange={(value) => handlePayoffChange(value, record)}
        />
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      title="Debt Details"
      onCancel={onClose}
      footer={
        <div style={{ textAlign: "right", marginTop: "16px" }}>
          <Button type="primary" onClick={handleSubmitPayment}>
            Enter
          </Button>
        </div>
      }
      width="1140px"
      style={{ top: "10px" }}
      bodyStyle={{ height: "calc(100vh - 160px)", overflowY: "scroll" }}
    >
      <Table
        columns={columns}
        dataSource={customerDebtData}
        rowKey="key"
        pagination={false}
      />
    </Modal>
  );
};

export default CustomerDebtModal;
