import { Button, Form, Input, Radio, Typography } from "antd";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Header from "../headerFooter/Header";
import Footer from "../headerFooter/footer";

const { Title } = Typography;
const { TextArea } = Input;

const Allergies: React.FC = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const location = useLocation();
  const [form] = Form.useForm();

  // Get allergiesData from location.state, default to empty object if undefined
  const allergiesData = location.state?.allergiesData || {};
  console.log("Allergies Data:", allergiesData);

  useEffect(() => {
    // Set initial form values if allergiesData exists
    form.setFieldsValue({
      faceType: allergiesData.faceType || undefined,
      allergyDetails: allergiesData.allergyDetails || undefined,
    });
  }, [allergiesData, form]);

  const handleSubmit = (values: any) => {
    console.log("Form Submitted:", values);
    navigate(`/app/booking/${merchantId}`, {
      state: {
        allergiesData: values,
        selectedBookings: location.state?.selectedBookings,
        selectedCustomers: location.state?.selectedCustomers,
        validityType: location.state?.validityType,
      },
    });
  };

  return (
    <>
      <div style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
        <Header
          title="Allergies Investigation"
          onBack={() =>
            navigate(`/app/booking/${merchantId}`, { state: location.state })
          }
          backgroundColor={"#fff"}
        />
        <div
          style={{ padding: "16px", paddingTop: "70px", paddingBottom: "80px" }}
        >
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            {/* Question Block 1 */}
            <div style={{ padding: "10px", borderRadius: "8px" }}>
              <Title level={5}>1. How is your face?</Title>
              <Form.Item name="faceType">
                <Radio.Group style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                      padding: "10px",
                    }}
                  >
                    {["Dry", "Oily", "Mix"].map((option) => (
                      <label
                        key={option}
                        style={{
                          padding: "12px",
                          borderRadius: "8px",
                          backgroundColor: "#fff",
                          boxShadow: "0 1px 4px rgba(0, 0, 0, 0.1)",
                          cursor: "pointer",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Radio value={option}>{option}</Radio>
                      </label>
                    ))}
                  </div>
                </Radio.Group>
              </Form.Item>
            </div>

            {/* Question Block 2 */}
            <div style={{ padding: "10px", borderRadius: "8px" }}>
              <Title level={5}>
                2. Any allergies before? If so, please explain in detail.
              </Title>
              <Form.Item name="allergyDetails">
                <TextArea placeholder="Please enter" rows={4} />
              </Form.Item>
            </div>

            {/* Submit Button */}
            <div
              style={{
                width: "95%",
                textAlign: "center",
                position: "fixed",
                zIndex: 1000,
                bottom: "80px",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              <Button
                type="primary"
                size="large"
                block
                htmlType="submit"
                style={{
                  backgroundColor: "orange",
                  color: "white",
                  fontSize: "16px",
                  borderRadius: "8px",
                }}
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Allergies;
