import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Select,
  Radio,
  Button,
  DatePicker,
  Upload,
  Row,
  Col,
  message,
  Checkbox,
} from "antd";
import {
  UploadOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
  CameraOutlined,
} from "@ant-design/icons";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import "../room/Room.css";
import { Content } from "antd/es/layout/layout";
import { get, post, put, uploadImg } from "../../services/Apiclient";
import ImageUpload from "../../components/imageUpload/ImageUpload";
import "./AddStaff.css";
import TextArea from "antd/es/input/TextArea";
import PhoneInput from "react-phone-input-2";
import dayjs from "dayjs";
import CryptoJS from "crypto-js";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;

const { Option } = Select;

// Define the type for the outlet data
interface Outlet {
  id: string;
  name: string;
}

interface OutletResponse {
  success: boolean;
  message: string;
  data: Outlet[];
}

// Yup validation schema
const validationSchema = Yup.object().shape({
  outletid: Yup.string().required("Please select Store"),
  staffnumber: Yup.string().required("Please select staff number"),
  staffcode: Yup.string().required("Please enter staff code"),
  firstname: Yup.string().required("Please enter first name"),
  lastname: Yup.string().required("Please enter last name"),
  gender: Yup.string().required("Please select gender"),
  phone_number: Yup.string().required("Please enter phone number"),
  email: Yup.string().email("Invalid email").required("Please enter email"),

  department: Yup.string().required("Please select department"),
  designation: Yup.string().required("Please select designation"),
  title: Yup.string().required("Please select title "),
  fixed_salary: Yup.number()
    .positive("Salary must be a positive number")
    .min(1, "Salary must be at least 1"),
  // address: Yup.string().required("Please enter address"),
  hr_rates: Yup.number(),
  // // dob: Yup.date().required("Please select date of birth"),
  // altername_address: Yup.string().required("Please enter address"),
  // password: Yup.string().required("Please enter password"),
  // emergency_contact: Yup.string().required("Please enter emergency contact"),
  // alternate_number: Yup.string()
  //   .matches(/^[0-9]+$/, "Phone number must contain only digits"),

  alternate_email: Yup.string().email("Invalid email"),
});

const AddStaff = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isUpdate = Boolean(id);
  const [formValues, setFormValues] = useState<{
    outletid: string;
    staffcode: string;
    firstname: string;
    lastname: string;
    countrycode: string;
    phone_number: string;
    title: string;
    email: string;
    address: string;
    gender: string;
    password: string;
    department: string;
    designation: string;
    fixed_salary: number;
    hr_rates: number;
    dob: dayjs.Dayjs | null; // Explicit type for dob
    profile_pic: string;
    upload_id_front: string;
    upload_id_back: string;
    idPicture: string;
    altername_address: string;
    staff_assign_id: string;
    profile_desc: string;
    preffered_name: string;
    emergency_contact: string;
    alternate_number: string | null;
    alternate_email: string;
    emp_status: string;
    staffnumber: string;
    start_date: dayjs.Dayjs | null; // Explicit type for start_date
    end_date: dayjs.Dayjs | null; // Explicit type for end_date
    educational_bg: string;
    isfixed: boolean;
    ishourly: boolean;
    iscommision: boolean;
    salaryStructure: string[];
  }>({
    outletid: localStorage.getItem("outlet_id") || "",
    staffcode: "",
    firstname: "",
    lastname: "",
    countrycode: "60",
    phone_number: "",
    title: "",
    email: "",
    address: "",
    gender: "",
    password: "123456",
    department: "",
    designation: "",
    fixed_salary: 0,
    hr_rates: 0,
    dob: null,
    profile_pic: "",
    upload_id_front: "",
    upload_id_back: "",
    idPicture: "",
    altername_address: "",
    staff_assign_id: "",
    profile_desc: "",
    preffered_name: "",
    emergency_contact: "",
    alternate_number: null,
    alternate_email: "",
    emp_status: "Full-time",
    staffnumber: "",
    start_date: null,
    end_date: null,
    educational_bg: "",
    isfixed: false,
    ishourly: false,
    iscommision: false,
    salaryStructure: [],
  });
  const [outletOptions, setOutletOptions] = useState<Outlet[]>([]);
  const [departmentData, setDepartmentData] = useState<any[]>([]);
  const [designationData, setDesignationData] = useState<any[]>([]);
  const [titleData, setTitleData] = useState<any[]>([]);

  const [storeLogoUrl, setStoreLogoUrl] = useState<string>("");

  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [imageArray, setImageArray] = useState<string[]>([]);

  const handleRemoveShowcaseImage = (index: number) => {
    setImageUrls((prev) => {
      const updatedUrls = [...prev];
      updatedUrls[index] = ""; // Clear the image at the specified index
      return updatedUrls;
    });

    setImageArray((prev) => {
      const updatedArray = [...prev];
      updatedArray[index] = ""; // Clear the corresponding image name
      return updatedArray;
    });
  };

  const uploadStoreShowcaseProps = {
    showUploadList: false,
    beforeUpload: (file: any) => {
      return false; // Prevent default upload behavior
    },
  };

  const handleShowCaseUpload = async (file: File, index: number) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await uploadImg(
        "/staff/uploadimage/staffprofile",
        formData
      );

      if (response?.data?.success) {
        const uploadedImage = response.data.data[0];
        const uploadedUrl = uploadedImage.baseuri;
        const uploadedName = uploadedImage.imagename;

        setImageUrls((prev) => {
          const updatedUrls = [...prev];
          updatedUrls[index] = uploadedUrl; // Add the uploaded image at the correct index
          return updatedUrls;
        });

        setImageArray((prev) => {
          const updatedArray = [...prev];
          updatedArray[index] = uploadedName; // Maintain the image name array for API submission
          return updatedArray;
        });

        message.success("Image uploaded successfully!");
      } else {
        message.error(response.data.message || "Failed to upload image.");
      }
    } catch (error) {
      message.error("An error occurred during image upload.");
      console.error("Upload error:", error);
    }

    return false; // Prevent default upload behavior
  };

  const handleRemoveStoreLogoImage = () => {
    setStoreLogoUrl("");
    setFormValues((prev) => ({
      ...prev,
      profile_pic: "",
    }));
  };

  const handleStoreLogoUpload = async (fileList: File[]) => {
    console.log(fileList, "filelist");
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file); // Ensure this matches the API's expected field name
    });

    try {
      const response = await uploadImg(
        "/staff/uploadimage/staffprofile",
        formData
      );

      if (response?.data?.success) {
        const uploadedFileName = response.data.data[0]?.imagename;
        setFormValues((prev) => ({
          ...prev,
          profile_pic: uploadedFileName,
        }));

        setStoreLogoUrl(response.data.data[0]?.baseuri);
      } else {
        message.error(response.data.message || "Failed to upload store logo.");
      }
    } catch (error) {
      message.error("An error occurred while uploading the store logo.");
      console.error("Upload error:", error);
    }
  };

  const uploadStoreLogoProps = {
    showUploadList: false,
    beforeUpload: (file: any, fileList: any[]) => {
      handleStoreLogoUpload(fileList); // Call handleUpload for custom behavior
      return false; // Prevent default upload
    },
  };

  const fetchStaffDetails = async (id: string) => {
    try {
      const response = await get(`/staff/${id}`);
      if (response?.data?.success) {
        const staffData = response.data.data;

        const prefilledImages = staffData.staffImages.map(
          (img: any) => img.imageUrl
        );
        const prefilledImageNames = staffData.staffImages.map(
          (img: any) => img.name
        );

        setImageUrls(prefilledImages);
        setImageArray(prefilledImageNames);

        // Populate the form with the fetched staff details
        setFormValues({
          ...staffData,
          firstname: staffData.name,
          phone_number:
            `${staffData.countrycode}${staffData.phone_number}` || "",
          dob: staffData.dob ? dayjs(staffData.dob, "YYYY-MM-DD") : null, // Convert to dayjs
          start_date: staffData.start_date
            ? dayjs(staffData.start_date, "YYYY-MM-DD")
            : null,
          end_date: staffData.end_date
            ? dayjs(staffData.end_date, "YYYY-MM-DD")
            : null,
          salaryStructure: [
            staffData.isfixed && "Fixed",
            staffData.ishourly && "Hourly",
            staffData.iscommision && "Commission",
          ].filter(Boolean),
        });
        setStoreLogoUrl(staffData.profilepicurl);
      } else {
        message.error("Failed to fetch staff details");
      }
    } catch (error) {
      console.error("Error fetching staff details:", error);
      message.error("An error occurred while fetching staff details");
    }
  };

  // Function to fetch outlet data
  const fetchOutletData = async () => {
    const response = await get("/outlet/dropdown"); // Call your API with the correct endpoint
    if (response?.data?.success) {
      setOutletOptions(response?.data?.data); // Store the fetched outlets
    } else {
      message.error("Failed to fetch outlets");
    }
  };

  const getDepartmentDropdown = async () => {
    try {
      let url = `/department/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setDepartmentData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getDesignationDropdown = async () => {
    try {
      let url = `/designation/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setDesignationData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getTitleDropdown = async () => {
    try {
      let url = `/titletype/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setTitleData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    if (isUpdate && id) {
      fetchStaffDetails(id); // Fetch staff details if editing
    }
  }, [id]);

  useEffect(() => {
    // Fetch outlet data when the component mounts
    fetchOutletData();
    getDepartmentDropdown();
    getDesignationDropdown();
    getTitleDropdown();
  }, []);

  const encryptPassword = (password: any) => {
    const secretKey = "G7HqA5VCbMMi3KQN2anRckza";
    return CryptoJS.AES.encrypt(password, secretKey).toString();
  };

  const handleSubmit = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });

      let formattedPhoneNumber = formValues.phone_number;

      // Remove country code from phone_number
      if (formValues.countrycode) {
        formattedPhoneNumber = formValues.phone_number.replace(
          new RegExp(`^\\+?${formValues.countrycode}`),
          ""
        );
      }

      const filteredImageArray = imageArray.filter(
        (name) => name && name.trim() !== ""
      );

      const apiPayload = {
        ...formValues,
        staffids: filteredImageArray,
        dob: formValues.dob ? formValues.dob?.format("YYYY-MM-DD") : null,
        start_date: formValues.start_date
          ? formValues.start_date?.format("YYYY-MM-DD")
          : null,
        end_date: formValues.end_date
          ? formValues.end_date?.format("YYYY-MM-DD")
          : null,
        phone_number: formattedPhoneNumber,
        ishourly: formValues.ishourly,
        iscommision: formValues.iscommision,
        password: encryptPassword(formValues.password),
        isfixed: formValues.isfixed,
      };

      let payload: any = apiPayload;

      if (isUpdate) {
        delete payload.password;
      }

      const response = isUpdate
        ? await put(`/staff/${id}`, payload)
        : await post("/staff/addstaff", payload, false);
      if (response?.data?.success) {
        message.success(
          isUpdate ? "Staff updated successfully!" : "Staff added successfully!"
        );
        navigate("/settings/staff/list"); // Navigate to the staff list or any other page after successful submission
      } else {
        message.error(
          "Failed to add staff: " + response?.data?.message ||
            "An error occurred"
        );
      }
    } catch (error: any) {
      // Display validation errors
      if (error instanceof Yup.ValidationError) {
        const firstError = error.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      } else {
        console.log(error);
        message.error("An unexpected error occurred");
      }
    }
  };

  const handleInputChange = (name: string, value: any) => {
    if (name === "salaryStructure") {
      setFormValues((prev) => ({
        ...prev,
        salaryStructure: value,
        isfixed: value.includes("Fixed"),
        ishourly: value.includes("Hourly"),
        iscommision: value.includes("Commission"),
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const salaryOptions = [
    { label: "Fixed", value: "Fixed" },
    { label: "Hourly", value: "Hourly" },
    { label: "Commission", value: "Commission" },
  ];

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Content className="mainContainer">
        {/* Page Header */}
        <Row justify="space-between" align="middle">
          <Col>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                <ArrowLeftOutlined
                  style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
                />
              </div>
              <h2 style={{ margin: 0 }}>{id ? "Edit Staff" : "Add Staff"}</h2>
            </div>
          </Col>
        </Row>

        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#fafafa",
            borderRadius: "12px",
            border: "1px solid #e0e7fe",
            padding: "20px",
            height: "calc(100vh - 70px)",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            maxWidth: "1250px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div className="form-scrollable-content">
            {/* Basic Info Section */}
            <Form
              layout="vertical"
              onFinish={handleSubmit}
              style={{ marginTop: 20 }}
            >
              <h2>Basic Info</h2>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Store" required>
                    <Select
                      placeholder="Please select"
                      value={formValues.outletid}
                      disabled={true}
                      onChange={(value) => handleInputChange("outletid", value)}
                    >
                      {outletOptions &&
                        outletOptions.length > 0 &&
                        outletOptions.map((outlet) => (
                          <Option key={outlet.id} value={outlet.id}>
                            {outlet.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Staff Number" required>
                    <Input
                      placeholder="Please enter"
                      value={formValues.staffnumber}
                      onChange={(e) =>
                        handleInputChange("staffnumber", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Staff Code" required>
                    <Input
                      placeholder="Please enter"
                      value={formValues.staffcode}
                      onChange={(e) =>
                        handleInputChange("staffcode", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label="Name" required className="custom-label">
                    <div className="name-input-container">
                      <Input
                        placeholder="First name"
                        value={formValues.firstname}
                        onChange={(e) =>
                          handleInputChange("firstname", e.target.value)
                        }
                        style={{
                          border: "1px solid #d9d9d9",
                          borderWidth: "1px 0px 1px 1px",
                          boxShadow: "none",
                        }}
                        className="name-input"
                      />
                      <span className="divider"></span>
                      <Input
                        placeholder="Last name"
                        value={formValues.lastname}
                        onChange={(e) =>
                          handleInputChange("lastname", e.target.value)
                        }
                        style={{
                          border: "1px solid #d9d9d9",
                          borderWidth: "1px 1px 1px 0px",
                          boxShadow: "none",
                        }}
                        className="name-input"
                      />
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Gender" required>
                    <Select
                      placeholder="Please select"
                      value={formValues.gender}
                      onChange={(value) => handleInputChange("gender", value)}
                    >
                      <Option value="Female">Female</Option>
                      <Option value="Male">Male</Option>
                      <Option value="Non-binary">Non-binary</Option>
                      <Option value="Prefer not to say">
                        Prefer not to say
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Phone Number" required>
                    <PhoneInput
                      placeholder="Please enter"
                      country={"my"}
                      value={formValues.phone_number}
                      // onChange={(value) =>
                      //   handleInputChange("phone_number", value)
                      // }
                      enableSearch
                      countryCodeEditable={false}
                      onChange={(value: any, country: any) => {
                        handleInputChange("countrycode", country.dialCode);
                        handleInputChange("phone_number", value);
                      }}
                      inputStyle={{
                        width: "100%",
                        height: "46px",
                        paddingLeft: "35px", // Adjust space for the flag
                        borderColor: "#e0e0e0", // Light border color for a softer look
                        borderRadius: "5px",
                        color: "#5c5c5c", // Softer text color
                        backgroundColor: "#f8f9fd", // Light background color
                      }}
                      containerStyle={{
                        width: "100%",
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Email" required>
                    <Input
                      placeholder="Please enter"
                      value={formValues.email}
                      onChange={(e) =>
                        handleInputChange("email", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Department" required>
                    <Select
                      placeholder="Please select"
                      value={formValues.department}
                      onChange={(value) =>
                        handleInputChange("department", value)
                      }
                    >
                      {departmentData &&
                        departmentData.length > 0 &&
                        departmentData.map((department) => (
                          <Option key={department.id} value={department.id}>
                            {department.departmenttype}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Designation" required>
                    <Select
                      placeholder="Please select"
                      value={formValues.designation}
                      onChange={(value) =>
                        handleInputChange("designation", value)
                      }
                    >
                      {designationData &&
                        designationData.length > 0 &&
                        designationData.map((designation) => (
                          <Option key={designation.id} value={designation.id}>
                            {designation.designationtype}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Title" required>
                    <Select
                      placeholder="Please select"
                      value={formValues.title}
                      onChange={(value) => handleInputChange("title", value)}
                    >
                      {titleData &&
                        titleData.length > 0 &&
                        titleData.map((title) => (
                          <Option key={title.id} value={title.id}>
                            {title.titletype}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Hourly Rate">
                    <Input
                      type="number"
                      min={0}
                      placeholder="Please enter"
                      value={formValues.hr_rates}
                      onChange={(e) =>
                        handleInputChange(
                          "hr_rates",
                          parseFloat(e.target.value)
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Fixed Salary" required>
                    <Input
                      type="number"
                      min={0}
                      placeholder="Please enter"
                      value={formValues.fixed_salary}
                      onChange={(e) =>
                        handleInputChange(
                          "fixed_salary",
                          parseFloat(e.target.value)
                        )
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Salary Structure">
                    <Checkbox.Group
                      options={salaryOptions}
                      value={formValues.salaryStructure}
                      onChange={(checkedValues) =>
                        handleInputChange("salaryStructure", checkedValues)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Personal Info Section */}
              <h2>Personal Info</h2>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Profile Picture">
                    {/* <Upload
                    listType="picture"
                    onChange={(info) => {
                      handleInputChange(
                        "profile_pic",
                        info.fileList.map((file) => file.thumbUrl)
                      );
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload> */}
                    {/* <ImageUpload /> */}
                    {formValues.profile_pic !== "" &&
                    storeLogoUrl !== "" &&
                    storeLogoUrl !== null &&
                    storeLogoUrl !== undefined ? (
                      <div className="image-preview">
                        <img src={storeLogoUrl} alt={`Uploaded image`} />
                        <CloseOutlined
                          className="remove-icon"
                          onClick={() => handleRemoveStoreLogoImage()}
                        />
                      </div>
                    ) : (
                      <>
                        <Upload
                          {...uploadStoreLogoProps}
                          multiple={false}
                          accept=".jpg,.png"
                        >
                          <div className="upload-box">
                            <CameraOutlined className="upload-icon" />
                          </div>
                        </Upload>
                      </>
                    )}
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}></Col>
                <Col xs={24} sm={12} md={8}></Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Preferred Name">
                    <Input
                      placeholder="Please enter"
                      value={formValues.preffered_name}
                      onChange={(e) =>
                        handleInputChange("preffered_name", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Date of Birth">
                    <DatePicker
                      style={{ width: "100%" }}
                      value={formValues.dob ? dayjs(formValues.dob) : null}
                      onChange={(date) => handleInputChange("dob", date)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Educational Background">
                    <Select
                      placeholder="Please select"
                      value={formValues.educational_bg}
                      onChange={(value) =>
                        handleInputChange("educational_bg", value)
                      }
                    >
                      <Option value="ph.d">ph.d</Option>
                      <Option value="master">master</Option>
                      <Option value="bachelor">bachelor</Option>
                      <Option value="junior college">junior college</Option>
                      <Option value="high school">high school</Option>
                      <Option value="middle school">middle school</Option>
                      <Option value="primary school">primary school</Option>
                      <Option value="other">other</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={16}>
                  <Form.Item label="Upload ID Picture">
                    {/* <Upload
                    listType="picture"
                    onChange={(info) => {
                      handleInputChange(
                        "idPicture",
                        info.fileList.map((file) => file.thumbUrl)
                      );
                    }}
                  >
                    <Button icon={<UploadOutlined />}>Upload</Button>
                  </Upload> */}
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "10px",
                        flexWrap: "wrap",
                      }}
                    >
                      {[...Array(6)].map((_, index) => (
                        <div key={index} className="image-preview">
                          {imageUrls[index] ? (
                            <>
                              <img
                                src={imageUrls[index]}
                                alt={`Showcase ${index + 1}`}
                              />
                              <CloseOutlined
                                className="remove-icon"
                                onClick={() => handleRemoveShowcaseImage(index)}
                              />
                            </>
                          ) : (
                            <Upload
                              {...uploadStoreShowcaseProps}
                              multiple={false} // Allow only one file per upload
                              accept=".jpg,.png"
                              beforeUpload={(file) =>
                                handleShowCaseUpload(file, index)
                              }
                            >
                              <div className="upload-box">
                                <CameraOutlined className="upload-icon" />
                              </div>
                            </Upload>
                          )}
                        </div>
                      ))}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={8}>
                  <Form.Item label="ID">
                    <Input
                      placeholder="Please enter"
                      value={formValues.staff_assign_id}
                      onChange={(e) =>
                        handleInputChange("staff_assign_id", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                {/* <Col xs={24} sm={12} md={8}>
              <Form.Item label="Password">
                  <Input
                    placeholder="Please enter"
                    value={formValues.password}
                    onChange={(e) =>
                      handleInputChange("password", e.target.value)
                    }
                  />
                </Form.Item>
              </Col> */}
                <Col span={24}>
                  <Form.Item label="Address">
                    <Input
                      placeholder="Please enter"
                      value={formValues.address}
                      onChange={(e) =>
                        handleInputChange("address", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col xs={24} md={24}>
                  <Form.Item label="Profile Description">
                    <Input.TextArea
                      placeholder="Please enter"
                      value={formValues.profile_desc}
                      onChange={(e) =>
                        handleInputChange("profile_desc", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Emergency Contact Section */}
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Emergency Contact">
                    <Input
                      placeholder="Please enter"
                      value={formValues.emergency_contact}
                      onChange={(e) =>
                        handleInputChange("emergency_contact", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Phone Number">
                    <Input
                      placeholder="Please enter"
                      value={formValues.alternate_number ?? ""}
                      onChange={(e) =>
                        handleInputChange("alternate_number", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Email">
                    <Input
                      placeholder="Please enter"
                      value={formValues.alternate_email}
                      onChange={(e) =>
                        handleInputChange("alternate_email", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label="Address">
                    <TextArea
                      rows={4}
                      placeholder="Please enter"
                      style={{ width: "1280px", minHeight: "90px" }}
                      autoSize={{ minRows: 4 }}
                      value={formValues.altername_address}
                      onChange={(e) =>
                        handleInputChange("altername_address", e.target.value)
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Employment Status */}
              <h2>Staff Info</h2>
              <Row gutter={[16, 16]}>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Employment Status">
                    <Radio.Group
                      value={formValues.emp_status}
                      onChange={(e) =>
                        handleInputChange("emp_status", e.target.value)
                      }
                    >
                      <Radio value="Full-time">Full-time</Radio>
                      <Radio value="Part-time">Part-time</Radio>
                      <Radio value="Resigned">Resigned</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="Start Date">
                    <DatePicker
                      style={{ width: "100%" }}
                      value={
                        formValues.start_date
                          ? dayjs(formValues.start_date)
                          : null
                      }
                      onChange={(date) => handleInputChange("start_date", date)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={12} md={8}>
                  <Form.Item label="End Date">
                    <DatePicker
                      style={{ width: "100%" }}
                      value={
                        formValues.end_date ? dayjs(formValues.end_date) : null
                      }
                      onChange={(date) => handleInputChange("end_date", date)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {/* Save Section */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 20,
                }}
                className="save-section"
              >
                <Button
                  type="default"
                  onClick={() => navigate(-1)}
                  style={{ marginRight: 8 }}
                >
                  Cancel
                </Button>
                <Button type="primary" onClick={handleSubmit}>
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </Content>
      </Content>
    </>
  );
};

export default AddStaff;
