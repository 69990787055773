import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Button,
  Divider,
  Tag,
  Steps,
  Modal,
} from "antd";
import {
  EditOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { deleteData, get, put } from "../../services/Apiclient";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import Datatable from "../../components/Datatable";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const InventoryIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_Code.png`;
const ApprovedIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_Detail_Approved.png`;
const PendingIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_Detail_Pending.png`;
const AdjustedIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_StockTakes_Adjusted.png`;

const { Title, Text } = Typography;

interface StockDetails {
  key: React.Key;
  id: string;
  ref_no: string;
  total_qty: number;
  total_amount: number;
  stock_adj_date: string;
  status: string;
  storage_id: string;
  stockin_id: string;
  stockout_id: string;
  created_at: string;
  storage_name: string;
  staff_id: string;
  staff_name: string;
  note?: string;
  stock_method: string;
  storage_type: string;
  type_of_stock: string;
  total_counted_qty: any;
  products: any;
  previousData: string | null;
  nextData: string | null;
}

const StockTakesDetailView = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [stockDetails, setStockDetails] = useState<StockDetails | null>(null);
  const [reviewModalVisible, setReviewModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const columns = [
    {
      title: "Number",
      dataIndex: "number",
      key: "number",
      width: 100,
      className: "column-spacing",
      render: (text: any, record: any, index: number) => (
        <span>
          <span style={{ marginRight: 3, fontWeight: "600" }}>{index + 1}</span>
        </span>
      ),
    },
    {
      title: "Product Code",
      dataIndex: "productcode",
      key: "productcode",
      width: 150,
      className: "column-spacing",
    },
    {
      title: "Product Name",
      dataIndex: "productname",
      key: "productname",
      width: 200,
      className: "column-spacing",
    },
    {
      title: "Unit",
      dataIndex: "unit_type",
      key: "unit_type",
      width: 100,
      className: "column-spacing",
    },
    {
      title: "Supplier",
      dataIndex: "supplier_name",
      key: "supplier_name",
      width: 150,
      className: "column-spacing",
    },
    {
      title: "Expected",
      dataIndex: "expected_qty",
      key: "expected_qty",
      width: 120,
      className: "column-spacing",
    },
    {
      title: "Counted",
      dataIndex: "counted_qty",
      key: "counted_qty",
      width: 120,
      className: "column-spacing",
    },
    {
      title: "Difference",
      dataIndex: "difference",
      key: "difference",
      width: 120,
      className: "column-spacing",
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      className: "column-spacing",
      render: (text: any, record: any) => (
        <span>
          <span style={{ marginRight: 3, fontWeight: "600" }}>-</span>
        </span>
      ),
    },
  ];

  const handleConfirmDelete = async () => {
    if (id) {
      try {
        const url = `/stock/remove/takes/${id}`;
        const response = await deleteData(url);

        if (response?.status === 200 || response?.status === 204) {
          // Assuming the room was successfully deleted
          console.log("Deleted Stock takes details");
          navigate("/inventory/stocktakes/list");
        } else {
          console.error("Error deleting Stock takes details:", response);
        }
      } catch (error) {
        console.error("Error during delete operation:", error);
      }
    }
    setDeleteModalVisible(false);
  };

  const handleDeleteClick = () => {
    setDeleteModalVisible(true); // Show the delete modal
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false); // Close the modal without deleting
  };

  const openReviewModal = () => {
    setReviewModalVisible(true);
  };

  const onCancel = () => {
    setReviewModalVisible(false);
  };

  const onReviewReject = async () => {
    setReviewModalVisible(false);
  };

  const onReviewApproved = async () => {
    let payload = {
      status: "Adjusted",
    };
    await put(`/stock/stockupdatetakes/${id}`, payload);
    fetchStockDetails(id);
    setReviewModalVisible(false);
  };

  const fetchStockDetails = async (id: any) => {
    try {
      const response = await get(`/stock/takes/${id}`);
      if (response?.data?.success) {
        setStockDetails(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching stock details:", error);
    }
  };

  useEffect(() => {
    fetchStockDetails(id);
  }, [id]);

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <div
        style={{
          padding: "20px",
          backgroundColor: "#f0f2f5",
          height: "calc(100vh - 40px)",
        }}
      >
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: "16px" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              icon={<ArrowLeftOutlined />}
              type="link"
              style={{ marginRight: "8px" }}
              onClick={() => {
                navigate("/inventory/stocktakes/list");
              }}
            ></Button>
            <Title level={4} style={{ margin: 0 }}>
              Stock Takes Details
            </Title>
          </div>
          <div>
            {stockDetails?.status !== "Adjusted" && (
              <>
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  style={{ marginRight: "8px", fontWeight: "500" }}
                  onClick={handleDeleteClick}
                >
                  Delete
                </Button>
                <Button
                  icon={<EditOutlined />}
                  type="primary"
                  style={{ marginRight: "8px", fontWeight: "500" }}
                  onClick={() => {
                    navigate(`/inventory/stocktakes/edit/${id}`);
                  }}
                >
                  Edit
                </Button>
              </>
            )}
            <Button
              icon={<LeftOutlined />}
              style={{ marginRight: "8px", fontWeight: "500" }}
              onClick={() =>
                navigate(
                  `/inventory/stocktakesdetails/view/${stockDetails?.previousData}`
                )
              }
              disabled={stockDetails?.previousData == null}
            >
              Previous
            </Button>
            <Button
              icon={<RightOutlined />}
              iconPosition={"end"}
              style={{ fontWeight: "500" }}
              onClick={() =>
                navigate(
                  `/inventory/stocktakesdetails/view/${stockDetails?.nextData}`
                )
              }
              disabled={stockDetails?.nextData == null}
            >
              Next
            </Button>
          </div>
        </Row>

        <Card
          style={{
            borderRadius: "8px",
            background: "#fafafa",
          }}
          bodyStyle={{ padding: 0 }}
        >
          <Card style={{ marginBottom: "20px" }} bodyStyle={{ padding: 0 }}>
            <Row
              gutter={16}
              justify="space-between"
              style={{ padding: "24px 24px 0px 24px" }}
            >
              <Col span={16} style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={InventoryIcon}
                  alt="icon"
                  style={{ width: "24px", marginRight: "8px" }}
                />
                <div>
                  <Text>Ref#</Text>
                  {/*  */}
                </div>
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                <Text>Counted</Text>
                {/* <Text strong style={{ display: "block" }}>
                  ${stockDetails?.total_amount}
                </Text> */}
              </Col>
            </Row>

            <Row
              gutter={16}
              justify="space-between"
              style={{ padding: "1px 24px 0px 24px" }}
            >
              <Col span={16} style={{ display: "flex", alignItems: "center" }}>
                <div>
                  <Title level={4} style={{ margin: 0 }}>
                    {stockDetails?.ref_no}
                  </Title>
                </div>
              </Col>
              <Col span={4} style={{ textAlign: "right" }}>
                {/* <Text
                  strong
                  style={{
                    display: "block",
                    fontWeight: "700",
                    lineHeight: "24px",
                  }}
                >
                  0
                </Text> */}

                <Title level={4} style={{ margin: 0 }}>
                  {stockDetails?.total_counted_qty}
                </Title>
              </Col>
            </Row>

            <Divider />

            <Row
              gutter={16}
              style={{ marginBottom: "16px", padding: "0px 24px" }}
            >
              <Col span={8}>
                <Text style={{ color: "#666" }}>
                  Date: {stockDetails?.stock_adj_date}
                </Text>
              </Col>
              <Col span={8}>
                <Text style={{ color: "#666" }}>
                  Staff: {stockDetails?.staff_name}
                </Text>
              </Col>
              <Col span={8}>
                <Text style={{ color: "#666" }}>
                  Storage:{stockDetails?.storage_type}{" "}
                </Text>
              </Col>
            </Row>

            <Row
              gutter={16}
              style={{ marginBottom: "16px", padding: "0px 24px" }}
            >
              <Col span={8}>
                <Text
                  style={{
                    color: "#ccc",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "16px",
                  }}
                >
                  Stock-in adjustment:-
                  {stockDetails?.stockin_id ? stockDetails?.stockin_id : "-"}
                </Text>
              </Col>
              <Col span={8}>
                <Text
                  style={{
                    color: "#ccc",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "16px",
                  }}
                >
                  Stock-out adjustment: :-
                  {stockDetails?.stockout_id ? stockDetails?.stockout_id : "-"}
                </Text>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={24}>
                {stockDetails?.status !== "To be adjusted" && (
                  <img
                    src={AdjustedIcon}
                    alt="icon"
                    style={{
                      width: "auto",
                      height: "60px",
                      marginRight: "8px",
                      position: "absolute",
                      bottom: "0px",
                      right: "0px",
                      transform: "rotate(-5deg)",
                    }}
                  />
                )}
              </Col>
            </Row>

            {stockDetails?.status == "To be adjusted" && (
              <>
                <Divider style={{ margin: "0px 0px 0px 0px" }} />
                <Row gutter={16} style={{ height: "56px" }}>
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      margin: "0px -20px",
                    }}
                  >
                    <Button
                      type="primary"
                      style={{
                        background: "#2e56f2",
                        color: "#fff",
                        textAlign: "center",
                        padding: "5px 12px",
                        borderRadius: "6px",
                        height: "32px",
                        cursor: "pointer",
                      }}
                      onClick={openReviewModal}
                    >
                      Adjustment
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </Card>

          <Card
            style={{
              marginTop: "20px",
              borderRadius: "8px",
              background: "#fafafa",
            }}
            bodyStyle={{ padding: "20px" }}
          >
            {/* Table */}
            <Datatable<StockDetails>
              columns={columns}
              dataSource={stockDetails?.products}
              scroll={{ y: 500 }}
              showPagination={false}
              tableClass="main-bg-table"
            />
          </Card>
        </Card>

        <Modal
          title="Adjustment"
          visible={reviewModalVisible}
          onCancel={onCancel}
          footer={null}
          centered
          width={400}
          bodyStyle={{ textAlign: "center" }}
          maskClosable={false}
          closable={false}
          className="add-model"
        >
          <div
            style={{
              height: "150px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p className="modal-message">
              After adjusting the account, the system will automatically
              generate a receipt or issue order based on the differences in
              product inventory. Please confirm that the inventory quantity is
              correct before adjusting the account!
            </p>
          </div>
          <div className="modal-divider"></div>
          <div className="modal-footer">
            <Button
              className="cancel-button"
              type="link"
              onClick={onReviewReject}
              style={{ color: "#000", fontSize: "16px", fontWeight: "500" }}
            >
              Cancle
            </Button>
            <div className="vl"></div>
            <Button
              className="confirm-button"
              type="link"
              onClick={onReviewApproved}
              style={{ color: "#1890ff", fontSize: "16px", fontWeight: "500" }}
            >
              Yes, adjust
            </Button>
          </div>
        </Modal>
        <ConfirmDeleteModal
          visible={deleteModalVisible}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          message="Are you sure to delete?"
        />
      </div>
    </>
  );
};

export default StockTakesDetailView;
