import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Typography,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios"; // For API calls

const { Text } = Typography;

const AddPerformancePlan: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams(); // Get ID from URL (if editing)
  const isEditMode = !!id; // Check if it's edit mode

  const [form] = Form.useForm(); // Form instance
  const [loading, setLoading] = useState(false);

  return (
    <div style={{ backgroundColor: "#f0f3f8" }} className="mainContainer">
      <Content>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: 16 }}
        >
          <Col>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowLeftOutlined
                onClick={() => navigate(-1)}
                style={{
                  marginRight: 12,
                  color: "#325DF2",
                  fontSize: 18,
                  cursor: "pointer",
                }}
              />
              <h2 style={{ margin: 0 }}>
                {isEditMode ? "Edit Performance Plan" : "Add Performance Plan"}
              </h2>
            </div>
          </Col>
        </Row>

        <Content
          style={{
            marginRight: "50px",
            marginLeft: "50px",
            backgroundColor: "#f6f7f9",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            height: "85vh",
          }}
        >
          <Title level={4} style={{ marginBottom: "15px", marginTop: "10px" }}>
            Basic Info
          </Title>

          {/* Form */}
          <Form
            form={form}
            layout="vertical"
            style={{ flex: 1, display: "flex", flexDirection: "column" }}
            onFinish={(values) => {
              setLoading(true);
              // Simulate API call or save logic
              setTimeout(() => {
                setLoading(false);
                navigate(
                  `/settings/performance/plan/calculationsettings/${
                    id || "new"
                  }`
                ); // Navigate to calculation settings
              }, 1000);
            }}
          >
            <div style={{ flex: 1 }}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="plancode"
                    label="Plan code"
                    rules={[
                      { required: true, message: "Please enter plan code" },
                    ]}
                  >
                    <Input placeholder="Please enter" allowClear />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="planname"
                    label="Plan name"
                    rules={[
                      { required: true, message: "Please enter plan name" },
                    ]}
                  >
                    <Input placeholder="Please enter" allowClear />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    name="note"
                    label="Note"
                    rules={[{ required: true, message: "Please enter note" }]}
                  >
                    <TextArea
                      rows={3}
                      style={{ width: "100%" }}
                      placeholder="Please enter"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item name="Plan setting">
                    <Title level={4} style={{ marginBottom: "15px" }}>
                      Plan Settings
                    </Title>
                    <Row gutter={[16, 16]}>
                      <Col span={10}>
                        <Checkbox>Service performance</Checkbox> ({" "}
                        <Checkbox>Service cost</Checkbox>)
                      </Col>
                      <Col span={7}>
                        <Checkbox>Product performance</Checkbox>
                      </Col>
                      <Col span={7}>
                        <Checkbox>Privilege performance</Checkbox>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </div>

            <Divider />

            {/* Buttons */}
            <Row justify="end" style={{ paddingBottom: "16px" }}>
              <Button
                style={{ marginRight: "10px", height: "46px" }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button
                style={{ height: "46px" }}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {/* {isEditMode ? "Next" : "Save"} */}
                Next
              </Button>
            </Row>
          </Form>
        </Content>
      </Content>
    </div>
  );
};

export default AddPerformancePlan;
