import React from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

type CommonListViewProps = {
  visible: boolean;
  onClose: () => void;
  options: any[];
  selectedPerformance: string;
  setSelectedPerformance: (value: string) => void;
  handleConfirm: () => void;
};

const CommonListView: React.FC<CommonListViewProps> = ({
  visible,
  onClose,
  options,
  selectedPerformance,
  setSelectedPerformance,
  handleConfirm,
}) => {
  if (!visible) return null;

  return (
    <>
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.4)",
          zIndex: 999,
        }}
        onClick={onClose}
      />

      <div
        style={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100vw",
          background: "#fff",
          borderRadius: "40px 40px 0 0",
          boxShadow: "0px -4px 12px rgba(0, 0, 0, 0.1)",
          maxHeight: "65vh",
          display: "flex",
          flexDirection: "column",
          zIndex: 1000,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: "16px",
            right: "16px",
            zIndex: 1,
          }}
        >
          <Button
            type="text"
            icon={<CloseOutlined style={{ fontSize: 18, color: "#fff" }} />}
            onClick={onClose}
            style={{
              backgroundColor: "#cecece",
              boxShadow: "none",
              borderRadius: "50%",
            }}
          />
        </div>

        <div
          style={{
            flex: 1,
            overflowY: "auto",
            padding: "40px 16px 16px",
            textAlign: "center",
          }}
        >
          {options.map((option) => (
            <Button
              key={option.id}
              type={selectedPerformance === option.id ? "default" : "text"}
              block
              onClick={() => setSelectedPerformance(option.id)}
              style={{
                marginBottom: 16,
                height: "48px",
                fontWeight: "500",
                color:
                  selectedPerformance === option.id ? "#3651f8" : "#d3d3d3",
                backgroundColor:
                  selectedPerformance === option.id ? "#f6f6f9" : "transparent",
                border:
                  selectedPerformance === option.id
                    ? "1px solid #3651f8"
                    : "none",
                borderRadius: "12px",
                margin: "0px 30px",
                width: "-webkit-fill-available",
              }}
            >
              {option.name}
            </Button>
          ))}
        </div>

        <div
          style={{
            padding: "16px",
          }}
        >
          <Button
            type="primary"
            block
            style={{
              height: "48px",
              backgroundColor: "#6758f8",
              borderColor: "#6758f8",
              borderRadius: "12px",
              fontWeight: "600",
            }}
            onClick={handleConfirm}
          >
            Confirm
          </Button>
        </div>
      </div>
    </>
  );
};

export default CommonListView;
