import React, { useEffect, useState } from "react";
import { Modal, Typography, Input, Button, Upload, message } from "antd";
import { get } from "../../../services/Apiclient";
import { LoadingOutlined } from "@ant-design/icons";

const { TextArea } = Input;
const { Text } = Typography;

interface ApplicableModelProps {
  visible: boolean;
  onClose: () => void;
  type?: string;
  id?: string;
}

const ApplicableModels: React.FC<ApplicableModelProps> = ({
  visible,
  onClose,
  type,
  id,
}) => {
  const [data, setData] = useState<any | null>(null);
  const [productNames, setProductNames] = useState<string[]>([]);
  const [serviceNames, setServiceNames] = useState<string[]>([]);

  const fetchData = async () => {
    try {
      const response = await get(`/${type}/${id}`);

      if (response?.data?.success) {
        const result = response.data.data;
        // console.log(response.data.data);
        setData(response.data.data);

        //set product names
        const allproductNames: any = [];
        result.products.forEach((product: any) => {
          if (product.type === "Single") {
            allproductNames.push(product.product_name);
          } else if (product.combo) {
            product.combo.forEach((comboItem: any) => {
              allproductNames.push(comboItem.product_name);
            });
          }
        });
        setProductNames(allproductNames);

        //set service names
        const allServiceNames: any = [];
        result.services.forEach((service: any) => {
          if (service.type === "Single") {
            allServiceNames.push(service.service_name);
          } else if (service.combo) {
            service.combo.forEach((comboItem: any) => {
              allServiceNames.push(comboItem.service_name);
            });
          }
        });
        setServiceNames(allServiceNames);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [type, id]);

  const productListString = productNames.join(", ");
  const serviceNameString = serviceNames.join(", ");

  return (
    <Modal
      open={visible}
      title="Area Of Application"
      onCancel={onClose}
      footer={null}
      width={400}
      bodyStyle={{ paddingBottom: "0" }}
      style={{ height: "calc(100vh-25px)" }}
    >
      <div
        style={{
          height: "calc(100%-56px)",
          overflowY: "auto",
        }}
      >
        {/* <LoadingOutlined classID="" className="Applicable_loading"/> */}
        {serviceNames?.length > 0 && (
          <>
            <div style={{ marginTop: "24px", marginBottom: "8px" }}>
              <span
                style={{
                  verticalAlign: "top",
                  fontSize: "14px",
                  color: "#333",
                }}
              >
                Services
              </span>
            </div>
            <div style={{ background: "#f6f7fa", padding: "12px 16px" }}>
              <div style={{ marginTop: "8px" }}>
                <span style={{ fontSize: "14px", color: "#333" }}>
                  {serviceNameString}
                </span>
              </div>
            </div>
          </>
        )}

        {productNames?.length > 0 && (
          <>
            <div style={{ marginTop: "24px", marginBottom: "8px" }}>
              <span
                style={{
                  verticalAlign: "top",
                  fontSize: "14px",
                  color: "#333",
                }}
              >
                Products
              </span>
            </div>
            <div style={{ background: "#f6f7fa", padding: "12px 16px" }}>
              <div style={{ marginTop: "8px" }}>
                <span style={{ fontSize: "14px", color: "#333" }}>
                  {productListString}
                </span>
              </div>
            </div>
          </>
        )}

        <div style={{ marginTop: "24px", marginBottom: "8px" }}>
          <span
            style={{ verticalAlign: "top", fontSize: "14px", color: "#333" }}
          >
            Store
          </span>
        </div>
        <div style={{ background: "#f6f7fa", padding: "12px 16px" }}>
          <span style={{ color: "#2e56f2", fontSize: "14px" }}>
            {data?.applicable_type}
          </span>
        </div>
      </div>
    </Modal>
  );
};

export default ApplicableModels;
