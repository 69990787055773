import { CalendarOutlined, ShopFilled } from "@ant-design/icons";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { Col, Row, Select, Typography } from "antd";
import { Option } from "antd/es/mentions";
import React, { useEffect, useRef, useState } from "react";
import Footer from "../headerfooter/footer/Footer";
const genderfem = `${process.env.PUBLIC_URL}/images/logo/Staffing_Gen_Female.png`;

const { Text, Title } = Typography;

const monthselector: React.CSSProperties = {
  width: 120,
  color: "#fff !important",
};

const BookingList: React.FC = () => {
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );
  const [selectedMonth, setSelectedMonth] = useState(
    new Date().toLocaleString("default", { month: "long" })
  );
  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleDateString("en-US", { month: "short", day: "numeric" })
  );
  const [calendarDays, setCalendarDays] = useState<
    { date: string; day: string; fullDate: Date }[]
  >([]);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const calendarRef = useRef<HTMLDivElement>(null);
  const slotRef = useRef<HTMLDivElement>(null);
  const fullCalendarRef = useRef<any>(null); // Add ref for FullCalendar

  const appointments = [
    {
      staffInitial: "P",
      staffName: "LIZA",
      service: "NEW SET EYELASH EXTENSION",
      peopleCount: "1 people",
      startTime: "2025-03-16T10:00:00Z",
      endTime: "2025-03-16T11:30:00Z",
      date: "2025-03-16",
    },
    {
      staffInitial: "A",
      staffName: "ANNA",
      service: "EYEBROW SHAPING",
      peopleCount: "1 people",
      startTime: "2025-03-18T09:30:00Z",
      endTime: "2025-03-18T10:00:00Z",
      date: "2025-03-18",
    },
    // ... (rest of your appointments)
  ];

  const statuses = ["Upcoming", "Arrived", "Paid", "No show", "Blocked"];
  const statusColors = ["#ff4d4f", "#faad14", "#52c41a", "#1890ff", "#d9d9d9"];

  const getTimeRange = () => {
    const filteredAppointments = getAppointmentsForSelectedDate();
    if (filteredAppointments.length === 0) {
      return { earliest: "08:00:00", latest: "18:00:00" };
    }
    const startTimes = filteredAppointments.map((appt) =>
      new Date(appt.start).getTime()
    );
    const endTimes = filteredAppointments.map((appt) =>
      new Date(appt.end).getTime()
    );
    const earliestTime = new Date(Math.min(...startTimes));
    const latestTime = new Date(Math.max(...endTimes));
    earliestTime.setHours(earliestTime.getHours() - 1);
    latestTime.setHours(latestTime.getHours() + 1);
    return {
      earliest: earliestTime.toTimeString().split(" ")[0],
      latest: latestTime.toTimeString().split(" ")[0],
    };
  };

  const scrollToCurrentWeek = (date: Date) => {
    if (calendarRef.current) {
      const days = calendarRef.current.querySelectorAll(
        "div[class^='calendar-day']"
      );
      days.forEach((day: Element) => {
        const dayText = (day as HTMLElement).innerText.trim();
        if (dayText.includes(date.getDate().toString())) {
          (day as HTMLElement).scrollIntoView({
            behavior: "smooth",
            inline: "start",
          });
          days.forEach((d) => (d as HTMLElement).classList.remove("active"));
          (day as HTMLElement).classList.add("active");
        }
      });
    }
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(
        new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    }, 1000);

    const today = new Date();
    const firstDayOfWeek = new Date(today);
    firstDayOfWeek.setDate(today.getDate() - today.getDay() + 1);

    setSelectedMonth(today.toLocaleString("default", { month: "long" }));
    setSelectedDate(today);
    setCurrentDate(
      today.toLocaleDateString("en-US", { month: "short", day: "numeric" })
    );
    updateCalendarDays(today);

    setTimeout(() => scrollToCurrentWeek(today), 200);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    // When selectedDate changes, update the calendar view and refetch events
    if (fullCalendarRef.current) {
      fullCalendarRef.current.getApi().gotoDate(selectedDate); // Move calendar to selected date
      fullCalendarRef.current.getApi().refetchEvents(); // Refetch events
    }
  }, [selectedDate]);

  const handleMonthChange = (value: string) => {
    setSelectedMonth(value);
    const year = new Date().getFullYear();
    const monthIndex = getMonthIndex(value);
    const daysInNewMonth = new Date(year, monthIndex + 1, 0).getDate();
    const newDay = Math.min(selectedDate.getDate(), daysInNewMonth);
    const newDate = new Date(year, monthIndex, newDay);
    setCurrentDate(
      newDate.toLocaleDateString("en-US", { month: "short", day: "numeric" })
    );
    setSelectedDate(newDate);
    updateCalendarDays(newDate, monthIndex);
  };

  const getMonthIndex = (monthName: string) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return months.indexOf(monthName);
  };

  const updateCalendarDays = (referenceDate: Date, monthIndex?: number) => {
    const year = referenceDate.getFullYear();
    const month =
      monthIndex !== undefined ? monthIndex : getMonthIndex(selectedMonth);
    const daysInMonth = new Date(year, month + 1, 0).getDate();
    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      const newDate = new Date(year, month, i);
      daysArray.push({
        date: newDate.getDate().toString(),
        day: newDate.toLocaleString("default", { weekday: "short" }),
        fullDate: newDate,
      });
    }
    setCalendarDays(daysArray);
  };

  const isCurrentDate = (fullDate: Date) => {
    const today = new Date();
    return (
      fullDate.getDate() === today.getDate() &&
      fullDate.getMonth() === today.getMonth() &&
      fullDate.getFullYear() === today.getFullYear()
    );
  };

  const isSelectedDate = (fullDate: Date) => {
    return (
      fullDate.getDate() === selectedDate.getDate() &&
      fullDate.getMonth() === selectedDate.getMonth() &&
      fullDate.getFullYear() === selectedDate.getFullYear()
    );
  };

  const handleDateSelect = (fullDate: Date) => {
    setSelectedDate(fullDate);
    setCurrentDate(
      fullDate.toLocaleDateString("en-US", { month: "short", day: "numeric" })
    );
  };

  const renderEventContent = (eventInfo: any) => {
    return (
      <div
        style={{
          backgroundColor: "#d7e6fb",
          borderLeft: "4px solid #8badeb",
          padding: "10px",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          height: "max-content",
          minHeight: 100,
        }}
      >
        <Row align="middle">
          <Col span={24}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                textAlign: "center",
              }}
            >
              <Text
                style={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: "#000",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src={genderfem}
                  alt=""
                  style={{
                    width: 30,
                    height: 30,
                    transform: "rotate(45deg)",
                    marginRight: "4px",
                    borderRadius: "100px",
                  }}
                />
                {eventInfo.event.extendedProps.staffName}
                <img
                  src={genderfem}
                  alt=""
                  style={{
                    width: 20,
                    height: 20,
                    transform: "rotate(45deg)",
                    marginLeft: "4px",
                    borderRadius: "100px",
                  }}
                />
              </Text>
              <Text
                style={{ color: "#818d9d", fontSize: "12px", marginTop: "5px" }}
              >
                {eventInfo.event.extendedProps.peopleCount}
              </Text>
            </div>
            <Text
              style={{ fontSize: "12px", fontWeight: 600 }}
            >{`${eventInfo.event.start.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}-${eventInfo.event.end.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}`}</Text>
            <br />
            <Text style={{ fontSize: "12px", fontWeight: 600 }}>
              {eventInfo.event.extendedProps.service}
            </Text>
          </Col>
        </Row>
      </div>
    );
  };

  const getAppointmentsForSelectedDate = () => {
    if (!selectedDate) return [];
    const dateStr = selectedDate.toISOString().split("T")[0];
    const filtered = appointments.filter((appt) => appt.date === dateStr);
    console.log(`Filtered events for ${dateStr}:`, filtered); // Debug log
    return filtered.map((appt) => ({
      title: `${appt.staffName} - ${appt.service}`,
      start: appt.startTime,
      end: appt.endTime,
      extendedProps: {
        staffName: appt.staffName,
        service: appt.service,
        peopleCount: appt.peopleCount,
      },
    }));
  };

  const { earliest, latest } = getTimeRange();

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          overflowY: "hidden",
          position: "relative",
          paddingBottom: "70px",
        }}
      >
        {/* Header */}
        <div
          style={{
            backgroundColor: "#2e56f2",
            padding: "10px 15px",
            marginTop: 0,
            color: "#fff",
          }}
        >
          <Row justify="space-between" align="middle">
            <Col>
              <Title level={4} style={{ color: "#fff", fontWeight: 600 }}>
                Daco Booking System
              </Title>
              <Title level={5} style={{ color: "#d9d9d9", marginTop: 40 }}>
                <ShopFilled /> Lam:Eyelash & Eyebrows
              </Title>
            </Col>
          </Row>
          <Row
            justify="space-between"
            align="middle"
            style={{ marginTop: "10px", color: "white" }}
          >
            <Col>
              <Select
                value={selectedMonth}
                onChange={handleMonthChange}
                style={monthselector}
                bordered={false}
                dropdownStyle={{ color: "#fff" }}
              >
                <Option value="January">January</Option>
                <Option value="February">February</Option>
                <Option value="March">March</Option>
                <Option value="April">April</Option>
                <Option value="May">May</Option>
                <Option value="June">June</Option>
                <Option value="July">July</Option>
                <Option value="August">August</Option>
                <Option value="September">September</Option>
                <Option value="October">October</Option>
                <Option value="November">November</Option>
                <Option value="December">December</Option>
              </Select>
              <style>{`.ant-select-selection-item { color: #fff !important; } .ant-select-arrow { color: #fff !important; }`}</style>
            </Col>
            <Col>
              <CalendarOutlined style={{ fontSize: 18, color: "#fff" }} />{" "}
              Panding(0)
            </Col>
          </Row>
        </div>

        {/* Calendar Section */}
        <div style={{ padding: "15px 0", borderBottom: "1px solid #f0f0f0" }}>
          <div
            ref={calendarRef}
            style={{
              display: "flex",
              overflowX: "auto",
              scrollBehavior: "smooth",
              margin: "0px 8px",
              padding: "0 10px",
            }}
          >
            {calendarDays.map((dayObj, index) => (
              <div
                key={index}
                className={`calendar-day-${dayObj.date}`}
                onClick={() => handleDateSelect(dayObj.fullDate)}
                style={{
                  textAlign: "center",
                  minWidth: "45px",
                  marginRight: "4px",
                  cursor: "pointer",
                  backgroundColor: isSelectedDate(dayObj.fullDate)
                    ? "#2e56f2"
                    : "#fff",
                  padding: "8px 4px",
                  borderRadius: isSelectedDate(dayObj.fullDate) ? "8px" : "0px",
                }}
              >
                <Text
                  style={{
                    color: isSelectedDate(dayObj.fullDate) ? "#fff" : "#818d9d",
                    fontWeight: isCurrentDate(dayObj.fullDate) ? 600 : 400,
                  }}
                >
                  {dayObj.day}
                </Text>
                <div
                  style={{
                    fontSize: "16px",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: isSelectedDate(dayObj.fullDate) ? "#fff" : "#252527",
                    fontWeight: isCurrentDate(dayObj.fullDate) ? 800 : 600,
                    margin: "auto",
                  }}
                >
                  {dayObj.date}
                </div>
                <Text
                  style={{
                    color: isSelectedDate(dayObj.fullDate) ? "#fff" : "#818d9d",
                    fontWeight: isCurrentDate(dayObj.fullDate) ? 600 : 400,
                  }}
                >
                  (1)
                </Text>
              </div>
            ))}
          </div>
          <Row style={{ marginTop: "10px", padding: "0 10px" }}>
            {statuses.map((status, index) => (
              <Col
                key={index}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "15px",
                }}
              >
                <span
                  style={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "50%",
                    backgroundColor: statusColors[index],
                    marginRight: "5px",
                  }}
                />
                <Text style={{ color: "#818d9d", fontSize: "12px" }}>
                  {status}
                </Text>
              </Col>
            ))}
          </Row>
        </div>

        {/* Timeline Section */}
        <div
          ref={slotRef}
          style={{
            position: "relative",
            zIndex: 1,
            height: "calc(100vh - 200px)",
            overflowY: "auto",
          }}
        >
          <FullCalendar
            ref={fullCalendarRef} // Attach ref to FullCalendar
            plugins={[timeGridPlugin]}
            initialView="timeGridDay"
            initialDate={selectedDate} // Set initial date to selectedDate
            headerToolbar={{ left: "", center: "", right: "" }}
            allDaySlot={false}
            events={getAppointmentsForSelectedDate} // Use function directly
            eventContent={renderEventContent}
            slotMinTime={earliest} // Dynamic min time
            slotMaxTime={latest} // Dynamic max time
            slotDuration="00:30:00"
            height="auto"
            contentHeight="auto"
            slotLabelFormat={{
              hour: "2-digit",
              minute: "2-digit",
              meridiem: false,
            }}
            eventDidMount={(info) => console.log("Event mounted:", info.event)} // Debug
          />
          <style>{`
            .fc-timegrid-event-harness-inset .fc-timegrid-event { background-color: #fff !important; border-top: 0px !important; border-radius: 6px; padding: 0px !important; height: max-content !important; min-height: 100px; display: flex; flex-direction: column; color: #333 !important; width: 220px; }
            .fc-scrollgrid-sync-inner { display: none; }
            .fc .fc-toolbar.fc-header-toolbar { margin-bottom: 0em; }
            .fc-timegrid-slot-label { color: #818d9d; font-size: 12px; padding: 5px 10px; border: none; }
            .fc-timegrid-event { border: none; background: none !important; }
            .fc-timegrid-event-harness { width: 100%; }
            .fc-timegrid-event .fc-event-main { padding: 0; }
            .fc-timegrid-slot-lane { border: none; }
          `}</style>
        </div>

        {/* Add Appointment Icon */}
        <div
          style={{
            position: "fixed",
            bottom: "90px",
            right: "20px",
            width: "50px",
            height: "50px",
            backgroundColor: "#2e56f2",
            borderRadius: "50%",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "24px",
            fontWeight: 600,
            zIndex: 1000,
            boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
          }}
        >
          +
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BookingList;
