import React, { useState, useEffect } from "react";
import {
  Layout,
  Menu,
  Button,
  Descriptions,
  Switch,
  Card,
  Row,
  Col,
  message,
  Typography,
  Tree,
} from "antd";
import {
  ArrowLeftOutlined,
  DownOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { get } from "../../services/Apiclient"; // Assuming this is where the get function is located
import { useNavigate } from "react-router-dom";
import OpeningHoursView from "../../components/model/merchant/OpeningHoursView";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const defaultStoreImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Store.png`;
const { Header } = Layout;
interface Outlet {
  id: string;
  storename: string;
  storecode: string;
  outletId: string; // Assuming this is the ID used to fetch details
}
const { Title, Text, Link } = Typography;
interface OutletDetails {
  id: string;
  storename: string;
  storecode: string;
  parentstore: string;
  storelevel: string;
  storeleveltype: string;
  taxid: string;
  address: string;

  latitude: string;
  longitude: string;
  businesstype: string[];
  businessTypeArrayNames: string[];
  storestatus: number;
  website: string | null;
  facebook: string | null;
  youtube: string | null;
  instagram: string | null;
  tiktok: string | null;
  xiaohongshu: string | null;
  google: string | null;
  storeintroduction: string | null;
  storelogo: string | null;
  storelogourl: string | null;
  customer_language: string;
  default_language: string;
  currency: string;
  timeformat: string | null;
  timezone: string;
  enable_language: number;
  customer_facing_language_second: string | null;
  outletid: string;
  username: string;
  email: string;
  name: string;
  parentstorename: string;
  outletDaysInfo: {
    id: string;
    dayofweek: number;
    dayname: string;
    starttime: string;
    endtime: string;
  }[];
  outletshowcase: any[];
  countrycode: string;
  phone_number: string; // Add this line
}
const BusinessPage = () => {
  // const treeData = [
  //   {
  //     title: "001-Suilady",
  //     key: "001-Suilady",
  //     children: [
  //       { title: "1-A3-store", key: "1-A3-store" },
  //       {
  //         title: "10100-Testing Buissness",
  //         key: "10100-Testing Buissness",
  //         children: [
  //           {
  //             title: "ggfg-fggg",
  //             key: "ggfg-fggg",
  //             children: [
  //               {
  //                 title: "9976-Learning Center QA",
  //                 key: "9976-Learning Center QA",
  //                 children: [
  //                   {
  //                     title: "9978-Learning Center QA 2",
  //                     key: "9978-Learning Center QA 2",
  //                   },
  //                 ],
  //               },
  //             ],
  //           },
  //         ],
  //       },
  //     ],
  //   },
  //   {
  //     title: "7874-A1-Outlet (Business Detail)",
  //     key: "7874-A1-Outlet",
  //   },
  //   {
  //     title: "9898-A2-Outlet (Business Detail)",
  //     key: "9898-A2-Outlet",
  //   },
  //   {
  //     title: "asdfsad-abc store",
  //     key: "asdfsad-abc",
  //     children: [
  //       {
  //         title: "123123-alvi",
  //         key: "123123-alvi",
  //       },
  //     ],
  //   },
  // ];
  const onSelect = (selectedKeys: any, info: any) => {
    console.log("Selected:", selectedKeys, info);
  };

  const onExpand = (expandedKeys: any) => {
    console.log("Expanded:", expandedKeys);
  };

  const navigate = useNavigate();
  const handleAddClick = () => {
    navigate("/settings/business-details/add");
  };
  const handleEditClick = () => {
    if (selectedOutlet) {
      navigate(`/settings/business-details/${selectedOutlet.id}`);
    }
  };
  const [outlets, setOutlets] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedOutlet, setSelectedOutlet] = useState<OutletDetails | null>(
    null
  );

  const [selectedOutletId, setSelectedOutletId] = useState<any>(
    localStorage.getItem("outlet_id") || null
  );

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showEditModal = () => {
    setIsModalVisible(true);
  };

  const [layoutStyle, setLayoutStyle] = useState<any>({
    height: "100%",
    backgroundColor: "hsla(0, 0%, 100%, .5)",
  });

  const [bodylayoutStyle, setBodyLayoutStyle] = useState<any>({
    height: "100%",
  });

  useEffect(() => {
    const fetchOutlets = async () => {
      try {
        const response = await get("/outlet/list/1/50/storename/DESC");
        // const response = await get("/user/storedata");
        if (response.data.success) {
          setOutlets(response.data.data);
        } else {
          message.error("Failed to fetch outlet data");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        message.error("Error fetching outlet data");
      } finally {
        setLoading(false);
      }
    };
    fetchOutlets();
    handleSelectStore();

    const handleResize = () => {
      if (window.innerWidth <= 992) {
        setLayoutStyle({
          backgroundColor: "hsla(0, 0%, 100%, .5)",
          height: "200px", // Small screen height
        });
        setBodyLayoutStyle({
          height: "100px", // Small screen height
        });
      } else {
        setLayoutStyle({
          height: "100%",
          backgroundColor: "hsla(0, 0%, 100%, .5)",
        });
        setBodyLayoutStyle({
          height: "100%", // Small screen height
        });
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSelectStore = async () => {
    let id = selectedOutletId
      ? selectedOutletId
      : localStorage.getItem("outlet_id");
    if (id) {
      try {
        const response = await get(`/outlet/${id}`);
        if (response.data.success) {
          setSelectedOutlet(response.data.data);
        } else {
          message.error("Failed to fetch selected outlet details");
        }
      } catch (error) {
        console.error("Error fetching outlet details:", error);
        message.error("Error fetching selected outlet details");
      }
    } else {
      setSelectedOutlet(null);
    }
  };

  useEffect(() => {
    handleSelectStore();
  }, [selectedOutletId]);
  const menuItems = outlets.map((outlet) => ({
    key: outlet.id,
    label: outlet.storename,
  }));
  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Row
        justify="space-between"
        align="middle"
        style={{ padding: "16px 24px" }}
      >
        <Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <div
              onClick={() => navigate("/settings")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
              />
            </div>
            <h2 style={{ margin: 0 }}>Business Details</h2>
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} style={{ margin: "0" }}>
        <Col span={6} xl={6} lg={6} xs={24}>
          <Card
            title="Locations"
            bordered={false}
            extra={
              localStorage.getItem("userRole") == "Merchant" && (
                <Button
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={handleAddClick}
                >
                  Add
                </Button>
              )
            }
            style={layoutStyle}
            bodyStyle={bodylayoutStyle}
          >
            <Menu
              mode="inline"
              defaultSelectedKeys={selectedOutletId}
              style={{
                borderRight: 0,
                backgroundColor: "hsla(0, 0%, 100%, .5)",
                maxHeight: "calc(100vh - 230px)",
                overflowY: "auto",
                height: "inherit",
              }}
              items={menuItems}
              onSelect={({ key }) => setSelectedOutletId(key)}
            />
            {/* <Tree
                showLine
                defaultExpandAll
                treeData={outlets}
                onSelect={onSelect}
                onExpand={onExpand}
                selectable
                switcherIcon={<DownOutlined />}
              /> */}
          </Card>
        </Col>
        <Col span={18} xl={18} lg={18} xs={24}>
          <Card
            style={{
              height: "calc(100vh - 120px)",
              backgroundColor: "hsla(0, 0%, 100%, .5)",
              overflowY: "auto",
            }}
          >
            <Row
              justify="center"
              align="middle"
              gutter={[16, 16]}
              style={{ padding: "16px 24px" }}
            >
              <Col span={12} xs={24} sm={12} md={12} lg={12} xl={12}>
                <h2 style={{ margin: 0 }} className="business-heading">
                  Business Details
                </h2>
              </Col>
              {selectedOutlet && (
                <Col
                  span={12}
                  xs={24}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="business-heading-btn"
                >
                  <Button type="primary" onClick={handleEditClick}>
                    Edit
                  </Button>
                </Col>
              )}
            </Row>
            {selectedOutlet ? (
              <>
                <h3>Basic Info</h3>
                <Card
                  bordered={false}
                  style={{
                    backgroundColor: "hsla(0, 0%, 100%, .5)",
                    padding: 16,
                  }}
                >
                  <Row gutter={[16, 16]} align="middle">
                    <Col span={3} xs={24} sm={24} md={3} lg={3} xl={3}>
                      <img
                        src={
                          selectedOutlet.storelogo &&
                          selectedOutlet.storelogo !== "default.png"
                            ? selectedOutlet.storelogourl || defaultStoreImage
                            : defaultStoreImage
                        }
                        alt="Store Logo"
                        style={{
                          width: 80,
                          height: 80,
                          borderRadius: "50%",
                          marginRight: 16,
                        }}
                      />
                    </Col>
                    <Col span={15} xs={24} sm={12} md={15} lg={15} xl={15}>
                      <h3 style={{ fontWeight: "bold", margin: 0 }}>
                        {selectedOutlet.storename || "Store Name"}
                      </h3>
                      <p style={{ margin: 0 }}>
                        #{selectedOutlet.storecode || "Store Code"}
                      </p>
                      <p style={{ margin: 0 }}>
                        {selectedOutlet.storeleveltype || "Store Level"}
                      </p>
                    </Col>
                    <Col span={6} xs={24} sm={12} md={6} lg={6} xl={6}>
                      <span>Store Status</span>
                      <Switch
                        defaultChecked={selectedOutlet.storestatus === 1}
                        value={selectedOutlet.storestatus == 0 ? false : true}
                        style={{ marginLeft: 8 }}
                      />
                    </Col>
                    <Row gutter={[16, 16]}>
                      <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Text className="normalbreak">Parent Store:</Text>
                      </Col>
                      <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                        {selectedOutlet.parentstorename || "-"}
                      </Col>

                      <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Text className="normalbreak">Email:</Text>
                      </Col>
                      <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                        {selectedOutlet.email || "-"}
                      </Col>

                      <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Text className="normalbreak">Phone Number:</Text>
                      </Col>
                      <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                        +{selectedOutlet?.countrycode || ""}{" "}
                        {selectedOutlet.phone_number || "-"}
                      </Col>

                      <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                        <Text className="normalbreak">Address:</Text>
                      </Col>
                      <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                        <Text
                          className="normalbreak"
                          style={{
                            display: "block",
                            width: "inherit",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {selectedOutlet.address || "-"}
                        </Text>
                      </Col>
                    </Row>
                  </Row>
                </Card>
                <h3 className="normalbreak">More Business Info</h3>
                <Card>
                  {/* <Descriptions column={1}>
                      <Descriptions.Item label="Business Type">
                        {selectedOutlet.businesstype.join(", ")}
                      </Descriptions.Item>
                      <Descriptions.Item label="Opening Hours">
                        {selectedOutlet.outletDaysInfo.map((day) => (
                          <div key={day.id}>
                            {day.dayname}: {day.starttime} - {day.endtime}
                          </div>
                        ))}
                      </Descriptions.Item>
                    </Descriptions> */}
                  <Row gutter={[16, 16]} style={{ marginTop: "2%" }}>
                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">Business Type :</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Text
                        className="normalbreak"
                        style={{
                          display: "block",
                          width: "inherit",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOutlet.businessTypeArrayNames.join(", ")}
                      </Text>
                    </Col>
                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">Opening Hours :</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      {/* {selectedOutlet.outletDaysInfo.map((day) => (
                          <div key={day.id}>
                            {day.dayname}: {day.starttime} - {day.endtime}
                          </div>
                        ))} */}
                      <Link
                        style={{
                          textDecoration: "underline",
                          textUnderlineOffset: "4px",
                        }}
                        onClick={() => showEditModal()}
                      >
                        View
                      </Link>
                    </Col>
                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">Time Zone:</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Text
                        className="normalbreak"
                        style={{
                          display: "block",
                          width: "inherit",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOutlet.timezone || "-"}
                      </Text>
                    </Col>
                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">Time Format:</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Text
                        className="normalbreak"
                        style={{
                          display: "block",
                          width: "inherit",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOutlet.timeformat || "-"}
                      </Text>
                    </Col>

                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">Currency:</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Text
                        className="normalbreak"
                        style={{
                          display: "block",
                          width: "inherit",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOutlet.currency || "-"}
                      </Text>
                    </Col>

                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">Default Language:</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Text
                        className="normalbreak"
                        style={{
                          display: "block",
                          width: "inherit",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOutlet.default_language || "-"}
                      </Text>
                    </Col>

                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">Store Introduction:</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Text
                        className="normalbreak"
                        style={{
                          display: "block",
                          width: "inherit",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOutlet.storeintroduction || "-"}
                      </Text>
                    </Col>

                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">Website:</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Text
                        className="normalbreak"
                        style={{
                          display: "block",
                          width: "inherit",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOutlet.website || "-"}
                      </Text>
                    </Col>

                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">Facebook:</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Text
                        className="normalbreak"
                        style={{
                          display: "block",
                          width: "inherit",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOutlet.facebook || "-"}
                      </Text>
                    </Col>

                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">Instagram:</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Text
                        className="normalbreak"
                        style={{
                          display: "block",
                          width: "inherit",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOutlet.instagram || "-"}
                      </Text>
                    </Col>

                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">Youtube:</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Text
                        className="normalbreak"
                        style={{
                          display: "block",
                          width: "inherit",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOutlet.youtube || "-"}
                      </Text>
                    </Col>

                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">TikTok:</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Text
                        className="normalbreak"
                        style={{
                          display: "block",
                          width: "inherit",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOutlet.tiktok || "-"}
                      </Text>
                    </Col>

                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">Google:</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Text
                        className="normalbreak"
                        style={{
                          display: "block",
                          width: "inherit",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOutlet.google || "-"}
                      </Text>
                    </Col>

                    <Col span={8} xs={24} sm={24} md={8} lg={8} xl={8}>
                      <Text className="normalbreak">Xiaohongshu:</Text>
                    </Col>
                    <Col span={16} xs={24} sm={24} md={16} lg={16} xl={16}>
                      <Text
                        className="normalbreak"
                        style={{
                          display: "block",
                          width: "inherit",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {selectedOutlet.xiaohongshu || "-"}
                      </Text>
                    </Col>
                  </Row>

                  {/* <Descriptions.Item label="Created At">{new Date().toLocaleString()}</Descriptions.Item> */}
                </Card>
              </>
            ) : (
              <p className="normalbreak">
                Please select a store to view details.
              </p>
            )}
          </Card>
        </Col>
      </Row>
      <OpeningHoursView
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        openingHours={selectedOutlet?.outletDaysInfo}
      />
    </>
  );
};
export default BusinessPage;
