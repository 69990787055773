import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Dropdown,
  Menu,
  Typography,
  Layout,
  Select,
  Space,
  Modal,
  message,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { deleteData, get, post, put } from "../../services/Apiclient";
import { useCurrency } from "../../contexts/CurrencyContext";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import Datatable from "../../components/Datatable";
import { Helmet } from "react-helmet";
// import { currencySymbol } from "../../constants/currencySymbol";
const { Option } = Select;
const { Text } = Typography;

const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;

const Order_ListItem_Time = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Order_ListItem_Time.png`;
const OrderItem_Member = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_OrderItem_Member.png`;

interface statusCounts {
  All: number;
  Paid: number;
  Unpaid: number;
  Voided: number;
}

export default function Einvoices() {
  const { currencySymbol } = useCurrency();
  const [isFirstConsultantModalVisible, setIsFirstConsultantModalVisible] =
    useState(false);
  const showFirstConsultantModal = () => setIsFirstConsultantModalVisible(true);
  const [firstConsultantText, setFirstConsultantText] = useState("");
  const [ConsultantId, setConsultantId] = useState("null");
  const [status, setStatus] = useState("All Status");
  const [selectedSortMethod, setSelectedSortMethod] = useState(
    "Sort by creation time"
  );
  const [customdatevisible, setCustomvisible] = useState(false);
  const [sortMethod, setSortMethod] = useState("Sort by creation time");
  const [isSearching, setIsSearching] = useState(false);
  const [isMerging, setIsMerging] = useState(false);
  const [rotated, setRotated] = useState(false);
  const [statusCounts, setStatusCounts] = useState<any>(0);
  const [billingData, setBillingData] = useState<any[]>([]);
  const navigate = useNavigate();
  const [noteRemark, setNoteRemark] = useState<string>("");
  const [noteModalVisible, setNoteModalVisible] = useState(false);
  const [submitModalVisible, setSubmitModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isDeleteBillModalVisible, setIsDeleteBillModalVisible] =
    useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [noteBillId, setNoteBillId] = useState<string>("");
  const month = moment().format("MMMM");

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const closeDeleteMemberModal = () => {
    setIsDeleteBillModalVisible(false);
  };

  const handleConfirmBill = async () => {
    setIsDeleteBillModalVisible(false);
    await put(`/billing/unpaid/remove/${selectedItem}`, {});
    message.success("Bill deleted successfully!");
    fetchBillingData();
  };

  // const [isModalVisible, setIsModalVisible] = useState(false);

  // const showModal = () => {
  //   setIsModalVisible(true);
  // };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  // const handleSave = (selectedData: any) => {
  //   console.log("Selected staff:", selectedData);
  //   setIsModalVisible(false);
  // };

  const fetchBillingData = async () => {
    try {
      // const todayDate = dayjs().format("YYYY-MM-DD");
      const response = await get(
        `/billing/einvoicelist?outlet=${localStorage.getItem(
          "outlet_id"
        )}&startdate=${startDate || "null"}&enddate=${endDate || "null"}`
      );

      if (response?.data?.success) {
        // console.log(response.data.data);
        setBillingData(
          response.data.data.map((item: any, index: any) => ({
            ...item,
            key: index, // Add index as key
          }))
        ); // Assuming the billing data is in the `billingData` field
        setStatusCounts(response.data.totalRecords);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  const handleFirstConsultantSelect = (selectedObject: any) => {
    // setSelectedReferrer(selectedObject);
    if (selectedObject == null) {
      setFirstConsultantText("");
      setConsultantId("null");
    } else {
      setFirstConsultantText(selectedObject.name);
      setConsultantId(selectedObject.id);
    }
  };

  const onCancelFirstConsultantModal = () => {
    setIsFirstConsultantModalVisible(false);
  };
  const [currentsorting, setCurrentsorting] = useState("created_at");
  const handleMenuClick = (e: any) => {
    setCurrentsorting(e.key);
    switch (e.key) {
      case "null":
        setSortMethod("Sort by creation time");
        break;
      case "ref_no":
        setSortMethod("Sort by bill code");
        break;
      case "final_total":
        setSortMethod("Sort by amount");
        break;
      default:
        setSortMethod("Sort by creation time");
        break;
    }
  };
  const menu = (
    <Menu
      onClick={handleMenuClick}
      selectedKeys={[currentsorting]}
      style={{ textAlign: "center" }}
    >
      <Menu.Item key="created_at">Sort by creation time</Menu.Item>
      {/* <Menu.Item key="payment_date">Sort by payment time</Menu.Item> */}
      <Menu.Item key="ref_no">Sort by bill code</Menu.Item>
      <Menu.Item key="final_total">Sort by amount</Menu.Item>
    </Menu>
  );

  const [selectedOption, setSelectedOption] = useState("today");
  const [formattedDate, setFormattedDate] = useState(
    moment().format("DD-MM-YYYY")
  );
  const [startDate, setStartDate] = useState(moment().format("DD-MM-YYYY"));
  const [endDate, setEndDate] = useState<string | null>(
    moment().format("DD-MM-YYYY")
  );

  const onOptionSelect = (e: any) => {
    switch (e.key) {
      case "today":
        setSelectedOption("today");
        setFormattedDate(moment().format("DD-MM-YYYY"));
        setStartDate(moment().format("DD-MM-YYYY"));
        setEndDate(null);
        break;
      case "yesterday":
        setSelectedOption("yesterday");
        setFormattedDate(moment().subtract(1, "days").format("DD-MM-YYYY"));
        setStartDate(moment().subtract(1, "days").format("DD-MM-YYYY"));
        setEndDate(null);
        break;
      case "thisWeek":
        setSelectedOption("thisWeek");
        setFormattedDate(
          `${moment().startOf("week").format("DD MMM.")} ~ ${moment()
            .endOf("week")
            .format("DD MMM.")}`
        );
        setStartDate(moment().startOf("week").format("DD-MM-YYYY"));
        setEndDate(moment().endOf("week").format("DD-MM-YYYY"));
        break;
      case "custom":
        setSelectedOption("custom");
        setFormattedDate(
          `${moment().startOf("week").format("DD MMM.")} ~ ${moment()
            .endOf("week")
            .format("DD MMM.")}`
        );
        setStartDate(moment().startOf("week").format("DD-MM-YYYY"));
        setEndDate(moment().endOf("week").format("DD-MM-YYYY"));
        break;
      default:
        setSelectedOption("today");
        setFormattedDate(moment().format("DD-MM-YYYY"));
        setStartDate(moment().format("DD-MM-YYYY"));
        setEndDate(null);
        break;
    }
  };

  const dropdownMenu = (
    <Menu
      selectedKeys={[selectedOption]}
      onClick={onOptionSelect}
      style={{ textAlign: "center" }}
    >
      <Menu.Item key="today">Today</Menu.Item>
      <Menu.Item key="yesterday">Yesterday</Menu.Item>
      <Menu.Item key="thisWeek">This week</Menu.Item>
      {/* <Menu.Item key="custom">Custom</Menu.Item> */}
    </Menu>
  );

  const [currentSelection, setCurrentSelection] = useState("null");

  const handleSelectionChange = (e: any) => {
    setCurrentSelection(e.key); // Update the selected value
    switch (e.key) {
      case "null":
        setStatus("All Status");
        break;
      case "unpaid":
        setStatus("unpaid");
        break;
      case "paid":
        setStatus("paid");
        break;
      case "voided":
        setStatus("voided");
        break;
      default:
        setStatus("All Status");
        break;
    }
  };

  const menus = (
    <Menu onClick={handleSelectionChange} selectedKeys={[currentSelection]}>
      <Menu.Item key="null">All Status</Menu.Item>
      <Menu.Item key="unpaid">Unpaid</Menu.Item>
      <Menu.Item key="paid">Paid</Menu.Item>
      <Menu.Item key="voided">Voided</Menu.Item>
    </Menu>
  );

  const handleClick = () => {
    setRotated(!rotated);
    fetchBillingData();
  };

  useEffect(() => {
    fetchBillingData();
  }, [status, ConsultantId, selectedOption, formattedDate, sortMethod]);

  const handleNoteModalShow = (billId: string, note: string) => {
    setNoteBillId(billId);
    setNoteRemark(note);
    setNoteModalVisible(true);
  };

  const handleNoteConfirm = async () => {
    try {
      const url = `/billing/unpaid/${noteBillId}`;
      // const outletId = localStorage.getItem("outlet_id");
      const payload = {
        note: noteRemark,
      };

      const response = await put(url, payload);
      if (response.data.success) {
        fetchBillingData();
      }
    } catch (error) {
      console.log("Error while updating note", error);
    }
    setNoteModalVisible(false);
  };

  const handleNoteCancel = async () => {
    setNoteModalVisible(false);
  };
  const handleNoteRemarkChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setNoteRemark(e.target.value);
  };

  const handleShowSubmittedModal = () => {
    setSubmitModalVisible(true);
  };

  const confirmSubmitted = () => {
    setSubmitModalVisible(false);
    message.success("Transaction been uploaded");
  };

  const handleHideSubmitModal = () => {
    setSubmitModalVisible(false);
  };

  const columns = [
    {
      title: "Ref#",
      dataIndex: "ref_no",
      key: "ref_no",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Date",
      dataIndex: "order_date",
      key: "order_date",
      width: 100,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
      width: 150,
      render: (text: any) => (
        <span style={{ textAlign: "left", display: "block" }}>
          {text || "Walk-in Customer"}
        </span>
      ),
    },
    {
      title: "Total Price",
      dataIndex: "final_total",
      key: "final_total",
      width: 100,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {currencySymbol.CURRENCY_SYMBOL}
          {text}
        </span>
      ),
    },
  ];

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([
    {
      key: "1",
      ref: "EX117720250221089",
      date: "2025-02-21",
      customername: "Walk-in customer",
      totalprice: "144.12",
    },
    {
      key: "2",
      ref: "EX117720250221090",
      date: "2025-02-21",
      customername: "Yoyo Low",
      totalprice: "185.12",
    },
  ]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.certifiedquality == true, // Disable checkbox when certifiedquality is true
    }),
  };

  const selectedRows = dataSource.filter((item) =>
    selectedRowKeys.includes(item.key)
  );
  // Sum the 'age' values of the selected rows
  const totalSelectedAge = selectedRows.reduce(
    (sum, item) => sum + parseFloat(item.totalprice),
    0
  );

  const handlesUpdate = async () => {
    const selectedBillIds = billingData
      .filter((item) => selectedRowKeys.includes(item.key)) // ✅ Match selected keys
      .map((item) => item.bill_id); // ✅ Extract bill_id

    let payload = {
      bill_ids: selectedBillIds,
    };

    const url = "/billing/einvoiceupdate";
    const response = await post(url, payload);
    if (response && response.data && response.data.success) {
      message.success("Successfully added!");
    } else {
      message.error(response.data.message || "Submission failed");
    }
    setNoteModalVisible(false);
    setSelectedRowKeys([]);
    fetchBillingData();
  };

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Layout style={{ height: "calc(100vh - 100px)", overflow: "hidden" }}>
        {/* <Row justify="space-between" align="middle">
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <h2>
                <span className="vertical-line"></span>E Invoices
              </h2>
            </div>
          </Col>
        </Row> */}
        <Row
          align="middle"
          justify="space-between"
          style={{
            padding: "0px 10px",
            position: "sticky",
            top: 0,
            zIndex: 10,
            marginBottom: "20px",
          }}
        >
          <Col style={{ display: "flex", alignItems: "center" }}>
            <h2 style={{ lineHeight: "22px", fontSize: "18px" }}>
              <span className="vertical-line"></span>eInvoice
            </h2>
          </Col>

          {/* Filters and Sorting Section */}
          <Col
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              gap: "20px",
              justifyContent: "flex-start",
              marginLeft: "30px",
            }}
          >
            {/* <Dropdown overlay={menus} trigger={["click"]}>
              <Typography.Link
                onClick={(e) => e.preventDefault()}
                style={{ fontWeight: 700, color: "#000000E0" }}
              >
                <Space>
                  {status}
                  <DownOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                </Space>
              </Typography.Link>
            </Dropdown> */}
            {/* <Button
                    type="link"
                    style={{
                      color: "#000000E0",
                      background: "none",
                      boxShadow: "none",
                      border: "none",
                      fontWeight: 700,
                    }}
                    value={firstConsultantText}
                    onClick={showFirstConsultantModal}
                  >
                    <span>{firstConsultantText || "All Staffs"}</span>{" "}
                    <DownOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                  </Button> */}
            {/* <Dropdown overlay={menu} trigger={["click"]}>
              <Typography.Link
                onClick={(e) => e.preventDefault()}
                style={{ fontWeight: 700, color: "#000000E0" }}
              >
                <Space>
                  {sortMethod}
                  <DownOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                </Space>
              </Typography.Link>
            </Dropdown> */}
            <Dropdown overlay={dropdownMenu} trigger={["click"]}>
              <Typography.Link
                onClick={(e) => e.preventDefault()}
                style={{ fontWeight: 700, color: "#000000E0" }}
              >
                <Space>
                  {formattedDate}
                  <DownOutlined style={{ color: "rgba(0, 0, 0, 0.25)" }} />
                </Space>
              </Typography.Link>
            </Dropdown>
          </Col>
          {selectedRowKeys.length > 0 && (
            <Col
              style={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                type="primary"
                onClick={() => {
                  setNoteModalVisible(true);
                }}
                style={{
                  background: "#2e56f2",
                  color: "#fff",
                  borderRadius: "6px",
                  width: "81px",
                  height: "50px",
                  fontWeight: 700,
                  fontSize: "15px",
                }}
              >
                Submit
              </Button>
            </Col>
          )}
        </Row>
        <Datatable<any>
          columns={columns}
          dataSource={billingData}
          showPagination={true}
          scroll={{ y: "calc(100vh - 280px)" }}
          showCheckbox={true}
          rowSelection={rowSelection}
          currentPage={currentPage}
          pageSize={pageSize}
          totalRecords={statusCounts}
          onPageChange={handlePageChange}
          dynamicTableHeight="calc(100vh - 240px)"
        />
      </Layout>
      <ConfirmDeleteModal
        visible={isDeleteBillModalVisible}
        onCancel={closeDeleteMemberModal}
        // onConfirm={() => handleDeleteApi(editingRecord.key)}
        onConfirm={handleConfirmBill}
        message="Are you sure you want to void the bill?"
      />
      <ConfirmDeleteModal
        visible={submitModalVisible}
        onCancel={handleHideSubmitModal}
        onConfirm={confirmSubmitted}
        message={`Are you sure to submmited ${month} Month Consolidate e-invoice?`}
      />
      <Modal
        open={noteModalVisible}
        title="E invoice"
        onCancel={handleNoteCancel}
        footer={null}
        centered
        bodyStyle={{ paddingBottom: "0" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Text>Are You sure You want to submit?</Text>
          <Button
            type="primary"
            style={{
              background: "#2e56f2",
              color: "#fff",
              borderRadius: "6px",
              width: "81px",
              height: "50px",
              fontWeight: 700,
              fontSize: "15px",
              marginTop: "20px",
            }}
            onClick={handlesUpdate}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
}
