import React, { useEffect, useState } from "react";
import { Layout, Button, Input, Row, Col, message, Pagination } from "antd";
import {
  CheckOutlined,
  CopyOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { deleteData, get, post } from "../../services/Apiclient";
import Datatable from "../../components/Datatable";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "../../styles/global.css";
import { log } from "console";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const { Content } = Layout;

interface MerchantData {
  id: string;
  key: React.Key;
  username: string;
  email: string;
  name: string;
  parentstore: string;
  address: string;
}

function MerchantList() {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<MerchantData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [editingRecord, setEditingRecord] = useState<any>(null);
  const [copiedRowKey, setCopiedRowKey] = useState<string | null>(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);

  const openDeleteModal = () => setIsDeleteModalVisible(true);
  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const handleDelete = async () => {
    if (editingRecord) {
      try {
        openDeleteModal();
      } catch (error) {
        message.error(`Error deleting merchant`);
      }
    }
  };

  const handleMerchantPreview = (record: any) => {
    const previewUrl = `${window.location.origin}/login?id=${record.key}`;

    navigator.clipboard
      .writeText(previewUrl)
      .then(() => {
        setCopiedRowKey(record.key);
        message.success("Merchant URL copied to clipboard!");
        setTimeout(() => setCopiedRowKey(null), 2000); // Reset icon after 2 seconds
      })
      .catch(() => message.error("Failed to copy URL"));
  };

  const [inProgress, setInProgress] = useState<{ [key: string]: boolean }>({});
  const [downloadUrl, setDownloadUrl] = useState<{ [key: string]: string }>({});
  const [globalInProgress, setGlobalInProgress] = useState(false);

  // const handleGenerateAPK = async (record: any) => {
  //   if (inProgress[record.key] || globalInProgress || downloadUrl[record.key])
  //     return;

  //   setInProgress((prev) => ({ ...prev, [record.key]: true }));
  //   setGlobalInProgress(true);

  //   message.info(
  //     "Generate APK started successfully. Please wait for a few minutes."
  //   );

  //   const requestBody = {
  //     websiteUrl: `${window.location.origin}/app/merchant/details/${record.key}`,
  //     projectPath: "/home/sui-test/tempapk/webviewapp",
  //     merchantId: record.key,
  //   };

  //   try {
  //     const response = await post("/generate-apk", requestBody);

  //     if (response.data.message === "Build started." && response.data.jobId) {
  //       message.success("APK Generated successfully!");

  //       const apkDownloadUrl = `https://tools.suibox.my/public/apk/${record.key}/app-release.apk`;

  //       setDownloadUrl((prev) => ({ ...prev, [record.key]: apkDownloadUrl }));
  //     } else {
  //       message.error(response.data.message || "Failed to generate APK");
  //     }
  //   } catch (error) {
  //     message.error("Error generating APK");
  //   } finally {
  //     setInProgress((prev) => ({ ...prev, [record.key]: false }));
  //     setGlobalInProgress(false);
  //   }
  // };

  const handlePreview = (record: any) => {
    localStorage.removeItem("customer_id");
    window.open(`/app/merchant/details/${record.key}`, "_blank");
  };

  const handleConfirm = () => {
    handleDeleteApi(editingRecord.key);
    closeDeleteModal();
  };

  const handleDeleteApi = async (id: string) => {
    try {
      const response = await deleteData(`/merchant/${id}`);
      if (response.data.success) {
        message.success("Merchant deleted successfully!");
        fetchData();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Error deleting Merchant");
    }
  };

  const columns = [
    // {
    //   title: "User Id",
    //   dataIndex: "id",
    //   key: "id",
    //   width: 150,
    //   sorter: (a: MerchantData, b: MerchantData) => a.id.localeCompare(b.id),
    //   showSorterTooltip: false,
    // },
    {
      title: "User Name",
      dataIndex: "username",
      key: "username",
      width: 70,
      sorter: (a: MerchantData, b: MerchantData) =>
        a.username.localeCompare(b.username),
      showSorterTooltip: false,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 100,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 80,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    // {
    //   title: "Parent Store",
    //   dataIndex: "parentstore",
    //   key: "parentstore",
    //   width: 150,
    // },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 100,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right" as "right",
      width: 110,
      render: (text: any, record: any): React.ReactNode => (
        <>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => navigate(`/merchant/edit/${record.key}`)}
          >
            Edit
          </a>
          {/* <a style={{ color: 'red' }} onClick={() => handleDeleteClick(record)} > */}
          <a
            style={{
              color: "rgb(245, 63, 63)",
              fontWeight: 700,
              textDecoration: "underline",
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => {
              setEditingRecord(record);
              handleDelete();
            }}
          >
            Delete
          </a>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => handleMerchantPreview(record)}
          >
            {copiedRowKey === record.key ? (
              <CheckOutlined style={{ color: "green", marginRight: "5px" }} />
            ) : (
              <CopyOutlined style={{ marginRight: "5px" }} />
            )}
            Copy URL
          </a>
          {/* <a
            style={{
              color: downloadUrl[record.key]
                ? "#28a745"
                : inProgress[record.key]
                ? "red"
                : "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
              pointerEvents: downloadUrl[record.key]
                ? "auto"
                : inProgress[record.key]
                ? "auto"
                : globalInProgress
                ? "none"
                : "auto",
              opacity: downloadUrl[record.key]
                ? 1
                : inProgress[record.key]
                ? 1
                : globalInProgress
                ? 0.5
                : 1,
            }}
            onClick={() => {
              if (downloadUrl[record.key]) {
                window.open(downloadUrl[record.key], "_blank");
              } else if (!globalInProgress && !inProgress[record.key]) {
                handleGenerateAPK(record);
              }
            }}
          >
            {downloadUrl[record.key]
              ? "Download APK"
              : inProgress[record.key]
              ? "In Progress..."
              : "Generate APK"}
          </a> */}

          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => {
              handlePreview(record);
            }}
          >
            Preview
          </a>
        </>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      const url = `/merchant/list/${currentPage}/${pageSize}`;
      const response = await get(url);
      if (response?.data?.success) {
        const apiData = response.data.data.map((item: any) => ({
          key: item.id,
          id: item.id,
          username: item.username,
          email: item.email,
          name: item.name,
          parentstore: item.parentstore,
          address: item.address,
        }));
        setDataSource(apiData);
        setTotalRecords(response.data.totalRecords);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize]);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Content>
        {/* Header Section */}
        <Row justify="space-between" align="middle">
          <Col>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
              />
            </div> */}
              <h2 style={{ margin: 0 }}>
                <span className="vertical-line"></span>Merchant Management
              </h2>
            </div>
          </Col>
          <Col>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate("/merchant/add")}
            >
              Add
            </Button>
          </Col>
        </Row>

        {/* Content Section */}
        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#f6f7f9",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
          }}
        >
          {/* Filters */}
          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col flex="auto">
              <Input.Search
                placeholder="Search by customer code, name, phone number, email"
                allowClear
                style={{ width: "30%" }}
                className="custom-search-input"
              />
            </Col>
          </Row>

          {/* Table */}
          <Datatable<MerchantData>
            columns={columns}
            dataSource={dataSource}
            scroll={{ y: "calc(100vh - 320px)" }}
            showCheckbox={true} // Custom scroll settings for this instance
            showPagination={false}
            dynamicTableHeight="calc(100vh - 280px)"
          />

          {/* Bottom Buttons */}
          <Row
            justify="space-between"
            style={{
              padding: "10px 20px",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            {/* Left Buttons */}
            <Col>
              <Button disabled type="default" style={{ marginRight: "10px" }}>
                Delete all selected
              </Button>
            </Col>

            {/* Right Section */}
            {totalRecords > 0 && (
              <Col>
                <Row justify="end" align="middle" style={{ gap: "16px" }}>
                  {/* Total Items Count */}
                  <Col>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(0,0,0,.85)",
                      }}
                    >
                      Total {totalRecords} items
                    </span>
                  </Col>

                  {/* Pagination */}
                  <Col>
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={totalRecords}
                      onChange={handlePageChange}
                      showSizeChanger={false}
                      itemRender={(page, type, originalElement) => {
                        if (type === "prev") {
                          const isDisabled = currentPage === 1;
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <LeftOutlined />
                              <span>Previous</span>
                            </a>
                          );
                        }
                        if (type === "next") {
                          const isDisabled =
                            currentPage === Math.ceil(totalRecords / pageSize);
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <span>Next</span>
                              <RightOutlined />
                            </a>
                          );
                        }
                        return originalElement;
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Content>
        <ConfirmDeleteModal
          visible={isDeleteModalVisible}
          onCancel={closeDeleteModal}
          // onConfirm={() => handleDeleteApi(editingRecord.key)}
          onConfirm={handleConfirm}
          message="Delete the Merchant?"
        />
      </Content>
    </>
  );
}

export default MerchantList;
