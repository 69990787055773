import {
  ArrowLeftOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  UnorderedListOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Pagination,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Datatable from "../../../components/Datatable";
import ColumnsReportsModal from "../../../components/model/ColumnsReportsModal";
import { get } from "../../../services/Apiclient";
import { useCurrency } from "../../../contexts/CurrencyContext";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface DataSourceItem {
  key: string;
  outlet_name: string;
  productcode: string;
  productname: string;
  category_name: string;
  stockintype: string;
  storage_type: string;
  product_quantity: number | undefined;
  unit: string;
  sellprice: number;
  product_amount: number;
  note: string;
  isSummaryRow?: boolean; // Flag to identify the summary row
}

const StockInInventoryReport: React.FC = () => {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const [isFolded, setIsFolded] = useState(false);
  const outletId = localStorage.getItem("outlet_id");
  const currentWeekStart = dayjs().startOf("isoWeek");
  const currentWeekEnd = dayjs().endOf("isoWeek");
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    currentWeekStart,
    currentWeekEnd,
  ]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [dataSource, setDataSource] = useState<DataSourceItem[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [storage, setStorage] = useState<any[]>([]);
  const [selectedStorage, setSelectedStorage] = useState<string | null>(null);
  const [outlet, setOutlet] = useState<any[]>([]);
  const [selectedOutlet, setSelectedOutlet] = useState<string | null>(null);
  const [product, setProduct] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [staff, setStaff] = useState<any[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<string | null>(null);
  const [productCategory, setProductCategory] = useState<any[]>([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState<
    string | null
  >(null);
  const [stockIn, setStockIn] = useState<any[]>([]);
  const [selectedStockIn, setSelectedStockIn] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getOutletData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/outlet/dropdown`);
      if (response?.data?.success && response.data.data) {
        setOutlet(response.data.data);
        setSelectedOutlet(outletId || null);
      }
    } catch (error) {
      console.error("Error fetching outlet:", error);
    }
  };

  const getStorageData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/storage/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setStorage(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching storage:", error);
    }
  };

  const getProductData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/product/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setProduct(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const getProductCategoryData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/category/dropdown/type/${outletId}/product`);
      if (response?.data?.success && response.data.data) {
        setProductCategory(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching product category:", error);
    }
  };

  const getStockInData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/stockin/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setStockIn(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching stockin dropdown:", error);
    }
  };

  const getStaffData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/staff/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setStaff(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching staff:", error);
    }
  };

  const getStockInInventoryData = async () => {
    try {
      const startDate = dateRange ? dateRange[0].format("YYYY-MM-DD") : "NULL";
      const endDate = dateRange ? dateRange[1].format("YYYY-MM-DD") : "NULL";
      const offset = currentPage;

      const response = await get(
        `/report/stockin/${startDate}/${endDate}/${selectedOutlet || "NULL"}/${
          selectedStockIn || "NULL"
        }/${selectedStorage || "NULL"}/${selectedStaff || "NULL"}/${
          selectedProduct || "NULL"
        }/${selectedProductCategory || "NULL"}/${pageSize}/${offset}`
      );

      if (response?.data?.success && response.data.data) {
        const formattedData = response.data.data.map(
          (item: any, index: number) => ({
            key: index.toString(),
            outlet_name: item.outlet_name || "",
            productcode: item.productcode || "",
            productname: item.productname || "",
            category_name: item.category_name || "",
            stockintype: item.stockintype || "",
            storage_type: item.storage_type || "",
            product_quantity: item.product_quantity || 0,
            unit: item.unit || "",
            sellprice: item.sellprice || 0,
            product_amount: item.product_amount || 0,
            note: item.note || "-",
          })
        );

        // Calculate totals for "Unit price" (sellprice) and "Amount" (product_amount)
        const totalSellPrice = formattedData.reduce(
          (sum: number, item: DataSourceItem) => sum + (item.sellprice || 0),
          0
        );
        const totalProductAmount = formattedData.reduce(
          (sum: number, item: DataSourceItem) =>
            sum + (item.product_amount || 0),
          0
        );

        // Add a summary row at the end
        const summaryRow: DataSourceItem = {
          key: "summary",
          outlet_name: "",
          productcode: "",
          productname: "",
          category_name: "",
          stockintype: "",
          storage_type: "",
          product_quantity: undefined,
          unit: "",
          sellprice: totalSellPrice,
          product_amount: totalProductAmount,
          note: "",
          isSummaryRow: true, // Flag to identify this as the summary row
        };

        setDataSource([...formattedData, summaryRow]);
        setTotalAmount(response.data.totalAmount || 0);
        setTotalRecords(response.data.totalRecords || 0);
      } else {
        setDataSource([]);
        setTotalAmount(0);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching stock-in inventory details:", error);
      setDataSource([]);
      setTotalAmount(0);
      setTotalRecords(0);
    }
  };

  useEffect(() => {
    getOutletData();
    getStorageData();
    getProductData();
    getStaffData();
    getProductCategoryData();
    getStockInData();
  }, []);

  useEffect(() => {
    if (selectedOutlet !== null) {
      getStockInInventoryData();
    }
  }, [selectedOutlet, currentPage, pageSize]);

  const handleRunFilter = () => {
    setCurrentPage(0); // Reset to first page when filters are applied
    getStockInInventoryData(); // Call API with selected filters
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1); // Adjust for 0-based indexing
  };

  const handlePageSizeChange = (value: number) => {
    setPageSize(value);
    setCurrentPage(0);
  };

  const handleFold = () => {
    setIsFolded(!isFolded);
  };

  // Define a render function for all columns to apply styles to the summary row
  const summaryRowStyle = {
    backgroundColor: "#d9e1f2",
    fontWeight: "normal",
  };

  const initialColumns = [
    {
      title: "Store",
      dataIndex: "outlet_name",
      key: "outlet_name",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? (
          <div style={summaryRowStyle}>{value}</div>
        ) : (
          value
        ),
    },
    {
      title: "Product code",
      dataIndex: "productcode",
      key: "productcode",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? (
          <div style={summaryRowStyle}>{value}</div>
        ) : (
          value
        ),
    },
    {
      title: "Product name",
      dataIndex: "productname",
      key: "productname",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? (
          <div style={summaryRowStyle}>{value}</div>
        ) : (
          value
        ),
    },
    {
      title: "Category",
      dataIndex: "category_name",
      key: "category_name",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? (
          <div style={summaryRowStyle}>{value}</div>
        ) : (
          value
        ),
    },
    {
      title: "Stock in method",
      dataIndex: "stockintype",
      key: "stockintype",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? (
          <div style={summaryRowStyle}>{value}</div>
        ) : (
          value
        ),
    },
    {
      title: "Storage",
      dataIndex: "storage_type",
      key: "storage_type",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? (
          <div style={summaryRowStyle}>{value}</div>
        ) : (
          value
        ),
    },
    {
      title: "Quantity",
      dataIndex: "product_quantity",
      key: "product_quantity",
      width: 150,
      render: (value: number, record: DataSourceItem) =>
        record.isSummaryRow ? (
          <div style={summaryRowStyle}>{value}</div>
        ) : (
          value
        ),
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? (
          <div style={summaryRowStyle}>{value}</div>
        ) : (
          value
        ),
    },
    {
      title: "Unit price",
      dataIndex: "sellprice",
      key: "sellprice",
      width: 150,
      render: (value: number, record: DataSourceItem) =>
        record.isSummaryRow ? (
          <div style={summaryRowStyle}>{currencySymbol.CURRENCY_SYMBOL}{value.toFixed(2)}</div>
        ) : (
          `{currencySymbol.CURRENCY_SYMBOL}${value.toFixed(2)}`
        ),
    },
    {
      title: "Amount",
      dataIndex: "product_amount",
      key: "product_amount",
      width: 150,
      render: (value: number, record: DataSourceItem) =>
        record.isSummaryRow ? (
          <div style={summaryRowStyle}>{currencySymbol.CURRENCY_SYMBOL}{value.toFixed(2)}</div>
        ) : (
          `{currencySymbol.CURRENCY_SYMBOL}${value.toFixed(2)}`
        ),
    },
    {
      title: "Note",
      dataIndex: "note",
      key: "note",
      width: 150,
      render: (value: string, record: DataSourceItem) =>
        record.isSummaryRow ? (
          <div style={summaryRowStyle}>{value}</div>
        ) : (
          value
        ),
    },
  ];

  // Define mandatory columns that cannot be deselected
  const mandatoryColumns = ["productname"];

  // State for columns order
  const [columns, setColumns] = useState(initialColumns);

  // Initialize selectedColumns with all column keys by default
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key)
  );

  // Track the last saved column selection
  const [lastSelectedColumns, setLastSelectedColumns] = useState<string[]>([]);

  // Filter columns based on selectedColumns and maintain order
  const displayedColumns = columns.filter((col) =>
    selectedColumns.includes(col.key)
  );

  const handleColumnsClick = () => {
    setIsModalVisible(true);
  };

  const handleModalSave = (
    selectedCols: string[],
    reorderedColumns: { key: string; title: string }[]
  ) => {
    setSelectedColumns(selectedCols);
    setLastSelectedColumns(selectedCols);
    setColumns(
      reorderedColumns.map((col) => {
        const existingCol = initialColumns.find((c) => c.key === col.key);
        return {
          ...col,
          dataIndex: col.key,
          width: 150,
          render: (value: any, record: DataSourceItem) =>
            record.isSummaryRow ? (
              <div style={summaryRowStyle}>
                {col.key === "sellprice" || col.key === "product_amount"
                  ? `{currencySymbol.CURRENCY_SYMBOL}${value.toFixed(2)}`
                  : value}
              </div>
            ) : col.key === "sellprice" || col.key === "product_amount" ? (
              `{currencySymbol.CURRENCY_SYMBOL}${value.toFixed(2)}`
            ) : (
              value
            ),
        };
      })
    );
  };

  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      background: "linear-gradient(90deg, #6a11cb, #2575fc)",
      color: "#fff",
      borderRadius: "10px",
      marginBottom: "16px",
      width: "595px",
      height: "60px",
      display: "flex",
      alignItems: "center",
      padding: "0 50px",
      position: "relative",
      overflow: "hidden",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      zIndex: 2,
      textAlign: "center",
    },
    label: {
      fontSize: "12px",
      fontWeight: "500",
      opacity: 0.9,
      color: "#caa2ff",
    },
    amount: {
      margin: 0,
      fontSize: "16px",
      color: "#fff",
      // animation: "fadeIn 1s ease-in-out infinite alternate",
    },
    transparentText: {
      position: "absolute",
      right: "20px",
      fontSize: "40px",
      fontWeight: "lighter",
      color: "rgba(255, 255, 255, 0.2)",
      textTransform: "uppercase",
      letterSpacing: "2px",
      zIndex: 1,
    },
  };

  return (
    <div style={{ padding: "20px" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowLeftOutlined
              style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
            />
            <Title level={4} style={{ margin: 0 }}>
              Stock-in Inventory Report
            </Title>
          </div>
        </Col>
        <Col>
          <Space>
            <Button
              icon={<UnorderedListOutlined />}
              onClick={handleColumnsClick}
              style={{ height: 40 }}
            >
              Columns
            </Button>
          </Space>
        </Col>
      </Row>

      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#f6f7f9",
          borderRadius: "12px",
          padding: "24px 24px 10px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
        }}
      >
        <div style={{ marginBottom: "16px", borderRadius: "8px" }}>
          <Row gutter={16} justify="start" style={{ marginBottom: "16px" }}>
            <Col>
              <Select
                value={selectedOutlet === null ? "all" : selectedOutlet}
                onChange={(value) =>
                  setSelectedOutlet(value === "all" ? null : value)
                }
                placeholder="Select outlet"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                <Option value="all">All outlets</Option>
                {outlet.map((outlet) => (
                  <Option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <RangePicker
                value={dateRange}
                onChange={(dates) =>
                  dates && setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])
                }
                format="DD/MM/YYYY"
                style={{ width: "416px", height: "46px", borderRadius: "4px" }}
                allowClear={false}
                className="custom-range-picker"
              />
            </Col>
            <Col>
              <Select
                value={
                  selectedProductCategory === null
                    ? "all"
                    : selectedProductCategory
                }
                onChange={(value) =>
                  setSelectedProductCategory(value === "all" ? null : value)
                }
                placeholder="Select product category"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                <Option value="all">All product category</Option>
                {productCategory.map((productCategory) => (
                  <Option key={productCategory.id} value={productCategory.id}>
                    {productCategory.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                value={selectedProduct === null ? "all" : selectedProduct}
                onChange={(value) =>
                  setSelectedProduct(value === "all" ? null : value)
                }
                placeholder="Select product"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                <Option value="all">All product</Option>
                {product.map((product) => (
                  <Option key={product.id} value={product.id}>
                    {product.productname}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row gutter={16} justify="start" align="middle">
            <Col>
              <Select
                value={selectedStockIn === null ? "all" : selectedStockIn}
                onChange={(value) =>
                  setSelectedStockIn(value === "all" ? null : value)
                }
                placeholder="Select stock in method"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                <Option value="all">All stock in method</Option>
                {stockIn.map((stockIn) => (
                  <Option key={stockIn.id} value={stockIn.id}>
                    {stockIn.stockintype}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                value={selectedStorage === null ? "all" : selectedStorage}
                onChange={(value) =>
                  setSelectedStorage(value === "all" ? null : value)
                }
                placeholder="Select storage"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                <Option value="all">All storage</Option>
                {storage.map((storage) => (
                  <Option key={storage.id} value={storage.id}>
                    {storage.type}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                value={selectedStaff === null ? "all" : selectedStaff}
                onChange={(value) =>
                  setSelectedStaff(value === "all" ? null : value)
                }
                placeholder="Select staff"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                <Option value="all">All staff</Option>
                {staff.map((staff) => (
                  <Option key={staff.id} value={staff.id}>
                    {staff.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <Space>
                <Button
                  style={{ height: "46px", width: "80px" }}
                  type="primary"
                  onClick={handleRunFilter}
                >
                  Run
                </Button>
                <Button
                  style={{
                    border: "none",
                    background: "#f6f7f9",
                    color: "#717ef1",
                    fontWeight: 600,
                    height: "46px",
                    width: "80px",
                  }}
                  onClick={handleFold}
                >
                  Fold{" "}
                  {isFolded ? (
                    <span
                      style={{
                        display: "inline-flex",
                        flexDirection: "column",
                      }}
                    >
                      <DownOutlined style={{ marginBottom: "-7px" }} />
                      <DownOutlined />
                    </span>
                  ) : (
                    <span
                      style={{
                        display: "inline-flex",
                        flexDirection: "column",
                      }}
                    >
                      <UpOutlined style={{ marginBottom: "-7px" }} />
                      <UpOutlined />
                    </span>
                  )}
                </Button>
              </Space>
            </Col>
          </Row>
        </div>

        <div style={styles.container}>
          <div style={styles.textContainer}>
            <Text style={styles.label}>Total amount</Text>
            <Text style={styles.amount}>
              {currencySymbol.CURRENCY_SYMBOL}{Number(totalAmount).toFixed(2)}
            </Text>
          </div>
          <Text style={styles.transparentText}>TOTAL</Text>
        </div>

        <Datatable
          columns={displayedColumns}
          dataSource={isFolded ? [] : dataSource}
          scroll={{ y: isFolded ? "0px" : "calc(100vh - 260px)" }}
          showCheckbox={false}
          rowSelection={undefined}
          showPagination={false}
          dynamicTableHeight={isFolded ? "20px" : "calc(100vh - 240px)"}
        />

        <Row
          justify="space-between"
          style={{
            alignItems: "center",
            marginTop: "40px",
            marginBottom: "16px",
          }}
        >
          <Col>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              style={{ width: 200, height: "46px", borderRadius: "4px" }}
            >
              <Option value={10}>10 entries/page</Option>
              <Option value={20}>20 entries/page</Option>
              <Option value={50}>50 entries/page</Option>
              <Option value={100}>100 entries/page</Option>
            </Select>
          </Col>

          {totalRecords >= 0 && (
            <Col>
              <Row justify="end" align="middle" style={{ gap: "16px" }}>
                <Col>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "rgba(0,0,0,.85)",
                    }}
                  >
                    Total {totalRecords} items
                  </span>
                </Col>
                <Col>
                  <Pagination
                    current={currentPage + 1} // Adjust for 1-based indexing in UI
                    pageSize={pageSize}
                    total={totalRecords}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    itemRender={(page, type, originalElement) => {
                      if (type === "prev") {
                        const isDisabled = currentPage === 0;
                        return (
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                              color: isDisabled ? "#bbb" : "#2e56f2",
                              pointerEvents: isDisabled ? "none" : "auto",
                            }}
                          >
                            <LeftOutlined />
                            <span>Previous</span>
                          </a>
                        );
                      }
                      if (type === "next") {
                        const isDisabled =
                          currentPage ===
                          Math.ceil(totalRecords / pageSize) - 1;
                        return (
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                              color: isDisabled ? "#bbb" : "#2e56f2",
                              pointerEvents: isDisabled ? "none" : "auto",
                            }}
                          >
                            <span>Next</span>
                            <RightOutlined />
                          </a>
                        );
                      }
                      return originalElement;
                    }}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Content>

      <ColumnsReportsModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onSave={handleModalSave}
        availableColumns={columns}
        initialSelectedColumns={selectedColumns}
        lastSelectedColumns={lastSelectedColumns}
        mandatoryColumns={mandatoryColumns}
      />

      <style>{`
        tr[data-row-key="summary"] td {
          padding-top: 10px !important;
          padding-bottom: 10px !important;
          height: 30px !important;
          background-color: #d9e1f2 !important; 
        }
        .custom-range-picker .ant-picker-input > input {
          text-align: center;
        }
      `}</style>
    </div>
  );
};

export default StockInInventoryReport;
