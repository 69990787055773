import {
  ArrowLeftOutlined,
  CameraOutlined,
  CloseCircleOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import OpeningHoursSettings from "../../components/model/merchant/OpeningHoursSettings";
import { encryptPassword } from "../../constants/encryptionUtils";
import { get, post, put, uploadImg } from "../../services/Apiclient";
import CryptoJS from "crypto-js";
import { Helmet } from "react-helmet";

const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const defaultStoreImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Store.png`;

const { Option } = Select;
const { Text, Link } = Typography;

interface OpeningHours {
  [key: string]: string;
}

interface StoreLevelOption {
  label: string;
  value: string; // or number, based on your API response
}

interface FormValues {
  storename: string;
  storecode: string;
  email: string;
  secondLanguage: string;
  countrycode: string;
  phone_number: string;
  username: string;
  address: string;
  country: string;
  state: string;
  password: string;
  firstlanguage: string;
  city: string;
  time_zone: string;
  language: string;
  currency: string;
  outlet_introduction: string;
  time_format: string;
  google: string;
  xiaohongshu: string;
  instagram: string;
  tiktok: string;
  postal_code: string;
  website: string;
  facebook: string;
  youtube: string;
  latitude: number | undefined;
  longitude: number | undefined;
  storestatus: boolean;
  storelevel: string;
  storelogo: string;
  enableonlineshop: boolean;
  enableretailshop: boolean;
  tax_id?: string;
  parentstore: string | null;
  business_type: string[];
  opening_hours: OpeningHours;
  gstno: string;
  salestaxno: string;
  servicetaxno: string;
  brnno: string;
  tin: string;
  tourismno: string;
  exemptionno: string;
}

//images path
const facebookIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Facebook.png`;
const googleIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_google.png`;
const instagramIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Instagram.png`;
const tiktokIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_TikTok.png`;
const websiteIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Website.png`;
const xiaohongshuIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Xiaohongshu.png`;
const youtubeIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Youtobe.png`;

const validationSchema = Yup.object().shape({
  storename: Yup.string().required("Outlet name is required"),
  //username: Yup.string().required("Store username is required"),
  password: Yup.string().required("Password is required"),
  storecode: Yup.string().required("Store Code is required"),
  state: Yup.string().required("State / Province is required"),
  city: Yup.string().required("City / District is required"),
  country: Yup.string().required("Country / Region is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  postal_code: Yup.string().required("Postal Code is required"),
  tax_id: Yup.string().required("Tax Id is required"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .min(10, "Phone number must be at least 10 characters")
    .max(13, "Phone number must be at Max 13 characters"),
  address: Yup.string().required("Address is required"),
  latitude: Yup.number()
    .typeError("Latitude must be a number")
    .required("Latitude is required"),
  longitude: Yup.number()
    .typeError("Longitude must be a number")
    .required("Longitude is required"),
  time_zone: Yup.string().required("Time zone is required"),
  language: Yup.string().required("Default language is required"),
  firstlanguage: Yup.string().required(
    "Customer-facing language (First language) is required"
  ),
});

const sectionTitleStyle = {
  fontWeight: "bold",
  fontSize: "16px",
  marginBottom: "16px",
  marginTop: "16px",
};

const buttonStyle = {
  backgroundColor: "#1890ff",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  padding: "6px 12px",
  margin: "5px",
};

const dayStyle = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  marginBottom: "16px",
};

const OutletForm: React.FC = () => {
  const initialValues: FormValues = {
    storename: "",
    email: "",
    secondLanguage: "",
    storecode: "",
    countrycode: "60",
    phone_number: "",
    address: "",
    country: "",
    postal_code: "",
    username: "",
    time_zone: "",
    password: "",
    parentstore: null,
    outlet_introduction: "",
    city: "",
    google: "",
    xiaohongshu: "",
    tiktok: "",
    instagram: "",
    time_format: "24-hour time",
    currency: "",
    website: "",
    state: "",
    facebook: "",
    firstlanguage: "",
    youtube: "",
    language: "",
    latitude: undefined,
    longitude: undefined,
    storestatus: true,
    storelevel: "",
    tax_id: "",
    storelogo: "",
    enableonlineshop: false,
    enableretailshop: true,
    business_type: [],
    opening_hours: {
      sunday: "09:00-18:00",
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "09:00-18:00",
    },
    gstno: "",
    salestaxno: "",
    servicetaxno: "",
    brnno: "",
    tin: "",
    tourismno: "",
    exemptionno: "",
  };

  const { id } = useParams<{ id: string }>();
  const [isEditMode, setIsEditMode] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDay, setCurrentDay] = useState<string>("");
  const [secondLanguageEnabled, setSecondLanguageEnabled] = useState(false);
  const [stateOptions, setStateOptions] = useState([]); // Store the state options
  const [selectedCountry, setSelectedCountry] = useState("");
  const [openingHours, setOpeningHours] = useState<OpeningHours>(
    initialValues.opening_hours
  );
  const [businessOptions, setBusinessOptions] = useState([]);
  const [parentStoreOptions, setParentStoreOptions] = useState([]);
  const [storeLevelOptions, setStoreLevelOptions] = useState<
    StoreLevelOption[]
  >([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]); // Store the language options
  const [formValues, setFormValues] = useState(initialValues);
  const [imageUrls, setImageUrls] = useState<string[]>([]); // Store URLs of the uploaded images
  const [imageArray, setImageArray] = useState<string[]>([]);
  const [submitted, setSubmitted] = useState(false);

  const [storeLogoUrl, setStoreLogoUrl] = useState<string>("");

  const navigate = useNavigate();

  const handleOpeningHoursSave = (updatedHours: any) => {
    setOpeningHours(updatedHours); // Update the opening hours state
  };

  const fetchOutletDetails = async (id: string) => {
    try {
      const response = await get(`/outlet/${id}`);
      if (response?.data?.success) {
        const outletData = response.data.data;

        // Convert outletDaysInfo to a format compatible with the opening_hours field
        const mappedOpeningHours = outletData.outletDaysInfo.reduce(
          (acc: any, curr: any) => {
            acc[
              curr.dayname.toLowerCase()
            ] = `${curr.starttime}-${curr.endtime}`;
            return acc;
          },
          {}
        );

        // Prefill images from the fetched outlet details
        const prefilledImages = outletData.outletshowcase.map(
          (img: any) => img.imageUrl
        );
        const prefilledImageNames = outletData.outletshowcase.map(
          (img: any) => img.name
        );

        setImageUrls(prefilledImages);
        setImageArray(prefilledImageNames);

        let password = decodeURIComponent(outletData.password);
        const secretKey = "G7HqA5VCbMMi3KQN2anRckza";
        const bytes = CryptoJS.AES.decrypt(password, secretKey);
        password = bytes.toString(CryptoJS.enc.Utf8);

        // Populate form with outlet data
        // Update form values with fetched data
        setFormValues({
          storename: outletData.storename || "",
          storecode: outletData.storecode || "",
          email: outletData.email || "",
          countrycode: outletData.countrycode || "60",
          phone_number:
            `${outletData.countrycode}${outletData.phone_number}` || "",
          address: outletData.address || "",
          country: outletData.country || "",
          state: outletData.state || "",
          city: outletData.city || "",
          postal_code: outletData.postalcode || "",
          latitude: outletData.latitude
            ? parseFloat(outletData.latitude)
            : undefined,
          longitude: outletData.longitude
            ? parseFloat(outletData.longitude)
            : undefined,
          storestatus: outletData.storestatus === 1,
          storelevel: outletData.storelevel,
          tax_id: outletData.taxid || "",
          parentstore: outletData.parentstore || null,
          outlet_introduction: outletData.storeintroduction || "",
          time_zone: outletData.timezone || "",
          time_format: outletData.timeformat || "12-hour time",
          currency: outletData.currency || "",
          firstlanguage: outletData.customer_language || "",
          language: outletData.default_language || "",
          website: outletData.website || "",
          facebook: outletData.facebook || "",
          youtube: outletData.youtube || "",
          instagram: outletData.instagram || "",
          tiktok: outletData.tiktok || "",
          xiaohongshu: outletData.xiaohongshu || "",
          google: outletData.google || "",
          business_type: outletData.businesstype.map((type: string) => type),
          opening_hours: mappedOpeningHours,
          username: outletData.username || outletData.email,
          password: password, // Keep password blank for security reasons
          secondLanguage: outletData.customer_facing_language_second,
          storelogo: outletData.storelogo,
          enableonlineshop: outletData.enableonlineshop,
          enableretailshop: outletData.enableretailshop,
          gstno: outletData.gstno,
          salestaxno: outletData.salestaxno,
          servicetaxno: outletData.servicetaxno,
          brnno: outletData.brnno,
          tin: outletData.tin,
          tourismno: outletData.tourismno,
          exemptionno: outletData.exemptionno,
        });

        setOpeningHours(mappedOpeningHours);
        setSecondLanguageEnabled(outletData.enable_language);
        setIsEditMode(true); // We're now in edit mode
        setStoreLogoUrl(outletData.storelogourl);
      }
    } catch (error) {
      console.error("Error fetching outlet details:", error);
    }
  };

  const fetchParentStores = async () => {
    try {
      const response = await get("/outlet/dropdown");
      if (response.data.success) {
        const options = response.data.data.map((store: any) => ({
          label: store.name,
          value: store.id,
        }));
        setParentStoreOptions(options); // Update state with fetched options
      } else {
        console.error(
          "Failed to fetch parent store options:",
          response.message
        );
      }
    } catch (error) {
      console.error("Error fetching parent store options:", error);
    }
  };

  const fetchStoreLevels = async () => {
    try {
      const response = await get(
        `/storelevel/dropdown/${localStorage.getItem("outlet_id")}`
      );
      if (response.data.success) {
        const options = response.data.data.map((store: any) => ({
          label: store.storeleveltype,
          value: store.id,
        }));
        setStoreLevelOptions(options);
      } else {
        console.error(
          "Failed to fetch parent store options:",
          response.message
        );
      }
    } catch (error) {
      console.error("Error fetching parent store options:", error);
    }
  };

  useEffect(() => {
    if (storeLevelOptions.length > 0) {
      if (id == undefined || id == null) {
        setFormValues((prevValues) => ({
          ...prevValues,
          storelevel: storeLevelOptions[0]?.value, // Set to the first option's value
        }));
      }
    }
  }, [storeLevelOptions]);

  // Fetch Country List
  const fetchCountryList = async () => {
    try {
      const response = await get("/merchant/countrylist");
      if (response.data.success) {
        const options = response.data.data.map((country: any) => ({
          label: country.name,
          value: country.name,
          code: country.code,
        }));
        setCountryOptions(options);
      } else {
        console.error("Failed to fetch country options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching country options:", error);
    }
  };

  // Fetch Time Zone List
  const fetchTimeZoneList = async () => {
    try {
      const response = await get("/merchant/timezonelist");

      if (response.data.success) {
        const options = response.data.data.map((timeZone: any) => ({
          label: timeZone.name,
          value: timeZone.name,
        }));
        setTimeZoneOptions(options); // Update state with fetched time zone options
      } else {
        console.error("Failed to fetch time zone options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching time zone options:", error);
    }
  };

  // Fetch Currency List
  const fetchCurrencyList = async () => {
    try {
      const response = await get("/merchant/currencylist");
      if (response.data.success) {
        const options = response.data.data.map((currency: any) => ({
          label: currency.name,
          value: currency.name,
        }));
        setCurrencyOptions(options); // Update state with fetched currency options
      } else {
        console.error("Failed to fetch currency options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching currency options:", error);
    }
  };

  // Fetch Language List
  const fetchLanguageList = async () => {
    try {
      const response = await get("/merchant/languagelist");

      if (response.data.success) {
        const options = response.data.data.map((language: any) => ({
          label: language.name,
          value: language.name,
        }));
        setLanguageOptions(options); // Update state with fetched language options
      } else {
        console.error("Failed to fetch language options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching language options:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchOutletDetails(id);
    }
  }, [id]);

  useEffect(() => {
    fetchParentStores(); // Fetch the parent store options on component mount
    fetchCountryList();
    fetchTimeZoneList();
    fetchCurrencyList();
    fetchLanguageList();
    fetchStoreLevels();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `/business/list`;
        const response = await get(url);
        if (response.data.success) {
          const options = response.data.data.map((business: any) => ({
            label: business.name,
            value: business.id.toString(),
          }));
          setBusinessOptions(options);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const fetchStateList = async (selectedCountry: string) => {
    if (!selectedCountry) return;
    try {
      const selectCode: any = countryOptions
        .filter((x: any) => x.label == selectedCountry)
        .map((x: any) => {
          return x;
        })[0];
      const response = await get(`/merchant/statelist/${selectCode.code}`);
      if (response.data.success) {
        const options = response.data.data.map((state: any) => ({
          label: state.name,
          value: state.name,
        }));
        setStateOptions(options);
      } else {
        setStateOptions([]);
        console.error("Failed to fetch state options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching state options:", error);
    }
  };

  useEffect(() => {
    if (selectedCountry) {
      fetchStateList(selectedCountry);
    } else {
      setStateOptions([]); // Clear state options if no country selected
    }
  }, [selectedCountry]);

  const showEditModal = () => {
    // setCurrentDay(day);
    setIsModalVisible(true);
  };

  const handleTimeChange = (times: any) => {
    if (times && times[0] && times[1]) {
      const updatedHours = {
        ...openingHours,
        [currentDay]: `${times[0].format("HH:mm")}-${times[1].format("HH:mm")}`,
      };
      setOpeningHours(updatedHours);
    }
  };

  const handleTimeChangeClosed = () => {
    const updatedHours = {
      ...openingHours,
      [currentDay]: `closed-null`,
    };
    setOpeningHours(updatedHours);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async (values: any) => {
    console.log("submit click");
    // setSubmitted(true);
    let businessTypeArray = [];
    if (values.business_type && values.business_type.length > 0) {
      businessTypeArray = values.business_type.map((id: string | number) =>
        typeof id === "string" ? parseInt(id.trim(), 10) : id
      );
    }

    const convertSchedule = (scheduleObj: { [key: string]: unknown }) => {
      return Object.entries(scheduleObj).map(([day, time]) => {
        if (typeof time === "string") {
          const [starttime, endtime] = time.split("-");
          return {
            day: day.charAt(0).toUpperCase() + day.slice(1),
            starttime,
            endtime,
          };
        } else if (
          time === null ||
          time === undefined ||
          time === "closed-null"
        ) {
          // Handle cases where the day is marked as closed or not set
          return {
            day: day.charAt(0).toUpperCase() + day.slice(1),
            starttime: "closed",
            endtime: null,
          };
        } else {
          throw new Error(
            `Expected a string for time, but received ${typeof time}`
          );
        }
      });
    };

    let formattedPhoneNumber = values.phone_number;

    // Remove country code from phone_number
    if (values.countrycode) {
      formattedPhoneNumber = values.phone_number.replace(
        new RegExp(`^\\+?${values.countrycode}`),
        ""
      );
    }

    const filteredImageArray = imageArray.filter(
      (name) => name && name.trim() !== ""
    );

    const formData = {
      storename: values.storename,
      storecode: values.storecode,
      parentstore: values.parentstore || null,
      storelevel: values.storelevel,
      email: values.email,
      password: encryptPassword(values.password), // Assuming encryptPassword is working as expected
      username: values.email,
      countrycode: values.countrycode,
      phone_number: formattedPhoneNumber,
      taxid: values.tax_id || "",
      address: values.address,
      country: values.country,
      state: values.state,
      city: values.city,
      postalcode: values.postal_code,
      latitude: values.latitude,
      longitude: values.longitude,
      storestatus: values.storestatus,
      businesstype: businessTypeArray,
      outletshowcase: filteredImageArray,
      outletDaysInfo: convertSchedule(openingHours),
      website: values.website,
      facebook: values.facebook,
      youtube: values.youtube,
      instagram: values.instagram,
      tiktok: values.tiktok,
      xiaohongshu: values.xiaohongshu,
      google: values.google,
      storeintroduction: values.outlet_introduction,
      storelogo: values.storelogo || "default.png",
      customer_language: values.firstlanguage,
      default_language: values.language,
      currency: values.currency,
      timeformat: values.time_format,
      timezone: values.time_zone,
      enable_language: secondLanguageEnabled, // Set based on switch state
      customer_facing_language_second: values.secondLanguage,
      enableonlineshop: values.enableonlineshop,
      enableretailshop: values.enableretailshop,
      gstno: values.gstno,
      salestaxno: values.salestaxno,
      servicetaxno: values.servicetaxno,
      brnno: values.brnno,
      tin: values.tin,
      tourismno: values.tourismno,
      exemptionno: values.exemptionno,
    };

    try {
      let response;
      if (isEditMode) {
        // Use the put method for updating
        let payload: any = formData;
        response = await put(`/outlet/${id}`, payload);
        if (id == localStorage.getItem("outlet_id")) {
          let store_name = formData.storecode + " - " + formData.storename;
          let enableonlineshop = formData.enableonlineshop;
          let enableretailshop = formData.enableretailshop;
          localStorage.setItem("store_name", store_name);
          localStorage.setItem(
            "enableonlineshop",
            JSON.stringify(enableonlineshop)
          );
          localStorage.setItem(
            "enableretailshop",
            JSON.stringify(enableretailshop)
          );
        }
      } else {
        response = await post("/outlet/addoutlet", formData);
      }
      if (response.status === 201 || response.status === 200) {
        let sourceArray = convertSchedule(openingHours);

        const formatTime = (time: any) => {
          if (time === "closed" || time === "null") return time;
          return time.length === 8 ? time : `${time}:00`;
        };

        // Helper function to get day index
        const getDayOfWeek = (dayName: any) => {
          const days = [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ];
          return days.indexOf(dayName);
        };

        // Simple random ID generator
        const generateId = () => {
          return Math.random().toString(36).substring(2, 10);
        };

        // Adjust time by adding/subtracting hours
        const adjustTime = (timeStr: any, hourDiff: any) => {
          if (!timeStr || timeStr === "closed" || timeStr === "null")
            return timeStr;

          const [hours, minutes, seconds] = timeStr.split(":").map(Number);

          const date = new Date();
          date.setHours(hours);
          date.setMinutes(minutes);
          date.setSeconds(seconds || 0);

          date.setHours(date.getHours() + hourDiff);

          const pad = (n: any) => n.toString().padStart(2, "0");

          return `${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
            date.getSeconds()
          )}`;
        };

        // Create the new array, overwriting starttime and endtime
        const targetArray = sourceArray.map((item: any) => {
          const formattedStart = formatTime(item.starttime);
          const formattedEnd = formatTime(item.endtime);

          const newStarttime = adjustTime(formattedStart, -1);
          const newEndtime = adjustTime(formattedEnd, 1);

          return {
            id: generateId(),
            dayofweek: getDayOfWeek(item.day),
            dayname: item.day,
            starttime: newStarttime,
            endtime: newEndtime,
          };
        });

        await localStorage.setItem("workinghours", JSON.stringify(targetArray));

        setSubmitted(false);
        navigate("/settings/business-details");
      } else {
        setSubmitted(false);
        message.error(response.data.message);
        console.error("Form submission failed:", response.data);
      }
    } catch (error) {
      setSubmitted(false);
      console.error("An error occurred during form submission:", error);
    }
  };

  // Handle images selected for upload
  const handleImagesChange = (formData: any) => {
    setImageUrls(formData); // Store the image URLs
  };

  // Handle image deletion
  const handleDeleteImage = (images: any) => {
    const filenames = images.map((url: any) => url.split("/").pop());
    setImageArray(filenames); // Update image array after deletion
  };

  const handleStoreLogoUpload = async (fileList: File[]) => {
    console.log(fileList, "filelist");
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file); // Ensure this matches the API's expected field name
    });

    try {
      const response = await uploadImg(
        "/outlet/uploadimage/outletlogo",
        formData
      );

      if (response?.data?.success) {
        const uploadedFileName = response.data.data[0]?.imagename;

        setFormValues((prev) => ({
          ...prev,
          storelogo: uploadedFileName,
        }));

        setStoreLogoUrl(response.data.data[0]?.baseuri);
      } else {
        message.error(response.data.message || "Failed to upload store logo.");
      }
    } catch (error) {
      message.error("An error occurred while uploading the store logo.");
      console.error("Upload error:", error);
    }
  };

  const handleRemoveStoreLogoImage = () => {
    setStoreLogoUrl("");
    setFormValues((prev) => ({
      ...prev,
      storelogo: "",
    }));
  };

  const uploadStoreLogoProps = {
    showUploadList: false,
    beforeUpload: (file: any, fileList: any[]) => {
      handleStoreLogoUpload(fileList); // Call handleUpload for custom behavior
      return false; // Prevent default upload
    },
  };

  const handleRemoveShowcaseImage = (index: number) => {
    setImageUrls((prev) => {
      const updatedUrls = [...prev];
      updatedUrls[index] = ""; // Clear the image at the specified index
      return updatedUrls;
    });

    setImageArray((prev) => {
      const updatedArray = [...prev];
      updatedArray[index] = ""; // Clear the corresponding image name
      return updatedArray;
    });
  };

  const handleShowCaseUpload = async (file: File, index: number) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await uploadImg(
        "/outlet/uploadimage/outletshowcase",
        formData
      );

      if (response?.data?.success) {
        const uploadedImage = response.data.data[0];
        const uploadedUrl = uploadedImage.baseuri;
        const uploadedName = uploadedImage.imagename;

        setImageUrls((prev) => {
          const updatedUrls = [...prev];
          updatedUrls[index] = uploadedUrl; // Add the uploaded image at the correct index
          return updatedUrls;
        });

        setImageArray((prev) => {
          const updatedArray = [...prev];
          updatedArray[index] = uploadedName; // Maintain the image name array for API submission
          return updatedArray;
        });

        message.success("Image uploaded successfully!");
      } else {
        message.error(response.data.message || "Failed to upload image.");
      }
    } catch (error) {
      message.error("An error occurred during image upload.");
      console.error("Upload error:", error);
    }

    return false; // Prevent default upload behavior
  };

  const uploadStoreShowcaseProps = {
    showUploadList: false,
    beforeUpload: (file: any) => {
      return false; // Prevent default upload behavior
    },
  };

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Content className="mainContainer">
        <Row justify="space-between" align="middle">
          <Col>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                onClick={() => navigate("/settings/business-details")}
                style={{ cursor: "pointer" }}
              >
                <ArrowLeftOutlined
                  style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
                />
              </div>
              <h2 style={{ margin: 0 }}>
                {" "}
                {isEditMode ? "Edit Outlet" : "Add new Outlet"}
              </h2>
            </div>
          </Col>
        </Row>
        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#fafafa",
            borderRadius: "12px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
            height: "calc(100vh - 80px)",
            maxWidth: "1250px",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            className="form-scrollable-content"
            style={{ maxHeight: "calc(100vh - 110px)" }}
          >
            <Formik
              initialValues={formValues}
              enableReinitialize={true}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ setFieldValue, values }) => (
                <Form style={{ paddingTop: "16px" }}>
                  {/* Basic Info Section */}
                  <div style={sectionTitleStyle}>Basic Info</div>
                  <Row gutter={[16, 16]}>
                    <Col xs={24} md={12} lg={8}>
                      <label>Store name *</label>
                      <Field name="storename">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                storename: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="storename">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col xs={24} md={12} lg={8} style={{ marginTop: "2.5%" }}>
                      <label style={{ marginRight: "2.5%" }}>
                        Store Status
                      </label>
                      <Switch
                        checked={values.storestatus}
                        onChange={(checked) => {
                          setFieldValue("storestatus", checked);
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            storestatus: checked, // Set to the first option's value
                          }));
                        }}
                      />
                    </Col>
                    <Col xs={0} md={0} lg={8}></Col>
                    <Col xs={24} md={12} lg={8}>
                      <label>Store Code *</label>
                      <Field name="storecode">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            disabled={isEditMode}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                storecode: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="storecode">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col
                      span={8}
                      xs={24}
                      md={12}
                      lg={8}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label>Parent store</label>
                      <Select
                        value={values.parentstore}
                        onChange={(value) => {
                          setFieldValue("parentstore", value);
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            parentstore: value, // Set to the field value
                          }));
                        }}
                        disabled={
                          localStorage.getItem("userRole") !== "Merchant"
                        }
                        placeholder="Select Parent store"
                      >
                        {parentStoreOptions.map((option: any) => (
                          <Option
                            key={`parentstore-${option.value}`}
                            value={option.value}
                          >
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col
                      span={8}
                      xs={24}
                      md={12}
                      lg={8}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label>Store level</label>
                      <Select
                        value={values.storelevel}
                        onChange={(value) => {
                          setFieldValue("storelevel", value);
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            storelevel: value, // Set to the field value
                          }));
                        }}
                        placeholder="Select Store level"
                      >
                        {storeLevelOptions.map((option: any) => (
                          <Option
                            key={`parentstore-${option.value}`}
                            value={option.value}
                          >
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col xs={24} md={12} lg={8}>
                      <label>Email *</label>
                      <Field name="email">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                email: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="email">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>

                    <Col xs={24} md={12} lg={8}>
                      <label>Phone Number *</label>
                      <Field name="phone_number">
                        {({ field, form }: any) => (
                          <PhoneInput
                            country={"my"} // Set default country code, change this as needed
                            value={field.value}
                            enableSearch
                            countryCodeEditable={false}
                            onChange={(value: any, country: any) => {
                              form.setFieldValue(
                                "countrycode",
                                country.dialCode
                              );
                              form.setFieldValue("phone_number", value);
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                countrycode: country.dialCode, // Set to the field value
                                phone_number: value, // Set to the field value
                              }));
                            }}
                            inputStyle={{
                              width: "100%",
                              height: "46px",
                              paddingLeft: "35px", // Adjust space for the flag
                              borderColor: "#e0e0e0", // Light border color for a softer look
                              borderRadius: "5px",
                              color: "#5c5c5c", // Softer text color
                              backgroundColor: "#f8f9fd", // Light background color
                            }}
                            containerStyle={{
                              width: "100%",
                            }}
                            placeholder="Please enter" // Optional: style customization
                          />
                        )}
                      </Field>
                      <ErrorMessage name="phone_number">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>

                    <Col span={8} xs={24} md={12} lg={8}>
                      <label>Tax ID *</label>
                      <Field name="tax_id">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                tax_id: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="tax_id">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col span={8} xs={24} md={12} lg={8}>
                      <label>Address *</label>
                      <Field name="address">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                address: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="address">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    {/* {!isEditMode && ( */}
                    <Col xs={24} md={12} lg={8}>
                      <label>Password *</label>
                      <Field name="password">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                password: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="password">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col xs={0} md={0} lg={8}></Col>
                    <Col
                      span={8}
                      xs={24}
                      md={12}
                      lg={8}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label>Country / Region *</label>
                      <Select
                        value={values.country}
                        onChange={(value) => {
                          setFieldValue("country", value);
                          setSelectedCountry(value);
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            country: value, // Set to the field value
                          }));
                        }}
                        placeholder="Select Country"
                      >
                        {countryOptions.map((option: any) => (
                          <Option
                            key={`country-${option.value}`}
                            value={option.value}
                          >
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                      <ErrorMessage name="country">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col
                      span={8}
                      xs={24}
                      md={12}
                      lg={8}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label>State / Province *</label>
                      {stateOptions && stateOptions.length > 0 ? (
                        <Select
                          value={values.state}
                          onChange={(value) => {
                            setFieldValue("state", value);
                            setFormValues((prevValues) => ({
                              ...prevValues,
                              state: value, // Set to the field value
                            }));
                          }}
                          placeholder="Select State"
                        >
                          {stateOptions.map((option: any) => (
                            <Option
                              key={`state-${option.value}`}
                              value={option.value}
                            >
                              {option.label}
                            </Option>
                          ))}
                        </Select>
                      ) : (
                        <Field name="state">
                          {({ field }: any) => (
                            <Input
                              {...field}
                              placeholder="Please enter"
                              onChange={(e) => {
                                setFormValues((prevValues) => ({
                                  ...prevValues,
                                  state: e.target.value, // Set to the field value
                                }));
                              }}
                            />
                          )}
                        </Field>
                      )}
                      <ErrorMessage name="state">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>

                    <Col span={8} xs={24} md={12} lg={8}>
                      <label>City / District *</label>
                      <Field name="city">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                city: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="city">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col span={8} xs={24} md={12} lg={8}>
                      <label>Postal code *</label>
                      <Field name="postal_code">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                postal_code: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="postal_code">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </Col>

                    <Col span={8} xs={24} md={12} lg={8}>
                      <label>Location *</label>
                      <Input.Group
                        compact
                        style={{ display: "flex", gap: "10px" }}
                      >
                        {/* Latitude Field */}
                        <Field name="latitude">
                          {({ field, form }: FieldProps) => (
                            <div style={{ position: "relative", width: "50%" }}>
                              <InputNumber
                                type="number"
                                {...field}
                                placeholder="Latitude"
                                style={{
                                  width: "100%",
                                  height: "40px",
                                  textAlign: "center",
                                }}
                                controls={false}
                                onChange={(value) => {
                                  form.setFieldValue("latitude", value);
                                  setFormValues((prevValues) => ({
                                    ...prevValues,
                                    latitude: value,
                                  }));
                                }}
                              />
                              {field.value && (
                                <CloseCircleOutlined
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    form.setFieldValue("latitude", "");
                                    setFormValues((prevValues: any) => ({
                                      ...prevValues,
                                      latitude: "",
                                    }));
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </Field>

                        {/* Longitude Field */}
                        <Field name="longitude">
                          {({ field, form }: FieldProps) => (
                            <div style={{ position: "relative", width: "50%" }}>
                              <InputNumber
                                type="number"
                                {...field}
                                placeholder="Longitude"
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  borderLeft: "1px solid #d9d9d9",
                                }}
                                controls={false}
                                onChange={(value) => {
                                  form.setFieldValue("longitude", value);
                                  setFormValues((prevValues) => ({
                                    ...prevValues,
                                    longitude: value,
                                  }));
                                }}
                              />
                              {field.value && (
                                <CloseCircleOutlined
                                  style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    form.setFieldValue("longitude", "");
                                    setFormValues((prevValues: any) => ({
                                      ...prevValues,
                                      longitude: "",
                                    }));
                                  }}
                                />
                              )}
                            </div>
                          )}
                        </Field>
                      </Input.Group>
                      <ErrorMessage name="latitude">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                      <ErrorMessage name="longitude">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </Col>
                  </Row>

                  <div style={sectionTitleStyle}>Sales</div>

                  <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                    <Col xs={24} sm={12} lg={8}>
                      <label style={{ marginRight: "2.5%" }}>
                        Enable online shop
                      </label>
                      <Switch
                        checked={values.enableonlineshop}
                        onChange={(checked) => {
                          setFieldValue("enableonlineshop", checked);
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            enableonlineshop: checked, // Set to the first option's value
                          }));
                        }}
                      />
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                      <label style={{ marginRight: "2.5%" }}>
                        Enable retail shop
                      </label>
                      <Switch
                        checked={values.enableretailshop}
                        onChange={(checked) => {
                          setFieldValue("enableretailshop", checked);
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            enableretailshop: checked, // Set to the first option's value
                          }));
                        }}
                      />
                    </Col>
                  </Row>

                  {/* More Business Info Section */}
                  <div style={sectionTitleStyle}>More Business Info</div>

                  <Row gutter={16} style={{ marginTop: "16px" }}>
                    <Col span={24}>
                      <label style={{ marginRight: "16px" }}>
                        Business Type :{" "}
                      </label>
                      <Checkbox.Group
                        style={{ rowGap: "16px", marginTop: "16px" }}
                        value={values.business_type} // Prefill from form values
                        options={businessOptions} // Use fetched options
                        onChange={(checkedValues: string[]) => {
                          setFieldValue("business_type", checkedValues); // Update form field with selected values
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            business_type: checkedValues, // Set to the field value
                          }));
                        }}
                      />
                    </Col>
                  </Row>
                  <div style={{ marginTop: "16px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "16px",
                      }}
                    >
                      <Text
                        strong
                        style={{ fontSize: "16px", marginRight: "8px" }}
                      >
                        Opening hours
                      </Text>
                      <Link
                        style={{
                          textDecoration: "underline",
                          textUnderlineOffset: "4px",
                        }}
                        onClick={() => showEditModal()}
                      >
                        Edit
                      </Link>
                    </div>
                    <Row gutter={[16, 16]}>
                      {Object.keys(openingHours).map((day) => (
                        <Col xs={24} sm={12} md={6} lg={3} key={day}>
                          <Card
                            // className="day-card"
                            className={`${
                              openingHours[day] !== null &&
                              openingHours[day] !== "closed-null"
                                ? "day-card"
                                : "day-card-closed"
                            }`}
                            bodyStyle={{
                              padding: "0px 0px 20px 0px",
                              textAlign: "center",
                            }}
                          >
                            <div
                              className={`${
                                openingHours[day] !== null &&
                                openingHours[day] !== "closed-null"
                                  ? "day-header"
                                  : "day-header-closed"
                              }`}
                            >
                              {day.charAt(0).toUpperCase() + day.slice(1)}
                            </div>
                            <div style={{ paddingTop: "10px" }}>
                              {openingHours[day] !== null &&
                              openingHours[day] !== "closed-null"
                                ? openingHours[day]
                                : "Closed"}
                            </div>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>

                  <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                    {/* Time Zone */}
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <label>Time zone *</label>
                      <Select
                        value={values.time_zone}
                        onChange={(value) => {
                          setFieldValue("time_zone", value);
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            time_zone: value, // Set to the field value
                          }));
                        }}
                        placeholder="Select Time Zone"
                        style={{ width: "100%" }}
                      >
                        {timeZoneOptions.map((option: any) => (
                          <Option key={`${option.value}`} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                      <ErrorMessage name="time_zone">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </Col>

                    {/* Time Format */}
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <label>Time format</label>
                      <Select
                        placeholder="Select Time Format"
                        value={values.time_format}
                        style={{ width: "100%" }}
                        onChange={(value) => {
                          setFieldValue("time_format", value);
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            time_format: value, // Set to the field value
                          }));
                        }}
                      >
                        <Option value="12-hour time">12-hour time</Option>
                        <Option value="24-hour time">24-hour time</Option>
                      </Select>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={8} xl={8}>
                      <label>Default language *</label>
                      <Select
                        placeholder="Select Language"
                        defaultValue="English"
                        style={{ width: "100%" }}
                        value={values.language}
                        onChange={(value) => {
                          setFieldValue("language", value);
                          setFormValues((prevValues) => ({
                            ...prevValues,
                            language: value, // Set to the field value
                          }));
                        }}
                      >
                        {languageOptions.map((option: any) => (
                          <Option key={`${option.value}`} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                      <ErrorMessage name="language">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </Col>

                    {/* Enable Second Language */}
                    <Col
                      xs={24}
                      sm={12}
                      md={12}
                      lg={8}
                      xl={8}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <label style={{ marginRight: "8px" }}>
                        Enable second language
                      </label>
                      <Tooltip title="If you are a native English speaker, and the store's main customer groups are Spanish speakers and French speakers, then you can set the default system display language to English, the first language to Spanish and the second language to French.">
                        <InfoCircleOutlined style={{ marginRight: "8px" }} />
                      </Tooltip>
                      <Switch
                        checked={secondLanguageEnabled}
                        onChange={(checked) => {
                          setSecondLanguageEnabled(checked);
                          // setFormValues((prevValues) => ({
                          //   ...prevValues,
                          //   secondLanguageEnabled: checked, // Set to the field value
                          // }));
                        }}
                      />
                    </Col>

                    {/* Customer-facing Language (First Language) */}
                    {!secondLanguageEnabled && (
                      <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                        <label>
                          Customer-facing language (First language) *
                        </label>
                        <Select
                          placeholder="Please select" // Disable if second language is not enabled
                          style={{ width: "100%" }}
                          value={values.firstlanguage}
                          onChange={(value) => {
                            setFieldValue("firstlanguage", value);
                            setFormValues((prevValues) => ({
                              ...prevValues,
                              firstlanguage: value, // Set to the field value
                            }));
                          }}
                        >
                          {languageOptions.map((option: any) => (
                            <Option key={option.value} value={option.value}>
                              {option.label}
                            </Option>
                          ))}
                        </Select>
                        <ErrorMessage name="firstlanguage">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </Col>
                    )}
                    {/* Conditionally Render Second Language Dropdown */}
                    {secondLanguageEnabled && (
                      <>
                        <Col span={8} xs={24} sm={24} md={12} lg={8}>
                          <label>
                            Customer-facing language (Second language)
                          </label>
                          <Select
                            placeholder="Please select"
                            value={values.secondLanguage}
                            onChange={(value) => {
                              setFieldValue("secondLanguage", value);
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                secondLanguage: value, // Set to the field value
                              }));
                            }}
                            style={{ width: "100%" }}
                          >
                            {languageOptions.map((option: any) => (
                              <Option key={option.value} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                        </Col>

                        <Col span={8} xs={24} sm={24} md={12} lg={8}>
                          <label>
                            Customer-facing language (First language) *
                          </label>
                          <Select
                            placeholder="Please select" // Disable if second language is not enabled
                            style={{ width: "100%" }}
                            value={values.firstlanguage}
                            onChange={(value) => {
                              setFieldValue("firstlanguage", value);
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                firstlanguage: value, // Set to the field value
                              }));
                            }}
                          >
                            {languageOptions.map((option: any) => (
                              <Option key={option.value} value={option.value}>
                                {option.label}
                              </Option>
                            ))}
                          </Select>
                          <ErrorMessage name="firstlanguage">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </Col>
                      </>
                    )}
                  </Row>

                  <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                    {/* Store Logo */}
                    <Col span={3} xs={24} sm={24} md={3} lg={3}>
                      <label>Store Logo</label>
                      <div style={{ marginTop: "10px" }}>
                        {values.storelogo !== "default.png" &&
                        storeLogoUrl !== "" &&
                        storeLogoUrl !== null &&
                        storeLogoUrl !== undefined ? (
                          <div className="image-preview">
                            <img src={storeLogoUrl} alt={`Uploaded image`} />
                            <CloseOutlined
                              className="remove-icon"
                              onClick={() => handleRemoveStoreLogoImage()}
                            />
                          </div>
                        ) : (
                          <>
                            {values.storelogo == "default.png" ? (
                              <>
                                <div className="image-preview">
                                  <img
                                    src={defaultStoreImage}
                                    alt={`Uploaded image`}
                                  />
                                  <CloseOutlined
                                    className="remove-icon"
                                    onClick={() => handleRemoveStoreLogoImage()}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <Upload
                                  {...uploadStoreLogoProps}
                                  multiple={false}
                                  accept=".jpg,.png"
                                >
                                  <div className="upload-box">
                                    <CameraOutlined className="upload-icon" />
                                  </div>
                                </Upload>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </Col>

                    {/* Store Showcase Images */}
                    <Col span={21} xs={24} sm={24} md={21} lg={21}>
                      <label>Store showcase images</label>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        {[...Array(6)].map((_, index) => (
                          <div key={index} className="image-preview">
                            {imageUrls[index] ? (
                              <>
                                <img
                                  src={imageUrls[index]}
                                  alt={`Showcase ${index + 1}`}
                                />
                                <CloseOutlined
                                  className="remove-icon"
                                  onClick={() =>
                                    handleRemoveShowcaseImage(index)
                                  }
                                />
                              </>
                            ) : (
                              <Upload
                                {...uploadStoreShowcaseProps}
                                multiple={false} // Allow only one file per upload
                                accept=".jpg,.png"
                                beforeUpload={(file) =>
                                  handleShowCaseUpload(file, index)
                                }
                              >
                                <div className="upload-box">
                                  <CameraOutlined className="upload-icon" />
                                </div>
                              </Upload>
                            )}
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                    <Col span={24}>
                      <label>Store Introduction</label>
                      <Field name="outlet_introduction">
                        {({ field }: FieldProps) => (
                          <Input.TextArea
                            {...field}
                            placeholder="Please enter"
                            rows={5}
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                outlet_introduction: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>

                  <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                    {/* Website */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>GST. No.</label>
                      <Field name="gstno">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                gstno: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>

                    {/* Facebook */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>Sales Tax No.</label>
                      <Field name="salestaxno">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                salestaxno: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>

                    {/* YouTube */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>Service Tax No.</label>
                      <Field name="servicetaxno">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                servicetaxno: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                    {/* Website */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>BRN (New) No.</label>
                      <Field name="brnno">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                brnno: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>

                    {/* Facebook */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>TIN</label>
                      <Field name="tin">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                tin: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>

                    {/* YouTube */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>Tourism No.</label>
                      <Field name="tourismno">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                tourismno: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                    {/* Website */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>Exemption No.</label>
                      <Field name="exemptionno">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                exemptionno: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                    {/* Website */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>Website</label>
                      <Field name="website">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            prefix={
                              <img
                                src={websiteIcon}
                                alt="Website"
                                style={{ width: 24 }}
                              />
                            }
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                website: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>

                    {/* Facebook */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>Facebook</label>
                      <Field name="facebook">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            prefix={
                              <img
                                src={facebookIcon}
                                alt="Facebook"
                                style={{ width: 24 }}
                              />
                            }
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                facebook: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>

                    {/* YouTube */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>YouTube</label>
                      <Field name="youtube">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            prefix={
                              <img
                                src={youtubeIcon}
                                alt="YouTube"
                                style={{ width: 24 }}
                              />
                            }
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                youtube: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                    {/* Instagram */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>Instagram</label>
                      <Field name="instagram">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            prefix={
                              <img
                                src={instagramIcon}
                                alt="Instagram"
                                style={{ width: 24 }}
                              />
                            }
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                instagram: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>

                    {/* TikTok */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>TikTok</label>
                      <Field name="tiktok">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            prefix={
                              <img
                                src={tiktokIcon}
                                alt="TikTok"
                                style={{ width: 24 }}
                              />
                            }
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                tiktok: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>

                    {/* Xiaohongshu */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>Xiaohongshu</label>
                      <Field name="xiaohongshu">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            prefix={
                              <img
                                src={xiaohongshuIcon}
                                alt="Xiaohongshu"
                                style={{ width: 24 }}
                              />
                            }
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                xiaohongshu: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                    {/* Google */}
                    <Col xs={24} sm={24} md={12} lg={8} xl={8}>
                      <label>Google</label>
                      <Field name="google">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            prefix={
                              <img
                                src={googleIcon}
                                alt="Google"
                                style={{ width: 24 }}
                              />
                            }
                            onChange={(e) => {
                              setFormValues((prevValues) => ({
                                ...prevValues,
                                google: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>

                  <OpeningHoursSettings
                    visible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    openingHours={openingHours}
                    onSave={handleOpeningHoursSave}
                  />

                  {/* <Modal
                  title={`Edit ${
                    currentDay.charAt(0).toUpperCase() + currentDay.slice(1)
                  } Hours`}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  {openingHours[currentDay] &&
                    openingHours[currentDay][0] !== "closed" && (
                      <TimePicker.RangePicker
                        format="HH:mm"
                        onChange={handleTimeChange}
                        defaultValue={[
                          dayjs(
                            openingHours[currentDay]?.split("-")[0],
                            "HH:mm"
                          ),
                          dayjs(
                            openingHours[currentDay]?.split("-")[1],
                            "HH:mm"
                          ),
                        ]}
                      />
                    )}
                </Modal> */}

                  {/* Save Section */}
                  <div className="save-section">
                    <Button
                      type="default"
                      onClick={() => navigate("/settings/business-details")}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={submitted}
                    >
                      {isEditMode ? "Update" : "Save"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Content>
      </Content>
    </>
  );
};

export default OutletForm;
