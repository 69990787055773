import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  List,
  Button,
  Typography,
  Divider,
  Space,
  Modal,
  message,
  Spin,
} from "antd";
import {
  SettingOutlined,
  ReloadOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  MailOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Datatable from "../components/Datatable";
import { Helmet } from "react-helmet";
import { Line, Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Filler,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { get } from "../services/Apiclient";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useCurrency } from "../contexts/CurrencyContext";

ChartJS.register(
  LineElement,
  BarElement,
  PointElement,
  LinearScale,
  CategoryScale,
  Filler,
  Tooltip,
  Legend
);
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const currencyIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Custom_Data_Field2.png`;
const turnoverIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_SaleToday.png`;
const privilegesCardPaymentIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Custom_Data_Field3.png`;
const privilegeCardSalesIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Custom_Data_Field8.png`;
const cardSalesQtyIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Custom_Data_Field7.png`;
const newAddedCustomersIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Custom_Data_Field4.png`;
const arrivalIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Custom_Data_Field6.png`;
const appointmentIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_TotalAppointments.png`;
const billingIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Image_Quick_SY.png`;
const addcustomerIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Image_Quick_XZHY.png`;
const addappointmentIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Image_Quick_XZYY.png`;
const servicelistIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Image_Quick_XMGL.png`;
const productlistIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Image_Quick_CPGL.png`;
const scheduledvisitIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_ArriveToday.png`;
const todayBirthdayIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_BirthdayClient.png`;
const expiringprivilegesIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_ExpiringMembership.png`;
const servicefollowupIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Followup.png`;
const customerattritionIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Attrition.png`;
const nearexpiredproductIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_NearExpired.png`;
const temporarycustomerserviceIcon = `${process.env.PUBLIC_URL}/images/logo/image (8).png`;
const lowstocklevelproductIcon = `${process.env.PUBLIC_URL}/images/logo/image (9).png`;
const serviceFileIcon = `${process.env.PUBLIC_URL}/images/logo/service-file.png`;
const userBlueIcon = `${process.env.PUBLIC_URL}/images/logo/user-blue.png`;

const { Text, Title } = Typography;

const Home = () => {
  const dataTypes = [
    {
      key: "collected_turnover",
      icon: currencyIcon,
      title: "Collected turnover",
      desc: "Total amount from all selected collected payment methods",
    },
    {
      key: "turnover",
      icon: turnoverIcon,
      title: "Turnover",
      desc: "Total amount for all selected payment methods",
    },
    {
      key: "privilege_card_payment",
      icon: privilegesCardPaymentIcon,
      title: "Privilege card payment",
      desc: "Total amount from all selected privilege payment methods",
    },
    {
      key: "privilege_card_sales",
      icon: privilegeCardSalesIcon,
      title: "Privilege card sales",
      desc: "Amount from privilege card sales",
    },
    {
      key: "card_sales_qty",
      icon: cardSalesQtyIcon,
      title: "Card sales qty",
      desc: "Quantity of sold privilege cards",
    },
    {
      key: "new_added_customers",
      icon: newAddedCustomersIcon,
      title: "New added customers",
      desc: "Number of new customers added to the customer list",
    },
    {
      key: "arrival",
      icon: arrivalIcon,
      title: "Arrival",
      desc: "Number of in-store customers",
    },
    {
      key: "total_appointments",
      icon: appointmentIcon,
      title: "Total appointments",
      desc: "Total appointments for the day",
    },
  ];

  const defaultSelected = [
    "collected_turnover",
    "turnover",
    "total_appointments",
  ];

  const { currencySymbol } = useCurrency();

  const [selected, setSelected] = useState(defaultSelected);
  const [selectedcard, setSelectedCard] = useState<any>([]);

  const [loading, setLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(false);

  const [selectedDate, setSelectedDate] = useState(dayjs());

  const toggleSelect = (key: any) => {
    setSelected((prev) => {
      if (prev.includes(key)) {
        const newSelection = prev.filter((k) => k !== key);
        return newSelection;
      } else {
        if (prev.length >= 3) {
          message.warning("You can select up to 3 tags only");
          return prev;
        }
        return [...prev, key];
      }
    });
  };

  const isSelected = (key: any) => selected.includes(key);

  const [customtagModalOpen, SetcustomtagModalOpen] = useState(false);
  const [top_selling_staff_comparison, settop_selling_staff_comparison] =
    useState([]);
  const [top_selling_service_comparison, settop_selling_service_comparison] =
    useState([]);
  const [collected_turnover, setcollected_turnover] = useState([]);
  const [appointment_count_array, setappointment_count_array] = useState([]);
  const [new_customer_array, setnew_customer_array] = useState([]);
  const [arrival_array, setarrival_array] = useState([]);
  const [upcoming_appointments, setupcoming_appointments] = useState([]);
  const [privilege_card_sales_array, setprivilege_card_sales_array] = useState(
    []
  );
  const [card_sales_qty_array, setcard_sales_qty_array] = useState([]);
  const [privilege_card_payment_array, setprivilege_card_payment_array] =
    useState([]);
  const [turnover, setturnover] = useState("0");
  const [total_appointment, settotal_appointment] = useState(0);
  const [startdate_turnover, setstartdate_turnover] = useState("0");
  const [previous_day_turnover, setprevious_day_turnover] = useState("0");
  const [turnover_difference_percentage, setturnover_difference_percentage] =
    useState<any>("0");
  const [turnover_trend, setturnover_trend] = useState<any>("");
  const [new_customer, setnew_customer] = useState<any>(0);
  const [arrival, setarrival] = useState<any>(0);
  const [privilege_card_sales, setprivilege_card_sales] = useState<any>(0);
  const [card_sales_qty, setcard_sales_qty] = useState<any>(0);
  const [privilege_card_payment, setprivilege_card_payment] = useState<any>(0);
  const [scheduled_visits, setscheduled_visits] = useState<any>(0);
  const [expiring_privileges, setexpiring_privileges] = useState<any>(0);
  const [service_followup, setservice_followup] = useState<any>(0);
  const [customer_attrition, setcustomer_attrition] = useState<any>(0);
  const [temporary_customer_service, settemporary_customer_service] =
    useState<any>(0);
  const [low_stocklevel_products, setlow_stocklevel_products] =
    useState<any>(0);
  const [nearexpired_products, setnearexpired_products] = useState<any>(0);
  const [customer_birthday, setcustomer_birthday] = useState<any>(0);

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const closeCustomtagModal = () => {
    SetcustomtagModalOpen(false);
    setSelected(selectedcard);
    setSelectedCard([]);
  };

  const handleCustomtagModalOpen = () => {
    SetcustomtagModalOpen(true);
    setSelectedCard(selected);
  };

  const handleCustomTagSave = () => {
    SetcustomtagModalOpen(false);
    if (selected.length === 0) {
      setSelected(defaultSelected);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const fetchDashboardData = async () => {
    setLoading(true);
    try {
      const result = await get(
        `/report/dashboard/${localStorage.getItem(
          "outlet_id"
        )}/${selectedDate.format("DD-MM-YYYY")}/${selectedDate.format(
          "DD-MM-YYYY"
        )}`
      );
      if (result.data && result.data.success) {
        setturnover(result.data.data.turnover);
        setcollected_turnover(result.data.data.collected_turnover);
        setappointment_count_array(result.data.data.appointment_count_array);
        settotal_appointment(result.data.data.total_appointment);
        setstartdate_turnover(result.data.data.startdate_turnover);
        setprevious_day_turnover(result.data.data.previous_day_turnover);
        setturnover_difference_percentage(
          result.data.data.turnover_difference_percentage
        );
        setturnover_trend(result.data.data.turnover_trend);
        setnew_customer(result.data.data.new_customer);
        setarrival(result.data.data.arrival);
        setnew_customer_array(result.data.data.new_customer_array);
        setarrival_array(result.data.data.arrival_array);
        setprivilege_card_sales(result.data.data.privilege_card_sales);
        setcard_sales_qty(result.data.data.card_sales_qty);
        setprivilege_card_payment(result.data.data.privilege_card_payment);
        setscheduled_visits(result.data.data.scheduled_visits);
        setexpiring_privileges(result.data.data.expiring_privileges);
        setservice_followup(result.data.data.service_followup);
        setcustomer_attrition(result.data.data.customer_attrition);
        settemporary_customer_service(
          result.data.data.temporary_customer_service
        );
        setlow_stocklevel_products(result.data.data.low_stocklevel_products);
        settop_selling_service_comparison(
          result.data.data.top_selling_service_comparison
        );
        setupcoming_appointments(result.data.data.upcoming_appointments);
        setprivilege_card_sales_array(
          result.data.data.privilege_card_sales_array
        );
        setcard_sales_qty_array(result.data.data.card_sales_qty_array);
        setprivilege_card_payment_array(
          result.data.data.privilege_card_payment_array
        );
        settop_selling_staff_comparison(
          result.data.data.top_selling_staff_comparison
        );
        setnearexpired_products(result.data.data.nearexpired_products);
        setcustomer_birthday(result.data.data.customer_birthday);
      }
    } catch (err) {
      console.error("Failed to load dashboard data", err);
    } finally {
      setLoading(false);
      setRefreshData(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
    // eslint-disable-next-line
  }, [refreshData, selectedDate]);

  const turnoverchartData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Turnover",
        data: collected_turnover,
        fill: true,
        backgroundColor: "rgba(138, 43, 226, 0.1)",
        borderColor: "rgba(138, 43, 226, 1)",
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const privilege_card_saleschartData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Privilege card sales",
        data: privilege_card_sales_array,
        fill: true,
        backgroundColor: "rgba(138, 43, 226, 0.1)",
        borderColor: "rgba(138, 43, 226, 1)",
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const privileges_card_paymentchartData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Privilege card payment",
        data: privilege_card_payment_array,
        fill: true,
        backgroundColor: "rgba(138, 43, 226, 0.1)",
        borderColor: "rgba(138, 43, 226, 1)",
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const arrivalchartData = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Arrival",
        data: arrival_array,
        fill: true,
        backgroundColor: "rgba(138, 43, 226, 0.1)",
        borderColor: "rgba(138, 43, 226, 1)",
        tension: 0.4,
        pointRadius: 0,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    scales: {
      x: {
        display: false,
        type: "category" as const, // <-- important!
      },
      y: {
        display: false,
      },
    },
  };

  const appointmentdata = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Appointments",
        data: appointment_count_array,
        backgroundColor: "#fa541c",
        borderRadius: 4,
        barThickness: 10,
      },
    ],
  };

  const card_sales_qtydata = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "Card sales Qty",
        data: card_sales_qty_array,
        backgroundColor: "#fa541c",
        borderRadius: 4,
        barThickness: 10,
      },
    ],
  };

  const newly_added_customerdata = {
    labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
    datasets: [
      {
        label: "New Customer",
        data: new_customer_array,
        backgroundColor: "#fa541c",
        borderRadius: 4,
        barThickness: 10,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
    scales: {
      x: { display: false },
      y: { display: false },
    },
  };

  const handleRefreshClick = () => {
    setRefreshData(true);
  };

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Spin spinning={loading} size="large">
        <div style={{ backgroundColor: "#f5f7fa" }}>
          <Row style={{ padding: "12px 12px 12px 0" }}>
            <span
              style={{
                borderLeft: "3px solid #325DF2",
                paddingLeft: "10px",
                fontFamily: "SourceHanSansCN-Bold",
                fontWeight: "700",
                fontSize: "18px",
              }}
            >
              Home
            </span>
          </Row>
          <Row gutter={[16, 16]}>
            <Col
              span={16}
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={16}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                height: "100%",
              }}
            >
              <Card
                title={<Text strong>Daily Data</Text>}
                extra={
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <DatePicker
                      allowClear={false}
                      value={selectedDate}
                      onChange={(date) => {
                        if (date) setSelectedDate(date);
                      }}
                      format="DD-MM-YYYY"
                      style={{ width: 140 }}
                    />
                    <Button
                      type="text"
                      onClick={handleRefreshClick}
                      icon={
                        <ReloadOutlined
                          style={{
                            fontSize: "18px",
                            color: "#a8b1c7",
                            transform: refreshData
                              ? "rotate(360deg)"
                              : "rotate(0deg)",
                            transition: "transform 0.6s ease-in-out",
                          }}
                        />
                      }
                      style={{ color: "#a8b1c7" }}
                    >
                      Refresh
                    </Button>
                    <Button
                      onClick={handleCustomtagModalOpen}
                      type="default"
                      icon={
                        <SettingOutlined
                          style={{ fontSize: "18px", color: "#a8b1c7" }}
                        />
                      }
                      style={{ color: "#a8b1c7", border: "1px solid #a8b1c7" }}
                    />
                  </div>
                }
                bordered={false}
                headStyle={{ borderBottom: "none" }}
                style={{ background: "#ffffff", borderRadius: "8px" }}
                bodyStyle={{ padding: "10px 24px 24px 24px" }}
              >
                <Row gutter={[16, 16]}>
                  {selected.map((key) => {
                    const item = dataTypes.find((d) => d.key === key);
                    if (!item) return null;

                    return (
                      <Col
                        key={key}
                        span={
                          selected.length == 3
                            ? 8
                            : selected.length == 2
                            ? 12
                            : 24
                        }
                        xs={24}
                        sm={24}
                        md={24}
                        lg={
                          selected.length == 3
                            ? 8
                            : selected.length == 2
                            ? 12
                            : 24
                        }
                        xl={
                          selected.length == 3
                            ? 8
                            : selected.length == 2
                            ? 12
                            : 24
                        }
                      >
                        <Card
                          style={{
                            borderRadius: 10,
                            background: "#fafbfc",
                            height: "100%",
                            position: "relative",
                          }}
                          bodyStyle={{ height: "100%", minHeight: "200px" }}
                        >
                          <Space direction="vertical" size={4}>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <img
                                src={item.icon}
                                alt={item.title}
                                style={{ height: 20, width: 20 }}
                              />
                              <Text type="secondary">{item.title}</Text>
                            </div>

                            {/* Value display */}
                            <Title
                              level={3}
                              style={{ margin: 0, marginTop: "10px" }}
                            >
                              {item.key === "total_appointments" ? (
                                <>
                                  <span
                                    style={{
                                      fontSize: "26px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {total_appointment}
                                  </span>{" "}
                                  <Text
                                    type="secondary"
                                    style={{ fontSize: "16px" }}
                                  >
                                    headcount
                                  </Text>
                                </>
                              ) : item.key === "arrival" ? (
                                <>
                                  <span
                                    style={{
                                      fontSize: "26px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {arrival}
                                  </span>{" "}
                                  <Text
                                    type="secondary"
                                    style={{ fontSize: "16px" }}
                                  >
                                    headcount
                                  </Text>
                                </>
                              ) : item.key === "new_added_customers" ? (
                                <>
                                  <span
                                    style={{
                                      fontSize: "26px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {new_customer}
                                  </span>{" "}
                                  <Text
                                    type="secondary"
                                    style={{ fontSize: "16px" }}
                                  >
                                    headcount
                                  </Text>
                                </>
                              ) : item.key === "card_sales_qty" ? (
                                <>
                                  <span
                                    style={{
                                      fontSize: "26px",
                                      fontWeight: 600,
                                    }}
                                  >
                                    {card_sales_qty}
                                  </span>
                                </>
                              ) : item.key === "privilege_card_sales" ? (
                                <>
                                  <span style={{ fontSize: "16px" }}>RM</span>
                                  <span style={{ fontSize: "26px" }}>
                                    {" "}
                                    {privilege_card_sales}
                                  </span>
                                </>
                              ) : item.key === "privilege_card_payment" ? (
                                <>
                                  <span style={{ fontSize: "16px" }}>RM</span>
                                  <span style={{ fontSize: "26px" }}>
                                    {" "}
                                    {privilege_card_payment}
                                  </span>
                                </>
                              ) : item.key === "turnover" ? (
                                <>
                                  <span style={{ fontSize: "16px" }}>RM</span>
                                  <span style={{ fontSize: "26px" }}>
                                    {" "}
                                    {turnover}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span style={{ fontSize: "16px" }}>RM</span>
                                  <span style={{ fontSize: "26px" }}>
                                    {" "}
                                    {turnover}
                                  </span>
                                </>
                              )}
                            </Title>

                            {/* Mini Chart */}
                            {item.key === "collected_turnover" && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 20,
                                  width: "calc(100% - 40px)",
                                  height: 50,
                                }}
                              >
                                {turnover == "0.00" ? (
                                  <span
                                    style={{
                                      position: "absolute",
                                      bottom: "0px",
                                      width: "100%",
                                      background: "rgba(138, 43, 226, 1)",
                                      height: "2px",
                                      display: "block",
                                    }}
                                  ></span>
                                ) : (
                                  <Line
                                    data={turnoverchartData}
                                    options={chartOptions}
                                  />
                                )}
                              </div>
                            )}

                            {item.key === "privilege_card_sales" && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 20,
                                  width: "calc(100% - 40px)",
                                  height: 50,
                                }}
                              >
                                {privilege_card_sales == 0 ? (
                                  <span
                                    style={{
                                      position: "absolute",
                                      bottom: "0px",
                                      width: "100%",
                                      background: "rgba(138, 43, 226, 1)",
                                      height: "2px",
                                      display: "block",
                                    }}
                                  ></span>
                                ) : (
                                  <Line
                                    data={privilege_card_saleschartData}
                                    options={chartOptions}
                                  />
                                )}
                              </div>
                            )}

                            {item.key === "privilege_card_payment" && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 20,
                                  width: "calc(100% - 40px)",
                                  height: 50,
                                }}
                              >
                                {privilege_card_payment == 0 ? (
                                  <span
                                    style={{
                                      position: "absolute",
                                      bottom: "0px",
                                      width: "100%",
                                      background: "rgba(138, 43, 226, 1)",
                                      height: "2px",
                                      display: "block",
                                    }}
                                  ></span>
                                ) : (
                                  <Line
                                    data={privileges_card_paymentchartData}
                                    options={chartOptions}
                                  />
                                )}
                              </div>
                            )}

                            {item.key === "arrival" && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 20,
                                  width: "calc(100% - 40px)",
                                  height: 50,
                                }}
                              >
                                {arrival == 0 ? (
                                  <span
                                    style={{
                                      position: "absolute",
                                      bottom: "0px",
                                      width: "100%",
                                      background: "rgba(138, 43, 226, 1)",
                                      height: "2px",
                                      display: "block",
                                    }}
                                  ></span>
                                ) : (
                                  <Line
                                    data={arrivalchartData}
                                    options={chartOptions}
                                  />
                                )}
                              </div>
                            )}

                            {item.key === "turnover" && (
                              <Space
                                size="small"
                                style={{
                                  position: "absolute",
                                  bottom: 20,
                                  marginTop: "auto",
                                  width: "calc(100% - 40px)",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Text
                                  type="secondary"
                                  style={{ fontSize: "16px" }}
                                >
                                  Day-to-Day
                                </Text>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {turnover_trend == "increase" ? (
                                    <ArrowUpOutlined
                                      style={{ color: "#0dac73" }}
                                    />
                                  ) : turnover_trend == "decrease" ? (
                                    <ArrowDownOutlined
                                      style={{ color: "#f5222d" }}
                                    />
                                  ) : (
                                    <ArrowDownOutlined
                                      style={{ color: "#f5222d" }}
                                    />
                                  )}
                                  <Text style={{ fontSize: "16px" }}>
                                    {turnover_difference_percentage == null
                                      ? "0"
                                      : turnover_difference_percentage}
                                    %
                                  </Text>
                                </div>
                              </Space>
                            )}

                            {item.key === "total_appointments" && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 20,
                                  width: "calc(100% - 40px)",
                                  height: 50,
                                }}
                              >
                                {total_appointment == 0 ? (
                                  <span
                                    style={{
                                      position: "absolute",
                                      bottom: "0px",
                                      width: "100%",
                                      background: "#fc461d",
                                      height: "2px",
                                      display: "block",
                                    }}
                                  ></span>
                                ) : (
                                  <Bar
                                    data={appointmentdata}
                                    options={options}
                                  />
                                )}
                              </div>
                            )}

                            {item.key === "card_sales_qty" && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 20,
                                  width: "calc(100% - 40px)",
                                  height: 50,
                                }}
                              >
                                {card_sales_qty == 0 ? (
                                  <span
                                    style={{
                                      position: "absolute",
                                      bottom: "0px",
                                      width: "100%",
                                      background: "#fc461d",
                                      height: "2px",
                                      display: "block",
                                    }}
                                  ></span>
                                ) : (
                                  <Bar
                                    data={card_sales_qtydata}
                                    options={options}
                                  />
                                )}
                              </div>
                            )}

                            {item.key === "new_added_customers" && (
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 20,
                                  width: "calc(100% - 40px)",
                                  height: 50,
                                }}
                              >
                                {new_customer == 0 ? (
                                  <span
                                    style={{
                                      position: "absolute",
                                      bottom: "0px",
                                      width: "100%",
                                      background: "#fc461d",
                                      height: "2px",
                                      display: "block",
                                    }}
                                  ></span>
                                ) : (
                                  <Bar
                                    data={newly_added_customerdata}
                                    options={options}
                                  />
                                )}
                              </div>
                            )}
                          </Space>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </Card>

              {/* Quick Entry Section */}
              <Card
                title={<Text strong>Quick Entry</Text>}
                bordered={false}
                headStyle={{ borderBottom: "none" }}
                style={{
                  background: "#ffffff",
                  borderRadius: "8px",
                  // width: "1210px",
                  // marginTop: "-560px",
                }}
              >
                <Row
                  gutter={[16, 16]}
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <Col
                    span={4}
                    xs={24}
                    sm={12}
                    md={8}
                    lg={4}
                    xl={4}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Link to="/sales/billing">
                      <img
                        src={billingIcon}
                        alt="servicelist"
                        style={{ height: "64px", width: "64px" }}
                      />
                    </Link>
                    <Text>Billing</Text>
                  </Col>

                  <Col
                    span={4}
                    xs={24}
                    sm={12}
                    md={8}
                    lg={4}
                    xl={4}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Link to="/customer/add">
                      <img
                        src={addcustomerIcon}
                        alt="servicelist"
                        style={{ height: "64px", width: "64px" }}
                      />
                    </Link>
                    <Text>Add customer</Text>
                  </Col>

                  <Col
                    span={4}
                    xs={24}
                    sm={12}
                    md={8}
                    lg={4}
                    xl={4}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Link to="/booking?add=true">
                      <img
                        src={addappointmentIcon}
                        alt="addappointent"
                        style={{ height: "64px", width: "64px" }}
                      />
                    </Link>
                    <Text>Add appointment</Text>
                  </Col>

                  <Col
                    span={4}
                    xs={24}
                    sm={12}
                    md={8}
                    lg={4}
                    xl={4}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Link to="/settings/service/list">
                      <img
                        src={servicelistIcon}
                        alt="servicelist"
                        style={{ height: "64px", width: "64px" }}
                      />
                    </Link>
                    <Text>Service list</Text>
                  </Col>
                  <Col
                    span={4}
                    xs={24}
                    sm={24}
                    md={12}
                    lg={4}
                    xl={4}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Link to="/settings/product/list">
                      <img
                        src={productlistIcon}
                        alt="servicelist"
                        style={{ height: "64px", width: "64px" }}
                      />
                    </Link>
                    <Text>Product list</Text>
                  </Col>
                </Row>
              </Card>

              {/* Top Services and Top Staffs Section */}
              <div>
                <Row gutter={[16, 16]}>
                  <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Card
                      title={<Text strong>Top Services</Text>}
                      bordered={false}
                      headStyle={{ borderBottom: "none" }}
                      style={{
                        background: "#ffffff",
                        borderRadius: "8px",
                        height: "100%",
                      }}
                    >
                      <Datatable<any>
                        columns={[
                          {
                            title: "Service",
                            dataIndex: "service_name",
                            render: (text: any) => (
                              <span
                                style={{
                                  overflow: "hidden",
                                  textWrap: "wrap",
                                  wordBreak: "break-word",
                                  lineHeight: "normal",
                                }}
                              >
                                {text}
                              </span>
                            ),
                          },
                          {
                            title: "This month",
                            dataIndex: "current_month_qty",
                            render: (text: number, record: any) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                {record.current_month_qty}
                                {record.status == "increase" ? (
                                  <ArrowUpOutlined
                                    style={{ color: "#0dac73" }}
                                  />
                                ) : record.status == "decrease" ? (
                                  <ArrowDownOutlined
                                    style={{ color: "#f5222d" }}
                                  />
                                ) : (
                                  <ArrowDownOutlined
                                    style={{ color: "#f5222d" }}
                                  />
                                )}
                              </div>
                            ),
                          },
                          {
                            title: "Last month",
                            dataIndex: "previous_month_qty",
                            // render: (sellprice: number) => `$${sellprice}`,
                          },
                        ]}
                        dataSource={top_selling_service_comparison}
                        scroll={{ y: 200 }}
                        showCheckbox={false}
                        rowSelection={rowSelection}
                        showPagination={false}
                        dynamicTableWidth={"100%"}
                      />
                    </Card>
                  </Col>
                  <Col span={12} xs={24} sm={24} md={24} lg={12} xl={12}>
                    <Card
                      title={<Text strong>Top Staffs</Text>}
                      bordered={false}
                      headStyle={{ borderBottom: "none" }}
                      style={{
                        background: "#ffffff",
                        borderRadius: "8px",
                        height: "100%",
                        // width: "590px",
                        // marginLeft: "-200px",
                      }}
                    >
                      <Datatable<any>
                        columns={[
                          {
                            title: "Staff",
                            dataIndex: "staff_name",
                            render: (text: any) => (
                              <span
                                style={{
                                  overflow: "hidden",
                                  textWrap: "wrap",
                                  wordBreak: "break-word",
                                  lineHeight: "normal",
                                }}
                              >
                                {text}
                              </span>
                            ),
                          },
                          {
                            title: "This month",
                            dataIndex: "current_month_price",
                            render: (text: number, record: any) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                {currencySymbol.CURRENCY_SYMBOL}
                                {text.toFixed(1)}
                              </div>
                            ),
                          },
                          {
                            title: "Last month",
                            dataIndex: "previous_month_price",
                            render: (text: number, record: any) => (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                {currencySymbol.CURRENCY_SYMBOL}
                                {text.toFixed(1)}
                              </div>
                            ),
                            // render: (sellprice: number) => `$${sellprice}`,
                          },
                        ]}
                        dataSource={top_selling_staff_comparison}
                        scroll={{ y: 200 }}
                        showCheckbox={false}
                        rowSelection={rowSelection}
                        showPagination={false}
                        dynamicTableWidth={"100%"}
                      />
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col
              span={8}
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={8}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                height: "100%",
              }}
            >
              <Card
                title={<Text strong>Daily Reminder</Text>}
                bordered={false}
                headStyle={{ borderBottom: "none" }}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  background: "#ffffff",
                  borderRadius: "8px",
                  overflowY: "auto",
                }}
              >
                <List
                  dataSource={[
                    {
                      title: "Scheduled visits",
                      count: scheduled_visits,
                      img: scheduledvisitIcon,
                      view: true,
                    },
                    {
                      title: "Today's birthday customers",
                      count: customer_birthday,
                      img: todayBirthdayIcon,
                      view: true,
                    },
                    {
                      title: "Expiring privileges",
                      count: expiring_privileges,
                      img: expiringprivilegesIcon,
                      view: true,
                    },
                    {
                      title: "Service follow up",
                      count: service_followup,
                      img: servicefollowupIcon,
                      view: true,
                    },
                    {
                      title: "Customer attrition",
                      count: customer_attrition,
                      img: customerattritionIcon,
                      view: true,
                    },
                    {
                      title: "Near-Expired products",
                      count: nearexpired_products,
                      img: nearexpiredproductIcon,
                      view: true,
                    },
                    {
                      title: "Temporary customer service",
                      count: temporary_customer_service,
                      img: temporarycustomerserviceIcon,
                      view: true,
                    },
                    {
                      title: "Low stock level products",
                      count: low_stocklevel_products,
                      img: lowstocklevelproductIcon,
                      view: true,
                    },
                  ]}
                  renderItem={(item) => (
                    <List.Item style={{ borderBottom: "none" }}>
                      <List.Item.Meta
                        avatar={
                          <img
                            src={item.img}
                            alt={item.title}
                            style={{ width: 20, height: 20 }}
                          />
                        }
                        title={<Text>{item.title}</Text>}
                      />
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Text style={{ marginRight: "16px" }}>
                          {item.count}
                        </Text>
                        {item.view && (
                          // style={{ color: "#1890ff" }}
                          <a style={{ color: "#ccc", cursor: "not-allowed" }}>
                            View
                          </a>
                        )}
                      </div>
                    </List.Item>
                  )}
                />
              </Card>
              {/* Upcoming Appointments Section */}
              <Card
                title={<Text strong>Upcoming Appointments</Text>}
                bordered={false}
                headStyle={{ borderBottom: "none" }}
                style={{
                  background: "#ffffff",
                  borderRadius: "8px",
                  maxHeight: "340px",
                  overflowY: "auto",
                }}
                bodyStyle={{
                  padding: "8px",
                }}
                className="upcoming_appointment_card"
              >
                {upcoming_appointments && upcoming_appointments.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingTop: "70px",
                    }}
                  >
                    <img
                      src="https://static-global.boka.vc/mase/def/Def_Empty_noData.png"
                      alt="No Data"
                      style={{ width: "80px", marginBottom: "10px" }}
                    />
                    <p>There are currently no appointments available today</p>
                  </div>
                ) : (
                  <List
                    dataSource={upcoming_appointments}
                    renderItem={(item: any) => {
                      const time =
                        item.appointment_time?.split(" ")[1]?.toUpperCase() ||
                        "";
                      return (
                        <List.Item
                          style={{
                            flexDirection: "column",
                            alignItems: "flex-start",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              padding: "0px 16px",
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: 8,
                              }}
                            >
                              <div>
                                <span
                                  style={{
                                    borderLeft: "3px solid #325DF2",
                                    paddingLeft: "10px",
                                    fontFamily: "SourceHanSansCN-Bold",
                                    fontWeight: "700",
                                    fontSize: "18px",
                                  }}
                                ></span>
                                <Text strong>{item.appointment_time}</Text>
                              </div>
                              <Button type="primary" size="small" disabled>
                                Check out
                              </Button>
                            </div>
                            <Divider style={{ margin: "8px 0px" }} />

                            <Row
                              gutter={8}
                              style={{ width: "100%", marginBottom: 6 }}
                            >
                              <Col span={20}>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <img
                                    src={userBlueIcon}
                                    alt="user"
                                    style={{ height: "20px", width: "20px" }}
                                  />
                                  <Text>{item.customer_fullname}</Text>
                                </div>
                              </Col>
                              <Col
                                span={4}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                    background: "#e1e7fb",
                                    padding: "0px 6px",
                                    borderRadius: "10px",
                                    width: "fit-content",
                                  }}
                                >
                                  <img
                                    src={userBlueIcon}
                                    alt="user"
                                    style={{ height: "20px", width: "20px" }}
                                  />
                                  1
                                </span>
                              </Col>
                            </Row>

                            <Space
                              style={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                                marginBottom: 6,
                              }}
                            >
                              {item.services && item.services.length == 0 && (
                                <Text>No service available</Text>
                              )}
                              {item.services &&
                                item.services.length > 0 &&
                                item.services.map(
                                  (service: any, index: number) => (
                                    <div
                                      key={index}
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: 4,
                                      }}
                                    >
                                      <img
                                        src={serviceFileIcon}
                                        alt="service-file"
                                        style={{
                                          height: "20px",
                                          width: "20px",
                                          marginRight: 8,
                                        }}
                                      />
                                      <Text>{service}</Text>
                                    </div>
                                  )
                                )}
                            </Space>

                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: 6,
                                width: "100%",
                              }}
                            >
                              <Row gutter={[8, 8]} style={{ width: "100%" }}>
                                <Col
                                  span={12}
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={12}
                                  xl={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <PhoneOutlined
                                    style={{ transform: "rotate(90deg)" }}
                                  />
                                  <Text>
                                    {item.customer_phonenumber || "-"}
                                  </Text>
                                </Col>
                                <Col
                                  span={12}
                                  xs={24}
                                  sm={24}
                                  md={24}
                                  lg={12}
                                  xl={12}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "8px",
                                  }}
                                >
                                  <MailOutlined />
                                  <Text>{item.customer_email || "-"}</Text>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </List.Item>
                      );
                    }}
                  />
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </Spin>
      <Modal
        title="Custom tag"
        open={customtagModalOpen}
        onCancel={closeCustomtagModal}
        width={1000} // Adjust modal width as needed
        footer={[
          <Button key="confirm" type="primary" onClick={handleCustomTagSave}>
            Confirm
          </Button>,
        ]} // Remove default footer buttons
        style={{ top: "10px" }}
        bodyStyle={{
          height: "calc(100vh - 150px)",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <Row gutter={[16, 16]}>
          {selected.map((key) => {
            const item: any = dataTypes.find((d: any) => d.key === key);
            return (
              <Col key={key} span={8}>
                <Card
                  size="small"
                  className="dashboardcard"
                  title={
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        <img
                          src={item.icon}
                          alt={item.title}
                          style={{ height: "20px", width: "20px" }}
                        />
                        <div>{item.title}</div>
                      </div>
                    </>
                  }
                  style={{ background: "#f6f7fb" }}
                  extra={
                    <a
                      onClick={() => toggleSelect(key)}
                      style={{
                        color: "#c4cad6",
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                      }}
                    >
                      ✕
                    </a>
                  }
                >
                  <Text type="secondary" className="normalbreak">
                    {item.desc}
                  </Text>
                </Card>
              </Col>
            );
          })}
        </Row>

        <Divider />

        <Title level={5}>Data type</Title>
        <Row gutter={[16, 16]}>
          {dataTypes.map((item) => (
            <Col key={item.key} span={8}>
              <Card
                onClick={() => toggleSelect(item.key)}
                className="dashboardcard"
                style={{
                  cursor: isSelected(item.key) ? "not-allowed" : "pointer",
                  background: isSelected(item.key) ? "#FAFBFC" : "#f6f7fb",
                  opacity: isSelected(item.key) ? "70%" : "100%",
                  pointerEvents: isSelected(item.key) ? "none" : "auto",
                }}
                title={
                  <>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <img
                        src={item.icon}
                        alt={item.title}
                        style={{ height: "20px", width: "20px" }}
                      />
                      <div>{item.title}</div>
                    </div>
                  </>
                }
                bodyStyle={{ padding: "10px" }}
              >
                <Text type="secondary" className="normalbreak">
                  {item.desc}
                </Text>
              </Card>
            </Col>
          ))}
        </Row>
      </Modal>
    </>
  );
};

export default Home;
