import React, { FC, useEffect, useRef, useState } from "react";
import {
  Modal,
  Tabs,
  Input,
  Card,
  Checkbox,
  Row,
  Col,
  Button,
  Empty,
  Typography,
  Tag,
  Avatar,
  Select,
  InputRef,
} from "antd";

import {
  ClearOutlined,
  DeleteOutlined,
  UndoOutlined,
  PhoneOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { get } from "../../../services/Apiclient";
import { Form } from "react-router-dom";
import AddCustomerModel from "./AddCustomerModel";
import { useCurrency } from "../../../contexts/CurrencyContext";
// import { currencySymbol } from "../../../constants/currencySymbol";

const maleIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Gender_Male.png`;
const femaleIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Gender_Female.png`;
const emptyIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noResults.png`;

const { Text } = Typography;
const { Option } = Select;

interface SearchCustomerModelProps {
  visible: boolean;
  onClose: () => void;
  onSelect: (selectedObject: any) => void;
}

const SearchCustomerModel: FC<SearchCustomerModelProps> = ({
  visible,
  onClose,
  onSelect,
}) => {
  const { currencySymbol } = useCurrency();
  const { TabPane } = Tabs;

  const [page, setPage] = useState(1);
  const [limit] = useState(10); // Default items per page
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [searchTerm, setSearchTerm] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [customerData, setCustomerData] = useState<any[]>([]);

  const userRole = localStorage.getItem("userRole");
  const permissions = JSON.parse(localStorage.getItem("permissions") || "{}");

  const searchInputRef = useRef<InputRef | null>(null);

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (visible) {
      setSearchTerm("");
      setCustomerData([]);
      setPage(1);
      setHasMore(true);
      setIsLoading(true);
      searchCustomer(1, "", limit);
      setTimeout(() => searchInputRef.current?.focus(), 100);
    }
  }, [visible]);

  const search = () => {
    setCustomerData([]);
    setPage(1);
    setHasMore(true);
    setIsLoading(true);
    searchCustomer(1, searchTerm, limit);
  };

  const handleSearchClear = () => {
    setSearchTerm("");
  };

  // Trigger API when `searchTerm` changes to empty
  useEffect(() => {
    if (searchTerm === "" && visible) {
      setCustomerData([]);
      setPage(1);
      setHasMore(true);
      setIsLoading(true);
      searchCustomer(1, "", limit);
    }
  }, [searchTerm]);

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      setCustomerData([]);
      searchCustomer(1, searchTerm, limit);
    }
  };

  const handleCardClick = (data: any) => {
    onSelect(data);
    onClose();
  };

  const handleCustomerAdd = async (data: any) => {
    await setIsModalVisible(false);
    await onClose();
    await onSelect(data);
  };

  const onAddCustomerCancel = async () => {
    await setIsModalVisible(false);
  };

  const searchCustomer = async (
    pageNumber: number,
    query: string,
    limit: number
  ) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const offset = (pageNumber - 1) * limit;
      const response = await get(
        `/customer/searchcustomers/${localStorage.getItem(
          "outlet_id"
        )}/${query}?pagesize=${limit}&offset=${offset}`
      );

      if (response.data.success) {
        const newCustomers = response.data.data;
        setTotalRecords(response.data.totalRecords || 0);

        setCustomerData((prev) => [...prev, ...newCustomers]);

        // Check if all records are loaded
        setHasMore(
          customerData.length + newCustomers.length < response.data.totalRecords
        );
      }
    } catch (error) {
      console.error("Error fetching customer data:", error);
    } finally {
      setIsLoading(false);
      setTimeout(() => searchInputRef.current?.focus(), 100);
    }
  };

  // useEffect(() => {

  // }, [searchTerm]);

  const renderCustomerCards = () =>
    customerData.map((customer: any) => (
      <Card
        key={customer.id}
        onClick={() => handleCardClick(customer)}
        style={{
          backgroundColor: "#f5f7fa",
          borderRadius: "8px",
          padding: "10px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          width: "100%",
          maxWidth: "610px",
          margin: "auto",
          cursor: "pointer",
          marginBottom: "12px",
        }}
        bodyStyle={{ padding: "0px" }}
      >
        <Row align="middle">
          {/* Left Side - Avatar and Basic Info */}
          <Col span={3}>
            <Avatar size={48} style={{ backgroundColor: "#c3d0e8" }} />
          </Col>
          <Col span={21}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography.Text
                strong
                style={{
                  fontSize: "16px",
                  display: "block",
                  marginRight: "10px",
                }}
              >
                {customer.name}
              </Typography.Text>
              <img
                src={customer.gender == "Male" ? maleIcon : femaleIcon}
                alt="icon"
                style={{ width: "20px", height: "20px", marginRight: "10px" }}
              />
              <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
                {customer.customercode}
              </Typography.Text>
            </div>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "5px",
                  color: "#888888",
                  marginRight: "28px",
                }}
              >
                <PhoneOutlined style={{ marginRight: "5px" }} />
                <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
                  +{customer.countrycode ? customer.countrycode : ""}{" "}
                  {customer.phonenumber ? customer.phonenumber : "-"}
                </Typography.Text>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#888888",
                }}
              >
                <HomeOutlined style={{ marginRight: "5px" }} />
                <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
                  {customer.homeaddress !== "" ? customer.homeaddress : "-"}
                </Typography.Text>
              </div>
            </div>
          </Col>
        </Row>
        <Row align="middle">
          <Col span={12}>
            <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
              Available balance:
            </Typography.Text>
            <Typography.Text
              strong
              style={{ fontSize: "16px", marginLeft: "5px" }}
            >
              {currencySymbol.CURRENCY_SYMBOL}
              {customer.balance}
            </Typography.Text>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
              Last consumption date:
            </Typography.Text>
            <Typography.Text
              style={{ fontSize: "14px", color: "#888888", marginLeft: "5px" }}
            >
              {customer.last_consumption_date_used !== null
                ? new Date(
                    customer.last_consumption_date_used
                  ).toLocaleDateString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  })
                : "-"}
            </Typography.Text>
          </Col>
        </Row>
        <Row
          style={{
            marginTop: "15px",
            borderTop: "1px solid #e0e0e0",
            paddingTop: "10px",
          }}
        >
          <Col span={24}>
            <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
              Note:
            </Typography.Text>
            <Typography.Text
              style={{ fontSize: "14px", color: "#888888", marginLeft: "5px" }}
            >
              {customer.notes !== "" ? customer.notes : "-"}
            </Typography.Text>
          </Col>
        </Row>
      </Card>
    ));

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (
        scrollHeight - scrollTop <= clientHeight + 20 &&
        hasMore &&
        !isLoading
      ) {
        setIsLoading(true);

        setPage((prevPage) => {
          const nextPage = prevPage + 1;
          searchCustomer(nextPage, searchTerm, limit);
          return nextPage;
        });
      }
    }
  };

  const handleClose = () => {
    setSearchTerm("");
    setCustomerData([]);
    setPage(1);
    setHasMore(true);
    setIsLoading(false);
    onClose();
  };

  return (
    <Modal
      visible={visible}
      title="Search Customer"
      onCancel={handleClose}
      footer={null}
      width="700px"
      style={{
        top: "10px",
      }}
      bodyStyle={{
        height: "calc(100vh - 100px)",
      }}
    >
      <Row
        justify="space-between"
        style={{
          display: "flex",
          padding: "6px 0px 0",
          gap: "5px",
          marginBottom: "24px",
        }}
      >
        <Col>
          {/* Search Input */}
          <Input
            ref={searchInputRef}
            placeholder="Search by name/phone number/email"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onClear={handleSearchClear}
            onKeyPress={handleKeyPress}
            disabled={isLoading}
            // onBlur={searchCustomer}
            style={{ width: "380px" }}
            allowClear
          />
        </Col>

        {/* Search Button */}
        <Col>
          <Button
            type="primary"
            onClick={search}
            style={{
              background: "#2e56f2",
              color: "#fff",
              borderRadius: "6px",
              width: "81px",
              height: "40px",
              fontWeight: 400,
              fontSize: "15px",
            }}
          >
            Search
          </Button>
        </Col>

        {/* Add New Customer Button */}
        {(userRole !== "Staff" ||
          (userRole === "Staff" && permissions?.addnewcustomer)) && (
          <Col>
            <Button
              style={{}}
              type="primary"
              onClick={showModal}
              className="add-button"
            >
              Add New Customer
            </Button>
          </Col>
        )}
      </Row>

      <div
        style={{
          height: "calc(100vh - 120px)",
          overflowY: "auto",
          paddingRight: "10px",
          marginRight: "-20px",
        }}
      >
        {!isLoading && customerData && customerData.length === 0 && (
          <Empty
            style={{
              height: "calc(100vh - 180px)",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            image={emptyIcon}
            imageStyle={{ height: 100 }}
            description="No results found"
          />
        )}
        <div
          ref={containerRef}
          onScroll={handleScroll}
          style={{
            height: "calc(100vh - 180px)",
            overflowY: "auto",
          }}
        >
          <>
            {customerData && customerData.length > 0 && (
              <>{renderCustomerCards()}</>
            )}
            {isLoading && (
              <div
                style={{
                  textAlign: "center",
                  padding: "20px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              >
                Loading...
              </div>
            )}
          </>
        </div>
        {/* {[...Array(8)].map((_, index) => (
          <Card
            onClick={() => {}}
            style={{
              padding: "10px",
              width: "100%",
              maxWidth: "600px",
              marginBottom: "16px",
              background: "#f6f7fa",
              borderRadius: "6px",
            }}
            bodyStyle={{ padding: "0px" }}
          >
            <Row align="middle">
              <Col span={3}>
                <Avatar size={48} style={{ backgroundColor: "#c3d0e8" }} />
              </Col>

              <Col span={20}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      alignContent: "center",
                      gap: "8px",
                    }}
                  >
                    <Typography.Text
                      style={{
                        fontSize: "16px",
                        fontWeight: 500,
                        color: "#333",
                      }}
                    >
                      dev patel
                    </Typography.Text>
                    <img
                      src={maleIcon}
                      alt="img"
                      style={{ width: "20px", height: "20px" }}
                    />
                    <Typography.Text
                      style={{
                        fontWeight: 400,
                        fontSize: "12px",
                        color: "#999",
                        marginLeft: "12px",
                      }}
                    >
                      1234
                    </Typography.Text>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "5px",
                      color: "#888888",
                      gap: "25px",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <PhoneOutlined style={{ marginRight: "5px" }} />
                      <Typography.Text
                        style={{ fontSize: "14px", color: "#888888" }}
                      >
                        1234567890
                      </Typography.Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        color: "#888888",
                      }}
                    >
                      <HomeOutlined style={{ marginRight: "5px" }} />
                      <Typography.Text
                        style={{ fontSize: "14px", color: "#888888" }}
                      >
                        xyzbhg
                      </Typography.Text>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row align="middle">
              <Col span={12}>
                <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
                  Available balance:
                </Typography.Text>
                <Typography.Text
                  strong
                  style={{ fontSize: "16px", marginLeft: "5px" }}
                >
                  $0
                </Typography.Text>
              </Col>
              <Col span={12} style={{ textAlign: "right" }}>
                <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
                  Last consumption date:
                </Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: "14px",
                    color: "#888888",
                    marginLeft: "5px",
                  }}
                >
                  1234567890
                </Typography.Text>
              </Col>
            </Row>
            <Row
              style={{
                marginTop: "15px",
                borderTop: "1px solid #e0e0e0",
                paddingTop: "10px",
                marginBottom: "0px",
              }}
            >
              <Col span={24}>
                <Typography.Text style={{ fontSize: "14px", color: "#888888" }}>
                  Note:
                </Typography.Text>
                <Typography.Text
                  style={{
                    fontSize: "14px",
                    color: "#888888",
                    marginLeft: "5px",
                  }}
                >
                </Typography.Text>
              </Col>
            </Row>
          </Card>
        ))} */}
      </div>

      <AddCustomerModel
        visible={isModalVisible}
        onClose={onAddCustomerCancel}
        onSave={handleCustomerAdd}
      />
    </Modal>
  );
};

export default SearchCustomerModel;
