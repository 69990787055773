import { RightOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Empty,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCurrency } from "../../../contexts/CurrencyContext";
import { get } from "../../../services/Apiclient";
import Datatable from "../../Datatable";
import CustomerDatatable from "../customer/CustomerDataTable";
import ApplicablePackageModel from "./ApplicablePackageModal";

const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const packagecardImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_CardItem_PackageCard.png`;
const packageCardImg = `${process.env.PUBLIC_URL}/images/logo/Def_Img_CardItem_Package.png`;

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Text } = Typography;

interface BillingPackageDetailsModalProps {
  visible: boolean;
  selectedItem: any;
  customerid: any;
  onClose: () => void;
  onSubmit: (redeemData: any[]) => void; // Updated to pass redeem data up
}

interface GiftCardsData {
  key: any;
  id: string;
  type: string;
  ref: string;
  store: string;
  time: string;
  item: string;
  account: string;
  qutUsed: string;
  accountedAmount: string;
}

const columns = [
  { title: "Type", dataIndex: "type", key: "type", width: 150 },
  { title: "Ref#", dataIndex: "ref", key: "ref", width: 150 },
  { title: "Store", dataIndex: "store", key: "store", width: 200 },
  { title: "Time", dataIndex: "time", key: "time", width: 200 },
  { title: "Item", dataIndex: "item", key: "item", width: 200 },
  { title: "Account", dataIndex: "account", key: "account", width: 150 },
  { title: "Qty. used", dataIndex: "qutUsed", key: "qutUsed", width: 150 },
  {
    title: "Accounted amount",
    dataIndex: "accountedAmount",
    key: "accountedAmount",
    width: 150,
  },
];

const storeColumns = [
  { title: "Store code", dataIndex: "storeCode", key: "storeCode", width: 100 },
  { title: "Store", dataIndex: "storeName", key: "storeName", width: 100 },
];

interface StoreDataProps {
  key: any;
  id: string;
}

interface PackageItemListProps {
  products: any[];
  services: any[];
}

const BillingPackageDetailsModal: React.FC<BillingPackageDetailsModalProps> = ({
  visible,
  selectedItem = null,
  customerid,
  onClose,
  onSubmit,
}) => {
  const { currencySymbol } = useCurrency();
  const { id } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [toggleButtonValue, setToggleButtonValue] =
    useState<any>("Package Items");
  const [packageItemList, setPackageItemList] = useState<PackageItemListProps>({
    products: [],
    services: [],
  });
  const [dataSource, setDataSource] = useState<GiftCardsData[]>([]);
  const [storeDataSource, setStoreDataSource] = useState<StoreDataProps[]>([]);
  const [applicableModal, setApplicableModal] = useState(false);
  const [redeemValues, setRedeemValues] = useState<{ [key: string]: number }>(
    {}
  );
  const [selectedRedeem, setSelectedRedeem] = useState<any[]>([]);

  useEffect(() => {
    if (toggleButtonValue === "Package Items") {
      getPackageItemDetails();
    } else if (toggleButtonValue === "Change History") {
      getPackageHistory();
    } else if (toggleButtonValue === "Applicable Stores") {
      getPackageStoreDetails();
    }
  }, [toggleButtonValue, currentPage, pageSize, id, selectedItem]);

  const getPackageItemDetails = async () => {
    try {
      const outletId = localStorage.getItem("outlet_id");
      const url = `/billing/customer/package/itemhistory/${customerid?.id}/${selectedItem?.bill_id}/${outletId}`;
      const response = await get(url);
      const data = response.data;
      if (data.success) {
        setPackageItemList(data.data);
        const initialRedeemValues: { [key: string]: number } = {};
        data.data.products.forEach((item: any) => {
          initialRedeemValues[item.id || item.item_name] = 0;
        });
        data.data.services.forEach((item: any) => {
          initialRedeemValues[item.id || item.item_name] = 0;
        });
        setRedeemValues(initialRedeemValues);
      }
    } catch (error) {
      console.error("Error while getting package items", error);
    }
  };

  const getPackageHistory = async () => {
    const outletId = localStorage.getItem("outlet_id");
    try {
      const url = `/billing/customer/package/history/${currentPage}/${pageSize}/${customerid?.id}/${selectedItem?.package_id}/${outletId}`;
      const response = await get(url);
      const data = response.data;
      if (data.success) {
        const transformedData = data.data.map((item: any) => {
          const combinedItems = [
            ...(item.item_single_details || []),
            ...(item.item_combo_details || []),
          ]
            .map((detail) => `${detail.item_name}x${detail.item_qty}`)
            .join(", ");
          const allItems = [
            ...(item.item_single_details || []),
            ...(item.item_combo_details || []),
          ];
          const totalItemsCount = allItems.length;
          const createdAt = new Date(item.created_at);
          const formattedDate = createdAt.toLocaleDateString("en-GB");
          const formattedTime = createdAt
            .toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit" })
            .toLowerCase();
          const formattedDateTime = `${formattedDate} ${formattedTime}`;

          return {
            id: item.id,
            key: item.id,
            type: "Purchased",
            ref: item.ref_no,
            store: item.outlet_name,
            time: formattedDateTime,
            item: combinedItems,
            account: item.account,
            qutUsed:
              totalItemsCount && totalItemsCount > 0
                ? `+${totalItemsCount}`
                : `totalItemsCount`,
            accountedAmount: `${currencySymbol.CURRENCY_SYMBOL}${parseFloat(
              item.item_price
            )}`,
          };
        });

        setDataSource(transformedData);
        setTotalRecords(data.totalRecords);
      }
    } catch (error) {
      console.error("Error in get package history", error);
    }
  };

  const getPackageStoreDetails = async () => {
    try {
      const url = `/billing/customer/package/history/store/${customerid?.id}/${selectedItem?.package_id}`;
      const response = await get(url);
      const data = response.data;
      if (data.success) {
        const storeDetails = data.data[0]?.store_names || [];
        const jsonStoreData = storeDetails.map((store: any) => ({
          id: store.id,
          key: store.id,
          storeCode: store.storecode,
          storeName: store.name,
        }));
        setStoreDataSource(jsonStoreData);
      }
    } catch (error) {
      console.error("Error in get package store details", error);
    }
  };

  const handleToggleButton = (value: string) => {
    setToggleButtonValue(value);
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const handleRedeemChange = (
    itemId: string,
    value: number | null,
    remaining: any,
    item: any,
    comboItems?: any[]
  ) => {
    const maxAmount = Number(remaining) || 0;
    const validValue =
      value !== null ? Math.max(0, Math.min(value, maxAmount)) : 0;

    // Determine packageId
    const packageId = comboItems
      ? item.combo_id // Use combo_id for combo items
      : item.id || item.item_name; // Use id or item_name for single items

    if (comboItems && packageId) {
      // For combo items, calculate total redeemed across all items with the same combo_id
      const otherComboValues = selectedRedeem
        .filter((r) => r.packageId === packageId && r.itemId !== itemId)
        .reduce((sum, r) => sum + (Number(r.redeemAmount) || 0), 0);

      const newTotal = otherComboValues + validValue;
      if (newTotal > maxAmount) {
        message.error(`The total redeem amount cannot exceed ${maxAmount}`);
        return;
      }
    } else if (value !== null && value > maxAmount) {
      message.error(`The redeem amount cannot exceed ${maxAmount}`);
    }

    setRedeemValues((prev) => ({ ...prev, [itemId]: validValue }));

    if (validValue > 0) {
      const updatedRedeem = [
        ...selectedRedeem.filter((r) => r.itemId !== itemId),
        {
          itemId,
          redeemAmount: validValue,
          packageId: selectedItem?.id,
          ...item,
        },
      ];
      setSelectedRedeem(updatedRedeem);
    } else {
      setSelectedRedeem(selectedRedeem.filter((r) => r.itemId !== itemId));
    }
  };

  const renderSingleItem = (item: any, type: any) => (
    <Row
      style={{
        border: "1px solid #f0f0f0",
        padding: "18px 8px 18px 12px",
        marginTop: "10px",
        lineHeight: "20px",
        background: "#fff",
        fontSize: "12px",
        position: "relative",
      }}
    >
      <Col span={3}>
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: "-18px",
            bottom: "-18px",
            left: "-15px",
            width: "25px",
            background: "rgba(46, 86, 242, .2)",
            borderRadius: "4px 0 0 4px",
            zIndex: 100,
            fontFamily: "PingFang SC",
            fontWeight: 500,
            fontSize: "12px",
            color: "#2e56f2",
          }}
        >
          <span style={{ transform: "rotate(-90deg)", verticalAlign: "top" }}>
            {type}
          </span>
        </div>
        <div style={{ marginLeft: "20px" }}>{item.item_name}</div>
      </Col>
      <Col span={3}>{item.item_qty || "0"}</Col>
      <Col span={3}>{item.item_used_qty || "0"}</Col>
      <Col span={4}>{item.purchasesessions || "-"}</Col>
      <Col span={3}>
        {currencySymbol.CURRENCY_SYMBOL}
        {item.item_price}
      </Col>
      <Col span={4}>{item.remaining || "0"}</Col>
      <Col span={4}>
        <InputNumber
          min={0}
          value={redeemValues[item.id || item.item_name]}
          disabled={item.remaining === "0"}
          onChange={(value) =>
            handleRedeemChange(
              item.id || item.item_name,
              value,
              item.remaining || "0",
              item
            )
          }
        />
      </Col>
    </Row>
  );

  const renderComboItem = (
    comboItems: any,
    type: any,
    details: any,
    index: any,
    lastIndex: any
  ) => (
    <Row
      style={{
        border: "1px solid #f0f0f0",
        padding: "18px 8px 18px 12px",
        marginTop: "10px",
        lineHeight: "20px",
        background: "#fff",
        fontSize: "12px",
        position: "relative",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Col span={3}>
        <div
          style={{
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            top: "-18px",
            bottom: "-18px",
            left: "-15px",
            width: "25px",
            background: "rgba(46, 86, 242, .2)",
            borderRadius: "4px 0 0 4px",
            zIndex: 100,
            fontFamily: "PingFang SC",
            fontWeight: 500,
            fontSize: "12px",
            color: "#2e56f2",
          }}
        >
          <span style={{ transform: "rotate(-90deg)", verticalAlign: "top" }}>
            {type}
          </span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "9px",
            marginLeft: "20px",
          }}
        >
          {comboItems.map((item: any) => (
            <Text key={item.id}>{item.item_name}</Text>
          ))}
        </div>
      </Col>
      <Col span={3}>
        <div style={{ display: "flex", flexDirection: "column", gap: "9px" }}>
          {comboItems.map((item: any) => (
            <Text key={item.id}>{item.max_available}</Text>
          ))}
        </div>
      </Col>
      <Col span={3}>
        <div style={{ display: "flex", flexDirection: "column", gap: "9px" }}>
          {comboItems.map((item: any) => (
            <Text key={item.id}>{item?.item_used_qty || "0"}</Text>
          ))}
        </div>
      </Col>
      {comboItems.length > 1 && (
        <>
          <div
            style={{
              position: "absolute",
              left: "46%",
              top: "16%",
              bottom: "16%",
              width: "2px",
              backgroundColor: "#2e56f2",
            }}
          />
          <div
            style={{
              position: "absolute",
              left: "46%",
              top: "48%",
              height: "2px",
              width: "15px",
              backgroundColor: "#2e56f2",
            }}
          />
        </>
      )}
      {index === 1 && (
        <div
          style={{
            position: "absolute",
            left: "44.7%",
            top: "16%",
            height: "2px",
            width: "15px",
            backgroundColor: "#2e56f2",
          }}
        />
      )}
      {index === lastIndex && (
        <div
          style={{
            position: "absolute",
            left: "44.7%",
            bottom: "16%",
            height: "2px",
            width: "15px",
            backgroundColor: "#2e56f2",
          }}
        />
      )}
      <Col span={4}>
        <div>
          <Text>{details?.purchasesessions}</Text>
        </div>
      </Col>
      <Col span={3}>
        <div
          style={{ display: "flex", alignItems: "center", padding: "10px 0" }}
        >
          <Text>
            {currencySymbol.CURRENCY_SYMBOL}
            {details?.selling_price}
          </Text>
        </div>
      </Col>
      <Col span={4}>
        <div
          style={{ display: "flex", alignItems: "center", padding: "10px 0" }}
        >
          <Text>{details?.remaining}</Text>
        </div>
      </Col>
      <Col span={4}>
        <div style={{ alignItems: "center" }}>
          {comboItems.map((item: any) => (
            <>
              <InputNumber
                key={item.id}
                min={0}
                type="number"
                value={redeemValues[item.id || item.item_name] || 0}
                disabled={
                  parseInt(item.max_available) - parseInt(item.item_used_qty) ==
                    0 || details?.remaining == "0"
                }
                onChange={(value) =>
                  handleRedeemChange(
                    item.id || item.item_name,
                    value,
                    parseInt(item.max_available) -
                      parseInt(item.item_used_qty) || "0",
                    item, // Pass item with combo_id
                    comboItems
                  )
                }
              />
            </>
          ))}
        </div>
      </Col>
    </Row>
  );

  const handleSubmitPayment = () => {
    if (selectedRedeem.length === 0) {
      message.warning(
        "Please enter at least one valid redeem amount greater than 0"
      );
      return;
    }

    onSubmit(selectedRedeem);
    console.log("Redeem Data:", selectedRedeem);
    onClose();
  };

  return (
    <Modal
      open={visible}
      title={"Package Details"}
      onCancel={onClose}
      footer={
        <div style={{ textAlign: "right", marginTop: "16px" }}>
          <Button type="primary" onClick={handleSubmitPayment}>
            Enter
          </Button>
        </div>
      }
      width="1100px"
      style={{ top: "50px" }}
      styles={{
        body: {
          height: "calc(100vh - 200px)",
          scrollbarWidth: "thin",
          overflowX: "hidden",
          overflowY: "auto",
        },
      }}
    >
      <div style={{ overflowY: "auto" }}>
        {selectedItem && (
          <div
            style={{
              color: "rgba(176, 87, 77, .8)",
              position: "relative",
              width: "100%",
              marginBottom: "16px",
              backgroundImage: `url(${packagecardImage})`,
              backgroundSize: "100% 100%",
              transition: "all .2s",
              borderRadius: "10px",
              overflow: "hidden",
            }}
          >
            <div className="package_image-icon">
              <div className="package_van-image">
                <img
                  className="package_van-image__img"
                  src={packageCardImg}
                  alt="package img"
                />
              </div>
            </div>
            <div className="package_view-bc">
              <div className="package_view-info">
                <div className="package_overflowText">
                  <span className="package_title">
                    {selectedItem?.package_name}
                  </span>
                  <span className="package_saleCardItem_text_code">
                    Code: {selectedItem.package_code}
                  </span>
                </div>
                <div className="package_view-apply">
                  <span
                    className="package_view-cardApply"
                    onClick={(e) => {
                      e.stopPropagation();
                      setApplicableModal(true);
                    }}
                  >
                    <div className="package_bk-cardApply">
                      <span className="package_cardApply_text_applicable">
                        Applicable to:
                      </span>
                      {selectedItem.applicable_to}
                    </div>
                  </span>
                  <span className="package_icon-gengduo">
                    <RightOutlined />
                  </span>
                </div>
              </div>
              <div className="ms_display-def_view-selectedItem">
                <div className="package_view-tip">Product remaining</div>
                <div className="package_view-value">
                  {selectedItem?.total_remaining_products}
                </div>
              </div>
              <div className="ms_display-def_view-item">
                <div className="package_view-tip">Service remaining</div>
                <div className="package_view-value">
                  {selectedItem?.total_remaining_services}
                </div>
              </div>
            </div>
            <div className="package_view-bottom">
              <div className="package_view-left">
                <div className="package_bk-validDateText">
                  <span className="package_saleCardItem_text_valid">
                    Valid for:{" "}
                  </span>
                  {selectedItem.typeof_validity === "Custom" ? (
                    <span className="package_bk-date">
                      {selectedItem.item_validfor}
                    </span>
                  ) : (
                    <span className="package_bk-date">
                      {selectedItem.item_validfor?.split(" ~ ")[0]}{" "}
                      {selectedItem.item_validfor?.split(" ~ ")[1]}{" "}
                      {selectedItem.typeof_validity}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {!selectedItem && (
          <Empty
            image={emptyImageUrl}
            imageStyle={{ height: 120, top: 400 }}
            description={<span>No data available</span>}
          />
        )}
      </div>
      <div>
        <Row gutter={24} style={{ marginTop: "2px" }}>
          <Col span={12}>
            Purchase date:{" "}
            {new Date(selectedItem?.created_at).toLocaleDateString("en-US")}
          </Col>
          <Col span={12}>
            Paid: {currencySymbol.CURRENCY_SYMBOL}
            {parseFloat(selectedItem?.selling_price)}
          </Col>
        </Row>
        <Row gutter={24} style={{ marginTop: "2px" }}>
          <Col span={12}>Purchase store: {selectedItem?.outlet_name}</Col>
          <Col span={12}>Purchase ref#: {selectedItem?.ref_no}</Col>
        </Row>
        <Row gutter={24} style={{ marginTop: "2px" }}>
          <Col span={12}>Debt: -</Col>
          <Col span={12}>Frozen: -</Col>
        </Row>
        <Row gutter={24} style={{ marginTop: "2px" }}>
          <Col span={12}>Notes:</Col>
        </Row>
      </div>
      <div style={{ borderBottom: "1px solid #e0e7fe", marginTop: "10px" }} />

      <Tabs activeKey={toggleButtonValue} onChange={handleToggleButton}>
        <TabPane tab="Package Items" key="Package Items">
          <Row
            style={{
              border: "1px solid #f0f0f0",
              lineHeight: "20px",
              padding: "8px 8px 8px 12px",
              background: "#fff",
              fontSize: "12px",
              fontWeight: 600,
              color: "#666",
            }}
          >
            <Col span={3}>Items</Col>
            <Col span={3}>Max available</Col>
            <Col span={3}>Used</Col>
            <Col span={4}>Purchase Session</Col>
            <Col span={3}>Paid</Col>
            <Col span={4}>Remaining</Col>
            <Col span={4}>Redeem</Col>
          </Row>

          {packageItemList.products.map((product, index) =>
            product.type === "Single"
              ? renderSingleItem(product, "Product")
              : renderComboItem(
                  product.combo,
                  "Product",
                  product,
                  index,
                  packageItemList.products.length - 1
                )
          )}

          {packageItemList.services.map((service, index) =>
            service.type === "Single"
              ? renderSingleItem(service, "Service")
              : renderComboItem(
                  service.combo,
                  "Service",
                  service,
                  index,
                  packageItemList.services.length - 1
                )
          )}
        </TabPane>

        <TabPane tab="Change History" key="Change History">
          <CustomerDatatable<GiftCardsData>
            columns={columns}
            dataSource={dataSource}
            scroll={{ y: "calc(100vh - 280px)" }}
            showCheckbox={false}
            showPagination={false}
            dynamicTableHeight="auto"
            currentPage={currentPage}
            pageSize={pageSize}
            totalRecords={totalRecords}
            onPageChange={handlePageChange}
          />
        </TabPane>

        <TabPane tab="Applicable Stores" key="Applicable Stores">
          <Datatable<StoreDataProps>
            columns={storeColumns}
            dataSource={storeDataSource}
            scroll={{ y: "calc(100vh - 280px)" }}
            showCheckbox={false}
            showPagination={false}
            dynamicTableHeight="auto"
          />
        </TabPane>
      </Tabs>

      {applicableModal && (
        <ApplicablePackageModel
          visible={applicableModal}
          selectedItem={selectedItem}
          onClose={() => setApplicableModal(false)}
        />
      )}
    </Modal>
  );
};

export default BillingPackageDetailsModal;
