import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Button, Col, Pagination, Row, Select, Space, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import dayjs from "dayjs"; // For date handling
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Datatable from "../../../components/Datatable";
import ColumnsReportsModal from "../../../components/model/ColumnsReportsModal";
import { get } from "../../../services/Apiclient";

const { Title, Text } = Typography;
const { Option } = Select;

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

interface DataSourceItem {
  key: string;
  productcode?: string;
  productname?: string;
  storage_type?: string;
  ref_no?: string;
  quantity?: number;
  unit_type_name?: string;
  expiry_date?: string;
  days_until_expiry?: number;
}

const ExpiryInventoryReport: React.FC = () => {
  const navigate = useNavigate();
  const outletId = localStorage.getItem("outlet_id");
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataSource, setDataSource] = useState<DataSourceItem[]>([]);
  const [storage, setStorage] = useState<any[]>([]);
  const [selectedStorage, setSelectedStorage] = useState<string | null>(null);
  const [outlet, setOutlet] = useState<any[]>([]);
  const [selectedOutlet, setSelectedOutlet] = useState<string | null>(null);
  const [product, setProduct] = useState<any[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [expiry, setExpiry] = useState<string>("30");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const getOutletData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/outlet/dropdown`);
      if (response?.data?.success && response.data.data) {
        setOutlet(response.data.data);
        setSelectedOutlet(outletId || null);
      }
    } catch (error) {
      console.error("Error fetching outlet:", error);
    }
  };

  const getStorageData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/storage/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setStorage(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching storage:", error);
    }
  };

  const getProductData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/product/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setProduct(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching product:", error);
    }
  };

  const getExpiryData = async () => {
    try {
      const offset = currentPage;

      const response = await get(
        `/report/expiryinventory/${selectedProduct || "NULL"}/${
          selectedOutlet || "NULL"
        }/${expiry}/${selectedStorage || "NULL"}/${pageSize}/${offset}`
      );

      if (response?.data?.success && response.data.data) {
        const formattedData = response.data.data.map(
          (item: any, index: number) => ({
            key: index.toString(),
            productcode: item.productcode,
            productname: item.productname,
            storage_type: item.storage_type,
            ref_no: item.ref_no,
            quantity: item.quantity,
            unit_type_name: item.unit_type_name,
            expiry_date: item.expiry_date || "-",
            days_until_expiry: item.days_until_expiry || 0,
          })
        );

        setDataSource(formattedData);
        setTotalRecords(response.data.totalRecords || 0);
      } else {
        setDataSource([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching tips summary:", error);
      setDataSource([]);
      setTotalRecords(0);
    }
  };

  useEffect(() => {
    getOutletData();
    getStorageData();
    getProductData();
  }, []);

  useEffect(() => {
    if (selectedOutlet !== null) {
      getExpiryData();
    }
  }, [selectedOutlet, currentPage, pageSize]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1);
  };

  const handlePageSizeChange = (value: number) => {
    setPageSize(value);
    setCurrentPage(0); // Reset to first page when page size changes
  };

  const handleRunFilter = () => {
    setCurrentPage(0); // Reset to first page when filters are applied
    getExpiryData(); // Call API with selected filters
  };

  // Table columns
  const initialColumns = [
    {
      title: "Product code",
      dataIndex: "productcode",
      key: "productcode",
      width: 150,
    },
    {
      title: "Product name",
      dataIndex: "productname",
      key: "productname",
      width: 150,
    },
    {
      title: "Storage",
      dataIndex: "storage_type",
      key: "storage_type",
      width: 150,
    },
    {
      title: "Batch code",
      dataIndex: "ref_no",
      key: "ref_no",
      width: 150,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
    },
    {
      title: "Unit",
      dataIndex: "unit_type_name",
      key: "unit_type_name",
      width: 150,
    },
    {
      title: "Expiry date",
      dataIndex: "expiry_date",
      key: "expiry_date",
      width: 150,
    },
    {
      title: "Days until expiration",
      dataIndex: "days_until_expiry",
      key: "days_until_expiry",
      width: 150,
    },
  ];

  // Define mandatory columns that cannot be deselected
  const mandatoryColumns = ["productname"];

  // State for columns order
  const [columns, setColumns] = useState(initialColumns);

  // Initialize selectedColumns with all column keys by default
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key)
  );

  // Track the last saved column selection
  const [lastSelectedColumns, setLastSelectedColumns] = useState<string[]>([]);

  // Filter columns based on selectedColumns and maintain order
  const displayedColumns = columns.filter((col) =>
    selectedColumns.includes(col.key)
  );

  const handleColumnsClick = () => {
    setIsModalVisible(true);
  };

  const handleModalSave = (
    selectedCols: string[],
    reorderedColumns: { key: string; title: string }[]
  ) => {
    setSelectedColumns(selectedCols);
    setLastSelectedColumns(selectedCols);
    setColumns(
      reorderedColumns.map((col) => ({
        ...col,
        dataIndex: col.key, // Assuming `dataIndex` can be derived from `key`
        width: 150,
      }))
    );
  };

  return (
    <div style={{ padding: "20px" }}>
      {/* Header */}
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => navigate(-1)}
          >
            <ArrowLeftOutlined
              style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
            />
            <Title level={4} style={{ margin: 0 }}>
              Expiry Inventory Report
            </Title>
          </div>
        </Col>
        <Col>
          <Space>
            {/* <Button style={{ height: 40 }} icon={<ExportOutlined />}>Export as</Button> */}
            <Button
              icon={<UnorderedListOutlined />}
              onClick={handleColumnsClick}
              style={{ height: 40 }}
            >
              Columns
            </Button>
          </Space>
        </Col>
      </Row>

      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#f6f7f9",
          borderRadius: "12px",
          padding: "24px 24px 10px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
        }}
      >
        {/* Filters */}
        <div style={{ marginBottom: "16px", borderRadius: "8px" }}>
          <Row gutter={16} justify="start" align="middle">
            <Col>
              <Select
                value={selectedOutlet === null ? "all" : selectedOutlet}
                onChange={(value) =>
                  setSelectedOutlet(value === "all" ? null : value)
                }
                placeholder="Select outlet"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                <Option value="all">All outlet</Option>
                {outlet.map((outlet) => (
                  <Option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                value={selectedStorage === null ? "all" : selectedStorage}
                onChange={(value) =>
                  setSelectedStorage(value === "all" ? null : value)
                }
                placeholder="Select storage"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                <Option value="all">All storage</Option>
                {storage.map((storage) => (
                  <Option key={storage.id} value={storage.id}>
                    {storage.type}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                value={expiry}
                onChange={(value) => setExpiry(value)}
                placeholder="Select expiry"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear={false}
              >
                <Option value="0">Expired</Option>
                <Option value="7">Expired within 7 days</Option>
                <Option value="30">Expired within 30 days</Option>
                <Option value="90">Expired within 90 days</Option>
                <Option value="180">Expired within 180 days</Option>
                <Option value="1">Expired up to 180 days</Option>
              </Select>
            </Col>
            <Col>
              <Select
                value={selectedProduct === null ? "all" : selectedProduct}
                onChange={(value) =>
                  setSelectedProduct(value === "all" ? null : value)
                }
                placeholder="Select product"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                <Option value="all">All product</Option>
                {product.map((product) => (
                  <Option key={product.id} value={product.id}>
                    {product.productname}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <Space>
                <Button
                  style={{ height: "46px", width: "80px" }}
                  type="primary"
                  onClick={handleRunFilter}
                >
                  Run
                </Button>
              </Space>
            </Col>
          </Row>
        </div>

        {/* Table */}
        <Datatable
          columns={displayedColumns}
          dataSource={dataSource}
          scroll={{ y: "calc(100vh - 260px)" }}
          showCheckbox={false}
          rowSelection={undefined}
          showPagination={false}
          dynamicTableHeight={"calc(100vh - 240px)"}
        />

        {/* Action Buttons and Pagination */}
        <Row
          justify="space-between"
          style={{
            alignItems: "center",
            marginTop: "40px",
            marginBottom: "16px",
          }}
        >
          {/* Left Buttons */}
          <Col>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              style={{ width: 200, height: "46px", borderRadius: "4px" }}
            >
              <Option value={10}>10 entries/page</Option>
              <Option value={20}>20 entries/page</Option>
              <Option value={50}>50 entries/page</Option>
              <Option value={100}>100 entries/page</Option>
            </Select>
          </Col>

          {/* Right Section */}
          {totalRecords >= 0 && (
            <Col>
              <Row justify="end" align="middle" style={{ gap: "16px" }}>
                {/* Total Items Count */}
                <Col>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "rgba(0,0,0,.85)",
                    }}
                  >
                    Total {totalRecords} items
                  </span>
                </Col>

                {/* Pagination */}
                <Col>
                  <Pagination
                    current={currentPage + 1}
                    pageSize={pageSize}
                    total={totalRecords}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    itemRender={(page, type, originalElement) => {
                      if (type === "prev") {
                        const isDisabled = currentPage === 0;
                        return (
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                              color: isDisabled ? "#bbb" : "#2e56f2",
                              pointerEvents: isDisabled ? "none" : "auto",
                            }}
                          >
                            <LeftOutlined />
                            <span>Previous</span>
                          </a>
                        );
                      }
                      if (type === "next") {
                        const isDisabled =
                          currentPage ===
                          Math.ceil(totalRecords / pageSize) - 1;
                        return (
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                              color: isDisabled ? "#bbb" : "#2e56f2",
                              pointerEvents: isDisabled ? "none" : "auto",
                            }}
                          >
                            <span>Next</span>
                            <RightOutlined />
                          </a>
                        );
                      }
                      return originalElement;
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Content>

      <ColumnsReportsModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onSave={handleModalSave}
        availableColumns={columns}
        initialSelectedColumns={selectedColumns}
        lastSelectedColumns={lastSelectedColumns}
        mandatoryColumns={mandatoryColumns}
      />
    </div>
  );
};

export default ExpiryInventoryReport;
