import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Typography,
  Dropdown,
  MenuProps,
  message,
  Modal,
} from "antd";
import {
  ArrowLeftOutlined,
  EditOutlined,
  SortAscendingOutlined,
  PrinterOutlined,
  InfoCircleOutlined,
  HomeOutlined,
} from "@ant-design/icons";
import {
  deleteData,
  get,
  post,
  put,
  uploadImg,
} from "../../services/Apiclient";
import { useNavigate, useParams } from "react-router-dom";
import "../../styles/global.css";
import { Content } from "antd/es/layout/layout";
import "./BillDetails.css";
import SelectServiceStaff from "../../components/model/billing/SelectServiceStaff";
import NotesModal from "../../components/model/billing/NotesModal";
import SelectProductStaff from "../../components/model/billing/SelectProductStaff";
import SignatureModel from "../../components/model/billing/SignatureModel";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import PdfPreview from "../../components/model/billing/PdfPreviewModel";
import { useCurrency } from "../../contexts/CurrencyContext";
import EinvoiceQrModal from "../../components/model/billing/EinvoiceQrModal";
import { Helmet } from "react-helmet";
import GenerateEInvoiceModal from "../../components/model/GenerateEInvoiceModal";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
// import { currencySymbol } from "../../constants/currencySymbol";

const { Title, Text } = Typography;

const OrderCustomerIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_OrderCustomer.png`;
const OrderMemberIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_OrderMember.png`;
const OrderDetail_XM = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_OrderDetail_XM.png`;
const PaymentIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Payment_XJ.png`;
const CamaraIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Camera.png`;
const memberRightsgiftCard = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_MemberRights_GiftCard.png`;
const memberRightsmembership = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_MemberRights_MemberShip.png`;
const memberRightspackage = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_MemberRights_Package.png`;
const memberRightstore = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_MemberRights_Store.png`;

interface checkoutData {
  ref_no: number;
  item_created_at: string;
  created_at_myt: string;
  male_count: string;
  female_count: string;
  customer_name: string;
  customer_lastname: string;
  customer_email: string;
  customer_source_name: string;
  refer_name: string;
  countrycode: string;
  phonenumber: number;
  sub_total: number;
  total_tax: number;
  tips: number;
  final_total: number;
  note: string;
  tip_rate: number;
  tip_value: number;
  tip_payment_method: string;
  bill_status: string;
  certifiedquality: boolean;
}

export default function BillDetails() {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  let { id } = useParams();
  const [checkoutData, setcheckoutData] = useState<checkoutData | null>(null);
  const [serviceData, setServiceData] = useState<any>([]);
  const [debtData, setDebtData] = useState<any>([]);
  const [kivData, setKIVData] = useState<any>([]);
  const [courseData, setCourseData] = useState<any>([]);
  const [productData, setProductData] = useState<any>([]);
  const [cartData, setCartData] = useState<any>([]);
  const [giftcardsData, setGiftcardsData] = useState<any>([]);
  const [packageData, setPackageData] = useState<any>([]);
  const [membershipData, setMembershipData] = useState<any>([]);
  const [taxData, setTaxData] = useState<any>([]);
  const [paymentData, setPaymentData] = useState<any>([]);
  const [visitBasedQty, setVisitBasedQty] = useState(0);
  const [ShowServiceStaffEditModal, setShowServiceStaffEditModal] =
    useState(false);
  const [selectedService, setSelectedService] = useState<any>(null);
  const [ShowProductStaffEditModal, setShowProductStaffEditModal] =
    useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);
  const [notesValue, setNotesValue] = useState("");
  const [showNotesEditModal, setShowNotesEditModal] = useState(false);
  const [showSignatureModel, setShowSignatureModel] = useState(false);
  const [signatureVal, setSignatureVal] = useState("");
  const [selectedImages, setSelectedImages] = useState<any[]>([]);
  const [existingNoteandImageVal, setExistingNoteandImageVal] =
    useState<any>(null);
  const [showPdfModal, setShowPdfModal] = useState(false);
  const [showQrcodeModal, setShowQrCodeModal] = useState(false);
  const [showGenerateEinvoceBill, setShowGenerateEinvoceBill] = useState(false);
  const [visibleEinvoiceSubmitted, setVisibleEinvoiceSubmitted] =
    useState(false);
  const [loadFirst, setloadFirst] = useState(false);
  // const [tipData, setTipData] = useState<any>([]);
  const token = localStorage.getItem("token");

  let invoiceType: string | null = null;

  if (token) {
    try {
      const base64Url = token.split(".")[1]; // Get the payload part
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join("")
      );

      const payload = JSON.parse(jsonPayload);

      invoiceType = payload?.tokendata?.invoicetype || null; // FIXED 🔥
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }

  const fetchCheckoutBillingData = async () => {
    try {
      const response = await get(`/billing/checkout/${id}`);

      if (response?.data?.success) {
        // console.log(response.data.data);
        const checkout = response.data.data;
        setcheckoutData(response.data.data); // Assuming the billing data is in the `billingData` field
        setServiceData(response.data.data.services);
        setDebtData(response.data.data.debt);
        setKIVData(response.data.data.kiv);
        setCourseData(response.data.data.course);
        if (
          response.data.data.services &&
          response.data.data.services.length > 0
        ) {
          const sumItemQty = response.data.data.services
            .filter(
              (service: any) => service.item_payment_method === "Visit-based"
            ) // Filter by payment method
            .reduce(
              (sum: any, service: any) => sum + parseInt(service.item_qty),
              0
            );

          setVisitBasedQty(sumItemQty);
        }
        setCartData(response.data.data.cartData);
        setProductData(response.data.data.products);
        setTaxData(response.data.data.tax);
        setGiftcardsData(response.data.data.giftcards);
        setPackageData(response.data.data.packages);
        setMembershipData(response.data.data.membership);
        // setTipData(response.data.data.tips);
        setPaymentData(response.data.data.payment);
        if (!loadFirst) {
          setShowPdfModal(true);
        }
        setloadFirst(true);

        // Set existing images if available
        if (checkout.note_images) {
          const existingImages = checkout.note_images.map(
            (image: {
              img_id: string;
              image_url: string;
              image_name: string;
            }) => ({
              img_id: image.img_id,
              url: image.image_url, // Store URL for existing images
              isExisting: true, // Flag to distinguish existing images
              name: image.image_name,
            })
          );
          setSelectedImages(existingImages);
        }
        if (checkout.sign) {
          setSignatureVal(checkout.sign);
        }
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  const navigateBilling = () => {
    navigate("/sales/billing");
  };

  const [isVoidModalVisible, setIsVoidModalVisible] = useState(false);
  const [isRebillModalVisible, setIsRebillModalVisible] = useState(false);
  const showVoidModal = () => {
    setIsVoidModalVisible(true);
  };
  const handleCancel = () => {
    setIsVoidModalVisible(false);
  };
  const updateBillingStatus = async () => {
    try {
      let payload = {
        bill_type: "voided",
      };
      await put(`/billing/statuschange/${id}`, payload);
      navigate("/sales");
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  const showRebillModal = () => {
    setIsRebillModalVisible(true);
  };
  const handleRebillCancel = () => {
    setIsRebillModalVisible(false);
  };

  const handleEinvoiceCancel = () => {
    setShowGenerateEinvoceBill(false);
  };

  const updateRebillConfirm = async () => {
    navigate(`/sales/billing/${id}`);
  };

  const updateEinvoice = async () => {
    setShowGenerateEinvoceBill(false);
    try {
      let payload = {};
      await put(`/billing/einvoicestatuschange/${id}`, payload);
      setShowQrCodeModal(true);
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  useEffect(() => {
    fetchCheckoutBillingData();
  }, []);

  const items: MenuProps["items"] = [
    {
      key: "calculation-details",
      label: (
        <div className="orderCalcDetail-dropdown-main">
          <div className="view-title-main">
            <span className="bk-title_ordcal">Calculation Details</span>
          </div>
          <div className="view-container">
            <div className="view-cell-item">
              <div className="view-cash-main">
                <div className="view-pay-main">
                  <div className="display_def_bk-image">
                    <div className="van-image">
                      <img
                        src={PaymentIcon}
                        className="van-image__img"
                        alt="Payment"
                      />
                    </div>
                  </div>
                  <div className="view-label_ordcal">cash</div>
                  <span className="view_sn">
                    {currencySymbol.CURRENCY_SYMBOL}123
                  </span>
                </div>
                <div className="view-list">
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">Turnover：</span>
                    <span className="view_vale">
                      {currencySymbol.CURRENCY_SYMBOL}12
                    </span>
                  </div>
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">
                      Performance：
                    </span>
                    <span className="view_vale">
                      {currencySymbol.CURRENCY_SYMBOL}0
                    </span>
                  </div>
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">
                      Commission：
                    </span>
                    <span className="view_vale">
                      {currencySymbol.CURRENCY_SYMBOL}0
                    </span>
                  </div>
                  <div className="view-item_cal_time">
                    <span className="repaymentDetail_turnover">
                      Calculation time：
                    </span>
                    <span className="view_vale">05/12/2024 03:19:40pm</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ];
  const handleStaffEdit = (item: any) => {
    setSelectedService(item);
    setShowServiceStaffEditModal(true);
  };

  const handleProductEdit = (item: any) => {
    setSelectedProduct(item);
    setShowProductStaffEditModal(true);
  };

  const handleServiceStaffChange = (data: any) => {
    let newStaffArray = data
      .filter((staffItem: any) => staffItem.selectedStaff) // Filter out items where staff is null
      .map((staffItem: any) => ({
        staff_service_id: staffItem.id,
        service_category_id: staffItem.selectedStaff.staffcategory,
        staff_id: staffItem.selectedStaff.id,
        staff_name: staffItem.selectedStaff.name,
        staff_code: staffItem.selectedStaff.staffcode,
        department_id: staffItem.selectedStaff.department_id,
        department_name: staffItem.selectedStaff.department_name,
        item_service_category_name: "service category 2",
        item_staff_service_category_name: staffItem.staffservicetype,
      }));

    const updateServiceData = serviceData.map((item: any) =>
      item.item_unique_id === selectedService.item_unique_id
        ? { ...item, staff: newStaffArray }
        : item
    );
    setServiceData(updateServiceData);
    setShowServiceStaffEditModal(false);
  };

  const handleNoteAdd = (data: any) => {
    setNotesValue(data);
    setShowNotesEditModal(true);
    setcheckoutData((pre: any) => ({
      ...pre,
      note: data,
    }));
  };

  const handleSignatureValue = (data: any) => {
    setSignatureVal(data);
    setShowSignatureModel(true);
    const imageNames =
      selectedImages.length > 0
        ? selectedImages.map((image: any) => image.name)
        : [];
    setExistingNoteandImageVal({
      img_name: imageNames,
      note: checkoutData?.note,
    });
  };

  const handleProductStaffChange = (data: any) => {
    let newStaffArray = data.map((staffItem: any) => ({
      staff_service_id: staffItem.id,
      service_category_id: staffItem.staffcategory,
      staff_id: staffItem.id,
      staff_name: staffItem.name,
      staff_code: staffItem.staffcode,
      department_id: staffItem.department_id,
      department_name: staffItem.department_name,
      staff_percentage: staffItem.percentage,
    }));

    const updatedCartItems = productData.map((item: any) =>
      item.item_unique_id === selectedProduct.item_unique_id
        ? { ...item, staff: newStaffArray }
        : item
    );
    setProductData(updatedCartItems);
    setShowProductStaffEditModal(false);
  };

  const handleImageChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      try {
        const newImages = Array.from(files).slice(0, 5 - selectedImages.length); // Limit to 5
        setSelectedImages((prev) => [...prev, ...newImages].slice(0, 5));

        // Prepare FormData
        const formData = new FormData();
        newImages.forEach((file) => {
          formData.append("file", file);
        });
        const uploadImageUrl = "/billing/uploadimage/billingnote";
        const response = await uploadImg(uploadImageUrl, formData);
        if (response.data.success) {
          // Extract image names from the API response
          const imageNames = response?.data.data.map(
            (item: { image_name: string }) => item.image_name
          );
          // setUpdateImageVal(imageNames);
          handleImageUpdateApi(imageNames);

          // const url = `/billing/signupdate/${id}`;
          // const outletId = localStorage.getItem("outlet_id");
          // const payload = {
          //   ref_no: checkoutData?.ref_no,
          //   outlet_id: outletId,
          //   note_images: imageNames,
          // };
          // const result = await put(url, payload);
          // if (result.status == 200) {
          //   fetchCheckoutBillingData();
          // }
        }
      } catch (error) {
        console.log("Error while updating image", error);
      }
    }
  };
  const [updateImageVal, setUpdateImageVal] = useState<any>([]);
  const handleRemoveImage = async (index: number) => {
    try {
      const response = await deleteData(`/billing/removeimg/${id}/${index}`);
      if (response?.data?.success) {
        fetchCheckoutBillingData();
      } else {
        message.error("Failed to delete work experience record");
      }
    } catch (error) {
      console.error("Error deleting work experience record:", error);
      message.error("Error deleting work experience record");
    }
  };

  const handleImageUpdateApi = async (image: any) => {
    try {
      const url = `/billing/signupdate/${id}`;
      const outletId = localStorage.getItem("outlet_id");

      const payload = {
        ref_no: checkoutData?.ref_no,
        outlet_id: outletId,
        note_images: image,
      };

      const result = await put(url, payload);
      if (result.status === 200) {
        fetchCheckoutBillingData();
      }
    } catch (error) {
      console.log("Error while removing image", error);
    }
  };

  const handleShowEInvoice = () => {
    if (checkoutData?.certifiedquality) {
      setVisibleEinvoiceSubmitted(true);
    } else {
      setShowGenerateEinvoceBill(true);
    }
  };

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Content className="mainContainer">
        {/* Header Section */}
        <Row justify="space-between" align="middle">
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div
                onClick={() => navigate("/sales")}
                style={{ cursor: "pointer" }}
              >
                <ArrowLeftOutlined
                  style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
                />
              </div>
              <h2 style={{ margin: 0 }}>Bill Details</h2>
            </div>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {invoiceType !== "No eInvoice" && (
                <Button
                  icon={<PrinterOutlined />}
                  onClick={() => {
                    if (invoiceType === "QRCode eInvoice") {
                      navigate("/cashiereinvoiceslayout1"); // Replace with your actual route
                    } else {
                      handleShowEInvoice();
                    }
                  }}
                  style={{
                    height: "45px !important",
                    marginRight: "8px",
                    borderColor: "#e0e7fe",
                    color: "#333",
                    borderRadius: "4px",
                    background: "#fff",
                    fontWeight: 700,
                  }}
                >
                  Generate E-Invoice
                </Button>
              )}
              <Button
                icon={<PrinterOutlined />}
                onClick={() => {
                  setShowPdfModal(true);
                }}
                style={{
                  height: "45px !important",
                  marginRight: "8px",
                  borderColor: "#e0e7fe",
                  color: "#333",
                  borderRadius: "4px",
                  background: "#fff",
                  fontWeight: 700,
                }}
              >
                Print
              </Button>
              {checkoutData?.bill_status === "voided" ? (
                <></>
              ) : (
                <Button
                  style={{
                    height: "45px !important",
                    borderRadius: "6px 6px 6px 6px",
                    border: "1px solid #2e56f2",
                    fontWeight: 700,
                    fontSize: "13px",
                    marginRight: "8px",
                    padding: "9px 18px",
                    background: "#fff",
                    color: "#2e56f2",
                    borderColor: "#2e56f2",
                  }}
                  onClick={navigateBilling}
                >
                  Continue Billing
                </Button>
              )}

              {checkoutData?.bill_status === "voided" ? (
                <></>
              ) : (
                <Button
                  style={{
                    height: "45px !important",
                    borderRadius: "6px 6px 6px 6px",
                    border: "1px solid #2e56f2",
                    fontWeight: 700,
                    fontSize: "13px",
                    marginRight: "8px",
                    background: "#fff",
                    color: "#ff971e",
                    borderColor: "#ff971e",
                  }}
                  // onClick={showRebillModal}
                >
                  Rebill
                </Button>
              )}
              {/* <Button className="quick-settings">Quick Settings</Button> */}
              {checkoutData?.bill_status === "voided" ? (
                <></>
              ) : (
                <Button
                  type="primary"
                  // onClick={showModal}
                  style={{
                    height: "45px !important",
                    background: "#fff",
                    borderRadius: "6px 6px 6px 6px",
                    border: "1px solid #2e56f2",
                    fontWeight: 700,
                    fontSize: "13px",
                    color: "#f43b00",
                    borderColor: "#f43b00",
                  }}
                  onClick={showVoidModal}
                >
                  Void
                </Button>
              )}
            </div>
          </Col>
        </Row>

        {/* Content Section */}
        <Content className="billdetail_content">
          <Row>
            <span className="billing_title">Billed to</span>
          </Row>
          <Row gutter={16} className="view-orderBaseInfo">
            <Col span={4} className="key-billNo">
              <span className="orderDetail_title_billId">Ref#: </span>
              <div className="display_def_view-value">
                <span className="spn_refno">{checkoutData?.ref_no}</span>
              </div>

              {checkoutData?.bill_status === "paid" ? (
                <div className="view-tag_pay_paid">PAID</div>
              ) : (
                <></>
              )}
              {checkoutData?.bill_status === "voided" ? (
                <div className="view-tag_pay_voided">VOIDED</div>
              ) : (
                <></>
              )}
            </Col>
            <Col span={4} className="key-updateUser">
              <span className="orderDetail_title_cashier">Cashier:</span>
              <div className="view-value_cashier">
                <span>admin</span>
              </div>
            </Col>
            <Col span={4} className="key-billDate">
              <span className="orderDetail_title_billDate">
                Accounting date:{" "}
              </span>
              <div className="view-value_date">
                <span>{checkoutData?.item_created_at}</span>
              </div>
            </Col>
            <Col span={4} className="key-payTimeText">
              <span className="orderDetail_title_payTime">Payment time:</span>
              <div className="view-value_time">
                <span>{checkoutData?.created_at_myt}</span>
              </div>
            </Col>
            {checkoutData?.refer_name && (
              <Col span={4} className="key-payTimeText">
                <span className="orderDetail_title_payTime">Referrer :</span>
                <div className="view-value_time">
                  <span>{checkoutData?.refer_name}</span>
                </div>
              </Col>
            )}
          </Row>
          <Row gutter={16} className="view-customerInfo">
            <Col
              span={5}
              className="view-orderDetailCustomer"
              style={{
                background: `transparent url(${OrderCustomerIcon}) 50% / 100% 100% no-repeat`,
              }}
            >
              <div className="display_def">
                <span className="orderDetail_text_count">Customer count: </span>
                <div className="view-value_gender">
                  <div className="view-item_male">
                    <span className="orderDetail_text_male">Male: </span>
                    <span>{checkoutData?.male_count}</span>
                  </div>
                  <div className="view-item_female">
                    <span className="orderDetail_text_female">Female:</span>
                    <span>{checkoutData?.female_count}</span>
                  </div>
                </div>
              </div>
              <div className="display_def_cussource">
                <span className="orderDetail_text_clientSource">
                  Customer source:
                </span>
                <div className="view-value_src">
                  <span className="">
                    {checkoutData?.customer_source_name
                      ? checkoutData?.customer_source_name
                      : "Walk-in"}
                  </span>
                </div>
              </div>
            </Col>
            <Col
              span={18}
              className="view-memberDetail"
              style={{
                background: `transparent url(${OrderMemberIcon}) 50% / 100% 100% no-repeat`,
              }}
            >
              <div className="display_def_view-info">
                <div className="view-avatar">
                  <img
                    src="https://static-global.boka.vc/mase/def/Def_Empty_Avatar_Other.png"
                    style={{
                      objectFit: "cover",
                      display: "block",
                      width: "40px",
                      height: "40px",
                      fontSize: 36,
                      verticalAlign: "middle",
                      borderStyle: "none",
                    }}
                  />
                </div>
                <div className="display_def_view-text">
                  <div className="labelType-flex">
                    <span className="memberInfo_label_name">Name:</span>
                    <span className="view-value_cus">
                      {checkoutData?.customer_name ||
                      checkoutData?.customer_lastname
                        ? `${checkoutData.customer_name} ${checkoutData.customer_lastname}`
                        : "Walk-in customer"}
                    </span>
                  </div>
                  <div className="view-flex_dtl">
                    <div className="labelType-flex_view-phone">
                      <span className="memberInfo_label_phone">
                        Phone number:
                      </span>
                      <span className="view-value_cus">
                        {checkoutData?.countrycode
                          ? `+${checkoutData?.countrycode} `
                          : ""}
                        {checkoutData?.phonenumber}
                      </span>
                    </div>
                    <div className="labelType-flex_view-phone">
                      <span className="memberInfo_email">E-mail:</span>
                      <span className="view-value_cus">
                        {checkoutData?.customer_email}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* Current Privilege */}
          {/* <div className="display_current_privilege_section">
          <Row className="view-title">
            <span className="bk-title">Current Privilege</span>
          </Row>

          <div className="current_privilege_view-memberRights">
            <div className="current_privilege_display-def">
              <div className="current_privilege_def">
                <div className="current_privilege_bk-image">
                  <div className="current_privilege_van_img">
                    <img
                      src={memberRightstore}
                      alt="MemberRightstore"
                      className="current_privilege_van-image__img"
                    ></img>
                  </div>
                </div>
                <span>Stored value balance:</span>
                <span className="current_privilege_color-def">$ 101</span>
                <div className="current_privilege_view-storeBalance">
                  <span className="current_privilege-orderDetail_text_memberStoreBalance">
                    (Stored / Bonus balance: $100 / $1)
                  </span>
                </div>
              </div>
            </div>
            <div className="current_privilege_line"></div>
            <div className="membership_card_display-def">
              <div className="membership_card_def">
                <div className="membership_card_bk-image">
                  <div className="membership_card_van-image">
                    <img
                      src={memberRightsmembership}
                      alt="MemberRightsmembership"
                      className="membership_card_van-image__img"
                    ></img>
                  </div>
                </div>
                <span className="membership_card_orderDetail_text">
                  Membership card:
                </span>
                <span className="current_privilege_color-def">1</span>
              </div>
            </div>
            <div className="current_privilege_line"></div>
            <div className="membership_card_display-def">
              <div className="membership_card_def">
                <div className="membership_card_bk-image">
                  <div className="membership_card_van-image">
                    <img
                      src={memberRightspackage}
                      alt="memberRightspackage"
                      className="membership_card_van-image__img"
                    ></img>
                  </div>
                </div>
                <span className="membership_card_orderDetail_text">
                  Package:
                </span>
                <span className="current_privilege_color-def">2</span>
              </div>
            </div>
            <div className="current_privilege_line"></div>
            <div className="membership_card_display-def">
              <div className="membership_card_def">
                <div className="membership_card_bk-image">
                  <div className="membership_card_van-image">
                    <img
                      src={memberRightsgiftCard}
                      alt="MemberRightsgiftCard"
                      className="membership_card_van-image__img"
                    ></img>
                  </div>
                </div>
                <span className="membership_card_orderDetail_text">
                  Voucher:
                </span>
                <span className="current_privilege_color-def">2</span>
              </div>
            </div>
          </div>
        </div> */}

          {/* Sales Details */}
          <div className="view-saleDetails">
            <Row className="view-title">
              <span className="bk-title">Sales Details</span>
            </Row>

            {courseData.length > 0 ? (
              <div>
                <Row>
                  <div className="view-subTitle_project">
                    <span className="vertical-line"></span>
                    {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                    <span className="bk-title_svr">Course</span>
                    {/* </div> */}
                  </div>
                </Row>
                {courseData &&
                  courseData.length > 0 &&
                  courseData.map((item: any, index: any) => (
                    <div className="view-orderDetailSaleItem">
                      <div className="view-goodsInfo">
                        <div className="view-name">
                          <span className="bk_title_servie">
                            {item.item_name}{" "}
                          </span>
                          <span className="span-count">{item.item_qty}x</span>
                        </div>
                      </div>
                      <div className="view-saleEmp">
                        <div className="view-saleEmp-main">
                          <span>Service by:</span>
                          <div className="view-saleEmp-info">
                            {item.staff &&
                              item.staff.length > 0 &&
                              item.staff.map((prd_staf: any, index: any) => (
                                <div className="view-saleEmp-item">
                                  <div className="view-name_typ">
                                    {prd_staf.staff_name}
                                  </div>
                                  <div className="view-serviceCodeText">
                                    <div className="view-tag">
                                      {prd_staf.department_name ||
                                        "undesignated"}
                                    </div>
                                  </div>
                                  <div className="view-achievement">
                                    <span>
                                      (Performance:{" "}
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {Number(
                                        (parseFloat(prd_staf.staff_percentage) /
                                          100) *
                                          parseFloat(item.item_price)
                                      ).toFixed(2)}
                                      )
                                    </span>
                                    {/* <div className="">
                                    <InfoCircleOutlined className="infocircle" />
                                  </div> */}
                                  </div>
                                </div>
                              ))}
                          </div>
                          {/* <div
                          className="btn-editStaff"
                          onClick={() => {
                            handleProductEdit(item);
                          }}
                        >
                          <EditOutlined className="homeouli" />
                        </div> */}
                        </div>
                      </div>
                      <div className="view_bdr"></div>
                      <div className="view-payInfo">
                        <div className="view-payment">
                          <div className="view-amount"></div>
                          <div className="view-payAmount"></div>
                          <div className="view-payText">
                            {item.item_qty}{" "}
                            {item.item_qty > 1
                              ? "deductions (course)"
                              : "deduction (course)"}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <></>
            )}

            {kivData.length > 0 ? (
              <div>
                <Row>
                  <div className="view-subTitle_project">
                    <span className="vertical-line"></span>
                    {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                    <span className="bk-title_svr">KIV</span>
                    {/* </div> */}
                  </div>
                </Row>
                {kivData &&
                  kivData.length > 0 &&
                  kivData.map((item: any, index: any) => (
                    <div className="view-orderDetailSaleItem">
                      <div className="view-goodsInfo">
                        <div className="view-name">
                          <span className="bk_title_servie">
                            {item.item_name}{" "}
                          </span>
                          <span className="span-count">{item.item_qty}x</span>
                        </div>
                      </div>
                      <div className="view-saleEmp">
                        <div className="view-saleEmp-main">
                          <span>Sold by:</span>
                          <div className="view-saleEmp-info">
                            {item.staff &&
                              item.staff.length > 0 &&
                              item.staff.map((prd_staf: any, index: any) => (
                                <div className="view-saleEmp-item">
                                  <div className="view-name_typ">
                                    {prd_staf.staff_name}
                                  </div>
                                  <div className="view-serviceCodeText">
                                    <div className="view-tag">
                                      {prd_staf.department_name ||
                                        "undesignated"}
                                    </div>
                                  </div>
                                  <div className="view-achievement">
                                    <span>
                                      (Performance:{" "}
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {Number(
                                        (parseFloat(prd_staf.staff_percentage) /
                                          100) *
                                          parseFloat(item.item_price)
                                      ).toFixed(2)}
                                      )
                                    </span>
                                    {/* <div className="">
                                    <InfoCircleOutlined className="infocircle" />
                                  </div> */}
                                  </div>
                                </div>
                              ))}
                          </div>
                          {/* <div
                          className="btn-editStaff"
                          onClick={() => {
                            handleProductEdit(item);
                          }}
                        >
                          <EditOutlined className="homeouli" />
                        </div> */}
                        </div>
                      </div>
                      <div className="view_bdr"></div>
                      <div className="view-payInfo">
                        <div className="view-payment">
                          <div className="view-amount"></div>
                          <div className="view-payAmount"></div>
                          <div className="view-payText">
                            {item.item_qty}{" "}
                            {item.item_qty > 1
                              ? "deductions (kiv)"
                              : "deduction (kiv)"}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <></>
            )}

            {debtData.length > 0 ? (
              <div>
                <Row>
                  <div className="view-subTitle_project">
                    <span className="vertical-line"></span>
                    {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                    <span className="bk-title_svr">Debt</span>
                    {/* </div> */}
                  </div>
                </Row>
                {debtData &&
                  debtData.length > 0 &&
                  debtData.map((item: any, index: any) => (
                    <div className="view-orderDetailSaleItem">
                      <div className="view-goodsInfo">
                        <div className="view-name">
                          <span className="bk_title_servie">
                            {item.item_name}{" "}
                          </span>
                          <span className="span-count">{item.item_qty}x</span>
                        </div>
                      </div>
                      <div className="view-saleEmp">
                        <div className="view-saleEmp-main">
                          <span>Details:</span>
                          <div className="view-saleEmp-info">
                            {item.debtdetails &&
                              item.debtdetails.length > 0 &&
                              item.debtdetails.map((staf: any, index: any) => (
                                <div
                                  className="view-saleEmp-item"
                                  style={{ width: "100%" }}
                                  key={index}
                                >
                                  <div
                                    className="view-name_typ"
                                    style={{ width: "100%" }}
                                  >
                                    {staf.debt_order_date} -{staf.debt_ref_no} -
                                    {staf.debt_item_name} -
                                    {currencySymbol.CURRENCY_SYMBOL}{" "}
                                    {parseFloat(
                                      staf.debt_payoff_amount
                                    ).toFixed(2)}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="view_bdr"></div>
                      <div className="view-payInfo">
                        <div className="view-payment">
                          <div className="view-amount">
                            <div className="view-tip">
                              <span className="">Total:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          <div className="view-payAmount">
                            <div className="view-tip">
                              <span className="">Paid:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          <div className="view-payText">
                            <div className="display_def_bk-image">
                              <div className="van-image">
                                <div className="view-sortName">
                                  {item.item_payment_method?.charAt(0)}
                                </div>
                              </div>
                            </div>
                            {item.item_payment_method}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <></>
            )}

            {/* Service */}
            {serviceData.length > 0 ? (
              <div>
                <Row>
                  <div className="view-subTitle_project">
                    <span className="vertical-line"></span>
                    {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                    <span className="bk-title_svr">Service</span>
                    {/* </div> */}
                  </div>
                </Row>
                {serviceData &&
                  serviceData.length > 0 &&
                  serviceData.map((item: any, index: any) => (
                    <div className="view-orderDetailSaleItem">
                      <div className="view-goodsInfo">
                        <div className="view-name">
                          <span className="bk_title_servie">
                            {item.item_name}{" "}
                          </span>
                          <span className="span-count">{item.item_qty}x</span>
                        </div>
                        <div className="view-standPrice">
                          <span className="lang-standPrice">
                            Standard unit price
                          </span>
                          {item.splits &&
                          item.splits.length > 0 &&
                          item.item_payment_method == "Visit-based" ? (
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span>
                                {(
                                  (parseFloat(item.item_price) -
                                    parseFloat(
                                      item.splits[0].split_item_price
                                    )) /
                                  parseFloat(item?.item_qty)
                                ).toFixed(2)}{" "}
                                {/* Ensures 2 decimal places */}
                              </span>
                            </span>
                          ) : (
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span>
                                {parseFloat(item?.item_unit_price).toFixed(2)}
                              </span>{" "}
                              {/* Display item_unit_price with 2 decimal places */}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="view-saleEmp">
                        <div className="view-saleEmp-main">
                          <span>Served by:</span>
                          <div className="view-saleEmp-info">
                            {item.staff &&
                              item.staff.length > 0 &&
                              item.staff.map((staf: any, index: any) => (
                                <div className="view-saleEmp-item" key={index}>
                                  <div className="view-name_typ">
                                    {staf.staff_name} (
                                    {staf.item_staff_service_category_name})
                                  </div>
                                  <div className="view-serviceCodeText">
                                    <div className="view-tag">
                                      {staf.department_name || "undesignated"}
                                    </div>
                                  </div>
                                  <div className="view-achievement">
                                    <span>
                                      (Commission:{" "}
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {staf.staff_percentage !== null
                                        ? Number(
                                            (parseFloat(staf.staff_percentage) /
                                              100) *
                                              parseFloat(item.item_price)
                                          ).toFixed(2)
                                        : "0.00"}
                                      )
                                    </span>
                                    {/* <Dropdown
                                    menu={{ items }}
                                    placement="topRight"
                                    arrow
                                  >
                                    <div className="">
                                      <InfoCircleOutlined className="infocircle" />
                                    </div>
                                  </Dropdown> */}
                                  </div>
                                </div>
                              ))}
                          </div>
                          {/* <div
                          className="btn-editStaff"
                          onClick={() => {
                            handleStaffEdit(item);
                          }}
                        >
                          <EditOutlined className="homeouli" />
                        </div> */}
                        </div>
                      </div>
                      <div className="view_bdr"></div>
                      <div className="view-payInfo">
                        <div className="view-payment">
                          <div className="view-amount">
                            <div className="view-tip">
                              <span className="">Total:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          <div className="view-payAmount">
                            <div className="view-tip">
                              <span className="">Paid:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          {item.splits && item.splits.length == 0 && (
                            <div className="view-payText">
                              <div className="display_def_bk-image">
                                <div className="van-image">
                                  <div className="view-sortName">
                                    {item.item_payment_method?.charAt(0)}
                                  </div>
                                </div>
                              </div>
                              {item.item_payment_method}
                            </div>
                          )}
                          {item.splits && item.splits.length > 0 && (
                            <>
                              {item.item_membership_discount > 0 &&
                                item.item_payment_method == "Discount" && (
                                  <>
                                    <div className="view-payText">
                                      <div className="display_def_bk-image">
                                        <div className="van-image">
                                          <div className="view-sortName">
                                            {item.item_payment_method?.charAt(
                                              0
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {item.item_payment_method}
                                      {" : "}
                                      {parseFloat(
                                        item.item_membership_discount
                                      ).toFixed(2)}{" "}
                                      % off
                                    </div>
                                    <br />
                                  </>
                                )}
                              {item.splits &&
                                item.splits.length == 1 &&
                                item.item_payment_method == "Visit-based" && (
                                  <>
                                    <div className="view-payText">
                                      <div className="display_def_bk-image">
                                        <div className="van-image">
                                          <div className="view-sortName">
                                            {item.item_payment_method?.charAt(
                                              0
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {item.item_payment_method}
                                      {" : "}
                                      {item.item_qty} Deduction
                                    </div>
                                  </>
                                )}
                              {item.splits.map(
                                (split: any, splitIndex: number) => (
                                  <>
                                    <div
                                      className="view-payText"
                                      key={splitIndex}
                                    >
                                      <div className="display_def_bk-image">
                                        <div className="van-image">
                                          <div className="view-sortName">
                                            {split.split_item_payment_method?.charAt(
                                              0
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {split.split_item_payment_method}:{" "}
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {parseFloat(
                                        split.split_item_price
                                      ).toFixed(2)}
                                    </div>
                                    <br />
                                  </>
                                )
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <></>
            )}

            {/* cartData */}
            {cartData.length > 0 ? (
              <div>
                <Row>
                  <div className="view-subTitle_project">
                    <span className="vertical-line"></span>
                    {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                    <span className="bk-title_svr">Online sales</span>
                    {/* </div> */}
                  </div>
                </Row>
                {cartData &&
                  cartData.length > 0 &&
                  cartData.map((item: any, index: any) => (
                    <div className="view-orderDetailSaleItem">
                      <div className="view-goodsInfo">
                        <div className="view-name">
                          <span className="bk_title_servie">
                            {item.item_name}
                          </span>
                          <span className="span-count">{item.item_qty}x</span>
                        </div>
                        <div className="view-standPrice">
                          <span className="lang-standPrice">
                            Standard unit price
                          </span>
                          <span className="bk-price_color-def">
                            <span className="span-unit">
                              {currencySymbol.CURRENCY_SYMBOL}
                            </span>
                            <span>
                              {parseFloat(item.item_unit_price).toFixed(2)}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="view_bdr"></div>
                      <div className="view-payInfo">
                        <div className="view-payment">
                          <div className="view-amount">
                            <div className="view-tip">
                              <span className="">Total:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          <div className="view-payAmount">
                            <div className="view-tip">
                              <span className="">Paid:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          {item.splits && item.splits.length == 0 && (
                            <div className="view-payText">
                              <div className="display_def_bk-image">
                                <div className="van-image">
                                  {item.item_payment_method == "Cash" ? (
                                    <img
                                      src={PaymentIcon}
                                      className="van-image__img"
                                      alt="Payment"
                                    />
                                  ) : (
                                    <div className="view-sortName">
                                      {item.item_payment_method?.charAt(0)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {item.item_payment_method}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <></>
            )}

            {/* product */}
            {productData.length > 0 ? (
              <div>
                <Row>
                  <div className="view-subTitle_project">
                    <span className="vertical-line"></span>
                    {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                    <span className="bk-title_svr">Product</span>
                    {/* </div> */}
                  </div>
                </Row>
                {productData &&
                  productData.length > 0 &&
                  productData.map((item: any, index: any) => (
                    <div className="view-orderDetailSaleItem">
                      <div className="view-goodsInfo">
                        <div className="view-name">
                          <span className="bk_title_servie">
                            {item.item_name}
                          </span>
                          <span className="span-count">{item.item_qty}x</span>
                        </div>
                        <div className="view-standPrice">
                          <span className="lang-standPrice">
                            Standard unit price
                          </span>
                          <span className="bk-price_color-def">
                            <span className="span-unit">
                              {currencySymbol.CURRENCY_SYMBOL}
                            </span>
                            <span>
                              {parseFloat(item.item_unit_price).toFixed(2)}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="view-saleEmp">
                        <div className="view-saleEmp-main">
                          <span>Sold by:</span>
                          <div className="view-saleEmp-info">
                            {item.staff &&
                              item.staff.length > 0 &&
                              item.staff.map((prd_staf: any, index: any) => (
                                <div className="view-saleEmp-item">
                                  <div className="view-name_typ">
                                    {prd_staf.staff_name}
                                  </div>
                                  <div className="view-serviceCodeText">
                                    <div className="view-tag">
                                      {prd_staf.department_name ||
                                        "undesignated"}
                                    </div>
                                  </div>
                                  <div className="view-achievement">
                                    <span>
                                      (Performance:{" "}
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {Number(
                                        (parseFloat(prd_staf.staff_percentage) /
                                          100) *
                                          parseFloat(item.item_price)
                                      ).toFixed(2)}
                                      )
                                    </span>
                                    {/* <div className="">
                                    <InfoCircleOutlined className="infocircle" />
                                  </div> */}
                                  </div>
                                </div>
                              ))}
                          </div>
                          {/* <div
                          className="btn-editStaff"
                          onClick={() => {
                            handleProductEdit(item);
                          }}
                        >
                          <EditOutlined className="homeouli" />
                        </div> */}
                        </div>
                      </div>
                      <div className="view_bdr"></div>
                      <div className="view-payInfo">
                        <div className="view-payment">
                          <div className="view-amount">
                            <div className="view-tip">
                              <span className="">Total:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          <div className="view-payAmount">
                            <div className="view-tip">
                              <span className="">Paid:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          {item.splits && item.splits.length == 0 && (
                            <div className="view-payText">
                              <div className="display_def_bk-image">
                                <div className="van-image">
                                  {item.item_payment_method == "Cash" ? (
                                    <img
                                      src={PaymentIcon}
                                      className="van-image__img"
                                      alt="Payment"
                                    />
                                  ) : (
                                    <div className="view-sortName">
                                      {item.item_payment_method?.charAt(0)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {item.item_payment_method}
                            </div>
                          )}
                          {item.splits && item.splits.length > 0 && (
                            <>
                              {item.item_membership_discount > 0 &&
                                item.item_payment_method == "Discount" && (
                                  <>
                                    <div className="view-payText">
                                      <div className="display_def_bk-image">
                                        <div className="van-image">
                                          <div className="view-sortName">
                                            {item.item_payment_method?.charAt(
                                              0
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {item.item_payment_method}
                                      {" : "}
                                      {parseFloat(
                                        item.item_membership_discount
                                      ).toFixed(2)}{" "}
                                      % off
                                    </div>
                                    <br />
                                  </>
                                )}
                              {item.splits.map(
                                (split: any, splitIndex: number) => (
                                  <>
                                    <div
                                      className="view-payText"
                                      key={splitIndex}
                                    >
                                      <div className="display_def_bk-image">
                                        <div className="van-image">
                                          <div className="view-sortName">
                                            {split.split_item_payment_method?.charAt(
                                              0
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {split.split_item_payment_method}:{" "}
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {parseFloat(
                                        split.split_item_price
                                      ).toFixed(2)}
                                    </div>
                                    <br />
                                  </>
                                )
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <></>
            )}

            {/* giftcards */}
            {giftcardsData.length > 0 ? (
              <div>
                <Row>
                  <div className="view-subTitle_project">
                    <span className="vertical-line"></span>
                    {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                    <span className="bk-title_svr">Gift card</span>
                    {/* </div> */}
                  </div>
                </Row>
                {giftcardsData &&
                  giftcardsData.length > 0 &&
                  giftcardsData.map((item: any, index: any) => (
                    <div className="view-orderDetailSaleItem">
                      <div className="view-goodsInfo">
                        <div className="view-name">
                          <span className="bk_title_servie">
                            {item.item_name}
                          </span>
                          <span className="span-count">{item.item_qty}x</span>
                        </div>
                        <div className="view-standPrice">
                          <span className="lang-standPrice">
                            Standard unit price
                          </span>
                          <span className="bk-price_color-def">
                            <span className="span-unit">
                              {currencySymbol.CURRENCY_SYMBOL}
                            </span>
                            <span>
                              {parseFloat(item?.item_unit_price).toFixed(2)}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="view-saleEmp">
                        <div className="view-saleEmp-main">
                          <span>Served by:</span>
                          <div className="view-saleEmp-info">
                            {item.staff &&
                              item.staff.length > 0 &&
                              item.staff.map((gft_staf: any, index: any) => (
                                <div className="view-saleEmp-item">
                                  <div className="view-name_typ">
                                    {gft_staf.staff_name}
                                  </div>
                                  <div className="view-serviceCodeText">
                                    <div className="view-tag">
                                      {gft_staf.department_name ||
                                        "undesignated"}
                                    </div>
                                  </div>
                                  <div className="view-achievement">
                                    <span>
                                      (Performance:{" "}
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {Number(
                                        (parseFloat(gft_staf.staff_percentage) /
                                          100) *
                                          parseFloat(item.item_price)
                                      ).toFixed(2)}
                                      )
                                    </span>
                                    {/* <div className="">
                                    <InfoCircleOutlined className="infocircle" />
                                  </div> */}
                                  </div>
                                </div>
                              ))}
                          </div>
                          {/* <div className="btn-editStaff" onClick={() => {}}>
                          <EditOutlined className="homeouli" />
                        </div> */}
                        </div>

                        {/* {item.item_note !== "123" ? <div>Note:{item.item_note}</div> : null} */}
                      </div>
                      <div className="view_bdr"></div>
                      <div className="view-payInfo">
                        <div className="view-payment">
                          <div className="view-amount">
                            <div className="view-tip">
                              <span className="">Total:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          <div className="view-payAmount">
                            <div className="view-tip">
                              <span className="">Paid:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          {item.splits && item.splits.length == 0 && (
                            <div className="view-payText">
                              <div className="display_def_bk-image">
                                <div className="van-image">
                                  {item.item_payment_method == "Cash" ? (
                                    <img
                                      src={PaymentIcon}
                                      className="van-image__img"
                                      alt="Payment"
                                    />
                                  ) : (
                                    <div className="view-sortName">
                                      {item.item_payment_method?.charAt(0)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {item.item_payment_method}
                            </div>
                          )}
                          {item.splits && item.splits.length > 0 && (
                            <>
                              {item.splits.map(
                                (split: any, splitIndex: number) => (
                                  <>
                                    <div
                                      className="view-payText"
                                      key={splitIndex}
                                    >
                                      <div className="display_def_bk-image">
                                        <div className="van-image">
                                          <div className="view-sortName">
                                            {split.split_item_payment_method?.charAt(
                                              0
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {split.split_item_payment_method}:{" "}
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {parseFloat(
                                        split.split_item_price
                                      ).toFixed(2)}
                                    </div>
                                    <br />
                                  </>
                                )
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <></>
            )}

            {/* Packages */}
            {packageData.length > 0 ? (
              <div>
                <Row>
                  <div className="view-subTitle_project">
                    <span className="vertical-line"></span>
                    {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                    <span className="bk-title_svr">Package</span>
                    {/* </div> */}
                  </div>
                </Row>
                {packageData &&
                  packageData.length > 0 &&
                  packageData.map((item: any, index: any) => (
                    <div className="view-orderDetailSaleItem">
                      <div className="view-goodsInfo">
                        <div className="view-name">
                          <span className="bk_title_servie">
                            {item.item_name}
                          </span>
                          <span className="span-count">{item.item_qty}x</span>
                        </div>
                        <div className="view-standPrice">
                          <span className="lang-standPrice">
                            Standard unit price
                          </span>
                          <span className="bk-price_color-def">
                            <span className="span-unit">
                              {currencySymbol.CURRENCY_SYMBOL}
                            </span>
                            <span>
                              {parseFloat(item?.item_unit_price).toFixed(2)}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="view-saleEmp">
                        <div className="view-saleEmp-main">
                          <span>Served by:</span>
                          <div className="view-saleEmp-info">
                            {item.staff &&
                              item.staff.length > 0 &&
                              item.staff.map((gft_staf: any, index: any) => (
                                <div className="view-saleEmp-item">
                                  <div className="view-name_typ">
                                    {gft_staf.staff_name}
                                  </div>
                                  <div className="view-serviceCodeText">
                                    <div className="view-tag">
                                      {gft_staf.department_name ||
                                        "undesignated"}
                                    </div>
                                  </div>
                                  <div className="view-achievement">
                                    <span>
                                      (Performance:{" "}
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {Number(
                                        (parseFloat(gft_staf.staff_percentage) /
                                          100) *
                                          parseFloat(item.item_price)
                                      ).toFixed(2)}
                                      )
                                    </span>
                                    {/* <div className="">
                                    <InfoCircleOutlined className="infocircle" />
                                  </div> */}
                                  </div>
                                </div>
                              ))}
                          </div>
                          {/* <div className="btn-editStaff" onClick={() => {}}>
                          <EditOutlined className="homeouli" />
                        </div> */}
                        </div>

                        {/* {item.item_note !== "123" ? <div>Note:{item.item_note}</div> : null} */}
                      </div>
                      <div className="view_bdr"></div>
                      <div className="view-payInfo">
                        <div className="view-payment">
                          <div className="view-amount">
                            <div className="view-tip">
                              <span className="">Total:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          <div className="view-payAmount">
                            <div className="view-tip">
                              <span className="">Paid:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          <div className="view-payText">
                            <div className="display_def_bk-image">
                              <div className="van-image">
                                {item.item_payment_method == "Cash" ? (
                                  <img
                                    src={PaymentIcon}
                                    className="van-image__img"
                                    alt="Payment"
                                  />
                                ) : (
                                  <div className="view-sortName">
                                    {item.item_payment_method?.charAt(0)}
                                  </div>
                                )}
                              </div>
                            </div>
                            {item.item_payment_method}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <></>
            )}
            {membershipData.length > 0 ? (
              <div>
                <Row>
                  <div className="view-subTitle_project">
                    <span className="vertical-line"></span>
                    {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                    <span className="bk-title_svr">Membership</span>
                    {/* </div> */}
                  </div>
                </Row>
                {membershipData &&
                  membershipData.length > 0 &&
                  membershipData.map((item: any, index: any) => (
                    <div className="view-orderDetailSaleItem">
                      <div className="view-goodsInfo">
                        <div className="view-name">
                          <span className="bk_title_servie">
                            {item.item_name}
                          </span>
                          <span className="span-count">{item.item_qty}x</span>
                        </div>
                        <div className="view-standPrice">
                          <span className="lang-standPrice">
                            Standard unit price
                          </span>
                          <span className="bk-price_color-def">
                            <span className="span-unit">
                              {currencySymbol.CURRENCY_SYMBOL}
                            </span>
                            <span>
                              {parseFloat(item?.item_unit_price).toFixed(2)}
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="view-saleEmp">
                        <div className="view-saleEmp-main">
                          <span>Served by:</span>
                          <div className="view-saleEmp-info">
                            {item.staff &&
                              item.staff.length > 0 &&
                              item.staff.map((gft_staf: any, index: any) => (
                                <div className="view-saleEmp-item">
                                  <div className="view-name_typ">
                                    {gft_staf.staff_name}
                                  </div>
                                  <div className="view-serviceCodeText">
                                    <div className="view-tag">
                                      {gft_staf.department_name ||
                                        "undesignated"}
                                    </div>
                                  </div>
                                  <div className="view-achievement">
                                    <span>
                                      (Performance:{" "}
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {Number(
                                        (parseFloat(gft_staf.staff_percentage) /
                                          100) *
                                          parseFloat(item.item_price)
                                      ).toFixed(2)}
                                      )
                                    </span>
                                    {/* <div className="">
                                    <InfoCircleOutlined className="infocircle" />
                                  </div> */}
                                  </div>
                                </div>
                              ))}
                          </div>
                          {/* <div className="btn-editStaff" onClick={() => {}}>
                          <EditOutlined className="homeouli" />
                        </div> */}
                        </div>

                        {/* {item.item_note !== "123" ? <div>Note:{item.item_note}</div> : null} */}
                      </div>
                      <div className="view_bdr"></div>
                      <div className="view-payInfo">
                        <div className="view-payment">
                          <div className="view-amount">
                            <div className="view-tip">
                              <span className="">Total:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          <div className="view-payAmount">
                            <div className="view-tip">
                              <span className="">Paid:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.item_price).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          {item.splits && item.splits.length == 0 && (
                            <div className="view-payText">
                              <div className="display_def_bk-image">
                                <div className="van-image">
                                  {item.item_payment_method == "Cash" ? (
                                    <img
                                      src={PaymentIcon}
                                      className="van-image__img"
                                      alt="Payment"
                                    />
                                  ) : (
                                    <div className="view-sortName">
                                      {item.item_payment_method?.charAt(0)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {item.item_payment_method}
                            </div>
                          )}
                          {item.splits && item.splits.length > 0 && (
                            <>
                              {item.splits.map(
                                (split: any, splitIndex: number) => (
                                  <>
                                    <div
                                      className="view-payText"
                                      key={splitIndex}
                                    >
                                      <div className="display_def_bk-image">
                                        <div className="van-image">
                                          <div className="view-sortName">
                                            {split.split_item_payment_method?.charAt(
                                              0
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      {split.split_item_payment_method}:{" "}
                                      {currencySymbol.CURRENCY_SYMBOL}
                                      {parseFloat(
                                        split.split_item_price
                                      ).toFixed(2)}
                                    </div>
                                    <br />
                                  </>
                                )
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <></>
            )}
            {/* Taxes */}
            {taxData.length > 0 ? (
              <div>
                <Row>
                  <div className="view-subTitle_project">
                    <span className="vertical-line"></span>
                    {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                    <span className="bk-title_svr">Taxes</span>
                    {/* </div> */}
                  </div>
                </Row>
                {taxData &&
                  taxData.length > 0 &&
                  taxData.map((item: any, index: any) => (
                    <div className="view-orderDetailSaleItem">
                      <div className="view-goodsInfo">
                        <div className="view-name">
                          <span className="bk_title_servie">
                            {item.tax_name}{" "}
                          </span>
                        </div>
                      </div>
                      <div className="view_bdr"></div>
                      <div className="view-payInfo">
                        <div className="view-payment">
                          <div className="view-amount">
                            <div className="view-tip">
                              <span className="">Total:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.tax_amount).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          <div className="view-payAmount">
                            <div className="view-tip">
                              <span className="">Paid:</span>
                            </div>
                            <span className="bk-price_color-def">
                              <span className="span-unit">
                                {currencySymbol.CURRENCY_SYMBOL}
                              </span>
                              <span className="span-value">
                                {parseFloat(item.tax_amount).toFixed(2)}
                              </span>
                            </span>
                          </div>
                          <div className="view-payText">
                            <div className="display_def_bk-image">
                              <div className="van-image">
                                {item.item_payment_method == "Cash" ? (
                                  <img
                                    src={PaymentIcon}
                                    className="van-image__img"
                                    alt="Payment"
                                  />
                                ) : (
                                  <div className="view-sortName">
                                    {item.tax_payment_method?.charAt(0)}
                                  </div>
                                )}
                              </div>
                            </div>
                            {item.tax_payment_method}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <></>
            )}
            {/* Tips */}

            {checkoutData?.tip_value && (
              <>
                <div>
                  <Row>
                    <div className="view-subTitle_project">
                      <span className="vertical-line"></span>
                      {/* <div
                  className="service"
                  style={{
                    background: `transparent url(${OrderDetail_XM}) 16px / 16px no-repeat;`,
                  }}
                > */}
                      <span className="bk-title_svr">Tips</span>
                      {/* </div> */}
                    </div>
                  </Row>

                  <div className="view-orderDetailSaleItem">
                    <div className="view-goodsInfo">
                      <div className="view-name">
                        <span className="bk_title_servie">
                          Tips Tips{checkoutData?.tip_rate}%{" "}
                        </span>
                      </div>
                    </div>
                    <div className="view_bdr"></div>
                    <div className="view-payInfo">
                      <div className="view-payment">
                        <div className="view-amount">
                          <div className="view-tip">
                            <span className="">Total:</span>
                          </div>
                          <span className="bk-price_color-def">
                            <span className="span-unit">
                              {currencySymbol.CURRENCY_SYMBOL}
                            </span>
                            <span className="span-value">
                              {Number(checkoutData?.tip_value).toFixed(2)}
                            </span>
                          </span>
                        </div>
                        <div className="view-payAmount">
                          <div className="view-tip">
                            <span className="">Paid:</span>
                          </div>
                          <span className="bk-price_color-def">
                            <span className="span-unit">
                              {currencySymbol.CURRENCY_SYMBOL}
                            </span>
                            <span className="span-value">
                              {Number(checkoutData?.tip_value).toFixed(2)}
                            </span>
                          </span>
                        </div>
                        <div className="view-payText">
                          <div className="display_def_bk-image">
                            <div className="van-image">
                              {checkoutData?.tip_payment_method == "Cash" ? (
                                <img
                                  src={PaymentIcon}
                                  className="van-image__img"
                                  alt="Payment"
                                />
                              ) : (
                                <div className="view-sortName">
                                  {/* {checkoutData?.tip_payment_method.charAt(0) || "P"} */}
                                  P
                                </div>
                              )}
                            </div>
                          </div>
                          {checkoutData?.tip_payment_method}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {/* Payment Summary */}
          <div className="view-paymentSummary">
            <Row className="view-title">
              <span className="bk-title">Payment Summary</span>
            </Row>
            <div className="view-orderDetailPaymentSummary">
              <div className="view-payInformation">
                <>
                  {kivData &&
                    kivData.length > 0 &&
                    kivData.map((item: any, index: any) => (
                      <div className="view-payItem_list">
                        <div className="display_def_bk-image"></div>
                        <div className="view-name_pay">KIV</div>
                        <div className="view-payAmt">
                          <span className="bk-price_color-def">
                            {item.item_qty}{" "}
                            {item.item_qty > 1 ? "deductions" : "deduction"}
                          </span>
                        </div>
                      </div>
                    ))}
                  {courseData &&
                    courseData.length > 0 &&
                    courseData.map((item: any, index: any) => (
                      <div className="view-payItem_list">
                        <div className="display_def_bk-image"></div>
                        <div className="view-name_pay">Course</div>
                        <div className="view-payAmt">
                          <span className="bk-price_color-def">
                            {item.item_qty}{" "}
                            {item.item_qty > 1 ? "deductions" : "deduction"}
                          </span>
                        </div>
                      </div>
                    ))}
                  {paymentData &&
                    paymentData.length > 0 &&
                    paymentData.map((item: any, index: any) => (
                      <div className="view-payItem_list">
                        <div className="display_def_bk-image">
                          <div className="van-image">
                            {item.payment_name == "Cash" ? (
                              <img
                                src={PaymentIcon}
                                className="van-image__img"
                                alt="Payment"
                              />
                            ) : (
                              <div className="view-sortName">
                                {item.payment_name?.charAt(0)}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="view-name_pay">{item.payment_name}</div>
                        {item.payment_name == "Visit-based" ? (
                          <>
                            <div className="view-payAmt">
                              <span className="bk-price_color-def">
                                {visitBasedQty} Deduction
                              </span>
                            </div>
                          </>
                        ) : (
                          <div className="view-payAmt">
                            <span className="bk-price_color-def">
                              {currencySymbol.CURRENCY_SYMBOL}
                              {Number(item.payment_amount).toFixed(2)}
                            </span>
                          </div>
                        )}
                      </div>
                    ))}
                </>
                {/* <div className="view-payItem_list">
                <div className="view-sortName">B</div>
                <div className="view-name_pay">Bank card</div>
                <div className="view-payAmt">
                  <span className="bk-price_color-def">$196</span>
                </div>
              </div> */}
              </div>
              <div className="view-rightBox">
                <div className="view-amountInfo">
                  <div className="view-subtotal">
                    <div className="view-left">
                      <span>Subtotal</span>
                    </div>
                    <div className="view-value">
                      {currencySymbol.CURRENCY_SYMBOL}
                      {Number(checkoutData?.sub_total).toFixed(2)}
                    </div>
                  </div>
                  <div className="view-taxes">
                    <div className="view-left">
                      <span>Taxes</span>
                    </div>
                    <div className="view-value">
                      <span>
                        {currencySymbol.CURRENCY_SYMBOL}
                        {Number(checkoutData?.total_tax).toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div className="view-serviceCharge">
                    <div className="view-left">
                      <span>Service charge</span>
                    </div>
                    <div className="view-value">
                      <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
                    </div>
                  </div>
                  <div className="view-total">
                    <div className="view-left">
                      <span>Total</span>
                    </div>
                    <div className="view-value_total">
                      <span>
                        {currencySymbol.CURRENCY_SYMBOL}
                        {Number(checkoutData?.final_total).toFixed(2)}
                      </span>
                    </div>
                  </div>
                  <div className="view-tips">
                    <div className="view-left">
                      <span>Tips</span>
                    </div>
                    <div className="view-value">
                      <span>
                        {Number(checkoutData?.tips).toFixed(2) || "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Notes & Signature */}
          <div className="view_group_note_seg_img">
            <div className="view_flex_note_seg">
              <div className="view-notes">
                <div className="view-title_note">
                  <span className="bk-title_note">Notes</span>
                  <div
                    className="view-btn-edt"
                    onClick={() => handleNoteAdd(checkoutData?.note)}
                  >
                    <EditOutlined className="homeouli" />
                  </div>
                </div>
                <div className="view-remark">{checkoutData?.note || "-"}</div>
              </div>
              <div className="view-signature">
                <div className="view-title_signature">
                  <span className="bk-title_note">Signature</span>
                </div>
                <div
                  className="view-img_sig"
                  onClick={() =>
                    !signatureVal && handleSignatureValue(signatureVal)
                  }
                >
                  <div className="bk-image_sig">
                    <div className="van_img_sig">
                      {signatureVal ? (
                        <img
                          src={signatureVal}
                          alt="Signature"
                          style={{ width: "100%", height: "auto" }}
                        />
                      ) : (
                        <span>Click here to add your signature</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="view-remarkImgs">
            <div className="bk-upload-remarkImgs">
              <div className="bk-image_img">
                <div className="van-image_up">
                  <img
                    src={CamaraIcon}
                    className="van-image__img"
                    alt="Payment"
                  />
                </div>
              </div>
            </div>
          </div> */}
            <div className="view-remarkImgs">
              {selectedImages &&
                selectedImages.map((image, index) => (
                  <div className="bk-upload-remarkImgs">
                    <div className="image-preview">
                      <img
                        // src={image}
                        // src={URL.createObjectURL(image)}
                        src={
                          image.isExisting
                            ? image.url
                            : URL.createObjectURL(image)
                        } // Check if the image is existing or new
                        alt={`Selected ${index}`}
                        className="van-image__img"
                      />
                      <button
                        className="remove-img-button"
                        onClick={() => handleRemoveImage(image.img_id)}
                      >
                        ✕
                      </button>
                    </div>
                  </div>
                ))}
              {selectedImages.length < 5 && (
                <>
                  <div
                    className="bk-upload-remarkImgs"
                    onClick={() =>
                      document.getElementById("fileInput")?.click()
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <div className="bk-image_img">
                      <div className="van-image_up">
                        <img
                          src={CamaraIcon}
                          className="van-image__img"
                          alt="Upload"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <input
                id="fileInput"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                multiple
                onChange={handleImageChange}
              />
            </div>
          </div>
          {/* <div className="orderCalcDetail-dropdown-main">
          <div className="view-title-main">
            <span className="bk-title_ordcal">Calculation Details</span>
          </div>
          <div className="view-container">
            <div className="view-cell-item">
              <div className="view-cash-main">
                <div className="view-pay-main">
                  <div className="display_def_bk-image">
                    <div className="van-image">
                      <img
                        src={PaymentIcon}
                        className="van-image__img"
                        alt="Payment"
                      />
                    </div>
                  </div>
                  <div className="view-label_ordcal">cash</div>
                  <span className="view_sn">$123</span>
                </div>
                <div className="view-list">
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">Turnover：</span>
                    <span className="view_vale">$12</span>
                  </div>
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">
                      Performance：
                    </span>
                    <span className="view_vale">$0</span>
                  </div>
                  <div className="view-item_in">
                    <span className="repaymentDetail_turnover">
                      Commission：
                    </span>
                    <span className="view_vale">$0</span>
                  </div>
                  <div className="view-item_cal_time">
                    <span className="repaymentDetail_turnover">
                      Calculation time：
                    </span>
                    <span className="view_vale">05/12/2024 03:19:40pm</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        </Content>
        {ShowServiceStaffEditModal && (
          <SelectServiceStaff
            visible={ShowServiceStaffEditModal}
            servicename={selectedService ? selectedService.item_name : ""}
            onClose={() => {
              setShowServiceStaffEditModal(false);
            }}
            staffArray={selectedService ? selectedService.staff : []}
            onSelect={handleServiceStaffChange}
          />
        )}

        {ShowProductStaffEditModal && (
          <>
            <SelectProductStaff
              visible={ShowProductStaffEditModal}
              servicename={selectedProduct ? selectedProduct.item_name : ""}
              onClose={() => {
                setShowProductStaffEditModal(false);
              }}
              staffArray={selectedProduct ? selectedProduct.staff : []}
              onSelect={handleProductStaffChange}
            />
          </>
        )}

        {showNotesEditModal && (
          <>
            <NotesModal
              visible={showNotesEditModal}
              onClose={() => {
                setShowNotesEditModal(false);
              }}
              onConfirm={handleNoteAdd}
              value={notesValue}
              refId={checkoutData?.ref_no}
              selectedImages={selectedImages}
            />
          </>
        )}

        <SignatureModel
          visible={showSignatureModel}
          onClose={() => {
            setShowSignatureModel(false);
          }}
          onConfirm={handleSignatureValue}
          value={signatureVal}
          refId={checkoutData?.ref_no}
          existingVal={existingNoteandImageVal}
        />

        <ConfirmDeleteModal
          visible={isVoidModalVisible}
          onCancel={handleCancel}
          onConfirm={updateBillingStatus}
          message="Are you sure you want to void this bill?"
        />

        <ConfirmDeleteModal
          visible={isRebillModalVisible}
          onCancel={handleRebillCancel}
          onConfirm={updateRebillConfirm}
          message="Are you sure you want to re-bill this bill?"
        />

        {invoiceType === "Bill to eInvoice Directly" &&
          checkoutData?.certifiedquality == false && (
            <GenerateEInvoiceModal
              visible={showGenerateEinvoceBill}
              onCancel={handleEinvoiceCancel}
              onConfirm={updateEinvoice}
              message={`Are you sure to generate eInvoice for bill number ${checkoutData?.ref_no}?`}
            />
          )}

        {invoiceType === "Manually eInvoice" &&
          checkoutData?.certifiedquality == false && (
            <ConfirmDeleteModal
              visible={showGenerateEinvoceBill}
              onCancel={handleEinvoiceCancel}
              onConfirm={updateEinvoice}
              message={`Are you sure to genetate einvoice for bill number ${checkoutData?.ref_no}?`}
            />
          )}

        {checkoutData?.certifiedquality == true && (
          <Modal
            title={null}
            visible={visibleEinvoiceSubmitted}
            onCancel={() => {
              setVisibleEinvoiceSubmitted(false);
            }}
            footer={null}
            centered
            width={370}
            bodyStyle={{ textAlign: "center" }}
            maskClosable={false}
            closable={true}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                padding: "20px",
              }}
            >
              <p className="modal-message">This bill been submitted.</p>
            </div>
          </Modal>
        )}

        <PdfPreview
          visible={showPdfModal}
          onClose={() => {
            setShowPdfModal(false);
          }}
          responseData={checkoutData}
        />
        <EinvoiceQrModal
          visible={showQrcodeModal}
          billid={id}
          onClose={() => {
            setShowQrCodeModal(false);
          }}
        ></EinvoiceQrModal>
      </Content>
    </>
  );
}
