import { Button, Col, Form, Input, message, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import "../../pages/membership/Membership.css";
import { post } from "../../services/Apiclient";
import { useParams } from "react-router-dom";

interface MembershipModelProps {
  visible: boolean;
  setVisible: (status: boolean) => void;
  setIsGenerating: (status: boolean) => void;
  setApkStatus: (status: string | null) => void;
}

const GenerateAPKModel: React.FC<MembershipModelProps> = ({
  visible,
  setVisible,
  setIsGenerating,
  setApkStatus,
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [isGenerating, setGenerating] = useState(false);
  const [apkDownloadUrl, setApkDownloadUrl] = useState<string | null>(null);

  useEffect(() => {
    if (visible) {
      setGenerating(false);
      setApkDownloadUrl(null);
    }
  }, [visible]);

  const handleGenerateAPK = async () => {
    try {
      const values = await form.validateFields();
      const { appName } = values;

      setGenerating(true);
      setIsGenerating(true);
      setApkDownloadUrl(null);
      setApkStatus("processing");

      const requestBody = {
        websiteUrl: `${window.location.origin}/app/merchant/details/${id}`,
        projectPath: "/home/sui-test/tempapk/webviewapp",
        merchantId: id,
        appName,
      };

      const response = await post("/generate-apk", requestBody);

      if (response.data.jobId) {
        message.success(response.data.message);
        const existingJobs = JSON.parse(
          localStorage.getItem("apkJobs") || "[]"
        );

        const updatedJobs = [
          ...existingJobs.filter((job: any) => job.merchantId !== id), // Remove old entry if exists
          { merchantId: id, jobId: response.data.jobId, status: "processing" },
        ];

        localStorage.setItem("apkJobs", JSON.stringify(updatedJobs));
        setGenerating(true);
        setVisible(false);
      } else {
        message.error(response.data.message || "Failed to generate APK");
        setGenerating(false);
        setIsGenerating(false);
      }
    } catch (error) {
      message.error("Error generating APK");
      setGenerating(false);
      setIsGenerating(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setIsGenerating(false);
  };

  return (
    <Modal
      title="Add APK Details"
      visible={visible}
      onCancel={handleCancel}
      footer={[
        <Button key="cancel" onClick={handleCancel} disabled={loading}>
          Cancel
        </Button>,
        isGenerating ? (
          <Button
            key="generating"
            type="primary"
            disabled
            style={{ color: "red" }}
          >
            In Progress APK...
          </Button>
        ) : apkDownloadUrl ? (
          <Button
            key="download"
            type="primary"
            style={{ backgroundColor: "green", borderColor: "green" }}
          >
            <a href={apkDownloadUrl} download style={{ color: "#fff" }}>
              Download APK
            </a>
          </Button>
        ) : (
          <Button
            key="generate"
            type="primary"
            onClick={handleGenerateAPK}
            loading={loading}
          >
            Generate APK
          </Button>
        ),
      ]}
      width={500}
    >
      {!apkDownloadUrl && (
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={16}>
              <Form.Item
                label="App Name"
                name="appName"
                rules={[{ required: true, message: "App Name is required" }]}
              >
                <Input placeholder="Enter App Name" disabled={loading} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      )}
    </Modal>
  );
};

export default GenerateAPKModel;
