import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  FormInstance,
  Input,
  Layout,
  Menu,
  Modal,
  Row,
  Select,
  Switch,
  Table,
  Tooltip,
  Typography,
  Upload,
  message,
} from "antd";
import React, { useEffect, useState } from "react";

import {
  ArrowLeftOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  PictureOutlined,
  PlusOutlined,
  RightOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import EditGalleryModel from "../../components/model/staff/gallery/EditGalleryModel";
import GalleryModel from "../../components/model/staff/gallery/GalleryModel";
import EditLoginPasswordModel from "../../components/model/staff/setting/EditLoginPasswordModel";
import ManagerAuthorizationModel from "../../components/model/staff/setting/ManagerAuthorizationModel";
import RefundConfirmationModel from "../../components/model/staff/setting/RefundConfirmationModel";
import CommissionPlanModal from "../../components/model/Staffing/CommissionPlanModal";
import PerformancePlanModel from "../../components/model/Staffing/PerformancePlanModel";
import { deleteData, get, post, put } from "../../services/Apiclient";
import CryptoJS from "crypto-js";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const StaffIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_Avatar_EmpM.png`;
const { TextArea } = Input;
const { Title, Text } = Typography;

const { Header, Sider, Content } = Layout;
const { Option } = Select;
function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero
  const day = String(date.getDate()).padStart(2, "0"); // Add leading zero
  return `${year}-${month}-${day}`;
}
interface AddTrainingRecordModalProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (values: any) => void;
  form: FormInstance;
  isEditRecord: boolean;
}
interface AddDispatchModalProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (data: any) => void;
}
interface EditDispatchModalProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (data: any) => void;
  form: FormInstance;
  isEditRecord: boolean;
}
interface StaffData {
  id: string;
  name: string;
  username: string;
  email: string;
  countrycode: string;
  phone_number: string;
  outlet_name: string;
  staffid: string;
  staffcode: number;
  staffnumber: string;
  lastname: string;
  gender: string;
  department: string;
  designation: string;
  title: string;
  hr_rates: number;
  fixed_salary: number;
  staff_assign_id: string;
  iscommision: boolean;
  isfixed: boolean;
  ishourly: boolean;
  profile_pic: string;
  profilepicurl: string;
  preffered_name: string;
  dob: string;
  userid: string;
  upload_id_front: string;
  upload_id_back: string;
  profile_desc: string;
  alternate_number: number;
  emergency_contact: string;
  altername_address: string;
  alternate_email: string;
  start_date: string;
  end_date: string | null;
  educational_bg: string;
  emp_status: string;
  address: string;
}
interface StaffProfileProps {
  staffData: StaffData | null;
}
const AddDispatchModal: React.FC<AddDispatchModalProps> = ({
  visible,
  onCancel,
  onSave,
}) => {
  const { id: staffid } = useParams<{ id: string }>();
  // const [form] = Form.useForm();
  const [designationOptions, setDesignationOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [performancePlans, setPerformancePlans] = useState([]);
  const [selectedCommission, setSelectedCommission] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [outletData, setOutletData] = useState<any[]>([]);
  const [selectedoutletValue, setSelectedoutletValue] = useState(
    localStorage.getItem("outlet_id") || null
  );
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalVisible(true);
  };

  const fetchPerformancePlans = async () => {
    const page = 1;
    const limit = 2;
    const sortField = "plancode";
    const sortOrder = "DESC";
    const staff = "Staff A";

    try {
      // Construct the URL with dynamic parameters
      const response = await get(
        `/title/performance/list/${page}/${limit}/${sortField}/${sortOrder}/${staff}`
      );
      console.log(response.data.data, "response.data.data");

      if (response?.data?.success) {
        setPerformancePlans(response.data.data);
      } else {
        message.error("Failed to fetch performance plans.");
      }
    } catch (error) {
      console.error("Error fetching performance plans:", error);
      message.error("Error fetching performance plans.");
    }
  };

  const fetchDesignations = async () => {
    try {
      const response = await get(
        `/designation/dropdown/${localStorage.getItem("outlet_id")}`
      );
      if (response?.data?.success) {
        setDesignationOptions(response.data.data);
      } else {
        message.error("Failed to fetch designations.");
      }
    } catch (error) {
      console.error("Error fetching designations:", error);
      message.error("Error fetching designations.");
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await get(
        `/department/dropdown/${localStorage.getItem("outlet_id")}`
      );
      if (response?.data?.success) {
        setDepartmentOptions(response.data.data);
      } else {
        message.error("Failed to fetch departments.");
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
      message.error("Error fetching departments.");
    }
  };

  const fetchTitleOptions = async () => {
    try {
      const response = await get(
        `/titletype/dropdown/${localStorage.getItem("outlet_id")}`
      ); // Replace with your API endpoint
      const data = await response;
      if (response.data.success) {
        setTitleOptions(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch title options:", error);
    }
  };

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setOutletData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    if (visible) {
      fetchDesignations();
      fetchDepartments();
      fetchTitleOptions();
      fetchPerformancePlans();
      getOutletDropdown();
      form.setFieldsValue({
        startTime: moment(),
      });
    }
  }, [visible]);

  // const handleSave = () => {
  //   form.validateFields().then((values) => {
  //     onSave(values);
  //     form.resetFields();
  //   });
  // };

  const handleSave = async () => {
    try {
      // Validate form fields
      const formData = await form.validateFields();

      // Construct payload with form data and selected performance and commission plans
      const outletId = localStorage.getItem("outlet_id");
      const payload = {
        staffid: staffid,
        sendingstore: formData.sendingStore,
        staffcode: formData.staffCode,
        receivingstore: formData.receivingStore,
        staffnumber: formData.assignedStaffNumber,
        starttime: formData.startTime.format("DD/MM/YYYY hh:mma"),
        endtime: formData.endTime.format("DD/MM/YYYY hh:mma"),
        department: formData.department,
        designation: formData.designation,
        title: formData.title,
        shift: formData.shift,
        outlet_id: outletId,
        performanceplan: performancePlans,
        commissionplan: selectedCommission,
        note: formData.note,
      };

      // Make the API call using the common post function
      const response = await post("/staffing/addstaffing", payload);

      if (response?.data?.success) {
        message.success("Dispatch saved successfully");
        onSave(payload); // Pass payload to the onSave callback if needed
        form.resetFields();
        onCancel();
      } else {
        message.error("Failed to save dispatch.");
      }
    } catch (error) {
      // If validation fails, this catch block will be triggered, and no API call is made
      console.error("Validation failed:", error);
      message.error("Please fill all required fields correctly.");
    }
  };

  const [isPerformanceModalVisible, setIsPerformanceModalVisible] =
    useState(false);
  // Static data for Performance Plan
  const [selectedPerformnce, setSelectedPerformnce] = useState<any[]>([]);
  const showPerformanceModal = () => {
    setIsPerformanceModalVisible(true);
  };
  const handlePerformanceCancel = () => {
    setIsPerformanceModalVisible(false);
  };
  const handlePerformanceSave = (selectedData: any[]) => {
    setSelectedPerformnce(selectedData); // Handle saving the selected performance plans
    setIsPerformanceModalVisible(false); // Close the modal after save
    // Update the form field with the selected performance plan name
    if (selectedData && selectedData.length > 0) {
      form.setFieldsValue({
        performancePlan: selectedData.map((plan) => plan.planname).join(", "),
      });
    }
  };

  const handleCommissionSave = (selectedData: any[]) => {
    setSelectedCommission(selectedData);
    setIsModalVisible(false);

    // Update the form field with the selected commission plan name
    form.setFieldsValue({
      commissionPlan: selectedData.map((plan) => plan.planname).join(", "), // Display the name of the selected plan
    });
  };

  return (
    <Modal
      title="Add Dispatch"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
      centered
      width={700}
    >
      <div
        style={{ maxHeight: "60vh", overflowY: "auto", paddingRight: "16px" }}
      >
        {" "}
        {/* Scrollable content container */}
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            sendingStore: "BeautyComfort",
            staffCode: "123456", // Replace with the predefined staff code
            startTime: moment(),
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Sending Store"
                name="sendingStore"
                rules={[
                  { required: true, message: "Please select a sending store" },
                ]}
              >
                <Select placeholder="Please select" disabled>
                  <Option value="BeautyComfort">BeautyComfort</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Staff Code"
                name="staffCode"
                rules={[{ required: true, message: "Please enter staff code" }]}
              >
                <Input placeholder="fgh" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Receiving Store"
                name="receivingStore"
                rules={[
                  {
                    required: true,
                    message: "Please select a receiving store",
                  },
                ]}
              >
                {/* <Select placeholder="Please select">
                  <Option value="Store1">Store1</Option>
                  <Option value="Store2">Store2</Option>
                </Select> */}
                <Select
                  placeholder="Please Select"
                  className="dropdown"
                  style={{ alignItems: "center" }}
                  value={selectedoutletValue}
                  onSelect={(value) => setSelectedoutletValue(value)}
                  onClear={() => setSelectedoutletValue(null)}
                  allowClear
                >
                  {outletData.map((outlet: any) => (
                    <Option
                      key={outlet.id}
                      value={outlet.id}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {outlet.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Assigned Staff Number"
                name="assignedStaffNumber"
                rules={[
                  { required: true, message: "Please enter staff number" },
                ]}
              >
                <Input allowClear placeholder="Please enter" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Start Time"
                name="startTime"
                rules={[
                  { required: true, message: "Please select start time" },
                ]}
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  style={{ width: "100%", height: "39px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="End Time"
                name="endTime"
                rules={[{ required: true, message: "Please select end time" }]}
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  style={{ width: "100%", height: "39px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Department"
                name="department"
                rules={[
                  { required: true, message: "Please select department" },
                ]}
              >
                <Select placeholder="Please select">
                  {departmentOptions.map((option: any) => (
                    <Option key={option.id} value={option.id}>
                      {option.departmenttype}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Designation"
                name="designation"
                rules={[
                  { required: true, message: "Please select designation" },
                ]}
              >
                <Select placeholder="Please select">
                  {designationOptions.map((option: any) => (
                    <Option key={option.id} value={option.id}>
                      {option.designationtype}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "Please select title" }]}
              >
                <Select placeholder="Please select">
                  {titleOptions.map((option: any) => (
                    <Option key={option.id} value={option.id}>
                      {option.titletype}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Shift" name="shift">
                <Select placeholder="Please select">
                  <Option value="Day">Day</Option>
                  <Option value="Evening">Evening</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Performance Plan" name="performancePlan">
                {/* <Select
                  placeholder="Please select"
                  onClick={showPerformanceModal}
                  suffixIcon={<RightOutlined onClick={showPerformanceModal} />}
                ></Select> */}
                <Input
                  placeholder="Please select"
                  style={{ cursor: "pointer" }}
                  className="billing-input"
                  onClick={showPerformanceModal}
                  suffix={<RightOutlined onClick={showPerformanceModal} />}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Commission Plan" name="commissionPlan">
                {/* <Select
                  placeholder="Please select"
                  onClick={showModal}
                  suffixIcon={<RightOutlined onClick={showModal} />}
                ></Select> */}
                <Input
                  placeholder="Please select"
                  style={{ cursor: "pointer" }}
                  className="billing-input"
                  onClick={showModal}
                  suffix={<RightOutlined onClick={showModal} />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Note" name="note">
                <TextArea
                  rows={6}
                  placeholder="Please enter"
                  style={{ width: "100%", resize: "none" }}
                  autoSize={{ minRows: 6, maxRows: 12 }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <PerformancePlanModel
        title="Select performance plan"
        visible={isPerformanceModalVisible}
        onCancel={handlePerformanceCancel}
        onSave={handlePerformanceSave} // Handle saving selected plans
        selectedProducts={selectedPerformnce} // Pass selected plans as data
        footer={null}
        // Pass static data to PerformancePlanModel
        staticData={performancePlans}
      />
      <CommissionPlanModal
        title="Select Commission Plan"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        // onSave={(selectedData) => {
        //   setSelectedCommission(selectedData);
        //   setIsModalVisible(false);
        //   console.log("Selected Data:", selectedData);
        // }}
        onSave={handleCommissionSave}
      />
    </Modal>
  );
};
const EditDispatchModal: React.FC<EditDispatchModalProps> = ({
  visible,
  onCancel,
  onSave,
  form,
  isEditRecord,
}) => {
  const { id: staffid } = useParams<{ id: string }>();
  // const [form] = Form.useForm();
  const [designationOptions, setDesignationOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [performancePlans, setPerformancePlans] = useState([]);
  const [selectedCommission, setSelectedCommission] = useState<any[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [outletData, setOutletData] = useState<any[]>([]);
  const [selectedoutletValue, setSelectedoutletValue] = useState(
    localStorage.getItem("outlet_id") || null
  );
  // const [form] = Form.useForm();

  const handleSave = () => {
    form.validateFields().then((values) => {
      onSave(values);
    });
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const fetchPerformancePlans = async () => {
    const page = 1;
    const limit = 2;
    const sortField = "plancode";
    const sortOrder = "DESC";
    const staff = "Staff A";

    try {
      // Construct the URL with dynamic parameters
      const response = await get(
        `/title/performance/list/${page}/${limit}/${sortField}/${sortOrder}/${staff}`
      );
      console.log(response.data.data, "response.data.data");

      if (response?.data?.success) {
        setPerformancePlans(response.data.data);
      } else {
        message.error("Failed to fetch performance plans.");
      }
    } catch (error) {
      console.error("Error fetching performance plans:", error);
      message.error("Error fetching performance plans.");
    }
  };

  const fetchDesignations = async () => {
    try {
      const response = await get(
        `/designation/dropdown/${localStorage.getItem("outlet_id")}`
      );
      if (response?.data?.success) {
        setDesignationOptions(response.data.data);
      } else {
        message.error("Failed to fetch designations.");
      }
    } catch (error) {
      console.error("Error fetching designations:", error);
      message.error("Error fetching designations.");
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await get(
        `/department/dropdown/${localStorage.getItem("outlet_id")}`
      );
      if (response?.data?.success) {
        setDepartmentOptions(response.data.data);
      } else {
        message.error("Failed to fetch departments.");
      }
    } catch (error) {
      console.error("Error fetching departments:", error);
      message.error("Error fetching departments.");
    }
  };

  const fetchTitleOptions = async () => {
    try {
      const response = await get(
        `/titletype/dropdown/${localStorage.getItem("outlet_id")}`
      ); // Replace with your API endpoint
      const data = await response;
      if (response.data.success) {
        setTitleOptions(response.data.data);
      }
    } catch (error) {
      console.error("Failed to fetch title options:", error);
    }
  };

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setOutletData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    if (visible) {
      fetchDesignations();
      fetchDepartments();
      fetchTitleOptions();
      fetchPerformancePlans();
      getOutletDropdown();
    }
  }, [visible]);

  const [isPerformanceModalVisible, setIsPerformanceModalVisible] =
    useState(false);
  // Static data for Performance Plan
  const [selectedPerformnce, setSelectedPerformnce] = useState<any[]>([]);
  const showPerformanceModal = () => {
    setIsPerformanceModalVisible(true);
  };
  const handlePerformanceCancel = () => {
    setIsPerformanceModalVisible(false);
  };
  const handlePerformanceSave = (selectedData: any[]) => {
    setSelectedPerformnce(selectedData); // Handle saving the selected performance plans
    setIsPerformanceModalVisible(false); // Close the modal after save
    // Update the form field with the selected performance plan name
    if (selectedData && selectedData.length > 0) {
      form.setFieldsValue({
        performancePlan: selectedData.map((plan) => plan.planname).join(", "),
      });
    }
  };

  const handleCommissionSave = (selectedData: any[]) => {
    setSelectedCommission(selectedData);
    setIsModalVisible(false);

    // Update the form field with the selected commission plan name
    form.setFieldsValue({
      commissionPlan: selectedData.map((plan) => plan.planname).join(", "), // Display the name of the selected plan
    });
  };

  return (
    <Modal
      title="Edit Dispatch"
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
      centered
      width={700}
    >
      <div
        style={{ maxHeight: "60vh", overflowY: "auto", paddingRight: "16px" }}
      >
        {" "}
        {/* Scrollable content container */}
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            sendingStore: "BeautyComfort",
            staffCode: "123456", // Replace with the predefined staff code
            // startTime: moment(),
          }}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Sending Store"
                name="sendingStore"
                rules={[
                  { required: true, message: "Please select a sending store" },
                ]}
              >
                <Select placeholder="Please select" disabled>
                  <Option value="BeautyComfort">BeautyComfort</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Staff Code"
                name="staffCode"
                rules={[{ required: true, message: "Please enter staff code" }]}
              >
                <Input placeholder="fgh" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Receiving Store"
                name="receivingStore"
                rules={[
                  {
                    required: true,
                    message: "Please select a receiving store",
                  },
                ]}
              >
                {/* <Select placeholder="Please select">
                  <Option value="Store1">Store1</Option>
                  <Option value="Store2">Store2</Option>
                </Select> */}
                <Select
                  placeholder="Please Select"
                  className="dropdown"
                  style={{ alignItems: "center" }}
                  value={selectedoutletValue}
                  onSelect={(value) => setSelectedoutletValue(value)}
                  onClear={() => setSelectedoutletValue(null)}
                  allowClear
                >
                  {outletData.map((outlet: any) => (
                    <Option
                      key={outlet.id}
                      value={outlet.id}
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {outlet.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Assigned Staff Number"
                name="assignedStaffNumber"
                rules={[
                  { required: true, message: "Please enter staff number" },
                ]}
              >
                <Input allowClear placeholder="Please enter" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Start Time"
                name="startTime"
                rules={[
                  { required: true, message: "Please select start time" },
                ]}
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  style={{ width: "100%", height: "39px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="End Time"
                name="endTime"
                rules={[{ required: true, message: "Please select end time" }]}
              >
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm"
                  style={{ width: "100%", height: "39px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Department"
                name="department"
                rules={[
                  { required: true, message: "Please select department" },
                ]}
              >
                <Select placeholder="Please select">
                  {departmentOptions.map((option: any) => (
                    <Option key={option.id} value={option.id}>
                      {option.departmenttype}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Designation"
                name="designation"
                rules={[
                  { required: true, message: "Please select designation" },
                ]}
              >
                <Select placeholder="Please select">
                  {designationOptions.map((option: any) => (
                    <Option key={option.id} value={option.id}>
                      {option.designationtype}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Title"
                name="title"
                rules={[{ required: true, message: "Please select title" }]}
              >
                <Select placeholder="Please select">
                  {titleOptions.map((option: any) => (
                    <Option key={option.id} value={option.id}>
                      {option.titletype}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item label="Shift" name="shift">
                <Select placeholder="Please select">
                  <Option value="Day">Day</Option>
                  <Option value="Evening">Evening</Option>
                </Select>
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item label="Performance Plan" name="performancePlan">
                <Input
                  placeholder="Please select"
                  style={{ cursor: "pointer" }}
                  className="billing-input"
                  onClick={showPerformanceModal}
                  suffix={<RightOutlined onClick={showPerformanceModal} />}
                />
              </Form.Item>
            </Col> */}
            {/* <Col span={12}>
              <Form.Item label="Commission Plan" name="commissionPlan">
                <Input
                  placeholder="Please select"
                  style={{ cursor: "pointer" }}
                  className="billing-input"
                  onClick={showModal}
                  suffix={<RightOutlined onClick={showModal} />}
                />
              </Form.Item>
            </Col> */}
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item label="Note" name="note">
                <TextArea
                  rows={6}
                  placeholder="Please enter"
                  style={{ width: "100%", resize: "none" }}
                  autoSize={{ minRows: 6, maxRows: 12 }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <PerformancePlanModel
        title="Select performance plan"
        visible={isPerformanceModalVisible}
        onCancel={handlePerformanceCancel}
        onSave={handlePerformanceSave} // Handle saving selected plans
        selectedProducts={selectedPerformnce} // Pass selected plans as data
        footer={null}
        // Pass static data to PerformancePlanModel
        staticData={performancePlans}
      />
      <CommissionPlanModal
        title="Select Commission Plan"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        // onSave={(selectedData) => {
        //   setSelectedCommission(selectedData);
        //   setIsModalVisible(false);
        //   console.log("Selected Data:", selectedData);
        // }}
        onSave={handleCommissionSave}
      />
    </Modal>
  );
};
const AddTrainingRecordModal: React.FC<AddTrainingRecordModalProps> = ({
  visible,
  onCancel,
  onSave,
  form,
  isEditRecord,
}) => {
  const handleSave = () => {
    form.validateFields().then((values) => {
      onSave(values);
    });
  };

  return (
    <Modal
      title={`${
        isEditRecord ? "Edit Training Record" : "Add Training Record"
      } `}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
      centered
      width={700}
    >
      <div
        style={{ maxHeight: "600px", overflowY: "auto", paddingRight: "16px" }}
      >
        <Form form={form} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Serial Number"
                name="srno"
                rules={[
                  { required: true, message: "Please enter serial number" },
                ]}
              >
                <Input placeholder="Please enter" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Course Name"
                name="coursename"
                rules={[
                  { required: true, message: "Please enter course name" },
                ]}
              >
                <Input placeholder="Please enter" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Educator"
                name="educator"
                rules={[{ required: true, message: "Please enter educator" }]}
              >
                <Input placeholder="Please enter" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Content"
                name="content"
                rules={[{ required: true, message: "Please enter content" }]}
              >
                <Input placeholder="Please enter" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Start Time"
                name="starttime"
                rules={[
                  { required: true, message: "Please select start time" },
                ]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{ width: "100%", height: "39px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="End Time"
                name="endtime"
                rules={[{ required: true, message: "Please select end time" }]}
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  style={{ width: "100%", height: "39px" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Result"
                name="result"
                rules={[{ required: true, message: "Please select result" }]}
              >
                <Select placeholder="Please select">
                  <Option value="Passed">Passed</Option>
                  <Option value="Failed">Failed</Option>
                  <Option value="Progress">Progress</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Score"
                name="score"
                rules={[{ required: true, message: "Please enter score" }]}
              >
                <Input placeholder="Please enter" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Certificate"
                name="certificate"
                rules={[
                  { required: true, message: "Please enter certificate" },
                ]}
              >
                <TextArea
                  rows={6}
                  placeholder="Please enter"
                  style={{ width: "100%", resize: "none" }}
                  autoSize={{ minRows: 6, maxRows: 12 }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Upload Certificate" name="trainingprofile">
                <Upload listType="picture-card">
                  <div>
                    <PlusOutlined />
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

interface AddWorkExperienceModalProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (values: any) => void;
  form: FormInstance;
  isEditRecord: boolean;
}

const AddWorkExperienceModal: React.FC<AddWorkExperienceModalProps> = ({
  visible,
  onCancel,
  onSave,
  form,
  isEditRecord,
}) => {
  const handleSave = () => {
    form.validateFields().then((values) => {
      onSave(values);
    });
  };

  return (
    <Modal
      title={`${
        isEditRecord ? "Edit Work Experience" : "Add Work Experience"
      } `}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
      centered
      width={700}
      bodyStyle={{ minHeight: "70vh", padding: "24px" }} // Adjusted height and padding
    >
      <Form form={form} layout="vertical">
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Serial Number"
              name="srno"
              rules={[
                { required: true, message: "Please enter serial number" },
              ]}
            >
              <Input placeholder="Please enter" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Company Name"
              name="cname"
              rules={[{ required: true, message: "Please enter company name" }]}
            >
              <Input placeholder="Please enter" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Job Title"
              name="jobtitle"
              rules={[{ required: true, message: "Please enter job title" }]}
            >
              <Input placeholder="Please enter" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Start Time"
              name="starttime"
              rules={[{ required: true, message: "Please select start time" }]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                style={{ width: "100%", height: "39px" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="End Time"
              name="endtime"
              rules={[{ required: true, message: "Please select end time" }]}
            >
              <DatePicker
                format="YYYY-MM-DD"
                style={{ width: "100%", height: "39px" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Job Responsibilities"
              name="jobresponsbilty"
              rules={[
                {
                  required: true,
                  message: "Please enter job responsibilities",
                },
              ]}
            >
              <Input placeholder="Please enter" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Job Achievements"
              name="jobarchieve"
              rules={[
                { required: true, message: "Please enter job achievements" },
              ]}
            >
              <Input placeholder="Please enter" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Reason for Dismissal"
              name="reasonfordimission"
              rules={[
                {
                  required: true,
                  message: "Please enter reason for dismissal",
                },
              ]}
            >
              <Input placeholder="Please enter" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const StaffInfo = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [staffData, setStaffData] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState("profile");

  useEffect(() => {
    const fetchStaffData = async () => {
      try {
        const response = await get(`/staff/${id}`); // Use the id to fetch data
        if (response?.data?.success) {
          setStaffData(response.data.data);
        } else {
          console.log("Failed to fetch staff data");
        }
      } catch (error) {
        console.error("Error fetching staff data:", error);
      }
    };

    if (id) fetchStaffData();
  }, [id]);

  const handleMenuClick = (e: any) => {
    setSelectedMenu(e.key);
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case "profile":
        return <StaffProfile staffData={staffData} />;
      case "staffing":
        return <Staffing />;
      case "training":
        return <Training />;
      case "workexperience":
        return <WorkExperience />;
      case "gallery":
        return <Gallery />;
      case "settings":
        return <Settings />;
      default:
        return <StaffProfile staffData={staffData} />;
    }
  };

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#f4f6fa" }}>
      <Header
        style={{
          padding: "0 24px",
          display: "flex",
          alignItems: "center",
          background: "transparent",
        }}
      >
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        />
        <span
          style={{
            fontSize: "20px",
            color: "#000",
            fontWeight: "500",
          }}
        >
          Staff Info
        </span>
      </Header>

      <Layout style={{ padding: "0px 20px" }}>
        <Sider
          width={220}
          style={{
            background: "#fafafa",
            padding: "20px",
            borderRadius: "8px",
            margin: "0px 16px 16px 0px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Menu
            mode="inline"
            selectedKeys={[selectedMenu]}
            onClick={handleMenuClick}
            style={{ borderRight: 0, background: "#fafafa" }}
          >
            <Menu.Item
              key="profile"
              style={
                selectedMenu === "profile"
                  ? { backgroundColor: "blue", color: "white" }
                  : {}
              }
            >
              Staff Profile
            </Menu.Item>
            {localStorage.getItem("userRole") !== "Staff" && (
              <Menu.Item
                key="staffing"
                style={
                  selectedMenu === "staffing"
                    ? { backgroundColor: "blue", color: "white" }
                    : {}
                }
              >
                Staffing
              </Menu.Item>
            )}
            <Menu.Item
              key="training"
              style={
                selectedMenu === "training"
                  ? { backgroundColor: "blue", color: "white" }
                  : {}
              }
            >
              Training
            </Menu.Item>
            <Menu.Item
              key="workexperience"
              style={
                selectedMenu === "workexperience"
                  ? { backgroundColor: "blue", color: "white" }
                  : {}
              }
            >
              Work Experience
            </Menu.Item>
            <Menu.Item
              key="gallery"
              style={
                selectedMenu === "gallery"
                  ? { backgroundColor: "blue", color: "white" }
                  : {}
              }
            >
              Gallery
            </Menu.Item>
            {localStorage.getItem("userRole") !== "Staff" && (
              <Menu.Item
                key="settings"
                style={
                  selectedMenu === "settings"
                    ? { backgroundColor: "blue", color: "white" }
                    : {}
                }
              >
                Settings
              </Menu.Item>
            )}
          </Menu>
        </Sider>

        <Layout style={{ padding: "0px 0px 16px 0px" }}>
          <Content
            style={{
              padding: "20px",
              background: "#fafafa",
              borderRadius: "8px",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Staff Profile Title with Edit Button */}

            {renderContent()}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};
// Staff Profile Component with Proper Design and Borders for Each Section
const StaffProfile: React.FC<StaffProfileProps> = ({ staffData }) => {
  const navigate = useNavigate();
  const { id: staffid } = useParams<{ id: string }>();
  return (
    <div style={{ padding: "10px" }}>
      {/* Header Section */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "25px",
        }}
      >
        <Title level={4} style={{ margin: 0 }}>
          Staff Profile
        </Title>
        <Button
          type="primary"
          onClick={() => {
            navigate(`/settings/staff/edit/${staffid}`);
          }}
        >
          Edit
        </Button>
      </div>

      {/* Profile Card Section */}
      <Card
        style={{
          background: "#fff",
          borderRadius: "8px",
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          maxHeight: "700px",
          overflowY: "auto",
        }}
      >
        {/* Profile Details */}
        <Row align="middle" gutter={16} style={{ marginBottom: "22px" }}>
          <Col>
            <img
              src={staffData?.profile_pic ? staffData.profilepicurl : StaffIcon}
              alt="Staff Avatar"
              style={{ borderRadius: "50%", width: "80px", height: "80px" }}
            />
          </Col>
          <Col>
            <Title level={5} style={{ margin: 0 }}>
              {staffData?.name} {staffData?.lastname}
              {staffData?.preffered_name ? `(${staffData.preffered_name})` : ""}
            </Title>
            <Col span={24}>
              <Text type="secondary">#{staffData?.staffid}</Text>
            </Col>
            <Col span={24}>
              <Text type="secondary">
                {staffData?.department}/{staffData?.designation}/
                {staffData?.title}
              </Text>
            </Col>
          </Col>
        </Row>

        {/* Staff Information */}
        <Divider />
        <Row gutter={[16, 8]}>
          <Col span={8}>
            <Text strong>Store:</Text>
          </Col>
          <Col span={12}>{staffData?.outlet_name}</Col>

          <Col span={8}>
            <Text strong>Email:</Text>
          </Col>
          <Col span={12}>{staffData?.email}</Col>

          <Col span={8}>
            <Text strong>Phone number:</Text>
          </Col>
          <Col span={12}>
            +{staffData?.countrycode} {staffData?.phone_number}
          </Col>

          <Col span={8}>
            <Text strong>Date of birth:</Text>
          </Col>
          <Col span={12}>{staffData?.dob ? staffData?.dob : "-"}</Col>

          <Col span={8}>
            <Text strong>Staff ID:</Text>
          </Col>
          <Col span={12}>{staffData?.staffid}</Col>

          <Col span={8}>
            <Text strong>Educational background:</Text>
          </Col>
          <Col span={12}>
            {staffData?.educational_bg !== "" ? staffData?.educational_bg : "-"}
          </Col>

          <Col span={8}>
            <Text strong>Address:</Text>
          </Col>
          <Col span={12}>
            {staffData?.address !== "" ? staffData?.address : "-"}
          </Col>
        </Row>

        {/* Emergency Contact Information */}
        <Divider />
        <Row gutter={[16, 8]}>
          <Col span={8}>
            <Text strong>Emergency Contact:</Text>
          </Col>
          <Col span={12}>
            {staffData?.emergency_contact !== ""
              ? staffData?.emergency_contact
              : "-"}
          </Col>

          <Col span={8}>
            <Text strong>Phone number:</Text>
          </Col>
          <Col span={12}>
            {staffData?.alternate_number ? staffData?.alternate_number : "-"}
          </Col>

          <Col span={8}>
            <Text strong>Email:</Text>
          </Col>
          <Col span={12}>
            {staffData?.alternate_email ? staffData?.alternate_email : "-"}
          </Col>

          <Col span={8}>
            <Text strong>Address:</Text>
          </Col>
          <Col span={12}>
            {staffData?.altername_address !== ""
              ? staffData?.altername_address
              : "-"}
          </Col>
        </Row>

        {/* Employment Status Information */}
        <Divider />
        <Row gutter={[16, 8]}>
          <Col span={8}>
            <Text strong>Employment Status:</Text>
          </Col>
          <Col span={12}>{staffData?.emp_status}</Col>

          <Col span={8}>
            <Text strong>Start date:</Text>
          </Col>
          <Col span={12}>
            {staffData?.start_date ? staffData?.start_date : "-"}
          </Col>

          <Col span={8}>
            <Text strong>End date:</Text>
          </Col>
          <Col span={12}>{staffData?.end_date ? staffData.end_date : "-"}</Col>

          <Col span={8}>
            <Text strong>Profile description:</Text>
          </Col>
          <Col span={12}>
            {staffData?.profile_desc ? staffData.profile_desc : "-"}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

const Staffing = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [staffingData, setStaffingData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [editingRecord, setEditingRecord] = useState<any>(null);
  const [isEditRecord, setIsEditRecord] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [isTerminateModalVisible, setisTerminateModalVisible] =
    useState<boolean>(false);
  const { id: staffid } = useParams<{ id: string }>();
  const [form] = Form.useForm();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const handleAddClick = () => {
    setIsModalVisible(true);
    setIsEditModalVisible(false);
  };

  const handleEditClick = (record: any) => {
    setIsEditRecord(true);
    setIsEditModalVisible(true);
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleEditCancel = () => {
    setIsEditModalVisible(false);
  };
  const fetchStaffingData = async (page: number, pageSize: number) => {
    try {
      const response = await get(
        `/staffing/list/${staffid}/${localStorage.getItem(
          "outlet_id"
        )}/${page}/${pageSize}`
      );
      if (response?.data?.success) {
        setStaffingData(response.data.data);
        setTotalRecords(response.data.totalRecords);
      } else {
        message.error("Failed to fetch staffing data");
      }
    } catch (error) {
      console.error("Error fetching staffing data:", error);
      message.error("Error fetching staffing data");
    }
  };

  useEffect(() => {
    fetchStaffingData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };

  const handleDelete = async () => {
    try {
      const response = await deleteData(`/staffing/remove/${editingRecord}`);
      if (response?.data?.success) {
        message.success("Deleted successfully");
        setIsDeleteModalVisible(false);
        fetchStaffingData(currentPage, pageSize);
      } else {
        message.error("Failed to delete record");
      }
    } catch (error) {
      console.error("Error deleting record:", error);
      message.error("Error deleting record");
    }
  };

  const terminateStaff = async () => {
    try {
      const currentDateTime = dayjs().format("DD/MM/YYYY hh:mma");

      const response = await put(`/staffing/${editingRecord}`, {
        isterminate: 0,
        endtime: currentDateTime,
      });
      if (response?.data?.success) {
        message.success("Staffing record terminated successfully");
        setisTerminateModalVisible(false);
        fetchStaffingData(currentPage, pageSize);
      } else {
        message.error("Failed to terminate staffing record");
      }
    } catch (error) {
      console.error("Error terminating staffing record:", error);
      message.error("Error terminating staffing record");
    }
  };

  const handleConfirm = () => {
    handleDelete();
  };

  const handleConfirmTerminal = () => {
    terminateStaff();
  };

  // Confirm delete action
  const confirmDelete = (id: string) => {
    setEditingRecord(id);
    setIsDeleteModalVisible(true);
  };

  const confirmTerminate = (id: string) => {
    setEditingRecord(id);
    setisTerminateModalVisible(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const closeTerminateModal = () => {
    setisTerminateModalVisible(false);
  };

  const openModal = (record: any = null) => {
    if (record) {
      setIsEditRecord(true);
      fetchstaffingById(record.id);
      setIsEditModalVisible(true);
    }
  };

  const columns = [
    {
      title: "Receiving Store",
      dataIndex: "receivingstorename",
      key: "receivingstorename",
    },
    { title: "Assigned Code", dataIndex: "staffnumber", key: "staffnumber" },
    { title: "Start Time", dataIndex: "starttime", key: "starttime" },
    {
      title: "End Time",
      dataIndex: "endtime",
      key: "endtime",
      render: (text: string | null) => (text ? text : "-"),
    },
    { title: "Note", dataIndex: "note", key: "note" },
    {
      title: "Action",
      key: "action",
      render: (record: any) => (
        <span>
          {record.isterminate == 1 && (
            <>
              <a
                style={{
                  color: "#2e56f2",
                  textDecoration: "underline",
                  fontWeight: 700,
                  marginRight: "16px",
                  textUnderlineOffset: "4px",
                }}
                onClick={() => openModal(record)}
              >
                Edit
              </a>
              <a
                style={{
                  color: "#2e56f2",
                  textDecoration: "underline",
                  fontWeight: 700,
                  marginRight: "16px",
                  textUnderlineOffset: "4px",
                }}
                onClick={() => {
                  confirmTerminate(record.id);
                }}
              >
                Terminate
              </a>
            </>
          )}
          {record.isterminate === 0 && (
            <a
              style={{
                color: "rgb(245, 63, 63)",
                fontWeight: 700,
                textDecoration: "underline",
                textUnderlineOffset: "4px",
              }}
              onClick={() => confirmDelete(record.id)}
            >
              Delete
            </a>
          )}
        </span>
      ),
    },
  ];

  const fetchstaffingById = async (id: string) => {
    try {
      const response = await get(`/staffing/${id}`);
      if (response?.data?.success) {
        console.log("getbyiddata", response?.data.data);
        setEditingRecord(response.data.data);
        console.log(response.data.data, "fetchstaffingByIdvvvvvvvvv");

        form.setFieldsValue({
          ...response.data.data,
          receivingStore: response.data.data.receivingstore || null,
          assignedStaffNumber: response.data.data.staffnumber || null,
          startTime: response.data.data.starttime
            ? dayjs(response.data.data.starttime, [
                "YYYY-MM-DD HH:mm",
                "YYYY-MM-DD HH:mm:ss",
                "DD/MM/YYYY HH:mm",
              ])
            : null,
          endTime: response.data.data.endtime
            ? dayjs(response.data.data.endtime, [
                "YYYY-MM-DD HH:mm",
                "YYYY-MM-DD HH:mm:ss",
                "DD/MM/YYYY HH:mm",
              ])
            : null,
        });
        setIsEditModalVisible(true);
      } else {
        message.error("Failed to fetch training record details");
      }
    } catch (error) {
      console.error("Error fetching training by ID:", error);
      message.error("Error fetching training details");
    }
  };

  const handleEditSave = async (values: any) => {
    // console.log("Saved values:", values);

    const payload = {
      staffid: values.staffid,
      sendingstore: values.sendingStore,
      outlet_id: localStorage.getItem("outlet_id"),
      receivingstore: values.receivingStore,
      // startTime:values.starttime,
      // endTime:values.endtime,
      startTime: values.startTime
        ? dayjs(values.startTime, [
            "YYYY-MM-DD HH:mm",
            "YYYY-MM-DD HH:mm:ss",
            "DD/MM/YYYY HH:mm",
          ])
        : null,
      endTime: values.endTime
        ? dayjs(values.endTime, [
            "YYYY-MM-DD HH:mm",
            "YYYY-MM-DD HH:mm:ss",
            "DD/MM/YYYY HH:mm",
          ])
        : null,
      note: values.note,
      staffnumber: values.assignedStaffNumber,
      title: values.title,
      department: values.department,
    };

    // const payload = { staffid, ...formattedValues };
    // payload.outlet_id = localStorage.getItem("outlet_id");
    console.log(payload, "editpayload");
    try {
      const response = await put(`/staffing/${editingRecord.id}`, payload);

      if (response?.data?.success) {
        message.success(`staffing record editing successfully`);
        setIsEditModalVisible(false);
        setEditingRecord(null);
        fetchStaffingData(currentPage, pageSize);
        console.log("edit values:", response.data);
      } else {
        message.error(`Failed to editingRecord update staffing record`);
      }
    } catch (error) {
      console.error(
        `Error ${editingRecord ? "updating" : "adding"} training:`,
        error
      );
      message.error(`Error ${editingRecord ? "updating" : "adding"} training`);
    }
  };

  const handleSave = (data: any) => {
    console.log("Dispatch data:", data);
    fetchStaffingData(currentPage, pageSize);
    setIsModalVisible(false);
  };

  // const handleEditSave = (data: any) => {
  //   console.log("Dispatch data:", data);
  //   fetchStaffingData(currentPage, pageSize);
  //   setIsEditModalVisible(false);
  // };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <h2>Staffing</h2>
        <Button
          type="primary"
          style={{ background: "#FFA500", borderColor: "#FFA500" }}
          onClick={handleAddClick}
        >
          + Add
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={staffingData}
        rowKey="id"
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalRecords,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          },
        }}
        onChange={handleTableChange}
      />
      <AddDispatchModal
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSave}
      />
      <EditDispatchModal
        visible={isEditModalVisible}
        onCancel={handleEditCancel}
        onSave={handleEditSave}
        form={form}
        isEditRecord={isEditRecord}
      />
      <ConfirmDeleteModal
        visible={isDeleteModalVisible}
        onCancel={closeDeleteModal}
        // onConfirm={() => handleDeleteApi(editingRecord.key)}
        onConfirm={handleConfirm}
        message="Are you sure to delete?"
      />
      <ConfirmDeleteModal
        visible={isTerminateModalVisible}
        onCancel={closeTerminateModal}
        onConfirm={handleConfirmTerminal} // Use the stored record ID
        message="Are you sure to end the dispatch?"
      />
    </div>
  );
};

const Training = () => {
  const { id: staffid } = useParams<{ id: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [trainingData, setTrainingData] = useState([]);
  const [editingRecord, setEditingRecord] = useState<any>(null);
  const [isEditRecord, setIsEditRecord] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [form] = Form.useForm();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    fetchTrainingData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchTrainingData = async (page: number, pageSize: number) => {
    try {
      const response = await get(
        `/training/getall/${staffid}/${localStorage.getItem(
          "outlet_id"
        )}/${page}/${pageSize}`
      );
      if (response?.data?.success) {
        setTrainingData(response.data.data);
        setTotalRecords(response.data.totalRecords);
      } else {
        message.error("Failed to fetch training data");
      }
    } catch (error) {
      console.error("Error fetching training data:", error);
      message.error("Error fetching training data");
    }
  };

  const fetchTrainingById = async (id: string) => {
    try {
      const response = await get(`/training/${id}`);
      if (response?.data?.success) {
        setEditingRecord(response.data.data);
        form.setFieldsValue({
          ...response.data.data,
          starttime: response.data.data.starttime
            ? dayjs(response.data.data.starttime, "YYYY-MM-DD")
            : null,
          endtime: response.data.data.endtime
            ? dayjs(response.data.data.endtime, "YYYY-MM-DD")
            : null,
        });
        setIsModalVisible(true);
      } else {
        message.error("Failed to fetch training record details");
      }
    } catch (error) {
      console.error("Error fetching training by ID:", error);
      message.error("Error fetching training details");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteData(`/training/remove/${editingRecord}`);
      if (response?.data?.success) {
        message.success("Training record deleted successfully");
        setIsDeleteModalVisible(false);
        fetchTrainingData(currentPage, pageSize);
      } else {
        message.error("Failed to delete training record");
      }
    } catch (error) {
      console.error("Error deleting training record:", error);
      message.error("Error deleting training record");
    }
  };

  const handleConfirm = () => {
    handleDelete();
  };

  // Confirm delete action
  const confirmDelete = (id: string) => {
    setEditingRecord(id);
    setIsDeleteModalVisible(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const openModal = (record: any = null) => {
    if (record) {
      setIsEditRecord(true);
      fetchTrainingById(record.id);
    } else {
      setIsEditRecord(false);
      setEditingRecord(null);
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    { title: "Serial Number", dataIndex: "srno", key: "srno" },
    { title: "Course Name", dataIndex: "coursename", key: "coursename" },
    { title: "Educator", dataIndex: "educator", key: "educator" },
    { title: "Start Time", dataIndex: "starttime", key: "starttime" },
    { title: "End Time", dataIndex: "endtime", key: "endtime" },
    { title: "Result", dataIndex: "result", key: "result" },
    {
      title: "Action",
      key: "action",
      render: (record: any) => (
        <span>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => openModal(record)}
          >
            Edit
          </a>
          <a
            style={{
              color: "rgb(245, 63, 63)",
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
            }}
            onClick={() => confirmDelete(record.id)}
          >
            Delete
          </a>
        </span>
      ),
    },
  ];

  const handleSave = async (values: any) => {
    console.log("Saved values:", values);

    const formattedValues = {
      ...values,
      starttime: formatDate(values.starttime),
      endtime: formatDate(values.endtime),
    };

    const payload = { staffid, ...formattedValues };
    payload.outlet_id = localStorage.getItem("outlet_id");
    try {
      const response = editingRecord
        ? await put(`/training/${editingRecord.id}`, payload)
        : await post(`/training/addtraining`, payload);

      if (response?.data?.success) {
        message.success(
          `Training record ${editingRecord ? "updated" : "added"} successfully`
        );
        fetchTrainingData(currentPage, pageSize); // Refresh data
        setIsModalVisible(false);
        setEditingRecord(null);
      } else {
        message.error(
          `Failed to ${editingRecord ? "update" : "add"} training record`
        );
      }
    } catch (error) {
      console.error(
        `Error ${editingRecord ? "updating" : "adding"} training:`,
        error
      );
      message.error(`Error ${editingRecord ? "updating" : "adding"} training`);
    }
  };

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <h2>Training</h2>
        <Button
          type="primary"
          style={{ background: "#FFA500", borderColor: "#FFA500" }}
          onClick={() => openModal(null)}
        >
          + Add
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={trainingData}
        rowKey="id" /* add table columns and data here */
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalRecords,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          },
        }}
        onChange={handleTableChange}
      />
      <AddTrainingRecordModal
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSave}
        form={form}
        isEditRecord={isEditRecord}
      />
      <ConfirmDeleteModal
        visible={isDeleteModalVisible}
        onCancel={closeDeleteModal}
        // onConfirm={() => handleDeleteApi(editingRecord.key)}
        onConfirm={handleConfirm}
        message="Are you sure to delete?"
      />
    </div>
  );
};

const WorkExperience = () => {
  const { id: staffid } = useParams<{ id: string }>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [workExperienceData, setWorkExperienceData] = useState([]);
  const [editingRecord, setEditingRecord] = useState<any>(null);
  const [isEditRecord, setIsEditRecord] = useState<boolean>(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [form] = Form.useForm();

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    fetchWorkExperienceData(currentPage, pageSize);
  }, [currentPage, pageSize]);

  const fetchWorkExperienceData = async (page: number, pageSize: number) => {
    try {
      const response = await get(
        `/workexperience/getall/${staffid}/${localStorage.getItem(
          "outlet_id"
        )}/${page}/${pageSize}`
      );
      if (response?.data?.success) {
        setWorkExperienceData(response.data.data);
      } else {
        message.error("Failed to fetch work experience data");
      }
    } catch (error) {
      console.error("Error fetching work experience data:", error);
      message.error("Error fetching work experience data");
    }
  };

  const fetchWorkExperienceById = async (id: string) => {
    try {
      const response = await get(`/workexperience/${id}`);
      if (response?.data?.success) {
        setEditingRecord(response.data.data);
        form.setFieldsValue({
          ...response.data.data,
          starttime: response.data.data.starttime
            ? dayjs(response.data.data.starttime, "YYYY-MM-DD")
            : null,
          endtime: response.data.data.endtime
            ? dayjs(response.data.data.endtime, "YYYY-MM-DD")
            : null,
        });
        setIsModalVisible(true);
      } else {
        message.error("Failed to fetch work experience record details");
      }
    } catch (error) {
      console.error("Error fetching work experience by ID:", error);
      message.error("Error fetching work experience details");
    }
  };

  const handleDelete = async () => {
    try {
      const response = await deleteData(
        `/workexperience/remove/${editingRecord}`
      );
      if (response?.data?.success) {
        message.success("Work experience record deleted successfully");
        setIsDeleteModalVisible(false);
        fetchWorkExperienceData(currentPage, pageSize);
      } else {
        message.error("Failed to delete work experience record");
      }
    } catch (error) {
      console.error("Error deleting work experience record:", error);
      message.error("Error deleting work experience record");
    }
  };

  const handleConfirm = () => {
    handleDelete();
  };

  // Confirm delete action
  const confirmDelete = (id: string) => {
    setEditingRecord(id);
    setIsDeleteModalVisible(true);
  };

  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const openModal = (record: any = null) => {
    if (record) {
      setIsEditRecord(true);
      fetchWorkExperienceById(record.id); // Fetch data for editing
    } else {
      setIsEditRecord(false);
      setEditingRecord(null); // Open modal for adding new record
      form.resetFields();
    }
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    { title: "Serial Number", dataIndex: "srno", key: "srno" },
    { title: "Company Name", dataIndex: "cname", key: "cname" },
    { title: "Designation", dataIndex: "jobtitle", key: "jobtitle" },
    { title: "Start Time", dataIndex: "starttime", key: "starttime" },
    { title: "End Time", dataIndex: "endtime", key: "endtime" },
    {
      title: "Action",
      key: "action",
      render: (record: any) => (
        <span>
          <a
            style={{
              color: "#2e56f2",
              fontWeight: 700,
              textDecoration: "underline",
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => openModal(record)}
          >
            Edit
          </a>
          <a
            style={{
              color: "rgb(245, 63, 63)",
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
            }}
            onClick={() => confirmDelete(record.id)}
          >
            Delete
          </a>
        </span>
      ),
    },
  ];

  const handleSave = async (values: any) => {
    const formattedValues = {
      ...values,
      starttime: formatDate(values.starttime),
      endtime: formatDate(values.endtime),
    };

    const payload = { staffid, ...formattedValues };
    payload.outlet_id = localStorage.getItem("outlet_id");
    try {
      const response = editingRecord
        ? await put(`/workexperience/${editingRecord.id}`, payload)
        : await post(`/workexperience/addworkexperience`, payload);

      if (response?.data?.success) {
        message.success(
          `Work Experience record ${
            editingRecord ? "updated" : "added"
          } successfully`
        );
        fetchWorkExperienceData(currentPage, pageSize); // Refresh data
        setIsModalVisible(false);
        setEditingRecord(null);
      } else {
        message.error(
          `Failed to ${editingRecord ? "update" : "add"} work experience record`
        );
      }
    } catch (error) {
      console.error(
        `Error ${editingRecord ? "updating" : "adding"} work experience:`,
        error
      );
      message.error(
        `Error ${editingRecord ? "updating" : "adding"} work experience`
      );
    }
  };

  const handleTableChange = (pagination: any) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "16px",
        }}
      >
        <h2>Work Experience</h2>
        <Button
          type="primary"
          style={{ background: "#FFA500", borderColor: "#FFA500" }}
          onClick={() => openModal(null)}
        >
          + Add
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={workExperienceData}
        rowKey="id" /* add table columns and data here */
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: totalRecords,
          onChange: (page, pageSize) => {
            setCurrentPage(page);
            setPageSize(pageSize);
          },
        }}
        onChange={handleTableChange}
      />
      <AddWorkExperienceModal
        visible={isModalVisible}
        onCancel={handleCancel}
        onSave={handleSave}
        form={form}
        isEditRecord={isEditRecord}
      />
      <ConfirmDeleteModal
        visible={isDeleteModalVisible}
        onCancel={closeDeleteModal}
        // onConfirm={() => handleDeleteApi(editingRecord.key)}
        onConfirm={handleConfirm}
        message="Are you sure to delete?"
      />
    </div>
  );
};
const Gallery = () => {
  // interface GalleryData {
  //   id: any;
  //   title: string;
  //   pricing: string;
  //   showcase: any[];
  //   worktags: any[];
  //   status: boolean;
  // }
  const [GalleryData, setGalleryData] = useState<any[]>([]);
  const [workTagData, setWorkTagData] = useState<any[]>([]);
  const [visible, setVisible] = useState(false);
  const { id } = useParams();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<any | null>(null);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedGallreyData, setSelectedGalleryData] = useState<any | null>(
    null
  );

  useEffect(() => {
    getWorkTagData();
    getStaffGalleryData();
  }, []);

  const getWorkTagData = async () => {
    try {
      let url = `/worktag/list/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setWorkTagData(response.data.data);
        } else {
          console.error("Error in Api call: ", response.data.message);
        }
      } else {
        console.error("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getStaffGalleryData = async () => {
    try {
      const url = `/staffgallery/staff/${id}`;
      const response = await get(url);
      const galleryData = response.data;
      if (galleryData.success) {
        setGalleryData(galleryData.data);
      }
    } catch (error) {
      console.error("Error fetching staff gallery data:", error);
    }
  };

  const handleGalleryModelOpen = () => {
    setVisible(true);
  };

  const handleGalleryModelClose = () => {
    setVisible(false);
  };

  const handleEditGalleryModelOpen = (item: any) => {
    setSelectedGalleryData(item);
    setEditModalVisible(true);
  };

  const handleGalleryModelSave = async (data: any) => {
    setVisible(false);
    // Handle save functionality here (e.g., API call to save data)
    try {
      const url = "/staffgallery";
      const response = await post(url, data);
      if (response.data.success) {
        message.success("Added successfully");
        getStaffGalleryData();
      }
    } catch (error) {
      console.error("Error saving gallery data:", error);
    }
  };

  const handleGalleryModelEdit = async (data: any) => {
    // Handle save functionality here (e.g., API call to save data)
    setEditModalVisible(false);
    try {
      const url = `/staffgallery/${selectedGallreyData?.id}`;
      const response = await put(url, data);
      if (response.data.success) {
        message.success("Successfully edited");
        getStaffGalleryData();
      }
    } catch (error) {
      console.error("Error saving gallery data:", error);
    }
  };

  // Function to handle switch change
  const handleSwitchChange = (checked: any, itemId: string) => {
    setGalleryData((prev: any) =>
      prev.map((item: any) =>
        item.id === itemId ? { ...item, show_status: checked } : item
      )
    );
  };

  const handleDeleteClick = (record: any) => {
    setSelectedData(record);
    setDeleteModalVisible(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (selectedData) {
        // Call delete API
        const response = await deleteData(
          `/staffgallery/remove/${selectedData}`
        );
        if (response.data.success) {
          message.success("Deleted successfully!");
          getStaffGalleryData();
        }
      }
      setDeleteModalVisible(false);
      setSelectedData(null);
    } catch (error) {
      message.error("Failed to delete department.");
      console.error(error);
    }
  };
  return (
    <>
      <Row align="middle" style={{ marginBottom: "24px" }}>
        <Col span={20}>
          <h3 style={{ margin: 0, color: "#333" }}>Gallery</h3>
        </Col>
        <Col span={4} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            style={{
              backgroundColor: "#FFA500",
              borderColor: "#FFA500",
              fontWeight: "bold",
            }}
            onClick={handleGalleryModelOpen}
          >
            + Add
          </Button>
        </Col>
      </Row>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(182px, 1fr))",
          gap: "16px",
          marginBottom: "16px",
        }}
      >
        {GalleryData && GalleryData.length > 0 ? (
          GalleryData.map((item: any) => (
            <>
              <div style={{ height: 236, width: 182 }}>
                <Card
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "0px",
                    overflow: "hidden",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    position: "relative",
                    border: "1px solid #e6e6e6",
                  }}
                  bodyStyle={{ padding: "0" }}
                  cover={
                    <div
                      style={{
                        position: "relative",
                        height: 170,
                        cursor: "pointer",
                        // backgroundImage:
                        //   "url('https://images.pexels.com/photos/459225/pexels-photo-459225.jpeg')",
                        backgroundImage: `url(${
                          item.showcase?.length > 0
                            ? item.showcase[0]?.url
                            : "https://images.pexels.com/photos/459225/pexels-photo-459225.jpeg"
                        })`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                      onClick={() => handleEditGalleryModelOpen(item)}
                    >
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          padding: "8px",
                          backgroundColor: "#00000033",
                          color: "#fff",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "5px",
                          }}
                        >
                          <PictureOutlined
                            style={{ marginRight: 4, fontSize: "14px" }}
                          />
                          <Text style={{ color: "#fff", fontSize: "12px" }}>
                            {item.showcase?.length}Pics
                          </Text>
                        </div>
                        <Button
                          type="text"
                          icon={<DeleteOutlined />}
                          style={{
                            color: "#fff",
                            backgroundColor: "transparent",
                            padding: "4px",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteClick(item?.id);
                          }}
                        />
                      </div>
                    </div>
                  }
                  // onClick={() => handleEditGalleryModelOpen(item)}
                >
                  <div style={{ padding: "8px 8px 0 13px" }}>
                    <Text strong style={{ fontSize: "14px" }}>
                      {item.title}
                    </Text>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "16px",
                        marginTop: "8px",
                      }}
                    >
                      <Text
                        type="secondary"
                        style={{ marginRight: "8px", fontSize: "12px" }}
                      >
                        {item.show_status ? "Show" : "Hide"}
                      </Text>
                      <Switch
                        size="default"
                        checked={item.show_status}
                        // onChange={handleSwitchChange}
                        onChange={(checked) =>
                          handleSwitchChange(checked, item.id)
                        }
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </>
          ))
        ) : (
          <>{/* <Text>No Data Found</Text> */}</>
        )}
      </div>
      {visible && (
        <GalleryModel
          visible={visible}
          onCancel={handleGalleryModelClose}
          onSave={handleGalleryModelSave}
          staffId={id}
          workTagData={workTagData}
        />
      )}
      {editModalVisible && (
        <EditGalleryModel
          visible={editModalVisible}
          onCancel={() => setEditModalVisible(false)}
          onSave={handleGalleryModelEdit}
          staffId={id}
          workTagData={workTagData}
          selectedItem={selectedGallreyData}
        />
      )}

      {deleteModalVisible && (
        <ConfirmDeleteModal
          visible={deleteModalVisible}
          onCancel={() => setDeleteModalVisible(false)}
          onConfirm={handleDeleteConfirm}
          message="Are you sure to delete?"
        />
      )}
    </>
  );
};

const Settings = () => {
  interface SettingsData {
    id: string;
    staffid: string;
    accountstatus: boolean;
    managerauth: boolean;
    managerstaffcode: number;
    managerauthorizationcode: number;
    refundauth: boolean;
    refundstaffcode: number;
    refundauthcode: number;
    enableappointment: boolean;
    staffportalapp: boolean;
    loginaccount: string;
    password: string;
    viewcustomerphone: boolean;
    addnewcustomer: boolean;
    performanceranking: boolean;
    customerreview: boolean;
    customerview: boolean;
    addnewappointment: boolean;
    addblocktime: boolean;
    editappointment: boolean;
    cancleappointment: boolean;
    checkout: boolean;
    confirmappointment: boolean;
    rejectappointment: boolean;
    viewappointmentotherstaff: boolean;
    addappointmentotherstaff: boolean;
    editcancelappointmentotherstaff: boolean;
    addcancelblockotherstaff: boolean;
    billing: boolean;
    editpricediscount: boolean;
    editproductconsumption: boolean;
    checkoutsales: boolean;
    editservicestaffcheckout: boolean;
    voidbill: boolean;
    limitdaysbill: boolean;
    billdays: number;
    limitdaysrebill: boolean;
    rebilldays: number;
    deleteunpaidbill: boolean;
    editaccountingdate: boolean;
    editbillnumber: boolean;
    mergebill: boolean;
    changeservicestaff: boolean;
    attendanceviaqr: boolean;
    clockinoutservice: boolean;
    edittag: boolean;
    editnote: boolean;
    voidrepaymentbill: boolean;
    voidrefundbill: boolean;
    verifybill: boolean;
    retractverification: boolean;
    forms: boolean;
    servicelog: boolean;
    appointmentslist: boolean;
    customerinfo: boolean;
    overtimeapp: boolean;
    leaveapp: boolean;
    galleryadd: boolean;
    gallerydelete: boolean;
    servicelogadd: boolean;
    servicelogedit: boolean;
    otherincomeexpense: boolean;
    customerservice: boolean;
    viewpuchaseunitprice: boolean;
    realtimeinventory: boolean;
    stockinadd: boolean;
    stockinedit: boolean;
    stockindelete: boolean;
    stockoutadd: boolean;
    stockoutedit: boolean;
    stockoutdelete: boolean;
    stockoutview: boolean;
    stockinview: boolean;
    staffList: boolean;
    onlinebilling: boolean;
  }

  const [settingsData, setSettingsData] = useState<SettingsData | null>(null);

  const [isManageAuthModalVisible, setIsManageAuthModalModalVisible] =
    useState(false);
  const [ManageAuthFormData, setManageAuthFormData] = useState({
    staffCode: "12345",
    authorizationCode: 0,
  });

  const handleModalManageAuthOpen = () => {
    if (!settingsData?.managerauth) setIsManageAuthModalModalVisible(true);
  };

  const handleManageAuthModalClose = () => {
    setIsManageAuthModalModalVisible(false);
  };

  const handleManageAuthSave = (data: { authorizationcode: number }) => {
    setSettingsData((prevState: any) => {
      if (!prevState) return null; // Handle case where settingsData is null
      const updatedState = {
        ...prevState,
        managerauthorizationcode: data.authorizationcode,
      };
      saveSettingsData(updatedState);
      return updatedState;
    });

    setIsManageAuthModalModalVisible(false);
  };

  const [
    isRefundConfirmationModelVisible,
    setIsRefundConfirmationModelVisible,
  ] = useState(false);

  const handleRefundConfirmationModalOpen = () => {
    if (!settingsData?.refundauth) setIsRefundConfirmationModelVisible(true);
  };

  const handleRefundConfirmationModalClose = () => {
    setIsRefundConfirmationModelVisible(false);
  };

  const handleRefundConfirmationSave = (data: {
    authorizationcode: number;
  }) => {
    setSettingsData((prevState: any) => {
      if (!prevState) return null; // Handle case where settingsData is null
      const updatedState = {
        ...prevState,
        refundauthcode: data.authorizationcode,
      };
      saveSettingsData(updatedState);
      return updatedState;
    });
    setIsRefundConfirmationModelVisible(false);
  };

  const [isEditLoginPasswordModelVisible, setEditLoginPasswordModelVisible] =
    useState(false);

  const handleEditLoginPasswordOpen = () => {
    setEditLoginPasswordModelVisible(true);
  };

  const handleEditLoginPasswordClose = () => {
    setEditLoginPasswordModelVisible(false);
  };

  const encryptPassword = (password: any) => {
    const secretKey = "G7HqA5VCbMMi3KQN2anRckza";
    return CryptoJS.AES.encrypt(password, secretKey).toString();
  };

  const handleEditLoginPasswordSave = (data: {
    account: string;
    password: string;
    confirmpassword: string;
  }) => {
    // Update settingsData with the password from ManageAuthFormData
    setSettingsData((prevState) => {
      if (!prevState) return null; // Handle case where settingsData is null
      const updatedState = {
        ...prevState,
        password: encryptPassword(data.password),
      };
      saveSettingsData(updatedState);
      return updatedState;
    });
    // Here you can send the formData to your backend or any other logic
    //console.log("Saved data:", EditLoginPasswordFormData);
    setEditLoginPasswordModelVisible(false);
  };

  const { id } = useParams();

  // Function to fetch data from API
  const fetchSettingsData = async () => {
    try {
      const response = await get(`/staffsetting/${id}`); // Replace with your actual API endpoint
      if (response.data.success || response.data.success == 200) {
        setSettingsData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching settings data:", error);
    }
  };

  const handleChangeStatus = <K extends keyof SettingsData>(
    field: K,
    value: SettingsData[K]
  ) => {
    setSettingsData((prevState) => {
      if (!prevState) return null;
      const updatedState = { ...prevState, [field]: value };
      return updatedState;
    });
  };

  // Update specific field in state
  const handleChange = <K extends keyof SettingsData>(
    field: K,
    value: SettingsData[K]
  ) => {
    setSettingsData((prevState) => {
      if (!prevState) return null;
      const updatedState = { ...prevState, [field]: value };
      saveSettingsData(updatedState); // Save the entire updated state to the API
      return updatedState;
    });
  };

  // Function to save updated settings data via PUT API call
  const saveSettingsData = async (updatedData: Partial<SettingsData>) => {
    try {
      const response = await put(`/staffsetting/${id}`, updatedData);
      if (response.data.success || response.data.success == 200) {
        message.success(response.data.message);
      } else {
        message.error("Failed to update settings");
      }
    } catch (error) {
      message.error("Failed to update settings");
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchSettingsData();
  }, []);

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <div style={{ padding: "5px", borderRadius: "8px" }}>
        {/* Security Settings Header */}
        <Title
          level={4}
          style={{ marginBottom: "20px", fontWeight: "bold", color: "#333" }}
        >
          Security Settings
        </Title>

        <Card
          style={{
            background: "#fff",
            borderRadius: "8px",
            boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
          }}
          bodyStyle={{
            maxHeight: "80vh", // Limit height to 60% of the viewport
            overflowY: "auto", // Enable scrolling within the Card's body
            paddingRight: "16px",
          }}
        >
          {/* Account Status Section */}
          <div style={{ marginBottom: "20px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "10px",
              }}
            >
              <Text strong>Account Status</Text>
              <Switch
                checked={settingsData?.accountstatus}
                onChange={(value) => handleChange("accountstatus", value)}
              />
            </div>
            {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
              marginTop: "25px",
            }}
          >
            <div>
              <Text strong>Manager Authorization</Text>
              <p style={{ color: "#888" }}>
                Applicable for voiding bills, confirming free of charge payment,
                and applied discount rate
              </p>
            </div>
            <Switch
              checked={settingsData?.managerauth}
              onChange={(value) => handleChange("managerauth", value)}
              // onClick={handleModalManageAuthOpen}
            />
          </div> */}
            {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            <Text strong>Refund Authorization</Text>
            <Switch
              checked={settingsData?.refundauth}
              onChange={(value) => handleChange("refundauth", value)}
              onClick={handleRefundConfirmationModalOpen}
            />
          </div> */}
            {/* <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Text strong>Enable Appointment</Text>
            <Switch
              checked={settingsData?.enableappointment}
              onChange={(value) => handleChange("enableappointment", value)}
            />
          </div> */}
            {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Text strong>Online Booking</Text>
          <Switch defaultChecked />
        </div> */}
          </div>

          {/* Login Section */}
          <Divider />
          <div style={{ marginBottom: "20px" }}>
            <Row
              gutter={16}
              align="middle"
              justify="space-between"
              style={{ width: "100%" }}
            >
              <Col>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Title
                    level={5}
                    style={{ color: "#333", margin: 0, fontWeight: "normal" }}
                  >
                    Log-in Daco portal
                  </Title>
                  <Tooltip title="Allow the staff to log in and use various functions of the portal">
                    <InfoCircleOutlined
                      style={{
                        marginLeft: "5px",
                        color: "blue",
                        cursor: "pointer",
                      }}
                    />
                  </Tooltip>
                </div>
              </Col>
              <Col>
                {/* <Switch defaultChecked  /> */}
                <Switch
                  style={{ marginLeft: "40%" }}
                  checked={settingsData?.staffportalapp}
                  onChange={(value) => handleChange("staffportalapp", value)}
                />
              </Col>
            </Row>
            <Title
              level={5}
              style={{ color: "#333", marginTop: "3%", fontWeight: "normal" }}
            >
              Login with password
              <Tooltip title="Default password：123456">
                <InfoCircleOutlined
                  style={{
                    marginLeft: "8px",
                    color: "blue",
                    cursor: "pointer",
                  }}
                />
              </Tooltip>
            </Title>
            <div
              style={{
                display: "flex",
                // justifyContent: "space-between",
                alignItems: "center",
                marginTop: "5px",
              }}
            >
              <Text style={{ color: "#888" }}>
                Login account: {settingsData?.loginaccount}
              </Text>
              <Text style={{ color: "#888", marginLeft: "5%" }}>
                Password： ******
              </Text>
              <Button type="link" onClick={handleEditLoginPasswordOpen}>
                Edit
              </Button>
            </div>
          </div>

          {/* Permissions Section */}
          <Divider />
          <Title level={5} style={{ color: "#333" }}>
            Permissions
          </Title>

          {/* Permissions Grouped Sections */}
          <div>
            <Card
              title="Customer Management"
              bordered={false}
              style={{ marginBottom: "10px" }}
              headStyle={{ backgroundColor: "#eaeefe" }} // Set header color
            >
              <Checkbox
                checked={settingsData?.customerview}
                onChange={(e) => handleChange("customerview", e.target.checked)}
              >
                View customers
              </Checkbox>
              {/* <Checkbox
              checked={settingsData?.viewcustomerphone}
              onChange={(e) =>
                handleChange("viewcustomerphone", e.target.checked)
              }
            >
              View customer's phone number/email
            </Checkbox> */}
              <Checkbox
                checked={settingsData?.addnewcustomer}
                onChange={(e) =>
                  handleChange("addnewcustomer", e.target.checked)
                }
                style={{ marginLeft: "10px" }}
              >
                Add new customer
              </Checkbox>
            </Card>

            {/* <Card
            title="Home"
            bordered={false}
            style={{ marginBottom: "10px" }}
            headStyle={{ backgroundColor: "#eaeefe" }}
          >
            <Checkbox
              checked={settingsData?.performanceranking}
              onChange={(e) =>
                handleChange("performanceranking", e.target.checked)
              }
            >
              Performance Ranking
            </Checkbox>
            <Checkbox
              checked={settingsData?.customerreview}
              onChange={(e) => handleChange("customerreview", e.target.checked)}
              style={{ marginLeft: "10px" }}
            >
              Customer Reviews
            </Checkbox>
          </Card> */}

            {/* <Card
            title="Appointment"
            bordered={false}
            style={{ marginBottom: "10px", borderRadius: "8px" }}
            headStyle={{ backgroundColor: "#eaeefe", fontWeight: "bold" }}
            bodyStyle={{ padding: "16px" }}
          >
            <Row gutter={[16, 8]}>
              <Col span={8}>
                <Checkbox
                  checked={settingsData?.addnewappointment}
                  onChange={(e) =>
                    handleChange("addnewappointment", e.target.checked)
                  }
                >
                  Add new appointment
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox
                  checked={settingsData?.addblocktime}
                  onChange={(e) =>
                    handleChange("addblocktime", e.target.checked)
                  }
                >
                  Add blocked time
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox
                  checked={settingsData?.editappointment}
                  onChange={(e) =>
                    handleChange("editappointment", e.target.checked)
                  }
                >
                  Edit appointment
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox
                  checked={settingsData?.cancleappointment}
                  onChange={(e) =>
                    handleChange("cancleappointment", e.target.checked)
                  }
                >
                  Cancel appointment
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox
                  checked={settingsData?.checkout}
                  onChange={(e) => handleChange("checkout", e.target.checked)}
                >
                  Check out
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox
                  checked={settingsData?.confirmappointment}
                  onChange={(e) =>
                    handleChange("confirmappointment", e.target.checked)
                  }
                >
                  Confirm appointment
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox
                  checked={settingsData?.rejectappointment}
                  onChange={(e) =>
                    handleChange("rejectappointment", e.target.checked)
                  }
                >
                  Reject appointment
                </Checkbox>
              </Col>
              <Col span={8}>
                <Checkbox
                  checked={settingsData?.viewappointmentotherstaff}
                  onChange={(e) =>
                    handleChange("viewappointmentotherstaff", e.target.checked)
                  }
                >
                  View appointment for other staffs
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  checked={settingsData?.addappointmentotherstaff}
                  onChange={(e) =>
                    handleChange("addappointmentotherstaff", e.target.checked)
                  }
                >
                  Add appointment for other staffs
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  checked={settingsData?.editcancelappointmentotherstaff}
                  onChange={(e) =>
                    handleChange(
                      "editcancelappointmentotherstaff",
                      e.target.checked
                    )
                  }
                >
                  Edit/Cancel other staff's appointment
                </Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox
                  checked={settingsData?.addcancelblockotherstaff}
                  onChange={(e) =>
                    handleChange("addcancelblockotherstaff", e.target.checked)
                  }
                >
                  Add/Cancel block of other staffs
                </Checkbox>
              </Col>
            </Row>
          </Card> */}

            <Card
              title="Sales"
              bordered={false}
              style={{ marginBottom: "10px", borderRadius: "8px" }}
              headStyle={{ backgroundColor: "#eaeefe", fontWeight: "bold" }}
              bodyStyle={{ padding: "16px" }}
            >
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Checkbox
                    checked={settingsData?.billing}
                    onChange={(e) => handleChange("billing", e.target.checked)}
                  >
                    Billing
                  </Checkbox>
                </Col>
                {/* <Col span={6}>
                <Checkbox
                  checked={settingsData?.editpricediscount}
                  onChange={(e) =>
                    handleChange("editpricediscount", e.target.checked)
                  }
                >
                  Edit price/discount
                </Checkbox>
              </Col> */}
                {/* <Col span={6}>
                <Checkbox
                  checked={settingsData?.editproductconsumption}
                  onChange={(e) =>
                    handleChange("editproductconsumption", e.target.checked)
                  }
                >
                  Edit product consumption
                </Checkbox>
              </Col> */}
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                {/* <Col span={6}>
                <Checkbox
                  checked={settingsData?.checkoutsales}
                  onChange={(e) =>
                    handleChange("checkoutsales", e.target.checked)
                  }
                >
                  Check out
                </Checkbox>
              </Col> */}
                {/* <Col span={8}>
                <Checkbox
                  checked={settingsData?.editservicestaffcheckout}
                  onChange={(e) =>
                    handleChange("editservicestaffcheckout", e.target.checked)
                  }
                >
                  Edit service staff after checkout
                </Checkbox>
              </Col> */}
                <Col span={4}>
                  <Checkbox
                    checked={settingsData?.onlinebilling}
                    onChange={(e) =>
                      handleChange("onlinebilling", e.target.checked)
                    }
                  >
                    Online sales
                  </Checkbox>
                </Col>
              </Row>

              {/* <Row gutter={[16, 8]} align="middle" style={{ marginTop: "10px" }}>
              <Col span={6}>
                <Checkbox
                  checked={settingsData?.limitdaysbill}
                  onChange={(e) =>
                    handleChange("limitdaysbill", e.target.checked)
                  }
                >
                  Limit days to view bill
                </Checkbox>
              </Col>
              <Col span={3}>
                <Input
                  value={settingsData?.billdays}
                  onChange={(e) =>
                    handleChange("billdays", parseInt(e.target.value) || 0)
                  }
                  style={{ width: "230px", textAlign: "center" }}
                />
              </Col>
              <Col span={2}>
                <Text style={{ color: "#888" }}>days</Text>
              </Col>
            </Row> */}

              {/* <Row gutter={[16, 8]} align="middle" style={{ marginTop: "10px" }}>
              <Col span={6}>
                <Checkbox
                  checked={settingsData?.limitdaysrebill}
                  onChange={(e) =>
                    handleChange("limitdaysrebill", e.target.checked)
                  }
                >
                  Limit days to rebill
                </Checkbox>
              </Col>
              <Col span={3}>
                <Input
                  value={settingsData?.rebilldays}
                  onChange={(e) =>
                    handleChange("rebilldays", parseInt(e.target.value) || 0)
                  }
                  style={{ width: "230px", textAlign: "center" }}
                />
              </Col>
              <Col span={2}>
                <Text style={{ color: "#888" }}>days</Text>
              </Col>
            </Row> */}

              <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                {/* <Col span={6}>
                <Checkbox
                  checked={settingsData?.deleteunpaidbill}
                  onChange={(e) =>
                    handleChange("deleteunpaidbill", e.target.checked)
                  }
                >
                  Delete unpaid bill
                </Checkbox>
              </Col> */}
                <Col span={6}>
                  {/* <Checkbox
                  checked={settingsData?.editaccountingdate}
                  onChange={(e) =>
                    handleChange("editaccountingdate", e.target.checked)
                  }
                >
                  Edit accounting date
                </Checkbox> */}
                </Col>
                {/* <Col span={6}>
                <Checkbox
                  checked={settingsData?.editbillnumber}
                  onChange={(e) =>
                    handleChange("editbillnumber", e.target.checked)
                  }
                >
                  Edit bill number
                </Checkbox>
              </Col> */}
              </Row>

              {/* <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
              <Col span={6}>
                <Checkbox
                  checked={settingsData?.mergebill}
                  onChange={(e) => handleChange("mergebill", e.target.checked)}
                >
                  Merge bills
                </Checkbox>
              </Col>
              <Col span={6}>
                <Checkbox
                  checked={settingsData?.changeservicestaff}
                  onChange={(e) =>
                    handleChange("changeservicestaff", e.target.checked)
                  }
                >
                  Change service staff
                </Checkbox>
              </Col>
            </Row> */}
            </Card>

            <Card
              title="Inventory"
              bordered={false}
              style={{ marginBottom: "10px", borderRadius: "8px" }}
              headStyle={{ backgroundColor: "#eaeefe", fontWeight: "bold" }}
              bodyStyle={{ padding: "16px" }}
            >
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Checkbox
                    checked={settingsData?.stockinview}
                    onChange={(e) =>
                      handleChange("stockinview", e.target.checked)
                    }
                  >
                    View Stock In
                  </Checkbox>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
                <Col span={4}>
                  <Checkbox
                    checked={settingsData?.stockoutview}
                    onChange={(e) =>
                      handleChange("stockoutview", e.target.checked)
                    }
                  >
                    View Stock Out
                  </Checkbox>
                </Col>
              </Row>
            </Card>

            <Card
              title="Staff"
              bordered={false}
              style={{ marginBottom: "10px", borderRadius: "8px" }}
              headStyle={{ backgroundColor: "#eaeefe", fontWeight: "bold" }}
              bodyStyle={{ padding: "16px" }}
            >
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <Checkbox
                    checked={settingsData?.staffList}
                    onChange={(e) =>
                      handleChange("staffList", e.target.checked)
                    }
                  >
                    View Staff
                  </Checkbox>
                </Col>
              </Row>

              {/* <Row gutter={[16, 16]} style={{ marginTop: "10px" }}>
              <Col span={4}>
                <Checkbox
                  checked={settingsData?.voidbill}
                  onChange={(e) => handleChange("voidbill", e.target.checked)}
                >
                  Edit staff Details
                </Checkbox>
              </Col>
            </Row> */}
            </Card>

            {/* Additional sections for other permissions (Management, Inventory, etc.) can be added here */}
          </div>

          <ManagerAuthorizationModel
            visible={isManageAuthModalVisible}
            onCancel={handleManageAuthModalClose}
            onSave={handleManageAuthSave}
            staffcode={settingsData?.managerstaffcode || 0}
            authorizationcode={settingsData?.managerauthorizationcode || 0}
          />

          <RefundConfirmationModel
            visible={isRefundConfirmationModelVisible}
            onCancel={handleRefundConfirmationModalClose}
            onSave={handleRefundConfirmationSave}
            staffcode={settingsData?.refundstaffcode || 0}
            authorizationcode={settingsData?.refundauthcode || 0}
          />

          <EditLoginPasswordModel
            visible={isEditLoginPasswordModelVisible}
            onCancel={handleEditLoginPasswordClose}
            onSave={handleEditLoginPasswordSave}
            account={settingsData?.loginaccount || ""}
          />
        </Card>
      </div>
    </>
  );
};

export default StaffInfo;
