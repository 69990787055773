import { Button, DatePicker, Divider, Form, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { get, put } from "../../../../services/Apiclient";
import TextArea from "antd/es/input/TextArea";
import moment from "moment";
import AttendanceAddTimeOff from "./AttendanceAddTimeOff";

interface AttendanceModalProps {
  visible: boolean;
  onCancel: () => void;
  staffData: any;
  onSaveSuccess?: () => void; // Callback to refresh data in parent modal
}

const AttendanceReviewEditModal: React.FC<AttendanceModalProps> = ({
  visible,
  onCancel,
  staffData,
  onSaveSuccess,
}) => {
  const outletId = localStorage.getItem("outlet_id");
  const [reviewData, setReviewData] = useState<any>(null);
  const [form] = Form.useForm();
  const [isTimeOffModalVisible, setIsTimeOffModalVisible] = useState(false)

  const getReviewByIdData = async () => {
    if (!outletId || !staffData) return;
    try {
      const { id, shiftid, staffid } = staffData;
      const response = await get(
        `/attendance/attendancedetails/${id}/${shiftid}/${staffid}`
      );
      if (response?.data?.success && response.data.data) {
        setReviewData(response.data.data);
        // Pre-fill the form with the fetched data
        form.setFieldsValue({
          clockin: response.data.data.clockin
            ? moment(response.data.data.clockin, "DD-MM-YYYY HH:mm")
            : null,
          clockout: response.data.data.clockout
            ? moment(response.data.data.clockout, "DD-MM-YYYY HH:mm")
            : null,
          note: response.data.data.note || "",
        });
      }
    } catch (error) {
      console.error("Error fetching selected review:", error);
      setReviewData(null);
    }
  };

  useEffect(() => {
    if (visible && staffData) {
      getReviewByIdData();
    }
  }, [visible, staffData]);

  const onFinish = async (values: any) => {
    try {
      const { id } = staffData;
      const payload = {
        note: values.note || "",
        clockin: values.clockin.format("DD-MM-YYYY HH:mm"),
        clockout: values.clockout.format("DD-MM-YYYY HH:mm"),
      };

      const response = await put(`/attendance/updateattendance/${id}`, payload);
      if (response?.data?.success) {
        message.success("Attendance updated successfully");
        onSaveSuccess?.();
        onCancel();
      } else {
        message.error("Failed to update attendance");
      }
      console.log("Payload:", payload);
    } catch (error) {
      console.error("Error updating attendance:", error);
      message.error("An error occurred while updating attendance");
    }
  };

  // Handle opening the Time Off modal
  const handleTimeOffClick = () => {
    setIsTimeOffModalVisible(true);
  };

  // Handle closing the Time Off modal
  const handleTimeOffModalClose = () => {
    setIsTimeOffModalVisible(false);
  };

  return (
    <>
      <Modal
        visible={visible}
        onCancel={onCancel}
        bodyStyle={{ height: "450px", overflowY: "auto" }}
        width={400}
        centered
        footer={[
          <>
            <Divider />
            <Button key="request" type="default" onClick={handleTimeOffClick}>
              Request time off
            </Button>
            <Button key="save" type="primary" onClick={() => form.submit()}>
              Save
            </Button>
          </>,
        ]}
      >
        <div>
          <h3 style={{ margin: 0, fontSize: 16, fontWeight: 600 }}>
            Attendance details
          </h3>
          <div
            style={{
              marginTop: 16,
              padding: "16px",
              borderRadius: "8px",
              backgroundColor: "#24bee2",
              color: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "5px",
              }}
            >
              <p style={{ margin: 0 }}>
                Staff:{" "}
                <strong>{reviewData?.staff_name || "Unknown Staff"}</strong>
              </p>
              <p style={{ margin: 0 }}>
                {reviewData?.starttime} - {reviewData?.endtime}
              </p>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <p style={{ margin: 0 }}>Date: {reviewData?.date || "-"}</p>
              <p style={{ margin: 0 }}>
                {reviewData?.starttime || "-"} - {reviewData?.endtime || "-"}
              </p>
            </div>
          </div>
          <Form
            style={{ marginTop: "15px" }}
            form={form}
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              label="Clock-in"
              name="clockin"
              rules={[
                { required: true, message: "Please select clock-in time" },
              ]}
            >
              <DatePicker
                style={{ width: "100%", height: "40px" }}
                placeholder="Select date and time"
                showTime={{ format: "HH:mm" }}
                format="DD-MM-YYYY HH:mm"
              />
            </Form.Item>
            <Form.Item
              label="Clock-out"
              name="clockout"
              rules={[
                { required: true, message: "Please select clock-out time" },
              ]}
            >
              <DatePicker
                style={{ width: "100%", height: "40px" }}
                placeholder="Select date and time"
                showTime={{ format: "HH:mm" }}
                format="DD-MM-YYYY HH:mm"
              />
            </Form.Item>
            <Form.Item label="Note" name="note">
              <TextArea rows={3} placeholder="Please enter" />
            </Form.Item>
          </Form>
        </div>
      </Modal>

      {/* Render the Time Off Modal */}
      <AttendanceAddTimeOff
        visible={isTimeOffModalVisible}
        onCancel={handleTimeOffModalClose}
        staffData={staffData}
        onSaveSuccess={onSaveSuccess}
      />
    </>
  );
};

export default AttendanceReviewEditModal;
