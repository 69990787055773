import React, { useEffect, useState } from "react";
import {
  Layout,
  Button,
  Input,
  Row,
  Col,
  Menu,
  Checkbox,
  Dropdown,
  message,
  Pagination,
} from "antd";
import {
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { deleteData, get, put } from "../../services/Apiclient";
import Datatable from "../../components/Datatable";
import { ArrowLeftOutlined, FilterOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "../../styles/global.css";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import ServiceCategoryModel from "../../components/model/ServiceCategoryModel";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const { Content } = Layout;

interface RoomData {
  key: React.Key;
  roomcode: string;
  roomname: string;
  category: string;
  status: string;
}

function RoomList() {
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<RoomData[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  // State for delete modal
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState<RoomData | null>(null);

  const [status, setStatus] = useState<string[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [valueChangeSearch, setValueChangeSearch] = useState(false);
  const [originalDataSource, setOriginalDataSource] = useState<RoomData[]>([]);
  const [isMultipleDeleteModalVisible, setIsMultipleDeleteModalVisible] =
    useState(false);
  const [searchText, setSearchText] = useState("");

  const handleDeleteAllSelected = () => {
    setIsMultipleDeleteModalVisible(true); // Show delete confirmation modal
  };

  const handleDeleteCancel = () => {
    setIsMultipleDeleteModalVisible(false); // Hide delete confirmation modal
  };

  const handleDeleteConfirm = async () => {
    try {
      const roomarr = selectedRowKeys;
      const body = { roomarr };
      const response = await put("/room/multipleremove", body); // Call API

      if (response?.status === 200 && response.data.success) {
        // Remove deleted items from the data source
        const updatedDataSource = dataSource.filter(
          (item) => !selectedRowKeys.includes(item.key)
        );
        setDataSource(updatedDataSource);
        setSelectedRowKeys([]);
        message.success("Selected items deleted successfully");
      } else {
        message.error("Failed to delete selected items");
      }
    } catch (error) {
      message.error("An error occurred while deleting items");
      console.error("Error deleting items:", error);
    } finally {
      setIsMultipleDeleteModalVisible(false); // Close the modal
    }
  };

  const filterIconStyle = {
    color: status.length > 0 ? "#1890ff" : "#aaa",
    cursor: "pointer",
    marginLeft: "8px",
  };

  // Handle delete click
  const handleDeleteClick = (record: RoomData) => {
    setSelectedRoom(record); // Set the room that is selected for deletion
    setDeleteModalVisible(true); // Show the delete modal
  };

  const handleEmploymentStatusChange = (checkedValues: any) => {
    setStatus(checkedValues);
  };

  const applyFilter = () => {
    if (status.length === 0) {
      setDataSource(originalDataSource);
    } else {
      const filteredData = originalDataSource.filter((item) =>
        status.includes(item.status)
      );
      setDataSource(filteredData);
    }
    setDropdownVisible(false);
  };

  const resetFilter = () => {
    setStatus([]);
    setDataSource(originalDataSource);
  };

  // Confirm deletion
  const handleConfirmDelete = async () => {
    if (selectedRoom) {
      try {
        const url = `/room/delete/${selectedRoom.key}`;
        const response = await deleteData(url);

        if (response?.status === 200 || response?.status === 204) {
          // Assuming the room was successfully deleted
          console.log("Deleted room with key:", selectedRoom.key);

          // Update the dataSource to remove the deleted room from the table
          setDataSource((prevData) =>
            prevData.filter((item) => item.key !== selectedRoom.key)
          );

          // Close the delete modal
          setDeleteModalVisible(false);
          message.success("Deleted successfully");
        } else {
          console.error("Error deleting room:", response);
          // Handle the error scenario here (e.g., show a notification or error message)
        }
      } catch (error) {
        console.error("Error during delete operation:", error);
      }
    }
  };

  // Cancel deletion
  const handleCancelDelete = () => {
    setDeleteModalVisible(false); // Close the modal without deleting
  };

  // Employment Status Menu
  const roomStatus = (
    <Menu>
      <Checkbox.Group
        style={{ padding: "10px" }}
        value={status}
        onChange={handleEmploymentStatusChange}
      >
        <Row>
          <Col span={24}>
            <Checkbox value="Active">Active</Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="InActive">Inactive</Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
      <Row justify="space-between" style={{ padding: "10px" }}>
        <Button onClick={resetFilter}>Reset</Button>
        <Button type="primary" onClick={applyFilter}>
          OK
        </Button>
      </Row>
    </Menu>
  );

  const columns = [
    {
      title: "Room Code",
      dataIndex: "roomcode",
      key: "roomcode",
      width: 150,
      className: "column-spacing", // Add custom class
    },
    {
      title: "Room Name",
      dataIndex: "roomname",
      key: "roomname",
      width: 200,
      className: "column-spacing", // Add custom class
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 150,
      className: "column-spacing", // Add custom class
    },
    {
      title: (
        <>
          Status
          <Dropdown
            overlay={roomStatus}
            trigger={["click"]}
            visible={dropdownVisible}
            onVisibleChange={(flag) => setDropdownVisible(flag)}
            overlayStyle={{ width: "150px" }} // Adjust size here
          >
            <FilterOutlined style={filterIconStyle} />
          </Dropdown>
        </>
      ),
      dataIndex: "status",
      key: "status",
      width: 100,
      className: "column-spacing", // Add custom class
      render: (status: string) => (
        <div>
          <span
            className={`status-dot ${
              status === "Active" ? "active" : "inactive"
            }`}
          ></span>
          <span className="status-text">{status}</span>
        </div>
      ),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right" as "right",
      width: 150,
      className: "column-spacing", // Add custom class
      render: (text: any, record: RoomData): React.ReactNode => (
        <>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => navigate(`/settings/room/edit/${record.key}`)}
          >
            Edit
          </a>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => navigate(`/settings/room/duplicate/${record.key}`)}
          >
            Duplicate
          </a>
          <a
            style={{
              color: "rgb(245, 63, 63)",
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
            }}
            onClick={() => handleDeleteClick(record)}
          >
            Delete
          </a>
        </>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      const url = `/room/${localStorage.getItem(
        "outlet_id"
      )}/${currentPage}/${pageSize}/created_at/DESC/${searchText || null}`;
      const response = await get(url);
      if (response?.data?.success) {
        const apiData = response.data.data.map((item: any) => ({
          key: item.id,
          roomcode: item.code,
          roomname: item.name,
          category: item.categoryname,
          status: item.status,
        }));
        setDataSource(apiData);
        setOriginalDataSource(apiData);
        setTotalRecords(response.data.totalRecords);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize]);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const handleSearch = () => {
    fetchData();
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };

  useEffect(() => {
    if (valueChangeSearch) {
      fetchData();
    }
  }, [valueChangeSearch]);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await setSearchText(e.target.value);
    await setValueChangeSearch(e.target.value.length === 0);
  };

  const handleSearchClear = async () => {
    await setSearchText("");
    setValueChangeSearch(true);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Content className="mainContainer">
        {/* Header Section */}
        <Row justify="space-between" align="middle">
          <Col>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                onClick={() => navigate("/settings")}
                style={{ cursor: "pointer" }}
              >
                <ArrowLeftOutlined
                  style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
                />
              </div>
              <h2 style={{ margin: 0 }}>Room Management</h2>
            </div>
          </Col>
          <Col>
            <Button
              className="add-button"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => navigate("/settings/room/add")}
            >
              Add
            </Button>
          </Col>
        </Row>

        {/* Content Section */}
        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#f6f7f9",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
          }}
        >
          {/* Filters */}
          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col flex="auto">
              <Input
                placeholder="Search by code / name"
                style={{
                  width: "300px",
                  height: "42px",
                  padding: "10px 16px 10px 16px",
                  alignItems: "center",
                }}
                value={searchText}
                onChange={handleInputChange}
                onClear={handleSearchClear}
                allowClear
                onKeyPress={handleKeyPress}
                prefix={
                  <SearchOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                      marginRight: "2px",
                      alignItems: "center",
                    }}
                  />
                }
              />
            </Col>
            <Col>
              <Button type="primary" onClick={handleSearch}>
                Search
              </Button>
            </Col>
          </Row>

          {/* Table */}
          <Datatable<RoomData>
            columns={columns}
            dataSource={dataSource}
            showPagination={false}
            scroll={{ y: "calc(100vh - 260px)" }}
            showCheckbox={true}
            rowSelection={rowSelection}
            dynamicTableHeight="calc(100vh - 240px)"
          />

          <Row
            justify="space-between"
            style={{
              marginTop: "16px",
              padding: "10px 20px",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            {/* Left Buttons */}
            <Col>
              <Button
                type="default"
                style={{ marginRight: "10px" }}
                onClick={handleDeleteAllSelected}
              >
                Delete all selected
              </Button>
            </Col>

            {/* Right Section */}
            {totalRecords > 0 && (
              <Col>
                <Row justify="end" align="middle" style={{ gap: "16px" }}>
                  {/* Total Items Count */}
                  <Col>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(0,0,0,.85)",
                      }}
                    >
                      Total {totalRecords} items
                    </span>
                  </Col>

                  {/* Pagination */}
                  <Col>
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={totalRecords}
                      onChange={handlePageChange}
                      showSizeChanger={false}
                      itemRender={(page, type, originalElement) => {
                        if (type === "prev") {
                          const isDisabled = currentPage === 1;
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <LeftOutlined />
                              <span>Previous</span>
                            </a>
                          );
                        }
                        if (type === "next") {
                          const isDisabled =
                            currentPage === Math.ceil(totalRecords / pageSize);
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <span>Next</span>
                              <RightOutlined />
                            </a>
                          );
                        }
                        return originalElement;
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>

          {/* Bottom Buttons */}
          {/* <Row justify="start">
          <Col>
            <Button
              type="default"
              style={{ marginRight: "10px" }}
              onClick={handleDeleteAllSelected}
            >
              Delete all selected
            </Button>
          </Col>
        </Row> */}
        </Content>
        {/* Delete Confirmation Modal */}
        <ConfirmDeleteModal
          visible={deleteModalVisible}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          message="Delete the room?"
        />

        {/* Delete Multiple Confirmation Modal */}
        <ConfirmDeleteModal
          visible={isMultipleDeleteModalVisible}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          message="Delete the room?"
        />
      </Content>
    </>
  );
}

export default RoomList;
