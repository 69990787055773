import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Input } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

interface AuthorizationModelProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (data: {
    account: string;
    password: string;
    confirmpassword: string;
  }) => void;
  account: string;
}

const EditLoginPasswordModel: React.FC<AuthorizationModelProps> = ({
  visible,
  onCancel,
  onSave,
  account,
}) => {
  // Use Ant Design Form Hook
  const [form] = Form.useForm();

  // Reset form fields when the modal is opened
  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);

  // Handle Save
  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        onSave(values); // Call onSave if validation passes
      })
      .catch((errorInfo) => {
        console.log("Validation Failed:", errorInfo);
      });
  };

  return (
    <Modal
      title="Edit Login Password"
      visible={visible}
      onCancel={onCancel}
      footer={null}
      centered
      width={400}
      className="add-model"
    >
      <Form
        form={form}
        layout="vertical"
        style={{ marginTop: "20px" }}
        initialValues={{
          account: account,
        }}
      >
        <Form.Item
          label="Account"
          name="account"
          style={{ marginLeft: "24px", marginRight: "24px" }}
        >
          <Input value={account} disabled />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          style={{
            marginLeft: "24px",
            marginRight: "24px",
            fontSize: "12px",
            color: "#666",
          }}
          rules={[
            { required: true, message: "Please enter your password" },
            { min: 6, message: "Password must be at least 6 characters" },
          ]}
        >
          <Input.Password
            iconRender={(visible) =>
              visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
            }
            placeholder="Please enter"
          />
        </Form.Item>

        <Form.Item
          label="Confirm Password"
          name="confirmpassword"
          style={{
            marginLeft: "24px",
            marginRight: "24px",
            fontSize: "12px",
            color: "#666",
          }}
          dependencies={["password"]}
          rules={[
            { required: true, message: "Please confirm your password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("Passwords do not match")
                );
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Please enter"
          />
        </Form.Item>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "12px",
            marginTop: "40px",
            paddingTop: "10px",
            paddingBottom: "10px",
            border: "1px solid #D9D9D9",
          }}
        >
          <Button
            type="primary"
            onClick={handleSave}
            style={{
              marginRight: "24px",
              background: "#2e56f2",
              color: "#fff",
              padding: "5px 12px",
              minWidth: "72px",
            }}
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditLoginPasswordModel;
