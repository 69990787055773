import { Card } from "antd";
import { Link } from "react-router-dom";

const ReportCard = ({ Image, title, link }: any) => (
  <Link to={link} style={{ textDecoration: "none" }}>
    <Card
      hoverable
      style={{
        width: "100px",
        borderRadius: "8px",
        border: "0px",
        display: "flex",
        alignItems: "center",
        // backgroundColor: bgColor, // Use bgColor for the card background
      }}
      bodyStyle={{
        alignItems: "center",
        padding: "16px",
      }}
    >
      <div
        style={{
          fontSize: "20px",
          color: "black",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          marginBottom: "8px",
        }}
      >
        <div
          style={{
            width: "35px",
            height: "35px",
            borderRadius: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={Image}
            alt={"Image"}
            style={{
              width: "50px",
              height: "50px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
      </div>
      <div>
        <div
          style={{
            fontSize: "13px",
            fontWeight: "700",
            color: "#666", // White text to contrast with bgColor
            textAlign: "center",
          }}
        >
          {title}
        </div>
      </div>
    </Card>
  </Link>
);

export default ReportCard;
