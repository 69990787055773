import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Tabs,
  Button,
  Avatar,
  Typography,
  Divider,
  Switch,
  Popover,
  Empty,
  message,
} from "antd";
import {
  SettingOutlined,
  ArrowLeftOutlined,
  RightOutlined,
  LeftOutlined,
  EditOutlined,
  CalendarOutlined,
  UserOutlined,
  EyeInvisibleOutlined,
  PhoneFilled,
  MailFilled,
  ClearOutlined,
  CopyOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import StoreSelectionModal from "../../components/model/customer/StoreSelectionModal";
import RemarkModal from "../../components/model/customer/RemarkModal";
import HistoricalVisitModal from "../../components/model/customer/HistoricalVisitModal";
import AdjustmentModal from "../../components/model/customer/AdjustmentModal";
import DebtModal from "../../components/model/customer/DebtModal";
import PointDetailsModal from "../../components/model/customer/PointDetailsModal";
import GiftcardSection from "../../components/model/customer/GiftcardSection";
import MembershipSection from "../../components/model/customer/MembershipSection";
import PackageSection from "../../components/model/customer/PackageSection";
import SalesHistory from "../../components/model/customer/SalesHistory";
import AppointmentsHistory from "../../components/model/customer/AppointmentsHistory";
import FormSection from "../../components/model/customer/FormSection";
import ChangePasswordModal from "../../components/model/customer/ChangePasswordModal";
import { deleteData, get, put } from "../../services/Apiclient";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import TagSelectModal from "../../components/model/customer/TagSelectModal";
import DateSelectionModal from "../../components/model/DateSelectionModal";
import ServiceLogs from "../../components/model/customer/ServiceLog";
import CommunicationRecords from "../../components/model/customer/CommunicationRecords";
import HistoricalCollectedPaymentModal from "../../components/model/customer/HistoricalCollectedPaymentModal";
import KIVSection from "../../components/model/customer/KIVSection";
import CourseSection from "../../components/model/customer/CourseSection";
import OnlineSalesSection from "../../components/model/customer/OnlineSalesSection";
// import { currencySymbol } from "../../constants/currencySymbol";
import CustomerDebtModal from "../../components/model/customer/CustomerDebtModal";
import { useCurrency } from "../../contexts/CurrencyContext";
import { Helmet } from "react-helmet";
const emptyImageUrl = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_noData.png`;
const visitIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Bg_clients_ZDDCS.png`;
const collectedPaymentIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Bg_clients_ZXFZE.png`;
const bonusBalanceIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Bg_clients_CZYE.png`;
const debitIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Bg_clients_WFKX.png`;
const loyaltyPointIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Bg_clients_LoyaltyPoints.png`;
const consumptionDateIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Bg_clients_ZHXFRQ.png`;
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;

const { TabPane } = Tabs;
const { Text, Title } = Typography;

interface CustomerData {
  id: string;
  status: boolean;
  outlet_id: string;
  merchant_id: string;
  customercode: string;
  firstname: string;
  lastname: string;
  countrycode: string;
  phonenumber: string;
  email: string;
  gender: string;
  physicalcard: string;
  dob: string | null; // Use `string | null` for date
  language: string;
  notes: string;
  source: string;
  source_name: string;
  referralby: string;
  referralid: string;
  firstconsultant: string;
  secondconsultant: string;
  thirdconsultant: string;
  referralname: string;
  firstconsultantname: string;
  secondconsultantname: string;
  thirdconsultantname: string;
  passportid: string;
  emergency_contact: string;
  alternate_number: string;
  homeaddress: string;
  occupation: string;
  whatsapp: boolean;
  facebook: string;
  instagram: string;
  password_status: boolean;
  password: string | null;
  customer_status: boolean;
  sms: boolean;
  emailstatus: boolean;
  marketing: boolean;
  active_member: boolean;
  store_name?: string;
  created_at?: string;
  previousData: string | null;
  nextData: string | null;
  debt: string | null;
  customerdata: {
    total_visit: string;
    total_payment: string;
    stock_bonus: any;
    debt: string;
    loyalty_pts: string;
    last_consumption_date: string;
  }[];
}

const CustomerProfile = () => {
  const { currencySymbol } = useCurrency();
  const navigate = useNavigate();
  const { id } = useParams();
  const [customerData, setCustomerData] = useState<CustomerData | null>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showStoreSelectionModal, setShowStoreSelectionModal] = useState(false);
  const [showRemarkModal, setShowRemarkModal] = useState(false);
  const [showHistoricalVisitModal, setShowHistoricalVisitModal] =
    useState(false);
  const [showHistoricalPaymentModal, setShowHistoricalPaymentModal] =
    useState(false);
  const [showCustomTagModal, setShowCustomTagModal] = useState(false);
  const [showAdjustModal, setShowAdjustModal] = useState(false);
  const [showDebtModal, setShowDebtModal] = useState(false);
  const [showPointDetailsModal, setShowPointDetailsModal] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showCustomerStatus, setShowCustomerStatus] = useState(false);
  const [showSmsNotification, setShowSmsNotification] = useState(false);
  const [showEmailNotification, setShowEmailNotification] = useState(false);
  const [showWhatsappNotification, setShowWhatsappNotification] =
    useState(false);
  const [showMarketingNotification, setShowMarketingNotification] =
    useState(false);
  const [showMemberStatus, setShowMemberStatus] = useState(false);
  const [showCustomerDebtModal, setShowCustomerDebtModal] = useState(false);
  // const [customerMemberDebtData, setCustomerMemberDebtData] = useState<any>([]);
  const [onlinesalesData, setonlinesalesData] = useState<any[]>([]);
  const [kivData, setkivData] = useState<any[]>([]);
  const [courseData, setcourseData] = useState<any[]>([]);

  const handleUpdateData = async (field: any, status: any) => {
    let payload = {
      [field]: status,
    };
    await put(`/customer/updatedata/${id}`, payload);
    message.success("Successfully edited!");
  };

  const openDeleteModal = () => setIsDeleteModalVisible(true);
  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const handleConfirm = () => {
    handleDeleteApi();
    closeDeleteModal();
  };

  const handleDeleteApi = async () => {
    try {
      const response = await deleteData(`/customer/remove/${id}`);
      if (response.data.success) {
        message.success("Customer deleted successfully!");
        navigate("/customer");
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Error deleting Customer");
    }
  };

  const fetchCustomerData = async () => {
    try {
      const response = await get(
        `/customer/${id}?outletfield=${localStorage.getItem("outlet_id")}`
      ); // API endpoint for fetching data by ID
      if (response.data.success) {
        setCustomerData(response.data.data);
        setShowCustomerStatus(response.data.data.status);
        setShowPassword(response.data.data.password_status);
        setShowSmsNotification(response.data.data.sms);
        setShowEmailNotification(response.data.data.emailstatus);
        setShowWhatsappNotification(response.data.data.whatsappstatus);
        setShowMarketingNotification(response.data.data.marketing);
        setShowMemberStatus(response.data.data.active_member);
      } else {
        message.error("Failed to fetch customer data");
      }
    } catch (error) {
      message.error("An error occurred while fetching customer data");
    }
  };

  // const fecthCustomerDebtData = async () => {
  //   try {
  //     const url = `/billing/customer/debtdetails/${id}/${localStorage.getItem(
  //       "outlet_id"
  //     )}`;
  //     const response = await get(url);

  //     if (response?.data?.success) {
  //       const Data = response?.data?.debts;
  //       setCustomerMemberDebtData(Data);
  //     } else {
  //       console.log("Error in API call: ", response);
  //     }
  //   } catch (error) {
  //     console.error("Error while fetching my items: ", error);
  //   }
  // };

  useEffect(() => {
    fetchCustomerData();
    // fecthCustomerDebtData();
  }, [id]);

  const SettingsPopoverContent = () => {
    return (
      <>
        <Helmet>
          <title>Daco</title>
          <meta name="description" content={"Daco salon"} />
          <meta property="og:title" content={"Daco"} />
          <meta property="og:description" content={"Daco"} />
          <meta property="og:image" content={logoIcon} />
          <meta property="og:url" content={`https://new.suibox.my`} />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Add more tags as needed */}
        </Helmet>
        <Card
          style={{
            width: "300px",
            borderRadius: "8px",
            padding: "10px",
          }}
          bodyStyle={{ padding: "0px" }}
          bordered={false}
        >
          <div style={{ padding: "12px 12px 0px 12px" }}>
            {/* Password Setting */}
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "10px" }}
            >
              <Col>
                <Text>Password</Text>
              </Col>
              <Col>
                <Text>{showPassword ? "On" : "Off"}</Text>
                <EditOutlined
                  style={{
                    marginLeft: "5px",
                    color: "#1890ff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setShowPasswordModal(true);
                  }}
                />
              </Col>
            </Row>

            {/* Toggle Switches */}
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "10px" }}
            >
              <Col>
                <Text>Customer status</Text>
              </Col>
              <Col>
                <Switch
                  checked={showCustomerStatus}
                  onChange={(checked) => {
                    handleUpdateData("status", checked);
                    setShowCustomerStatus(checked);
                  }}
                />
              </Col>
            </Row>
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "10px" }}
            >
              <Col>
                <Text>SMS notifications</Text>
              </Col>
              <Col>
                <Switch
                  checked={showSmsNotification}
                  onChange={(checked) => {
                    handleUpdateData("sms", checked);
                    setShowSmsNotification(checked);
                  }}
                />
              </Col>
            </Row>
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "10px" }}
            >
              <Col>
                <Text>Email notifications</Text>
              </Col>
              <Col>
                <Switch
                  checked={showEmailNotification}
                  onChange={(checked) => {
                    handleUpdateData("emailstatus", checked);
                    setShowEmailNotification(checked);
                  }}
                />
              </Col>
            </Row>
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "10px" }}
            >
              <Col>
                <Text>WhatsApp notifications</Text>
              </Col>
              <Col>
                <Switch
                  checked={showWhatsappNotification}
                  onChange={(checked) => {
                    handleUpdateData("whatsappstatus", checked);
                    setShowWhatsappNotification(checked);
                  }}
                />
              </Col>
            </Row>
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "10px" }}
            >
              <Col>
                <Text>Marketing notifications</Text>
              </Col>
              <Col>
                <Switch
                  checked={showMarketingNotification}
                  onChange={(checked) => {
                    handleUpdateData("marketing", checked);
                    setShowMarketingNotification(checked);
                  }}
                />
              </Col>
            </Row>
            <Row
              justify="space-between"
              align="middle"
              style={{ marginBottom: "10px" }}
            >
              <Col>
                <Text>Activate member status</Text>
              </Col>
              <Col>
                <Switch
                  checked={showMemberStatus}
                  onChange={(checked) => {
                    handleUpdateData("active_member", checked);
                    setShowMemberStatus(checked);
                  }}
                />
              </Col>
            </Row>
          </div>
          <Divider style={{ margin: "10px 0px" }} />

          {/* Delete Customer Button */}
          <Row justify="center">
            <Button
              type="text"
              icon={<DeleteOutlined />}
              style={{ color: "#ff4d4f", fontWeight: "600" }}
              onClick={() => {
                openDeleteModal();
              }}
            >
              Delete customer
            </Button>
          </Row>
        </Card>
      </>
    );
  };
  return (
    <div style={{ padding: "20px", backgroundColor: "#f5f7fa" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            icon={<ArrowLeftOutlined />}
            type="link"
            style={{ marginRight: "8px" }}
            onClick={() => {
              navigate("/customer");
            }}
          ></Button>
          <Title level={4} style={{ margin: 0 }}>
            Customer Profile
          </Title>
        </div>
        <div>
          <Button
            icon={<LeftOutlined />}
            style={{ marginRight: "8px", fontWeight: "500" }}
            onClick={() =>
              navigate(`/customer/view/${customerData?.previousData}`)
            }
            disabled={customerData?.previousData == null}
          >
            Previous
          </Button>
          <Button
            icon={<RightOutlined />}
            iconPosition={"end"}
            style={{ fontWeight: "500" }}
            onClick={() => navigate(`/customer/view/${customerData?.nextData}`)}
            disabled={customerData?.nextData == null}
          >
            Next
          </Button>
        </div>
      </Row>

      <Row gutter={24}>
        {/* Sidebar - Basic Info */}
        <Col span={6}>
          <Card
            style={{
              backgroundColor: "#f6f7f9",
              borderRadius: "8px",
              height: "calc(100vh - 90px)",
              overflowY: "auto",
              position: "relative",
            }}
            bodyStyle={{ padding: "0px" }}
          >
            {/* Header with Edit and Settings */}
            <Row
              justify="space-between"
              align="middle"
              style={{ padding: "10px" }}
            >
              <Col>
                <span className="vertical-line"></span>
                <Text strong>Basic Info.</Text>
              </Col>
              <Col>
                <Button
                  type="text"
                  icon={<EditOutlined />}
                  style={{ marginRight: 0, color: "#a8b1c7" }}
                  onClick={() => navigate(`/customer/edit/${id}`)}
                >
                  Edit
                </Button>
                {/* <Button type="text" icon={<SettingOutlined />}>
                  Setting
                </Button> */}
                <Popover
                  content={<SettingsPopoverContent />}
                  title={null}
                  trigger="hover"
                  placement="bottom"
                >
                  <Button
                    type="text"
                    icon={<SettingOutlined />}
                    style={{ marginRight: 0, color: "#a8b1c7" }}
                  >
                    Setting
                  </Button>
                </Popover>
              </Col>
            </Row>

            <div style={{ textAlign: "center" }}>
              <Card
                style={{
                  backgroundColor: "#6e7687", // Adjust color to match the image
                  borderRadius: "20px",
                  padding: "20px",
                  color: "#fff",
                  height: "200px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundImage:
                    "radial-gradient(circle, rgba(126,130,153,1) 0%, rgba(85,90,110,1) 100%)",
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)",
                  margin: "0px 20px",
                }}
                bodyStyle={{ padding: "0px" }}
              >
                {/* Avatar and Name Section */}
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    size={48}
                    icon={<UserOutlined />}
                    style={{ backgroundColor: "#8a92a5" }}
                  />
                  <div style={{ marginLeft: "15px", textAlign: "left" }}>
                    <Text strong style={{ color: "#fff", fontSize: "16px" }}>
                      {customerData?.firstname} {customerData?.lastname}
                    </Text>
                    <br />
                    <Text style={{ color: "#c0c5cf", fontSize: "14px" }}>
                      {customerData?.customercode}
                    </Text>
                  </div>
                </div>

                {/* Contact Information */}
                <div style={{ marginTop: "10px" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#c0c5cf",
                      marginBottom: "8px",
                    }}
                  >
                    <PhoneFilled />
                    <Text
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        color: "#fff",
                        fontWeight: "700",
                      }}
                    >
                      +{customerData?.countrycode} {customerData?.phonenumber}
                    </Text>
                    <EyeInvisibleOutlined
                      style={{ marginLeft: "10px", cursor: "pointer" }}
                    />
                    {/* <CopyOutlined style={{ cursor: "pointer" }} /> */}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#c0c5cf",
                      marginBottom: "8px",
                      fontWeight: "700",
                    }}
                  >
                    <MailFilled />
                    <Text
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        color: "#fff",
                      }}
                    >
                      {customerData?.email ? customerData?.email : "-"}
                    </Text>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "#c0c5cf",
                    }}
                  >
                    <ClearOutlined />
                    <Text
                      style={{
                        marginLeft: "10px",
                        fontSize: "14px",
                        color: "#fff",
                      }}
                    >
                      {customerData?.dob ? customerData?.dob : "-"}
                    </Text>
                  </div>
                </div>
              </Card>
              <Card
                style={{
                  backgroundColor: "transparent",
                  padding: "20px",
                  maxWidth: "400px",
                  margin: "auto",
                }}
                bordered={false}
                bodyStyle={{ padding: "0px" }}
              >
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "10px" }}
                >
                  <Col>
                    <Text>Store</Text>
                  </Col>
                  <Col>
                    <Text>{customerData?.store_name}</Text>
                    <RightOutlined
                      style={{ fontSize: "12px", marginLeft: "5px" }}
                      onClick={() => {
                        setShowStoreSelectionModal(true);
                      }}
                    />
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "10px" }}
                >
                  <Col>
                    <Text>Customer source</Text>
                  </Col>
                  <Col>
                    <Text>
                      {customerData?.source_name
                        ? customerData?.source_name
                        : "-"}
                    </Text>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "10px" }}
                >
                  <Col>
                    <Text>Referrer</Text>
                  </Col>
                  <Col>
                    <Text>{customerData?.referralname}</Text>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "10px" }}
                >
                  <Col>
                    <Text>Preferred notification language</Text>
                  </Col>
                  <Col>
                    <Text>
                      {customerData?.language ? customerData?.status : "-"}
                    </Text>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "10px" }}
                >
                  <Col>
                    <Text>Occupation</Text>
                  </Col>
                  <Col>
                    <Text>
                      {customerData?.occupation
                        ? customerData?.occupation
                        : "-"}
                    </Text>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "10px" }}
                >
                  <Col>
                    <Text>Home address</Text>
                  </Col>
                  <Col>
                    <Text>
                      {customerData?.homeaddress
                        ? customerData?.homeaddress
                        : "-"}
                    </Text>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "10px" }}
                >
                  <Col>
                    <Text>Consultant 1st</Text>
                  </Col>
                  <Col>
                    <Text>
                      {customerData?.firstconsultantname
                        ? customerData?.firstconsultantname
                        : "-"}
                    </Text>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "10px" }}
                >
                  <Col>
                    <Text>Consultant 2nd</Text>
                  </Col>
                  <Col>
                    <Text>
                      {customerData?.secondconsultantname
                        ? customerData?.secondconsultantname
                        : "-"}
                    </Text>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "10px" }}
                >
                  <Col>
                    <Text>Consultant 3rd</Text>
                  </Col>
                  <Col>
                    <Text>
                      {customerData?.thirdconsultantname
                        ? customerData?.thirdconsultantname
                        : "-"}
                    </Text>
                  </Col>
                </Row>
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "10px" }}
                >
                  <Col>
                    <Text>Joined</Text>
                  </Col>
                  <Col>
                    <Text>
                      {customerData?.created_at
                        ? customerData?.created_at
                        : "-"}
                    </Text>
                  </Col>
                </Row>
              </Card>
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#f5f7fa",
                  borderRadius: "8px",
                }}
              >
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "16px" }}
                >
                  <Col>
                    <Text strong style={{ fontSize: "16px" }}>
                      <span className="vertical-line"></span>
                      Tags
                    </Text>
                  </Col>
                  <Col>
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      style={{ color: "#a8b1c7" }}
                      onClick={() => {
                        setShowCustomTagModal(true);
                      }}
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>

                <div style={{ textAlign: "center", marginTop: "40px" }}>
                  <Empty
                    image={emptyImageUrl}
                    imageStyle={{
                      height: 120,
                    }}
                    description={<span>No labels currently available</span>}
                  />
                </div>
              </div>
              <div
                style={{
                  padding: "20px",
                  backgroundColor: "#f5f7fa",
                  borderRadius: "8px",
                }}
              >
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginBottom: "10px" }}
                >
                  <Col>
                    <Text strong style={{ fontSize: "16px" }}>
                      <span
                        style={{
                          display: "inline-block",
                          width: "4px",
                          height: "20px",
                          backgroundColor: "#1890ff",
                          marginRight: "8px",
                        }}
                      />
                      Note
                    </Text>
                  </Col>
                  <Col>
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      style={{ color: "#a8b1c7" }}
                      onClick={() => {
                        setShowRemarkModal(true);
                      }}
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>

                <div
                  style={{
                    marginTop: "10px",
                    textAlign: `${customerData?.notes ? "justify" : "center"}`,
                  }}
                >
                  {customerData?.notes ? (
                    <Text>{customerData?.notes}</Text>
                  ) : (
                    <Empty
                      image={emptyImageUrl}
                      imageStyle={{
                        height: 120,
                      }}
                      description={<span>No labels currently available</span>}
                    />
                  )}
                </div>
              </div>
            </div>
            <div
              style={{
                position: "sticky",
                bottom: "0px",
                left: 0,
                right: 0,
                textAlign: "center",
                background: "#fff",
                padding: "10px",
                paddingLeft: "auto",
                paddingRight: "auto",
                borderTop: "1px solid #e0e7fe",
              }}
            >
              <Button
                type="primary"
                style={{
                  backgroundColor: "rgba(0,173,111,0.1)",
                  color: "#00ad6f",
                  border: "none",
                  marginRight: "10px",
                  width: "112px",
                }}
              >
                Book now
              </Button>
              <Button
                type="primary"
                style={{
                  backgroundColor: "rgba(46,86,242,.1)",
                  color: "#2e56f2",
                  border: "none",
                  width: "112px",
                }}
              >
                Check out
              </Button>
            </div>
          </Card>
        </Col>
        <Col span={18}>
          {customerData?.customerdata.map((data, index) => (
            <Card
              key={index}
              style={{
                backgroundColor: "#f6f7f9",
                borderRadius: "8px",
                height: "calc(100vh - 90px)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              bodyStyle={{ padding: "0px" }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "16px",
                  margin: "16px 0",
                }}
              >
                Customer Data
              </div>
              <Row gutter={16}>
                <Col span={8}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      borderColor: "#e0e7fe",
                      width: "100%",
                      padding: "20px",
                      position: "relative",
                      backgroundColor: "#fff",
                    }}
                    bodyStyle={{ padding: 0 }}
                  >
                    {/* Title and Value */}
                    <div style={{ paddingRight: "50px" }}>
                      <Text type="secondary" style={{ fontSize: "14px" }}>
                        Total visits
                      </Text>
                      <Title
                        level={3}
                        style={{ margin: "8px 0", color: "#333" }}
                      >
                        {/* {customerData?.customerdata.total_visit} */}
                        {data?.total_visit || "-"}
                      </Title>
                    </div>
                    <img
                      src={visitIcon}
                      alt="icon"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        width: "60px",
                        height: "40px",
                        opacity: 0.8, // Adjust opacity to match design
                      }}
                    />

                    {/* Edit Button */}
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        color: "#2f54eb",
                      }}
                      onClick={() => {
                        setShowHistoricalVisitModal(true);
                      }}
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      borderColor: "#e0e7fe",
                      width: "100%",
                      padding: "20px",
                      position: "relative",
                      backgroundColor: "#fff",
                    }}
                    bodyStyle={{ padding: 0 }}
                  >
                    {/* Title and Value */}
                    <div style={{ paddingRight: "50px" }}>
                      <Text type="secondary" style={{ fontSize: "14px" }}>
                        Total collected payment ({currencySymbol.CURRENCY_CODE})
                      </Text>
                      <Title
                        level={3}
                        style={{ margin: "8px 0", color: "#333" }}
                      >
                        {currencySymbol.CURRENCY_SYMBOL}{" "}
                        {Number(data?.total_payment)?.toFixed(2)}
                      </Title>
                    </div>
                    <img
                      src={collectedPaymentIcon}
                      alt="icon"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        width: "60px",
                        height: "40px",
                        opacity: 0.8,
                      }}
                    />

                    {/* Edit Button */}
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        color: "#2f54eb",
                      }}
                      onClick={() => {
                        setShowHistoricalPaymentModal(true);
                      }}
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      borderColor: "#e0e7fe",
                      width: "100%",
                      padding: "20px",
                      position: "relative",
                      backgroundColor: "#fff",
                    }}
                    bodyStyle={{ padding: 0 }}
                  >
                    {/* Title and Value */}
                    <div style={{ paddingRight: "50px" }}>
                      <Text type="secondary" style={{ fontSize: "14px" }}>
                        Stored/Bonus balance ({currencySymbol.CURRENCY_CODE})
                      </Text>
                      <Title
                        level={3}
                        style={{ margin: "8px 0", color: "#333" }}
                      >
                        {data?.stock_bonus.remaining_bonus_balance} /{" "}
                        {data?.stock_bonus.remaining_store_balance}
                      </Title>
                    </div>
                    <img
                      src={bonusBalanceIcon}
                      alt="icon"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        width: "60px",
                        height: "40px",
                        opacity: 0.8, // Adjust opacity to match design
                      }}
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: "16px" }}>
                <Col span={8}>
                  <Card
                    style={{
                      borderRadius: "12px",
                      borderColor: "#e0e7fe",
                      width: "100%",
                      padding: "20px",
                      position: "relative",
                      backgroundColor: "#fff",
                    }}
                    bodyStyle={{ padding: 0 }}
                  >
                    {/* Title and Value */}
                    <div style={{ paddingRight: "50px" }}>
                      <Text type="secondary" style={{ fontSize: "14px" }}>
                        Debts ({currencySymbol.CURRENCY_CODE})
                      </Text>
                      <Title
                        level={3}
                        style={{ margin: "8px 0", color: "#2e56f2" }}
                      >
                        {currencySymbol.CURRENCY_SYMBOL}
                        <a
                          style={{
                            color: "#2e56f2",
                            textDecoration: "underline",
                            fontWeight: 700,
                            marginRight: "16px",
                            textUnderlineOffset: "4px",
                          }}
                          // onClick={() => {
                          //   if (parseFloat(data?.debt) > 0) {
                          //     navigate(
                          //       `/sales/billing?customerid=${id}&debt=${data?.debt}`
                          //     );
                          //   }
                          // }}
                          onClick={() => {
                            setShowCustomerDebtModal(true);
                          }}
                        >
                          {parseFloat(data?.debt).toFixed(2)}
                        </a>
                      </Title>
                    </div>
                    <img
                      src={debitIcon}
                      alt="icon"
                      style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        width: "60px",
                        height: "40px",
                        opacity: 0.8, // Adjust opacity to match design
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setShowCustomerDebtModal(true);
                      }}
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      borderColor: "#e0e7fe",
                      width: "100%",
                      padding: "20px",
                      position: "relative",
                      backgroundColor: "#fff",
                    }}
                    bodyStyle={{ padding: 0 }}
                  >
                    {/* Title and Value */}
                    <div style={{ paddingRight: "50px" }}>
                      <Text type="secondary" style={{ fontSize: "14px" }}>
                        Loyalty Points
                      </Text>
                      <Title
                        level={3}
                        style={{ margin: "8px 0", color: "#333" }}
                      >
                        <a
                          style={{
                            color: "#2e56f2",
                            textDecoration: "underline",
                            fontWeight: 700,
                            marginRight: "16px",
                            textUnderlineOffset: "4px",
                          }}
                          onClick={() => {
                            setShowPointDetailsModal(true);
                          }}
                        >
                          {data?.loyalty_pts}
                        </a>
                      </Title>
                    </div>
                    <img
                      src={loyaltyPointIcon}
                      alt="icon"
                      style={{
                        position: "absolute",
                        bottom: "20px",
                        right: "10px",
                        width: "60px",
                        height: "40px",
                        opacity: 0.8,
                      }}
                    />
                    <Button
                      type="text"
                      icon={<EditOutlined />}
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        color: "#2f54eb",
                      }}
                      onClick={() => {
                        setShowAdjustModal(true);
                      }}
                    />
                  </Card>
                </Col>
                <Col span={8}>
                  <Card
                    style={{
                      borderRadius: "8px",
                      borderColor: "#e0e7fe",
                      width: "100%",
                      padding: "20px",
                      position: "relative",
                      backgroundColor: "#fff",
                    }}
                    bodyStyle={{ padding: 0 }}
                  >
                    {/* Title and Value */}
                    <div style={{ paddingRight: "50px" }}>
                      <Text type="secondary" style={{ fontSize: "14px" }}>
                        Last Consumption Date
                      </Text>
                      <Title
                        level={3}
                        style={{ margin: "8px 0", color: "#333" }}
                      >
                        {data?.last_consumption_date !== "-"
                          ? new Date(
                              data.last_consumption_date
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })
                          : "-"}
                        {/* {data?.last_consumption_date || "-"} */}
                      </Title>
                    </div>
                    <img
                      src={consumptionDateIcon}
                      alt="icon"
                      style={{
                        position: "absolute",
                        bottom: "20px",
                        right: "10px",
                        width: "60px",
                        height: "40px",
                        opacity: 0.8, // Adjust opacity to match design
                      }}
                    />
                  </Card>
                </Col>
              </Row>

              {/* Tabs Section */}
              <Tabs defaultActiveKey="1" style={{ marginTop: "20px" }}>
                <TabPane tab="Memberships" key="1">
                  <MembershipSection />
                </TabPane>
                <TabPane tab="Gift cards" key="2">
                  <GiftcardSection />
                </TabPane>
                <TabPane tab="Packages" key="3">
                  <PackageSection />
                </TabPane>
                <TabPane tab="Sales" key="4">
                  <SalesHistory />
                </TabPane>
                <TabPane tab="Appointments" key="5">
                  <AppointmentsHistory />
                </TabPane>
                <TabPane tab="Service Logs" key="6">
                  <Text>Content for Service Logs</Text>
                  {/* <ServiceLogs /> */}
                </TabPane>
                <TabPane tab="Communication Records" key="7">
                  <Text>Content for Communication Records</Text>
                  {/* <CommunicationRecords /> */}
                </TabPane>
                <TabPane tab="Forms" key="8">
                  <Text>Form section</Text>
                  {/* <FormSection /> */}
                </TabPane>
                <TabPane tab="KIV" key="9">
                  <KIVSection />
                </TabPane>
                <TabPane tab="Course" key="10">
                  <CourseSection />
                </TabPane>
                <TabPane tab="Onlinesales" key="11">
                  <OnlineSalesSection />
                </TabPane>
              </Tabs>
            </Card>
          ))}
        </Col>
      </Row>
      <ChangePasswordModal
        visible={showPasswordModal}
        onClose={() => {
          setShowPasswordModal(false);
        }}
        showPassword={showPassword}
      />
      <StoreSelectionModal
        visible={showStoreSelectionModal}
        onClose={() => {
          setShowStoreSelectionModal(false);
        }}
        onConfirm={fetchCustomerData}
        value={customerData?.outlet_id}
      />
      <RemarkModal
        visible={showRemarkModal}
        onClose={() => {
          setShowRemarkModal(false);
        }}
        onConfirm={fetchCustomerData}
        value={customerData?.notes}
      />
      <HistoricalVisitModal
        message={"Total Historical Visit"}
        visible={showHistoricalVisitModal}
        onClose={() => {
          setShowHistoricalVisitModal(false);
        }}
      ></HistoricalVisitModal>
      <HistoricalCollectedPaymentModal
        message={"Total Historical Collected Payment"}
        visible={showHistoricalPaymentModal}
        onClose={() => {
          setShowHistoricalPaymentModal(false);
        }}
      />
      <AdjustmentModal
        visible={showAdjustModal}
        onClose={() => {
          setShowAdjustModal(false);
        }}
      />
      <DebtModal
        visible={showDebtModal}
        onClose={() => {
          setShowDebtModal(false);
        }}
      />
      <PointDetailsModal
        visible={showPointDetailsModal}
        onClose={() => {
          setShowPointDetailsModal(false);
        }}
      />
      <TagSelectModal
        visible={showCustomTagModal}
        onClose={() => {
          setShowCustomTagModal(false);
        }}
      />
      <CustomerDebtModal
        visible={showCustomerDebtModal}
        debt={customerData?.debt}
        // customerDebtData={customerMemberDebtData}
        onClose={() => {
          setShowCustomerDebtModal(false);
        }}
      />
      {/* <DateSelectionModal /> */}
      <ConfirmDeleteModal
        visible={isDeleteModalVisible}
        onCancel={closeDeleteModal}
        onConfirm={handleConfirm}
        message="Are you sure you want to delete this customer? This operation cannot be undone!"
      />
    </div>
  );
};

export default CustomerProfile;
