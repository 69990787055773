import {
  ArrowLeftOutlined,
  DoubleRightOutlined,
  InfoCircleOutlined,
  PrinterOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  message,
  Row,
  Select,
  Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { get } from "../../../services/Apiclient";
import { useCurrency } from "../../../contexts/CurrencyContext";
const { RangePicker } = DatePicker;
const { Option } = Select;

const AccountingRecordSheet = () => {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const today: [Dayjs, Dayjs] = [dayjs().startOf("day"), dayjs().endOf("day")];
  const [outletData, setOutletData] = useState<any[]>([]);
  const [outletCode, setOutletCode] = useState<any>(
    localStorage.getItem("outlet_id") ? localStorage.getItem("outlet_id") : null
  );
  const [allDepartmentData, setAllDepartmentData] = useState([]);
  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>(today);
  const [startDate, setStartDate] = useState<string | null>(
    today[0].format("DD-MM-YYYY")
  );
  const [endDate, setEndDate] = useState<string | null>(
    today[1].format("DD-MM-YYYY")
  );
  const [sheetType, setSheetType] = useState("receipt");
  const [foldExpand, setFoldExpand] = useState<"expand" | "fold">("expand");
  const [checkBoxSelect, setCheckBoxSelect] = useState<boolean>(false);
  const [turnoverDetails, setTurnoverDetails] = useState<any>(null);

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);
      if (response && response.data && response.data.success) {
        setOutletData(response.data.data);
      } else {
        console.log(
          "Error in API call: ",
          response?.data?.message || "Response not found"
        );
      }
    } catch (errors: any) {
      errors.inner?.forEach((error: any) => message.error(error.message));
    }
  };

  const getDepartmentDropdown = async () => {
    try {
      let url = `/department/dropdown/${outletCode}`;
      let response = await get(url);
      if (response && response.data && response.data.success) {
        setAllDepartmentData(response.data.data);
      } else {
        console.log(
          "Error in API call: ",
          response?.data?.message || "Response not found"
        );
      }
    } catch (errors: any) {
      errors.inner?.forEach((error: any) => message.error(error.message));
    }
  };

  const fetchAccountingData = async () => {
    if (!outletCode || !startDate || !endDate) {
      message.error("Please select an outlet and date range.");
      return;
    }
    try {
      const url = `/report/accountingdata/${outletCode}/${startDate}/${endDate}`;
      const response = await get(url);
      if (response && response.data && response.data.success) {
        setTurnoverDetails(response.data.data);
      } else {
        setTurnoverDetails(null);
        message.error(
          response?.data?.message || "Failed to fetch accounting data."
        );
      }
    } catch (error) {
      setTurnoverDetails(null);
      message.error("An error occurred while fetching data.");
      console.error(error);
    }
  };

  useEffect(() => {
    getOutletDropdown();
  }, []);

  useEffect(() => {
    fetchAccountingData();
  }, [outletCode, startDate, endDate]);

  useEffect(() => {
    if (outletCode) getDepartmentDropdown();
  }, [outletCode]);

  const handleOutletChange = (value: string) => {
    setOutletCode(value);
  };

  const handleDateChange = (dates: any) => {
    if (dates) {
      const formattedStartDate = dates[0].format("DD-MM-YYYY");
      const formattedEndDate = dates[1].format("DD-MM-YYYY");
      setStartDate(formattedStartDate);
      setEndDate(formattedEndDate);
      setDateRange(dates);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const handleDepartmentChange = (value: any) => {
    console.log("Department selected:", value);
  };

  const handleSheetTypeChange = (value: any) => {
    setSheetType(value);
  };

  const toggleFoldExpand = () => {
    setFoldExpand((prevState) => (prevState === "expand" ? "fold" : "expand"));
  };

  const handleCheckboxChange = (e: any) => {
    setCheckBoxSelect(e.target.checked);
  };

  return (
    <Content className="mainContainer">
      {/* Header Section */}
      <Row
        justify="space-between"
        align="middle"
        gutter={[10, 10]}
        style={{ flexWrap: "wrap", marginBottom: "16px" }}
      >
        {/* Left Section (Back Button & Title) */}
        <Col xs={24} md={12}>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            <div
              onClick={() => navigate("/reports")}
              style={{ cursor: "pointer" }}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
              />
            </div>
            <p
              style={{
                margin: 0,
                fontFamily:
                  "SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC",
                fontSize: "18px",
                fontWeight: 700,
              }}
              className="Accounting_header_title"
            >
              Accounting Record Sheet
            </p>
          </div>
        </Col>

        {/* Right Section (Export & Print Buttons) */}
        <Col xs={24} md={12} style={{ textAlign: "right" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            <Select
              placeholder="Export as"
              style={{ width: "100px", height: "46px" }}
            >
              <Option value="null">Excel</Option>
              <Option value="Walk-in">PDF</Option>
            </Select>
            <Button
              icon={<PrinterOutlined />}
              style={{
                color: "rgb(102, 102, 102)",
                height: "46px",
                padding: "9px 18px",
                backgroundColor: "white",
                borderColor: "#e0e7fe",
                borderRadius: "6px",
                fontWeight: 700,
              }}
            >
              Print
            </Button>
          </div>
        </Col>
      </Row>

      {/* Content Section */}
      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#ffffff",
          borderRadius: "12px",
          padding: "24px 24px 10px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
        }}
      >
        {/* Filters */}
        <div style={{ position: "relative" }}>
          <Row
            gutter={[10, 10]}
            align="middle"
            justify="start"
            style={{ marginBottom: "16px", flexWrap: "wrap" }}
          >
            {/* Outlet Select */}
            <Col xs={24} sm={12} md={6} lg={6}>
              <Select
                value={outletCode}
                onChange={handleOutletChange}
                placeholder="Store List"
                style={{ width: "100%", height: "46px" }}
              >
                {outletData.length > 0 &&
                  outletData.map((outdata: any) => (
                    <Option value={outdata.id} key={outdata.id}>
                      {outdata.name}
                    </Option>
                  ))}
              </Select>
            </Col>

            {/* Date Range Picker */}
            <Col xs={24} sm={12} md={6} lg={6}>
              <RangePicker
                style={{ width: "100%", height: "46px", textAlignLast: "center" }}
                value={dateRange}
                onChange={handleDateChange}
                format="DD/MM/YYYY"
                ranges={{
                  Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                  Yesterday: [
                    dayjs().subtract(1, "day").startOf("day"),
                    dayjs().subtract(1, "day").endOf("day"),
                  ],
                  "This Week": [dayjs().startOf("week"), dayjs().endOf("week")],
                  "This Month": [
                    dayjs().startOf("month"),
                    dayjs().endOf("month"),
                  ],
                  "Last Month": [
                    dayjs().subtract(1, "month").startOf("month"),
                    dayjs().subtract(1, "month").endOf("month"),
                  ],
                }}
              />
            </Col>

            {/* Sheet Type Select */}
            <Col xs={24} sm={12} md={6} lg={6}>
              <Select
                placeholder="Select"
                style={{ width: "100%", height: "46px" }}
                value={sheetType}
                onChange={handleSheetTypeChange}
                disabled
              >
                <Option value="receipt">Receipt</Option>
                <Option value="summarySheet">Summary sheet</Option>
              </Select>
            </Col>

            {/* Buttons (Show only when folded) */}
            {foldExpand === "fold" && (
              <Col xs={24} sm={12} md={6} lg={6} style={{ textAlign: "right" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: "10px",
                    flexWrap: "wrap",
                  }}
                >
                  <Button
                    type="primary"
                    style={{ height: "46px", minWidth: "80px" }}
                    onClick={fetchAccountingData}
                  >
                    Run
                  </Button>
                  <Button
                    style={{
                      border: "none",
                      background: "#fff",
                      color: "#717ef1",
                      fontWeight: 600,
                      height: "46px",
                      minWidth: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onClick={toggleFoldExpand}
                  >
                    <span>{foldExpand === "fold" ? "Expand" : "Fold"}</span>
                    <DoubleRightOutlined
                      style={{
                        transform:
                          foldExpand === "fold"
                            ? "rotate(85deg)"
                            : "rotate(270deg)",
                        transition: "transform 0.3s",
                      }}
                    />
                  </Button>
                </div>
              </Col>
            )}
          </Row>
          {/* Row (Show only when expanded) */}
          {foldExpand === "expand" && (
            <Row gutter={[10, 10]} align="middle" justify="space-between">
              {/* Department Select Dropdown */}
              <Col xs={24} sm={12} md={10} lg={10}>
                <Select
                  placeholder="All department"
                  style={{
                    width: "100%", // Ensures responsiveness
                    maxWidth: "400px",
                    height: "40px",
                  }}
                  disabled={sheetType === "receipt"}
                  onChange={handleDepartmentChange}
                >
                  {allDepartmentData.length > 0 &&
                    allDepartmentData.map((unit: any) => (
                      <Option key={unit.id} value={unit.id}>
                        {unit.departmenttype}
                      </Option>
                    ))}
                </Select>
              </Col>

              {/* Buttons Section */}
              <Col
                xs={24}
                sm={12}
                md={14}
                lg={14}
                style={{ textAlign: "right" }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end", // Align buttons to the right
                    gap: "10px", // Reduce gap for smaller screens
                    flexWrap: "wrap", // Ensure wrapping on smaller screens
                  }}
                >
                  <Button
                    type="primary"
                    style={{ height: "46px", minWidth: "80px" }}
                    onClick={fetchAccountingData}
                  >
                    Run
                  </Button>

                  <Button
                    style={{
                      border: "none",
                      background: "#fff",
                      color: "#717ef1",
                      fontWeight: 600,
                      height: "46px",
                      minWidth: "100px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onClick={toggleFoldExpand}
                  >
                    <span>{foldExpand === "expand" ? "Fold" : "Expand"}</span>
                    <DoubleRightOutlined
                      style={{
                        transform:
                          foldExpand === "expand"
                            ? "rotate(270deg)"
                            : "rotate(90deg)",
                        transition: "transform 0.3s",
                      }}
                    />
                  </Button>
                </div>
              </Col>
            </Row>
          )}
        </div>

        {/* Rest of the Content */}
        <Row gutter={10} justify="center" style={{ marginTop: "20px" }}>
          <Col
            xs={24}
            sm={12}
            md={8}
            lg={6}
            style={{
              marginBottom: "16px",
              border: "1px solid #e0e7fe",
              borderRadius: "8px",
              padding: "16px",
              textAlign: "center",
            }}
          >
            <p style={{ fontWeight: 700, fontSize: "16px" }}>Account Slip</p>
            <p
              style={{
                fontWeight: 700,
                fontFamily:
                  "SourceHanSansCN-Bold, PingFangSC-Semibold, PingFang SC",
              }}
            >
              (
              {turnoverDetails?.outlet_name ||
                outletData.find((outlet) => outlet.id === outletCode)?.name ||
                "N/A"}
              )
            </p>
            <Col
              flex={2}
              style={{
                paddingLeft: 0,
                fontSize: "14px",
                textAlign: "start",
                marginBottom: "10px",
              }}
            >
              <span style={{ color: "#adb5ba" }}>Date range: </span>
              {turnoverDetails
                ? `${turnoverDetails.startdate} - ${turnoverDetails.enddate}`
                : `${startDate || ""} ~ ${endDate || ""}`}
            </Col>
            <Row>
              <Col flex={2} style={{ fontSize: "14px", textAlign: "start" }}>
                <span style={{ color: "#adb5ba" }}>Printer: </span>
              </Col>
              <Col style={{ textAlign: "end" }} flex={2}>
                <span style={{ color: "#adb5ba" }}>Time printed: </span>
                {turnoverDetails?.printtime
                  ? dayjs(turnoverDetails.printtime).format("HH:mm:ss")
                  : "N/A"}
              </Col>
            </Row>
            <div
              style={{
                width: "60px",
                margin: "20px 0px",
                border: "5px solid black",
              }}
            />
            <Row>
              <Col>
                <span style={{ color: "#adb5ba", marginRight: "10px" }}>
                  Department:{" "}
                </span>
                {turnoverDetails?.department || "N/A"}
              </Col>
            </Row>
            <Row>
              <Col>
                <span style={{ color: "#adb5ba", marginRight: "10px" }}>
                  Business days:{" "}
                </span>
                {turnoverDetails?.business_days || 0}
              </Col>
            </Row>
            <Row>
              <Col>
                <span style={{ color: "#adb5ba", marginRight: "10px" }}>
                  Number of customers:{" "}
                </span>
                {turnoverDetails?.customers || 0}
              </Col>
            </Row>
            <div
              style={{
                width: "60px",
                margin: "20px 0px",
                border: "5px solid black",
              }}
            />

            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                margin: "5px 0",
              }}
            >
              <span
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                Collected turnover
                <Tooltip title="Revenue from all selected collected payment methods">
                  <InfoCircleOutlined
                    style={{ color: "#1890ff", cursor: "pointer" }}
                  />
                </Tooltip>
              </span>

              <span>
                {currencySymbol.CURRENCY_SYMBOL}
                {turnoverDetails?.collected_turnover.toLocaleString() || "0"}
              </span>
            </div>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                margin: "5px 0",
              }}
            >
              <span
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                Turnover:{" "}
                <Tooltip title="Revenue from all selected payment methods">
                  <InfoCircleOutlined
                    style={{ color: "#1890ff", cursor: "pointer" }}
                  />
                </Tooltip>
              </span>
              <span>
                {currencySymbol.CURRENCY_SYMBOL}
                {turnoverDetails?.turnover.toLocaleString() || "0"}
              </span>
            </div>

            <div style={{ padding: "0px 10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                  color: "#999",
                }}
              >
                <span>Products</span>
                <span>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {turnoverDetails?.products.toLocaleString() || "0"}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                  color: "#999",
                }}
              >
                <span>Services</span>
                <span>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {turnoverDetails?.services.toLocaleString() || "0"}
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                  color: "#999",
                }}
              >
                <span>Privileges</span>
                <span>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {turnoverDetails?.privileges.toLocaleString() || "0"}
                </span>
              </div>
              <div
                style={{
                  marginLeft: "20px",
                  color: "#999",
                  marginTop: "10px",
                  backgroundColor: "#f6f7fa",
                  padding: "6px 10px",
                  borderRadius: "6px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px 0",
                    color: "#666",
                  }}
                >
                  <span>Prepaid membership</span>
                  <span>
                    {currencySymbol.CURRENCY_SYMBOL}
                    {turnoverDetails?.prepaid_membership || "0"}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px 0",
                    color: "#666",
                  }}
                >
                  <span>Visit-based membership</span>
                  <span>
                    {currencySymbol.CURRENCY_SYMBOL}
                    {turnoverDetails?.visitbased_membership.toLocaleString() ||
                      "0"}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px 0",
                    color: "#666",
                  }}
                >
                  <span>Discount membership</span>
                  <span>
                    {currencySymbol.CURRENCY_SYMBOL}
                    {turnoverDetails?.discount_membership.toLocaleString() ||
                      "0"}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px 0",
                    color: "#666",
                  }}
                >
                  <span>Gift card</span>
                  <span>
                    {currencySymbol.CURRENCY_SYMBOL}
                    {turnoverDetails?.giftcards || "0"}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    margin: "5px 0",
                    color: "#666",
                  }}
                >
                  <span>Packages</span>
                  <span>
                    {currencySymbol.CURRENCY_SYMBOL}
                    {turnoverDetails?.packages.toLocaleString() || "0"}
                  </span>
                </div>
              </div>
            </div>

            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 0",
              }}
            >
              Other income{" "}
              <span>
                {currencySymbol.CURRENCY_SYMBOL}
                {turnoverDetails?.otherincome || "0"}
              </span>
            </div>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 0",
              }}
            >
              Expenses{" "}
              <span>
                {currencySymbol.CURRENCY_SYMBOL}
                {turnoverDetails?.expenses || "0"}
              </span>
            </div>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 0",
              }}
            >
              Taxes{" "}
              <span>
                {currencySymbol.CURRENCY_SYMBOL}
                {turnoverDetails?.total_tax.toLocaleString() || "0"}
              </span>
            </div>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 0",
              }}
            >
              Service Charge <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
            </div>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 0",
              }}
            >
              <span
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                Total Revenue{" "}
                <Tooltip title="Turnover - Expenses + Other income">
                  <InfoCircleOutlined
                    style={{ color: "#1890ff", cursor: "pointer" }}
                  />
                </Tooltip>
              </span>
              <span>
                {currencySymbol.CURRENCY_SYMBOL}
                {turnoverDetails?.totalrevenue.toLocaleString() || "0"}
              </span>
            </div>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                display: "flex",
                justifyContent: "space-between",
                padding: "15px 0",
              }}
            >
              <span
                style={{ display: "flex", alignItems: "center", gap: "5px" }}
              >
                Collected revenue{" "}
                <Tooltip title="Collected turnover - Expenses + Other income">
                  <InfoCircleOutlined
                    style={{ color: "#1890ff", cursor: "pointer" }}
                  />
                </Tooltip>
              </span>
              <span>
                {currencySymbol.CURRENCY_SYMBOL}
                {turnoverDetails?.collectedrevenue
                  .toLocaleString()
                  .toLocaleString() || "0"}
              </span>
            </div>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                margin: "5px 0",
              }}
            >
              Master card <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
            </div>
            <div style={{ padding: "0px 10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                  color: "#999",
                }}
              >
                <span>Products</span>
                <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                  color: "#999",
                }}
              >
                <span>Services</span>
                <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                  color: "#999",
                }}
              >
                <span>Privileges</span>
                <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                  color: "#999",
                }}
              >
                <span>Taxes</span>
                <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                  color: "#999",
                }}
              >
                <span>Tips</span>
                <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
              </div>
            </div>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                margin: "5px 0",
              }}
            >
              QR <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
            </div>
            <div style={{ padding: "0px 10px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                  color: "#999",
                }}
              >
                <span>Products</span>
                <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                  color: "#999",
                }}
              >
                <span>Services</span>
                <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                  color: "#999",
                }}
              >
                <span>Privileges</span>
                <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                  color: "#999",
                }}
              >
                <span>Taxes</span>
                <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px 0",
                  color: "#999",
                }}
              >
                <span>Tips</span>
                <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
              </div>
            </div>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                margin: "5px 0",
              }}
            >
              Repayment <span>{currencySymbol.CURRENCY_SYMBOL}0</span>
            </div>
            <div
              style={{
                width: "60px",
                margin: "20px 0px",
                border: "5px solid black",
              }}
            />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "700",
                marginBottom: "10px",
                display: "flex",
                justifyContent: "space-between",
                margin: "5px 0",
              }}
            >
              Signature
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <canvas
                ref={canvasRef}
                style={{
                  border: "1px solid #ccc",
                  width: "400px",
                  height: "100px",
                  backgroundColor: "#f5f5f5",
                }}
              />
            </div>
          </Col>
        </Row>
      </Content>
    </Content>
  );
};

export default AccountingRecordSheet;
