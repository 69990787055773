import React, { useEffect, useState } from "react";
import {
  Modal,
  Calendar,
  Button,
  Row,
  Col,
  Typography,
  Card,
  Divider,
  message,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { get } from "../../../services/Apiclient";
import updateLocale from "dayjs/plugin/updateLocale";

dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
});

interface AppointmentTimeModalProps {
  visible: boolean;
  onCancel: () => void;
  onTimeSelect: (time: string) => void;
}

const AppointmentTimeModal: React.FC<AppointmentTimeModalProps> = ({
  visible,
  onCancel,
  onTimeSelect,
}) => {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedTime, setSelectedTime] = useState<string | null>(null);
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const [selectedDay, setSelectedDay] = useState<number | null>(null);
  const [dayInfo, setDayinfo] = useState<any[]>([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState<string[]>([]);

  const fetchDayInfo = async () => {
    try {
      const response = await get(
        `/appointment/dayinfo/${localStorage.getItem("outlet_id")}`
      );
      if (response.data.success) {
        setDayinfo(response.data.data);
      } else {
        message.error("Failed to fetch services");
      }
    } catch (error) {
      console.error("Error fetching services: ", error);
      message.error("Error fetching services");
    }
  };

  const changeMonth = (direction: "prev" | "next") => {
    const newMonth = selectedMonth.add(direction === "next" ? 1 : -1, "month");
    setSelectedMonth(newMonth);
    setSelectedDate(newMonth.startOf("month")); // Reset calendar to first day of new month
    setSelectedDay(null);
    setSelectedTime(null);
  };

  const handleDateSelect = (date: Dayjs) => {
    // Ensure the selected date is not in the past
    if (date.isBefore(dayjs(), "day")) {
      message.error("You cannot select a past date.");
      return;
    }

    setSelectedDate(date);
    setSelectedDay(date.date());
    setSelectedMonth(date.startOf("month"));

    filterTimeSlotsForDay(date);
  };

  const handleTimeSelect = (time: string) => {
    setSelectedTime(time);
  };

  const handleSave = () => {
    if (selectedTime) {
      const formattedTime = dayjs(
        `${selectedDate.format("YYYY-MM-DD")} ${selectedTime}`,
        "YYYY-MM-DD hh:mm A"
      ).format("DD/MM/YYYY hh:mm A");

      onTimeSelect(formattedTime);
      setSelectedTime(null);
      setSelectedDate(dayjs());
      setSelectedDay(null);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchDayInfo();
      setSelectedTime("");
      const today = dayjs();
      setSelectedDate(today);
      filterTimeSlotsForDay(dayjs());
    }
  }, [visible]);

  const generateTimeSlots = (start: string, end: string, date: Dayjs) => {
    const slots: string[] = [];
    let currentTime = dayjs(start, "HH:mm");
    const now = dayjs(); // Current time

    while (currentTime.isBefore(dayjs(end, "HH:mm"))) {
      // Disable past times only for the current day
      if (!date.isSame(now, "day") || currentTime.isAfter(now)) {
        slots.push(currentTime.format("hh:mm A"));
      }
      currentTime = currentTime.add(30, "minute");
    }
    return slots;
  };

  const filterTimeSlotsForDay = (date: Dayjs) => {
    const dayOfWeek = date.day();
    const dayData = dayInfo.find((day) => day.dayofweek === dayOfWeek);

    if (!dayData) {
      setAvailableTimeSlots([]);
      setSelectedTime(""); // 👈 Reset selected time if no data found
      return;
    }

    if (dayData.starttime === "closed") {
      setAvailableTimeSlots([]);
      setSelectedTime(""); // 👈 Reset selected time if closed
    } else {
      const slots = generateTimeSlots(dayData.starttime, dayData.endtime, date);
      setAvailableTimeSlots(slots);

      if (slots.length > 0) {
        setSelectedTime(slots[0]); // 👈 Auto-select first slot
      } else {
        setSelectedTime(""); // 👈 Reset if no slots
      }
    }
  };

  const disabledDate = (current: Dayjs) => {
    return current.isBefore(dayjs(), "day");
  };

  useEffect(() => {
    filterTimeSlotsForDay(selectedDate);
  }, [dayInfo, selectedDate]);

  const renderCustomHeader = () => {
    const dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

    return (
      <Row
        justify="space-between"
        style={{ padding: "0 8px", textAlign: "center" }}
      >
        {dayNames.map((day) => (
          <Col key={day} style={{ width: "14.28%" }}>
            <strong>{day}</strong>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <Modal
      title="Appointment Time"
      open={visible}
      onCancel={onCancel}
      className="normal-time-blue-modal"
      footer={[
        <>
          <Divider style={{ margin: "0px" }} />
          <Row justify="end" style={{ marginTop: "20px" }}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={handleSave}
              disabled={!selectedDay || !selectedTime}
              style={{ marginLeft: "10px" }}
            >
              Save
            </Button>
          </Row>
        </>,
      ]}
      style={{ top: "10px" }}
      bodyStyle={{
        height: "calc(100vh - 180px)",
        overflow: "hidden",
        overflowY: "auto",
      }}
      width={900}
    >
      {/* Selected Date & Time Display */}
      <Card
        style={{
          background: "#2e56f3",
          //   padding: "10px",
          textAlign: "center",
          marginBottom: "15px",
          margin: "10px 24px 20px 24px",
        }}
        bodyStyle={{
          padding: "16px",
        }}
      >
        <Typography.Text strong style={{ color: "#fff" }}>
          Time selected:{" "}
          {selectedDate
            ? `${selectedDate.format("DD/MM/YYYY")} ${selectedTime}`
            : "None"}
        </Typography.Text>
      </Card>

      <Divider style={{ margin: "0px" }} />

      <Row gutter={16} style={{ margin: "0px 0px" }}>
        <Col
          span={20}
          style={{ borderRight: "1px solid #e4eaf8", marginTop: "20px" }}
        >
          <Row justify="center" align="middle" style={{ marginBottom: "20px" }}>
            <Button
              style={{ boxShadow: "none", border: "none" }}
              onClick={() => changeMonth("prev")}
            >
              <ArrowLeftOutlined
                style={{ color: "#2e56f3", fontWeight: "900" }}
              />
            </Button>
            <Typography.Title style={{ margin: "0px 40px" }} level={4}>
              {selectedMonth.format("MMMM YYYY")}
            </Typography.Title>
            <Button
              style={{ boxShadow: "none", border: "none" }}
              onClick={() => changeMonth("next")}
            >
              <ArrowRightOutlined
                style={{ color: "#2e56f3", fontWeight: "900" }}
              />
            </Button>
          </Row>
          {renderCustomHeader()}
          <Calendar
            fullscreen={false}
            value={selectedDate}
            onSelect={handleDateSelect} // Fixed: Updates selected date
            headerRender={() => null}
            className="custom-time-calendar"
            disabledDate={disabledDate}
          />
        </Col>
        <Col span={4} style={{ marginTop: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              height: "calc(100vh - 290px)",
              overflowY: "auto",
            }}
          >
            {availableTimeSlots.length > 0 ? (
              availableTimeSlots.map((time) => (
                <Button
                  key={time}
                  type={selectedTime === time ? "primary" : "default"}
                  style={{
                    background: selectedTime == time ? "#2b57f6" : "#fff",
                    border: "none",
                  }}
                  onClick={() => handleTimeSelect(time)}
                >
                  {time}
                </Button>
              ))
            ) : (
              <Typography.Text type="secondary" style={{ textAlign: "center" }}>
                No Available Slots
              </Typography.Text>
            )}
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default AppointmentTimeModal;
