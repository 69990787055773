import React from "react";
import { Layout, Row, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const stockInImage = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_In.png`;
const stockOutImage = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_Out.png`;
const stockTakesImage = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Inventory_Stock_Takes.png`;
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;

const { Content } = Layout;

// Inventory Data
const inventory = [
  {
    title: "Stock In",
    image: stockInImage,
    link: "/inventory/stockin/list",
    bgColor: "#1e90ff",
  },
  {
    title: "Stock Out",
    image: stockOutImage,
    link: "/inventory/stockout/list",
    bgColor: "#1e90ff",
  },
  {
    title: "Stock Takes",
    image: stockTakesImage,
    link: "/inventory/stocktakes/list",
    bgColor: "#1e90ff",
  },
];

export default function Inventory() {
  const navigate = useNavigate();

  // Get user role and permissions from localStorage
  const userRole = localStorage.getItem("userRole");
  const permissions = JSON.parse(localStorage.getItem("permissions") || "{}");

  // Filter inventory items based on role and permissions
  const filteredInventory = inventory.filter((item) => {
    // If the user is Staff, apply stricter rules
    if (userRole === "Staff") {
      // Always hide "Stock Takes" for Staff
      if (item.title === "Stock Takes") return false;

      // Show "Stock In" and "Stock Out" only if they have the appropriate permissions
      if (item.title === "Stock In" && !permissions?.stockinview) return false;
      if (item.title === "Stock Out" && !permissions?.stockoutview)
        return false;
    }

    // If not Staff, show all items
    return true;
  });

  // Handle navigation
  const handleNavigation = (link: string) => {
    navigate(link);
  };

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Layout>
        {/* Header Section */}
        <Row justify="space-between" align="middle">
          <Col>
            <h2 style={{ lineHeight: "40px", fontSize: "18px" }}>
              <span className="vertical-line"></span>Inventory
            </h2>
          </Col>
        </Row>

        {/* Content Section */}
        <Content
          style={{
            backgroundColor: "#f6f7f9",
            borderRadius: "12px",
            padding: "20px 0px",
            maxWidth: "450px",
          }}
        >
          <Row gutter={[16, 16]} justify="start">
            {filteredInventory.map((item, index) => (
              <Col key={index} xs={24} sm={8} style={{ textAlign: "center" }}>
                <div
                  style={{
                    backgroundColor: item.bgColor,
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0 auto",
                    cursor: "pointer",
                  }}
                  onClick={() => handleNavigation(item.link)}
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    style={{
                      width: "100%",
                      height: "100%",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                </div>
                <p style={{ marginTop: "10px" }}>{item.title}</p>
              </Col>
            ))}
          </Row>
        </Content>

        {/* Responsive Styles */}
        <style>{`
        @media (max-width: 768px) {
          .icon-container {
            width: 50px;
            height: 50px;
          }
        }

        @media (max-width: 576px) {
          .icon-container {
            width: 40px;
            height: 40px;
          }
        }
      `}</style>
      </Layout>
    </>
  );
}
