import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Select,
  Typography,
} from "antd";
import { Country, State } from "country-state-city";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { get, post } from "../../services/Apiclient";
import { injectGlobalStyles, styles } from "./styles";

const { Option } = Select;
const { Text, Title } = Typography;

// Get country list
const countryOptions = Country.getAllCountries().map((country) => ({
  label: country.name,
  value: country.isoCode, // Use ISO code to fetch states
}));

interface EInvoiceData {
  merchantName?: string;
  userName?: string;
  address?: string;
  created_at?: string;
  salesno?: string;
  amount?: string;
}

interface MerchantData {
  tin?: string;
  brnno?: string;
  name?: string;
  email?: string;
  phone_number?: string;
  city?: string;
  stateCode?: string;
  address?: string;
}

const CashierEInvoiceLayout3: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>(
    JSON.parse(localStorage.getItem("cashierFormData") || "{}")
  );
  const salesNo = useParams<{ salesNo: string }>().salesNo;
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [states, setStates] = useState<any[]>([]);
  // const [cities, setCities] = useState<any[]>([]);
  const [useGeneralTIN, setUseGeneralTIN] = useState(false); // State for "Use general TIN" checkbox
  const [isForeigner, setIsForeigner] = useState(false);
  const [merchantData, setMerchantData] = useState<MerchantData | null>(null); // State to store merchant data

  const [einvoiceData, setEinvoiceData] = useState<EInvoiceData | null>(null);

  useEffect(() => {
    injectGlobalStyles();
    fetchEinvoiceData();
  }, [salesNo]);

  // Handle country selection
  const handleCountryChange = (countryCode: string) => {
    const statesList = State.getStatesOfCountry(countryCode).map((state) => ({
      label: state.name,
      value: state.isoCode,
    }));

    setStates(statesList); // Reset state list when country changes
    setFormData({ ...formData, country: countryCode, state: "", city: "" });
  };

  // // Handle state selection
  // const handleStateChange = (stateCode: string) => {
  //   const citiesList = City.getCitiesOfState(formData.country, stateCode).map(
  //     (city) => ({
  //       label: city.name,
  //       value: city.name,
  //     })
  //   );

  //   setCities(citiesList);
  //   setFormData({ ...formData, state: stateCode, city: "" });
  // };

  const fetchEinvoiceData = async () => {
    if (!salesNo) {
      message.error("No sales number provided in URL");
      // navigate("/cashiereinvoiceslayout1");
      return;
    }

    try {
      setLoading(true);
      const response = await get(`/message/${salesNo}`);

      if (response?.data?.success) {
        setEinvoiceData(response.data.einvoice);
      } else {
        message.error("Failed to fetch einvoice data");
      }
    } catch (error) {
      message.error("Error loading einvoice data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchMerchantData = async () => {
      const merchantId = localStorage.getItem("merchantid"); // Get merchant ID from localStorage
      if (merchantId) {
        try {
          const response = await get(`/merchant/${merchantId}`);
          if (response?.data?.data) {
            setMerchantData(response.data.data); // Store merchant data
          }
        } catch (error) {
          console.error("Error fetching merchant data:", error);
          message.error("Failed to fetch merchant data.");
        }
      }
    };

    fetchMerchantData();
  }, []);

  // Handle "Use general TIN" checkbox change
  const handleCheckboxChange = (e: any) => {
    if (e.target.checked) {
      setUseGeneralTIN(true);
      setIsForeigner(false); // Uncheck the "foreigner" checkbox
      form.setFieldsValue({ taxId: merchantData?.tin || "" }); // Set TIN if available
    } else {
      setUseGeneralTIN(false);
      form.setFieldsValue({ taxId: "" }); // Clear the TIN field if unchecked
    }
  };

  // Handle "I am a foreigner" checkbox change
  const handleForeignerChange = (e: any) => {
    if (e.target.checked) {
      setIsForeigner(true);
      setUseGeneralTIN(false); // Uncheck the "Use general TIN" checkbox
      form.setFieldsValue({ taxId: "" }); // Clear the TIN field for foreigners
    } else {
      setIsForeigner(false);
    }
  };

  const handleCustomerSubmit = async () => {
    try {
      const values = await form.validateFields(); // Validate form fields

      // Concatenate address fields
      const fullAddress = `${values.city}, ${values.state}, ${values.country}, ${values.postalZone}`;
      const generatedInvoiceId = `INV${salesNo}`;

      const invoicePayload = [
        {
          invoiceId: generatedInvoiceId, // Dynamic invoice ID
          issueDate: "2025-03-31", // YYYY-MM-DD
          issueTime: "00:40:00Z", // HH:mm:ssZ
          currency: "MYR",
          invoicePeriod: {
            startDate: "2025-03-01",
            endDate: "2025-03-31",
            description: "March 2025 Invoice Period",
          },
          billingReference: {
            id: "151891-1981",
          },
          additionalDocuments: [
            {
              id: "L1",
              type: "CustomsImportForm",
            },
            {
              id: "FTA",
              type: "FreeTradeAgreement",
              description: "Sample Description",
            },
          ],
          supplier: {
            tin: "C11425111080",
            brn: "200201029605",
            name: "Supplier Name",
            email: "supplier@email.com",
            phone: "+60123456789",
            city: "Kuala Lumpur",
            postalCode: "50480",
            stateCode: "14",
            address: "Lot 66,",
            address1: "Persiaran Jaya",
          },
          buyer: {
            tin: values.taxId || "", // Ensure it does not send 'undefined'
            brn: values.sstNumber || "",
            name: values.name,
            email: values.email,
            phone: values.phonenumber,
            city: values.city,
            postalCode: values.postalZone,
            stateCode: values.state,
            address: fullAddress,
            address1: fullAddress,
          },
          items: [
            {
              id: "1",
              description: "Laptop Peripherals",
              quantity: 1,
              price: 1436.5,
              allowance: {
                chargeIndicator: false,
                reason: "Discount",
                amount: 0.0,
              },
            },
          ],
          taxAmount: 87.63,
          taxSubtotals: [
            {
              taxableAmount: 1436.5,
              taxAmount: 87.63,
              taxCategoryId: "01",
            },
          ],
          paymentMeans: {
            code: "01",
            accountId: "1234567890",
          },
          lineExtensionAmount: 1436.5,
          taxExclusiveAmount: 1436.5,
          taxInclusiveAmount: 1524.13,
          allowanceTotalAmount: 0.0,
          chargeTotalAmount: 0.0,
          roundingAmount: 0.0,
          totalAmount: 1524.13,
        },
      ];

      setLoading(true);

      console.log("Sending payload:", JSON.stringify(invoicePayload, null, 2)); // Debugging

      const postResponse = await post(
        "/message/generateinvoice",
        invoicePayload
      );

      console.log("Response:", postResponse);

      if (
        postResponse?.data?.acceptedDocuments?.length > 0 &&
        postResponse?.data?.acceptedDocuments[0]?.uuid
      ) {
        const uuid = postResponse.data.acceptedDocuments[0].uuid; // Extract the uuid from accepted documents
        message.success(`Invoice generated successfully! UUID: ${uuid}`); // Success message with uuid
        localStorage.setItem("invoiceId", generatedInvoiceId);
        localStorage.setItem("invoiceUuid", uuid);
        navigate(`/cashiereinvoiceslayout4/${salesNo}`);
      } else if (
        postResponse?.data?.message === "Some invoices already exist" &&
        postResponse?.data?.existingInvoices?.length > 0
      ) {
        const existingUuid = postResponse.data.existingInvoices[0].uuid; // Extract the uuid from existing invoices
        message.warning(`Invoice already exists! UUID: ${existingUuid}`); // Warning message with the existing uuid
        localStorage.setItem("invoiceId", generatedInvoiceId); // Optionally store the generated invoice ID
        localStorage.setItem("invoiceUuid", existingUuid);
        navigate(`/cashiereinvoiceslayout4/${salesNo}`);
      } else {
        console.error("API Error:", postResponse?.data);
        message.error(
          `Failed to generate invoice. Error: ${
            postResponse?.data?.error || "Unknown error"
          }`
        );
      }
    } catch (error) {
      console.error("Error generating invoice:", error);
      message.error("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div style={{ backgroundColor: "#fff" }}>
        <div
          style={{
            ...styles.layoutContainer,
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            minHeight: "90vh",
          }}
        >
          <div
            style={{ color: "blue", textAlign: "end", marginBottom: "30px" }}
          >
            <a
              href="#"
              style={{ textDecoration: "underline", marginRight: 10 }}
            >
              FAQ
            </a>{" "}
            |{" "}
            <a href="#" style={{ textDecoration: "underline", marginLeft: 10 }}>
              INSTRUCTION
            </a>
          </div>
          {/* Section 1: Title */}
          <div
            style={{
              padding: "15px 20px",
              textAlign: "start",
              marginBottom: "20px",
            }}
          >
            <Title
              level={2}
              style={{
                marginTop: "0px",
                fontWeight: 600,
                fontSize: "18px",
                lineHeight: "1.5",
              }}
            >
              Declare Tax <br /> Invoice Number <br /> to Malaysia <br /> LHDN
              E-Invoice
            </Title>
            <Text
              style={{
                fontSize: "12px",
                color: "#8b8b8b",
                display: "block",
                marginTop: "8px",
              }}
            >
              Powered by Daco
            </Text>
          </div>

          {/* Terms of Service Notice */}
          <div
            style={{
              backgroundColor: "#fffbe6",
              padding: "10px 15px",
              borderRadius: "8px",
              marginBottom: "20px",
              textAlign: "start",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Text style={{ fontSize: "12px", color: "#000", display: "block" }}>
              By submitting the document, you are also agreeing to the terms
              listed in the{" "}
              <a href="#" style={{ textDecoration: "underline" }}>
                Terms of Service
              </a>
            </Text>
          </div>

          <div
            style={{
              backgroundColor: "#fff",
              padding: "20px 20px",
              borderRadius: "8px",
              marginBottom: "20px",
              textAlign: "start",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Text
              style={{ marginBottom: 10, color: "#8b8b8b", display: "block" }}
            >
              GUID
            </Text>
            <Text
              style={{
                display: "block",
                padding: 10,
                borderRadius: "8px",
                backgroundColor: "#f1f2f6",
              }}
            >
              330dd251-f
            </Text>
          </div>

          {/* Store & Sales Information */}
          <div
            style={{
              backgroundColor: "#fff",
              padding: "15px 20px",
              borderRadius: "8px",
              marginBottom: "20px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Text
              strong
              style={{
                fontSize: "14px",
                display: "block",
                marginBottom: 15,
                color: "#000",
                textAlign: "start",
              }}
            >
              Store & sales information
            </Text>
            <div
              style={{
                textAlign: "start",
                // lineHeight: "1.5",
              }}
            >
              <Text
                style={{
                  fontSize: "12px",
                  color: "#8b8b8b",
                  marginBottom: 5,
                  display: "block",
                }}
              >
                Company name
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  marginBottom: 15,
                  display: "block",
                }}
              >
                {einvoiceData?.merchantName}
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  color: "#8b8b8b",
                  marginBottom: 5,
                  display: "block",
                }}
              >
                Store name
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  marginBottom: 15,
                  display: "block",
                }}
              >
                {einvoiceData?.userName}
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  color: "#8b8b8b",
                  marginBottom: 5,
                  display: "block",
                }}
              >
                Store address
              </Text>
              <Text
                style={{ fontSize: "12px", marginBottom: 15, display: "block" }}
              >
                {einvoiceData?.address}
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  color: "#8b8b8b",
                  marginBottom: 5,
                  display: "block",
                }}
              >
                Sales Date
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  marginBottom: 15,
                  display: "block",
                }}
              >
                {einvoiceData?.created_at &&
                  new Date(einvoiceData.created_at)
                    .toLocaleString("en-GB", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                    })
                    .replace(/, /g, " - ")}
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  color: "#8b8b8b",
                  marginBottom: 5,
                  display: "block",
                }}
              >
                Sales number
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  marginBottom: 15,
                  display: "block",
                }}
              >
                {einvoiceData?.salesno || salesNo}
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  color: "#8b8b8b",
                  marginBottom: 5,
                  display: "block",
                }}
              >
                Sales Amount (RM)
              </Text>
              <Text
                style={{
                  fontSize: "12px",
                  display: "block",
                }}
              >
                {einvoiceData?.amount || "0.00"}
              </Text>
            </div>
          </div>

          {/* Buyer Details Form */}
          <div
            style={{
              // flex: 1,
              // overflowY: "auto",
              paddingBottom: "90px",
              backgroundColor: "#fff",
              padding: "15px 20px",
              borderRadius: "8px",
              marginBottom: "20px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <Text
              strong
              style={{
                fontSize: "14px",
                display: "block",
                marginBottom: "10px",
                color: "#000",
                padding: 10,
                borderRadius: "10px",
                backgroundColor: "#f1f2f6",
                textAlign: "start",
              }}
            >
              Buyer Detail
            </Text>
            <Form layout="vertical" form={form}>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px", color: "#8b8b8b" }}>
                    Buyer's name*
                  </span>
                }
                name="name"
                rules={[{ required: true, message: "Please enter your name" }]}
              >
                <Input type="name" allowClear />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px", color: "#8b8b8b" }}>
                    Buyer's TIN*
                  </span>
                }
                name="taxId"
                rules={[
                  { required: true, message: "Please enter your tax ID" },
                ]}
              >
                <Input type="text" allowClear />
              </Form.Item>

              <Form.Item name="useGeneralTIN" valuePropName="checked">
                <div style={styles.checkboxContainer}>
                  <Checkbox
                    onChange={handleCheckboxChange}
                    checked={useGeneralTIN} // Bind checkbox to state
                  >
                    Use general TIN
                  </Checkbox>
                  <Checkbox
                    style={{ fontSize: "12px", color: "#8b8b8b" }}
                    onChange={handleForeignerChange}
                    checked={isForeigner} // Bind checkbox to state
                  >
                    I am a foreigner
                  </Checkbox>
                </div>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px", color: "#8b8b8b" }}>
                    Buyer's identification Type*
                  </span>
                }
                name="identificationType"
                rules={[
                  {
                    required: true,
                    message: "Please select identification type",
                  },
                ]}
              >
                <Select
                  placeholder="Select..."
                  style={{ textAlign: "start", height: "46px" }}
                  allowClear
                >
                  <Option value="passport">Passport</Option>
                  <Option value="id">ID Card</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px", color: "#8b8b8b" }}>
                    Identification number*
                  </span>
                }
                name="idNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter identification number",
                  },
                ]}
              >
                <Input type="" allowClear />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px", color: "#8b8b8b" }}>
                    Buyer's SST Registration Number (if applicable)
                  </span>
                }
                name="sstNumber"
                rules={[
                  { required: false }, // Make this field optional
                ]}
              >
                <Input
                  placeholder="Sample: W88888888888888"
                  type=""
                  allowClear
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px", color: "#8b8b8b" }}>
                    Buyer's Email*
                  </span>
                }
                name="email"
                rules={[{ required: true, message: "Please enter your email" }]}
              >
                <Input type="email" allowClear />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px", color: "#8b8b8b" }}>
                    Buyer's contact number*
                  </span>
                }
                name="phonenumber"
                rules={[
                  { required: true, message: "Please enter your phone number" },
                ]}
              >
                <Input type="number" allowClear />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px", color: "#8b8b8b" }}>
                    Country*
                  </span>
                }
                name="country"
                rules={[
                  { required: true, message: "Please enter your country" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select a country"
                  optionFilterProp="label"
                  style={{ textAlign: "start", height: "46px" }}
                  onChange={handleCountryChange}
                  options={countryOptions}
                  allowClear
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px", color: "#8b8b8b" }}>
                    State*
                  </span>
                }
                name="state"
                rules={[{ required: true, message: "Please enter your state" }]}
              >
                <Select
                  showSearch
                  placeholder="Select a state"
                  optionFilterProp="label"
                  style={{ textAlign: "start", height: "46px" }}
                  // onChange={handleStateChange}
                  onChange={(value) =>
                    setFormData({ ...formData, state: value })
                  }
                  allowClear
                  options={states}
                  disabled={states.length === 0}
                />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px", color: "#8b8b8b" }}>
                    Postal Zone*
                  </span>
                }
                name="postalZone"
                rules={[
                  { required: true, message: "Please enter your postal zone" },
                ]}
              >
                <Input type="" allowClear />
              </Form.Item>
              <Form.Item
                label={
                  <span style={{ fontSize: "12px", color: "#8b8b8b" }}>
                    City Name*
                  </span>
                }
                name="city"
                rules={[{ required: true, message: "Please enter your city" }]}
              >
                <Input type="" allowClear />
              </Form.Item>
              <Button type="primary" onClick={handleCustomerSubmit}>
                SUBMIT
              </Button>
            </Form>
          </div>
          <div
            style={{
              textAlign: "center",
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CashierEInvoiceLayout3;
