import { Typography } from "antd";
import React from "react";

const { Text } = Typography;

interface FooterItem {
  label: string;
  icon: React.ReactNode;
  onClick?: () => void; // Optional click handler for each item
}

interface FooterProps {
  items: FooterItem[];
  activeIndex: number | null;
  setActiveIndex: (index: number) => void;
}

const navBarStyle: React.CSSProperties = {
  position: "fixed",
  zIndex: 1000,
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  backgroundColor: "#F9F9F9",
  borderTop: "1px solid #ddd",
  padding: "8px 0",
};

const itemStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  cursor: "pointer",
  color: "#818d9d", // Inactive color
  transition: "color 0.3s ease",
};

const activeItemStyle: React.CSSProperties = {
  ...itemStyle,
  color: "#6157f4",
};

const iconStyle: React.CSSProperties = {
  fontSize: "20px",
};

const ChildComponentFooter: React.FC<FooterProps> = ({
  items,
  activeIndex,
  setActiveIndex,
}) => {
  return (
    <div style={navBarStyle}>
      {items.map((item, index) => {
        const isActive = activeIndex === index;
        return (
          <div
            key={index}
            style={isActive ? activeItemStyle : itemStyle}
            onClick={() => {
              setActiveIndex(index);
              item.onClick?.();
            }}
          >
            <div
              style={{
                ...iconStyle,
                color: isActive ? "#6157f4" : "#818d9d",
              }}
            >
              {item.icon}
            </div>
            <Text
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: isActive ? "#6157f4" : "#818d9d",
              }}
            >
              {item.label}
            </Text>
          </div>
        );
      })}
    </div>
  );
};

export default ChildComponentFooter;
