import React, { useEffect, useState } from "react";
import {
  Layout,
  Card,
  Row,
  Col,
  Typography,
  Tabs,
  Divider,
  Button,
  Badge,
} from "antd";
import { BellOutlined, MenuOutlined, DownOutlined } from "@ant-design/icons";
import CommonListView from "../common/commonListView";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import Footer from "../headerfooter/footer/Footer";
import moment, { Moment } from "moment";
import InfiniteCalendar from "../common/InfiniteCalendar";
ChartJS.register(ArcElement, Tooltip, Legend);

const PaymentIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Payment_XJ.png`;
const StaffIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_Avatar_EmpF.png`;
const performanceDetailsIcon = `${process.env.PUBLIC_URL}/images/logo/performance-details-icon.png`;
const customerReviewIcon = `${process.env.PUBLIC_URL}/images/logo/customer-review-icon.png`;
const performanceRankingIcon = `${process.env.PUBLIC_URL}/images/logo/performance-ranking-icon.png`;

const { Header, Content } = Layout;
const { Title, Text } = Typography;
const { TabPane } = Tabs;

const Dashboard = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isCalendarModalVisible, setIsCalendarModalVisible] = useState(false);
  const [selectedPerformance, setSelectedPerformance] = useState("1");
  const [startDate, setStartDate] = useState<Moment | null>(moment());
  const [endDate, setEndDate] = useState<Moment | null>(moment());

  useEffect(() => {
    if (isModalVisible) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto"; // cleanup
    };
  }, [isModalVisible]);

  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  const handleConfirm = () => {
    console.log("Selected option:", selectedPerformance);
    setIsModalVisible(false);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  const handleOpenCalendarModal = () => {
    setIsCalendarModalVisible(true);
  };

  const handleDateChange = (start: Moment | null, end: Moment | null) => {
    console.log("Selected Start Date:", start?.format("YYYY-MM-DD"));
    console.log("Selected End Date:", end?.format("YYYY-MM-DD"));
    setStartDate(start);
    setEndDate(end);
  };

  const handleCancel = () => {
    console.log("Selection canceled");
    setIsCalendarModalVisible(false);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  // Sample data for Male/Female chart
  const genderValues = [0, 0];
  const totalGender = genderValues.reduce((a, b) => a + b, 0);

  const genderData = {
    labels: ["Male", "Female"],
    datasets: [
      {
        data: totalGender === 0 ? [1] : genderValues,
        backgroundColor:
          totalGender === 0
            ? ["#E0E0E0"] // grey fallback color
            : ["#3B4CCA", "#FF6A39"],
        borderWidth: 0,
      },
    ],
  };

  // Sample data for New/Returning chart
  const customerTypeValues = [0, 0];
  const totalCustomerType = customerTypeValues.reduce((a, b) => a + b, 0);

  const customerTypeData = {
    labels: ["New", "Returning"],
    datasets: [
      {
        data: totalCustomerType === 0 ? [1] : customerTypeValues,
        backgroundColor:
          totalCustomerType === 0 ? ["#E0E0E0"] : ["#3B82F6", "#FBBF24"],
        borderWidth: 0,
      },
    ],
  };

  const chartOptions = {
    cutout: "70%", // Donut hole size
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false, // Disable tooltips
      },
    },
  };

  const options = [
    { id: "1", name: "Performance" },
    { id: "2", name: "Net Performance" },
    { id: "3", name: "Turnover" },
    { id: "4", name: "Performance" },
    { id: "5", name: "Net Performance" },
    { id: "6", name: "Turnover" },
  ];

  return (
    <Layout style={{ minHeight: "100vh", backgroundColor: "#f5f5f5" }}>
      {/* Header Section */}
      <Row
        gutter={[16, 0]}
        style={{
          margin: "0px",
          background: "linear-gradient(135deg, #DFF2F9 0%, #F3F1FB 100%)",
          padding: "10px 20px",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Col
          span={22}
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={StaffIcon}
            alt="Staff Avatar"
            style={{ width: 48, borderRadius: "50%" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Title level={5} style={{ margin: 0 }}>
              ANNIE LEW TZE YIN (LA007)
            </Title>
            <Text type="secondary">001-LamLam Eyelash & Eyebrows</Text>
          </div>
        </Col>
        <Col span={2} style={{ position: "absolute", top: 10, right: 20 }}>
          <Badge dot>
            <BellOutlined style={{ fontSize: "24px" }} />
          </Badge>
        </Col>
      </Row>

      {/* Content Section */}
      <Content
        style={{
          marginBottom: "68px",
          padding: "16px",
          background:
            "linear-gradient(88deg, rgb(223, 242, 249) 0%, rgb(243, 241, 251) 100%)",
        }}
      >
        {/* Date Tabs */}
        <Tabs
          className="staff-date-selection"
          defaultActiveKey="1"
          centered
          type="line"
          tabPosition="top"
          moreIcon={null} // Removes the default ellipsis menu
          tabBarGutter={10}
          tabBarExtraContent={
            <Button
              icon={<MenuOutlined />}
              style={{
                color: "#d2d2d2",
                fontSize: 20,
                border: "none",
                backgroundColor: "#f5f5f5",
              }}
              onClick={handleOpenCalendarModal}
            />
          }
        >
          <TabPane tab="Today" key="1" />
          <TabPane tab="Yesterday" key="2" />
          <TabPane tab="This Month" key="3" />
          <TabPane tab="Last Month" key="4" />
        </Tabs>

        {/* Performance Card */}
        <Card
          style={{
            borderRadius: 12,
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)",
            backgroundColor: "#ffffff",
            padding: 0,
          }}
          bodyStyle={{ padding: "0px" }}
        >
          {/* Header */}
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: 16, padding: 12 }}
          >
            <Col span={12}>
              <Title
                level={5}
                style={{
                  margin: 0,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                My Performance ($)
              </Title>
            </Col>

            <Col span={12} style={{ display: "flex", justifyContent: "end" }}>
              <Button
                style={{
                  backgroundColor: "#F6F6F9",
                  borderRadius: 8,
                  border: "1px solid #E0E0E0",
                  color: "#333",
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                }}
                onClick={handleOpenModal}
              >
                <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                  Performance Performance{" "}
                </span>
                <DownOutlined style={{ fontSize: 12, marginLeft: 8 }} />
              </Button>
            </Col>
          </Row>
          <Divider style={{ margin: "10px 0px" }} />

          {/* Top Summary Section */}
          <Row gutter={[16, 16]} style={{ textAlign: "left", padding: 12 }}>
            <Col span={8} style={{ borderRight: "1px solid #efefef" }}>
              <Text type="secondary" className="normalbreak">
                Total performance
              </Text>
              <Title level={4} style={{ marginTop: 8 }}>
                0
              </Title>
            </Col>
            <Col span={8} style={{ borderRight: "1px solid #efefef" }}>
              <Text type="secondary" className="normalbreak">
                Total commission
              </Text>
              <Title
                level={4}
                style={{ marginTop: "auto", position: "absolute", bottom: 0 }}
              >
                0
              </Title>
            </Col>
            <Col span={8}>
              <Text type="secondary" className="normalbreak">
                Tips
              </Text>
              <Title
                level={4}
                style={{ marginTop: "auto", position: "absolute", bottom: 0 }}
              >
                0
              </Title>
            </Col>

            <Col span={8} style={{ borderRight: "1px solid #efefef" }}>
              <Text type="secondary" className="normalbreak">
                Service performance
              </Text>
              <Title
                level={4}
                style={{ marginTop: "auto", position: "absolute", bottom: 0 }}
              >
                0
              </Title>
            </Col>
            <Col span={8} style={{ borderRight: "1px solid #efefef" }}>
              <Text type="secondary" className="normalbreak">
                Product performance
              </Text>
              <Title level={4} style={{ marginTop: 8 }}>
                0
              </Title>
            </Col>
            <Col span={8}>
              <Text type="secondary" className="normalbreak">
                Privilege performance
              </Text>
              <Title level={4} style={{ marginTop: 8 }}>
                0
              </Title>
            </Col>
          </Row>

          {/* Divider */}
          <Divider style={{ margin: 0 }} />

          {/* Table-like Section */}
          <Row
            style={{
              backgroundColor: "#F6F6F9",
              padding: "8px 12px",
              borderRadius: 8,
              margin: "10px 10px 0px 10px",
            }}
          >
            <Col span={6}>
              <Text strong> </Text>
            </Col>

            <Col
              span={6}
              style={{
                textAlign: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Text style={{ color: "#a4a3a5" }}>Fulfilled perf.</Text>
            </Col>

            <Col
              span={6}
              style={{
                textAlign: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Text style={{ color: "#a4a3a5" }}>Collected payment perf.</Text>
            </Col>

            <Col
              span={6}
              style={{
                textAlign: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <Text style={{ color: "#a4a3a5" }}>Commission</Text>
            </Col>
          </Row>

          {/* Data Rows */}
          {[
            { label: "Service", values: ["0", "0", "0"] },
            { label: "Product", values: ["0", "0", "0"] },
            { label: "Privilege", values: ["-", "0", "0"] },
            { label: "Total", values: ["0", "0", "0"] },
          ].map((row, idx) => (
            <Row
              key={idx}
              style={{
                padding: "12px",
                borderBottom: idx === 3 ? "none" : "1px solid #F0F0F0",
                alignItems: "center",
              }}
            >
              <Col span={6}>
                <Text strong>{row.label}</Text>
              </Col>
              <Col span={6} style={{ textAlign: "center" }}>
                <Text strong>{row.values[0]}</Text>
              </Col>
              <Col span={6} style={{ textAlign: "center" }}>
                <Text strong>{row.values[1]}</Text>
              </Col>
              <Col span={6} style={{ textAlign: "center" }}>
                <Text strong>{row.values[2]}</Text>
              </Col>
            </Row>
          ))}
        </Card>

        {/* Privilege Card Payment */}
        <Card
          style={{
            marginTop: 10,
            marginBottom: 10,
            borderRadius: "12px 12px 0px 0px",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            boxShadow: "0px 4px 12px rgba(0,0,0,0.05)",
          }}
          bodyStyle={{ width: "100%", padding: 12 }}
        >
          <Row>
            <Col span={20}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  style={{
                    backgroundColor: "#FAAD14",
                    borderRadius: 4,
                    width: 24,
                    height: 24,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 8,
                  }}
                >
                  <Text strong style={{ color: "#fff", fontSize: 12 }}>
                    {/* Icon replacement */}
                    <img
                      src={PaymentIcon}
                      className="van-image__img"
                      alt="Payment"
                    />
                  </Text>
                </div>
                <Text strong>Privilege card payment</Text>
              </div>
            </Col>
            <Col span={4} style={{ display: "flex", justifyContent: "end" }}>
              <Title
                level={5}
                style={{
                  margin: 0,
                  fontWeight: 600,
                }}
              >
                0
              </Title>
            </Col>
          </Row>
        </Card>

        {/* More Section */}
        <Card
          style={{
            borderRadius: "0px 0px 12px 12px",
            boxShadow: "0px 4px 12px rgba(0,0,0,0.05)",
            marginBottom: 10,
          }}
          bodyStyle={{ padding: 12 }}
        >
          <Title level={5} style={{ margin: "0px 0px 10px 0px" }}>
            More
          </Title>

          {/* First row: Performance Details and Rankings */}
          <Row gutter={[16, 16]}>
            <Col span={14}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#dbebfa",
                    borderRadius: 12,
                    padding: 16,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Text className="normalbreak" style={{ fontSize: 14 }}>
                    Performance Details
                  </Text>
                  <img
                    src={performanceDetailsIcon}
                    style={{ height: "48px" }}
                    alt="Payment"
                  />
                </div>
                <div
                  style={{
                    backgroundColor: "#e1e6fa",
                    borderRadius: 12,
                    padding: 16,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Text className="normalbreak" style={{ fontSize: 14 }}>
                    Customer Reviews
                  </Text>
                  <img
                    src={customerReviewIcon}
                    style={{ height: "48px" }}
                    alt="Payment"
                  />
                </div>
              </div>
            </Col>

            <Col span={10}>
              <div
                style={{
                  backgroundColor: "#fef0ef",
                  borderRadius: 12,
                  padding: 16,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  height: "100%",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Text
                  className="normalbreak"
                  style={{ fontSize: 14, textAlign: "center" }}
                >
                  Performance Rankings
                </Text>
                <img
                  src={performanceRankingIcon}
                  style={{ height: "65px" }}
                  alt="Payment"
                />
              </div>
            </Col>
          </Row>
        </Card>

        {/* Number of Customers */}
        <Card
          style={{
            borderRadius: 12,
            boxShadow: "0px 4px 12px rgba(0,0,0,0.05)",
            backgroundColor: "#ffffff",
            padding: 12,
          }}
          bodyStyle={{ padding: 12 }}
        >
          {/* Header */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: 16,
            }}
          >
            <Title
              level={5}
              style={{
                margin: 0,
                fontWeight: 600,
                color: "#333333",
              }}
            >
              Number of customers
            </Title>
            <Title
              level={5}
              style={{
                margin: 0,
                fontWeight: 600,
                color: "#FF6A39", // Orange color
                marginLeft: 8,
              }}
            >
              0
            </Title>
          </div>

          {/* Charts & Legends */}
          <Row gutter={[16, 16]} align="middle" justify="center">
            {/* Gender Chart */}
            <Col span={12} style={{ textAlign: "center" }}>
              <Doughnut data={genderData} options={chartOptions} width={100} />

              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 8,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 8,
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <span
                      style={{
                        width: 8,
                        height: 8,
                        backgroundColor: "#3B4CCA",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    />
                    <Text style={{ color: "#333" }}>Male</Text>
                  </div>
                  <Text strong style={{ marginLeft: "auto" }}>
                    0
                  </Text>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 8,
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <span
                      style={{
                        width: 8,
                        height: 8,
                        backgroundColor: "#FF6A39",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    />
                    <Text style={{ color: "#333" }}>Female</Text>
                  </div>
                  <Text strong style={{ marginLeft: "auto" }}>
                    0
                  </Text>
                </div>
              </div>
            </Col>

            {/* Customer Type Chart */}
            <Col span={12} style={{ textAlign: "center" }}>
              <Doughnut
                data={customerTypeData}
                options={chartOptions}
                width={100}
              />

              <div
                style={{
                  marginTop: 16,
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  gap: 8,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 8,
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <span
                      style={{
                        width: 8,
                        height: 8,
                        backgroundColor: "#3B82F6",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    />
                    <Text style={{ color: "#333" }}>New</Text>
                  </div>
                  <Text strong style={{ marginLeft: "auto" }}>
                    0
                  </Text>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 8,
                    width: "90%",
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <span
                      style={{
                        width: 8,
                        height: 8,
                        backgroundColor: "#FBBF24",
                        borderRadius: "50%",
                        display: "inline-block",
                      }}
                    />
                    <Text style={{ color: "#333" }}>Returning</Text>
                  </div>
                  <Text strong style={{ marginLeft: "auto" }}>
                    0
                  </Text>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </Content>
      <Footer />
      {/* {isModalVisible && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100vw",
            background: "#fff",
            borderRadius: "40px 40px 0 0",
            boxShadow: "0px -4px 12px rgba(0, 0, 0, 0.1)",
            maxHeight: "65vh",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: "16px",
              right: "16px",
              zIndex: 1,
            }}
          >
            <Button
              type="text"
              icon={<CloseOutlined style={{ fontSize: 18, color:"#fff" }} />}
              onClick={handleClose}
              style={{
                backgroundColor: "#cecece",
                boxShadow: "none",
                borderRadius: "50%",
              }}
            />
          </div>
          <div
            style={{
              flex: 1,
              overflowY: "auto",
              padding: "40px 16px 16px",
              textAlign: "center",
            }}
          >
            {options.map((option) => (
              <Button
                key={option}
                type={selectedPerformance === option ? "default" : "text"}
                block
                onClick={() => setSelectedPerformance(option)}
                style={{
                  marginBottom: 16,
                  height: "48px",
                  fontWeight: "500",
                  color: selectedPerformance === option ? "#3651f8" : "#d3d3d3",
                  backgroundColor:
                    selectedPerformance === option ? "#f6f6f9" : "transparent",
                  border:
                    selectedPerformance === option
                      ? "1px solid #f6f6f9"
                      : "none",
                  borderRadius: "12px",
                  margin: "0px 30px",
                  width: "-webkit-fill-available",
                }}
              >
                {option}
              </Button>
            ))}
          </div>

          <div
            style={{
              padding: "16px",
            }}
          >
            <Button
              type="primary"
              block
              style={{
                height: "48px",
                backgroundColor: "#6758f8",
                borderColor: "#6758f8",
                borderRadius: "12px",
                fontWeight: "600",
              }}
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </div>
        </div>
      )} */}
      <InfiniteCalendar
        visible={isCalendarModalVisible}
        initialStartDate={startDate || undefined}
        initialEndDate={endDate || undefined}
        onDateChange={handleDateChange}
        onCancel={handleCancel}
      />
      <CommonListView
        visible={isModalVisible}
        onClose={handleCloseModal}
        options={options}
        selectedPerformance={selectedPerformance}
        setSelectedPerformance={setSelectedPerformance}
        handleConfirm={handleConfirm}
      />
    </Layout>
  );
};

export default Dashboard;
