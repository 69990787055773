import React from "react";
import { Route, Routes } from "react-router-dom";
import { userRoles } from "../constants/userRoles";
import MainLayout from "../layouts/MainLayout";
import AppForgotPassword from "../mobile/auth/ForgotPassword";
import AppOtpScreen from "../mobile/auth/OtpScreen";
import AppSignIn from "../mobile/auth/SignIn";
import AppSignUp from "../mobile/auth/SignUp";
import Allergies from "../mobile/booking/Allergies";
import AppointmentDetails from "../mobile/booking/AppointmentDetails";
import AppointmentList from "../mobile/booking/AppointmentList";
import AppointmentSuccess from "../mobile/booking/AppointmentSuccess.";
import AppBooking from "../mobile/booking/Booking";
import OutletAppList from "../mobile/booking/OutletList";
import ServiceStaffSelection from "../mobile/booking/ServiceStaffSelection";
import GiftCardDetails from "../mobile/giftCard/GiftCardDetails";
import GiftCardList from "../mobile/giftCard/GiftCardList";
import AppMembershipDetail from "../mobile/membership/MembershipDetail";
import AppMembershipList from "../mobile/membership/MembershipList";
import MerchantDetails from "../mobile/merchant/MerchantDetails";
import NewsDetails from "../mobile/news/NewsDetails";
import NewsList from "../mobile/news/NewsList";
import OutletDetails from "../mobile/outlet/OutletDetails";
import AppPackageDetail from "../mobile/package/PackageDetail";
import AppPackageList from "../mobile/package/PackageList";
import PersonalDetails from "../mobile/personalInfo/PersonalDetails";
import UserProfile from "../mobile/profile/UserProfile";
import AppPurchaseHistory from "../mobile/purchaseHistory/AppPurchaseHistory";
import AppPurchaseHistoryDetails from "../mobile/purchaseHistory/AppPurchaseHistoryDetails";
import AddAddress from "../mobile/settings/AddAddress";
import AddressSetting from "../mobile/settings/AddressSetting";
import ChangePassword from "../mobile/settings/ChangePassword";
import LanguageSelection from "../mobile/settings/LanguageSelection";
import MyAddress from "../mobile/settings/MyAddress";
import AllProducts from "../mobile/shop/AllProducts";
import AllPromotion from "../mobile/shop/AllPromotion";
import PaymentSuccess from "../mobile/shop/PaymentSuccess";
import ProductCart from "../mobile/shop/ProductCart";
import ProductCategory from "../mobile/shop/ProductCategory";
import ProductCheckout from "../mobile/shop/ProductCheckout";
import ProductDetails from "../mobile/shop/ProductDetails";
import Products from "../mobile/shop/Products";
import AppointmentDetail from "../mobile/staff/appointment/AppointmentDetail";
import BookingList from "../mobile/staff/appointment/BookingList";
import LoadingPage from "../mobile/staff/auth/LoadingPage";
import StaffOutletSelection from "../mobile/staff/auth/StaffOutletSelection";
import StaffSignin from "../mobile/staff/auth/StaffSignin";
import Dashboard from "../mobile/staff/dashboard/Dashboard";
import NotificationDetails from "../mobile/staff/notification/NotificationDetails";
import NotificationPage from "../mobile/staff/notification/NotificationPage";
import PerformanceDetails from "../mobile/staff/performance/PerformanceDetails";
import AssignService from "../pages/AssignService";
import EInvoiceForm from "../pages/EInvoiceForm";
import Home from "../pages/Home";
import InactivityTimer from "../pages/InactivityTimer";
import NotFound from "../pages/NotFound";
import Booking from "../pages/booking/Booking";
import CashierEInvoiceLayout1 from "../pages/cashierEInvoice/CashierEInvoiceLayout1";
import CashierEInvoiceLayout2 from "../pages/cashierEInvoice/CashierEInvoiceLayout2";
import CashierEInvoiceLayout3 from "../pages/cashierEInvoice/CashierEInvoiceLayout3";
import CashierEInvoiceLayout4 from "../pages/cashierEInvoice/CashierEInvoiceLayout4";
import CategoryList from "../pages/category/CategoryList";
import CommonData from "../pages/commonData/CommonData";
import CustomerForm from "../pages/customer/CustomerForm";
import CustomerFormqr from "../pages/customer/CustomerFormqr";
import CustomerList from "../pages/customer/CustomerList";
import CustomerProfile from "../pages/customer/CustomerProfile";
import CustomerTag from "../pages/customerTags/CustomerTag";
import Einvoices from "../pages/einvoice/Einvoices";
import GiftCardsList from "../pages/giftCards/GiftCardsList";
import GiftCardsView from "../pages/giftCards/GiftCardsView";
import Inventory from "../pages/inventory/Inventory";
import MembershipList from "../pages/membership/MembershipList";
import MembershipView from "../pages/membership/MembershipView";
import MerchantForm from "../pages/merchant/MerchantForm";
import MerchantList from "../pages/merchant/MerchantList";
import NoticeBoardAdd from "../pages/noticeboard/NoticeBoardAdd";
import NoticeBoardList from "../pages/noticeboard/NoticeboardList";
import OnlineSales from "../pages/onlineSales/OnlineSales";
import OnlineSalesBillDetails from "../pages/onlineSales/OnlineSalesBillDetails";
import OutletForm from "../pages/outlet/OutletForm";
import OutletList from "../pages/outlet/OutletList";
import OverTime from "../pages/overTime/OverTime";
import PackagesList from "../pages/packages/PackagesList";
import PackagesView from "../pages/packages/PackagesView";
import AddCommissionPlan from "../pages/performance/AddCommissionPlan";
import AddPerformancePlan from "../pages/performance/AddPerformancePlan";
import CalculationSettings from "../pages/performance/CalculationSettings";
import CommissionPlan from "../pages/performance/CommissionPlan";
import Performance from "../pages/performance/Performance";
import PerformancePlan from "../pages/performance/PerformancePlan";
import ProductAdd from "../pages/product/ProductAdd";
import ProductList from "../pages/product/ProductList";
import Reports from "../pages/report/Reports";
import AccountingRecordSheet from "../pages/report/reports/AccountingRecordSheet";
import ExpiryInventoryReport from "../pages/report/reports/ExpiryInventoryReport";
import StockInInventoryReport from "../pages/report/reports/StockInInventoryReport";
import StockOutInventoryReport from "../pages/report/reports/StockOutInventoryReport";
import TipsDetails from "../pages/report/reports/TipsDetails";
import TipsSummary from "../pages/report/reports/TipsSummary";
import AccountingReport from "../pages/reports/sales/AccountingRecordSheet";
import RoomAdd from "../pages/room/RoomAdd";
import RoomList from "../pages/room/RoomList";
import BillDetails from "../pages/sales/BillDetails";
import Billing from "../pages/sales/Billing";
import Pay from "../pages/sales/Pay";
import Sales from "../pages/sales/Sales";
import ServiceAdd from "../pages/service/ServiceAdd";
import ServiceList from "../pages/service/ServiceList";
import Settings from "../pages/settings/Settings";
import StaffScheduling from "../pages/shiftScheduling/ShiftScheduling";
import SignInForm from "../pages/signup/SignInForm";
import StaffAdd from "../pages/staff/StaffAdd";
import StaffInfo from "../pages/staff/StaffInfo";
import StaffList from "../pages/staff/StaffList";
import Attendance from "../pages/staffAttendance/Attendance";
import AttendanceReview from "../pages/staffAttendance/AttendanceReview";
import StockInAdd from "../pages/stock/StockInAdd";
import StockInDetailView from "../pages/stock/StockInDetailView";
import StockInList from "../pages/stock/StockInList";
import StockOutAdd from "../pages/stock/StockOutAdd";
import StockOutDetailView from "../pages/stock/StockOutDetailView";
import StockOutList from "../pages/stock/StockOutList";
import AddStockTakes from "../pages/stock/StockTakesAdd";
import StockTakesDetailView from "../pages/stock/StockTakesDetailView";
import StockTakesList from "../pages/stock/StockTakesList";
import TimeOff from "../pages/timeOff/TimeOff";
import WorkTags from "../pages/workTags/WorkTags";
import ProtectedRoute from "../routes/ProtectedRoute";
import BillingSummary from "../pages/report/reports/BillingSummary";
import GiftcardSalesReport from "../pages/report/reports/GiftcardSalesReport";
import StockInOutDetailReport from "../pages/report/reports/StockInOutDetailReport";
import HeaderOnlyLayout from "../layouts/HeaderOnlyLayout";
import WorkingHoursSummary from "../pages/report/reports/WorkingHoursSummary";
import SalesSummaryReport from "../pages/report/reports/SalesSummaryReport";
import SettlementSheetSummaryReport from "../pages/report/reports/SettlementSheetSummaryReport";

const AppRoutes: React.FC = () => (
  <>
    <InactivityTimer />
    <Routes>
      <Route
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MainLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path="/"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="customer"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <CustomerList />
            </ProtectedRoute>
          }
        />

        <Route
          path="noticeboard"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <NoticeBoardList />
            </ProtectedRoute>
          }
        />

        <Route
          path="onlinesales"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <OnlineSales />
            </ProtectedRoute>
          }
        />

        <Route
          path="merchant/list"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <MerchantList />
            </ProtectedRoute>
          }
        />
        <Route
          path="settings"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <Settings />
            </ProtectedRoute>
          }
        />
        <Route
          path="reports"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <Reports />
            </ProtectedRoute>
          }
        />
        <Route
          path="inventory"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <Inventory />
            </ProtectedRoute>
          }
        />
        <Route
          path="sales"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <Sales />
            </ProtectedRoute>
          }
        />
        <Route
          path="booking"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <Booking />
            </ProtectedRoute>
          }
        />
        <Route
          path="einvoices"
          element={
            <ProtectedRoute
              allowedRoles={[
                userRoles.SUPERADMIN,
                userRoles.OUTLETADMIN,
                userRoles.CUSTOMER,
                userRoles.MERCHANT,
                userRoles.STAFF,
              ]}
            >
              <Einvoices />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <HeaderOnlyLayout />
          </ProtectedRoute>
        }
      >
        <Route
          path="cashiereinvoiceslayout1"
          element={<CashierEInvoiceLayout1 />}
        />
      </Route>

      <Route path=":id" element={<CashierEInvoiceLayout2 />} />

      <Route
        path="cashiereinvoiceslayout3/:salesNo"
        element={<CashierEInvoiceLayout3 />}
      />

      <Route
        path="cashiereinvoiceslayout4/:salesNo"
        element={<CashierEInvoiceLayout4 />}
      />

      <Route
        path="settings/product/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ProductList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/product/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ProductAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/product/edit/:productId"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ProductAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/product/duplicate/:productId"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ProductAdd />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/service/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ServiceList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/service/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ServiceAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/service/edit/:serviceId"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ServiceAdd />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/room/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <RoomList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/room/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <RoomAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/room/edit/:roomId"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <RoomAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/room/duplicate/:roomId"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <RoomAdd />
          </ProtectedRoute>
        }
      />

      <Route
        path="outlet"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OutletList />
          </ProtectedRoute>
        }
      />
      <Route
        path="outlet/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OutletForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="outlet/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OutletForm />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/business-details"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OutletList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/business-details/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OutletForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/business-details/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OutletForm />
          </ProtectedRoute>
        }
      />

      <Route
        path="common-data"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CommonData />
          </ProtectedRoute>
        }
      />

      <Route
        path="reports/billsummary"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <BillingSummary />
          </ProtectedRoute>
        }
      />

      <Route
        path="reports/giftcardsalesreport"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <GiftcardSalesReport />
          </ProtectedRoute>
        }
      />

      <Route
        path="reports/stockinoutdetailreport"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockInOutDetailReport />
          </ProtectedRoute>
        }
      />

      {/* reports routes */}
      <Route
        path="reports/workinghoursummary"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <WorkingHoursSummary />
          </ProtectedRoute>
        }
      />
      <Route
        path="reports/tipsummary"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <TipsSummary />
          </ProtectedRoute>
        }
      />

      <Route
        path="reports/tipdetails"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <TipsDetails />
          </ProtectedRoute>
        }
      />

      <Route
        path="reports/stockininventory"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockInInventoryReport />
          </ProtectedRoute>
        }
      />

      <Route
        path="reports/stockoutinventory"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockOutInventoryReport />
          </ProtectedRoute>
        }
      />

      <Route
        path="reports/stockinoutinventory"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockInOutDetailReport />
          </ProtectedRoute>
        }
      />

      <Route
        path="reports/expiryinventory"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <ExpiryInventoryReport />
          </ProtectedRoute>
        }
      />

      <Route
        path="reports/accountingReport"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AccountingRecordSheet />
          </ProtectedRoute>
        }
      />

      <Route
        path="reports/salessummary"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <SalesSummaryReport />
          </ProtectedRoute>
        }
      />

      <Route
        path="reports/settlementsummary"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <SettlementSheetSummaryReport />
          </ProtectedRoute>
        }
      />

      <Route
        path="inventory/stockin/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockInList />
          </ProtectedRoute>
        }
      />
      <Route
        path="noticeboard/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <NoticeBoardAdd />
          </ProtectedRoute>
        }
      />

      <Route
        path="noticeboard/edit/:noticeBoardId"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <NoticeBoardAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockin/Add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockInAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockin/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockInAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockout/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockOutList />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockout/Add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockOutAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockout/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockOutAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stocktakes/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockTakesList />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stocktakes/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AddStockTakes />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stocktakes/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AddStockTakes />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockindetails/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockInDetailView />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stockoutdetails/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockOutDetailView />
          </ProtectedRoute>
        }
      />
      <Route
        path="inventory/stocktakesdetails/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StockTakesDetailView />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/staff/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StaffList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/staff/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StaffAdd />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/staff/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StaffAdd />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/staffScheduling/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StaffScheduling />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/timeOff/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <TimeOff />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/overTime/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OverTime />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/attendance/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <Attendance />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/attendance/review"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AttendanceReview />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/performance/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <Performance />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/performance/plan"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <PerformancePlan />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/performance/plan/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AddPerformancePlan />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/performance/plan/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AddPerformancePlan />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/performance/plan/calculationsettings/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CalculationSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/performance/commissionplan"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CommissionPlan />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/performance/commissionplan/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AddCommissionPlan />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/performance/commissionplan/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AddCommissionPlan />
          </ProtectedRoute>
        }
      />

      <Route
        path="customer/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CustomerForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="customer/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CustomerProfile />
          </ProtectedRoute>
        }
      />
      <Route
        path="customer/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CustomerForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="customer/addcustomer/:merchant_id/:outlet_id"
        element={<CustomerFormqr />}
      />

      <Route path="einvoice" element={<EInvoiceForm />} />

      <Route
        path="settings/membership/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MembershipList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/membership/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MembershipView />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/worktags"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <WorkTags />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/customertags"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CustomerTag />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/giftCards/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <GiftCardsList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/giftCards/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <GiftCardsView />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/packages/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <PackagesList />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/package/view/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <PackagesView />
          </ProtectedRoute>
        }
      />

      <Route
        path="settings/packages/view"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <PackagesView />
          </ProtectedRoute>
        }
      />

      <Route
        path="merchant/add"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MerchantForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="merchant/edit/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MerchantForm />
          </ProtectedRoute>
        }
      />
      <Route
        path="merchant/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <MerchantList />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/staff/info/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <StaffInfo />
          </ProtectedRoute>
        }
      />
      <Route
        path="settings/category/list"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <CategoryList />
          </ProtectedRoute>
        }
      />

      <Route
        path="sales/billing"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <Billing />
          </ProtectedRoute>
        }
      />
      <Route
        path="sales/billing/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <Billing />
          </ProtectedRoute>
        }
      />
      <Route
        path="sales/pay/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <Pay />
          </ProtectedRoute>
        }
      />
      <Route
        path="onlinesales/billdetails/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <OnlineSalesBillDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="sales/billdetails/:id"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <BillDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="designshow"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AssignService />
          </ProtectedRoute>
        }
      />
      <Route
        path="reports/accounting"
        element={
          <ProtectedRoute
            allowedRoles={[
              userRoles.SUPERADMIN,
              userRoles.OUTLETADMIN,
              userRoles.CUSTOMER,
              userRoles.MERCHANT,
              userRoles.STAFF,
            ]}
          >
            <AccountingReport />
          </ProtectedRoute>
        }
      />

      <Route path="/login" element={<SignInForm />} />

      <Route path="*" element={<NotFound />} />

      {/* ---------------- Mobile routes ----------------  */}
      {/* Notice board routes */}
      <Route path="/app/news/:merchantId" element={<NewsList />} />
      <Route
        path="/app/news/details/:merchantId/:id"
        element={<NewsDetails />}
      />

      {/* Customer package routes */}
      <Route path="/app/package/:merchantId" element={<AppPackageList />} />
      <Route
        path="/app/package/details/:merchantId/:id"
        element={<AppPackageDetail />}
      />

      {/* Sign up Sign in routes */}
      <Route path="/app/signUp/:merchantId" element={<AppSignUp />} />
      <Route path="/app/signIn/:merchantId" element={<AppSignIn />} />
      <Route
        path="/app/otp-screen/:merchantId/:data/:type/:navigat"
        element={<AppOtpScreen />}
      />
      <Route
        path="/app/forgot-password/:merchantId/:email"
        element={<AppForgotPassword />}
      />

      <Route
        path="/app/merchant/details/:merchantId"
        element={<MerchantDetails />}
      />
      <Route
        path="/app/outlet/details/:merchantId/:id"
        element={<OutletDetails />}
      />
      <Route path="/app/giftcard/list/:merchantId" element={<GiftCardList />} />
      <Route
        path="/app/giftcard/details/:merchantId/:id"
        element={<GiftCardDetails />}
      />
      <Route
        path="/app/personalInfo/details/:merchantId"
        element={<PersonalDetails />}
      />
      <Route path="/app/profile/:merchantId" element={<UserProfile />} />
      {/* customer profile */}
      <Route
        path="/app/setting/addresssetting/:merchantId"
        element={<AddressSetting />}
      />
      <Route
        path="/app/setting/myaddress/:merchantId"
        element={<MyAddress />}
      />
      <Route
        path="/app/setting/addaddress/:merchantId"
        element={<AddAddress />}
      />
      <Route
        path="/app/setting/addaddress/:merchantId/:id"
        element={<AddAddress />}
      />
      <Route
        path="/app/setting/changepassword/:merchantId"
        element={<ChangePassword />}
      />
      <Route
        path="/app/setting/languageselection/:merchantId"
        element={<LanguageSelection />}
      />
      {/* App shop route */}
      <Route path="/app/products/:merchantId" element={<Products />} />
      <Route path="/app/allproducts/:merchantId" element={<AllProducts />} />
      <Route path="/app/allpromotion/:merchantId" element={<AllPromotion />} />
      <Route
        path="/app/productCategory/:merchantId/:id"
        element={<ProductCategory />}
      />
      <Route
        path="/app/productDetails/:merchantId/:id"
        element={<ProductDetails />}
      />
      <Route path="/app/cart/:merchantId" element={<ProductCart />} />
      <Route
        path="/app/checkout/:merchantId/:id"
        element={<ProductCheckout />}
      />
      <Route path="/app/success/:merchantId" element={<PaymentSuccess />} />

      {/* App membership route */}
      <Route
        path="/app/membership/:merchantId"
        element={<AppMembershipList />}
      />
      <Route path="/app/membership/details" element={<AppMembershipDetail />} />
      <Route
        path="/app/membership/details/:merchantId/:id"
        element={<AppMembershipDetail />}
      />

      {/* App booking routes */}
      <Route
        path="/app/appointment/list/:merchantId"
        element={<AppointmentList />}
      />
      <Route path="/app/outlet/list/:merchantId" element={<OutletAppList />} />
      <Route
        path="/app/servicestaff/list/:merchantId"
        element={<ServiceStaffSelection />}
      />
      <Route path="/app/booking/:merchantId" element={<AppBooking />} />
      <Route path="/app/allergies/:merchantId" element={<Allergies />} />
      <Route
        path="/app/appointmentsuccess/:merchantId"
        element={<AppointmentSuccess />}
      />
      <Route
        path="/app/appointment/details/:appointmentId/:merchantId/:serviceId"
        element={<AppointmentDetails />}
      />

      {/* Purchase History */}
      <Route
        path="/app/purchasehistory/:merchantId"
        element={<AppPurchaseHistory />}
      />
      <Route
        path="/app/purchasehistory/details/:merchantId"
        element={<AppPurchaseHistoryDetails />}
      />

      {/* ---------------- Staff Mobile routes ----------------  */}
      {/* auth route */}
      <Route path="/app/staff/signin" element={<StaffSignin />} />
      <Route
        path="/app/staff/outletselection"
        element={<StaffOutletSelection />}
      />
      <Route path="/app/staff/loading" element={<LoadingPage />} />

      <Route path="/app/staff/dashboard" element={<Dashboard />} />
      <Route
        path="/app/staff/performancedetails"
        element={<PerformanceDetails />}
      />
      <Route path="/app/staff/notifications" element={<NotificationPage />} />
      <Route
        path="/app/staff/notificationdetail"
        element={<NotificationDetails />}
      />

      {/* appointment route */}
      <Route path="/app/staff/booking/list" element={<BookingList />} />
      <Route
        path="/app/staff/appointment/details"
        element={<AppointmentDetail />}
      />
    </Routes>
  </>
);

export default AppRoutes;
