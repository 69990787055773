import { Button, Form, Input } from "antd";
import Title from "antd/es/typography/Title";
import React, { useState } from "react";
import StaffOutletSelection from "./StaffOutletSelection";
import { useNavigate } from "react-router-dom";

const formItemStyle: React.CSSProperties = {
  marginBottom: "16px",
};

const signInButtonStyle: React.CSSProperties = {
  backgroundColor: "#f0f0f0",
  color: "#000",
  border: "none",
  height: "48px",
  fontSize: "16px",
  width: "100%",
  marginTop: "40px",
  borderRadius: "8px",
};

const StaffSignin: React.FC = () => {
  const navigate = useNavigate()
  // const [showStaffOutletSelection, setShowStaffOutletSelection] =
  //   useState(false);

  const handleSignIn = () => {
    // Simulate sign-in success
    // setShowStaffOutletSelection(true);
    navigate("/app/staff/outletselection")
  };

  // if (showStaffOutletSelection) {
  //   return <StaffOutletSelection />;
  // }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "90vh",
        backgroundColor: "#fff",
        padding: "20px",
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "350px",
          padding: "20px",
        }}
      >
        <Title
          level={2}
          style={{
            textAlign: "center",
            fontWeight: "bold",
            marginBottom: "40px",
            color: "#000",
          }}
        >
          Sign in to Daco Staff
        </Title>

        <Form
          layout="vertical"
          onFinish={handleSignIn}
          style={{ maxWidth: "400px", margin: "0 auto" }}
        >
          <Form.Item
            label="E-Mail / Phone number"
            name="email"
            required
            style={formItemStyle}
          >
            <Input
              placeholder="E-Mail / Phone number"
              type="email"
              autoComplete="off"
            />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            required
            style={formItemStyle}
          >
            <Input.Password
              placeholder="Password"
              autoComplete="new-password"
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={signInButtonStyle}>
              Sign In
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default StaffSignin;
