import React, { useEffect, useState } from "react";
import {
  Layout,
  Row,
  Col,
  Button,
  Input,
  Dropdown,
  Menu,
  message,
  Modal,
  Pagination,
  Radio,
} from "antd";
import {
  PlusOutlined,
  FilterOutlined,
  RightOutlined,
  ArrowLeftOutlined,
  LeftOutlined,
  SearchOutlined,
  SettingFilled,
  DownloadOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { deleteData, get, post, put } from "../../services/Apiclient";
import Datatable from "../../components/Datatable";
import { useNavigate } from "react-router-dom";
import "../../styles/global.css";
import Mainmodel from "../../components/model/Mainmodel";
import SupplierList from "../supplier/SupplierList";
import MembershipModel from "../../components/model/membership/MembershipModel";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import { useCurrency } from "../../contexts/CurrencyContext";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
// import { currencySymbol } from "../../constants/currencySymbol";

const { Content } = Layout;

interface MembershipData {
  key: React.Key;
  memcode: string;
  memname: string;
  memtype: string;
  price: string;
  sessions: string;
  discount: string;
  validfor: string;
  status: string;
}

function MembershipList() {
  const { currencySymbol } = useCurrency();
  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState<MembershipData[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [selectedUsageStatus, setSelectedUsageStatus] = useState<string[]>([]);
  const [usageDropdownVisible, setUsageDropdownVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataAdded, setDataAdded] = useState(false);
  const [valueChangeSearch, setValueChangeSearch] = useState(false);
  const [originalDataSource, setOriginalDataSource] = useState<
    MembershipData[]
  >([]);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedMemebrship, setSelectedMemebrship] =
    useState<MembershipData | null>(null);
  const [isMultipleDeleteModalVisible, setIsMultipleDeleteModalVisible] =
    useState(false);

  const handleDeleteAllSelected = () => {
    setIsMultipleDeleteModalVisible(true); // Show delete confirmation modal
  };

  const handleDeleteCancel = () => {
    setIsMultipleDeleteModalVisible(false); // Hide delete confirmation modal
  };

  const handleMembershipAdd = () => {
    setDataAdded(!dataAdded);
  };

  const handleDeleteConfirm = async () => {
    try {
      const membershipIds = selectedRowKeys;
      const body = { membershipIds };
      const response = await put("/membership/deleteall", body); // Call API

      if (response?.status === 200 && response.data.success) {
        // Remove deleted items from the data source
        const updatedDataSource = dataSource.filter(
          (item) => !selectedRowKeys.includes(item.key)
        );
        setDataSource(updatedDataSource);
        setSelectedRowKeys([]);
        message.success("Selected items deleted successfully");
      } else {
        message.error("Failed to delete selected items");
      }
    } catch (error) {
      message.error("An error occurred while deleting items");
      console.error("Error deleting items:", error);
    } finally {
      setIsMultipleDeleteModalVisible(false); // Close the modal
    }
  };

  // Function to show the modal
  const showModal = () => {
    setIsModalVisible(true);
  };

  // Function to handle cancel or close of the modal
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  // Function to handle save from the modal
  const handleSave = (data: any) => {
    console.log("Saved Data:", data); // Replace with actual save logic
    setIsModalVisible(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  // Handle Usage Status change
  const handleUsageStatusChange = (e: any) => {
    setSelectedUsageStatus(e.target.value);
  };

  const resetUsageFilter = () => {
    setSelectedUsageStatus([]);
    // setDataSource(originalDataSource);
  };

  // Apply Usage Status filter
  const applyUsageFilter = () => {
    if (selectedUsageStatus.length === 0) {
      setDataSource(originalDataSource);
    } else {
      const filteredData = originalDataSource.filter((item) =>
        selectedUsageStatus.includes(item.status)
      );
      setDataSource(filteredData);
    }
    setUsageDropdownVisible(false);
  };

  // Confirm deletion
  const handleConfirmDelete = async () => {
    if (selectedMemebrship) {
      try {
        const url = `/membership/remove/${selectedMemebrship.key}`;
        const response = await deleteData(url);

        if (response?.status === 200 || response?.status === 204) {
          console.log("Deleted membership with key:", selectedMemebrship.key);

          setDataSource((prevData) =>
            prevData.filter((item) => item.key !== selectedMemebrship.key)
          );

          // Close the delete modal
          setDeleteModalVisible(false);
          message.success("Deleted successfully");
        } else {
          console.error("Error deleting room:", response);
          // Handle the error scenario here (e.g., show a notification or error message)
        }
      } catch (error) {
        console.error("Error during delete operation:", error);
      }
    }
  };

  // Cancel deletion
  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const handleDeleteClick = (record: MembershipData) => {
    setSelectedMemebrship(record);
    setDeleteModalVisible(true);
  };

  // Usage Status Menu
  const usageStatusMenu = (
    <Menu>
      <Radio.Group
        style={{ padding: "10px" }}
        value={selectedUsageStatus}
        onChange={handleUsageStatusChange}
      >
        <Row>
          <Col span={24}>
            <Radio value="Disabled">Disabled</Radio>
          </Col>
          <Col span={24}>
            <Radio value="Enabled">Enabled</Radio>
          </Col>
        </Row>
      </Radio.Group>
      <Row justify="space-between" style={{ padding: "10px" }}>
        <Button onClick={resetUsageFilter}>Reset</Button>
        <Button type="primary" onClick={applyUsageFilter}>
          OK
        </Button>
      </Row>
    </Menu>
  );

  const usageFilterIconStyle = {
    color: selectedUsageStatus.length > 0 ? "#1890ff" : "#aaa",
    cursor: "pointer",
    marginLeft: "8px",
  };

  const columns = [
    {
      title: "Mem. Code",
      dataIndex: "memcode",
      key: "memcode",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Mem. Name",
      dataIndex: "memname",
      key: "memname",
      width: 200,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Mem. Type",
      dataIndex: "memtype",
      key: "memtype",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 100,
      render: (sellprice: number) =>
        `${currencySymbol.CURRENCY_SYMBOL}${sellprice}`,
    },
    {
      title: "Value / Sessions",
      dataIndex: "sessions",
      key: "sessions",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Valid for",
      dataIndex: "validfor",
      key: "validfor",
      width: 200,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: (
        <>
          Status
          <Dropdown
            overlay={usageStatusMenu}
            trigger={["click"]}
            visible={usageDropdownVisible}
            onVisibleChange={(flag) => setUsageDropdownVisible(flag)}
            overlayStyle={{ width: "150px" }} // Adjust size here
          >
            <FilterOutlined style={usageFilterIconStyle} />
          </Dropdown>
        </>
      ),
      dataIndex: "status",
      key: "status",
      width: 100,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right" as "right",
      width: 150,
      render: (text: any, record: MembershipData): React.ReactNode => (
        <>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => navigate(`/settings/membership/view/${record.key}`)}
          >
            View
          </a>
          {/* <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            // onClick={() =>
            //   navigate(`/settings/product/duplicate/${record.key}`)
            // }
          >
            Duplicate
          </a> */}
          {/* <a style={{ color: 'red' }} onClick={() => handleDeleteClick(record)} > */}
          <a
            style={{
              color: "rgb(245, 63, 63)",
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
            }}
            onClick={() => handleDeleteClick(record)}
          >
            Delete
          </a>
        </>
      ),
    },
  ];

  const fetchData = async () => {
    try {
      // const url = `/room/${localStorage.getItem(
      //   "outlet_id"
      // )}/${currentPage}/${pageSize}/created_at/DESC/${searchText || null}`;
      const url = `/membership/list/${currentPage}/${pageSize}/created_at/DESC/${
        searchText || null
      }/${localStorage.getItem("outlet_id")}`;
      //  const url =`/membership/list/${currentPage}/${pageSize}/membership_code/DESC/${searchText || null}/null`
      const response = await get(url);
      if (response?.data?.success) {
        const apiData = response.data.data.map((item: any) => ({
          key: item.id,
          memcode: item.membership_code,
          memname: item.membership_name,
          memtype: item.membership_type,
          price:
            item.membership_type == "Prepaid"
              ? item.selling_price
              : item.membership_type == "Discount"
              ? item.selling_price
              : item.totalprice,
          sessions:
            item.membership_type == "Prepaid"
              ? item.value
              : item.membership_type == "Discount"
              ? item.value
              : item.buyprice + item.giveaway + " Sessions",
          discount:
            item.membership_type == "Prepaid"
              ? `${item.product_discount}% / ${item.service_discount}% / ${item.privilege_discount}%`
              : item.membership_type == "Discount"
              ? `${item.dis_product_discount}% / ${item.dis_service_discount}% / ${item.dis_privilege_discount}%`
              : "- / - / -",
          validfor:
            item.typeof_validity == "Custom"
              ? `${item.valid_startdate} ~ ${item.valid_enddate}`
              : `${item.valid_count} ${item.valid_countperiod}`,
          status: item.status,
        }));
        console.log(apiData);

        setDataSource(apiData);
        setOriginalDataSource(apiData);
        setTotalRecords(response.data.totalRecords);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize, dataAdded]);

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const handleSearch = () => {
    fetchData();
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };

  useEffect(() => {
    if (valueChangeSearch) {
      fetchData();
    }
  }, [valueChangeSearch]);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await setSearchText(e.target.value);
    await setValueChangeSearch(e.target.value.length === 0);
  };

  const handleSearchClear = async () => {
    await setSearchText("");
    setValueChangeSearch(true);
  };

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Content className="mainContainer">
        {/* Header Section */}
        <Row justify="space-between" align="middle">
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div onClick={() => navigate(-1)} style={{ cursor: "pointer" }}>
                <ArrowLeftOutlined
                  style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
                />
              </div>
              <h2 style={{ margin: 0 }}>Memberships</h2>
            </div>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                icon={<DownloadOutlined />}
                className="export-button"
                onClick={() => {}}
              >
                Import
              </Button>
              <Button
                icon={<UploadOutlined />}
                className="export-button"
                onClick={() => {}}
              >
                Export
              </Button>
              <Button
                className="quick-settings"
                style={{
                  color: "rgb(46, 86, 242)",
                  borderColor: "rgb(46, 86, 242)",
                }}
              >
                Quick Settings
              </Button>
              {/* <Button className="quick-settings">Quick Settings</Button> */}
              <Button
                className="add-button"
                type="primary"
                icon={<PlusOutlined />}
                onClick={showModal}
              >
                Add
              </Button>
            </div>
          </Col>
        </Row>

        {/* Content Section */}
        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#f6f7f9",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
          }}
        >
          {/* Filters */}
          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col flex="auto">
              <Input
                placeholder="Search by memberships name / code"
                value={searchText}
                onChange={handleInputChange}
                onClear={handleSearchClear}
                allowClear
                onKeyPress={handleKeyPress}
                prefix={
                  <SearchOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                      marginRight: "2px",
                      alignItems: "center",
                    }}
                  />
                }
                style={{ width: "400px", height: "42px", alignItems: "center" }}
              />
            </Col>
            <Col>
              <Button type="primary" onClick={handleSearch}>
                Search
              </Button>
            </Col>
          </Row>

          {/* Table */}
          <Datatable<MembershipData>
            columns={columns}
            dataSource={dataSource}
            scroll={{ y: "calc(100vh - 280px)" }}
            showCheckbox={true}
            rowSelection={rowSelection}
            showPagination={false}
            dynamicTableHeight="calc(100vh - 240px)"
          />

          {/* Action Buttons */}
          <Row
            justify="space-between"
            style={{
              padding: "10px 20px",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            {/* Left Buttons */}
            <Col>
              <Button
                className="deleteall-btn"
                disabled={selectedRowKeys.length === 0}
                type="default"
                style={{ marginRight: "10px" }}
                onClick={handleDeleteAllSelected}
              >
                Delete all selected
              </Button>
              {localStorage.getItem("userRole") == "Merchant" && (
                <Button
                  className="duplicate-btn"
                  type="primary"
                  // onClick={handleDeleteAllSelected}
                >
                  Duplicate & Distribute
                </Button>
              )}
            </Col>

            {/* Right Section */}
            {totalRecords > 0 && (
              <Col>
                <Row justify="end" align="middle" style={{ gap: "16px" }}>
                  {/* Total Items Count */}
                  <Col>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(0,0,0,.85)",
                      }}
                    >
                      Total {totalRecords} items
                    </span>
                  </Col>

                  {/* Pagination */}
                  <Col>
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={totalRecords}
                      onChange={handlePageChange}
                      showSizeChanger={false}
                      itemRender={(page, type, originalElement) => {
                        if (type === "prev") {
                          const isDisabled = currentPage === 1;
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <LeftOutlined />
                              <span>Previous</span>
                            </a>
                          );
                        }
                        if (type === "next") {
                          const isDisabled =
                            currentPage === Math.ceil(totalRecords / pageSize);
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <span>Next</span>
                              <RightOutlined />
                            </a>
                          );
                        }
                        return originalElement;
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>

          {/* Modals can go here */}
        </Content>
        <MembershipModel
          visible={isModalVisible}
          onCancel={handleCancel}
          onSelect={handleMembershipAdd}
        />

        {/* Delete Confirmation Modal */}
        <ConfirmDeleteModal
          visible={deleteModalVisible}
          onCancel={handleCancelDelete}
          onConfirm={handleConfirmDelete}
          message="Delete the membership?"
        />

        {/* Delete Multiple Confirmation Modal */}
        <ConfirmDeleteModal
          visible={isMultipleDeleteModalVisible}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          message="Delete the membership?"
        />
      </Content>
    </>
  );
}

export default MembershipList;
