import {
  ArrowLeftOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
  UndoOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Pagination,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Datatable from "../../../components/Datatable";
import ColumnsReportsModal from "../../../components/model/ColumnsReportsModal";
import { useCurrency } from "../../../contexts/CurrencyContext";
import { get } from "../../../services/Apiclient";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);

interface DataSourceItem {
  key: string;
  staffcode: string;
  staff_name: string;
  tips_amount: number;
}

const TipsSummary: React.FC = () => {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const outletId = localStorage.getItem("outlet_id");
  const currentWeekStart = dayjs().startOf("isoWeek");
  const currentWeekEnd = dayjs().endOf("isoWeek");
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    currentWeekStart,
    currentWeekEnd,
  ]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [dataSource, setDataSource] = useState<DataSourceItem[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [paymentMethod, setPaymentMethod] = useState<string | null>(null);
  const [outlet, setOutlet] = useState<any[]>([]);
  const [staff, setStaff] = useState<any[]>([]);
  const [departments, setDepartments] = useState<any[]>([]);
  const [designations, setDesignations] = useState<any[]>([]);
  const [selectedOutlet, setSelectedOutlet] = useState<string | null>(outletId);
  const [selectedStaff, setSelectedStaff] = useState<string | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<string | null>(
    null
  );
  const [selectedDesignation, setSelectedDesignation] = useState<string | null>(
    null
  );
  const [isModalVisible, setIsModalVisible] = useState(false);

  const initialColumns = [
    { title: "Staff code", dataIndex: "staffcode", key: "staffcode" },
    { title: "Staff name", dataIndex: "staff_name", key: "staff_name" },
    { title: "Tips collected", dataIndex: "tips_amount", key: "tips_amount" },
  ];

  // Define mandatory columns that cannot be deselected
  const mandatoryColumns = ["staff_name"];

  // State for columns order
  const [columns, setColumns] = useState(initialColumns);

  // Initialize selectedColumns with all column keys by default
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key)
  );

  // Track the last saved column selection
  const [lastSelectedColumns, setLastSelectedColumns] = useState<string[]>([]);

  // Filter columns based on selectedColumns and maintain order
  const displayedColumns = columns.filter((col) =>
    selectedColumns.includes(col.key)
  );

  const getOutletData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/outlet/dropdown`);
      if (response?.data?.success && response.data.data) {
        setOutlet(response.data.data);
        const outletExists = response.data.data.some(
          (item: any) => item.id === outletId
        );
        if (outletExists) {
          setSelectedOutlet(outletId);
        } else {
          setSelectedOutlet(response.data.data[0]?.id || null);
        }
      }
    } catch (error) {
      console.error("Error fetching outlet:", error);
    }
  };

  const getStaffData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/staff/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setStaff(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching staff:", error);
    }
  };

  const getDepartmentData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/department/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setDepartments(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching department:", error);
    }
  };

  const getDesignationData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/designation/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setDesignations(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching designation:", error);
    }
  };

  const getTipSummaryData = async () => {
    try {
      const startDate = dateRange[0].format("DD-MM-YYYY");
      const endDate = dateRange[1].format("DD-MM-YYYY");
      const offset = currentPage;

      const response = await get(
        `/report/tipsummary/${startDate}/${endDate}/${
          selectedOutlet || "NULL"
        }/${selectedDesignation || "NULL"}/${selectedDepartment || "NULL"}/${
          paymentMethod || "NULL"
        }/${pageSize}/${offset}/${selectedStaff || "NULL"}`
      );
      console.log("API response:", response);

      if (response?.data?.success && response.data.data) {
        const formattedData = response.data.data.map(
          (item: any, index: number) => ({
            key: index.toString(),
            staffcode: item.staffcode || "",
            staff_name: item.staff_name || "",
            tips_amount: item.tips_amount || 0,
          })
        );
        setDataSource(formattedData);
        setTotalAmount(response.data.total_tips || 0);
        setTotalRecords(response.data.totalRecords || 0);
      } else {
        setDataSource([]);
        setTotalAmount(0);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching tips summary:", error);
      setDataSource([]);
      setTotalAmount(0);
      setTotalRecords(0);
    }
  };

  useEffect(() => {
    getOutletData();
    getStaffData();
    getDepartmentData();
    getDesignationData();
  }, []);

  useEffect(() => {
    if (selectedOutlet !== null) {
      getTipSummaryData();
    }
  }, [selectedOutlet, currentPage, pageSize]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys: React.Key[]) => setSelectedRowKeys(selectedKeys),
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1);
  };

  const handlePageSizeChange = (value: number) => {
    setPageSize(value);
    setCurrentPage(0);
  };

  const handleRunFilter = () => {
    console.log("Filters applied:", {
      selectedOutlet,
      dateRange,
      paymentMethod,
      selectedDepartment,
      selectedDesignation,
      selectedStaff,
      pageSize,
      offset: currentPage,
    });
    setCurrentPage(0);
    getTipSummaryData();
  };

  const handleReload = () => {
    setDateRange([currentWeekStart, currentWeekEnd]);
    setSelectedStaff(null);
    setSelectedDepartment(null);
    setSelectedDesignation(null);
    setPaymentMethod(null);
    setSelectedOutlet(outletId);
    setCurrentPage(0);
    setPageSize(10);
    getTipSummaryData();
  };

  const [isFolded, setIsFolded] = useState(false);
  const handleFold = () => {
    setIsFolded(!isFolded);
  };

  const handleColumnsClick = () => {
    setIsModalVisible(true);
  };

  const handleModalSave = (
    selectedCols: string[],
    reorderedColumns: { key: string; title: string }[]
  ) => {
    setSelectedColumns(selectedCols);
    setLastSelectedColumns(selectedCols);
    setColumns(
      reorderedColumns.map((col) => ({
        ...col,
        dataIndex: col.key, // Assuming `dataIndex` can be derived from `key`
      }))
    );
  };

  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      background: "linear-gradient(90deg, #6a11cb, #2575fc)",
      color: "#fff",
      borderRadius: "10px",
      marginBottom: "16px",
      width: "595px",
      height: "60px",
      display: "flex",
      alignItems: "center",
      padding: "0 50px",
      position: "relative",
      overflow: "hidden",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      zIndex: 2,
      textAlign: "center",
    },
    label: {
      fontSize: "12px",
      fontWeight: "500",
      opacity: 0.9,
      color: "#caa2ff",
    },
    amount: {
      margin: 0,
      fontSize: "16px",
      color: "#fff",
      // animation: "fadeIn 1s ease-in-out infinite alternate",
    },
    transparentText: {
      position: "absolute",
      right: "20px",
      fontSize: "40px",
      fontWeight: "lighter",
      color: "rgba(255, 255, 255, 0.2)",
      textTransform: "uppercase",
      letterSpacing: "2px",
      zIndex: 1,
    },
  };

  return (
    <div style={{ padding: "20px" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowLeftOutlined
              style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
            />
            <Title level={4} style={{ margin: 0 }}>
              Tips Summary
            </Title>
          </div>
        </Col>
        <Col>
          <Space>
            {/* <Button style={{ height: 40 }} icon={<ExportOutlined />}>Export as</Button> */}
            <Button
              icon={<UnorderedListOutlined />}
              onClick={handleColumnsClick}
              style={{ height: 40 }}
            >
              Columns
            </Button>
          </Space>
        </Col>
      </Row>

      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#f6f7f9",
          borderRadius: "12px",
          padding: "24px 24px 10px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
        }}
      >
        <div style={{ marginBottom: "16px", borderRadius: "8px" }}>
          <Row gutter={16} justify="start" style={{ marginBottom: "16px" }}>
            <Col>
              <Select
                value={selectedOutlet === null ? "all" : selectedOutlet}
                onChange={(value) =>
                  setSelectedOutlet(value === "all" ? null : value)
                }
                placeholder="Select outlet"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                <Option value="all">All outlets</Option>
                {outlet.map((outlet) => (
                  <Option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                value={selectedStaff === null ? "all" : selectedStaff}
                onChange={(value) =>
                  setSelectedStaff(value === "all" ? null : value)
                }
                placeholder="Select staff"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                <Option value="all">All staff</Option>
                {staff.map((staff) => (
                  <Option key={staff.id} value={staff.id}>
                    {staff.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                value={selectedDepartment === null ? "all" : selectedDepartment}
                onChange={(value) =>
                  setSelectedDepartment(value === "all" ? null : value)
                }
                placeholder="Select department"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                <Option value="all">All departments</Option>
                {departments.map((dept) => (
                  <Option key={dept.id} value={dept.id}>
                    {dept.departmenttype}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                value={
                  selectedDesignation === null ? "all" : selectedDesignation
                }
                onChange={(value) =>
                  setSelectedDesignation(value === "all" ? null : value)
                }
                placeholder="Select designation"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                <Option value="all">All designations</Option>
                {designations.map((designation) => (
                  <Option key={designation.id} value={designation.id}>
                    {designation.designationtype}
                  </Option>
                ))}
              </Select>
            </Col>
            {isFolded && (
              <Col style={{ marginLeft: "auto" }}>
                <Space>
                  <Button
                    icon={<UndoOutlined />}
                    style={{
                      border: "none",
                      background: "#f6f7f9",
                      height: "46px",
                    }}
                    onClick={handleReload}
                  />
                  <Button
                    style={{ height: "46px", width: "80px" }}
                    type="primary"
                    onClick={handleRunFilter}
                  >
                    Run
                  </Button>
                  <Button
                    style={{
                      border: "none",
                      background: "#f6f7f9",
                      color: "#717ef1",
                      fontWeight: 600,
                      height: "46px",
                      width: "80px",
                    }}
                    onClick={handleFold}
                  >
                    {isFolded ? "Expand" : "Fold"}
                    <DoubleRightOutlined
                      style={{
                        transform: isFolded
                          ? "rotate(85deg)"
                          : "rotate(270deg)",
                        transition: "transform 0.3s",
                      }}
                    />
                  </Button>
                </Space>
              </Col>
            )}
          </Row>
          {!isFolded && (
            <Row gutter={16} justify="start" align="middle">
              <Col style={{ display: "flex", justifyContent: "center" }}>
                <RangePicker
                  value={dateRange}
                  onChange={(dates) =>
                    dates && setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])
                  }
                  format="DD/MM/YYYY"
                  style={{
                    width: "416px",
                    height: "46px",
                    borderRadius: "4px",
                    textAlignLast: "center",
                  }}
                  allowClear={false}
                  ranges={{
                    Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                    Yesterday: [
                      dayjs().subtract(1, "day").startOf("day"),
                      dayjs().subtract(1, "day").endOf("day"),
                    ],
                    "This Week": [
                      dayjs().startOf("week"),
                      dayjs().endOf("week"),
                    ],
                    "This Month": [
                      dayjs().startOf("month"),
                      dayjs().endOf("month"),
                    ],
                    "Last Month": [
                      dayjs().subtract(1, "month").startOf("month"),
                      dayjs().subtract(1, "month").endOf("month"),
                    ],
                  }}
                />
              </Col>

              <Col>
                <Select
                  value={paymentMethod === null ? "all" : paymentMethod}
                  onChange={(value) =>
                    setPaymentMethod(value === "all" ? null : value)
                  }
                  placeholder="Select payment method"
                  style={{
                    width: "200px",
                    height: "46px",
                    borderRadius: "4px",
                  }}
                  allowClear
                >
                  <Option value="all">All payment methods</Option>
                  <Option value="Cash">Cash</Option>
                  <Option value="Package">Package</Option>
                  <Option value="Billing on account">Billing on account</Option>
                  <Option value="Discount">Discount</Option>
                  <Option value="Giftcard">Giftcard</Option>
                  <Option value="kiv">kiv</Option>
                  <Option value="Card">Card</Option>
                  <Option value="Course">Course</Option>
                  <Option value="Prepaid">Prepaid</Option>
                  <Option value="Visit-Based">Visit-Based</Option>
                </Select>
              </Col>
              <Col style={{ marginLeft: "auto" }}>
                <Space>
                  <Button
                    icon={<UndoOutlined />}
                    style={{
                      border: "none",
                      background: "#f6f7f9",
                      height: "46px",
                    }}
                    onClick={handleReload}
                  />
                  <Button
                    style={{ height: "46px", width: "80px" }}
                    type="primary"
                    onClick={handleRunFilter}
                  >
                    Run
                  </Button>
                  <Button
                    style={{
                      border: "none",
                      background: "#f6f7f9",
                      color: "#717ef1",
                      fontWeight: 600,
                      height: "46px",
                      width: "80px",
                    }}
                    onClick={handleFold}
                  >
                    {isFolded ? "Expand" : "Fold"}
                    <DoubleRightOutlined
                      style={{
                        transform: isFolded
                          ? "rotate(85deg)"
                          : "rotate(270deg)",
                        transition: "transform 0.3s",
                      }}
                    />
                  </Button>
                </Space>
              </Col>
            </Row>
          )}
        </div>

        <div style={styles.container}>
          <div style={styles.textContainer}>
            <Text style={styles.label}>Total</Text>
            <Text style={styles.amount}>
              {currencySymbol.CURRENCY_SYMBOL}
              {totalAmount.toFixed(2)}
            </Text>
          </div>
          <Text style={styles.transparentText}>TOTAL</Text>
        </div>

        <Datatable
          columns={displayedColumns}
          dataSource={dataSource}
          scroll={{
            y: isFolded ? "calc(100vh - 400px)" : "calc(100vh - 470px)",
            x: "max-content",
          }}
          showCheckbox={false}
          rowSelection={rowSelection}
          showPagination={false}
          dynamicTableHeight={
            isFolded ? "calc(100vh - 385px)" : "calc(100vh - 450px)"
          }
        />

        <Row
          justify="space-between"
          style={{
            alignItems: "center",
            marginTop: "40px",
            marginBottom: "16px",
          }}
        >
          <Col>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              style={{ width: 200, height: "46px", borderRadius: "4px" }}
            >
              <Option value={10}>10 entries/page</Option>
              <Option value={20}>20 entries/page</Option>
              <Option value={50}>50 entries/page</Option>
              <Option value={100}>100 entries/page</Option>
            </Select>
          </Col>

          {totalRecords >= 0 && (
            <Col>
              <Row justify="end" align="middle" style={{ gap: "16px" }}>
                <Col>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "rgba(0,0,0,.85)",
                    }}
                  >
                    Total {totalRecords} items
                  </span>
                </Col>
                <Col>
                  <Pagination
                    current={currentPage + 1}
                    pageSize={pageSize}
                    total={totalRecords}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    itemRender={(page, type, originalElement) => {
                      if (type === "prev") {
                        const isDisabled = currentPage === 0;
                        return (
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                              color: isDisabled ? "#bbb" : "#2e56f2",
                              pointerEvents: isDisabled ? "none" : "auto",
                            }}
                          >
                            <LeftOutlined />
                            <span>Previous</span>
                          </a>
                        );
                      }
                      if (type === "next") {
                        const isDisabled =
                          currentPage ===
                          Math.ceil(totalRecords / pageSize) - 1;
                        return (
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                              color: isDisabled ? "#bbb" : "#2e56f2",
                              pointerEvents: isDisabled ? "none" : "auto",
                            }}
                          >
                            <span>Next</span>
                            <RightOutlined />
                          </a>
                        );
                      }
                      return originalElement;
                    }}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Content>

      <ColumnsReportsModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onSave={handleModalSave}
        availableColumns={columns}
        initialSelectedColumns={selectedColumns}
        lastSelectedColumns={lastSelectedColumns}
        mandatoryColumns={mandatoryColumns}
      />
    </div>
  );
};

export default TipsSummary;
