import {
  BellOutlined,
  HomeOutlined,
  ScheduleOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Footer from "./Footer";

const ParentComponentFooter: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { merchantId } = useParams();
  const [activeIndex, setActiveIndex] = React.useState<number>(0);

  // Simulating customer authentication check
  const isCustomerLoggedIn = !!localStorage.getItem("customer_id");

  // Define paths for index 3 (Booking tab)
  const bookingPaths = [
    `/app/appointment/list/${merchantId}`,
    `/app/outlet/list/${merchantId}`,
    `/app/servicestaff/list/${merchantId}`,
    `/app/booking/${merchantId}`,
    `/app/allergies/${merchantId}`,
    `/app/appointmentsuccess/${merchantId}`,
  ];

  // Determine the active index based on the current path
  useEffect(() => {
    let newIndex = 0;

    if (location.pathname === `/app/merchant/details/${merchantId}`) {
      newIndex = 0;
    } else if (
      location.pathname === `/app/news/${merchantId}` ||
      location.pathname.startsWith(`/app/news/details/${merchantId}`)
    ) {
      newIndex = 1;
    } else if (
      [
        `/app/products/${merchantId}`,
        `/app/allproducts/${merchantId}`,
        `/app/allpromotion/${merchantId}`,
        `/app/cart/${merchantId}`,
        `/app/success/${merchantId}`,
      ].includes(location.pathname)
    ) {
      newIndex = 2;
    } else if (
      bookingPaths.includes(location.pathname) ||
      location.pathname.startsWith(`/app/appointment/details`)
    ) {
      newIndex = 3;
    } else if (location.pathname === `/app/profile/${merchantId}`) {
      newIndex = 4;
    }

    setActiveIndex(newIndex);
  }, [location.pathname, merchantId]);

  // Redirect to sign-in if index is 3 and user is not logged in
  useEffect(() => {
    // Avoid redirecting if already on the sign-in page
    if (location.pathname === `/app/signIn/${merchantId}`) {
      return;
    }

    // Redirect if the current path is a booking path (index 3) and user is not logged in
    if (activeIndex === 3 && !isCustomerLoggedIn) {
      navigate(`/app/signIn/${merchantId}`);
    }
  }, [
    activeIndex,
    isCustomerLoggedIn,
    navigate,
    location.pathname,
    merchantId,
  ]);

  const handleNavigation = (path: string, index: number) => {
    if (index === 3 && !isCustomerLoggedIn) {
      navigate(`/app/signIn/${merchantId}`);
    } else {
      navigate(path);
    }
  };

  const footerItems = [
    {
      label: "Home",
      icon: <HomeOutlined />,
      onClick: () => handleNavigation(`/app/merchant/details/${merchantId}`, 0),
    },
    {
      label: "Noticeboard",
      icon: <BellOutlined />,
      onClick: () => handleNavigation(`/app/news/${merchantId}`, 1),
    },
    {
      label: "Products",
      icon: <ShoppingCartOutlined style={{ fontSize: "24px" }} />,
      onClick: () => handleNavigation(`/app/products/${merchantId}`, 2),
    },
    {
      label: "Booking",
      icon: <ScheduleOutlined />,
      onClick: () => handleNavigation(`/app/appointment/list/${merchantId}`, 3),
    },
    {
      label: "Profile",
      icon: <UserOutlined />,
      onClick: () => handleNavigation(`/app/profile/${merchantId}`, 4),
    },
  ];

  return (
    <>
      <Footer
        items={footerItems}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </>
  );
};

export default ParentComponentFooter;
