import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FilterFilled,
  PlusOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Layout,
  Row,
  Select,
  Table,
  message,
} from "antd";
import { Content } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ShiftAddModal from "../../components/model/staff/shiftschedual/ShiftAddModal";
import { get, post } from "../../services/Apiclient";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;

// Custom styles for the table and layout
const customStyles = `
  .ant-table {
    border: 1px solid #e8ecef !important;
  }
  .ant-table-body table{
    border-spacing: 0px !important;
  }
  .ant-table-thead > tr > th {
    background: #fff !important;
    border: 1px solid #e8ecef !important;
    text-align: center !important;
    font-size: 13px !important;
    font-weight: 600 !important;
    text-transform: capitalize !important;
    padding: 8px !important;
  }
  .ant-table-tbody > tr > td {
    border: 1px solid #e8ecef !important;
    padding: 0 !important;
    height: 50px;
  }
  .staff-cell {
    display: flex;
    flex-direction: column;
    padding: 8px;
    font-size: 14px;
    font-weight: 500;
    color: #000;
  }
  .staff-details {
    font-size: 12px;
    color: #555;
    white-space: nowrap;
  }
  .shift-cell {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #24bee2;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    margin: 2px;
    box-sizing: border-box;
  }
  .shift-list-item {
    padding: 12px 16px;
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    margin: 16px;
    background: #24bee2;
    border-radius: 4px;
  }
  .shift-list-item-selected {
    background: #2e56f2;
  }
`;

const ShiftSchedualing: React.FC = () => {
  const navigate = useNavigate();
  const [calendarView, setCalendarView] = useState("week");
  const [title, setTitle] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [shifts, setShifts] = useState<any[]>([]);
  const [assignedShifts, setAssignedShifts] = useState<any[]>([]);
  const [tempAssignedShifts, setTempAssignedShifts] = useState<any[]>([]);
  const [currentShift, setCurrentShift] = useState<any>(null);
  const [shiftAssignments, setShiftAssignments] = useState<
    Map<string, { staffId: string; date: string }[]>
  >(new Map());
  const [currentWeekStart, setCurrentWeekStart] = useState(
    dayjs().startOf("week")
  );
  const [currentMonthStart, setCurrentMonthStart] = useState(
    dayjs().startOf("month")
  );
  const outletId = localStorage.getItem("outlet_id");
  const [servicesData, setServicesData] = useState<any[]>([]);
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [staffData, setStaffData] = useState<any[]>([]);
  const [operatingDays, setOperatingDays] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch services, staff data, operating days, and shifts on component mount
  useEffect(() => {
    getServicesData();
    getStaffData();
    getOperatingDays();
  }, []);

  // Fetch shifts whenever the selected service changes
  useEffect(() => {
    if (selectedService) {
      getShiftsData();
    }
  }, [selectedService]);

  // Fetch assigned shifts whenever the calendar view or selected period changes
  useEffect(() => {
    if (selectedService && outletId) {
      setIsLoading(true);
      fetchAssignedShifts().finally(() => setIsLoading(false));
    }
  }, [selectedService, calendarView, currentWeekStart, currentMonthStart]);

  // Fetch operating days for the outlet
  const getOperatingDays = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/appointment/dayinfo/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setOperatingDays(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching operating days:", error);
    }
  };

  // Update the title based on the current view
  const updateTitle = () => {
    if (calendarView === "week") {
      const start = dayjs(currentWeekStart);
      const end = start.add(6, "day");
      setTitle(`${start.format("MMM DD")} - ${end.format("MMM DD")}`);
    } else {
      setTitle(currentMonthStart.format("MMMM YYYY"));
    }
  };

  useEffect(() => {
    updateTitle();
  }, [currentWeekStart, currentMonthStart, calendarView]);

  const fetchAssignedShifts = async () => {
    if (!selectedService || !outletId) return;

    let startdate, enddate;
    if (calendarView === "week") {
      startdate = dayjs(currentWeekStart);
      enddate = startdate.add(6, "day");
    } else {
      startdate = dayjs(currentMonthStart).startOf("month");
      enddate = dayjs(currentMonthStart).endOf("month");
    }

    const startdateFormatted = startdate.format("DD-MM-YYYY");
    const enddateFormatted = enddate.format("DD-MM-YYYY");

    try {
      const response = await get(
        `/shift/${outletId}/${selectedService}/${startdateFormatted}/${enddateFormatted}`
      );
      if (response?.data?.success && response.data.data) {
        const fetchedShifts = response.data.data.map((shift: any) => {
          const selectedDate = dayjs(shift.date, "DD-MM-YYYY");
          const [startHour, startMinute] = shift.shiftstarttime.split(":");
          const [endHour, endMinute] = shift.shiftendtime.split(":");

          return {
            id: shift.id,
            resourceId: shift.staffid,
            start: selectedDate
              .set("hour", parseInt(startHour))
              .set("minute", parseInt(startMinute))
              .toISOString(),
            end: selectedDate
              .set("hour", parseInt(endHour))
              .set("minute", parseInt(endMinute))
              .toISOString(),
            shiftstarttime: shift.shiftstarttime,
            shiftendtime: shift.shiftendtime,
            date: shift.date,
          };
        });

        setAssignedShifts(fetchedShifts);
      } else {
        setAssignedShifts([]);
        message.error("Failed to fetch assigned shifts.");
      }
    } catch (error) {
      console.error("Error fetching assigned shifts:", error);
      setAssignedShifts([]);
      message.error("Error fetching assigned shifts. Please try again.");
    }
  };

  const handleAddShift = (newShift: any) => {
    setShifts([...shifts, newShift]);
    fetchAssignedShifts();
  };

  const handleSave = async () => {
    if (shiftAssignments.size > 0) {
      const newShifts: any[] = [];
      shiftAssignments.forEach((assignments, shiftId) => {
        const shift = shifts.find((s) => s.id === shiftId);
        if (shift) {
          assignments.forEach(({ staffId, date }) => {
            const selectedDate = dayjs(date);
            newShifts.push({
              ...shift,
              resourceId: staffId,
              start: selectedDate
                .set("hour", dayjs(shift.start).hour())
                .set("minute", dayjs(shift.start).minute())
                .toISOString(),
              end: selectedDate
                .set("hour", dayjs(shift.end).hour())
                .set("minute", dayjs(shift.end).minute())
                .toISOString(),
              id: `${shiftId}-${staffId}-${date}`,
            });
          });
        }
      });

      const payload = {
        serviceid: selectedService,
        outlet_id: outletId,
        Shifts: Array.from(shiftAssignments.entries()).map(
          ([shiftId, assignments]) => ({
            shiftid: shiftId,
            staffs: assignments.map(({ staffId, date }) => ({
              staffid: staffId,
              date: dayjs(date).format("DD-MM-YYYY"),
            })),
          })
        ),
      };

      console.log("Saving shifts payload:", payload);

      try {
        const response = await post(`/shift/assignshift`, payload);
        if (response?.data?.success) {
          const updatedTempShifts = [...tempAssignedShifts, ...newShifts];
          setTempAssignedShifts(updatedTempShifts);

          const updatedShifts = [...assignedShifts, ...updatedTempShifts];
          setAssignedShifts(updatedShifts);

          setTempAssignedShifts([]);
          setCurrentShift(null);
          setShiftAssignments(new Map());
          setIsEditMode(false);
          message.success("Shifts assigned successfully!");
          fetchAssignedShifts();
        } else {
          message.error("Failed to assign shifts. Please try again.");
        }
      } catch (error) {
        console.error("Error assigning shifts:", error);
        message.error("Error assigning shifts. Please try again.");
      }
    } else {
      setIsEditMode(false);
    }
  };

  const handleCancel = () => {
    setTempAssignedShifts([]);
    setCurrentShift(null);
    setShiftAssignments(new Map());
    setIsEditMode(false);
  };

  const handleShiftSelect = (shift: any) => {
    if (isEditMode) {
      setCurrentShift(shift);
    }
  };

  const handleDateClick = (staffId: string, date: string) => {
    if (isEditMode && currentShift) {
      const selectedDate = dayjs(date);
      const shiftExists = allShiftsForDisplay.some(
        (shift) =>
          shift.resourceId === staffId &&
          dayjs(shift.start).format("YYYY-MM-DD") === date
      );

      if (!shiftExists) {
        const cellKey = `${staffId}-${date}`;
        const existingAssignments = shiftAssignments.get(currentShift.id) || [];
        const cellIndex = existingAssignments.findIndex(
          (cell) => `${cell.staffId}-${cell.date}` === cellKey
        );

        if (cellIndex === -1) {
          const updatedAssignments = [
            ...existingAssignments,
            { staffId, date },
          ];
          setShiftAssignments(
            new Map(shiftAssignments.set(currentShift.id, updatedAssignments))
          );
        } else {
          const updatedAssignments = [...existingAssignments];
          updatedAssignments.splice(cellIndex, 1);
          if (updatedAssignments.length > 0) {
            setShiftAssignments(
              new Map(shiftAssignments.set(currentShift.id, updatedAssignments))
            );
          } else {
            const newShiftAssignments = new Map(shiftAssignments);
            newShiftAssignments.delete(currentShift.id);
            setShiftAssignments(newShiftAssignments);
          }
        }
      }
    }
  };

  const allShiftsForDisplay = [...assignedShifts, ...tempAssignedShifts];

  const calculateTotalHours = (staffId: string) => {
    const staffShifts = allShiftsForDisplay.filter((shift) => {
      if (shift.resourceId !== staffId) return false;

      const shiftDate = dayjs(shift.date, "DD-MM-YYYY");
      let startRange, endRange;
      if (calendarView === "week") {
        startRange = dayjs(currentWeekStart);
        endRange = startRange.add(6, "day");
      } else {
        startRange = dayjs(currentMonthStart).startOf("month");
        endRange = dayjs(currentMonthStart).endOf("month");
      }

      return (
        shiftDate.isSame(startRange, "day") ||
        shiftDate.isSame(endRange, "day") ||
        (shiftDate.isAfter(startRange, "day") &&
          shiftDate.isBefore(endRange, "day"))
      );
    });

    let totalMinutes = 0;
    staffShifts.forEach((shift) => {
      const [startHour, startMinute] = shift.shiftstarttime.split(":");
      const [endHour, endMinute] = shift.shiftendtime.split(":");
      const start = dayjs(shift.date, "DD-MM-YYYY")
        .set("hour", parseInt(startHour))
        .set("minute", parseInt(startMinute));
      const end = dayjs(shift.date, "DD-MM-YYYY")
        .set("hour", parseInt(endHour))
        .set("minute", parseInt(endMinute));
      const durationMinutes = end.diff(start, "minute");
      totalMinutes += durationMinutes;
    });

    const totalHours = Math.floor(totalMinutes / 60);
    return totalHours;
  };

  const generateColumns = () => {
    const columns = [];
    let daysToShow = 7;
    let startDate = currentWeekStart;

    if (calendarView === "month") {
      startDate = currentMonthStart.startOf("month");
      daysToShow = currentMonthStart.daysInMonth();
    } else {
      startDate = currentWeekStart.startOf("week");
      daysToShow = 7;
    }

    for (let i = 0; i < daysToShow; i++) {
      const day = startDate.add(i, "day");
      const date = day.format("YYYY-MM-DD");
      const isPastDate = day.isBefore(dayjs(), "day");
      const dayOfWeek = day.day();
      const operatingDay = operatingDays.find((d) => d.dayofweek === dayOfWeek);
      const isClosed = operatingDay && operatingDay.starttime === "closed";

      columns.push({
        title: (
          <div style={{ textAlign: "center" }}>
            <div>{day.format("dddd")}</div>
            <div>{day.format("DD")}</div>
          </div>
        ),
        dataIndex: date,
        key: date,
        width: 120,
        render: (text: any, record: any) => {
          const staffId = record.id;
          const shift = allShiftsForDisplay.find(
            (s) =>
              s.resourceId === staffId &&
              dayjs(s.start).format("YYYY-MM-DD") === date
          );

          let assignedShift: any = null;
          let isSelected = false;
          shiftAssignments.forEach((assignments, shiftId) => {
            const cell = assignments.find(
              (cell: any) => cell.staffId === staffId && cell.date === date
            );
            if (cell) {
              isSelected = true;
              assignedShift = shifts.find((s) => s.id === shiftId);
            }
          });

          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundColor:
                  isPastDate || isClosed ? "#f0f5f9" : "transparent",
                cursor: isPastDate || isClosed ? "not-allowed" : "pointer",
              }}
              onClick={() =>
                !isPastDate && !isClosed && handleDateClick(staffId, date)
              }
            >
              {shift ? (
                <div
                  className="shift-cell"
                  style={{
                    backgroundColor: isPastDate ? "transparent" : undefined, // Remove background color for past dates
                    color: isPastDate ? "#555" : "#fff", // Adjust text color for readability
                  }}
                >
                  {dayjs(shift.start).format("HH:mm")} -{" "}
                  {dayjs(shift.end).format("HH:mm")}
                </div>
              ) : isSelected && assignedShift ? (
                <div
                  className="shift-cell"
                  style={{
                    backgroundColor: isPastDate ? "transparent" : undefined,
                    color: isPastDate ? "#555" : "#fff",
                  }}
                >
                  {dayjs(assignedShift.start).format("HH:mm")} -{" "}
                  {dayjs(assignedShift.end).format("HH:mm")}
                </div>
              ) : null}
            </div>
          );
        },
      });
    }

    return [
      {
        title: "Staff",
        dataIndex: "staff",
        key: "staff",
        width: 200,
        fixed: "left" as const,
        render: (text: string, record: any) => {
          const totalHours = calculateTotalHours(record.id);
          return (
            <div className="staff-cell">
              <div style={{ justifyContent: "space-between", display: "flex" }}>
                <div>
                  {record.name} ({record.staffcode})
                </div>
                <div style={{ color: "blue" }}>
                  {totalHours > 0 ? `${totalHours}h` : ""}
                </div>
              </div>
              <div className="staff-details">
                {record.designation_name || "N/A"} |{" "}
                {record.title_name || "N/A"} | {record.department_name || "N/A"}
              </div>
            </div>
          );
        },
      },
      ...columns,
    ];
  };

  const tableData = staffData
    .filter((staff) => staff && staff.id && staff.name && staff.staffcode)
    .map((staff) => ({
      key: staff.id,
      id: staff.id,
      name: staff.name,
      staffcode: staff.staffcode,
      designation_name: staff.designation_name,
      title_name: staff.title_name,
      department_name: staff.department_name,
    }));

  const getServicesData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/service/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setServicesData(response.data.data);
        if (response.data.data.length > 0) {
          setSelectedService(response.data.data[0].id);
        }
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getStaffData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/staff/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setStaffData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching staff:", error);
    }
  };

  const getShiftsData = async () => {
    if (!outletId || !selectedService) return;
    try {
      const response = await get(
        `/shift/allshift/${outletId}/${selectedService}`
      );
      if (response?.data?.success && response.data.data) {
        const fetchedShifts = response.data.data.map((shift: any) => ({
          id: shift.id,
          shiftname: shift.shiftname,
          start: dayjs()
            .set("hour", parseInt(shift.starttime.split(":")[0]))
            .set("minute", parseInt(shift.starttime.split(":")[1]))
            .toISOString(),
          end: dayjs()
            .set("hour", parseInt(shift.endtime.split(":")[0]))
            .set("minute", parseInt(shift.endtime.split(":")[1]))
            .toISOString(),
        }));
        setShifts(fetchedShifts);
      }
    } catch (error) {
      console.error("Error fetching shifts:", error);
      setShifts([]);
    }
  };

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Layout
        style={{
          flexDirection: "column",
          height: "100vh",
          background: "#f5f7fa",
        }}
      >
        <style>{customStyles}</style>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 20px",
          }}
        >
          <Button
            type="link"
            icon={
              <ArrowLeftOutlined
                style={{ fontSize: "16px", color: "#2e56f2" }}
              />
            }
            onClick={() => navigate("/settings")}
            style={{ color: "#000", fontWeight: "700", fontSize: "18px" }}
          >
            Shift Scheduling
          </Button>
        </div>

        <div
          style={{
            display: "flex",
            padding: "0px 12px 16px 24px",
            height: "calc(100vh - 80px)",
          }}
        >
          <Sider
            width={300}
            style={{
              background: "#fff",
              padding: "0px",
              borderRadius: "8px",
              overflowY: "auto",
              boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
            }}
          >
            <div
              style={{
                overflowY: "auto",
                height: "calc(100vh - 120px)",
                width: "300px",
              }}
              className="custom-scrollbar"
            >
              <Select
                value={selectedService}
                style={{
                  height: "40px",
                  width: "calc(100% - 32px)",
                  margin: "16px",
                  borderRadius: "4px",
                }}
                onChange={(value) => setSelectedService(value)}
              >
                {servicesData.map((service) => (
                  <Select.Option key={service.id} value={service.id}>
                    {service.servicename}
                  </Select.Option>
                ))}
              </Select>
              <Button
                type="primary"
                icon={<PlusOutlined />}
                style={{
                  margin: "16px",
                  borderRadius: "4px",
                  width: "calc(100% - 32px)",
                  height: "40px",
                  color: "blue",
                  border: "1px solid #f0f5f9",
                  background: "transparent",
                }}
                onClick={() => setIsModalVisible(true)}
              >
                Add new shift
              </Button>

              {shifts.map((shift, index) => (
                <div
                  key={shift.id}
                  className={`shift-list-item ${
                    currentShift === shift ? "shift-list-item-selected" : ""
                  }`}
                  onClick={() => handleShiftSelect(shift)}
                >
                  {dayjs(shift.start).format("HH:mm")} -{" "}
                  {dayjs(shift.end).format("HH:mm")} <br />
                  <span style={{ fontSize: "12px", color: "whitesmoke" }}>
                    {dayjs(shift.start).format("HH:mm")} -{" "}
                    {dayjs(shift.end).format("HH:mm")}
                  </span>
                </div>
              ))}
            </div>
          </Sider>

          <Layout style={{ padding: "0px 16px 0px 16px", overflowY: "auto" }}>
            <Content
              style={{
                padding: "24px",
                background: "#fff",
                borderRadius: "8px",
              }}
            >
              <Row
                justify="space-between"
                align="middle"
                style={{ marginBottom: "16px" }}
              >
                <Col style={{ fontWeight: "500", fontSize: "14px" }}>
                  Schedule
                </Col>
                <Col style={{ fontSize: "14px", color: "blue" }}>
                  Repeat current schedule
                </Col>
              </Row>
              <Row
                justify="space-between"
                align="middle"
                style={{ marginBottom: "16px" }}
              >
                <Col>
                  <Row gutter={16} align="middle">
                    <Col>
                      <Button
                        style={{
                          height: "46px",
                          color: "#2e56f2",
                          backgroundColor: "#f0f5ff",
                          border: "none",
                          borderRadius: "4px",
                          fontWeight: "500",
                        }}
                        onClick={() => {
                          if (calendarView === "week") {
                            setCurrentWeekStart(dayjs().startOf("week"));
                          } else {
                            setCurrentMonthStart(dayjs().startOf("month"));
                          }
                          updateTitle();
                        }}
                      >
                        {calendarView === "week" ? "This week" : "This month"}
                      </Button>
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          padding: "0px 10px",
                          height: "46px",
                          border: "1px solid #d9d9d9",
                          borderRadius: "4px",
                        }}
                      >
                        <Button
                          icon={<ArrowLeftOutlined />}
                          style={{
                            width: "30px",
                            border: "none",
                            color: "#2e56f2",
                          }}
                          onClick={() => {
                            if (calendarView === "week") {
                              setCurrentWeekStart(
                                currentWeekStart.subtract(1, "week")
                              );
                            } else {
                              setCurrentMonthStart(
                                currentMonthStart.subtract(1, "month")
                              );
                            }
                            updateTitle();
                          }}
                        />
                        <span
                          style={{
                            fontSize: "16px",
                            color: "#000",
                            fontWeight: "500",
                          }}
                        >
                          {title}
                        </span>
                        <Button
                          icon={<ArrowRightOutlined />}
                          style={{
                            width: "30px",
                            border: "none",
                            color: "#2e56f2",
                          }}
                          onClick={() => {
                            if (calendarView === "week") {
                              setCurrentWeekStart(
                                currentWeekStart.add(1, "week")
                              );
                            } else {
                              setCurrentMonthStart(
                                currentMonthStart.add(1, "month")
                              );
                            }
                            updateTitle();
                          }}
                        />
                      </div>
                    </Col>
                    <Col>
                      <Select
                        defaultValue="week"
                        style={{
                          width: 120,
                          height: "46px",
                          borderRadius: "4px",
                        }}
                        onChange={(value) => {
                          setIsLoading(true);
                          setCalendarView(value);
                          if (value === "week") {
                            setCurrentWeekStart(dayjs().startOf("week"));
                          } else {
                            setCurrentMonthStart(dayjs().startOf("month"));
                          }
                          setTimeout(() => setIsLoading(false), 0);
                        }}
                      >
                        <Select.Option value="week">Week</Select.Option>
                        <Select.Option value="month">Month</Select.Option>
                      </Select>
                    </Col>
                  </Row>
                </Col>
                {!isEditMode && (
                  <Col>
                    <Row gutter={16}>
                      <Col>
                        <Button
                          style={{
                            height: "46px",
                            borderRadius: "4px",
                            border: "1px solid #d9d9d9",
                            fontWeight: "500",
                          }}
                          onClick={() => setIsEditMode(true)}
                        >
                          Edit
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          style={{
                            height: "46px",
                            borderRadius: "4px",
                            border: "1px solid #d9d9d9",
                            fontWeight: "500",
                          }}
                        >
                          Copy
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          style={{
                            height: "46px",
                            borderRadius: "4px",
                            border: "1px solid #d9d9d9",
                          }}
                        >
                          <FilterFilled style={{ color: "#000" }} />
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
                {isEditMode && (
                  <Col>
                    <Row justify="end" gutter={16}>
                      <Col>
                        <Button
                          style={{
                            height: "46px",
                            borderRadius: "4px",
                            border: "1px solid #d9d9d9",
                            fontWeight: "500",
                          }}
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          style={{
                            height: "46px",
                            borderRadius: "4px",
                            background: "#2e56f2",
                            color: "#fff",
                            border: "none",
                            fontWeight: "500",
                          }}
                          onClick={handleSave}
                        >
                          Save
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>

              <Table
                key={`${calendarView}-${currentWeekStart.format(
                  "YYYY-MM-DD"
                )}-${currentMonthStart.format("YYYY-MM-DD")}`}
                columns={generateColumns()}
                dataSource={tableData}
                pagination={false}
                scroll={{
                  x: "max-content",
                  y: 320,
                  scrollToFirstRowOnChange: true,
                }}
                bordered
              />

              <Row align="middle" style={{ marginTop: "16px", gap: "10px" }}>
                <Checkbox />
                Show staff on staffing
              </Row>
            </Content>
          </Layout>
        </div>

        <ShiftAddModal
          visible={isModalVisible}
          onClose={() => setIsModalVisible(false)}
          onSelect={handleAddShift}
          selectedService={selectedService}
          outletId={outletId}
        />
      </Layout>
    </>
  );
};

export default ShiftSchedualing;
