import {
  ArrowLeftOutlined,
  LeftOutlined,
  ReloadOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Button, Checkbox, Col, Input, Pagination, Row, Select } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Datatable from "../../components/Datatable";
import { get } from "../../services/Apiclient";

interface StaffData {
  key: React.Key;
  staffcode: string;
  staffname: string;
  department: string;
  designation: string;
  employeementstatus: string;
  performanceprofile: string;
  commissionprofile: string;
}

const Performance: React.FC = () => {
  const navigate = useNavigate();
  const outletId = localStorage.getItem("outlet_id");
  const [servicesData, setServicesData] = useState<any[]>([]);
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const [dataSource, setDataSource] = useState<any[]>([
    {
      key: "1",
      staffcode: "LA01",
      staffname: "MARIA FELOMENA VILLEGAS",
      department: "Skin",
      designation: "Beautician",
      employeementstatus: "In-service",
      perfoamnceprofile: "Shop manager",
      commissionprofile: "Shop manager",
    },
    {
      key: "2",
      staffcode: "LA02",
      staffname: "Kong Chi Liang",
      department: "Eyelash",
      designation: "Eyelash technician",
      employeementstatus: "In-service",
      perfoamnceprofile: "Shop manager",
      commissionprofile: "Shop manager",
    },
    {
      key: "3",
      staffcode: "LA03",
      staffname: "LIE PHING LEE",
      department: "Nail",
      designation: "Nail technician",
      employeementstatus: "In-service",
      perfoamnceprofile: "Shop manager",
      commissionprofile: "Shop manager",
    },
    {
      key: "4",
      staffcode: "LA04",
      staffname: "JASON JASON",
      department: "Marketing",
      designation: "Marketing",
      employeementstatus: "In-service",
      perfoamnceprofile: "Shop manager",
      commissionprofile: "Shop manager",
    },
  ]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) =>
      setSelectedRowKeys(newSelectedRowKeys),
  };

  const getServicesData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/service/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setServicesData(response.data.data);
        setSelectedService(response.data.data[0]?.id || null);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  useEffect(() => {
    getServicesData();
  }, []);

  const columns = [
    {
      title: "Staff Code",
      dataIndex: "staffcode",
      key: "staffcode",
      width: 150,
    },
    {
      title: "Staff Name",
      dataIndex: "staffname",
      key: "staffname",
      width: 200,
    },
    {
      title: "department",
      dataIndex: "department",
      key: "department",
      width: 150,
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      width: 100,
    },
    {
      title: "Employeement status",
      dataIndex: "employeementstatus",
      key: "employessmentstatus",
      width: 150,
    },
    {
      title: "Commission profile",
      dataIndex: "commissionprofile",
      key: "commissionprofile",
      width: 150,
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right" as "right",
      width: 100,
      render: (text: any, record: StaffData): React.ReactNode => (
        <>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            // onClick={() => navigate(`/settings/staff/info/${record.key}`)}
          >
            Edit
          </a>
        </>
      ),
    },
  ];

  return (
    <div style={{ backgroundColor: "#f0f3f8" }} className="mainContainer">
      <Content>
        {/* Header Section */}
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: 16 }}
        >
          <Col>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowLeftOutlined
                onClick={() => navigate(-1)}
                style={{
                  marginRight: 12,
                  color: "#325DF2",
                  fontSize: 18,
                  cursor: "pointer",
                }}
              />
              <h2 style={{ margin: 0 }}>Performance</h2>
            </div>
          </Col>
          <Col>
            <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
              <Button
                icon={<ReloadOutlined />}
                style={{
                  marginRight: 8,
                  height: "46px",
                }}
              >
                Recalculate
              </Button>
              <Button
                style={{
                  background: "#2e56f2",
                  color: "white",
                  height: "46px",
                }}
                onClick={() => navigate("/settings/performance/plan")}
              >
                Performance plan
              </Button>
              <Button
                style={{
                  background: "#2e56f2",
                  color: "white",
                  height: "46px",
                }}
                onClick={() => navigate("/settings/performance/commissionplan")}
              >
                Commission plan
              </Button>
            </div>
          </Col>
        </Row>

        {/* Filters Section */}
        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#f6f7f9",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
          }}
        >
          <Row gutter={[16, 16]} style={{ marginBottom: 16, display: "flex" }}>
            <Col>
              <Select
                value={selectedService}
                style={{ width: 200, height: 40 }}
                onChange={setSelectedService}
              >
                {servicesData.map((service) => (
                  <Select.Option key={service.id} value={service.id}>
                    {service.servicename}
                  </Select.Option>
                ))}
              </Select>
            </Col>

            <Col span={8}>
              <Input
                placeholder="Search by staff code, name"
                style={{ height: 46 }}
              />
            </Col>
            {/* search button */}
            <Col style={{ marginLeft: "auto" }}>
              <Button
                type="primary"
                style={{
                  height: 46,
                  background: "#2e56f2",
                  border: "none",
                  fontWeight: 500,
                }}
              >
                Search
              </Button>
            </Col>
          </Row>

          {/* Table */}
          <Datatable
            columns={columns}
            dataSource={dataSource}
            scroll={{ y: "calc(100vh - 260px)" }}
            showCheckbox
            rowSelection={rowSelection}
            showPagination={false}
            dynamicTableHeight="calc(100vh - 240px)"
          />

          {/* Pagination */}
          <Row
            style={{
              marginTop: "10px",
              padding: "20px 20px 0px 0px",
              alignItems: "center",
            }}
          >
            <Col style={{ marginRight: "10px" }}>
              <Checkbox>Hide resigned staff</Checkbox>
            </Col>
            <Col>
              <Checkbox>Hide set staff</Checkbox>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <Row align="middle" style={{ gap: 16 }}>
                <span
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    color: "rgba(0,0,0,.85)",
                  }}
                >
                  Total items
                </span>
                <Pagination
                  defaultCurrent={1}
                  // total={50}
                  showSizeChanger={false}
                  itemRender={(page, type) => {
                    if (type === "prev")
                      return (
                        <a
                          style={{
                            display: "flex",
                            gap: 5,
                            fontSize: 12,
                            fontWeight: 700,
                            color: "#2e56f2",
                          }}
                        >
                          <LeftOutlined />
                          Previous
                        </a>
                      );
                    if (type === "next")
                      return (
                        <a
                          style={{
                            display: "flex",
                            gap: 5,
                            fontSize: 12,
                            fontWeight: 700,
                            color: "#2e56f2",
                          }}
                        >
                          Next
                          <RightOutlined />
                        </a>
                      );
                    return page;
                  }}
                  style={{ display: "inline-flex" }}
                />
              </Row>
            </Col>
          </Row>
        </Content>
      </Content>
    </div>
  );
};

export default Performance;
