import React, { useEffect, useState } from "react";
import { Dropdown, Layout, Menu, Space, Avatar, Row, Col } from "antd";
import {
  Outlet,
  Link,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import {
  HomeFilled,
  ShopFilled,
  UserOutlined,
  SettingFilled,
  InboxOutlined,
  ShoppingOutlined,
  BellOutlined,
  ShoppingCartOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MenuOutlined,
  LogoutOutlined,
  ContainerOutlined,
  CalendarOutlined,
  AuditOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { jwtDecode } from "jwt-decode";
import { getPagePermissions, getPermissions } from "./permissionsHelper"; // Import helper

const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_White.png`;
const logosmallIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_White_small.png`;
const { Header, Content, Sider } = Layout;

const menuItems = [
  { key: "/", icon: <HomeFilled />, label: "Home" },
  { key: "/customer", icon: <UserOutlined />, label: "Customers" },
  { key: "/sales", icon: <ShoppingCartOutlined />, label: "Sales" },
  { key: "/inventory", icon: <InboxOutlined />, label: "Inventory" },
  { key: "/booking", icon: <CalendarOutlined />, label: "Booking" },
  {
    key: "/onlinesales",
    icon: <ShoppingOutlined />,
    label: "Online Store",
  },
  { key: "/noticeboard", icon: <BellOutlined />, label: "Noticeboard" },
  { key: "/reports", icon: <MenuOutlined />, label: "Report" },
  { key: "/einvoices", icon: <AuditOutlined />, label: "eInvoice" },
  {
    key: "/cashiereinvoiceslayout1",
    icon: <DatabaseOutlined />,
    label: "My Invoice",
  },
  // { key: "/outlet", icon: <ShopFilled />, label: "Outlet" },
  // { key: "/settings", icon: <SettingFilled />, label: "Settings" },
  { key: "/merchant/list", icon: <UserOutlined />, label: "Merchant" },
];

const MainLayout: React.FC = () => {
  let userRole: any = null;
  let invoicetype: any = null;
  try {
    const token: any = localStorage.getItem("token");
    if (token) {
      const decodedToken: any = jwtDecode(token);
      userRole = decodedToken?.tokendata?.role;
      invoicetype = decodedToken?.tokendata?.invoicetype;
    } else {
      localStorage.setItem("token", "");
      <Navigate to="/login" replace />;
    }
  } catch (error) {
    console.error("Token decoding failed:", error);
    localStorage.setItem("token", "");
  }

  const [collapsed, setCollapsed] = useState(window.innerWidth < 768);
  const location = useLocation();
  const navigate = useNavigate();

  // Get permissions
  const permissions = getPermissions();
  const pagepermissions = getPagePermissions();

  // Check permissions only for Staff
  const hasPermission = (key: string) => {
    if (invoicetype === "No eInvoice") {
      if (key === "/einvoices" || key === "/cashiereinvoiceslayout1") {
        return false; // Hide eInvoice and My Invoice if No eInvoice
      }
    }

    if (userRole === "Staff") {
      switch (key) {
        case "/merchant/list":
          return permissions.merchantList;
        case "/outlet":
          return permissions.outletList;
        case "/customer":
          return permissions.customerview && pagepermissions.customerpage;
        case "/inventory":
          return (
            (permissions.stockinview || permissions.stockinview) &&
            pagepermissions.inventorypage
          );
        case "/noticeboard":
          return permissions.noticeboardview && pagepermissions.noticeboardpage;
        case "/onlinesales":
          return (
            permissions.enableonlineshop && pagepermissions.onlinestorepage
          );
        case "/settings":
          return permissions.staffList;
        case "/einvoices":
          return permissions.outletList && pagepermissions.einvoicepage;
        case "/sales":
          return (
            permissions.billing &&
            pagepermissions.salespage &&
            localStorage.getItem("enableretailshop") == "true"
          );
        case "/booking":
          return permissions.bookingview && pagepermissions.bookingpage;
        case "/onlinesales":
          return (
            permissions.onlinebilling &&
            pagepermissions.onlinestorepage &&
            localStorage.getItem("enableonlineshop") == "true"
          );
        default:
          return true;
      }
    }
    if (key == "/") {
      return pagepermissions.homepage;
    } else if (key == "/customer") {
      return pagepermissions.customerpage;
    } else if (key == "/sales") {
      return pagepermissions.salespage;
    } else if (key == "/inventory") {
      return pagepermissions.inventorypage;
    } else if (key == "/booking") {
      return pagepermissions.bookingpage;
    } else if (key == "/onlinesales") {
      return pagepermissions.onlinestorepage;
    } else if (key == "/noticeboard") {
      return pagepermissions.noticeboardpage;
    } else if (key == "/reports") {
      return pagepermissions.reportpage;
    } else if (key == "/einvoices") {
      return pagepermissions.einvoicepage;
    } else if (key == "/cashiereinvoiceslayout1") {
      return pagepermissions.myinvoicepage;
    }
    return true; // Full access for Superadmin and Merchant
  };

  // Filter menu items
  const filteredMenuItems = menuItems.filter((item) => {
    if (userRole === "Superadmin") {
      return item.key === "/merchant/list"; // Superadmin sees only Merchant
    }

    if (
      (userRole === "Outletadmin" || userRole === "Merchant") &&
      item.key === "/merchant/list"
    ) {
      return false; // Hide Merchant for Outlet Admins
    }

    // if (item.key === "/cashiereinvoiceslayout1") {
    //   return invoicetype === "QRCode eInvoice"; // Show only if QRCode eInvoice
    // }

    return hasPermission(item.key); // Apply permissions for other roles
  });

  // Handle logout
  const handleLogout = () => {
    let merchantid = localStorage.getItem("merchantid");
    let userlogin = localStorage.getItem("outletlogin");
    localStorage.removeItem("customer_id");
    localStorage.removeItem("store_name");
    localStorage.removeItem("token");
    localStorage.removeItem("timeout");
    localStorage.removeItem("enableonlineshop");
    localStorage.removeItem("enableretailshop");
    localStorage.removeItem("einvoiceenable");
    localStorage.removeItem("staffid");
    localStorage.removeItem("permissions");
    localStorage.removeItem("userRole");
    localStorage.removeItem("currencySymbol");
    if (userlogin === "true" && merchantid !== "") {
      navigate(`/login?id=${merchantid}`);
    } else {
      navigate("/login");
    }
  };

  const handleSettings = () => {
    navigate("/settings");
  };

  const handleOutlet = () => {
    navigate("/outlet");
  };

  useEffect(() => {
    const handleResize = () => setCollapsed(window.innerWidth < 768);

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const userMenu = (
    <Menu>
      {(userRole === "Outletadmin" || userRole === "Merchant") && (
        <Menu.Item key="1" onClick={handleOutlet}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <ShopFilled /> Outlet
          </div>
        </Menu.Item>
      )}
      {(userRole === "Outletadmin" || userRole === "Merchant") && (
        <Menu.Item key="2" onClick={handleSettings}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <SettingFilled /> Settings
          </div>
        </Menu.Item>
      )}
      <Menu.Item key="3" onClick={handleLogout}>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <LogoutOutlined /> Logout
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
        style={{
          backgroundColor: "#2e56f2",
          display: "flex",
          flexDirection: "column",
          width: "240px",
          height: "100vh",
          overflow: "hidden",
          transition: "0.3s ease",
        }}
        className="main-sider"
        trigger={
          collapsed ? (
            <MenuUnfoldOutlined />
          ) : (
            <MenuFoldOutlined
              style={{
                display: "flex",
                justifyContent: "end",
                paddingRight: "20px",
                height: "inherit",
                fontWeight: "900",
              }}
            />
          )
        }
      >
        <img
          src={collapsed ? logosmallIcon : logoIcon}
          alt="DACO"
          className="logo"
          style={{
            width: collapsed ? 50 : 200,
            height: 50,
            margin: 16,
            background: "transparent",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transition: "0.3s ease",
          }}
        />
        <Menu
          className="custom-mainlayout-scrollbar"
          theme="dark"
          selectedKeys={[location.pathname]}
          mode="inline"
          style={{
            backgroundColor: "#2e56f2",
            flex: 1,
            height: "calc(100vh - 130px)",
            overflowY: "auto", // <-- this is crucial
            overflowX: "hidden",
            minHeight: 0,
            transition: "0.3s ease",
          }}
        >
          {filteredMenuItems.map((item) => (
            <Menu.Item
              key={item.key}
              icon={React.cloneElement(item.icon, {
                style: {
                  color: "#FFF",
                },
              })}
              style={
                location.pathname == item.key
                  ? { backgroundColor: "#2749ce", color: "#FFF" }
                  : { color: "#FFF" }
              }
            >
              <Link
                to={item.key}
                style={{
                  color: "#FFF",
                }}
              >
                {item.label}
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
      <Layout>
        <Header
          className="main-layout-header"
          style={{
            position: "fixed",
            width:
              window.innerWidth < 768
                ? collapsed
                  ? "calc(100% - 80px)"
                  : "calc(100% - 80px)"
                : collapsed
                ? "calc(100% - 80px)"
                : "calc(100% - 240px)",
            padding: "0 20px",
            backgroundColor: "#fff",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            zIndex: 1,
            transition: "0.3s ease",
          }}
        >
          <Row gutter={[16, 16]} style={{ width: "100%" }}>
            <Col span={12} style={{ display: "flex", alignItems: "center" }}>
              <span
                style={{
                  fontSize: "14px",
                  lineHeight: "32px",
                  fontWeight: "700",
                  color: "#333333",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  display: "block",
                }}
              >
                {localStorage.getItem("store_name")}
              </span>
            </Col>
            <Col
              span={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <div>
                <Dropdown
                  overlay={userMenu}
                  placement="bottomCenter"
                  trigger={["click"]}
                >
                  <Space style={{ cursor: "pointer" }}>
                    <Avatar icon={<UserOutlined />} />
                    <span>{userRole}</span>
                  </Space>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Header>
        <Content
          className="main-layout-content"
          style={{
            marginTop: 64,
            padding: 16,
            overflowY: "auto",
            height: "calc(100vh - 64px)",
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
