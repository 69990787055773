import {
  Button,
  Col,
  Divider,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import React, { useState } from "react";
import Datatable from "../../../Datatable";

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

interface Service {
  key: string;
  code: string;
  name: string;
  paymentMethod: string;
  turnoverDesignated: number;
  turnoverUndesignated: number;
  turnoverCustomType1: number;
  turnoverCustomType2: number;
}

interface Product {
  key: string;
  code: string;
  name: string;
  paymentMethod: string;
  turnover: number;
}

interface Privilege {
  key: string;
  code: string;
  name: string;
  type: string;
  price: string;
  value: string;
  sessions: string;
  discount: string;
  period: string;
  turnover: number; // Added to match CalculationSettings.tsx
}

interface ModalProps {
  isServiceModalVisible: boolean;
  isProductModalVisible: boolean;
  isPrivilegeModalVisible: boolean;
  handleCloseServiceModal: () => void;
  handleCloseProductModal: () => void;
  handleClosePrivilegeModal: () => void;
  setServices: React.Dispatch<React.SetStateAction<Service[]>>;
  setProducts: React.Dispatch<React.SetStateAction<Product[]>>;
  setPrivileges: React.Dispatch<React.SetStateAction<Privilege[]>>;
  services: Service[];
  products: Product[];
  privileges: Privilege[];
}

const ServiceProductModal: React.FC<ModalProps> = ({
  isServiceModalVisible,
  isProductModalVisible,
  isPrivilegeModalVisible,
  handleCloseServiceModal,
  handleCloseProductModal,
  handleClosePrivilegeModal,
  setServices,
  setProducts,
  setPrivileges,
  services,
  products,
  privileges,
}) => {
  const [selectedServiceRows, setSelectedServiceRows] = useState<any[]>([]);
  const [selectedProductRows, setSelectedProductRows] = useState<any[]>([]);
  const [selectedPrivilegeRows, setSelectedPrivilegeRows] = useState<any[]>([]);

  // Modal data for services
  const modalServicesData = [
    {
      key: "1",
      serviceCode: "TD101",
      serviceName: "NEW SET EYELASH EXTENSION",
      price: "RM198",
    },
    {
      key: "2",
      serviceCode: "TD102",
      serviceName: "TOUCH UP EYELASH EXTENSION",
      price: "RM100",
    },
    {
      key: "3",
      serviceCode: "TD103",
      serviceName: "REMOVE EYELASH EXTENSION",
      price: "RM20",
    },
    {
      key: "4",
      serviceCode: "TD104",
      serviceName: "LOWER LASH EXTENSION",
      price: "RM58",
    },
    {
      key: "5",
      serviceCode: "TD106",
      serviceName: "TOUCH UP LASHES 14 DAYS",
      price: "RM130",
    },
  ];

  // Modal data for products
  const modalProductsData = [
    {
      key: "1",
      productCode: "250260001",
      productName: "Cell Grow Sheet Mask",
      category: "Eye Lash",
      price: "RM0",
      unit: "Pcs",
      supplier: "Tabaco",
    },
    {
      key: "2",
      productCode: "BS",
      productName: "Moisturing & watering essence (purple)",
      category: "CYM",
      price: "RM578",
      unit: "Box",
      supplier: "BeautHub",
    },
    {
      key: "3",
      productCode: "CCM",
      productName: "Cell Grow Sheet Mask",
      category: "Skin Care",
      price: "RM238",
      unit: "Box",
      supplier: "BeautHub",
    },
    {
      key: "4",
      productCode: "gerkg",
      productName: "Geranium Moon Body Care Essence",
      category: "Melle",
      price: "RM611",
      unit: "Bottle",
      supplier: "Melle",
    },
  ];

  // Modal data for privileges (updated to include turnover)
  const modalPrivilegesData = [
    {
      key: "1",
      code: "250260001",
      name: "1000",
      type: "Prepaid",
      price: "RM1,000",
      value: "RM1200",
      sessions: "-/-",
      discount: "-/-",
      period: "2 year from purchase",
      turnover: 100, // Default turnover value
    },
    {
      key: "2",
      code: "250260003",
      name: "RM6000",
      type: "Prepaid",
      price: "RM6,000",
      value: "RM6000",
      sessions: "-/-",
      discount: "-/-",
      period: "2 year from purchase",
      turnover: 100, // Default turnover value
    },
    {
      key: "3",
      code: "250260004",
      name: "Charlene",
      type: "Prepaid",
      price: "RM0",
      value: "RM9858.00",
      sessions: "-/-",
      discount: "-/-",
      period: "2 year from purchase",
      turnover: 100, // Default turnover value
    },
  ];

  // Handle saving selected services
  const handleSaveService = () => {
    const newServices = selectedServiceRows.map((row, index) => ({
      key: (services.length + index + 1).toString(),
      code: row.serviceCode,
      name: row.serviceName,
      paymentMethod: "Default",
      turnoverDesignated: 100,
      turnoverUndesignated: 100,
      turnoverCustomType1: 100,
      turnoverCustomType2: 100,
    }));
    setServices([...services, ...newServices]);
    setSelectedServiceRows([]);
    handleCloseServiceModal();
  };

  // Handle saving selected products
  const handleSaveProduct = () => {
    const newProducts = selectedProductRows.map((row, index) => ({
      key: (products.length + index + 1).toString(),
      code: row.productCode,
      name: row.productName,
      paymentMethod: "Default",
      turnover: 10,
    }));
    setProducts([...products, ...newProducts]);
    setSelectedProductRows([]);
    handleCloseProductModal();
  };

  // Handle saving selected privileges (updated to include turnover)
  const handleSavePrivilege = () => {
    const newPrivileges = selectedPrivilegeRows.map((row, index) => ({
      key: (privileges.length + index + 1).toString(),
      code: row.code,
      name: row.name,
      type: row.type,
      price: row.price,
      value: row.value,
      sessions: row.sessions,
      discount: row.discount,
      period: row.period,
      turnover: row.turnover || 100, // Default to 100 if not present
    }));
    setPrivileges([...privileges, ...newPrivileges]);
    setSelectedPrivilegeRows([]);
    handleClosePrivilegeModal();
  };

  // Modal columns for Service selection
  const serviceModalColumns = [
    {
      title: "Service Code",
      dataIndex: "serviceCode",
      key: "serviceCode",
    },
    {
      title: "Service Name",
      dataIndex: "serviceName",
      key: "serviceName",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
  ];

  // Modal columns for Product selection
  const productModalColumns = [
    {
      title: "Product Code",
      dataIndex: "productCode",
      key: "productCode",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
    },
  ];

  // Modal columns for Privilege selection
  const privilegeModalColumns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 100,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 100,
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
      width: 150,
    },
    {
      title: "Sessions",
      dataIndex: "sessions",
      key: "sessions",
      width: 150,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      width: 100,
    },
    {
      title: "Period of Validity",
      dataIndex: "period",
      key: "period",
      width: 200,
    },
  ];

  return (
    <>
      {/* Modal for Adding Service */}
      <Modal
        title="Services"
        centered
        visible={isServiceModalVisible}
        onCancel={handleCloseServiceModal}
        footer={[
          <>
            <Divider />
            <div style={{ display: "flex" }}>
              <Text>Selected {selectedServiceRows.length} items</Text>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  style={{ marginRight: "5px" }}
                  key="cancel"
                  onClick={handleCloseServiceModal}
                >
                  Cancel
                </Button>
                <Button key="save" type="primary" onClick={handleSaveService}>
                  Save
                </Button>
              </div>
            </div>
          </>,
        ]}
        width={900}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col span={8}>
            <Select defaultValue="All categories" style={{ width: "100%" }}>
              <Option value="All categories">All categories</Option>
            </Select>
          </Col>
          <Col span={13}>
            <Input
              placeholder="Search by service name/code"
              style={{ width: "100%", height: "46px" }}
            />
          </Col>
          <Col style={{ marginLeft: "auto" }}>
            <Button
              type="primary"
              style={{
                height: 46,
                background: "#2e56f2",
                border: "none",
                fontWeight: 500,
              }}
            >
              Search
            </Button>
          </Col>
        </Row>
        <Datatable
          columns={serviceModalColumns}
          dataSource={modalServicesData}
          scroll={{ y: 300 }}
          showCheckbox
          rowSelection={{
            onChange: (selectedRowKeys: any, selectedRows: any) => {
              setSelectedServiceRows(selectedRows);
            },
          }}
          showPagination={false}
        />
      </Modal>

      {/* Modal for Adding Product */}
      <Modal
        title="Product"
        centered
        visible={isProductModalVisible}
        onCancel={handleCloseProductModal}
        footer={[
          <>
            <Divider />
            <div style={{ display: "flex" }}>
              <Text>Selected {selectedProductRows.length} items</Text>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  style={{ marginRight: "5px" }}
                  key="cancel"
                  onClick={handleCloseProductModal}
                >
                  Cancel
                </Button>
                <Button key="save" type="primary" onClick={handleSaveProduct}>
                  Save
                </Button>
              </div>
            </div>
          </>,
        ]}
        width={900}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col span={6}>
            <Select defaultValue="All categories" style={{ width: "100%" }}>
              <Option value="All categories">All categories</Option>
            </Select>
          </Col>
          <Col span={6}>
            <Select defaultValue="All suppliers" style={{ width: "100%" }}>
              <Option value="All suppliers">All suppliers</Option>
            </Select>
          </Col>
          <Col span={9}>
            <Input
              placeholder="Search by product name/code"
              style={{ width: "100%", height: "46px" }}
            />
          </Col>
          <Col style={{ marginLeft: "auto" }}>
            <Button
              type="primary"
              style={{
                height: 46,
                background: "#2e56f2",
                border: "none",
                fontWeight: 500,
              }}
            >
              Search
            </Button>
          </Col>
        </Row>
        <Datatable
          columns={productModalColumns}
          dataSource={modalProductsData}
          scroll={{ y: 300 }}
          showCheckbox
          rowSelection={{
            onChange: (selectedRowKeys: any, selectedRows: any) => {
              setSelectedProductRows(selectedRows);
            },
          }}
          showPagination={false}
        />
      </Modal>

      {/* Modal for Adding Privilege */}
      <Modal
        title="Select Privilege"
        centered
        visible={isPrivilegeModalVisible}
        onCancel={handleClosePrivilegeModal}
        footer={[
          <>
            <Divider />
            <div style={{ display: "flex" }}>
              <Text>Selected {selectedPrivilegeRows.length} items</Text>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  style={{ marginRight: "5px" }}
                  key="cancel"
                  onClick={handleClosePrivilegeModal}
                >
                  Cancel
                </Button>
                <Button key="save" type="primary" onClick={handleSavePrivilege}>
                  Save
                </Button>
              </div>
            </div>
          </>,
        ]}
        width={900}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col span={20}>
            <Input
              placeholder="Search by code/name"
              style={{ width: "100%", height: "46px" }}
            />
          </Col>
          <Col style={{ marginLeft: "auto" }}>
            <Button
              type="primary"
              style={{
                height: 46,
                background: "#2e56f2",
                border: "none",
                fontWeight: 500,
              }}
            >
              Search
            </Button>
          </Col>
        </Row>
        <Datatable
          columns={privilegeModalColumns}
          dataSource={modalPrivilegesData}
          scroll={{ y: 300 }}
          showCheckbox
          rowSelection={{
            onChange: (selectedRowKeys: any, selectedRows: any) => {
              setSelectedPrivilegeRows(selectedRows);
            },
          }}
          showPagination
          //   showpagination={{
          //     pageSize: 5,
          //     showSizeChanger: false,
          //   }}
        />
      </Modal>
    </>
  );
};

export default ServiceProductModal;
