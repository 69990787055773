import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Checkbox,
  TimePicker,
  Button,
  Typography,
} from "antd";
import dayjs from "dayjs";

const { Text } = Typography;

interface OpeningHours {
  [key: string]: string | null;
}

interface OpeningHoursSettingsProps {
  visible: boolean;
  onClose: () => void;
  openingHours: OpeningHours;
  onSave: (updatedHours: OpeningHours) => void;
}

const OpeningHoursSettings: React.FC<OpeningHoursSettingsProps> = ({
  visible,
  onClose,
  openingHours,
  onSave,
}) => {
  const [tempHours, setTempHours] = useState<OpeningHours>(openingHours);

  const handleCheckboxChange = (day: string, checked: boolean) => {
    setTempHours({
      ...tempHours,
      [day]: checked ? "09:00-18:00" : "closed-null",
    });
  };

  const handleTimeChange = (day: string, times: [dayjs.Dayjs, dayjs.Dayjs]) => {
    if (times && times[0] && times[1]) {
      setTempHours({
        ...tempHours,
        [day]: `${times[0].format("HH:mm")}-${times[1].format("HH:mm")}`,
      });
    }
  };

  const handleSave = () => {
    onSave(tempHours);
    onClose();
  };

  useEffect(() => {
    setTempHours(openingHours); // Update tempHours when openingHours prop changes
  }, [openingHours]);

  return (
    <Modal
      title="Opening hours"
      visible={visible}
      onCancel={onClose}
      bodyStyle={{
        height: "calc(100vh - 340px)",
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarWidth: "thin",
      }}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
    >
      <Row
        style={{
          borderBottom: "1px solid #f0f0f0",
          paddingBottom: "8px",
          fontWeight: "bold",
          marginTop: "16px",
        }}
      >
        <Col span={6}>Week</Col>
        <Col span={9}>Opening time</Col>
        <Col span={9}>Closing time</Col>
      </Row>

      {Object.keys(tempHours).map((day) => (
        <Row key={day} align="middle" style={{ marginTop: "8px" }}>
          <Col span={6}>
            <Checkbox
              checked={tempHours[day] !== "closed-null"}
              onChange={(e) => handleCheckboxChange(day, e.target.checked)}
            >
              {day.charAt(0).toUpperCase() + day.slice(1)}
            </Checkbox>
          </Col>
          <Col span={9}>
            {tempHours[day] !== "closed-null" ? (
              <TimePicker
                format="HH:mm"
                value={
                  tempHours[day]
                    ? dayjs(tempHours[day]?.split("-")[0], "HH:mm")
                    : null
                }
                onChange={(time) =>
                  handleTimeChange(day, [
                    time!,
                    dayjs(tempHours[day]?.split("-")[1], "HH:mm"),
                  ])
                }
                style={{ width: "100%" }}
              />
            ) : (
              <Text>Closed</Text>
            )}
          </Col>
          <Col span={9}>
            {tempHours[day] !== "closed-null" ? (
              <TimePicker
                format="HH:mm"
                value={
                  tempHours[day]
                    ? dayjs(tempHours[day]?.split("-")[1], "HH:mm")
                    : null
                }
                onChange={(time: any) =>
                  handleTimeChange(day, [
                    dayjs(tempHours[day]?.split("-")[0], "HH:mm"),
                    time!,
                  ])
                }
                style={{ width: "100%" }}
              />
            ) : (
              <Text>-</Text>
            )}
          </Col>
        </Row>
      ))}
    </Modal>
  );
};

export default OpeningHoursSettings;
