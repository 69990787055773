import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  message,
  Pagination,
  Empty,
  Divider,
  Checkbox,
  Menu,
  Dropdown,
} from "antd";
import {
  PlusOutlined,
  ArrowLeftOutlined,
  RightOutlined,
  LeftOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import dayjs, { Dayjs } from "dayjs"; // Import Dayjs for date manipulation
import { ColumnsType } from "antd/es/table";
import { get } from "../../services/Apiclient";
import Datatable from "../../components/Datatable";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const { RangePicker } = DatePicker;
const { Option } = Select;

interface StockTakeRecord {
  key: string;
  ref: string;
  date: string;
  creationTime: string;
  storage: string;
  countedBy: string;
  status: string;
  expected: number;
  counted: number;
  difference: number;
}

export default function StockTakesList() {
  const navigate = useNavigate();

  const [dataSource, setDataSource] = useState<StockTakeRecord[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchText, setSearchText] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [outletData, setOutletData] = useState([]);
  const [originalDataSource, setOriginalDataSource] = useState<any[]>([]);
  const [storageOptions, setStorageOptions] = useState([]); // Dynamic storage options
  const [storageDropdownVisible, setStorageDropdownVisible] = useState(false);
  const [selectedStorage, setSelectedStorage] = useState<string[]>([]); // Selected storage
  const [selectedOutlet, setSelectedOutlet] = useState(
    localStorage.getItem("outlet_id") || null
  );
  const [status, setStatus] = useState<string[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [valueChangeSearch, setValueChangeSearch] = useState(false);
  // Define dateRange state as an array of Dayjs or null
  const today: [Dayjs, Dayjs] = [dayjs().startOf("day"), dayjs().endOf("day")];

  const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>(today);
  const [loading, setLoading] = useState(false);
  // const [service, setService] = useState<string | null>("");

  const handleStorageChange = (checkedValues: any) => {
    setSelectedStorage(checkedValues);
  };

  const applyStorageFilter = () => {
    if (selectedStorage.length === 0) {
      setDataSource(originalDataSource);
    } else {
      const filteredData = originalDataSource.filter((item) =>
        selectedStorage.includes(item.storage)
      );
      setDataSource(filteredData);
    }
    setStorageDropdownVisible(false);
  };

  const resetStorageFilter = () => {
    setSelectedStorage([]);
    setDataSource(originalDataSource);
  };

  const storageFilterMenu = (
    <Menu>
      {storageOptions.length > 0 ? (
        <>
          <Checkbox.Group
            style={{
              padding: "10px",
              display: "flex",
              flexDirection: "column",
            }}
            value={selectedStorage}
            onChange={handleStorageChange}
          >
            {storageOptions.map((storage: any) => (
              <Row key={storage.id} style={{ marginBottom: "8px" }}>
                <Col span={24}>
                  <Checkbox value={storage.type}>{storage.type}</Checkbox>
                </Col>
              </Row>
            ))}
          </Checkbox.Group>
        </>
      ) : (
        <div style={{ textAlign: "center" }}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>No Filters</span>}
          />
        </div>
      )}
      <Divider style={{ margin: 0 }} />
      <Row justify="space-between" style={{ padding: "10px" }}>
        <Button
          onClick={resetStorageFilter}
          disabled={selectedStorage.length === 0}
        >
          Reset
        </Button>
        <Button type="primary" onClick={applyStorageFilter}>
          OK
        </Button>
      </Row>
    </Menu>
  );

  const handleServiceStatusChange = (checkedValues: any) => {
    setStatus(checkedValues);
  };

  const applyFilter = () => {
    if (status.length === 0) {
      setDataSource(originalDataSource);
    } else {
      const filteredData = originalDataSource.filter((item) =>
        status.includes(item.status)
      );
      setDataSource(filteredData);
    }
    setDropdownVisible(false);
  };
  const resetFilter = () => {
    setStatus([]);
    setDataSource(originalDataSource);
  };

  const serviceStatus = (
    <Menu style={{ width: "180px" }}>
      <Checkbox.Group
        style={{ padding: "10px", display: "flex", flexDirection: "column" }}
        value={status}
        onChange={handleServiceStatusChange}
      >
        <Row>
          <Col span={24}>
            <Checkbox value="To be adjusted">To be adjusted</Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="Adjusted">Adjusted</Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
      <Divider style={{ margin: 0 }} />
      <Row justify="space-between" style={{ padding: "10px" }}>
        <Button onClick={resetFilter} disabled={status.length === 0}>
          Reset
        </Button>
        <Button type="primary" onClick={applyFilter}>
          OK
        </Button>
      </Row>
    </Menu>
  );

  const filterIconStyle = {
    color: status.length > 0 ? "#1890FF" : "#aaa",
    cursor: "pointer",
    marginLeft: "8px",
  };

  const filterStorageIconStyle = {
    color: selectedStorage.length > 0 ? "#1890FF" : "#aaa",
    cursor: "pointer",
    marginLeft: "8px",
  };

  const setToday = () => {
    const today: [Dayjs, Dayjs] = [
      dayjs().startOf("day"),
      dayjs().endOf("day"),
    ];
    setDateRange(today);
  };

  const handleDateChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (dates && dates[0] && dates[1]) {
      setDateRange([dates[0], dates[1]]);
    } else {
      setDateRange(today);
    }
  };

  const fetchStockAdjustList = async (page: number = 1) => {
    if (!dateRange) {
      message.warning("Please select a date range.");
      return;
    }

    try {
      setLoading(true);
      const startDate = dateRange[0].format("YYYY-MM-DD");
      const endDate = dateRange[1].format("YYYY-MM-DD");

      const response = await get(
        `/stock/stockadjustlist/${currentPage}/${pageSize}/created_at/DESC/${selectedOutlet}/${searchText}?startDate=${startDate}&endDate=${endDate}`
      );

      if (response.data && response.data.success) {
        const fetchedData = response.data.data.map(
          (item: any, index: number) => ({
            key: item.stock_adjust_id,
            ref: item.ref_no,
            date: item.stock_adj_date,
            creationTime: item.created_at,
            storage: item.storage_type,
            countedBy: item.staff_name,
            status: item.status,
            expected: item.expected_qty,
            counted: item.counted_qty,
            difference: item.difference,
          })
        );

        setDataSource(fetchedData);
        setOriginalDataSource(fetchedData);
        setTotalRecords(response.data.totalRecords);
      } else {
        message.error("Failed to fetch data");
      }
    } catch (error) {
      message.error("Error fetching stock adjust list");
    } finally {
      setLoading(false);
    }
  };

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: null,
            name: "All Store",
          });
          setOutletData(data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };

  const getStorageDropdown = async () => {
    try {
      let url = `/storage/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setStorageOptions(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    // Initial fetch on component load if default date range is set
    if (dateRange) {
      fetchStockAdjustList();
    }
  }, [dateRange]);
  useEffect(() => {
    fetchStockAdjustList();
  }, [selectedOutlet, currentPage, pageSize]);

  useEffect(() => {
    getStorageDropdown();
    getOutletDropdown();
    fetchStockAdjustList();
  }, []);

  const columns: ColumnsType<StockTakeRecord> = [
    {
      title: "Ref#",
      dataIndex: "ref",
      key: "ref",
      fixed: "left", // To make the Ref column sticky (optional)
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (date: string) => dayjs(date).format("DD-MM-YYYY"),
      sorter: (a: any, b: any) => dayjs(a.date).unix() - dayjs(b.date).unix(),
      showSorterTooltip: false,
    },
    {
      title: "Creation time",
      dataIndex: "creationTime",
      key: "creationTime",
    },
    {
      title: (
        <>
          Storage
          <Dropdown
            overlay={storageFilterMenu}
            trigger={["click"]}
            visible={storageDropdownVisible}
            onVisibleChange={(flag) => setStorageDropdownVisible(flag)}
            overlayStyle={{ width: "150px" }}
          >
            <FilterOutlined style={filterStorageIconStyle} />
          </Dropdown>
        </>
      ),
      dataIndex: "storage",
      key: "storage",
    },
    {
      title: "Counted by",
      dataIndex: "countedBy",
      key: "countedBy",
    },
    {
      // title: "Status",
      title: (
        <>
          Status
          <Dropdown
            overlay={serviceStatus}
            trigger={["click"]}
            visible={dropdownVisible}
            onVisibleChange={(flag) => setDropdownVisible(flag)}
            overlayStyle={{ width: "150px" }} // Adjust size here
          >
            <FilterOutlined style={filterIconStyle} />
          </Dropdown>
        </>
      ),
      dataIndex: "status",
      key: "status",
      render: (status: string) =>
        status === "Adjusted" ? (
          <span style={{ color: "green" }}>● Adjusted</span>
        ) : (
          <span style={{ color: "orange" }}>● To be adjusted</span>
        ),
    },
    {
      title: "Expected",
      dataIndex: "expected",
      key: "expected",
    },
    {
      title: "Counted",
      dataIndex: "counted",
      key: "counted",
    },
    {
      title: "Difference",
      dataIndex: "difference",
      key: "difference",
    },
    {
      title: "Action",
      key: "action",
      fixed: "right",
      render: (text: any, record: any): React.ReactNode => (
        <>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() =>
              navigate(`/inventory/stocktakesdetails/view/${record.key}`)
            }
          >
            View
          </a>
        </>
      ),
    },
  ];

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      fetchStockAdjustList();
    }
  };

  useEffect(() => {
    if (valueChangeSearch) {
      fetchStockAdjustList();
    }
  }, [valueChangeSearch]);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await setSearchText(e.target.value);
    await setValueChangeSearch(e.target.value.length === 0);
  };

  const handleSearchClear = async () => {
    await setSearchText("");
    setValueChangeSearch(true);
  };

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Content className="mainContainer paddingextra">
        {/* Header Section */}
        <Row justify="space-between" align="middle">
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div
                onClick={() => navigate("/inventory")}
                style={{ cursor: "pointer" }}
              >
                <ArrowLeftOutlined
                  style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
                />
              </div>
              <h2 style={{ margin: 0 }}>Stock Takes</h2>
            </div>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{ marginRight: "8px" }}
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate("/inventory/stocktakes/add")}
                className="add-button"
              >
                Add
              </Button>
            </div>
          </Col>
        </Row>

        {/* Content Section */}
        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
          }}
        >
          {/* Filters */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "16px",
            }}
          >
            <Row gutter={16}>
              <Col>
                <Select
                  placeholder="Select Service"
                  style={{ width: "198px", height: "40px !important" }}
                  value={selectedOutlet}
                  onChange={(value) => setSelectedOutlet(value)}
                  disabled={localStorage.getItem("userRole") !== "Merchant"}
                >
                  {outletData.length > 0 &&
                    outletData.map((point: any) => (
                      <Option key={point.id} value={point.id}>
                        {point.name}
                      </Option>
                    ))}
                </Select>
              </Col>
              <Col>
                <RangePicker
                  style={{ width: "272px", height: "42px" }}
                  value={dateRange}
                  onChange={handleDateChange}
                  ranges={{
                    Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                    Yesterday: [
                      dayjs().subtract(1, "day").startOf("day"),
                      dayjs().subtract(1, "day").endOf("day"),
                    ],
                    "This Week": [
                      dayjs().startOf("week"),
                      dayjs().endOf("week"),
                    ],
                    "This Month": [
                      dayjs().startOf("month"),
                      dayjs().endOf("month"),
                    ],
                    "Last Month": [
                      dayjs().subtract(1, "month").startOf("month"),
                      dayjs().subtract(1, "month").endOf("month"),
                    ],
                  }}
                />
              </Col>
              <Col>
                <Button
                  style={{
                    width: "60px",
                    height: "40px",
                    lineHeight: "40px",
                    cursor: "pointer",
                    background: "rgba(46, 86, 242, .1)",
                    borderRadius: "6px 6px 6px 6px",
                    color: "#2e56f2",
                  }}
                  type="primary"
                  onClick={setToday}
                >
                  Today
                </Button>
              </Col>
              <Col>
                <Input
                  placeholder="Ref#"
                  style={{ width: "198px", height: "40px !important" }}
                  value={searchText}
                  onChange={handleInputChange}
                  onClear={handleSearchClear}
                  allowClear
                  onKeyPress={handleKeyPress}
                />
              </Col>
            </Row>

            {/* Search Button */}
            <Button type="primary" style={{ height: 32 }}>
              Search
            </Button>
          </div>

          {/* Table */}
          <Datatable<StockTakeRecord>
            columns={columns}
            dataSource={dataSource}
            // currentPage={currentPage}
            // pageSize={pageSize}
            // totalRecords={totalRecords}
            scroll={{ y: "calc(100vh - 220px)" }}
            showPagination={false}
            dynamicTableHeight="calc(100vh - 180px)"
          />
          {totalRecords && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "16px",
              }}
            >
              {/* Display total items */}
              <div
                style={{
                  marginLeft: "auto",
                  marginRight: "16px",
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "#555",
                }}
              >
                Total {totalRecords || 0} items
              </div>

              {/* Pagination component */}
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={handlePageChange}
                showSizeChanger={false}
                itemRender={(page, type, originalElement) => {
                  if (type === "prev") {
                    return (
                      <a
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          fontSize: "12px",
                          fontWeight: "700",
                          color: currentPage === 1 ? "#bbb" : "#2e56f2",
                          pointerEvents: currentPage === 1 ? "none" : "auto",
                        }}
                      >
                        <LeftOutlined />
                        <span>Previous</span>
                      </a>
                    );
                  }
                  if (type === "next") {
                    const lastPage = Math.ceil((totalRecords || 0) / pageSize!);
                    return (
                      <a
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          fontSize: "12px",
                          fontWeight: "700",
                          color: currentPage === lastPage ? "#bbb" : "#2e56f2",
                          pointerEvents:
                            currentPage === lastPage ? "none" : "auto",
                        }}
                      >
                        <span>Next</span>
                        <RightOutlined />
                      </a>
                    );
                  }
                  return originalElement;
                }}
              />
            </div>
          )}
        </Content>
      </Content>
    </>
  );
}
