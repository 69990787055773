import { CopyOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Select,
  Switch,
  Table,
} from "antd";
import update from "immutability-helper";
import React, { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import * as Yup from "yup";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import { deleteData, get, post, put } from "../../services/Apiclient";

const { Option } = Select;

interface PaymentMethodProps {
  selectedOutlet: string;
}

const PaymentMethod: React.FC<PaymentMethodProps> = ({ selectedOutlet }) => {
  const validationSchema = Yup.object().shape({
    paymentmethodtype: Yup.string().required("Please enter Types name"),
    paymentmethodcategory: Yup.string().required(
      "Please select Payment category"
    ),
    servicechargevalue: Yup.string().required("Please select Service charge"),
  });

  const initialFormValues = {
    paymentmethodtype: "",
    paymentmethodcategory: "",
    servicecharge: false,
    servicechargevalue: 0,
    outlet_id: selectedOutlet,
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [selectedData, setSelectedData] = useState<any | null>(null);
  const [editingRecord, setEditingRecord] = useState<any>(null);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [outletData, setOutletData] = useState<any[]>([]);
  const outletId = localStorage.getItem("outlet_id");
  const [selectedOutlets, setSelectedOutlets] = useState<string[]>([]);
  const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false);

  const [formValues, setFormValues] = useState(initialFormValues);
  const [form] = Form.useForm();
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setFormValues(initialFormValues);
    setEditingRecord(null);
    setSelectedOutlets([]);
    setIsDuplicateModalVisible(false);
  };

  const handleOk = async () => {
    try {
      await validationSchema.validate(formValues, { abortEarly: false });

      if (editingRecord) {
        formValues.outlet_id = selectedOutlet;
        await put(`/paymentmethod/${editingRecord.key}`, formValues);
        message.success("Payment Method updated successfully!");
        fetchPaymentMethods();
      } else {
        formValues.outlet_id = selectedOutlet;
        // Add mode: Call API to add a new record
        await post("/paymentmethod/addpaymentmethod", formValues);
        message.success("Payment Method added successfully!");
        fetchPaymentMethods();
      }
      setIsModalVisible(false);
      setFormValues(initialFormValues);
      setEditingRecord(null);
    } catch (errors: any) {
      if (errors instanceof Yup.ValidationError) {
        const firstError = errors.inner[0];
        if (firstError) {
          message.error(firstError.message);
        }
      }
    }
  };

  const handleDeleteClick = (record: any) => {
    setSelectedData(record);
    setDeleteModalVisible(true);
  };

  const handleEdit = async (record: any) => {
    try {
      // Fetch the record by ID
      const response = await get(`/paymentmethod/${record.key}`);
      if (response.data.success) {
        const data = response.data.data;
        setEditingRecord({
          key: data.id,
        });
        setFormValues({
          paymentmethodcategory: data.paymentmethodcategory,
          paymentmethodtype: data.paymentmethodtype,
          servicecharge: data.servicecharge,
          servicechargevalue: data.servicechargevalue,
          outlet_id: selectedOutlet,
        });
        setIsModalVisible(true);
      }
    } catch (error) {
      message.error("Failed to load payment method details.");
      console.error(error);
    }
  };

  const handleConfirm = async () => {
    try {
      if (selectedData) {
        // Call delete API
        await deleteData(`/paymentmethod/remove/${selectedData.key}`);
        message.success("Payment Method deleted successfully!");
        fetchPaymentMethods();
      }
      setDeleteModalVisible(false);
      setSelectedData(null);
    } catch (error) {
      message.error("Failed to delete payment method.");
      console.error(error);
    }
  };

  const handleToggle = async (key: React.Key, checked: boolean) => {
    try {
      let url = `/paymentmethod/${key}`;

      let body = {
        status: checked,
      };

      const response = await put(url, body);
      if (response && response.data) {
        if (response.data.success) {
          fetchPaymentMethods();
        } else {
          console.log("Error in api call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (errors) {
      message.error("error occur in api call");
    }
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false); // Close the modal without deleting
  };

  const columns = [
    {
      title: "",
      dataIndex: "index",
      key: "order",
      width: 20,
      render: () => <span style={{ cursor: "move" }}>☰</span>, // Drag icon for order
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
      width: 60,
      render: (text: any, record: any, index: number) => (
        <span>
          <span>{index + 1}</span>
        </span>
      ),
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: 100,
    },
    {
      title: "Type",
      dataIndex: "paymentmethodtype",
      key: "paymentmethodtype",
      width: 100,
    },
    {
      title: "Payment category",
      dataIndex: "paymentmethodcategory",
      key: "paymentmethodcategory",
      width: 100,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 60,
      render: (status: any, record: any) => (
        <Switch
          checked={status}
          onChange={(checked) => handleToggle(record.key, checked)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 100,
      render: (text: any, record: any) => (
        <>
          {" "}
          {record.allowedit ? (
            <a
              style={{
                color: "#2e56f2",
                textDecoration: "underline",
                fontWeight: 700,
                marginRight: "16px",
                textUnderlineOffset: "4px",
              }}
              onClick={() => handleEdit(record)}
            >
              Edit
            </a>
          ) : (
            <></>
          )}
          {record.allowdelete ? (
            <a
              style={{
                color: "rgb(245, 63, 63)",
                fontWeight: 700,
                textDecoration: "underline",
                textUnderlineOffset: "4px",
              }}
              onClick={() => handleDeleteClick(record)}
            >
              Delete
            </a>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  const fetchPaymentMethods = async () => {
    try {
      const response = await get(`/paymentmethod/list/${selectedOutlet}`);
      if (response.data.success) {
        const data = response.data.data.map((item: any, index: number) => ({
          key: item.id,
          order: (index + 1).toString(),
          code: item.code,
          paymentmethodtype: item.paymentmethodtype,
          paymentmethodcategory: item.paymentmethodcategory,
          status: item.status,
          allowdelete: item.allowdelete,
          allowedit: item.allowedit,
        }));
        setDataSource(data);
      } else {
        message.error("Error while fetch data");
      }
    } catch (error) {
      message.error("Failed to load payment methods.");
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPaymentMethods();
  }, [selectedOutlet]);

  useEffect(() => {
    fetchPaymentMethods();
  }, []);

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const draggedRow = dataSource[dragIndex];
    const newDataSource = update(dataSource, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedRow],
      ],
    });
    setDataSource(newDataSource);
    updateSequence(newDataSource);
  };

  // Update sequence API call
  const updateSequence = async (newDataSource: any[]) => {
    const updatedSequence = newDataSource.map((item, index) => ({
      id: item.key,
      sequence: index + 1,
    }));
    try {
      await put("/paymentmethod/sequenceupdate", {
        paymentmethods: updatedSequence,
      });
    } catch {
      message.error("Failed to update payment methods order.");
    }
  };

  const DraggableBodyRow = ({
    index,
    moveRow,
    className,
    style,
    ...restProps
  }: any) => {
    const ref = React.useRef<HTMLTableRowElement>(null);
    const [, drop] = useDrop({
      accept: "row",
      hover(item: any, monitor) {
        if (!ref.current) return;
        const dragIndex = item.index;
        const hoverIndex = index;
        if (dragIndex === hoverIndex) return;

        const hoverBoundingRect = ref.current.getBoundingClientRect();
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        const clientOffset = monitor.getClientOffset();
        const hoverClientY = clientOffset!.y - hoverBoundingRect.top;

        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;

        moveRow(dragIndex, hoverIndex);
        item.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag] = useDrag({
      type: "row",
      item: { index },
      collect: (monitor) => ({ isDragging: monitor.isDragging() }),
    });

    drag(drop(ref));

    return (
      <tr
        ref={ref}
        style={{ ...style, opacity: isDragging ? 0.5 : 1 }}
        {...restProps}
      />
    );
  };

  const handleInputChange = (name: any, value: any) => {
    setFormValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);
      if (response && response.data) {
        if (response.data.success) {
          setOutletData(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  useEffect(() => {
    getOutletDropdown();
  }, []);

  const showDuplicateModal = () => {
    if (outletData.length === 0) {
      getOutletDropdown().then(() => {
        setIsDuplicateModalVisible(true);
      });
    } else {
      setIsDuplicateModalVisible(true);
    }
  };

  // Handle Duplicate action
  const handleDuplicate = async () => {
    if (selectedOutlets.length === 0) {
      message.error("Please select an outlet.");
      return;
    }

    try {
      const requestData = {
        target_outlet_id: selectedOutlets[0],
      };

      const response = await post(
        `/paymentmethod/duplicate/${selectedOutlet}`,
        requestData
      );

      if (response.data.success) {
        message.success("Payment duplicated successfully!");
        handleCancel();
      } else {
        message.error("Failed to duplicate Payment.");
      }
    } catch (error) {
      message.error("An error occurred while duplicating the Payment.");
      console.error(error);
    }
  };

  // Handle selecting the outlets
  const handleOutletChange = (value: string[]) => {
    setSelectedOutlets(value);
  };

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "16px",
          }}
        >
          <h3 style={{ marginBottom: 0 }}>Payment methods</h3>
          <div style={{ display: "flex", gap: "10px" }}>
            {localStorage.getItem("userRole") == "Merchant" && (
              <Button
                type="primary"
                icon={<CopyOutlined />}
                onClick={showDuplicateModal}
              >
                Duplicate
              </Button>
            )}
            <Button type="primary" icon={<PlusOutlined />} onClick={showModal}>
              Add
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          components={{
            body: {
              row: DraggableBodyRow,
            },
          }}
          scroll={{ y: "calc(100vh - 230px)" }}
          rowClassName={() => "custom-row"}
          onRow={(record, index) => ({
            index,
            className: "",
            style: {},
            moveRow,
          })}
        />
        <Modal
          title={editingRecord ? "Edit" : "Add"}
          visible={isModalVisible}
          onCancel={handleCancel}
          onOk={handleOk}
          okText="Save"
          cancelText="Cancel"
        >
          <Form layout="vertical">
            <Form.Item label="Type name" required>
              <Input
                placeholder="Please enter"
                value={formValues.paymentmethodtype}
                onChange={(e) =>
                  handleInputChange("paymentmethodtype", e.target.value)
                }
                allowClear
              />
            </Form.Item>
            <Form.Item label="Payment category" required>
              <Select
                placeholder="Please select"
                value={formValues.paymentmethodcategory}
                onChange={(value) =>
                  handleInputChange("paymentmethodcategory", value)
                }
              >
                <Option value="Cash">Cash</Option>
                <Option value="Offset">Offset</Option>
                <Option value="Free of charge">Free of charge</Option>
              </Select>
            </Form.Item>
            <Form.Item style={{ marginBottom: "16px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {/* Label */}
                <label style={{ fontWeight: "500", fontSize: "16px" }}>
                  Service charge
                </label>
                {/* Switch */}
                <Switch
                  checked={formValues.servicecharge}
                  onChange={(value) =>
                    handleInputChange("servicecharge", value)
                  }
                />
              </div>
            </Form.Item>

            {/* Input field for service charge */}
            <Form.Item>
              <Input
                placeholder="0"
                suffix="%"
                type="number"
                min={0}
                value={formValues.servicechargevalue}
                onChange={(e) =>
                  handleInputChange("servicechargevalue", e.target.value)
                }
                disabled={!formValues.servicecharge}
                style={{
                  backgroundColor: !formValues.servicecharge
                    ? "#f5f5f5"
                    : "white",
                  pointerEvents: !formValues.servicecharge ? "none" : "auto",
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
        {/* Modal for selecting and duplicate outlets */}
        <Modal
          title="Select Outlet to Duplicate"
          visible={isDuplicateModalVisible}
          onOk={handleDuplicate}
          onCancel={handleCancel}
          okText="Copy"
          cancelText="Cancel"
        >
          <Form form={form} layout="vertical">
            <Form.Item label="Select Outlet" name="outlet" required>
              <Select
                value={selectedOutlets[0]}
                onChange={(value) => handleOutletChange([value])}
                placeholder="Select an outlet to duplicate"
                style={{ width: "100%" }}
              >
                {outletData.map((outlet: any) => (
                  <Option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
        <ConfirmDeleteModal
          visible={deleteModalVisible}
          onCancel={handleCancelDelete}
          // onConfirm={() => handleDeleteApi(editingRecord.key)}
          onConfirm={handleConfirm}
          message="Are you sure to delete?"
        />
      </DndProvider>
    </>
  );
};

export default PaymentMethod;
