import React, { useState, useEffect } from "react";
import {
  Table,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
  Tag,
  Space,
  Typography,
  Card,
  Pagination,
  Input,
  message,
  Spin,
} from "antd";
import {
  ArrowLeftOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  UndoOutlined,
  UnorderedListOutlined,
  UpOutlined,
} from "@ant-design/icons";
import moment from "moment";
import { Content } from "antd/es/layout/layout";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import ColumnsReportsModal from "../../../components/model/ColumnsReportsModal";
import { get } from "../../../services/Apiclient";
import ReferrerModal from "../../../components/model/customer/ReferrerModal";
import { useCurrency } from "../../../contexts/CurrencyContext";

const StaffIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_Avatar_EmpM.png`;
const { RangePicker } = DatePicker;
const { Option } = Select;
const { Title, Text } = Typography;

const BillingSummary = () => {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const [filters, setFilters] = useState({
    outlet: null,
    dateRange: [],
    billType: null,
    paymentType: null,
    customerSource: null,
    department: null,
    searchRef: "",
    searchCustomer: "",
  });

  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    current: 1,
    pageSize: 10,
  });

  const outletId = localStorage.getItem("outlet_id");
  const [selectedOutlet, setSelectedOutlet] = useState<string | null>(outletId);
  const [selectedStaff, setSelectedStaff] = useState<string | null>(null);
  const [outlet, setOutlet] = useState<any[]>([]);
  const [customerSourceData, setCustomerSourceData] = useState<any[]>([]);
  const [staff, setStaff] = useState<any[]>([]);
  const [departments, setDepartments] = useState<any[]>([]);
  const [customersource, setCustomersource] = useState<any[]>([]);
  const [selectedDepartment, setSelectedDepartment] = useState<string | null>(
    null
  );
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState<any[]>([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | null
  >(null);
  const [selectedBillType, setSelectedBillType] = useState<string | null>(null);

  const [selectedCustomerSource, setSelectedCustomerSource] = useState<
    string | null
  >(null);

  const [selectedItemType, setSelectedItemType] = useState<string | null>(null);
  const [selectedCashier, setSelectedCashier] = useState<string | null>(null);

  const currentWeekStart = dayjs().startOf("isoWeek"); // Monday
  const currentWeekEnd = dayjs().endOf("isoWeek"); // Sunday
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    currentWeekStart,
    currentWeekEnd,
  ]);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const [isFolded, setIsFolded] = useState(false);
  const [numberOfItems, setnumberOfItems] = useState("0");
  const [totalpaid, settotalpaid] = useState(0);
  const [serviceproducttotal, setserviceproducttotal] = useState(0);
  const [cardsales, setcardsales] = useState(0);
  const [tips, settips] = useState(0);
  const [servicecharge, setservicecharge] = useState(0);
  const [Privilegepayment, setPrivilegepayment] = useState(0);
  const [qr, setqr] = useState(0);
  const [onlinetransfer, setonlinetransfer] = useState(0);
  const [collected, setcollected] = useState(0);
  const [totaltax, settotaltax] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customercode, setcustomercode] = useState("");
  const [searchitem, setsearchitem] = useState("");
  const [searchrefno, setsearchrefno] = useState("");
  const [textChange, setTextChange] = useState(false);
  const [loading, setloading] = useState(false);
  const [valueChangeSearch, setValueChangeSearch] = useState(false);

  const [isReferralModalVisible, setIsReferralModalVisible] = useState(false);
  const [referralText, setReferralText] = useState("");
  const [referralId, setReferralId] = useState("null");

  const showReferralModal = () => setIsReferralModalVisible(true);
  const onCancel = () => {
    setIsReferralModalVisible(false);
  };

  const handleReferrerSelect = (selectedObject: any) => {
    // setSelectedReferrer(selectedObject);
    setReferralText(selectedObject.name);
    setReferralId(selectedObject.id);
  };

  const handleSearchItemChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setsearchitem(value || "");
    if (value && value.length == 0) {
      setTextChange(true);
    }
  };

  const handleSearchRefnoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setsearchrefno(value || "");
    if (value && value.length == 0) {
      setTextChange(true);
    }
  };

  const handleSearchCustomercodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setcustomercode(value || "");
    if (value && value.length == 0) {
      setTextChange(true);
    }
  };

  useEffect(() => {
    getStockInInventoryData();
  }, [textChange]);

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: "null",
            name: "All Outlets",
          });
          setOutlet(response.data.data);
          setSelectedOutlet(
            localStorage.getItem("outlet_id")
              ? localStorage.getItem("outlet_id")
              : "null"
          );
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const fetchPaymentMethods = async () => {
    try {
      const response = await get(
        `/paymentmethod/list/${localStorage.getItem("outlet_id")}`
      );
      if (response.data.success) {
        setPaymentMethod(response.data.data);
        setSelectedPaymentMethod(null);
      } else {
        message.error("Failed to fetch payment methods");
      }
    } catch (error) {
      console.error("Error fetching payment methods: ", error);
      message.error("Error fetching payment methods");
    }
  };

  const getCustomerSourceDropdown = async () => {
    try {
      let url = `/customersource/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          setCustomersource(response.data.data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getStaffData = async () => {
    try {
      const response = await get(
        `/staff/dropdown/${localStorage.getItem("outlet_id")}`
      );
      if (response?.data?.success && response.data.data) {
        setStaff(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching staff:", error);
      message.error("Failed to fetch staff data.");
    }
  };

  const getDepartmentData = async () => {
    try {
      const response = await get(
        `/department/dropdown/${localStorage.getItem("outlet_id")}`
      );
      if (response?.data?.success && response.data.data) {
        setDepartments(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching department:", error);
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1); // Adjust for 0-based indexing
  };

  const handlePageSizeChange = (value: number) => {
    setPageSize(value);
    setCurrentPage(0);
  };

  const handleSearchItemClear = () => {
    setsearchitem("");
    setValueChangeSearch(true);
  };

  const handleSearchRefClear = () => {
    setsearchrefno("");
    setValueChangeSearch(true);
  };

  const handleSearchCustomercodeClear = () => {
    setcustomercode("");
    setValueChangeSearch(true);
  };

  useEffect(() => {
    if (valueChangeSearch) {
      getStockInInventoryData();
    }
  }, [valueChangeSearch]);

  const handleReload = () => {
    setDateRange([currentWeekStart, currentWeekEnd]);
    setSelectedStaff(null);
    setSelectedDepartment(null);
    setSelectedPaymentMethod(null);
    setSelectedOutlet(outletId);
    setSearchValue(null);
    setCurrentPage(0);
    setPageSize(10);
  };

  const handleRunFilter = () => {
    setCurrentPage(0); // Reset to first page when filters are applied
    getStockInInventoryData(); // Call API with selected filters
  };

  const handleFold = () => {
    setIsFolded(!isFolded);
  };

  const handleColumnsClick = () => {
    setIsModalVisible(true);
  };

  // Simulated API call
  // const fetchData = () => {
  //   const result: any = mockData.slice(
  //     (pagination.current - 1) * pagination.pageSize,
  //     pagination.current * pagination.pageSize
  //   );
  //   setData(result);
  //   setPagination((prev) => ({ ...prev, total: mockData.length }));
  // };

  const getStockInInventoryData = async () => {
    try {
      setloading(true);
      const startDate = dateRange ? dateRange[0].format("DD-MM-YYYY") : "NULL";
      const endDate = dateRange ? dateRange[1].format("DD-MM-YYYY") : "NULL";
      const offset = currentPage;

      const response = await get(
        `/report/transactiondetails/${
          selectedOutlet || "null"
        }/${startDate}/${endDate}/${selectedDepartment || "null"}/${
          selectedBillType || "null"
        }/${selectedPaymentMethod || "null"}/${
          selectedCustomerSource || "null"
        }/${selectedItemType || "null"}/${selectedStaff || "null"}/${
          selectedCashier || "null"
        }/${referralId || "null"}/${customercode || "null"}/${
          searchitem || "null"
        }/${searchrefno || "null"}/${pageSize}/${offset}`
      );

      if (response?.data?.success && response.data.data) {
        const formattedData = response.data.data.map(
          (item: any, index: number) => ({
            ...item,
            key: index.toString(),
          })
        );

        setData(formattedData);
        setTotalRecords(response.data.totalRecords || 0);
        setnumberOfItems(response.data.numberOfItems || "0");
        settotalpaid(response.data.totalpaid || 0);
        setserviceproducttotal(response.data.serviceproducttotal || 0);
        setcardsales(response.data.cardsales || 0);
        settips(response.data.tips || 0);
        setservicecharge(response.data.servicecharge || 0);
        setPrivilegepayment(response.data.Privilegepayment || 0);
        setqr(response.data.qr || 0);
        setonlinetransfer(response.data.onlinetransfer || 0);
        setcollected(response.data.collected || 0);
        settotaltax(response.data.totaltax || 0);
      } else {
        setData([]);
        setTotalRecords(0);
        setnumberOfItems("0");
        settotalpaid(0);
        setserviceproducttotal(0);
        setcardsales(0);
        settips(0);
        setservicecharge(0);
        setPrivilegepayment(0);
        setqr(0);
        setonlinetransfer(0);
        setcollected(0);
        settotaltax(0);
      }
    } catch (error) {
      console.error("Error fetching stock-in inventory details:", error);
      setData([]);
      setTotalRecords(0);
      setnumberOfItems("0");
      settotalpaid(0);
      setserviceproducttotal(0);
      setcardsales(0);
      settips(0);
      setservicecharge(0);
      setPrivilegepayment(0);
      setqr(0);
      setonlinetransfer(0);
      setcollected(0);
      settotaltax(0);
    } finally {
      setloading(false); // 🔴 Stop spinner
      setValueChangeSearch(false);
      setTextChange(false);
    }
  };

  useEffect(() => {
    getStockInInventoryData();
  }, [currentPage, pageSize, filters]);

  useEffect(() => {
    getOutletDropdown();
    fetchPaymentMethods();
    getCustomerSourceDropdown();
    getStaffData();
    getDepartmentData();
  }, []);

  const showBilldetails = (id: string) => {
    navigate(`/sales/billdetails/${id}`);
  };

  const showCustomerdetails = (id: string) => {
    navigate(`/customer/view/${id}`);
  };

  const initialColumns = [
    {
      title: "Bill Type",
      dataIndex: "bill_type",
      key: "bill_type",
      fixed: "left" as "left",
      width: 150,
      render: (
        _: any,
        record: any // as discuss with grecy set static
      ) => <div>Sales Bill</div>,
    },
    {
      title: "Ref#",
      dataIndex: "ref_no",
      key: "ref_no",
      fixed: "left" as "left",
      width: 150,
      render: (_: any, record: any) => (
        <a
          onClick={() => {
            showBilldetails(record.bill_id);
          }}
        >
          {record.ref_no}
        </a>
      ),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: 150,
      render: (_: any, record: any) => (
        <a
          onClick={() => {
            showCustomerdetails(record.customer_id);
          }}
        >
          {record.firstname} {record.lastname}
        </a>
      ),
    },
    {
      title: "Customer Code",
      dataIndex: "customercode",
      key: "customercode",
      width: 150,
    },
    {
      title: "Customer Source",
      dataIndex: "customer_source_name",
      key: "customer_source_name",
      width: 150,
    },
    {
      title: "Referrer",
      dataIndex: "customer_refer_name",
      key: "customer_refer_name",
      width: 150,
      render: (_: any, record: any) => (
        <div>{record.customer_refer_name || "-"}</div>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "final_total",
      key: "final_total",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight: "normal",
          }}
        >
          {currencySymbol.CURRENCY_SYMBOL} {text}
        </span>
      ),
    },
    {
      title: "Accounting Date",
      dataIndex: "order_date",
      key: "order_date",
      width: 150,
    },
    { title: "Cashier", dataIndex: "cashier", key: "cashier", width: 150 },
    {
      title: "Items",
      key: "items",
      width: 200,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items && record.items.length > 0 ? (
            <>
              {record.items.map((item: any, i: any) => (
                <div
                  key={i}
                  style={{
                    padding: "15px",
                    overflow: "hidden",
                    textWrap: "wrap",
                    wordBreak: "break-word",
                    lineHeight: "normal",
                    borderBottom:
                      i !== record.items.length - 1
                        ? "1px solid #e0e0e0"
                        : "none",
                  }}
                >
                  <strong>{item.item_name}</strong>
                </div>
              ))}
            </>
          ) : (
            <div
              style={{
                padding: "15px",
              }}
            >
              <strong>-</strong>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Unit Price",
      key: "unit_price",
      width: 200,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items && record.items.length > 0 ? (
            <>
              {record.items.map((item: any, i: any) => (
                <div
                  key={i}
                  style={{
                    padding: "15px",
                    borderBottom:
                      i !== record.items.length - 1
                        ? "1px solid #e0e0e0"
                        : "none",
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL} {item.unit_price}
                </div>
              ))}
            </>
          ) : (
            <div
              style={{
                padding: "15px",
              }}
            >
              <strong>-</strong>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Qty",
      key: "item_qty",
      width: 150,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items && record.items.length > 0 ? (
            <>
              {record.items.map((item: any, i: any) => (
                <div
                  key={i}
                  style={{
                    padding: "15px",
                    borderBottom:
                      i !== record.items.length - 1
                        ? "1px solid #e0e0e0"
                        : "none",
                  }}
                >
                  {item.item_qty}
                </div>
              ))}
            </>
          ) : (
            <div
              style={{
                padding: "15px",
              }}
            >
              <strong>-</strong>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Paid",
      key: "collected_payment",
      width: 150,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items && record.items.length > 0 ? (
            <>
              {record.items.map((item: any, i: any) => (
                <div
                  key={i}
                  style={{
                    padding: "15px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight: "normal",
                    borderBottom:
                      i !== record.items.length - 1
                        ? "1px solid #e0e0e0"
                        : "none",
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL} {item.collected_payment}
                </div>
              ))}
            </>
          ) : (
            <div
              style={{
                padding: "15px",
              }}
            >
              <strong>-</strong>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Payment Method",
      key: "item_payment_method",
      width: 200,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {/* , background: "#f6f5fa" */}
          {record.items && record.items.length > 0 ? (
            <>
              {record.items.map((item: any, i: any) => (
                <div
                  key={i}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "15px",
                    borderBottom:
                      i !== record.items.length - 1
                        ? "1px solid #e0e0e0"
                        : "none",
                  }}
                >
                  <span
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      lineHeight: "normal",
                    }}
                  >
                    {item.item_payment_method}
                  </span>
                  <span>
                    ({currencySymbol.CURRENCY_SYMBOL} {item.collected_payment})
                  </span>
                </div>
              ))}
            </>
          ) : (
            <div
              style={{
                padding: "15px",
              }}
            >
              <strong>-</strong>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Collected Payment",
      key: "collected_payment",
      width: 250,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items && record.items.length > 0 ? (
            <>
              {record.items.map((item: any, i: any) => (
                <div
                  key={i}
                  style={{
                    padding: "15px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight: "normal",
                    borderBottom:
                      i !== record.items.length - 1
                        ? "1px solid #e0e0e0"
                        : "none",
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL} {item.collected_payment}
                </div>
              ))}
            </>
          ) : (
            <div
              style={{
                padding: "15px",
              }}
            >
              <strong>-</strong>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Privilege Payment",
      key: "privilege",
      width: 250,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items && record.items.length > 0 ? (
            <>
              {record.items.map((item: any, i: any) => (
                <div
                  key={i}
                  style={{
                    padding: "15px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    lineHeight: "normal",
                    borderBottom:
                      i !== record.items.length - 1
                        ? "1px solid #e0e0e0"
                        : "none",
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL} {item.privilege}
                </div>
              ))}
            </>
          ) : (
            <div
              style={{
                padding: "15px",
              }}
            >
              <strong>-</strong>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Staff",
      key: "staff",
      width: 250,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items && record.items.length > 0 ? (
            <>
              {record.items.map((item: any, i: any) => (
                <>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                      borderBottom:
                        i !== record.items.length - 1 &&
                        item.staff &&
                        item.staff.length > 0
                          ? "1px solid #e0e0e0"
                          : "none",
                    }}
                  >
                    {item.staff && item.staff.length > 0 ? (
                      <>
                        {item.staff.map((staff: any, j: any) => (
                          <div
                            key={j}
                            style={{
                              padding: "5px",
                              display: "flex",
                              alignItems: "center",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              lineHeight: "normal",
                              borderBottom:
                                j !== item.staff && item.staff.length - 1
                                  ? "1px solid #e0e0e0"
                                  : "none",
                            }}
                          >
                            {staff.imageUrl !== "" &&
                            staff.imageUrl !== null &&
                            staff.imageUrl !== undefined ? (
                              <img
                                src={staff.imageUrl}
                                alt={staff.staff_name}
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                              />
                            ) : (
                              <img
                                src={StaffIcon}
                                alt={staff.staff_name}
                                style={{
                                  height: "40px",
                                  width: "40px",
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                              />
                            )}
                            {staff.staff_name}
                          </div>
                        ))}
                      </>
                    ) : (
                      <div
                        style={{
                          padding: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        -
                      </div>
                    )}
                  </Space>
                </>
              ))}
            </>
          ) : (
            <div
              style={{
                padding: "15px",
              }}
            >
              <strong>-</strong>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Department",
      key: "department",
      width: 150,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items && record.items.length > 0 ? (
            <>
              {record.items.map((item: any, i: any) => (
                <>
                  <Space
                    direction="vertical"
                    style={{
                      width: "100%",
                      borderBottom:
                        i !== record.items.length - 1 &&
                        item.staff &&
                        item.staff.length > 0
                          ? "1px solid #e0e0e0"
                          : "none",
                    }}
                  >
                    {item.staff && item.staff.length > 0 ? (
                      <>
                        {item.staff.map((staff: any, j: any) => (
                          <div
                            key={j}
                            style={{
                              padding: "5px",
                              display: "flex",
                              alignItems: "center",
                              borderBottom:
                                j !== item.staff && item.staff.length - 1
                                  ? "1px solid #e0e0e0"
                                  : "none",
                            }}
                          >
                            <span
                              style={{
                                height: "40px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                lineHeight: "normal",
                              }}
                            />
                            {staff.department_name !== null &&
                            staff.department_name !== undefined &&
                            staff.department_name !== "" ? (
                              <div>{staff.department_name}</div>
                            ) : (
                              <div>-</div>
                            )}
                          </div>
                        ))}
                      </>
                    ) : (
                      <div
                        style={{
                          padding: "5px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        -
                      </div>
                    )}
                  </Space>
                </>
              ))}
            </>
          ) : (
            <div
              style={{
                padding: "15px",
              }}
            >
              <strong>-</strong>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Taxes",
      key: "item_tax_rate",
      width: 150,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items && record.items.length > 0 ? (
            <>
              {record.items.map((item: any, i: any) => (
                <div
                  key={i}
                  style={{
                    padding: "15px",
                    borderBottom:
                      i !== record.items.length - 1
                        ? "1px solid #e0e0e0"
                        : "none",
                  }}
                >
                  {item.item_tax_rate !== "" &&
                  item.item_tax_rate !== undefined &&
                  item.item_tax_rate !== null ? (
                    <div>
                      {currencySymbol.CURRENCY_SYMBOL}{" "}
                      {(parseFloat(item.collected_payment) *
                        parseFloat(item.item_tax_rate)) /
                        100}
                    </div>
                  ) : (
                    <div>-</div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <div
              style={{
                padding: "15px",
              }}
            >
              <strong>-</strong>
            </div>
          )}
        </Space>
      ),
    },
    {
      title: "Note",
      key: "item_note",
      width: 200,
      render: (_: any, record: any) => (
        <Space direction="vertical" style={{ width: "100%" }}>
          {record.items && record.items.length > 0 ? (
            <>
              {record.items.map((item: any, i: any) => (
                <div
                  key={i}
                  style={{
                    padding: "15px",
                    borderBottom:
                      i !== record.items.length - 1
                        ? "1px solid #e0e0e0"
                        : "none",
                  }}
                >
                  {item.item_note || "-"}
                </div>
              ))}
            </>
          ) : (
            <div
              style={{
                padding: "15px",
              }}
            >
              <strong>-</strong>
            </div>
          )}
        </Space>
      ),
    },
  ];

  const [columns, setColumns] = useState(initialColumns);

  const mandatoryColumns = ["bill_type", "ref_no"];

  // Initialize selectedColumns with all column keys by default
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key)
  );

  const [lastSelectedColumns, setLastSelectedColumns] = useState<string[]>([]);

  const displayedColumns = columns.filter((col) =>
    selectedColumns.includes(col.key)
  );

  const handleModalSave = (
    selectedCols: string[],
    reorderedColumns: { key: string; title: string }[]
  ) => {
    setSelectedColumns(selectedCols);
    setLastSelectedColumns(selectedCols);
    setColumns(
      reorderedColumns.map((col: any) => ({
        ...col,
        dataIndex: col.key, // Assuming `dataIndex` can be derived from `key`
      }))
    );
  };

  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      background: "linear-gradient(90deg, #6a11cb, #2575fc)",
      color: "#fff",
      borderRadius: "10px",
      marginBottom: "16px",
      height: "60px",
      display: "flex",
      alignItems: "center",
      padding: "0 50px",
      position: "relative",
      gap: "40px",
      overflowX: "scroll",
      whiteSpace: "nowrap",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      zIndex: 2,
      textAlign: "center",
    },
    label: {
      fontSize: "12px",
      fontWeight: "500",
      opacity: 0.9,
      color: "#caa2ff",
    },
    amount: {
      margin: 0,
      fontSize: "16px",
      color: "#fff",
      // animation: "fadeIn 1s ease-in-out infinite alternate",
    },
    transparentText: {
      position: "fixed",
      right: "60px",
      fontSize: "40px",
      fontWeight: "lighter",
      color: "rgba(255, 255, 255, 0.2)",
      textTransform: "uppercase",
      letterSpacing: "2px",
      zIndex: 1,
    },
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchValue(value || null);
  };

  return (
    <Spin spinning={loading} size="large">
      <div style={{ padding: 24 }}>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: "16px" }}
        >
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate("/reports")}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
              />
              <Title level={4} style={{ margin: 0 }}>
                Transaction Details Report
              </Title>
            </div>
          </Col>
          <Col>
            <Space>
              {/* <Button style={{ height: 40 }} icon={<ExportOutlined />}>Export as</Button> */}
              <Button
                icon={<UnorderedListOutlined />}
                onClick={handleColumnsClick}
                style={{ height: 40 }}
              >
                Columns
              </Button>
            </Space>
          </Col>
        </Row>

        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#f6f7f9",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
          }}
        >
          <>
            <div style={{ marginBottom: "16px", borderRadius: "8px" }}>
              <Row gutter={16} justify="start" style={{ marginBottom: "16px" }}>
                <Col>
                  <Select
                    value={selectedOutlet}
                    onChange={(value) => setSelectedOutlet(value)}
                    placeholder="Select outlet"
                    style={{ width: 200, height: "46px", borderRadius: "4px" }}
                  >
                    {outlet.map((outlet) => (
                      <Option key={outlet.id} value={outlet.id}>
                        {outlet.name}
                      </Option>
                    ))}
                  </Select>
                </Col>
                <Col>
                  <RangePicker
                    value={dateRange}
                    onChange={(dates) =>
                      dates && setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])
                    }
                    format="DD/MM/YYYY"
                    style={{
                      width: "416px",
                      height: "46px",
                      borderRadius: "4px",
                    }}
                    allowClear={false}
                    className="custom-range-picker"
                    ranges={{
                      Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                      Yesterday: [
                        dayjs().subtract(1, "day").startOf("day"),
                        dayjs().subtract(1, "day").endOf("day"),
                      ],
                      "This Week": [
                        dayjs().startOf("week"),
                        dayjs().endOf("week"),
                      ],
                      "This Month": [
                        dayjs().startOf("month"),
                        dayjs().endOf("month"),
                      ],
                      "Last Month": [
                        dayjs().subtract(1, "month").startOf("month"),
                        dayjs().subtract(1, "month").endOf("month"),
                      ],
                    }}
                  />
                </Col>
                <Col>
                  <Select
                    value={selectedBillType === null ? "all" : selectedBillType}
                    onChange={(value) =>
                      setSelectedBillType(value === "all" ? null : value)
                    }
                    placeholder="Select Bill type"
                    style={{
                      width: "200px",
                      height: "46px",
                      borderRadius: "4px",
                    }}
                  >
                    <Option value="all">All Bill types</Option>
                    <Option value="sales">Sales Bill</Option>
                  </Select>
                </Col>
                <Col>
                  <Select
                    value={
                      selectedPaymentMethod === null
                        ? "all"
                        : selectedPaymentMethod
                    }
                    onChange={(value) =>
                      setSelectedPaymentMethod(value === "all" ? null : value)
                    }
                    placeholder="Select payment method"
                    style={{
                      width: "200px",
                      height: "46px",
                      borderRadius: "4px",
                    }}
                  >
                    <Option value="all">All payment methods</Option>
                    {paymentMethod &&
                      paymentMethod.length > 0 &&
                      paymentMethod.map((payment: any) => (
                        <Option key={payment.id} value={payment.id}>
                          {payment.paymentmethodtype}
                        </Option>
                      ))}
                  </Select>
                </Col>
                {isFolded && (
                  <Col style={{ marginLeft: "auto" }}>
                    <Space>
                      <Button
                        style={{ height: "46px", width: "80px" }}
                        type="primary"
                        onClick={handleRunFilter}
                      >
                        Run
                      </Button>
                      <Button
                        style={{
                          border: "none",
                          background: "#f6f7f9",
                          color: "#717ef1",
                          fontWeight: 600,
                          height: "46px",
                          width: "80px",
                        }}
                        onClick={handleFold}
                      >
                        {isFolded ? (
                          <>
                            Expand{" "}
                            <span
                              style={{
                                display: "inline-flex",
                                flexDirection: "column",
                              }}
                            >
                              <DownOutlined style={{ marginBottom: "-7px" }} />
                              <DownOutlined />
                            </span>
                          </>
                        ) : (
                          <>
                            Fold{" "}
                            <span
                              style={{
                                display: "inline-flex",
                                flexDirection: "column",
                              }}
                            >
                              <UpOutlined style={{ marginBottom: "-7px" }} />
                              <UpOutlined />
                            </span>
                          </>
                        )}
                      </Button>
                    </Space>
                  </Col>
                )}
              </Row>
              {!isFolded && (
                <>
                  <Row
                    gutter={16}
                    justify="start"
                    style={{ marginBottom: "16px" }}
                  >
                    <Col>
                      <Select
                        value={
                          selectedCustomerSource === null
                            ? "all"
                            : selectedCustomerSource
                        }
                        onChange={(value) =>
                          setSelectedCustomerSource(
                            value === "all" ? null : value
                          )
                        }
                        placeholder="Select customer source"
                        style={{
                          width: 200,
                          height: "46px",
                          borderRadius: "4px",
                        }}
                      >
                        <Option value="all">All customer sources</Option>
                        {customersource.map((source) => (
                          <Option key={source.id} value={source.id}>
                            {source.customersourcetype}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col>
                      <Select
                        value={
                          selectedDepartment === null
                            ? "all"
                            : selectedDepartment
                        }
                        onChange={(value) =>
                          setSelectedDepartment(value === "all" ? null : value)
                        }
                        placeholder="Select department"
                        style={{
                          width: 200,
                          height: "46px",
                          borderRadius: "4px",
                        }}
                      >
                        <Option value="all">All departments</Option>
                        {departments.map((dept) => (
                          <Option key={dept.id} value={dept.id}>
                            {dept.departmenttype}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col>
                      <Select
                        value={
                          selectedItemType === null ? "all" : selectedItemType
                        }
                        onChange={(value) =>
                          setSelectedItemType(value === "all" ? null : value)
                        }
                        placeholder="Select Bill type"
                        style={{
                          width: "200px",
                          height: "46px",
                          borderRadius: "4px",
                        }}
                      >
                        <Option value="all">All</Option>
                        <Option value="Product">Product</Option>
                        <Option value="Service">Service</Option>
                        <Option value="Giftcard">Giftcard</Option>
                        <Option value="Package">Package</Option>
                        <Option value="Membership">Membership</Option>
                      </Select>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      <Select
                        value={selectedStaff === null ? "all" : selectedStaff}
                        onChange={(value) =>
                          setSelectedStaff(value === "all" ? null : value)
                        }
                        placeholder="Select staff"
                        style={{
                          width: 200,
                          height: "46px",
                          borderRadius: "4px",
                        }}
                      >
                        <Option value="all">All staff</Option>
                        {staff.map((staff) => (
                          <Option key={staff.id} value={staff.id}>
                            {staff.name}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      <Select
                        value={
                          selectedCashier === null ? "all" : selectedCashier
                        }
                        onChange={(value) =>
                          setSelectedCashier(value === "all" ? null : value)
                        }
                        placeholder="Select cashiers"
                        style={{
                          width: 200,
                          height: "46px",
                          borderRadius: "4px",
                        }}
                      >
                        <Option value="all">All cashiers</Option>
                        <Option value="admin">Admin</Option>
                      </Select>
                    </Col>
                  </Row>
                </>
              )}
              <Row gutter={16} justify="start" align="middle">
                {!isFolded && (
                  <>
                    <Col>
                      <Input
                        placeholder="Search by item name / code..."
                        style={{ width: 200, height: "46px", borderRadius: 4 }}
                        value={searchitem || ""}
                        onChange={handleSearchItemChange}
                        onPressEnter={handleRunFilter} // Trigger search on Enter key
                        allowClear
                        onClear={handleSearchItemClear}
                      />
                    </Col>
                    <Col>
                      <Input
                        placeholder="Search by ref#"
                        style={{ width: 200, height: "46px", borderRadius: 4 }}
                        value={searchrefno || ""}
                        onChange={handleSearchRefnoChange}
                        onPressEnter={handleRunFilter} // Trigger search on Enter key
                        allowClear
                        onClear={handleSearchRefClear}
                      />
                    </Col>
                    <Col>
                      <Input
                        placeholder="Search by customer code"
                        style={{ width: 200, height: "46px", borderRadius: 4 }}
                        value={customercode || ""}
                        onChange={handleSearchCustomercodeChange}
                        onPressEnter={handleRunFilter} // Trigger search on Enter key
                        allowClear
                        onClear={handleSearchCustomercodeClear}
                      />
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "center" }}>
                      <Input
                        placeholder="Referrer"
                        onClick={showReferralModal}
                        readOnly
                        value={referralText}
                        style={{
                          width: 200,
                          height: "46px",
                          borderRadius: "4px",
                        }}
                        suffix={<span className="textSuffix">&gt;</span>}
                      />
                      {/* <Select
                        value={selectedStaff === null ? "all" : selectedStaff}
                        onChange={(value) =>
                          setSelectedStaff(value === "all" ? null : value)
                        }
                        placeholder="Select referer"
                        style={{
                          width: 200,
                          height: "46px",
                          borderRadius: "4px",
                        }}
                        allowClear
                      >
                        <Option value="all">All referers</Option>
                      </Select> */}
                    </Col>
                    <Col style={{ marginLeft: "auto" }}>
                      <Space>
                        <Button
                          style={{ height: "46px", width: "80px" }}
                          type="primary"
                          onClick={handleRunFilter}
                        >
                          Run
                        </Button>
                        <Button
                          style={{
                            border: "none",
                            background: "#f6f7f9",
                            color: "#717ef1",
                            fontWeight: 600,
                            height: "46px",
                            width: "80px",
                          }}
                          onClick={handleFold}
                        >
                          {isFolded ? (
                            <>
                              Expand{" "}
                              <span
                                style={{
                                  display: "inline-flex",
                                  flexDirection: "column",
                                }}
                              >
                                <DownOutlined
                                  style={{ marginBottom: "-7px" }}
                                />
                                <DownOutlined />
                              </span>
                            </>
                          ) : (
                            <>
                              Fold{" "}
                              <span
                                style={{
                                  display: "inline-flex",
                                  flexDirection: "column",
                                }}
                              >
                                <UpOutlined style={{ marginBottom: "-7px" }} />
                                <UpOutlined />
                              </span>
                            </>
                          )}
                        </Button>
                      </Space>
                    </Col>
                  </>
                )}
              </Row>
            </div>

            <div style={styles.container} className="total-container">
              <div style={styles.textContainer}>
                <Text style={styles.label}>Number of items</Text>
                <Text style={styles.amount}>{numberOfItems}</Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Paid</Text>
                <Text style={styles.amount}>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {totalpaid.toFixed(2)}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Services & Product</Text>
                <Text style={styles.amount}>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {serviceproducttotal.toFixed(2)}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Card sales</Text>
                <Text style={styles.amount}>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {cardsales.toFixed(2)}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Taxes</Text>
                <Text style={styles.amount}>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {totaltax.toFixed(2)}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Tips</Text>
                <Text style={styles.amount}>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {tips.toFixed(2)}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Service charge</Text>
                <Text style={styles.amount}>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {servicecharge.toFixed(2)}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Collected payment</Text>
                <Text style={styles.amount}>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {collected.toFixed(2)}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Privilege payment</Text>
                <Text style={styles.amount}>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {Privilegepayment.toFixed(2)}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>QR</Text>
                <Text style={styles.amount}>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {qr.toFixed(2)}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>ONLINE TRANSFER</Text>
                <Text style={styles.amount}>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {onlinetransfer.toFixed(2)}
                </Text>
              </div>
              <Text style={styles.transparentText}>TOTAL</Text>
            </div>

            {/* Table */}
            <Table
              columns={displayedColumns}
              dataSource={data}
              rowKey="ref_no"
              className="billsummary-table"
              // pagination={{
              //   total: pagination.total,
              //   current: pagination.current,
              //   pageSize: pagination.pageSize,
              //   showSizeChanger: true,
              //   showQuickJumper: false,
              //   pageSizeOptions: ["10", "20", "50", "100"],
              //   showTotal: (total, range) => `Total ${total} items`,
              //   onChange: (page, pageSize) => {
              //     setPagination((prev) => ({
              //       ...prev,
              //       current: page,
              //       pageSize,
              //     }));
              //   },
              // }}
              pagination={false}
              scroll={{
                x: 1600,
                y: `calc(100vh - ${!isFolded ? 500 : 430}px)`,
              }}
              style={{ height: `calc(100vh - ${!isFolded ? 500 : 430}px)` }}
            />
            <Row
              justify="space-between"
              style={{
                alignItems: "center",
                marginTop: "40px",
                marginBottom: "16px",
              }}
            >
              <Col>
                <Select
                  value={pageSize}
                  onChange={handlePageSizeChange}
                  style={{ width: 200, height: "46px", borderRadius: "4px" }}
                >
                  <Option value={10}>10 entries/page</Option>
                  <Option value={20}>20 entries/page</Option>
                  <Option value={50}>50 entries/page</Option>
                  <Option value={100}>100 entries/page</Option>
                </Select>
              </Col>

              {totalRecords >= 0 && (
                <Col>
                  <Row justify="end" align="middle" style={{ gap: "16px" }}>
                    <Col>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "500",
                          color: "rgba(0,0,0,.85)",
                        }}
                      >
                        Total {totalRecords} items
                      </span>
                    </Col>
                    <Col>
                      <Pagination
                        current={currentPage + 1}
                        pageSize={pageSize}
                        total={totalRecords}
                        onChange={handlePageChange}
                        showSizeChanger={false}
                        itemRender={(page, type, originalElement) => {
                          if (type === "prev") {
                            const isDisabled = currentPage === 0;
                            return (
                              <a
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  fontSize: "12px",
                                  fontWeight: "700",
                                  color: isDisabled ? "#bbb" : "#2e56f2",
                                  pointerEvents: isDisabled ? "none" : "auto",
                                }}
                              >
                                <LeftOutlined />
                                <span>Previous</span>
                              </a>
                            );
                          }
                          if (type === "next") {
                            const isDisabled =
                              currentPage ===
                              Math.ceil(totalRecords / pageSize) - 1;
                            return (
                              <a
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                  fontSize: "12px",
                                  fontWeight: "700",
                                  color: isDisabled ? "#bbb" : "#2e56f2",
                                  pointerEvents: isDisabled ? "none" : "auto",
                                }}
                              >
                                <span>Next</span>
                                <RightOutlined />
                              </a>
                            );
                          }
                          return originalElement;
                        }}
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </>
        </Content>
        <ColumnsReportsModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onSave={handleModalSave}
          availableColumns={columns}
          initialSelectedColumns={selectedColumns}
          lastSelectedColumns={lastSelectedColumns}
          mandatoryColumns={mandatoryColumns}
        />
        {isReferralModalVisible && (
          <ReferrerModal
            visible={isReferralModalVisible}
            onClose={onCancel}
            onSelect={handleReferrerSelect}
          ></ReferrerModal>
        )}
      </div>
    </Spin>
  );
};

export default BillingSummary;
