import { ArrowLeftOutlined, UndoOutlined } from "@ant-design/icons";
import { Button, Col, DatePicker, Row, Select, Space, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCurrency } from "../../../contexts/CurrencyContext";
import { get } from "../../../services/Apiclient";
const { Title, Text } = Typography;
const { RangePicker } = DatePicker;

interface SettlementData {
  turnover: {
    payment_method: string;
    total_item_price: number;
  }[];
  tax: string;
  privilege_card_payment: string;
  redemption_payment: string;
  debt_payment: string;
  freeofcharge: string;
  total_sum: string;
  final_turnover: string;
  customer_array: {
    walk_in: number;
    male_count: number;
    guest_count: number;
    female_count: number;
    new_customer: number;
    customer_count: number;
    designated_count: number;
    returning_customer: number;
    nondesignated_count: number;
  };
  item_details: {
    count: number;
    item_id: string;
    item_name: string;
    item_type: string;
    item_price: string;
    total_price: number;
  }[];
  item_taxes: {
    count: number;
    taxname: string | null;
    item_tax: string;
    item_tax_rate: string;
  }[];
  debt_detail_sum: string | null;
}

const styles: { [key: string]: React.CSSProperties } = {
  container: {
    background: "linear-gradient(90deg, #6a11cb, #2575fc)",
    color: "#fff",
    borderRadius: "10px",
    marginBottom: "16px",
    // width: "595px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    padding: "0 50px",
    position: "relative",
    overflow: "hidden",
    justifyContent: "space-between", // Ensures equal spacing
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    zIndex: 2,
    textAlign: "center",
  },
  label: {
    fontSize: "12px",
    fontWeight: "500",
    opacity: 0.9,
    color: "#caa2ff",
  },
  amount: {
    margin: 0,
    fontSize: "18px",
    fontWeight: 700,
    color: "#fff",
  },
};

const SettlementSheetSummaryReport: React.FC = () => {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const outletId = localStorage.getItem("outlet_id");
  const [outlet, setOutlet] = useState<any[]>([]);
  const [selectedOutlet, setSelectedOutlet] = useState<string | null>(outletId);
  const currentWeekStart = dayjs().startOf("day");
  const currentWeekEnd = dayjs().endOf("day");
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    currentWeekStart,
    currentWeekEnd,
  ]);
  const [settlementSummaryData, setSettlementSummaryData] = useState<
    SettlementData[]
  >([]);
  const [selectedSection, setSelectedSection] = useState<string | null>(null);
  const sectionRef = useRef<HTMLDivElement | null>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleSelectSection = (sectionId: string): void => {
    setSelectedSection(sectionId);

    requestAnimationFrame(() => {
      const element = document.getElementById(sectionId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    });
  };

  useEffect(() => {
    const handleScroll = () => {
      if (sectionRef.current) {
        const { top } = sectionRef.current.getBoundingClientRect();
        // sectionRef.current.style.position = top <= 0 ? "fixed" : "relative";
        setIsScrolled(top <= 0);
        sectionRef.current.style.background = "white";
        sectionRef.current.style.background = "white";
        sectionRef.current.style.zIndex = "1000";
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const getOutletData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/outlet/dropdown`);
      if (response?.data?.success && response.data.data) {
        setOutlet(response.data.data);
        const outletExists = response.data.data.some(
          (item: any) => item.id === outletId
        );
        if (outletExists) {
          setSelectedOutlet(outletId);
        } else {
          setSelectedOutlet(response.data.data[0]?.id || null);
        }
      }
    } catch (error) {
      console.error("Error fetching outlet:", error);
    }
  };

  const getSettlementSummaryData = async () => {
    try {
      const startDate = dateRange[0].format("DD-MM-YYYY");
      const endDate = dateRange[1].format("DD-MM-YYYY");

      const response = await get(
        `/report/sattlementdetails/${selectedOutlet}/${startDate}/${endDate}`
      );
      if (response?.data?.success && response.data.data) {
        setSettlementSummaryData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching outlet:", error);
    }
  };

  useEffect(() => {
    getOutletData();
  }, []);

  useEffect(() => {
    if (selectedOutlet) {
      getSettlementSummaryData();
    }
  }, [selectedOutlet]);

  const handleRunFilter = () => {
    getSettlementSummaryData();
  };

  const handleReload = () => {
    setDateRange([currentWeekStart, currentWeekEnd]);
    setSelectedOutlet(outletId);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowLeftOutlined
              style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
            />
            <Title level={4} style={{ margin: 0 }}>
              Settlement Sheet Summary
            </Title>
          </div>
        </Col>
      </Row>

      <Content
        style={{
          backgroundColor: "#f6f7f9",
          borderRadius: "12px",
          border: "1px solid #e0e7fe",
          // overflowY: "auto",
          // maxHeight: "calc(100vh - 100px)",
          // scrollbarWidth: "thin", // For Firefox
          position: "relative", // Needed for sticky positioning
        }}
      >
        {/* filter */}
        <div
          style={{
            position: "sticky",
            top: 0, // Stick to the top
            zIndex: 1000, // Ensure it stays above other elements
            backgroundColor: "#f6f7f9",
            padding: "24px",
            paddingBottom: 0,
            transition: "box-shadow 0.3s ease-in-out",
            boxShadow: isScrolled ? "0px 4px 10px rgba(0, 0, 0, 0.1)" : "none",
          }}
        >
          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col>
              <Select
                value={selectedOutlet ?? "all"}
                onChange={(value) => setSelectedOutlet(value)}
                style={{ width: 200, height: "46px" }}
              >
                <Select.Option value="all">All outlets</Select.Option>
                {outlet.map((outlet) => (
                  <Select.Option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col>
              <RangePicker
                value={dateRange}
                onChange={(dates) =>
                  dates && setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])
                }
                format="DD/MM/YYYY"
                style={{ height: "46px", textAlignLast: "center" }}
                allowClear={false}
                ranges={{
                  Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                  Yesterday: [
                    dayjs().subtract(1, "day").startOf("day"),
                    dayjs().subtract(1, "day").endOf("day"),
                  ],
                  "This Week": [dayjs().startOf("week"), dayjs().endOf("week")],
                  "This Month": [
                    dayjs().startOf("month"),
                    dayjs().endOf("month"),
                  ],
                  "Last Month": [
                    dayjs().subtract(1, "month").startOf("month"),
                    dayjs().subtract(1, "month").endOf("month"),
                  ],
                }}
              />
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <Space>
                <Button
                  icon={<UndoOutlined />}
                  style={{
                    background: "#f6f7f9",
                    height: "46px",
                    width: "40px",
                  }}
                  onClick={handleReload}
                />
                <Button
                  type="primary"
                  style={{ height: "46px", width: "70px" }}
                  onClick={handleRunFilter}
                >
                  Run
                </Button>
              </Space>
            </Col>
          </Row>

          {/* button of select and scroll to get that part */}
          <Row gutter={10} style={{ marginBottom: "10px" }}>
            <Col>
              <Button
                style={{
                  border: 0,
                  backgroundColor:
                    selectedSection === "turnover" ? "#1478fe" : "#f6f7f9",
                  boxShadow: "none",
                  color: selectedSection === "turnover" ? "white" : "#becbd3",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px 13px",
                  height: 40,
                }}
                onClick={() => handleSelectSection("turnover")}
              >
                Turnover
              </Button>
            </Col>
            {/* <Col>
              <Button
                style={{
                  border: 0,
                  backgroundColor: "#f6f7f9",
                  boxShadow: "none",
                  color: "#becbd3",
                  fontWeight: 600,
                  padding: "10px 13px",
                }}
                onClick={() => handleSelectSection("staffinfo")}
              >
                Staff Information
              </Button>
            </Col> */}
            <Col>
              <Button
                style={{
                  border: 0,
                  backgroundColor:
                    selectedSection === "customerflow" ? "#1478fe" : "#f6f7f9",
                  boxShadow: "none",
                  color:
                    selectedSection === "customerflow" ? "white" : "#becbd3",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px 13px",
                  height: 40,
                }}
                onClick={() => handleSelectSection("customerflow")}
              >
                Customer Flow
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  border: 0,
                  backgroundColor:
                    selectedSection === "service" ? "#1478fe" : "#f6f7f9",
                  boxShadow: "none",
                  color: selectedSection === "service" ? "white" : "#becbd3",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px 13px",
                  height: 40,
                }}
                onClick={() => handleSelectSection("service")}
              >
                Service
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  border: 0,
                  backgroundColor:
                    selectedSection === "privilege" ? "#1478fe" : "#f6f7f9",
                  boxShadow: "none",
                  color: selectedSection === "privilege" ? "white" : "#becbd3",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px 13px",
                  height: 40,
                }}
                onClick={() => handleSelectSection("privilege")}
              >
                Privilege
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  border: 0,
                  backgroundColor:
                    selectedSection === "product" ? "#1478fe" : "#f6f7f9",
                  boxShadow: "none",
                  color: selectedSection === "product" ? "white" : "#becbd3",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px 13px",
                  height: 40,
                }}
                onClick={() => handleSelectSection("product")}
              >
                Product
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  border: 0,
                  backgroundColor:
                    selectedSection === "taxes" ? "#1478fe" : "#f6f7f9",
                  boxShadow: "none",
                  color: selectedSection === "taxes" ? "white" : "#becbd3",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px 13px",
                  height: 40,
                }}
                onClick={() => handleSelectSection("taxes")}
              >
                Taxes
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  border: 0,
                  backgroundColor:
                    selectedSection === "incomeexpenses"
                      ? "#1478fe"
                      : "#f6f7f9",
                  boxShadow: "none",
                  color:
                    selectedSection === "incomeexpenses" ? "white" : "#becbd3",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px 13px",
                  height: 40,
                }}
                onClick={() => handleSelectSection("incomeexpenses")}
              >
                Other Income & Expenses
              </Button>
            </Col>
            <Col>
              <Button
                style={{
                  border: 0,
                  backgroundColor:
                    selectedSection === "deptrepayment" ? "#1478fe" : "#f6f7f9",
                  boxShadow: "none",
                  color:
                    selectedSection === "deptrepayment" ? "white" : "#becbd3",
                  fontSize: "16px",
                  fontWeight: 600,
                  padding: "10px 13px",
                  height: 40,
                }}
                onClick={() => handleSelectSection("deptrepayment")}
              >
                Dept & Repayment
              </Button>
            </Col>
          </Row>
        </div>

        {/* details contenct */}
        <div
          style={{
            padding: "24px",
            paddingTop: 0,
            maxHeight: "calc(100vh - 260px)", // Adjust height based on your layout needs
            overflowY: "auto",
            scrollbarWidth: "thin",
          }}
        >
          {/* turnover */}
          <div id="turnover">
            <div style={{ display: "flex" }}>
              <Title level={5} style={{ margin: 10, marginLeft: 0 }}>
                <span className="vertical-line" />
                Turnover
              </Title>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                border: "1px solid #e8ebf3",
                fontWeight: 500,
                color: "#9c9ca6",
                display: "flex",
                gap: "40px",
                padding: "10px 20px",
                fontSize: 13,
                marginBottom: 8,
              }}
            >
              <div style={{ flexGrow: 1 }}>Payment method</div>
              <div>Turnover</div>
              <div
                style={{ width: "150px", textAlign: "right", paddingRight: 20 }}
              >
                Actual turnover
              </div>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                border: "1px solid #e8ebf3",
                fontWeight: 500,
                color: "#9c9ca6",
                padding: "15px 20px",
                fontSize: 14,
                marginBottom: 8,
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "40px",
                  color: "black",
                  // marginBottom: 10,
                }}
              >
                <div style={{ flexGrow: 1 }}>Collection payment</div>
                <div>
                  {currencySymbol.CURRENCY_SYMBOL}
                  {settlementSummaryData[0]?.total_sum || 0}
                </div>
                <div
                  style={{
                    width: "150px",
                    textAlign: "right",
                    paddingRight: "20px",
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}
                  {settlementSummaryData[0]?.total_sum || 0}
                </div>
              </div>
              {settlementSummaryData.length > 0 &&
              settlementSummaryData[0]?.turnover ? (
                <div
                  style={{
                    padding: "15px 20px",
                    backgroundColor: "#f6f5fa",
                    marginTop: 10,
                  }}
                >
                  {settlementSummaryData[0].turnover.map(
                    (turnover, index, array) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          gap: "40px",
                          marginBottom:
                            index !== array.length - 1 ? "12px" : "0px",
                        }}
                      >
                        <div style={{ flexGrow: 1 }}>
                          {turnover.payment_method}
                        </div>
                        <div>
                          {currencySymbol.CURRENCY_SYMBOL}
                          {turnover.total_item_price.toLocaleString()}
                        </div>
                        <div style={{ width: "150px", textAlign: "right" }}>
                          {currencySymbol.CURRENCY_SYMBOL}
                          {turnover.total_item_price.toLocaleString()}
                        </div>
                      </div>
                    )
                  )}
                </div>
              ) : (
                <span></span>
              )}
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                border: "1px solid #e8ebf3",
                fontWeight: 500,
                padding: "15px 20px",
                fontSize: 14,
                display: "flex",
                gap: "40px",
                color: "black",
                marginBottom: 8,
              }}
            >
              <div style={{ flexGrow: 1 }}>Privilege card payment</div>
              <div>
                {currencySymbol.CURRENCY_SYMBOL}
                {settlementSummaryData[0]?.privilege_card_payment || 0}
              </div>
              <div
                style={{
                  width: "150px",
                  textAlign: "right",
                  paddingRight: "20px",
                }}
              >
                {currencySymbol.CURRENCY_SYMBOL}
                {settlementSummaryData[0]?.privilege_card_payment || 0}
              </div>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                border: "1px solid #e8ebf3",
                fontWeight: 500,
                padding: "15px 20px",
                fontSize: 14,
                display: "flex",
                gap: "40px",
                color: "black",
                marginBottom: 8,
              }}
            >
              <div style={{ flexGrow: 1 }}>Redemption payment</div>
              <div>
                {currencySymbol.CURRENCY_SYMBOL}
                {settlementSummaryData[0]?.redemption_payment || 0}
              </div>
              <div
                style={{
                  width: "150px",
                  textAlign: "right",
                  paddingRight: "20px",
                }}
              >
                {currencySymbol.CURRENCY_SYMBOL}
                {settlementSummaryData[0]?.redemption_payment || 0}
              </div>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                border: "1px solid #e8ebf3",
                fontWeight: 500,
                padding: "15px 20px",
                fontSize: 14,
                display: "flex",
                gap: "40px",
                color: "black",
                marginBottom: 8,
              }}
            >
              <div style={{ flexGrow: 1 }}>Dept payment</div>
              <div>
                {currencySymbol.CURRENCY_SYMBOL}
                {settlementSummaryData[0]?.debt_payment || 0}
              </div>
              <div
                style={{
                  width: "150px",
                  textAlign: "right",
                  paddingRight: "20px",
                }}
              >
                {currencySymbol.CURRENCY_SYMBOL}
                {settlementSummaryData[0]?.debt_payment || 0}
              </div>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                border: "1px solid #e8ebf3",
                fontWeight: 500,
                padding: "15px 20px",
                fontSize: 14,
                display: "flex",
                gap: "40px",
                color: "black",
                marginBottom: 8,
              }}
            >
              <div style={{ flexGrow: 1 }}>Free-of-charge payment</div>
              <div>
                {currencySymbol.CURRENCY_SYMBOL}
                {settlementSummaryData[0]?.freeofcharge || 0}
              </div>
              <div
                style={{
                  width: "150px",
                  textAlign: "right",
                  paddingRight: "20px",
                }}
              >
                {currencySymbol.CURRENCY_SYMBOL}
                {settlementSummaryData[0]?.freeofcharge || 0}
              </div>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                border: "1px solid #e8ebf3",
                fontWeight: 500,
                padding: "15px 20px",
                fontSize: 14,
                display: "flex",
                gap: "40px",
                color: "black",
                marginBottom: 8,
              }}
            >
              <div style={{ flexGrow: 1 }}>Tax payment</div>
              <div>
                {currencySymbol.CURRENCY_SYMBOL}
                {settlementSummaryData[0]?.tax || 0}
              </div>
              <div
                style={{
                  width: "150px",
                  textAlign: "right",
                  paddingRight: "20px",
                }}
              >
                {currencySymbol.CURRENCY_SYMBOL}
                {settlementSummaryData[0]?.tax || 0}
              </div>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                border: "1px solid #e8ebf3",
                fontWeight: 500,
                padding: "15px 20px",
                fontSize: 14,
                display: "flex",
                gap: "40px",
                color: "black",
                marginBottom: 8,
              }}
            >
              <div style={{ flexGrow: 1 }}>Total</div>
              <div>
                {currencySymbol.CURRENCY_SYMBOL}
                {settlementSummaryData[0]?.final_turnover || 0}
              </div>
              <div
                style={{
                  width: "150px",
                  textAlign: "right",
                  paddingRight: "20px",
                }}
              >
                {currencySymbol.CURRENCY_SYMBOL}
                {settlementSummaryData[0]?.final_turnover || 0}
              </div>
            </div>
          </div>

          {/* customer flow */}
          <div id="customerflow">
            <div style={{ display: "flex" }}>
              <Title level={5} style={{ margin: 10, marginLeft: 0 }}>
                <span className="vertical-line" />
                Customer flow
              </Title>
            </div>
            <div style={styles.container}>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Male</Text>
                <Text style={styles.amount}>
                  {settlementSummaryData[0]?.customer_array.male_count || 0}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Female</Text>
                <Text style={styles.amount}>
                  {settlementSummaryData[0]?.customer_array.female_count || 0}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>New</Text>
                <Text style={styles.amount}>
                  {settlementSummaryData[0]?.customer_array.new_customer || 0}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Returning Customer</Text>
                <Text style={styles.amount}>
                  {settlementSummaryData[0]?.customer_array
                    .returning_customer || 0}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Walk-in</Text>
                <Text style={styles.amount}>
                  {settlementSummaryData[0]?.customer_array.walk_in || 0}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Designated</Text>
                <Text style={styles.amount}>
                  {settlementSummaryData[0]?.customer_array.designated_count ||
                    0}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Non-designated</Text>
                <Text style={styles.amount}>
                  {settlementSummaryData[0]?.customer_array
                    .nondesignated_count || 0}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Guest-count</Text>
                <Text style={styles.amount}>
                  {settlementSummaryData[0]?.customer_array.guest_count || 0}
                </Text>
              </div>
              <div style={styles.textContainer}>
                <Text style={styles.label}>Customer flow</Text>
                <Text style={styles.amount}>
                  {settlementSummaryData[0]?.customer_array.customer_count || 0}
                </Text>
              </div>
            </div>
          </div>

          {/* service */}
          <div id="service">
            <div style={{ display: "flex" }}>
              <Title level={5} style={{ margin: 10, marginLeft: 0 }}>
                <span className="vertical-line" />
                Service
              </Title>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                border: "1px solid #e8ebf3",
                fontWeight: 500,
                color: "#9c9ca6",
                display: "flex",
                gap: "40px",
                padding: "10px 20px",
                fontSize: 13,
                marginBottom: 8,
              }}
            >
              <div style={{ flexGrow: 1 }}>Item</div>
              <div>Count</div>
              <div
                style={{ width: "150px", textAlign: "right", paddingRight: 20 }}
              >
                Amount
              </div>
            </div>
            {settlementSummaryData.length > 0 &&
            settlementSummaryData[0]?.item_details ? (
              <div>
                {settlementSummaryData[0].item_details
                  .filter((item) => item.item_type === "Service")
                  .map((item, key) => (
                    <div
                      key={key}
                      style={{
                        backgroundColor: "white",
                        borderRadius: 8,
                        border: "1px solid #e8ebf3",
                        fontWeight: 500,
                        padding: "15px 20px",
                        fontSize: 14,
                        display: "flex",
                        gap: "40px",
                        color: "black",
                        marginBottom: 8,
                      }}
                    >
                      <div style={{ flexGrow: 1 }}>{item.item_name}</div>
                      <div>{item.count}</div>
                      <div
                        style={{
                          width: "150px",
                          textAlign: "right",
                          paddingRight: "20px",
                        }}
                      >
                        {currencySymbol.CURRENCY_SYMBOL}
                        {item.total_price.toLocaleString()}
                      </div>
                    </div>
                  ))}
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: 8,
                    border: "1px solid #e8ebf3",
                    fontWeight: 500,
                    padding: "15px 20px",
                    fontSize: 14,
                    display: "flex",
                    gap: "40px",
                    color: "black",
                    marginBottom: 8,
                  }}
                >
                  <div style={{ flexGrow: 1 }}>Total</div>
                  <div>
                    {settlementSummaryData[0].item_details
                      .filter((item) => item.item_type === "Service")
                      .reduce((sum, item) => sum + item.count, 0)}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      textAlign: "right",
                      paddingRight: "20px",
                    }}
                  >
                    {currencySymbol.CURRENCY_SYMBOL}
                    {settlementSummaryData[0].item_details
                      .filter((item) => item.item_type === "Service")
                      .reduce((sum, item) => sum + item.total_price, 0)
                      .toLocaleString()}
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 8,
                  border: "1px solid #e8ebf3",
                  fontWeight: 500,
                  padding: "15px 20px",
                  fontSize: 14,
                  display: "flex",
                  gap: "40px",
                  color: "black",
                  marginBottom: 8,
                }}
              >
                <div style={{ flexGrow: 1 }}>Total</div>
                <div>0</div>
                <div
                  style={{
                    width: "150px",
                    textAlign: "right",
                    paddingRight: "20px",
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}0
                </div>
              </div>
            )}
          </div>

          {/* privilege */}
          <div>
            <div id="privilege" style={{ display: "flex" }}>
              <Title level={5} style={{ margin: 10, marginLeft: 0 }}>
                <span className="vertical-line" />
                Privilege
              </Title>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                border: "1px solid #e8ebf3",
                fontWeight: 500,
                color: "#9c9ca6",
                display: "flex",
                gap: "40px",
                padding: "10px 20px",
                fontSize: 13,
                marginBottom: 8,
              }}
            >
              <div style={{ flexGrow: 1 }}>Item</div>
              <div>Count</div>
              <div
                style={{ width: "150px", textAlign: "right", paddingRight: 20 }}
              >
                Amount
              </div>
            </div>
            {settlementSummaryData.length > 0 &&
            settlementSummaryData[0]?.item_details ? (
              <div>
                {settlementSummaryData[0].item_details
                  .filter(
                    (item) =>
                      item.item_type === "Membership" ||
                      item.item_type === "Giftcard" ||
                      item.item_type === "Package"
                  )
                  .map((item, key) => (
                    <div
                      key={key}
                      style={{
                        backgroundColor: "white",
                        borderRadius: 8,
                        border: "1px solid #e8ebf3",
                        fontWeight: 500,
                        padding: "15px 20px",
                        fontSize: 14,
                        display: "flex",
                        gap: "40px",
                        color: "black",
                        marginBottom: 8,
                      }}
                    >
                      <div style={{ flexGrow: 1 }}>{item.item_name}</div>
                      <div>{item.count}</div>
                      <div
                        style={{
                          width: "150px",
                          textAlign: "right",
                          paddingRight: "20px",
                        }}
                      >
                        {currencySymbol.CURRENCY_SYMBOL}
                        {item.total_price.toLocaleString()}
                      </div>
                    </div>
                  ))}
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: 8,
                    border: "1px solid #e8ebf3",
                    fontWeight: 500,
                    padding: "15px 20px",
                    fontSize: 14,
                    display: "flex",
                    gap: "40px",
                    color: "black",
                    marginBottom: 8,
                  }}
                >
                  <div style={{ flexGrow: 1 }}>Total</div>
                  <div>
                    {settlementSummaryData[0].item_details
                      .filter(
                        (item) =>
                          item.item_type === "Membership" ||
                          item.item_type === "Giftcard" ||
                          item.item_type === "Package"
                      )
                      .reduce((sum, item) => sum + item.count, 0)}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      textAlign: "right",
                      paddingRight: "20px",
                    }}
                  >
                    {currencySymbol.CURRENCY_SYMBOL}
                    {settlementSummaryData[0].item_details
                      .filter(
                        (item) =>
                          item.item_type === "Membership" ||
                          item.item_type === "Giftcard" ||
                          item.item_type === "Package"
                      )
                      .reduce((sum, item) => sum + item.total_price, 0)
                      .toLocaleString()}
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 8,
                  border: "1px solid #e8ebf3",
                  fontWeight: 500,
                  padding: "15px 20px",
                  fontSize: 14,
                  display: "flex",
                  gap: "40px",
                  color: "black",
                  marginBottom: 8,
                }}
              >
                <div style={{ flexGrow: 1 }}>Total</div>
                <div>0</div>
                <div
                  style={{
                    width: "150px",
                    textAlign: "right",
                    paddingRight: "20px",
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}0
                </div>
              </div>
            )}
          </div>

          {/* product */}
          <div id="product">
            <div style={{ display: "flex" }}>
              <Title level={5} style={{ margin: 10, marginLeft: 0 }}>
                <span className="vertical-line" /> Product
              </Title>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                border: "1px solid #e8ebf3",
                fontWeight: 500,
                color: "#9c9ca6",
                display: "flex",
                gap: "40px",
                padding: "10px 20px",
                fontSize: 13,
                marginBottom: 8,
              }}
            >
              <div style={{ flexGrow: 1 }}>Item</div>
              <div>Count</div>
              <div
                style={{ width: "150px", textAlign: "right", paddingRight: 20 }}
              >
                Amount
              </div>
            </div>
            {settlementSummaryData.length > 0 &&
            settlementSummaryData[0]?.item_details ? (
              <div>
                {settlementSummaryData[0].item_details
                  .filter((item) => item.item_type === "Product")
                  .map((item, key) => (
                    <div
                      key={key}
                      style={{
                        backgroundColor: "white",
                        borderRadius: 8,
                        border: "1px solid #e8ebf3",
                        fontWeight: 500,
                        padding: "15px 20px",
                        fontSize: 14,
                        display: "flex",
                        gap: "40px",
                        color: "black",
                        marginBottom: 8,
                      }}
                    >
                      <div style={{ flexGrow: 1 }}>{item.item_name}</div>
                      <div>{item.count}</div>
                      <div
                        style={{
                          width: "150px",
                          textAlign: "right",
                          paddingRight: "20px",
                        }}
                      >
                        {currencySymbol.CURRENCY_SYMBOL}
                        {item.total_price.toLocaleString()}
                      </div>
                    </div>
                  ))}
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: 8,
                    border: "1px solid #e8ebf3",
                    fontWeight: 500,
                    padding: "15px 20px",
                    fontSize: 14,
                    display: "flex",
                    gap: "40px",
                    color: "black",
                    marginBottom: 8,
                  }}
                >
                  <div style={{ flexGrow: 1 }}>Total</div>
                  <div>
                    {settlementSummaryData[0].item_details
                      .filter((item) => item.item_type === "Product")
                      .reduce((sum, item) => sum + item.count, 0)}
                  </div>
                  <div
                    style={{
                      width: "150px",
                      textAlign: "right",
                      paddingRight: "20px",
                    }}
                  >
                    {currencySymbol.CURRENCY_SYMBOL}
                    {settlementSummaryData[0].item_details
                      .filter((item) => item.item_type === "Product")
                      .reduce((sum, item) => sum + item.total_price, 0)
                      .toLocaleString()}
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 8,
                  border: "1px solid #e8ebf3",
                  fontWeight: 500,
                  padding: "15px 20px",
                  fontSize: 14,
                  display: "flex",
                  gap: "40px",
                  color: "black",
                  marginBottom: 8,
                }}
              >
                <div style={{ flexGrow: 1 }}>Total</div>
                <div>0</div>
                <div
                  style={{
                    width: "150px",
                    textAlign: "right",
                    paddingRight: "20px",
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}0
                </div>
              </div>
            )}
          </div>

          {/* taxes */}
          <div id="taxes">
            <div style={{ display: "flex" }}>
              <Title level={5} style={{ margin: 10, marginLeft: 0 }}>
                <span className="vertical-line" /> Taxes
              </Title>
            </div>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: 8,
                border: "1px solid #e8ebf3",
                fontWeight: 500,
                color: "#9c9ca6",
                display: "flex",
                gap: "40px",
                padding: "10px 20px",
                fontSize: 13,
                marginBottom: 8,
              }}
            >
              <div style={{ flexGrow: 1 }}>Item</div>
              <div
                style={{ width: "150px", textAlign: "right", paddingRight: 20 }}
              >
                Amount
              </div>
            </div>
            {settlementSummaryData.length > 0 &&
            settlementSummaryData[0]?.item_taxes?.filter(
              (item) => item.taxname !== null
            ).length > 0 ? (
              <div>
                {settlementSummaryData[0].item_taxes
                  .filter((item) => item.taxname !== null)
                  .map((item, key) => (
                    <div
                      key={key}
                      style={{
                        backgroundColor: "white",
                        borderRadius: 8,
                        border: "1px solid #e8ebf3",
                        fontWeight: 500,
                        padding: "15px 20px",
                        fontSize: 14,
                        display: "flex",
                        gap: "40px",
                        color: "black",
                        marginBottom: 8,
                      }}
                    >
                      <div style={{ flexGrow: 1 }}>{item.taxname}</div>
                      {/* <div>{item.count}</div> */}
                      <div
                        style={{
                          width: "150px",
                          textAlign: "right",
                          paddingRight: "20px",
                        }}
                      >
                        {currencySymbol.CURRENCY_SYMBOL}
                        {item.item_tax_rate}
                      </div>
                    </div>
                  ))}
                <div
                  style={{
                    backgroundColor: "white",
                    borderRadius: 8,
                    border: "1px solid #e8ebf3",
                    fontWeight: 500,
                    padding: "15px 20px",
                    fontSize: 14,
                    display: "flex",
                    gap: "40px",
                    color: "black",
                    marginBottom: 8,
                  }}
                >
                  <div style={{ flexGrow: 1 }}>Total</div>

                  <div
                    style={{
                      width: "150px",
                      textAlign: "right",
                      paddingRight: "20px",
                    }}
                  >
                    {currencySymbol.CURRENCY_SYMBOL}
                    {settlementSummaryData[0].item_taxes.reduce(
                      (sum, item) =>
                        sum +
                        (isNaN(parseFloat(item.item_tax_rate))
                          ? 0
                          : parseFloat(item.item_tax_rate)),
                      0
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 8,
                  border: "1px solid #e8ebf3",
                  fontWeight: 500,
                  padding: "15px 20px",
                  fontSize: 14,
                  display: "flex",
                  gap: "40px",
                  color: "black",
                  marginBottom: 8,
                }}
              >
                <div style={{ flexGrow: 1 }}>Total</div>
                <div
                  style={{
                    width: "150px",
                    textAlign: "right",
                    paddingRight: "20px",
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}0
                </div>
              </div>
            )}
          </div>

          <div style={{ display: "flex" }}>
            {/* income expenses */}
            <div
              id="incomeexpenses"
              style={{ marginRight: "10px", flexGrow: 1 }}
            >
              <div style={{ display: "flex" }}>
                <Title level={5} style={{ margin: 10, marginLeft: 0 }}>
                  <span className="vertical-line" /> Other Income & Expenses
                </Title>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 8,
                  border: "1px solid #e8ebf3",
                  fontWeight: 500,
                  color: "#9c9ca6",
                  display: "flex",
                  gap: "40px",
                  padding: "10px 20px",
                  fontSize: 13,
                  marginBottom: 8,
                }}
              >
                <div style={{ flexGrow: 1 }}>Type</div>
                <div
                  style={{
                    width: "150px",
                    textAlign: "right",
                    paddingRight: 20,
                  }}
                >
                  Amount
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 8,
                  border: "1px solid #e8ebf3",
                  fontWeight: 500,
                  padding: "15px 20px",
                  fontSize: 14,
                  display: "flex",
                  gap: "40px",
                  color: "black",
                  marginBottom: 8,
                }}
              >
                <div style={{ flexGrow: 1 }}>Income</div>
                <div
                  style={{
                    width: "150px",
                    textAlign: "right",
                    paddingRight: 20,
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}0
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 8,
                  border: "1px solid #e8ebf3",
                  fontWeight: 500,
                  padding: "15px 20px",
                  fontSize: 14,
                  display: "flex",
                  gap: "40px",
                  color: "black",
                  marginBottom: 8,
                }}
              >
                <div style={{ flexGrow: 1 }}>Expenses</div>
                <div
                  style={{
                    width: "150px",
                    textAlign: "right",
                    paddingRight: 20,
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}0
                </div>
              </div>
            </div>

            {/* dept & repayment */}
            <div id="deptrepayment" style={{ flexGrow: 1 }}>
              <div style={{ display: "flex" }}>
                <Title level={5} style={{ margin: 10, marginLeft: 0 }}>
                  <span className="vertical-line" /> Dept & Repayment
                </Title>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 8,
                  border: "1px solid #e8ebf3",
                  fontWeight: 500,
                  color: "#9c9ca6",
                  display: "flex",
                  gap: "40px",
                  padding: "10px 20px",
                  fontSize: 13,
                  marginBottom: 8,
                }}
              >
                <div style={{ flexGrow: 1 }}>Type</div>
                <div
                  style={{
                    width: "150px",
                    textAlign: "right",
                    paddingRight: 20,
                  }}
                >
                  Amount
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 8,
                  border: "1px solid #e8ebf3",
                  fontWeight: 500,
                  padding: "15px 20px",
                  fontSize: 14,
                  display: "flex",
                  gap: "40px",
                  color: "black",
                  marginBottom: 8,
                }}
              >
                <div style={{ flexGrow: 1 }}>Dept</div>
                <div
                  style={{
                    width: "150px",
                    textAlign: "right",
                    paddingRight: 20,
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}
                  {settlementSummaryData[0]?.debt_detail_sum || 0}
                </div>
              </div>
              <div
                style={{
                  backgroundColor: "white",
                  borderRadius: 8,
                  border: "1px solid #e8ebf3",
                  fontWeight: 500,
                  padding: "15px 20px",
                  fontSize: 14,
                  display: "flex",
                  gap: "40px",
                  color: "black",
                  marginBottom: 8,
                }}
              >
                <div style={{ flexGrow: 1 }}>Repayment</div>
                <div
                  style={{
                    width: "150px",
                    textAlign: "right",
                    paddingRight: 20,
                  }}
                >
                  {currencySymbol.CURRENCY_SYMBOL}0
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </div>
  );
};

export default SettlementSheetSummaryReport;
