import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Switch,
  TimePicker,
  message,
} from "antd";
import dayjs from "dayjs";
import React from "react";
import { post } from "../../../../services/Apiclient";

// Define the props interface for the ShiftAddModal component
interface ShiftAddModalProps {
  visible: boolean;
  onClose: () => void;
  onSelect: (shift: any) => void;
  selectedService: string | null; // Add selectedService prop
  outletId: string | null; // Add outletId prop
}

const ShiftAddModal: React.FC<ShiftAddModalProps> = ({
  visible,
  onClose,
  onSelect,
  selectedService,
  outletId,
}) => {
  const [form] = Form.useForm();

  // Handle form submission
  const handleAddShift = async (values: any) => {
    // Construct the API payload
    const payload = {
      serviceid: selectedService || "",
      shiftname: values.shifName || "",
      starttime: values.startTime
        ? dayjs(values.startTime).format("HH:mm")
        : "",
      earliestclockin: values.earliestClockIn
        ? dayjs(values.earliestClockIn).format("HH:mm")
        : "",
      earliestclockout: values.earliestClockOut
        ? dayjs(values.earliestClockOut).format("HH:mm")
        : "",
      latestclockin: values.latestClockIn
        ? dayjs(values.latestClockIn).format("HH:mm")
        : "",
      latestclockout: values.latestClockOut
        ? dayjs(values.latestClockOut).format("HH:mm")
        : "",
      endtime: values.endTime ? dayjs(values.endTime).format("HH:mm") : "",
      breaktime: values.breakTime ? "true" : "false", // Convert boolean to string
      outlet_id: outletId || "",
    };

    try {
      // Make the POST request to add the shift
      const response = await post(`/shift/addshift`, payload);
      if (response?.data?.success) {
        // Construct the shift object to pass back to StaffScheduling
        const newShift = {
          id: response.data.data?.id || `${Date.now()}`, // Use the ID from the API response, or fallback to a generated ID
          title:
            values.shifName ||
            `${dayjs(values.startTime).format("HH:mm")} - ${dayjs(
              values.endTime
            ).format("HH:mm")}`,
          start: dayjs(values.startTime).toISOString(),
          end: dayjs(values.endTime).toISOString(),
          extendedProps: {
            earliestClockIn: values.earliestClockIn
              ? dayjs(values.earliestClockIn).format("HH:mm")
              : null,
            latestClockIn: values.latestClockIn
              ? dayjs(values.latestClockIn).format("HH:mm")
              : null,
            earliestClockOut: values.earliestClockOut
              ? dayjs(values.earliestClockOut).format("HH:mm")
              : null,
            latestClockOut: values.latestClockOut
              ? dayjs(values.latestClockOut).format("HH:mm")
              : null,
            breakTime: values.breakTime,
          },
        };

        // Pass the new shift to the parent component
        onSelect(newShift);

        // Reset the form and close the modal
        form.resetFields();
        onClose();
        message.success("Shift added successfully!");
      } else {
        message.error("Failed to add shift. Please try again.");
      }
      console.log("payloaddd", payload)
    } catch (error) {
      console.error("Error adding shift:", error);
      message.error("Error adding shift. Please try again.");
    }
  };

  return (
    <Modal
      style={{ top: "5px" }}
      title="Add Shift"
      visible={visible}
      onCancel={onClose}
      footer={
        <>
          <Divider />
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                style={{
                  height: "46px",
                  borderRadius: "4px",
                  border: "1px solid #d9d9d9",
                  fontWeight: "500",
                }}
                onClick={onClose}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                style={{
                  height: "46px",
                  borderRadius: "4px",
                  background: "#2e56f2",
                  border: "none",
                  fontWeight: "500",
                }}
                onClick={() => form.submit()}
              >
                Save
              </Button>
            </Col>
          </Row>
        </>
      }
    >
      <Form
        form={form}
        onFinish={handleAddShift}
        style={{ marginBottom: "46px" }}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="shifName"
              label="Shift name"
              rules={[{ required: true, message: "Please enter" }]}
            >
              <Input
                style={{ width: "100%", height: "45px", borderRadius: "4px" }}
                placeholder="Enter shift name"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="startTime"
              label="Start time"
              rules={[
                { required: true, message: "Please select a start time" },
              ]}
            >
              <TimePicker
                format="HH:mm"
                style={{ width: "100%", height: "45px", borderRadius: "4px" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="earliestClockIn" label="Earliest clock-in time">
              <TimePicker
                format="HH:mm"
                style={{ width: "100%", height: "45px", borderRadius: "4px" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="latestClockIn" label="Latest clock-in time">
              <TimePicker
                format="HH:mm"
                style={{ width: "100%", height: "45px", borderRadius: "4px" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="endTime"
              label="End time"
              rules={[{ required: true, message: "Please select an end time" }]}
            >
              <TimePicker
                format="HH:mm"
                style={{ width: "100%", height: "45px", borderRadius: "4px" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="earliestClockOut" label="Earliest clock-out time">
              <TimePicker
                format="HH:mm"
                style={{ width: "100%", height: "45px", borderRadius: "4px" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="latestClockOut" label="Latest clock-out time">
              <TimePicker
                format="HH:mm"
                style={{ width: "100%", height: "45px", borderRadius: "4px" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="breakTime"
          label="Break time"
          valuePropName="checked"
          initialValue={false}
          style={{ display: "block" }}
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ShiftAddModal;
