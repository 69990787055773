import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Checkbox,
  Row,
  Col,
  Tabs,
  message,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { get } from "../services/Apiclient";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const defaultStoreImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Store.png`;

const { Option } = Select;
const { TabPane } = Tabs;

const EInvoiceForm = () => {
  const [form] = Form.useForm();
  const [activeTab, setActiveTab] = useState("personal");
  const { mid, oid, bid } = useParams();
  const [outleturlData, setoutleturldata] = useState("");
  const [outletlogoData, setoutletlogodata] = useState<any>(null);

  const fetchOutletDetails = async (id: string) => {
    try {
      const response = await get(`/outlet/${oid}`);
      if (response?.data?.success) {
        const outletData = response.data.data;
        setoutletlogodata(outletData.storelogo);
        setoutleturldata(outletData.storelogourl);
      }
    } catch (error) {
      console.error("Error fetching outlet details:", error);
    }
  };

  const onFinish = (values: any) => {
    console.log("Form Values:", values);
    message.success("E-invoice successfully generated.");
  };

  useEffect(() => {
    if (oid) {
      fetchOutletDetails(oid);
    }
  }, [oid]);

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <div
        style={{
          background: "#008cba",
          padding: 20,
          textAlign: "center",
          color: "white",
          fontSize: "1.5em",
          fontWeight: "bold",
          marginBottom: "10px",
        }}
      >
        Create e-Invoice
        <div style={{ fontSize: "0.8em", fontWeight: "normal" }}>
          Effortlessly Generate Your Digital Invoices
        </div>
      </div>
      <div
        style={{
          maxWidth: 800,
          margin: "auto",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            gap: "10px",
            padding: "20px 20px 0px 20px",
            background: "white",
            borderRadius: "10px 10px 0px 0px",
          }}
        >
          {outletlogoData !== "default.png" && outletlogoData !== null ? (
            <img
              src={outleturlData}
              alt="Company Logo"
              style={{ height: 40 }}
            />
          ) : (
            <img
              src={defaultStoreImage}
              alt="Company Logo"
              style={{ height: 40 }}
            />
          )}
          {/* <span style={{ fontWeight: "bold" }}>Daco</span> */}
        </div>

        <div
          style={{
            background: "white",
            padding: 20,
            borderRadius: "0px 0px 10px 10px",
          }}
        >
          <Form form={form} layout="vertical" onFinish={onFinish}>
            <h3>
              {/* Step 1:{" "} */}
              <span
                style={{
                  textDecoration: "underline",
                  color: "#000",
                }}
              >
                Parties
              </span>
            </h3>
            <Row gutter={16}>
              {/* <Col span={12}>
                <Form.Item name="suppliersname" label="Supplier's Name">
                  <Input placeholder="Enter name" disabled />
                </Form.Item>
              </Col> */}
              <Col span={24} lg={24} md={12}>
                <Form.Item
                  name="buyersname"
                  label="Buyer's Name"
                  rules={[{ required: true, message: "Please enter name" }]}
                >
                  <Input placeholder="Enter name" />
                </Form.Item>
              </Col>
              {/* <Col span={6}>
                <Form.Item name="date" label="Date">
                  <Input disabled style={{ background: "#f5f5f5" }} />
                </Form.Item>
              </Col> */}
            </Row>

            {/* <h3>
              <span
                style={{
                  textDecoration: "underline",
                  color: "#000",
                }}
              >
                Supplier details
              </span>
            </h3> */}

            {/* <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="supplierstin"
                  label="Supplier's Tax Identification Number(TIN)"
                >
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="suppliersidentification"
                  label="Supplier's Registration / Identification Number/Passport Number"
                >
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="suppliersregistration"
                  label="Supplier's SST Registration Number"
                >
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="supplierstourismtax"
                  label="Supplier's Tourism Tax Registration Number"
                >
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="suppliersemail" label="Supplier's e-mail">
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="suppliersmsic"
                  label="Supplier's Malaysia Standard Industrial Classification (MSIC) Code"
                >
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="suppliersactivity"
                  label="Supplier's Business Activity Description"
                >
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
            </Row> */}

            <h3>
              {/* Step 2:{" "} */}
              <span
                style={{
                  textDecoration: "underline",
                  color: "#000",
                }}
              >
                Buyer details
              </span>
            </h3>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="buyerstin"
                  label="Buyer's Tax Identification Number(TIN)"
                  rules={[{ required: true, message: "Please enter" }]}
                >
                  <Input placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="buyersidentification"
                  label="Buyer's Registration / Identification Number/Passport Number"
                  rules={[{ required: true, message: "Please enter" }]}
                >
                  <Input placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="buyersregistration"
                  label="Buyer's SST Registration Number"
                  rules={[{ required: true, message: "Please enter" }]}
                >
                  <Input placeholder="Enter" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="buyersemail" label="Buyer's e-mail">
                  <Input placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>

            <h3>
              <span
                style={{
                  textDecoration: "underline",
                  color: "#000",
                }}
              >
                Address
              </span>
            </h3>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item name="suppliersaddress" label="Supplier's Address">
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="buyersaddress"
                  label="Buyer's Address"
                  rules={[{ required: true, message: "Please enter" }]}
                >
                  <Input placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>

            <h3>
              <span
                style={{
                  textDecoration: "underline",
                  color: "#000",
                }}
              >
                Contact Number
              </span>
            </h3>

            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="supplierscontact"
                  label="Supplier's Contact Number"
                >
                  <Input placeholder="Enter" disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="buyerscontact"
                  label="Buyer's Contact Number"
                  rules={[{ required: true, message: "Please enter" }]}
                >
                  <Input placeholder="Enter" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              name="agreement1"
              valuePropName="checked"
              rules={[{ required: true, message: "Please select" }]}
            >
              <Checkbox>
                I hereby acknowledge that all the information provided is true
                and complete.
              </Checkbox>
            </Form.Item>

            <Form.Item
              name="agreement2"
              valuePropName="checked"
              rules={[{ required: true, message: "Please select" }]}
            >
              <Checkbox>
                I hereby agreed to{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    textUnderlineOffset: "4px",
                    color: "blue",
                  }}
                >
                  Daco
                </span>{" "}
                policy.
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" block>
                Create
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
};

export default EInvoiceForm;
