import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Input,
  Radio,
  Row,
  Select,
  Tabs,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Datatable from "../../components/Datatable";
import ServiceProductModal from "../../components/model/staff/performance/ServiceProductModal";

const { Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

interface Service {
  key: string;
  code: string;
  name: string;
  paymentMethod: string;
  turnoverDesignated: number;
  turnoverUndesignated: number;
  turnoverCustomType1: number;
  turnoverCustomType2: number;
}

interface Product {
  key: string;
  code: string;
  name: string;
  paymentMethod: string;
  turnover: number;
}

interface Privilege {
  key: string;
  code: string;
  name: string;
  type: string;
  price: string;
  value: string;
  sessions: string;
  discount: string;
  period: string;
  turnover: number; // Ensure this is included
  // Additional fields for Visit-based membership
  generalTurnover?: number; // For General settings
  customizedTurnover?: number; // For Customized settings
}

const CalculationSettings: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("1"); // Default tab: Service
  const [selectedSetting, setSelectedSetting] = useState("specific");
  const [selectedPrivilege, setSelectedPrivilege] = useState("prepaid");
  const [privilegeSetting, setPrivilegeSetting] = useState<
    "general" | "customized" | null
  >(null); // Track General/Customized
  const [isServiceModalVisible, setIsServiceModalVisible] = useState(false);
  const [isProductModalVisible, setIsProductModalVisible] = useState(false);
  const [isPrivilegeModalVisible, setIsPrivilegeModalVisible] = useState(false);

  // Sample data for services
  const [services, setServices] = useState([
    {
      key: "1",
      code: "TD101",
      name: "NEW SET EYELASH EXTENSION",
      paymentMethod: "Default",
      turnoverDesignated: 100,
      turnoverUndesignated: 100,
      turnoverCustomType1: 100,
      turnoverCustomType2: 100,
    },
    {
      key: "2",
      code: "TD102",
      name: "TOUCH UP EYELASH EXTENSION",
      paymentMethod: "Default",
      turnoverDesignated: 100,
      turnoverUndesignated: 100,
      turnoverCustomType1: 100,
      turnoverCustomType2: 0,
    },
    // ... (other services)
  ]);

  // Sample data for products
  const [products, setProducts] = useState([
    {
      key: "1",
      code: "CCM",
      name: "Cell Grow Sheet Mask",
      paymentMethod: "Default",
      turnover: 10,
    },
    // ... (other products)
  ]);

  // Sample data for privileges (initially empty, with Visit-based fields)
  const [privileges, setPrivileges] = useState<Privilege[]>([
    {
      key: "1",
      code: "VB001",
      name: "Visit-Based Plan 1",
      type: "Visit-based",
      price: "RM500",
      value: "RM600",
      sessions: "10",
      discount: "0%",
      period: "1 year",
      turnover: 100,
      generalTurnover: 100,
      customizedTurnover: 150,
    },
  ]);

  // Handle modal visibility
  const handleOpenServiceModal = () => setIsServiceModalVisible(true);
  const handleCloseServiceModal = () => setIsServiceModalVisible(false);
  const handleOpenProductModal = () => setIsProductModalVisible(true);
  const handleCloseProductModal = () => setIsProductModalVisible(false);
  const handleOpenPrivilegeModal = () => setIsPrivilegeModalVisible(true);
  const handleClosePrivilegeModal = () => setIsPrivilegeModalVisible(false);

  // Handle input changes for services
  const handleServiceChange = (
    index: number,
    field: keyof Service,
    value: string | number
  ) => {
    const updatedServices = [...services];
    (updatedServices[index][field] as typeof value) = value;
    setServices(updatedServices);
  };

  // Handle input changes for products
  const handleProductChange = (
    index: number,
    field: keyof Product,
    value: string | number
  ) => {
    const updatedProducts = [...products];
    (updatedProducts[index][field] as typeof value) = value;
    setProducts(updatedProducts);
  };

  // Handle input changes for privileges
  const handlePrivilegeChange = (
    index: number,
    field: keyof Privilege,
    value: string | number
  ) => {
    const updatedPrivileges = [...privileges];
    if (field === "turnover" && privilegeSetting) {
      (updatedPrivileges[index][
        privilegeSetting === "general"
          ? "generalTurnover"
          : "customizedTurnover"
      ] as typeof value) = value;
    } else {
      (updatedPrivileges[index][field] as typeof value) = value;
    }
    setPrivileges(updatedPrivileges);
  };

  // Handle delete
  const handleDelete = (index: number, type: string) => {
    if (type === "service") {
      setServices(services.filter((_, i) => i !== index));
    } else if (type === "product") {
      setProducts(products.filter((_, i) => i !== index));
    } else if (type === "privilege") {
      setPrivileges(privileges.filter((_, i) => i !== index));
    }
  };

  // Apply All functionality for services
  const handleApplyAll = (field: keyof Service, value: any) => {
    const updatedServices = services.map((service) => ({
      ...service,
      [field]: value,
    }));
    setServices(updatedServices);
  };

  // Apply All functionality for products
  const handleApplyAllProduct = (field: keyof Product, value: number) => {
    const updatedProducts = products.map((product) => ({
      ...product,
      [field]: value,
    }));
    setProducts(updatedProducts);
  };

  // Apply All functionality for privileges
  const handleApplyAllPrivilege = (field: keyof Privilege, value: number) => {
    const updatedPrivileges = privileges.map((privilege) => ({
      ...privilege,
      [field]: value,
    }));
    setPrivileges(updatedPrivileges);
  };

  // Datatable columns for Service tab
  const serviceColumns = [
    {
      title: "Service Code",
      dataIndex: "code",
      key: "code",
      editable: true,
      width: 100,
    },
    {
      title: "Service Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      width: 150,
      render: (text: string) => (
        <Text style={{ textWrap: "wrap" }}>{text}</Text>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      width: 200,
      editable: true,
      render: (text: string, record: Service, index: number) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Text>
            {text} <EditOutlined style={{ color: "blue" }} />
          </Text>
          {index === 0 && (
            <Button
              type="primary"
              onClick={() => handleApplyAll("paymentMethod", text)}
            >
              Apply All
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Performance Calculation (Turnover) - Designated",
      dataIndex: "turnoverDesignated",
      key: "turnoverDesignated",
      editable: true,
      width: 350,
      render: (text: number, record: Service, index: number) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Select
            defaultValue="Turnover"
            style={{ marginRight: "7px", width: "100px", height: "46px" }}
          >
            <Option value="Turnover">Turnover</Option>
          </Select>
          <Input
            type="number"
            style={{ height: "46px", width: "100px" }}
            value={text}
            onChange={(e) =>
              handleServiceChange(index, "turnoverDesignated", e.target.value)
            }
            suffix="%"
          />
          {index === 0 && (
            <Button
              type="primary"
              onClick={() => handleApplyAll("turnoverDesignated", text)}
            >
              Apply All
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Performance Calculation (Turnover) - Undesignated",
      dataIndex: "turnoverUndesignated",
      key: "turnoverUndesignated",
      editable: true,
      width: 350,
      render: (text: number, record: Service, index: number) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Select
            defaultValue="Turnover"
            style={{ marginRight: "7px", width: "100px", height: "46px" }}
          >
            <Option value="Turnover">Turnover</Option>
          </Select>
          <Input
            type="number"
            style={{ height: "46px", width: "100px" }}
            value={text}
            onChange={(e) =>
              handleServiceChange(index, "turnoverUndesignated", e.target.value)
            }
            suffix="%"
          />
          {index === 0 && (
            <Button
              type="primary"
              onClick={() => handleApplyAll("turnoverUndesignated", text)}
            >
              Apply All
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Performance Calculation (Turnover) - CUSTOM_TYPE1",
      dataIndex: "turnoverCustomType1",
      key: "turnoverCustomType1",
      editable: true,
      width: 350,
      render: (text: number, record: Service, index: number) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Select
            defaultValue="Turnover"
            style={{ marginRight: "7px", width: "100px", height: "46px" }}
          >
            <Option value="Turnover">Turnover</Option>
          </Select>
          <Input
            type="number"
            style={{ height: "46px", width: "100px" }}
            value={text}
            onChange={(e) =>
              handleServiceChange(index, "turnoverCustomType1", e.target.value)
            }
            suffix="%"
          />
          {index === 0 && (
            <Button
              type="primary"
              onClick={() => handleApplyAll("turnoverCustomType1", text)}
            >
              Apply All
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Performance Calculation (Turnover) - CUSTOM_TYPE2",
      dataIndex: "turnoverCustomType2",
      key: "turnoverCustomType2",
      editable: true,
      width: 350,
      render: (text: number, record: Service, index: number) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Select
            defaultValue="Turnover"
            style={{ marginRight: "7px", width: "100px", height: "46px" }}
          >
            <Option value="Turnover">Turnover</Option>
          </Select>
          <Input
            type="number"
            style={{ height: "46px", width: "100px" }}
            value={text}
            onChange={(e) =>
              handleServiceChange(index, "turnoverCustomType2", e.target.value)
            }
            suffix="%"
          />
          {index === 0 && (
            <Button
              type="primary"
              onClick={() => handleApplyAll("turnoverCustomType2", text)}
            >
              Apply All
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right" as "right",
      width: 90,
      render: (_: any, record: Service, index: number) => (
        <Button
          type="link"
          danger
          onClick={() => handleDelete(index, "service")}
        >
          Delete
        </Button>
      ),
    },
  ];

  // Datatable columns for Product tab
  const productColumns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      editable: true,
      width: 100,
    },
    {
      title: "Product Name",
      dataIndex: "name",
      key: "name",
      editable: true,
      width: 150,
      render: (text: string) => (
        <Text style={{ textWrap: "wrap" }}>{text}</Text>
      ),
    },
    {
      title: "Payment Method",
      dataIndex: "paymentMethod",
      key: "paymentMethod",
      editable: true,
      width: 200,
      render: (text: string, record: Product, index: number) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Text>
            {text} <EditOutlined style={{ color: "blue" }} />
          </Text>
          {index === 0 && (
            <Button
              type="primary"
              onClick={() => handleApplyAll("paymentMethod", text)}
            >
              Apply All
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Performance Calculation (Turnover)",
      dataIndex: "turnover",
      key: "turnover",
      width: 350,
      editable: true,
      render: (text: number, record: Product, index: number) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Select
            defaultValue="Turnover"
            style={{ marginRight: "7px", width: "100px", height: "46px" }}
          >
            <Option value="Turnover">Turnover</Option>
          </Select>
          <Input
            type="number"
            style={{ height: "46px", width: "100px" }}
            value={text}
            onChange={(e) =>
              handleProductChange(index, "turnover", e.target.value)
            }
            suffix="%"
          />
          {index === 0 && (
            <Button
              type="primary"
              onClick={() => handleApplyAllProduct("turnover", text)}
            >
              Apply All
            </Button>
          )}
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right" as "right",
      width: 90,
      render: (_: any, record: Product, index: number) => (
        <Button
          type="link"
          danger
          onClick={() => handleDelete(index, "product")}
        >
          Delete
        </Button>
      ),
    },
  ];

  // Datatable columns for Privilege tab (General and Customized)
  const generalPrivilegeColumns = [
    { title: "Code", dataIndex: "code", key: "code", width: 100 },
    { title: "Privilege Name", dataIndex: "name", key: "name", width: 150 },
    {
      title: "Payment Method",
      dataIndex: "type",
      key: "type",
      width: 200,
      render: (text: string) => (
        <Text>
          {text} <EditOutlined style={{ color: "blue" }} />
        </Text>
      ),
    },
    {
      title: "Performance Calculation",
      dataIndex: "generalTurnover",
      key: "generalTurnover",
      width: 300,
      editable: true,
      render: (text: number, record: Privilege, index: number) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Select
            defaultValue="Turnover"
            style={{ marginRight: "7px", width: "100px", height: "46px" }}
          >
            <Option value="Turnover">Turnover</Option>
          </Select>
          <Input
            type="number"
            style={{ height: "46px", width: "100px" }}
            value={text || 100}
            onChange={(e) =>
              handlePrivilegeChange(
                index,
                "generalTurnover",
                parseInt(e.target.value) || 100
              )
            }
            suffix="%"
          />
        </div>
      ),
    },
    {
      title: "Service redemption",
      dataIndex: "serviceredemption",
      key: "serviceredemption",
      width: 200,
      render: (text: string) => <Text>{text}</Text>,
    },
    {
      title: "Action",
      key: "action",
      fixed: "right" as "right",
      width: 90,
      render: (_: any, record: Privilege, index: number) => (
        <Button
          type="link"
          danger
          onClick={() => handleDelete(index, "privilege")}
        >
          Delete
        </Button>
      ),
    },
  ];

  const customizedPrivilegeColumns = [
    { title: "Code", dataIndex: "code", key: "code", width: 100 },
    { title: "Service Name", dataIndex: "name", key: "name", width: 150 },
    {
      title: "Payment Method",
      dataIndex: "type",
      key: "type",
      width: 200,
      render: (text: string) => (
        <Text>
          {text} <EditOutlined style={{ color: "blue" }} />
        </Text>
      ),
    },
    {
      title: "Performance Calculation",
      dataIndex: "customizedTurnover",
      key: "customizedTurnover",
      width: 350,
      editable: true,
      render: (text: number, record: Privilege, index: number) => (
        <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
          <Select
            defaultValue="Turnover"
            style={{ marginRight: "7px", width: "100px", height: "46px" }}
          >
            <Option value="Turnover">Turnover</Option>
          </Select>
          <Input
            type="number"
            style={{ height: "46px", width: "100px" }}
            value={text || 100}
            onChange={(e) =>
              handlePrivilegeChange(
                index,
                "customizedTurnover",
                parseInt(e.target.value) || 100
              )
            }
            suffix="%"
          />
        </div>
      ),
    },
    {
      title: "Action",
      key: "action",
      fixed: "right" as "right",
      width: 90,
      render: (_: any, record: Privilege, index: number) => (
        <Button
          type="link"
          danger
          onClick={() => handleDelete(index, "privilege")}
        >
          Delete
        </Button>
      ),
    },
  ];

  const currentPrivilegeColumns =
    privilegeSetting === "customized"
      ? customizedPrivilegeColumns
      : generalPrivilegeColumns;

  return (
    <div style={{ backgroundColor: "#f0f3f8", padding: "12px 22px" }}>
      <Content>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: 16 }}
        >
          <Col>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowLeftOutlined
                onClick={() => navigate(-1)}
                style={{
                  marginRight: 12,
                  color: "#325DF2",
                  fontSize: 18,
                  cursor: "pointer",
                }}
              />
              <h2 style={{ margin: 0 }}>
                Performance Plan (001 - Full Time Staff)
              </h2>
            </div>
          </Col>
        </Row>

        <Content
          style={{
            backgroundColor: "#f6f7f9",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            marginBottom: "50px",
          }}
        >
          <style>{`:where(.css-dev-only-do-not-override-161f05s).ant-radio-button-wrapper:not(:first-child)::before { width: 0px; }`}</style>
          <Tabs activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab="Service" key="1">
              {/* Service tab content */}
              <div>
                <div>
                  <div
                    style={{
                      border: "1px solid #ebeff7",
                      padding: "5px",
                      marginBottom: "20px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                      width: "355px",
                    }}
                  >
                    <Radio.Group
                      value={selectedSetting}
                      onChange={(e) => setSelectedSetting(e.target.value)}
                    >
                      <Radio.Button
                        value="specific"
                        style={{
                          border: "none",
                          color:
                            selectedSetting === "specific"
                              ? "#324fdb"
                              : "black",
                          backgroundColor:
                            selectedSetting === "specific"
                              ? "#f6f5fa"
                              : "white",
                          fontWeight:
                            selectedSetting === "specific" ? "bold" : "normal",
                        }}
                      >
                        Setting by specific service
                      </Radio.Button>
                      <Radio.Button
                        value="category"
                        style={{
                          border: "none",
                          color:
                            selectedSetting === "category"
                              ? "#324fdb"
                              : "black",
                          backgroundColor:
                            selectedSetting === "category"
                              ? "#f6f5fa"
                              : "white",
                          fontWeight:
                            selectedSetting === "category" ? "bold" : "normal",
                        }}
                      >
                        Setting by category
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                  {selectedSetting === "specific" && (
                    <div style={{ marginBottom: "20px" }}>
                      <Radio.Group>
                        <Text style={{ marginRight: "10px" }}>Staff type:</Text>
                        <Radio value="Service type 1">Service type 1</Radio>
                        <Radio value="Service type 2">Service type 2</Radio>
                        <Radio value="Service type 3">Service type 3</Radio>
                        <Radio value="Service type 4">Service type 4</Radio>
                      </Radio.Group>
                    </div>
                  )}
                  {selectedSetting === "category" && (
                    <div style={{ marginBottom: "20px" }}>
                      <Radio.Group>
                        <Text style={{ marginRight: "10px" }}>Staff type:</Text>
                        <Radio value="Category type 1">Category type 1</Radio>
                        <Radio value="Category type 2">Category type 2</Radio>
                        <Radio value="Category type 3">Category type 3</Radio>
                        <Radio value="Category type 4">Category type 4</Radio>
                      </Radio.Group>
                    </div>
                  )}
                </div>
                <Datatable<Service>
                  columns={serviceColumns}
                  dataSource={services}
                  scroll={{ y: "calc(100vh - 260px)" }}
                  rowSelection={{}}
                  showPagination={false}
                  dynamicTableHeight="calc(100vh - 240px)"
                />
                <br />
                <div style={{ textAlign: "left" }}>
                  <Button
                    style={{
                      border: "none",
                      background: "none",
                      color: "orange",
                    }}
                    onClick={handleOpenServiceModal}
                  >
                    + Add to the add service
                  </Button>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Product" key="2">
              {/* Product tab content */}
              <div>
                <div
                  style={{
                    border: "1px solid #ebeff7",
                    padding: "5px",
                    marginBottom: "20px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    width: "355px",
                  }}
                >
                  <Radio.Group
                    value={selectedSetting}
                    onChange={(e) => setSelectedSetting(e.target.value)}
                  >
                    <Radio.Button
                      value="specific"
                      style={{
                        border: "none",
                        color:
                          selectedSetting === "specific" ? "#324fdb" : "black",
                        backgroundColor:
                          selectedSetting === "specific" ? "#f6f5fa" : "white",
                        fontWeight:
                          selectedSetting === "specific" ? "bold" : "normal",
                      }}
                    >
                      Setting by specific service
                    </Radio.Button>
                    <Radio.Button
                      value="category"
                      style={{
                        border: "none",
                        color:
                          selectedSetting === "category" ? "#324fdb" : "black",
                        backgroundColor:
                          selectedSetting === "category" ? "#f6f5fa" : "white",
                        fontWeight:
                          selectedSetting === "category" ? "bold" : "normal",
                      }}
                    >
                      Setting by category
                    </Radio.Button>
                  </Radio.Group>
                </div>
                <div>
                  <style>{`.ant-table-body table { background: none; }`}</style>
                  <Datatable
                    columns={productColumns}
                    dataSource={products}
                    scroll={{ y: "calc(100vh - 260px)" }}
                    rowSelection={{}}
                    showPagination={false}
                    dynamicTableHeight="calc(100vh - 240px)"
                  />
                </div>
                <br />
                <div style={{ textAlign: "left" }}>
                  <Button
                    style={{
                      border: "none",
                      background: "none",
                      color: "orange",
                    }}
                    onClick={handleOpenProductModal}
                  >
                    + Add to the add product
                  </Button>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Privilege" key="3">
              <div>
                <div
                  style={{
                    border: "1px solid #ebeff7",
                    padding: "5px",
                    marginBottom: "20px",
                    backgroundColor: "white",
                    borderRadius: "8px",
                    width: "100%",
                    maxWidth: "790px",
                  }}
                >
                  <Radio.Group
                    value={selectedPrivilege}
                    onChange={(e) => {
                      setSelectedPrivilege(e.target.value);
                      if (e.target.value === "visit-based") {
                        setPrivilegeSetting("general"); // Default to General when Visit-based is selected
                      } else {
                        setPrivilegeSetting(null);
                      }
                    }}
                    style={{ display: "flex", flexWrap: "wrap", gap: "5px" }}
                  >
                    {[
                      { value: "prepaid", label: "Prepaid membership" },
                      { value: "discount", label: "Discount membership" },
                      { value: "visit-based", label: "Visit-based membership" },
                      { value: "voucher", label: "Voucher" },
                      { value: "vouchers", label: "Vouchers" },
                      { value: "package", label: "Package" },
                    ].map(({ value, label }) => (
                      <Radio.Button
                        key={value}
                        value={value}
                        style={{
                          border: "none",
                          color:
                            selectedPrivilege === value ? "#324fdb" : "black",
                          backgroundColor:
                            selectedPrivilege === value ? "#f6f5fa" : "white",
                          fontWeight:
                            selectedPrivilege === value ? "bold" : "normal",
                        }}
                      >
                        {label}
                      </Radio.Button>
                    ))}
                  </Radio.Group>
                </div>
                {selectedPrivilege === "visit-based" && (
                  <div
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Button
                      style={{
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: `${
                          privilegeSetting === "general" ? "#4b35ea" : "white"
                        }`,
                        color: `${
                          privilegeSetting === "general" ? "white" : "black"
                        }`,
                        width: "120px",
                      }}
                      onClick={() => setPrivilegeSetting("general")}
                    >
                      General
                    </Button>
                    <Button
                      style={{
                        borderRadius: "8px 8px 0px 0px",
                        backgroundColor: `${
                          privilegeSetting === "customized"
                            ? "#4b35ea"
                            : "white"
                        }`,
                        color: `${
                          privilegeSetting === "customized" ? "white" : "black"
                        }`,
                        width: "120px",
                      }}
                      onClick={() => setPrivilegeSetting("customized")}
                    >
                      Customized
                    </Button>
                  </div>
                )}
                <Datatable
                  columns={currentPrivilegeColumns}
                  dataSource={privileges}
                  scroll={{ y: "calc(100vh - 260px)" }}
                  showCheckbox
                  rowSelection={{}}
                  showPagination={false}
                  dynamicTableHeight="calc(100vh - 240px)"
                />
                <br />
                <div style={{ textAlign: "left" }}>
                  <Button
                    style={{
                      border: "none",
                      background: "none",
                      color: "orange",
                    }}
                    onClick={handleOpenPrivilegeModal}
                  >
                    + Add to the add privilege
                  </Button>
                </div>
              </div>
            </TabPane>
          </Tabs>

          <Divider />

          <Row style={{ paddingBottom: "16px" }}>
            <Col>
              <Button>Copy & Distribute</Button>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
              <Button type="primary">Save</Button>
            </Col>
          </Row>
        </Content>
      </Content>

      <ServiceProductModal
        isServiceModalVisible={isServiceModalVisible}
        isProductModalVisible={isProductModalVisible}
        isPrivilegeModalVisible={isPrivilegeModalVisible}
        handleCloseServiceModal={handleCloseServiceModal}
        handleCloseProductModal={handleCloseProductModal}
        handleClosePrivilegeModal={handleClosePrivilegeModal}
        setServices={setServices}
        setProducts={setProducts}
        setPrivileges={setPrivileges}
        services={services}
        products={products}
        privileges={privileges}
      />
    </div>
  );
};

export default CalculationSettings;
