import React from "react";
import { Card, Row, Col, Typography, Button, Divider } from "antd";
import { LeftOutlined, ClearOutlined } from "@ant-design/icons";

const notificationIcon = `${process.env.PUBLIC_URL}/images/logo/notification-icon.png`;
const { Title, Text } = Typography;

const notifications = [
  {
    id: 1,
    title: "Stock out",
    message: "Your Stock Out application has been approved!",
    time: "15/02/2025 15:50",
    icon: notificationIcon, // Placeholder image icon, replace with yours
  },
  {
    id: 2,
    title: "Stock out",
    message: "Your Stock Out application has been approved!",
    time: "15/02/2025 15:26",
    icon: notificationIcon, // Placeholder image icon, replace with yours
  },
];

const NotificationPage = () => {
  return (
    <div
      style={{
        backgroundColor: "#F6F6F9",
        minHeight: "100vh",
        padding: "16px",
      }}
    >
      {/* Header */}
      <Row align="middle" justify="space-between" style={{ marginBottom: 16 }}>
        <Col span={2}>
          <Button
            type="text"
            icon={<LeftOutlined style={{ color: "#2c2c2c" }} />}
          />
        </Col>
        <Col span={22} style={{ display: "flex", justifyContent: "end" }}>
          <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
            <Title level={5} style={{ margin: 0, color: "#303030" }}>
              Notifications
            </Title>
            <Button
              type="text"
              icon={<ClearOutlined style={{ transform: "rotate(32deg)" }} />}
              style={{ color: "#3d3d3d" }}
            >
              Read all
            </Button>
          </div>
        </Col>
      </Row>

      {/* Notification List */}
      {notifications.map((item) => (
        <Card
          key={item.id}
          style={{
            marginBottom: "12px",
            borderRadius: "8px",
            boxShadow: "0px 4px 12px rgba(0,0,0,0.05)",
          }}
          bodyStyle={{ padding: "12px" }}
        >
          <Row justify="space-between" align="top">
            {/* Icon + Title */}
            <Col span={12}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={item.icon}
                  alt="icon"
                  style={{
                    width: "24px",
                    height: "24px",
                    marginRight: "8px",
                  }}
                />
                <Text strong style={{ fontSize: "14px" }}>
                  {item.title}
                </Text>
              </div>
            </Col>

            {/* Time */}
            <Col span={12} style={{ textAlign: "right" }}>
              <Text
                type="secondary"
                style={{ fontSize: "12px", color: "#c4c4c4" }}
              >
                {item.time}
              </Text>
            </Col>
          </Row>

          {/* Message */}
          <Row style={{ marginTop: "8px" }}>
            <Col span={24}>
              <Text
                style={{ fontSize: "14px", color: "#474747" }}
                className="normalbreak"
              >
                {item.message}
              </Text>
            </Col>
          </Row>
        </Card>
      ))}

      {/* No More Data Footer */}
      <Row justify="center" style={{ padding: "12px 0" }}>
        <Text type="secondary" style={{ fontSize: "12px" }}>
          No more data
        </Text>
      </Row>
    </div>
  );
};

export default NotificationPage;
