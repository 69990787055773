import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Radio,
  Row,
  Select,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const { Option } = Select;
const { TextArea } = Input;

interface CommissionPlanData {
  plancode: string;
  planname: string;
  staffType: string;
  note: string;
  serviceSettings: string[]; // Array of selected service settings
  productSettings: string[]; // Array of selected product settings
  privilegeSettings: string[]; // Array of selected privilege settings
}

const AddCommissionPlan: React.FC = () => {
  const navigate = useNavigate();
  const [selectedSetting, setSelectedSetting] = useState<string>("setting");
  const [showAdditionalCommission, setShowAdditionalCommission] =
    useState(false);
  const { id } = useParams<{ id: string }>(); // Get the ID from the URL to determine Add/Edit mode
  const [form] = Form.useForm();
  const isEditMode = !!id; // If ID exists, it's Edit mode; otherwise, Add mode

  // Sample data for Edit mode (you can fetch this from an API in a real application)
  const [initialData, setInitialData] = useState<CommissionPlanData | null>(
    null
  );

  useEffect(() => {
    if (isEditMode) {
      // Simulate fetching data for the given ID
      const fetchedData: CommissionPlanData = {
        plancode: "001",
        planname: "Full time staff",
        staffType: "Full Time Staff",
        note: "This is a sample note for full-time staff commission plan.",
        serviceSettings: ["specificService"], // Array of selected values
        productSettings: ["specificProduct"], // Array of selected values
        privilegeSettings: ["prepaidMembership", "visitBasedMembership"], // Array of selected values
      };
      setInitialData(fetchedData);
      form.setFieldsValue(fetchedData);
    }
  }, [id, form]);

  const onFinish = (values: CommissionPlanData) => {
    console.log("Form values:", values);
    // Handle form submission (e.g., API call to save or update the data)
    if (isEditMode) {
      // Update existing plan
      console.log(`Updating commission plan with ID ${id}`, values);
    } else {
      // Add new plan
      console.log("Adding new commission plan", values);
    }
    navigate("/settings/performance/plan"); // Navigate back to the CommissionPlan page
  };

  return (
    <div style={{ backgroundColor: "#f0f3f8", padding: "12px 22px" }}>
      <Content>
        {/* Header Section */}
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: 16 }}
        >
          <Col>
            <div style={{ display: "flex", alignItems: "center" }}>
              <ArrowLeftOutlined
                onClick={() => navigate(-1)}
                style={{
                  marginRight: 12,
                  color: "#325DF2",
                  fontSize: 18,
                  cursor: "pointer",
                }}
              />
              <h2 style={{ margin: 0 }}>
                {isEditMode ? "Edit Commission Plan" : "Add Commission Plan"}
              </h2>
            </div>
          </Col>
        </Row>

        {/* Form Section */}
        <Content
          style={{
            backgroundColor: "#f6f7f9",
            borderRadius: "12px",
            padding: "24px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
            marginBottom: "50px",
          }}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            initialValues={{
              serviceSettings: [],
              productSettings: [],
              privilegeSettings: [],
            }}
          >
            {/* Basic Info Section */}
            <h3 style={{ marginBottom: 16 }}>Basic info</h3>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Form.Item
                  label="Plan code"
                  name="plancode"
                  rules={[
                    { required: true, message: "Please enter the plan code" },
                  ]}
                >
                  <Input placeholder="Enter plan code" allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Plan name"
                  name="planname"
                  rules={[
                    { required: true, message: "Please enter the plan name" },
                  ]}
                >
                  <Input placeholder="Enter plan name" allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Form.Item label="Note" name="note">
                  <TextArea rows={4} placeholder="Enter note" />
                </Form.Item>
              </Col>
            </Row>

            {/* Plan Settings Section */}
            <h3 style={{ marginBottom: 20 }}>Plan settings</h3>
            <Row>
              <Col>
                <Radio.Group
                  defaultValue="setting"
                  value={selectedSetting}
                  onChange={(e) => setSelectedSetting(e.target.value)}
                >
                  <Radio value="setting">
                    Setting by service / product / privilege
                  </Radio>
                  <Radio value="individual">Individual's performance</Radio>
                  <Radio value="store">Store performance</Radio>
                </Radio.Group>
              </Col>
            </Row>

            {selectedSetting === "setting" && (
              <Row
                style={{
                  marginTop: 16,
                  padding: 20,
                  backgroundColor: "white",
                  borderRadius: "8px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                }}
              >
                {/* Service Section */}
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Col>
                    <h4 style={{ margin: 0, marginRight: 15 }}>Service</h4>
                  </Col>
                  <Col flex="auto">
                    <Radio.Group style={{ display: "flex", gap: "10px" }}>
                      <Radio value="specificService">
                        Setting by specific service
                      </Radio>
                      <Radio value="category">Setting by slab</Radio>
                    </Radio.Group>
                  </Col>
                </Row>

                {/* Product Section */}
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Col>
                    <h4 style={{ margin: 0, marginRight: 12 }}>Product</h4>
                  </Col>
                  <Col flex="auto">
                    <Radio.Group style={{ display: "flex", gap: "10px" }}>
                      <Radio value="specificProduct">
                        Setting by specific product
                      </Radio>
                      <Radio value="category">Setting by slab</Radio>
                    </Radio.Group>
                  </Col>
                </Row>

                {/* Privilege Section */}
                <Row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "10px",
                  }}
                >
                  <Col>
                    <h4 style={{ margin: 0, marginRight: 9 }}>Privilege</h4>
                  </Col>
                  <Col flex="auto">
                    <Radio.Group style={{ display: "flex", gap: "10px" }}>
                      <Radio value="specificPrivilege">
                        Setting by specific privilege
                      </Radio>
                      <Radio value="category">Setting by slab</Radio>
                    </Radio.Group>
                  </Col>
                </Row>
              </Row>
            )}

            {/* Separate Commission */}
            <Row
              style={{
                marginTop: 12,
                backgroundColor: "white",
                padding: 20,
                borderRadius: "8px",
              }}
            >
              <Col>
                <Checkbox>Separate commission</Checkbox>
              </Col>
            </Row>

            {/* Additional Commission */}
            <Row
              style={{
                marginTop: 12,
                backgroundColor: "white",
                padding: 20,
                borderRadius: "8px",
              }}
            >
              <Col>
                <Checkbox
                  checked={showAdditionalCommission}
                  onChange={(e) =>
                    setShowAdditionalCommission(e.target.checked)
                  }
                >
                  Additional commission
                </Checkbox>
              </Col>

              {/* Show Service/Product options only if Additional Commission is checked */}
              {showAdditionalCommission && (
                <Row
                  style={{
                    marginTop: 12,
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "15px",
                  }}
                >
                  <Col>
                    <Checkbox>Service</Checkbox>
                  </Col>
                  <Col>
                    <Checkbox>Product</Checkbox>
                  </Col>
                  <Col>
                    <Checkbox>Prepaid membership</Checkbox>
                  </Col>
                  <Col>
                    <Checkbox>Discount membership</Checkbox>
                  </Col>
                  <Col>
                    <Checkbox>Visit-based membership</Checkbox>
                  </Col>
                  <Col>
                    <Checkbox>Voucher</Checkbox>
                  </Col>
                  <Col>
                    <Checkbox>Vouchers</Checkbox>
                  </Col>
                  <Col>
                    <Checkbox>Package</Checkbox>
                  </Col>
                </Row>
              )}
            </Row>

            <Row
              style={{
                marginTop: 12,
                padding: 20,
                backgroundColor: "white",
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <Col>
                <h4 style={{ margin: 0, marginRight: 9 }}>
                  Slab commission calculation:
                </h4>
              </Col>
              <Col flex="auto">
                <Radio.Group style={{ display: "flex", gap: "10px" }}>
                  <Radio value="highestslabcalculation">
                    Highest Slab calculation
                  </Radio>
                  <Radio value="slabbyslab">Slab-by-slab calculation</Radio>
                </Radio.Group>
              </Col>
            </Row>

            {/* Form Actions */}
            <Divider />
            <Row style={{ marginTop: 24 }}>
              <Col style={{ marginLeft: "auto" }}>
                <Button
                  style={{ marginRight: 10 }}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button type="primary" htmlType="submit">
                  {isEditMode ? "Save" : "Next"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Content>
      </Content>
    </div>
  );
};

export default AddCommissionPlan;
