import { Col, Layout, Row, Typography } from "antd";
import { Helmet } from "react-helmet";
import { getPermissions } from "../../layouts/permissionsHelper";
import ReportCard from "./ReportCard";

const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const StockInInventoryReport = `${process.env.PUBLIC_URL}/images/logo/stock-in-inventory-report.png`;
const StockOutInventoryReport = `${process.env.PUBLIC_URL}/images/logo/stock-out-inventory-report.png`;
const StockInOutInventoryReport = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Report_Stock_InOut_Detail.png`;
const expiryInventoryReport = `${process.env.PUBLIC_URL}/images/logo/expiry-inventory-report.png`;
const tipSummaryReport = `${process.env.PUBLIC_URL}/images/logo/tips-summary-report.png`;
const tipDetailReport = `${process.env.PUBLIC_URL}/images/logo/tips-detail-report.png`;
const accountingReport = `${process.env.PUBLIC_URL}/images/logo/accounting-report.png`;
const billsummaryReport = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Report_Transaction_Detail.png`;
const workingHourSummaryReport = `${process.env.PUBLIC_URL}/images/logo/working-hour-summary-report.png`;
const salesSummaryReport = `${process.env.PUBLIC_URL}/images/logo/sales-summary-report.png`;
const settlementSummaryReport = `${process.env.PUBLIC_URL}/images/logo/settlement-summary-report.png`;
const { Content } = Layout;
const { Title } = Typography;

const reportsSections = [
  {
    title: "Sales",
    reports: [
      {
        title: "Accounting Report",
        link: "/reports/accountingReport",
        permissionKey: "accountingReport",
        Image: accountingReport,
      },
      {
        title: "Transaction Details Report",
        link: "/reports/billsummary",
        permissionKey: "billSummary",
        Image: billsummaryReport,
      },
      {
        title: "Sales Summary Report",
        link: "/reports/salessummary",
        permissionKey: "salesSummary",
        Image: salesSummaryReport,
      },
      {
        title: "Settlement Sheet Summary",
        link: "/reports/settlementsummary",
        permissionKey: "settlementsummary",
        Image: settlementSummaryReport,
      },
      // {
      //   title: "Loyalty Points",
      //   link: "/reports/loyalty-points",
      //   bgColor: "#df2900",
      //   permissionKey: "loyaltyPoints",
      //   icon: <GiftOutlined style={{ fontSize: "24px", color: "#fff" }} />,
      // },
      // {
      //   title: "Remaining Points",
      //   link: "/reports/remaining-points",
      //   bgColor: "#df2900",
      //   permissionKey: "remainingPoints",
      //   icon: <TagOutlined style={{ fontSize: "24px", color: "#fff" }} />,
      // },
      // {
      //   title: "Profit & Loss",
      //   link: "/reports/profit-loss",
      //   bgColor: "#df2900",
      //   permissionKey: "profitLoss",
      //   icon: <DollarOutlined style={{ fontSize: "24px", color: "#fff" }} />,
      // },
      // {
      //   title: "Profit & Loss (Detail)",
      //   link: "/reports/profit-loss-detail",
      //   bgColor: "#df2900",
      //   permissionKey: "profitLossDetail",
      //   icon: <DollarOutlined style={{ fontSize: "24px", color: "#fff" }} />,
      // },
    ],
  },
  //   {
  //     title: "Customer",
  //     reports: [
  //       {
  //         title: "Customer Information Sheet",
  //         link: "/reports/staff-summary",
  //         bgColor: "#fab21a",
  //         permissionKey: "staffSummary",
  //         icon: <TeamOutlined style={{ fontSize: "24px", color: "#fff" }} />,
  //       },
  //       {
  //         title: "Loyalty Point Summary",
  //         link: "/reports/staff-details",
  //         bgColor: "#fab21a",
  //         permissionKey: "staffDetails",
  //         icon: <UserOutlined style={{ fontSize: "24px", color: "#fff" }} />,
  //       },
  //       {
  //         title: "Remaining Privillage Summary",
  //         link: "/reports/service-summary",
  //         bgColor: "#fab21a",
  //         permissionKey: "serviceSummary",
  //         icon: <BarChartOutlined style={{ fontSize: "24px", color: "#fff" }} />,
  //       },
  //       {
  //         title: "Remaining Privillage Details",
  //         link: "/reports/working-hours",
  //         bgColor: "#fab21a",
  //         permissionKey: "workingHours",
  //         icon: (
  //           <ClockCircleOutlined style={{ fontSize: "24px", color: "#fff" }} />
  //         ),
  //       },
  //       {
  //         title: "Gift Card sales Report",
  //         link: "/reports/tip-summary",
  //         bgColor: "#fab21a",
  //         permissionKey: "tipSummary",
  //         icon: <DollarOutlined style={{ fontSize: "24px", color: "#fff" }} />,
  //       },
  //     ],
  //   },
  {
    title: "Staff",
    reports: [
      //   {
      //     title: "Staff Summary",
      //     link: "/reports/staff-summary",
      //     bgColor: "#2d56ed",
      //     permissionKey: "staffSummary",
      //     icon: <TeamOutlined style={{ fontSize: "24px", color: "#fff" }} />,
      //   },
      //   {
      //     title: "Staff Details",
      //     link: "/reports/staff-details",
      //     bgColor: "#2d56ed",
      //     permissionKey: "staffDetails",
      //     icon: <UserOutlined style={{ fontSize: "24px", color: "#fff" }} />,
      //   },
      //   {
      //     title: "Service Summary",
      //     link: "/reports/service-summary",
      //     bgColor: "#2d56ed",
      //     permissionKey: "serviceSummary",
      //     icon: <BarChartOutlined style={{ fontSize: "24px", color: "#fff" }} />,
      //   },
      {
        title: "Working Hours Summary",
        link: "/reports/workinghoursummary",
        permissionKey: "workingHours",
        Image: workingHourSummaryReport,
      },
      {
        title: "Tip Summary",
        link: "/reports/tipsummary",
        permissionKey: "tipSummary",
        Image: tipSummaryReport,
      },
      {
        title: "Tip Details",
        link: "/reports/tipdetails",
        permissionKey: "tipDetails",
        Image: tipDetailReport,
      },
      //   {
      //     title: "Staff Loyalty",
      //     link: "/reports/staff-loyalty",
      //     bgColor: "#2d56ed",
      //     permissionKey: "staffLoyalty",
      //     icon: <GiftOutlined style={{ fontSize: "24px", color: "#fff" }} />,
      //   },
    ],
  },
  {
    title: "Inventory",
    reports: [
      {
        title: "Stock-in Inventory Report",
        link: "/reports/stockininventory",
        permissionKey: "stockininventory",
        Image: StockInInventoryReport,
      },
      {
        title: "Stock-out Inventory Report",
        link: "/reports/stockoutinventory",
        permissionKey: "stockoutinventory",
        Image: StockOutInventoryReport,
      },
      {
        title: "Stock In/Out Detail Report",
        link: "/reports/stockinoutinventory",
        permissionKey: "stockSummary",
        Image: StockInOutInventoryReport,
      },
      {
        title: "Expiry Inventory Report",
        link: "/reports/expiryinventory",
        permissionKey: "expiryInventory",
        Image: expiryInventoryReport,
      },
    ],
  },
];

function Reports() {
  const userRole = localStorage.getItem("userRole");

  // Function to check if card should be displayed
  const isCardVisible = (permissionKey: string) => {
    if (userRole !== "Staff") return true;
    return getPermissions()[permissionKey] || false;
  };

  // Function to check if a section has visible cards
  const isSectionVisible = (section: any) => {
    return section.reports.some((report: any) =>
      isCardVisible(report.permissionKey)
    );
  };

  // Render reports Sections
  const renderReportsSection = ({ title, reports }: any, index: any) => (
    <div key={index}>
      <Title level={5} style={{ marginBottom: "16px", color: "#b1b0c1" }}>
        {title}
      </Title>
      <Row gutter={[12, 12]}>
        {reports
          .filter((report: any) => isCardVisible(report.permissionKey))
          .map((report: any, idx: any) => (
            <Col
              key={idx}
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <ReportCard
                title={report.title}
                link={report.link}
                bgColor={report.bgColor}
                Image={report.Image}
              />
            </Col>
          ))}
      </Row>
    </div>
  );

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Layout>
        <Row justify="space-between" align="middle">
          <Col>
            <h2>
              <span className="vertical-line"></span>Report
            </h2>
          </Col>
        </Row>

        <Content
          style={{
            backgroundColor: "white",
            borderRadius: "12px",
            padding: "0px 24px 24px 24px",
            border: "1px solid #e0e7fe",
            marginTop: "16px",
            height: "calc(100vh - 180px)",
            overflowY: "auto",
          }}
        >
          {reportsSections.filter(isSectionVisible).map(renderReportsSection)}
        </Content>
      </Layout>
    </>
  );
}

export default Reports;
