import React, { useEffect, useState } from "react";
import { Modal, Checkbox, Button } from "antd";

const SelectCategoryModal = ({
  visible,
  onClose,
  onSave,
  selectedCategories = [],
  categoriesList = [],
}: any) => {
  const [selected, setSelected] = useState<{ id: any; name: any }[]>([]);

  useEffect(() => {
    if (visible) {
      setSelected(
        selectedCategories.map((category: any) => ({
          id: category.categoryid,
          name: category.categoryname,
        }))
      );
    }
  }, [visible, selectedCategories]);

  const handleCategoryChange = (category: any, checked: boolean) => {
    setSelected((prev) =>
      checked
        ? [...prev, { id: category.id, name: category.name }]
        : prev.filter((item) => item.id !== category.id)
    );
  };

  const handleSave = () => {
    onSave(selected);
    onClose();
  };

  return (
    <Modal
      title="Select Category"
      open={visible}
      onCancel={onClose}
      footer={
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
          }}
        >
          <span>
            Selected: {selected.length}{" "}
            {selected.length === 1 ? "item" : "items"}
          </span>
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        </div>
      }
    >
      <div style={{ maxHeight: "300px", overflowY: "auto", padding: "10px" }}>
        {categoriesList.map((category: any) => (
          <div
            key={category.id}
            style={{
              padding: "10px",
              marginBottom: "10px",
              backgroundColor: "#f5f8ff",
              border: "1px solid #e0e7fe",
              borderRadius: "6px",
            }}
          >
            <Checkbox
              checked={selected.some((item) => item.id === category.id)}
              onChange={(e) => handleCategoryChange(category, e.target.checked)}
            >
              {category.name}
            </Checkbox>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default SelectCategoryModal;
