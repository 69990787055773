import {
  ArrowLeftOutlined,
  DownOutlined,
  LeftOutlined,
  RightOutlined,
  UnorderedListOutlined,
  UpOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Datatable from "../../../components/Datatable";
import ColumnsReportsModal from "../../../components/model/ColumnsReportsModal";
import { get } from "../../../services/Apiclient";
import moment, { Moment } from "moment";
import { useCurrency } from "../../../contexts/CurrencyContext";

const { Title, Text } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

const StockInOutDetailReport: React.FC = () => {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const [isFolded, setIsFolded] = useState(false);
  const outletId = localStorage.getItem("outlet_id");
  const currentWeekStart = dayjs().startOf("isoWeek");
  const currentWeekEnd = dayjs().endOf("isoWeek");
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    currentWeekStart,
    currentWeekEnd,
  ]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [storage, setStorage] = useState<any[]>([]);
  const [selectedStorage, setSelectedStorage] = useState<string | null>(null);
  const [outlet, setOutlet] = useState<any[]>([]);
  const [selectedOutlet, setSelectedOutlet] = useState<string | null>(null);
  const [suppplier, setSupplier] = useState<any[]>([]);
  const [selectedSupplier, setSelectedSupplier] = useState<string | null>(null);

  const [selectedTypeofStock, setSelectedTypeofStock] = useState<string | null>(
    null
  );
  const [stockIn, setStockIn] = useState<any[]>([]);
  const [selectedStockIn, setSelectedStockIn] = useState<string | null>(null);
  const [stockOut, setStockOut] = useState<any[]>([]);
  const [selectedStockOut, setSelectedStockOut] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [productname, setproductname] = useState("");
  const [staffname, setstaffname] = useState("");
  const [batchcode, setbatchcode] = useState("");
  const [textChange, setTextChange] = useState(false);
  const [loading, setloading] = useState(false);

  const handleSearchProductChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setproductname(value || "");
    if (value.length == 0) {
      setTextChange(true);
    }
  };

  const handleSearchStaffChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setstaffname(value || "");
    if (value.length == 0) {
      setTextChange(true);
    }
  };

  const handleSearchBatchcodeChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    setbatchcode(value || "");
    if (value.length == 0) {
      setTextChange(true);
    }
  };

  useEffect(() => {
    getStockInInventoryData();
  }, [textChange]);

  const getOutletData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/outlet/dropdown`);
      if (response?.data?.success && response.data.data) {
        let data = response.data.data;
        data.unshift({
          id: null,
          name: "All Outlets",
        });
        setOutlet(data);
        setSelectedOutlet(outletId || null);
      }
    } catch (error) {
      console.error("Error fetching outlet:", error);
    }
  };

  const getStorageData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/storage/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setStorage(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching storage:", error);
    }
  };

  const getStockInData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/stockin/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setStockIn(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching stockin dropdown:", error);
    }
  };

  const getStockOutData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/stockout/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setStockOut(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching stockout dropdown:", error);
    }
  };

  const getSupplierData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/supplier/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setSupplier(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching staff:", error);
    }
  };

  const getStockInInventoryData = async () => {
    try {
      setloading(true);
      const startDate = dateRange ? dateRange[0].format("YYYY-MM-DD") : "NULL";
      const endDate = dateRange ? dateRange[1].format("YYYY-MM-DD") : "NULL";
      const offset = currentPage;

      const response = await get(
        `/report/stockinout/${startDate}/${endDate}/${
          selectedOutlet || "NULL"
        }/${selectedStockIn || "NULL"}/${selectedStockOut || "NULL"}/${
          selectedTypeofStock || "NULL"
        }/${selectedStorage || "NULL"}/${
          selectedSupplier || "NULL"
        }/${pageSize}/${offset}/${productname || "NULL"}/${
          staffname || "NULL"
        }/${batchcode || "NULL"}`
      );

      if (response?.data?.success && response.data.data) {
        const formattedData = response.data.data.map(
          (item: any, index: number) => ({
            ...item,
            key: index.toString(),
          })
        );

        setDataSource(formattedData);
        setTotalRecords(response.data.totalRecords || 0);
      } else {
        setDataSource([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching stock-in inventory details:", error);
      setDataSource([]);
      setTotalRecords(0);
    } finally {
      setloading(false); // 🔴 Stop spinner
      setValueChangeSearch(false);
      setTextChange(false);
    }
  };

  useEffect(() => {
    getOutletData();
    getStorageData();
    getSupplierData();
    getStockInData();
    getStockOutData();
  }, []);

  useEffect(() => {
    getStockInInventoryData();
  }, [currentPage, pageSize]);

  const handleRunFilter = () => {
    setCurrentPage(0); // Reset to first page when filters are applied
    getStockInInventoryData(); // Call API with selected filters
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1); // Adjust for 0-based indexing
  };

  const handlePageSizeChange = (value: number) => {
    setPageSize(value);
    setCurrentPage(0);
  };

  const handleFold = () => {
    setIsFolded(!isFolded);
  };

  // Define a render function for all columns to apply styles to the summary row

  const initialColumns = [
    {
      title: "Type",
      dataIndex: "type_of_stock",
      key: "type_of_stock",
      width: 150,
      render: (value: string, record: any) => value,
    },
    {
      title: "Ref#",
      dataIndex: "ref_no",
      key: "ref_no",
      width: 150,
      render: (value: string, record: any) => value,
    },
    {
      title: "Date",
      dataIndex: "stock_date",
      key: "stock_date",
      width: 150,
      render: (value: string, record: any) => value,
    },
    {
      title: "Product code",
      dataIndex: "productcode",
      key: "productcode",
      width: 150,
      render: (value: string, record: any) => (
        <div
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight: "normal",
          }}
        >
          {record.productcode}
        </div>
      ),
    },
    {
      title: "Product name",
      dataIndex: "productname",
      key: "productname",
      width: 150,
      render: (value: string, record: any) => (
        <div
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight: "normal",
          }}
        >
          {record.productname}
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "category_name",
      key: "category_name",
      width: 150,
      render: (value: string, record: any) => (
        <div
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight: "normal",
          }}
        >
          {record.category_name}
        </div>
      ),
    },
    {
      title: "Standard cost Price",
      dataIndex: "standard_cost",
      key: "standard_cost",
      width: 150,
      render: (value: string, record: any) => value,
    },
    {
      title: "Standard selling Price",
      dataIndex: "standard_price",
      key: "standard_price",
      width: 150,
      render: (value: string, record: any) => value,
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      width: 150,
      render: (value: string, record: any) => value,
    },
    {
      title: "Standard unit",
      dataIndex: "unit",
      key: "unit",
      width: 150,
      render: (value: string, record: any) => value,
    },
    {
      title: "Unit price",
      dataIndex: "unit_price",
      key: "unit_price",
      width: 150,
      render: (value: string, record: any) => value,
    },
    {
      title: "Total price",
      dataIndex: "total_price",
      key: "total_price",
      width: 150,
      render: (value: string, record: any) => value,
    },
    {
      title: "Stock in / out method",
      dataIndex: "method",
      key: "method",
      width: 150,
      render: (value: string, record: any) => (
        <div
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight: "normal",
          }}
        >
          {record.method}
        </div>
      ),
    },
    {
      title: "Staff / Store",
      dataIndex: "staff_name",
      key: "staff_name",
      width: 150,
      render: (value: string, record: any) => (
        <div
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight: "normal",
          }}
        >
          {record.staff_name} / {record.store_name}
        </div>
      ),
    },
    {
      title: "Batch code",
      dataIndex: "batchcode",
      key: "batchcode",
      width: 150,
      render: (value: string, record: any) => (
        <div
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight: "normal",
          }}
        >
          {record.batchcode}
        </div>
      ),
    },
    {
      title: "Storage",
      dataIndex: "storage",
      key: "storage",
      width: 150,
      render: (value: string, record: any) => (
        <div
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight: "normal",
          }}
        >
          {record.storage}
        </div>
      ),
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      key: "supplier",
      width: 150,
      render: (value: string, record: any) => (
        <div
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight: "normal",
          }}
        >
          {record.supplier}
        </div>
      ),
    },
    {
      title: "Creation time",
      dataIndex: "created_at",
      key: "created_at",
      width: 150,
      render: (value: string, record: any) => (
        <div
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight: "normal",
          }}
        >
          {moment(record.created_at).format("YYYY-MM-DD HH:mm")}
        </div>
      ),
    },
  ];

  // Define mandatory columns that cannot be deselected
  const mandatoryColumns = ["type_of_stock"];

  // State for columns order
  const [columns, setColumns] = useState(initialColumns);

  // Initialize selectedColumns with all column keys by default
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    columns.map((col) => col.key)
  );

  // Track the last saved column selection
  const [lastSelectedColumns, setLastSelectedColumns] = useState<string[]>([]);
  const [valueChangeSearch, setValueChangeSearch] = useState(false);

  // Filter columns based on selectedColumns and maintain order
  const displayedColumns = columns.filter((col) =>
    selectedColumns.includes(col.key)
  );

  const handleColumnsClick = () => {
    setIsModalVisible(true);
  };

  const handleModalSave = (
    selectedCols: string[],
    reorderedColumns: { key: string; title: string }[]
  ) => {
    setSelectedColumns(selectedCols);
    setLastSelectedColumns(selectedCols);
    setColumns(
      reorderedColumns.map((col) => {
        const existingCol = initialColumns.find((c) => c.key === col.key);
        return {
          ...col,
          dataIndex: col.key,
          width: 150,
          render: (value: any, record: any) =>
            col.key === "sellprice" || col.key === "product_amount"
              ? `${currencySymbol.CURRENCY_SYMBOL}${value.toFixed(2)}`
              : value,
        };
      })
    );
  };

  const styles: { [key: string]: React.CSSProperties } = {
    container: {
      background: "linear-gradient(90deg, #6a11cb, #2575fc)",
      color: "#fff",
      borderRadius: "10px",
      marginBottom: "16px",
      width: "595px",
      height: "60px",
      display: "flex",
      alignItems: "center",
      padding: "0 50px",
      position: "relative",
      overflow: "hidden",
    },
    textContainer: {
      display: "flex",
      flexDirection: "column",
      zIndex: 2,
      textAlign: "center",
    },
    label: {
      fontSize: "12px",
      fontWeight: "500",
      opacity: 0.9,
      color: "#caa2ff",
    },
    amount: {
      margin: 0,
      fontSize: "16px",
      color: "#fff",
      // animation: "fadeIn 1s ease-in-out infinite alternate",
    },
    transparentText: {
      position: "absolute",
      right: "20px",
      fontSize: "40px",
      fontWeight: "lighter",
      color: "rgba(255, 255, 255, 0.2)",
      textTransform: "uppercase",
      letterSpacing: "2px",
      zIndex: 1,
    },
  };

  const handleProductnameSearchClear = () => {
    setproductname("");
    setValueChangeSearch(true);
  };

  const handleStaffnameSearchClear = () => {
    setstaffname("");
    setValueChangeSearch(true);
  };

  const handleBatchcodeSearchClear = () => {
    setbatchcode("");
    setValueChangeSearch(true);
  };

  // const handleSelectOutletClear = () => {
  //   setValueChangeSearch(true);
  // };

  useEffect(() => {
    if (valueChangeSearch) {
      getStockInInventoryData();
    }
  }, [valueChangeSearch]);

  return (
    <Spin spinning={loading} size="large">
      <div style={{ padding: "20px" }}>
        <Row
          justify="space-between"
          align="middle"
          style={{ marginBottom: "16px" }}
        >
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => navigate(-1)}
            >
              <ArrowLeftOutlined
                style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
              />
              <Title level={4} style={{ margin: 0 }}>
                Stock In/Out Detail Report
              </Title>
            </div>
          </Col>
          <Col>
            <Space>
              <Button
                icon={<UnorderedListOutlined />}
                onClick={handleColumnsClick}
                style={{ height: 40 }}
              >
                Columns
              </Button>
            </Space>
          </Col>
        </Row>

        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#f6f7f9",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
          }}
        >
          <div style={{ marginBottom: "16px", borderRadius: "8px" }}>
            <Row gutter={16} justify="start" style={{ marginBottom: "16px" }}>
              <Col
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Text>Store</Text>
                <Select
                  value={selectedOutlet}
                  onChange={(value) => setSelectedOutlet(value)}
                  placeholder="Select outlet"
                  style={{ width: 200, height: "46px", borderRadius: "4px" }}
                  // allowClear
                  // onClear={handleSelectOutletClear}
                >
                  {outlet.map((outlet) => (
                    <Option key={outlet.id} value={outlet.id}>
                      {outlet.name}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Text>Date</Text>
                <RangePicker
                  value={dateRange}
                  onChange={(dates) =>
                    dates && setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])
                  }
                  format="DD/MM/YYYY"
                  style={{
                    width: "416px",
                    height: "46px",
                    borderRadius: "4px",
                  }}
                  allowClear={false}
                  className="custom-range-picker"
                  ranges={{
                    Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                    Yesterday: [
                      dayjs().subtract(1, "day").startOf("day"),
                      dayjs().subtract(1, "day").endOf("day"),
                    ],
                    "This Week": [
                      dayjs().startOf("week"),
                      dayjs().endOf("week"),
                    ],
                    "This Month": [
                      dayjs().startOf("month"),
                      dayjs().endOf("month"),
                    ],
                    "Last Month": [
                      dayjs().subtract(1, "month").startOf("month"),
                      dayjs().subtract(1, "month").endOf("month"),
                    ],
                  }}
                />
              </Col>
              <Col
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Text>Type</Text>
                <Select
                  value={
                    selectedTypeofStock === null ? "all" : selectedTypeofStock
                  }
                  onChange={(value) =>
                    setSelectedTypeofStock(value === "all" ? null : value)
                  }
                  placeholder="Select Type"
                  style={{ width: 200, height: "46px", borderRadius: "4px" }}
                  // allowClear
                >
                  <Option value="all">All</Option>
                  <Option value="Stock-in">Stock In</Option>
                  <Option value="Stock-out">Stock Out</Option>
                </Select>
              </Col>
              <Col
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Text>Product</Text>
                <Input
                  placeholder="Product name / code"
                  style={{ width: 200, height: "46px", borderRadius: 4 }}
                  value={productname || ""}
                  onChange={handleSearchProductChange}
                  onPressEnter={handleRunFilter} // Trigger search on Enter key
                  allowClear
                  onClear={handleProductnameSearchClear}
                />
              </Col>
              <Col
                style={{ display: "flex", flexDirection: "column", gap: "5px" }}
              >
                <Text>Stock in method</Text>
                <Select
                  value={selectedStockIn === null ? "all" : selectedStockIn}
                  onChange={(value) =>
                    setSelectedStockIn(value === "all" ? null : value)
                  }
                  placeholder="Select stock in method"
                  style={{ width: 200, height: "46px", borderRadius: "4px" }}
                  // allowClear
                >
                  <Option value="all">All stock in method</Option>
                  {stockIn.map((stockIn) => (
                    <Option key={stockIn.id} value={stockIn.id}>
                      {stockIn.stockintype}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <Row gutter={16} justify="start" align="middle">
              {!isFolded && (
                <>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Text>Stock out method</Text>
                    <Select
                      value={
                        selectedStockOut === null ? "all" : selectedStockOut
                      }
                      onChange={(value) =>
                        setSelectedStockOut(value === "all" ? null : value)
                      }
                      placeholder="Select stock out method"
                      style={{
                        width: 200,
                        height: "46px",
                        borderRadius: "4px",
                      }}
                      // allowClear
                    >
                      <Option value="all">All stock out method</Option>
                      {stockOut.map((stockIn) => (
                        <Option key={stockIn.id} value={stockIn.id}>
                          {stockIn.stockouttype}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Text>Staff / Store</Text>
                    <Input
                      placeholder="Please enter"
                      style={{ width: 200, height: "46px", borderRadius: 4 }}
                      value={staffname || ""}
                      onChange={handleSearchStaffChange}
                      onPressEnter={handleRunFilter} // Trigger search on Enter key
                      allowClear
                      onClear={handleStaffnameSearchClear}
                    />
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Text>Batch code</Text>
                    <Input
                      placeholder="Please enter"
                      style={{ width: 200, height: "46px", borderRadius: 4 }}
                      value={batchcode || ""}
                      onChange={handleSearchBatchcodeChange}
                      onPressEnter={handleRunFilter} // Trigger search on Enter key
                      allowClear
                      onClear={handleBatchcodeSearchClear}
                    />
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Text>Storage</Text>
                    <Select
                      value={selectedStorage === null ? "all" : selectedStorage}
                      onChange={(value) =>
                        setSelectedStorage(value === "all" ? null : value)
                      }
                      placeholder="Select storage"
                      style={{
                        width: 200,
                        height: "46px",
                        borderRadius: "4px",
                      }}
                      // allowClear
                    >
                      <Option value="all">All</Option>
                      {storage.map((storage) => (
                        <Option key={storage.id} value={storage.id}>
                          {storage.type}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                  <Col
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Text>Supplier</Text>
                    <Select
                      value={
                        selectedSupplier === null ? "all" : selectedSupplier
                      }
                      onChange={(value) =>
                        setSelectedSupplier(value === "all" ? null : value)
                      }
                      placeholder="Select staff"
                      style={{
                        width: 200,
                        height: "46px",
                        borderRadius: "4px",
                      }}
                      // allowClear
                    >
                      <Option value="all">All</Option>
                      {suppplier.map((staff) => (
                        <Option key={staff.id} value={staff.id}>
                          {staff.name}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </>
              )}
              <Col style={{ marginLeft: "auto" }}>
                <Space>
                  <Button
                    style={{ height: "46px", width: "80px" }}
                    type="primary"
                    onClick={handleRunFilter}
                  >
                    Run
                  </Button>
                  <Button
                    style={{
                      border: "none",
                      background: "#f6f7f9",
                      color: "#717ef1",
                      fontWeight: 600,
                      height: "46px",
                      width: "80px",
                    }}
                    onClick={handleFold}
                  >
                    {isFolded ? (
                      <>
                        Expand{" "}
                        <span
                          style={{
                            display: "inline-flex",
                            flexDirection: "column",
                          }}
                        >
                          <DownOutlined style={{ marginBottom: "-7px" }} />
                          <DownOutlined />
                        </span>
                      </>
                    ) : (
                      <>
                        Fold{" "}
                        <span
                          style={{
                            display: "inline-flex",
                            flexDirection: "column",
                          }}
                        >
                          <UpOutlined style={{ marginBottom: "-7px" }} />
                          <UpOutlined />
                        </span>
                      </>
                    )}
                  </Button>
                </Space>
              </Col>
            </Row>
          </div>

          <Datatable
            columns={displayedColumns}
            dataSource={dataSource}
            scroll={{
              y: !isFolded ? "calc(100vh - 420px)" : "calc(100vh - 385px)",
            }}
            showCheckbox={false}
            rowSelection={undefined}
            showPagination={false}
            dynamicTableHeight={
              !isFolded ? "calc(100vh - 420px)" : "calc(100vh - 385px)"
            }
          />

          <Row
            justify="space-between"
            style={{
              alignItems: "center",
              marginTop: "40px",
              marginBottom: "16px",
            }}
          >
            <Col>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
              >
                <Option value={10}>10 entries/page</Option>
                <Option value={20}>20 entries/page</Option>
                <Option value={50}>50 entries/page</Option>
                <Option value={100}>100 entries/page</Option>
              </Select>
            </Col>

            {totalRecords >= 0 && (
              <Col>
                <Row justify="end" align="middle" style={{ gap: "16px" }}>
                  <Col>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(0,0,0,.85)",
                      }}
                    >
                      Total {totalRecords} items
                    </span>
                  </Col>
                  <Col>
                    <Pagination
                      current={currentPage + 1} // Adjust for 1-based indexing in UI
                      pageSize={pageSize}
                      total={totalRecords}
                      onChange={handlePageChange}
                      showSizeChanger={false}
                      itemRender={(page, type, originalElement) => {
                        if (type === "prev") {
                          const isDisabled = currentPage === 0;
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <LeftOutlined />
                              <span>Previous</span>
                            </a>
                          );
                        }
                        if (type === "next") {
                          const isDisabled =
                            currentPage ===
                            Math.ceil(totalRecords / pageSize) - 1;
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <span>Next</span>
                              <RightOutlined />
                            </a>
                          );
                        }
                        return originalElement;
                      }}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>
        </Content>

        <ColumnsReportsModal
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          onSave={handleModalSave}
          availableColumns={columns}
          initialSelectedColumns={selectedColumns}
          lastSelectedColumns={lastSelectedColumns}
          mandatoryColumns={mandatoryColumns}
        />
      </div>
    </Spin>
  );
};

export default StockInOutDetailReport;
