import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
  SettingOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
} from "antd";
import { Content } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"; // Import the plugin
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Datatable from "../../components/Datatable";
import AttendanceDetailsModal from "../../components/model/staff/attendance/AttendanceDetailsModal";
import { get } from "../../services/Apiclient";

// Extend dayjs with the isSameOrBefore plugin
dayjs.extend(isSameOrBefore);

const { RangePicker } = DatePicker;

const customheader: React.CSSProperties = {
  padding: "0 !important",
  paddingLeft: "0 !important",
};

const Attendance: React.FC = () => {
  const navigate = useNavigate();
  const outletId = localStorage.getItem("outlet_id");
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [dataSource, setDataSource] = useState<any[]>([
    {
      key: "1",
      name: "MARIA FELOMENA VILLEGAS",
      attendanceday: 0,
      restday: 1,
      latecount: 0,
      profile: 0,
      commisionprofile: 0,
      timeoffcount: 0,
      timeoffduration: 0,
      overtimecount: 0,
      overtimeduration: 0,
      attendance: {
        "Mar 10, 2025": { clockIn: "Day off", clockOut: "Day off" },
        "Mar 11, 2025": { clockIn: "09:00", clockOut: "17:00" },
        "Mar 12, 2025": { clockIn: "Absent", clockOut: "Absent" },
      },
    },
    {
      key: "2",
      name: "Kong Chi Liang",
      attendanceday: 0,
      restday: 6,
      latecount: 0,
      profile: 0,
      commisionprofile: 0,
      timeoffcount: 0,
      timeoffduration: 0,
      overtimecount: 0,
      overtimeduration: 0,
      attendance: {
        "Mar 10, 2025": { clockIn: "Day off", clockOut: "Day off" },
        "Mar 11, 2025": { clockIn: "08:30", clockOut: "16:30" },
        "Mar 12, 2025": { clockIn: "Day off", clockOut: "Day off" },
      },
    },
    {
      key: "3",
      name: "LIE PHING LEE",
      attendanceday: 0,
      restday: 1,
      latecount: 0,
      profile: 0,
      commisionprofile: 0,
      timeoffcount: 0,
      timeoffduration: 0,
      overtimecount: 0,
      overtimeduration: 0,
      attendance: {
        "Mar 10, 2025": { clockIn: "Absent", clockOut: "Absent" },
        "Mar 11, 2025": { clockIn: "09:15", clockOut: "17:15" },
        "Mar 12, 2025": { clockIn: "Day off", clockOut: "Day off" },
      },
    },
    {
      key: "4",
      name: "JASON JASON",
      attendanceday: 0,
      restday: 1,
      latecount: 0,
      profile: 0,
      commisionprofile: 0,
      timeoffcount: 0,
      timeoffduration: 0,
      overtimecount: 0,
      overtimeduration: 0,
      attendance: {
        "Mar 10, 2025": { clockIn: "Day off", clockOut: "Day off" },
        "Mar 11, 2025": { clockIn: "09:00", clockOut: "17:00" },
        "Mar 12, 2025": { clockIn: "Absent", clockOut: "Absent" },
      },
    },
  ]);
  const [servicesData, setServicesData] = useState<any[]>([]);
  const [departmentData, setDepartmentData] = useState<any[]>([]);
  const [selectedService, setSelectedService] = useState<string | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<string | null>(
    null
  );
  const [selectedView, setSelectedView] = useState("week");
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(
    null
  );
  const [isWlanModalVisible, setIsWlanModalVisible] = useState(false);
  const [isAttendanceModalVisible, setIsAttendanceModalVisible] =
    useState(false);
  const [wlanName, setWlanName] = useState("");
  const [macAddress, setMacAddress] = useState("");
  const [selectedStaff, setSelectedStaff] = useState<any>(null);
  const datatableRef = useRef<HTMLDivElement>(null); // Ref to trigger reflow

  // Generate dynamic date columns
  const generateDateColumns = () => {
    if (!dateRange || !dateRange[0] || !dateRange[1]) return [];

    const startDate = dateRange[0];
    const endDate = dateRange[1];
    const columns: any[] = [];

    let currentDate = dayjs(startDate);
    while (currentDate.isSameOrBefore(endDate, "day")) {
      const dateStr = currentDate.format("MMM DD, YYYY");
      columns.push({
        title: <div style={customheader}>{dateStr}</div>,
        children: [
          {
            title: <div style={customheader}>Clock In</div>,
            key: `clockIn_${dateStr}`,
            dataIndex: ["attendance", dateStr, "clockIn"],
            width: 120,
            minWidth: 120,
            maxWidth: 120,
            render: (text: string) => <span>{text || "-"}</span>,
          },
          {
            title: <div style={customheader}>Clock Out</div>,
            key: `clockOut_${dateStr}`,
            dataIndex: ["attendance", dateStr, "clockOut"],
            width: 120,
            minWidth: 120,
            maxWidth: 120,
            render: (text: string) => <span>{text || "-"}</span>,
          },
        ],
      });

      currentDate = currentDate.add(1, "day");
    }
    return columns;
  };

  const dateColumns = useMemo(() => generateDateColumns(), [dateRange]);

  useEffect(() => {
    if (selectedView === "week") {
      setDateRange([dayjs().startOf("week"), dayjs().endOf("week")]);
    } else {
      setDateRange([dayjs().startOf("month"), dayjs().endOf("month")]);
    }
  }, [selectedView]);

  useEffect(() => {
    // Force reflow after component mounts
    const timer = setTimeout(() => {
      if (datatableRef.current) {
        datatableRef.current.style.display = "none";
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        datatableRef.current.offsetHeight; // Trigger reflow
        datatableRef.current.style.display = "";
      }
    }, 100); // Slight delay to ensure DOM is ready
    return () => clearTimeout(timer);
  }, []);
  const getServicesData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/service/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setServicesData(response.data.data);
        setSelectedService(response.data.data[0]?.id || null);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
    }
  };

  const getDepartmentData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/department/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setDepartmentData(response.data.data);
        setSelectedDepartment(response.data.data[0]?.id || null);
      }
    } catch (error) {
      console.error("Error fetching department:", error);
    }
  };

  useEffect(() => {
    getServicesData();
    getDepartmentData();
  }, []);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) =>
      setSelectedRowKeys(newSelectedRowKeys),
  };

  interface ColumnItem {
    title: string;
    dataIndex?: string;
    key?: string;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    render?: (text: any, record?: any) => JSX.Element;
    fixed?: "right";
  }

  const baseColumns: ColumnItem[] = [
    {
      title: "Staff name",
      dataIndex: "name",
      key: "name",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span style={{ wordBreak: "break-word" }}>{text}</span>,
    },
    {
      title: "Attendance days",
      dataIndex: "attendanceday",
      key: "attendanceday",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Rest days",
      dataIndex: "restday",
      key: "restday",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Late arrival count",
      dataIndex: "latecount",
      key: "latecount",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Late arrival duration",
      dataIndex: "profile",
      key: "profile",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Early departure count",
      dataIndex: "commisionprofile",
      key: "commisionprofile",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Early departure duration",
      dataIndex: "commisionprofile",
      key: "commisionprofile2",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Time off count",
      dataIndex: "timeoffcount",
      key: "timeoffcount",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Time off duration",
      dataIndex: "timeoffduration",
      key: "timeoffduration",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Overtime count",
      dataIndex: "overtimecount",
      key: "overtimecount",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Overtime hours",
      dataIndex: "overtimehours",
      key: "overtimehours",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Missing records",
      dataIndex: "missingrecords",
      key: "missingrecords",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Absenteeism",
      dataIndex: "absenteeism",
      key: "absenteeism",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span>{text}</span>,
    },
    {
      title: "On-site service",
      dataIndex: "onsiteservice",
      key: "onsiteservice",
      width: 150,
      minWidth: 150,
      maxWidth: 150,
      render: (text) => <span>{text}</span>,
    },
  ];

  interface AttendanceRecord {
    clockIn: string;
    clockOut: string;
  }

  interface StaffData {
    key: string;
    name: string;
    attendanceday: number;
    restday: number;
    latecount: number;
    profile: number;
    commisionprofile: number;
    timeoffcount: number;
    timeoffduration: number;
    overtimecount: number;
    overtimeduration: number;
    attendance: Record<string, AttendanceRecord>;
  }

  const columns: ColumnItem[] = [
    ...baseColumns,
    ...dateColumns,
    {
      title: "Action",
      key: "action",
      fixed: "right",
      width: 100,
      minWidth: 100,
      maxWidth: 100,
      render: (text: any, record: StaffData) => (
        <a
          style={{
            color: "#2e56f2",
            textDecoration: "underline",
            fontWeight: 700,
          }}
          onClick={() => {
            setSelectedStaff(record);
            setIsAttendanceModalVisible(true);
          }}
        >
          View
        </a>
      ),
    },
  ];

  const handleSave = () => {
    console.log("Saving WLAN:", { wlanName, macAddress });
    setIsWlanModalVisible(false);
    setWlanName("");
    setMacAddress("");
  };

  return (
    <Content style={{ padding: 24, background: "#f5f7fb" }}>
      {/* Header Section */}
      <Row justify="space-between" align="middle" style={{ marginBottom: 16 }}>
        <Col>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowLeftOutlined
              onClick={() => navigate(-1)}
              style={{
                marginRight: 12,
                color: "#325DF2",
                fontSize: 18,
                cursor: "pointer",
              }}
            />
            <h2 style={{ margin: 0 }}>Attendance</h2>
          </div>
        </Col>
        <Col>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <Button
              icon={<UploadOutlined />}
              style={{ marginRight: 8, height: "46px" }}
            >
              Export as
            </Button>
            <Button
              icon={<SettingOutlined />}
              style={{ height: "46px" }}
              onClick={() => setIsWlanModalVisible(true)}
            >
              Attendance setting
            </Button>
            <Button
              style={{ background: "#2e56f2", color: "white", height: "46px" }}
              onClick={() => navigate("/settings/attendance/review")}
            >
              Review
            </Button>
          </div>
        </Col>
      </Row>

      {/* Filters Section */}
      <Content
        style={{
          background: "#f6f7f9",
          borderRadius: 12,
          padding: 24,
          border: "1px solid #e0e7fe",
        }}
      >
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col>
            <Select
              value={selectedService}
              style={{ width: 200, height: 40, borderRadius: 4 }}
              onChange={setSelectedService}
            >
              {servicesData.map((service) => (
                <Select.Option key={service.id} value={service.id}>
                  {service.servicename}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              value={selectedDepartment}
              style={{ width: 200, height: 40, borderRadius: 4 }}
              onChange={setSelectedDepartment}
            >
              {departmentData.map((dept) => (
                <Select.Option key={dept.id} value={dept.id}>
                  {dept.departmenttype}
                </Select.Option>
              ))}
            </Select>
          </Col>
          <Col>
            <Select
              value={selectedView}
              onChange={setSelectedView}
              style={{ width: 100 }}
            >
              <Select.Option value="week">Week</Select.Option>
              <Select.Option value="month">Month</Select.Option>
            </Select>
          </Col>
          <Col>
            <RangePicker
              value={dateRange}
              allowClear={false}
              format="MMM DD"
              style={{ width: 250, height: "46px" }}
              onChange={(dates) =>
                setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])
              }
            />
          </Col>
        </Row>
        <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
          <Col span={8}>
            <Input
              placeholder="Search by staff code, name"
              style={{ height: 40, borderRadius: 4 }}
            />
          </Col>
          <Col span={8} style={{ display: "flex", alignItems: "center" }}>
            <Checkbox style={{ marginRight: 8 }} />
            Hide resigned and inactive staff
          </Col>
          <Col>
            <Button
              type="primary"
              style={{
                height: 40,
                borderRadius: 4,
                background: "#2e56f2",
                border: "none",
                fontWeight: 500,
              }}
            >
              Search
            </Button>
          </Col>
        </Row>

        <Title level={4}>Staff Attendance List</Title>

        {/* CSS to remove padding and enforce styling */}
        <style>
          {`
            .ant-table-thead > tr > th {
              padding: 0 !important;
              padding-left: 16px !important;
              font-weight: 400;
              font-size: 14px;
              min-width: 0 !important;
              max-width: none !important;
            }
            .ant-table-thead > tr > td > div {
              padding: 0 !important;
              text-align: center !important;
            }
          `}
        </style>

        {/* Table with ref to force reflow */}
        <div ref={datatableRef}>
          <Datatable
            columns={columns}
            dataSource={dataSource}
            scroll={{ y: "calc(100vh - 300px)", x: "max-content" }}
            rowSelection={{ selectedRowKeys, onChange: rowSelection.onChange }}
            showPagination={false}
          />
        </div>

        {/* Pagination */}
        <Row justify="end" style={{ marginTop: "10px" }}>
          <Col>
            <Row align="middle" style={{ gap: 16 }}>
              <span
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  color: "rgba(0,0,0,.85)",
                }}
              >
                Total items
              </span>
              <Pagination
                defaultCurrent={1}
                showSizeChanger={false}
                itemRender={(page, type) => {
                  if (type === "prev")
                    return (
                      <a
                        style={{
                          display: "flex",
                          gap: 5,
                          fontSize: 12,
                          fontWeight: 700,
                          color: "#2e56f2",
                        }}
                      >
                        <LeftOutlined />
                        Previous
                      </a>
                    );
                  if (type === "next")
                    return (
                      <a
                        style={{
                          display: "flex",
                          gap: 5,
                          fontSize: 12,
                          fontWeight: 700,
                          color: "#2e56f2",
                        }}
                      >
                        Next
                        <RightOutlined />
                      </a>
                    );
                  return page;
                }}
                style={{ display: "inline-flex" }}
              />
            </Row>
          </Col>
        </Row>
      </Content>

      {/* WLAN Modal */}
      <Modal
        title="Add WLAN"
        open={isWlanModalVisible}
        onCancel={() => {
          setIsWlanModalVisible(false);
          setWlanName("");
          setMacAddress("");
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setIsWlanModalVisible(false);
              setWlanName("");
              setMacAddress("");
            }}
          >
            Cancel
          </Button>,
          <Button key="save" type="primary" onClick={handleSave}>
            Save
          </Button>,
        ]}
        width={400}
      >
        <div style={{ marginBottom: 16 }}>
          <label>Name</label>
          <Input
            placeholder="Please enter"
            value={wlanName}
            onChange={(e) => setWlanName(e.target.value)}
            style={{ marginTop: 8, width: "100%" }}
          />
        </div>
        <div>
          <label>MAC address</label>
          <Input
            placeholder="e.g. 00:11:22:33:44:55"
            value={macAddress}
            onChange={(e) => setMacAddress(e.target.value)}
            style={{ marginTop: 8, width: "100%" }}
          />
        </div>
      </Modal>

      {/* Attendance Details Modal */}
      <AttendanceDetailsModal
        visible={isAttendanceModalVisible}
        onCancel={() => setIsAttendanceModalVisible(false)}
        staffData={selectedStaff}
      />
    </Content>
  );
};

export default Attendance;
