import React, { useEffect, useState } from "react";
import {
  Layout,
  Button,
  Input,
  Select,
  Table,
  Row,
  Col,
  Pagination,
  Dropdown,
  Menu,
  Checkbox,
  message,
} from "antd";
import {
  FilterOutlined,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { get } from "../../services/Apiclient";
import Datatable from "../../components/Datatable";
import { useNavigate } from "react-router-dom";
import QRCodeModal from "../../components/model/customer/QRCodeModal";
import TagSelectModal from "../../components/model/customer/TagSelectModal";
import ReferrerModal from "../../components/model/customer/ReferrerModal";
import SelectStaffModal from "../../components/model/customer/SelectStaffModal";
import NoteModal from "../../components/model/customer/NoteModal";
import { Helmet } from "react-helmet";
const qrIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Clients_SaoMa.png`;
const maleIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Gender_Male.png`;
const femaleIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Gender_Female.png`;
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const { Option } = Select;
const { Content } = Layout;
interface CustomerList {
  key: React.Key;
  customercode: String;
  customername: String;
  gender: String;
  phonenumber: String;
  email: String;
  customertag: String;
  source: String;
  referralname: String;
  consultant: String;
  status: String;
  consultantname: string;
  lastconsumptiondate: String;
  created_at: String;
  store_name: String;
  notes: String;
}
const CustomerList: React.FC = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState<string[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [originalDataSource, setOriginalDataSource] = useState<any[]>([]);
  const handleServiceStatusChange = (checkedValues: any) => {
    setStatus(checkedValues);
  };
  const [isReferralModalVisible, setIsReferralModalVisible] = useState(false);
  const [isFirstConsultantModalVisible, setIsFirstConsultantModalVisible] =
    useState(false);
  const showFirstConsultantModal = () => setIsFirstConsultantModalVisible(true);
  const showReferralModal = () => setIsReferralModalVisible(true);
  const onCancel = () => {
    setIsReferralModalVisible(false);
  };

  const userRole = localStorage.getItem("userRole");
  const permissions = JSON.parse(localStorage.getItem("permissions") || "{}");

  const [referralText, setReferralText] = useState("");
  const [firstConsultantText, setFirstConsultantText] = useState("");
  const [referralId, setReferralId] = useState("null");
  const [ConsultantId, setConsultantId] = useState("null");

  const [showNotesModal, setShowNotesModal] = useState(false);
  const [valueChangeSearch, setValueChangeSearch] = useState(false);
  const [notesText, setNotesText] = useState("");

  const handleReferrerSelect = (selectedObject: any) => {
    // setSelectedReferrer(selectedObject);
    setReferralText(selectedObject.name);
    setReferralId(selectedObject.id);
  };

  const handleFirstConsultantSelect = (selectedObject: any) => {
    // setSelectedReferrer(selectedObject);
    if (selectedObject == null) {
      setFirstConsultantText("");
      setConsultantId("null");
    } else {
      setFirstConsultantText(selectedObject.name);
      setConsultantId(selectedObject.id);
    }
  };

  const onCancelFirstConsultantModal = () => {
    setIsFirstConsultantModalVisible(false);
  };
  const applyFilter = () => {
    if (status.length === 0) {
      setDataSource(originalDataSource);
    } else {
      const filteredData = originalDataSource.filter((item) =>
        status.includes(item.status)
      );
      setDataSource(filteredData);
    }
    setDropdownVisible(false);
  };
  const resetFilter = () => {
    setStatus([]);
    setDataSource(originalDataSource);
  };

  const filterIconStyle = {
    color: status.length > 0 ? "#1890FF" : "#aaa",
    cursor: "pointer",
    marginLeft: "8px",
  };

  const showNoteText = (text: string) => {
    setNotesText(text);
    setShowNotesModal(true);
  };

  const serviceStatus = (
    <Menu>
      <Checkbox.Group
        style={{ padding: "10px" }}
        value={status}
        onChange={handleServiceStatusChange}
      >
        <Row>
          <Col span={24}>
            <Checkbox value="Active">Active</Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="Inactive">Inactive</Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
      <Row justify="space-between" style={{ padding: "10px" }}>
        <Button onClick={resetFilter}>Reset</Button>
        <Button type="primary" onClick={applyFilter}>
          OK
        </Button>
      </Row>
    </Menu>
  );
  const columns = [
    {
      title: "Code",
      dataIndex: "customercode",
      key: "customercode",
      width: 150,
      sorter: (a: any, b: any) => a.customercode.localeCompare(b.customercode),
      showSorterTooltip: false,
    },
    {
      title: "Name",
      dataIndex: "customername",
      key: "customername",
      width: 150,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      width: 200,
      render: (text: string) => (
        <>
          {text == "Male" ? (
            <img
              src={maleIcon}
              alt={text}
              style={{ width: "20px", height: "20px" }}
            />
          ) : (
            <>
              {text == "Female" ? (
                <img
                  src={femaleIcon}
                  alt={text}
                  style={{ width: "20px", height: "20px" }}
                />
              ) : (
                <span>{text}</span>
              )}
            </>
          )}
        </>
      ),
    },
    {
      title: "Phone number",
      dataIndex: "phonenumber",
      key: "phonenumber",
      width: 200,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 250,
    },
    {
      title: (
        <>
          Customer Tag
          <Button
            style={{ border: "none", boxShadow: "none" }}
            onClick={() => {
              setShowCustomTagModal(true);
            }}
          >
            <FilterOutlined style={filterIconStyle} />
          </Button>
        </>
      ),
      dataIndex: "customertag",
      key: "customertag",
      width: 200,
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      width: 150,
    },
    {
      title: "Referrer",
      dataIndex: "referralname",
      key: "referralname",
      width: 200,
    },
    {
      title: "Consultant",
      dataIndex: "consultantname",
      key: "consultantname",
      width: 200,
    },
    {
      title: (
        <>
          Status
          <Dropdown
            overlay={serviceStatus}
            trigger={["click"]}
            visible={dropdownVisible}
            onVisibleChange={(flag) => setDropdownVisible(flag)}
            overlayStyle={{ width: "150px" }} // Adjust size here
          >
            <FilterOutlined style={filterIconStyle} />
          </Dropdown>
        </>
      ),
      dataIndex: "status",
      key: "status",
      width: 200,
    },
    {
      title: "Last consumption date",
      dataIndex: "lastconsumptiondate",
      key: "lastconsumptiondate",
      width: 200,
    },
    {
      title: "Joined",
      dataIndex: "created_at",
      key: "created_at",
      width: 120,
      sorter: (a: any, b: any) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      showSorterTooltip: false,
    },
    {
      title: "Store",
      dataIndex: "store_name",
      key: "store_name",
      width: 200,
    },
    {
      title: "Note",
      dataIndex: "notes",
      key: "notes",
      width: 200,
      render: (text: any, record: any): React.ReactNode => (
        <>
          <span
            style={{
              display: "-webkit-box",
              WebkitLineClamp: 4,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
              width: "200px", // Ensure a fixed width to apply ellipsis correctly
            }}
            onClick={() => {
              showNoteText(record.notes);
            }}
          >
            {record.notes}
          </span>
        </>
      ),
    },
    {
      title: "Action",
      fixed: "right" as "right",
      width: 100,
      render: (text: any, record: any): React.ReactNode => (
        <>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              fontWeight: 700,
              marginRight: "16px",
              textUnderlineOffset: "4px",
            }}
            onClick={() => navigate(`/customer/view/${record.key}`)}
          >
            View
          </a>
        </>
      ),
    },
  ];
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [outletData, setOutletData] = useState<any[]>([]);
  const [customerSourceData, setCustomerSourceData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [showQrcodeModal, setShowQrCodeModal] = useState(false);
  const [showCustomTagModal, setShowCustomTagModal] = useState(false);
  const [outletCode, setoutletCode] = useState<any>(
    localStorage.getItem("outlet_id") || ""
  );
  const [privilegesCode, setprivilegesCode] = useState("null");
  const [sourceCode, setSourceCode] = useState("null");
  const [dayCode, setDayCode] = useState("null");
  const [searchText, setSearchText] = useState("");
  const fetchData = async () => {
    try {
      const url = `/customer/getall/${currentPage}/${pageSize}/created_at/DESC/${searchText}?outletfield=${outletCode}&sourcefield=${sourceCode}&consultantfield=${ConsultantId}&referralfield=${referralId}&dateField=${dayCode}`;
      const response = await get(url);
      if (response?.data?.success) {
        const apiData = response.data.data.map((item: any) => ({
          key: item.id,
          customercode: item.customercode,
          customername: `${item.firstname} ${item.lastname}`,
          gender: item.gender,
          phonenumber: item.phonenumber,
          email: item.email,
          customertag: item.customertag,
          source: item.source_name,
          referralname: item.referralname,
          consultantname: item.consultantname,
          status: item.status,
          lastconsumptiondate:
            item.last_consumption_date_used !== null
              ? new Date(item.last_consumption_date_used)
                  .toISOString()
                  .split("T")[0]
              : "-",
          joined: item.joined,
          store_name: item.store_name,
          notes: item.notes,
          created_at: item.created_at,
        }));
        setDataSource(apiData);
        setOriginalDataSource(apiData);
        setTotalRecords(response.data.totalRecords);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };
  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: "null",
            name: "All ",
          });
          setOutletData(response.data.data);
          setoutletCode(
            localStorage.getItem("outlet_id")
              ? localStorage.getItem("outlet_id")
              : "null"
          );
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const handleOutletChange = (value: string) => {
    setoutletCode(value);
  };

  const handlePrivilegesChange = (value: string) => {
    setprivilegesCode(value);
  };

  const handleSourceChange = (value: string) => {
    setSourceCode(value);
  };

  const handleDayChange = (value: string) => {
    setDayCode(value);
  };
  const getCustomerSourceDropdown = async () => {
    try {
      let url = `/customersource/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: "null",
            customersourcetype: "All Source",
          });
          setCustomerSourceData(data);
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };
  useEffect(() => {
    getOutletDropdown();
    getCustomerSourceDropdown();
  }, []);
  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };

  useEffect(() => {
    if (valueChangeSearch) {
      fetchData();
    }
  }, [valueChangeSearch]);

  const handleSearchChange = async (e: any) => {
    const value = e.target.value;
    setSearchText(value);
    // Call fetchData only if there is text to search for
    await setValueChangeSearch(e.target.value.length === 0);
  };
  const handleSearchClear = () => {
    setSearchText("");
    setValueChangeSearch(true);
  };
  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    pageSize,
    outletCode,
    sourceCode,
    dayCode,
    ConsultantId,
    referralId,
  ]);
  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };
  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Layout>
        {/* Header Section */}
        <Row justify="space-between" align="middle">
          <Col>
            <h2 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Customer List{" "}
              <Button
                type="default"
                style={{
                  borderColor: "#1245ee",
                  backgroundColor: "#fff",
                  marginLeft: "12px",
                  padding: "8px",
                }}
                onClick={() => {
                  setShowQrCodeModal(true);
                }}
              >
                <img
                  src={qrIcon}
                  alt="icon"
                  style={{ width: "20px", height: "20px" }}
                />
              </Button>
            </h2>
          </Col>
          <Col>
            <Button style={{ marginRight: "8px" }}>Export</Button>
            {(userRole !== "Staff" ||
              (userRole === "Staff" && permissions?.addnewcustomer)) && (
              <Button
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate("/customer/add")}
              >
                Add
              </Button>
            )}
          </Col>
        </Row>
        {/* Content Section */}
        <Content
          style={{
            marginTop: "10px",
            height: "100%",
            backgroundColor: "#F6F7F9",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #E0E7FE",
          }}
        >
          {/* Filters */}
          <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
            {" "}
            {/* First row */}
            <Col xs={24} sm={12} md={4}>
              <Select
                placeholder="Select Outlet"
                value={outletCode}
                onChange={(value) => handleOutletChange(value)}
                className="dropdown"
                style={{ width: "100%" }}
                disabled={localStorage.getItem("userRole") !== "Merchant"}
              >
                {outletData.length > 0 &&
                  outletData.map((outdata: any) => (
                    <Option value={outdata.id} key={outdata.id}>
                      {outdata.name}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Select
                placeholder="ALL"
                value={privilegesCode}
                onChange={(value) => handlePrivilegesChange(value)}
                style={{ width: "100%" }}
              >
                <Option value="null">ALL</Option>
                <Option value="null1">With privileges</Option>
                <Option value="null2">Without privileges</Option>
              </Select>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Input
                placeholder="Referrer"
                onClick={showReferralModal}
                readOnly
                value={referralText}
                suffix={<span className="textSuffix">&gt;</span>}
              />
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Input
                placeholder="Consultant"
                onClick={showFirstConsultantModal}
                readOnly
                value={firstConsultantText}
                suffix={<span className="textSuffix">&gt;</span>}
              />
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Select
                placeholder="All sources"
                value={sourceCode}
                onChange={(value) => handleSourceChange(value)}
                style={{ width: "100%" }}
              >
                {customerSourceData.length > 0 &&
                  customerSourceData.map((source: any) => (
                    <Option value={source.id} key={source.id}>
                      {source.customersourcetype}
                    </Option>
                  ))}
                {/* <Option value="null">All sources</Option>
              <Option value="Walk-in">Walk-in</Option>
              <Option value="Online advertisement">Online advertisement</Option>
              <Option value="Referred by a friend">Referred by a friend</Option>
              <Option value="Social Media">Social media</Option> */}
              </Select>
            </Col>
            <Col xs={24} sm={12} md={4}>
              <Select
                placeholder="All sources"
                value={dayCode}
                onChange={(value) => handleDayChange(value)}
                style={{ width: "100%" }}
              >
                <Option value="null">All</Option>
                <Option value="7">Within 7 days</Option>
                <Option value="30">Within 30 days</Option>
                <Option value="90">Within 90 days</Option>
              </Select>
            </Col>
          </Row>
          <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
            {" "}
            {/* Second row */}
            <Col xs={24} sm={20} md={20}>
              <Input
                placeholder="Search by customer code, name, phone number, email"
                value={searchText}
                onChange={handleSearchChange}
                allowClear
                style={{ width: "50%" }}
                onClear={handleSearchClear}
                onKeyPress={handleKeyPress}
                prefix={
                  <SearchOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                      marginRight: "2px",
                      alignItems: "center",
                    }}
                  />
                }
              />
            </Col>
            <Col xs={24} sm={4} md={4} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                onClick={() => {
                  fetchData();
                }}
              >
                Search
              </Button>
            </Col>
          </Row>
          {/* Table */}
          <Datatable<any>
            columns={columns}
            dataSource={dataSource}
            currentPage={currentPage}
            pageSize={pageSize}
            totalRecords={totalRecords}
            onPageChange={handlePageChange}
            scroll={{ y: "calc(100vh - 420px)" }}
            showCheckbox // Custom scroll settings for this instance
          />
        </Content>
        {showQrcodeModal && (
          <QRCodeModal
            visible={showQrcodeModal}
            onClose={() => {
              setShowQrCodeModal(false);
            }}
          ></QRCodeModal>
        )}
        {showCustomTagModal && (
          <TagSelectModal
            visible={showCustomTagModal}
            onClose={() => {
              setShowCustomTagModal(false);
            }}
          />
        )}
        {isReferralModalVisible && (
          <ReferrerModal
            visible={isReferralModalVisible}
            onClose={onCancel}
            onSelect={handleReferrerSelect}
          ></ReferrerModal>
        )}
        {isFirstConsultantModalVisible && (
          <SelectStaffModal
            visible={isFirstConsultantModalVisible}
            onClose={onCancelFirstConsultantModal}
            onSelect={handleFirstConsultantSelect}
          ></SelectStaffModal>
        )}
        {showNotesModal && (
          <NoteModal
            visible={showNotesModal}
            onClose={() => {
              setNotesText("");
              setShowNotesModal(false);
            }}
            message={notesText}
          />
        )}
      </Layout>
    </>
  );
};
export default CustomerList;
