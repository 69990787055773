import {
  ArrowLeftOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Pagination,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import React, {
  useEffect,
  useState,
  useMemo,
  useLayoutEffect,
  useRef,
} from "react";
import { useNavigate } from "react-router-dom";
import Datatable from "../../../components/Datatable";
import ColumnsReportsModal from "../../../components/model/ColumnsReportsModal";
import { get } from "../../../services/Apiclient";
import { useCurrency } from "../../../contexts/CurrencyContext";

const { Title } = Typography;
const { RangePicker } = DatePicker;
const { Option } = Select;

// Define the DataSourceItem type with dynamic tax and billingonaccount fields
interface DataSourceItem {
  key: React.Key;
  date: string;
  turnover: number;
  collectedrevenue: number;
  otherincome: number;
  expense: number;
  netrevenue: number;
  netcollectedrevenue: number;
  outstandingpayment: number;
  repayment: number;
  fulfilledperformance: number;
  privilagecard: number;
  tips: number;
  customer_flow: number;
  numberofcustomers: {
    newReturning: string;
    maleFemale: string;
  };
  tax: Record<string, number>;
  taxTotal: number;
  billingonaccount: Record<
    string,
    {
      product: number;
      service: number;
      privilege: number;
      tip: number;
      taxes: number;
      total: number;
    }
  >;
}

// Define CustomColumnType to align with Ant Design's ColumnType
interface CustomColumnType {
  title: string;
  dataIndex?: string | string[];
  key: string;
  width?: number; // Keep as optional to match Ant Design's type
  children?: CustomColumnType[];
}

const SalesSummaryReport: React.FC = () => {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const outletId = localStorage.getItem("outlet_id");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [dataSource, setDataSource] = useState<DataSourceItem[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [outlet, setOutlet] = useState<any[]>([]);
  const [selectedOutlet, setSelectedOutlet] = useState<string | null>(outletId);
  const currentMonthStart = dayjs().startOf("day");
  const currentMonthEnd = dayjs().endOf("day");
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs]>([
    currentMonthStart,
    currentMonthEnd,
  ]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableWidth, setTableWidth] = useState<number | null>(null);
  const tableRef = useRef<HTMLDivElement | null>(null);
  const [isTableReady, setIsTableReady] = useState(false);

  useLayoutEffect(() => {
    if (tableRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          setTableWidth(entry.contentRect.width);
        }
      });

      observer.observe(tableRef.current);
      return () => observer.disconnect();
    }
  }, []);

  // Fetch outlet data
  const getOutletData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/outlet/dropdown`);
      if (response?.data?.success && response.data.data) {
        setOutlet(response.data.data);
        const outletExists = response.data.data.some(
          (item: any) => item.id === outletId
        );
        if (outletExists) {
          setSelectedOutlet(outletId);
        } else {
          setSelectedOutlet(response.data.data[0]?.id || null);
        }
      }
    } catch (error) {
      console.error("Error fetching outlet:", error);
    }
  };

  // Fetch sales summary data
  const fetchSalesSummary = async () => {
    if (!selectedOutlet) return;

    try {
      setIsLoading(true);
      const startDate = dateRange[0].format("DD-MM-YYYY");
      const endDate = dateRange[1].format("DD-MM-YYYY");
      const offset = currentPage;
      const endpoint = `/report/salessummary/${selectedOutlet}/${startDate}/${endDate}/${pageSize}/${offset}`;
      const response = await get(endpoint);

      if (response?.data?.success && response.data.data) {
        const apiData = response.data.data;

        // Map API response to DataSourceItem
        const mappedData: DataSourceItem[] = apiData.map(
          (item: any, index: number) => {
            // Calculate tax total and map taxes
            const taxObject: Record<string, number> = {};
            item.taxes
              .filter((tax: any) => tax.taxname !== null)
              .forEach((tax: any) => {
                taxObject[tax.taxname] = tax.item_tax_rate;
              });
            const taxTotal = Object.values(taxObject).reduce(
              (sum, value) => sum + value,
              0
            );

            // Get all unique payment methods
            const allPaymentMethods = Array.from(
              new Set(
                apiData.flatMap((row: any) =>
                  row.payment_methods.map((pm: any) => pm.payment_method)
                )
              )
            );

            // Map payment methods to billingonaccount
            const billingonaccount: Record<
              string,
              {
                product: number;
                service: number;
                privilege: number;
                tip: number;
                taxes: number;
                total: number;
              }
            > = {};
            allPaymentMethods.forEach((method: any) => {
              const pm = item.payment_methods.find(
                (p: any) => p.payment_method === method
              );
              billingonaccount[method] = pm
                ? {
                    product: pm.product,
                    service: pm.service,
                    privilege: pm.privilege,
                    tip: pm.tip,
                    taxes: pm.taxes,
                    total: pm.total,
                  }
                : {
                    product: 0,
                    service: 0,
                    privilege: 0,
                    tip: 0,
                    taxes: 0,
                    total: 0,
                  };
            });

            return {
              key: index.toString(),
              date: item.order_date,
              turnover: parseFloat(item.turnover.toFixed(2)),
              collectedrevenue: parseFloat(item.collected_revenue.toFixed(2)),
              otherincome: parseFloat(item.otherincome.toFixed(2)),
              expense: parseFloat(item.expense.toFixed(2)),
              netrevenue: parseFloat(item.net_revenue.toFixed(2)),
              netcollectedrevenue: parseFloat(
                item.net_collected_revenue.toFixed(2)
              ),
              outstandingpayment: parseFloat(
                item.outstanding_payment.toFixed(2)
              ),
              repayment: parseFloat(item.repayment.toFixed(2)),
              fulfilledperformance: parseFloat(
                item.fulfilledperformance.toFixed(2)
              ),
              privilagecard: parseFloat(item.privilegecardsales.toFixed(2)),
              tips: parseFloat(item.tips.toFixed(2)),
              customer_flow: parseFloat(item.customer_flow.toFixed(2)),
              numberofcustomers: {
                newReturning: `${item.new_customer}/${item.returning_customer}`,
                maleFemale: `${item.male_count}/${item.female_count}`,
              },
              tax: Object.fromEntries(
                Object.entries(taxObject).map(([key, value]) => [
                  key,
                  parseFloat(value.toFixed(2)),
                ])
              ),
              taxTotal: parseFloat(taxTotal.toFixed(2)),
              billingonaccount: Object.fromEntries(
                Object.entries(billingonaccount).map(([key, value]) => [
                  key,
                  {
                    product: parseFloat(value.product.toFixed(2)),
                    service: parseFloat(value.service.toFixed(2)),
                    privilege: parseFloat(value.privilege.toFixed(2)),
                    tip: parseFloat(value.tip.toFixed(2)),
                    taxes: parseFloat(value.taxes.toFixed(2)),
                    total: parseFloat(value.total.toFixed(2)),
                  },
                ])
              ),
            };
          }
        );

        console.log("mappedData", mappedData);

        setDataSource(mappedData);
        setTotalRecords(response.data.totalrecords);

        setTimeout(() => {
          if (tableRef.current) {
            const newWidth = tableRef.current.getBoundingClientRect().width;
            setTableWidth(newWidth);
          }
        }, 100);
      } else {
        console.error("Failed to fetch sales summary data");
        setDataSource([]);
        setTotalRecords(0);
      }
    } catch (error) {
      console.error("Error fetching sales summary:", error);
      setDataSource([]);
      setTotalRecords(0);
    } finally {
      setIsLoading(false);
    }
  };

  // Fetch data on mount and when dependencies change
  useEffect(() => {
    getOutletData();
  }, []);

  useEffect(() => {
    fetchSalesSummary();
  }, [currentPage, pageSize]);

  // Extract unique tax names and payment methods from the API response
  const uniqueTaxNames = useMemo(() => {
    const allTaxes = dataSource.flatMap((item) =>
      Object.keys(item.tax).map((key) => ({
        taxname: key,
        item_tax: key,
      }))
    );
    const uniqueTaxes = Array.from(
      new Map(allTaxes.map((tax) => [tax.item_tax, tax])).values()
    );
    return uniqueTaxes;
  }, [dataSource]);

  const uniquePaymentMethods = useMemo(() => {
    return Array.from(
      new Set(dataSource.flatMap((item) => Object.keys(item.billingonaccount)))
    );
  }, [dataSource]);

  // Define columns with nested subrows using useMemo
  const initialColumns: CustomColumnType[] = useMemo(() => {
    const numberofcustomersChildren = [
      {
        title: "New/Returning",
        dataIndex: ["numberofcustomers", "newReturning"],
        key: "newReturning",
        width: 150,
      },
      {
        title: "Male/Female",
        dataIndex: ["numberofcustomers", "maleFemale"],
        key: "maleFemale",
        width: 150,
      },
    ];

    const taxChildren = [
      ...uniqueTaxNames.map((tax) => ({
        title: tax.taxname,
        dataIndex: ["tax", tax.item_tax],
        key: tax.item_tax,
        width: 150,
        render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
      })),
      {
        title: "Total",
        dataIndex: "taxTotal",
        key: "taxTotal",
        width: 150,
        render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
      },
    ];

    const billingOnAccountChildren = uniquePaymentMethods.map((method) => ({
      title: method,
      key: method,
      children: [
        {
          title: "Product",
          dataIndex: ["billingonaccount", method, "product"],
          key: `${method}-product`,
          width: 150,
          render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
        },
        {
          title: "Service",
          dataIndex: ["billingonaccount", method, "service"],
          key: `${method}-service`,
          width: 150,
          render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
        },
        {
          title: "Privilege",
          dataIndex: ["billingonaccount", method, "privilege"],
          key: `${method}-privilege`,
          width: 150,
          render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
        },
        {
          title: "Tip",
          dataIndex: ["billingonaccount", method, "tip"],
          key: `${method}-tip`,
          width: 150,
          render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
        },
        {
          title: "Taxes",
          dataIndex: ["billingonaccount", method, "taxes"],
          key: `${method}-taxes`,
          width: 150,
          render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
        },
        {
          title: "Total",
          dataIndex: ["billingonaccount", method, "total"],
          key: `${method}-total`,
          width: 150,
          render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
        },
      ],
    }));

    return [
      {
        title: "Date",
        dataIndex: "date",
        fixed: "left" as "left",
        key: "date",
        width: 120,
      },
      {
        title: "Turnover",
        dataIndex: "turnover",
        key: "turnover",
        width: 150,
        render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
      },
      {
        title: "Collected revenue",
        dataIndex: "collectedrevenue",
        key: "collectedrevenue",
        width: 200,
        render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
      },
      {
        title: "Other income",
        dataIndex: "otherincome",
        key: "otherincome",
        width: 150,
        render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
      },
      {
        title: "Expense",
        dataIndex: "expense",
        key: "expense",
        width: 150,
        render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
      },
      {
        title: "Net revenue",
        dataIndex: "netrevenue",
        key: "netrevenue",
        width: 150,
        render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
      },
      {
        title: "Net collected revenue",
        dataIndex: "netcollectedrevenue",
        key: "netcollectedrevenue",
        width: 200,
        render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
      },
      {
        title: "Outstanding payment",
        dataIndex: "outstandingpayment",
        key: "outstandingpayment",
        width: 150,
        render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
      },
      {
        title: "Repayment",
        dataIndex: "repayment",
        key: "repayment",
        width: 150,
        render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
      },
      {
        title: "Customer flow",
        dataIndex: "customer_flow",
        key: "customer_flow",
        width: 150,
      },
      {
        title: "Number of Customers",
        key: "numberofcustomers",
        width: numberofcustomersChildren.reduce(
          (sum, child) => sum + (child.width || 0),
          0
        ),
        children: numberofcustomersChildren,
      },
      {
        title: "Tax",
        key: "tax",
        width: taxChildren.reduce((sum, child) => sum + (child.width || 0), 0),
        children: taxChildren,
      },
      {
        title: "Tips",
        dataIndex: "tips",
        key: "tips",
        width: 150,
        render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
      },
      {
        title: "Fulfilled performance",
        dataIndex: "fulfilledperformance",
        key: "fulfilledperformance",
        width: 150,
        render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
      },
      {
        title: "Privilege card sales",
        dataIndex: "privilagecard",
        key: "privilegecard",
        width: 150,
        render: (value: number) =>
          `${currencySymbol.CURRENCY_SYMBOL}${(value ?? 0).toLocaleString()}`,
      },
      {
        title: "Billing on account",
        key: "billingonaccount",
        width: billingOnAccountChildren.reduce(
          (sum, child) =>
            sum +
            (child.children?.reduce(
              (childSum, subChild) => childSum + (subChild.width || 0),
              0
            ) || 0),
          0
        ),
        children: billingOnAccountChildren,
      },
    ];
  }, [uniqueTaxNames, uniquePaymentMethods]);

  const mandatoryColumns = ["date"];
  const [columns, setColumns] = useState<ColumnsType<DataSourceItem>>([]);

  useEffect(() => {
    if (isLoading) return;

    const updatedColumns: ColumnsType<DataSourceItem> = initialColumns.map(
      (col) => {
        if (col.children) {
          const filteredChildren = col.children.map((child) => ({
            ...child,
            width: child.width || 150,
          }));
          return {
            ...col,
            width: filteredChildren.reduce(
              (sum, child) => sum + (child.width || 0),
              0
            ),
            children: filteredChildren,
          };
        }
        return { ...col, width: col.width || 150 };
      }
    );

    setColumns(updatedColumns);

    setSelectedColumns([
      ...updatedColumns
        .filter(
          (
            col
          ): col is Exclude<
            ColumnsType<DataSourceItem>[number],
            { children?: CustomColumnType[] }
          > => !("children" in col)
        )
        .map((col) => col.key)
        .filter((key): key is string => key !== undefined),
      ...updatedColumns
        .filter(
          (
            col
          ): col is Extract<
            ColumnsType<DataSourceItem>[number],
            { children: CustomColumnType[] }
          > => "children" in col
        )
        .flatMap((col) =>
          "children" in (col as CustomColumnType) &&
          (col as CustomColumnType).children
            ? (col as CustomColumnType).children!.map((child) => child.key)
            : []
        )
        .filter((key): key is string => key !== undefined),
    ]);
  }, [initialColumns, isLoading]);

  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [lastSelectedColumns, setLastSelectedColumns] = useState<string[]>([]);

  const availableColumnsForModal = [
    ...initialColumns
      .filter((col) => !col.children)
      .map((col) => ({
        key: col.key,
        title: col.title,
      })),
    ...initialColumns
      .filter((col) => col.children)
      .flatMap((col) =>
        col.children!.map((child) => ({
          key: child.key,
          title: `${col.title} - ${child.title}`,
        }))
      ),
  ];

  const displayedColumns: ColumnsType<DataSourceItem> = useMemo(() => {
    const flattenedColumns: ColumnsType<DataSourceItem> = [];

    columns.forEach((col) => {
      if (col.key === "billingonaccount" && "children" in col && col.children) {
        const filteredBillingChildren = col.children.filter(
          (child) =>
            child.key !== undefined &&
            selectedColumns.includes(String(child.key))
        );
        flattenedColumns.push(
          ...filteredBillingChildren.map((child) => ({
            ...child,
            width: child.width || 150, // Ensure width is set
          }))
        );
      } else if ("children" in col && col.children) {
        const filteredChildren = col.children.filter(
          (child) =>
            child.key !== undefined &&
            selectedColumns.includes(String(child.key))
        );
        if (filteredChildren.length > 0) {
          const updatedChildren = filteredChildren.map((child) => ({
            ...child,
            width: child.width || 150, // Ensure width is set for children
          }));
          flattenedColumns.push({
            ...col,
            width: updatedChildren.reduce(
              (sum, child) =>
                sum + (typeof child.width === "number" ? child.width : 150),
              0
            ), // Recalculate parent width
            children: updatedChildren,
          });
        }
      } else if (selectedColumns.includes(String(col.key))) {
        flattenedColumns.push({ ...col, width: col.width || 150 });
      }
    });

    return flattenedColumns;
  }, [columns, selectedColumns]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys: React.Key[]) => setSelectedRowKeys(selectedKeys),
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page - 1);
  };

  const handlePageSizeChange = (value: number) => {
    setPageSize(value);
    setCurrentPage(0);
  };

  const handleRunFilter = () => {
    setCurrentPage(0);
    fetchSalesSummary();
  };

  const handleColumnsClick = () => {
    setIsModalVisible(true);
  };

  const handleModalSave = (
    selectedCols: string[],
    reorderedColumns: { key: string; title: string }[]
  ) => {
    setSelectedColumns(selectedCols);
    setLastSelectedColumns(selectedCols);

    const newColumns: ColumnsType<DataSourceItem> = initialColumns.map(
      (col) => {
        if ("children" in col && col.children) {
          const filteredChildren = col.children.filter((child) =>
            selectedCols.includes(child.key)
          );
          return {
            ...col,
            children: filteredChildren.map((child) => ({
              ...child,
              width: child.width || 150,
            })),
          };
        }
        return { ...col, width: col.width || 150 };
      }
    );

    setColumns(newColumns);
  };

  const tableKey = useMemo(
    () =>
      `${JSON.stringify(
        displayedColumns.map((col) => ({
          key: col.key,
          children:
            "children" in col
              ? col.children?.map((child) => child.key)
              : undefined,
        }))
      )}-${Date.now()}`, // Add timestamp to force re-render
    [displayedColumns]
  );

  // Calculate totals and append a total row to the dataSource
  const dataSourceWithTotal = useMemo(() => {
    if (!dataSource.length) return [];

    // Calculate totals for each numeric field
    const totals: Partial<DataSourceItem> = {
      key: "total",
      date: "Total",
      turnover: 0,
      collectedrevenue: 0,
      otherincome: 0,
      expense: 0,
      netrevenue: 0,
      netcollectedrevenue: 0,
      outstandingpayment: 0,
      repayment: 0,
      fulfilledperformance: 0,
      privilagecard: 0,
      tips: 0,
      customer_flow: 0,
      numberofcustomers: {
        newReturning: "0",
        maleFemale: "0",
      },
      tax: {},
      taxTotal: 0,
      billingonaccount: {},
    };

    // Initialize tax and billingonaccount structures
    uniqueTaxNames.forEach((tax) => {
      totals.tax![tax.taxname] = 0;
    });

    uniquePaymentMethods.forEach((method) => {
      totals.billingonaccount![method] = {
        tip: 0,
        taxes: 0,
        total: 0,
        product: 0,
        service: 0,
        privilege: 0,
      };
    });

    // Sum up the values
    dataSource.forEach((item) => {
      totals.turnover! += item.turnover;
      totals.collectedrevenue! += item.collectedrevenue;
      totals.otherincome! += item.otherincome;
      totals.expense! += item.expense;
      totals.netrevenue! += item.netrevenue;
      totals.netcollectedrevenue! += item.netcollectedrevenue;
      totals.outstandingpayment! += item.outstandingpayment;
      totals.repayment! += item.repayment;
      totals.fulfilledperformance! += item.fulfilledperformance;
      totals.privilagecard! += item.privilagecard;
      totals.tips! += item.tips;
      totals.customer_flow! += item.customer_flow;
      const newReturningTotals = dataSource.reduce(
        (acc, item) => {
          const [newCustomer, returningCustomer] =
            item.numberofcustomers.newReturning
              .toString()
              .split("/")
              .map(Number);
          acc.newCustomer += newCustomer;
          acc.returningCustomer += returningCustomer;
          return acc;
        },
        { newCustomer: 0, returningCustomer: 0 }
      );
      const maleFemaleTotals = dataSource.reduce(
        (acc, item) => {
          const [maleCustomer, femaleCustomer] =
            item.numberofcustomers.maleFemale.toString().split("/").map(Number);
          acc.maleCustomer += maleCustomer;
          acc.femaleCustomer += femaleCustomer;
          return acc;
        },
        { maleCustomer: 0, femaleCustomer: 0 }
      );
      totals.numberofcustomers!.newReturning = `${newReturningTotals.newCustomer}/${newReturningTotals.returningCustomer}`;
      // totals.numberofcustomers!.newReturning = `${totals.numberofcustomers!.newReturning.newCustomer}/${totals.numberofcustomers!.newReturning.returningCustomer}`;
      totals.numberofcustomers!.maleFemale = `${maleFemaleTotals.maleCustomer}/${maleFemaleTotals.femaleCustomer}`;
      totals.taxTotal! += item.taxTotal;

      // Sum taxes
      Object.keys(item.tax).forEach((taxName) => {
        totals.tax![taxName]! += item.tax[taxName];
      });

      // Sum billingonaccount
      Object.keys(item.billingonaccount).forEach((method) => {
        totals.billingonaccount![method]!.tip +=
          item.billingonaccount[method].tip;
        totals.billingonaccount![method]!.taxes +=
          item.billingonaccount[method].taxes;
        totals.billingonaccount![method]!.total +=
          item.billingonaccount[method].total;
        totals.billingonaccount![method]!.product +=
          item.billingonaccount[method].product;
        totals.billingonaccount![method]!.service +=
          item.billingonaccount[method].service;
        totals.billingonaccount![method]!.privilege +=
          item.billingonaccount[method].privilege;
      });
    });

    // Round all numeric values to 2 decimal places
    totals.turnover = parseFloat(totals.turnover!.toFixed(2));
    totals.collectedrevenue = parseFloat(totals.collectedrevenue!.toFixed(2));
    totals.otherincome = parseFloat(totals.otherincome!.toFixed(2));
    totals.expense = parseFloat(totals.expense!.toFixed(2));
    totals.netrevenue = parseFloat(totals.netrevenue!.toFixed(2));
    totals.netcollectedrevenue = parseFloat(
      totals.netcollectedrevenue!.toFixed(2)
    );
    totals.outstandingpayment = parseFloat(
      totals.outstandingpayment!.toFixed(2)
    );
    totals.repayment = parseFloat(totals.repayment!.toFixed(2));
    totals.fulfilledperformance = parseFloat(
      totals.fulfilledperformance!.toFixed(2)
    );
    totals.privilagecard = parseFloat(totals.privilagecard!.toFixed(2));
    totals.tips = parseFloat(totals.tips!.toFixed(2));
    totals.customer_flow! = parseFloat(totals.customer_flow!.toFixed(2));
    totals.numberofcustomers!.newReturning =
      totals.numberofcustomers!.newReturning;
    totals.numberofcustomers!.maleFemale = totals.numberofcustomers!.maleFemale;
    totals.taxTotal = parseFloat(totals.taxTotal!.toFixed(2));

    Object.keys(totals.tax!).forEach((taxName) => {
      totals.tax![taxName] = parseFloat(totals.tax![taxName]!.toFixed(2));
    });

    Object.keys(totals.billingonaccount!).forEach((method) => {
      totals.billingonaccount![method].tip = parseFloat(
        totals.billingonaccount![method].tip.toFixed(2)
      );
      totals.billingonaccount![method].taxes = parseFloat(
        totals.billingonaccount![method].taxes.toFixed(2)
      );
      totals.billingonaccount![method].total = parseFloat(
        totals.billingonaccount![method].total.toFixed(2)
      );
      totals.billingonaccount![method].product = parseFloat(
        totals.billingonaccount![method].product.toFixed(2)
      );
      totals.billingonaccount![method].service = parseFloat(
        totals.billingonaccount![method].service.toFixed(2)
      );
      totals.billingonaccount![method].privilege = parseFloat(
        totals.billingonaccount![method].privilege.toFixed(2)
      );
    });

    // Append the total row to the dataSource
    return [...dataSource, totals as DataSourceItem];
  }, [dataSource, uniqueTaxNames, uniquePaymentMethods]);

  useEffect(() => {
    const updateTableWidth = () => {
      if (tableRef.current) {
        setTableWidth(tableRef.current.getBoundingClientRect().width);
      }
    };

    // Run after data is set
    if (dataSource.length > 0) {
      setTimeout(updateTableWidth, 100);
    }

    // Listen for window resize
    window.addEventListener("resize", updateTableWidth);
    return () => window.removeEventListener("resize", updateTableWidth);
  }, [isLoading, dataSource, displayedColumns]);

  const customStyles = `
  :where(.css-tjsggz).ant-table-wrapper,
  :where(.css-49qm).ant-table-wrapper,
  :where(.css-1j8v0g2).ant-table-wrapper,
  :where(.css-dev-only-do-not-override-161f05s).ant-table-wrapper {
    width: 100% !important;
  }
  :where(.css-dev-only-do-not-override-161f05s).ant-table-wrapper .ant-table-thead > tr > th,
  :where(.css-49qm).ant-table-wrapper .ant-table-thead > tr > th,
  :where(.css-1j8v0g2).ant-table-wrapper .ant-table-thead > tr > th,
  :where(.css-tjsggz).ant-table-wrapper .ant-table-thead > tr > th {
    position: relative;
    color: rgba(0, 0, 0, 0.88) !important;
    font-weight: 600 !important;
    background: #fafafa !important;
    border: 1px solid #f0f0f0 !important;
    transition: background 0.2s ease !important;
    padding: 0 16px !important;
    width: ${
      tableWidth ? `${tableWidth / displayedColumns.length}px` : "auto"
    } !important;
    max-width: fit-content !important;
    max-width: fit-content !important;
    box-sizing: border-box !important;
    white-space: nowrap; 
  }
    .ant-table-header {
    align-items: center;
    background: #fafbff;
    border: 1px solid #e0e7fe;
    border-radius: 4px;
    border-spacing: 0;
    display: flex;
    height: 40px;
    margin-bottom: 4px;
    width: 100% important;
}
`;

  return (
    <div style={{ padding: "20px" }}>
      <Row
        justify="space-between"
        align="middle"
        style={{ marginBottom: "16px" }}
      >
        <Col>
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowLeftOutlined
              style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
            />
            <Title level={4} style={{ margin: 0 }}>
              Sales Summary Report
            </Title>
          </div>
        </Col>
        <Col>
          <Space>
            <Button
              icon={<UnorderedListOutlined />}
              onClick={handleColumnsClick}
              style={{ height: 40 }}
            >
              Columns
            </Button>
          </Space>
        </Col>
      </Row>

      <Content
        style={{
          marginTop: "10px",
          backgroundColor: "#f6f7f9",
          borderRadius: "12px",
          padding: "24px 24px 10px",
          border: "1px solid #e0e7fe",
          boxSizing: "border-box",
        }}
      >
        <div style={{ marginBottom: "16px", borderRadius: "8px" }}>
          <Row gutter={16} justify="start" style={{ marginBottom: "16px" }}>
            <Col>
              <Select
                value={selectedOutlet === null ? "all" : selectedOutlet}
                onChange={(value) =>
                  setSelectedOutlet(value === "all" ? null : value)
                }
                placeholder="Select outlet"
                style={{ width: 200, height: "46px", borderRadius: "4px" }}
                allowClear
              >
                {/* <Option value="all">All outlets</Option> */}
                {outlet.map((outlet) => (
                  <Option key={outlet.id} value={outlet.id}>
                    {outlet.name}
                  </Option>
                ))}
              </Select>
            </Col>
            {/* <Col style={{ alignContent: "center" }}>
              <Checkbox>Including sub stores</Checkbox>
            </Col> */}
            <Col style={{ display: "flex", justifyContent: "center" }}>
              <RangePicker
                value={dateRange}
                onChange={(dates) =>
                  dates && setDateRange(dates as [dayjs.Dayjs, dayjs.Dayjs])
                }
                format="DD/MM/YYYY"
                style={{
                  height: "46px",
                  borderRadius: "4px",
                  textAlignLast: "center"
                }}
                allowClear={false}
                ranges={{
                  Today: [dayjs().startOf("day"), dayjs().endOf("day")],
                  Yesterday: [
                    dayjs().subtract(1, "day").startOf("day"),
                    dayjs().subtract(1, "day").endOf("day"),
                  ],
                  "This Week": [dayjs().startOf("week"), dayjs().endOf("week")],
                  "This Month": [
                    dayjs().startOf("month"),
                    dayjs().endOf("month"),
                  ],
                  "Last Month": [
                    dayjs().subtract(1, "month").startOf("month"),
                    dayjs().subtract(1, "month").endOf("month"),
                  ],
                }}
              />
            </Col>

            <Col style={{ marginLeft: "auto" }}>
              <Space>
                <Button
                  style={{ height: "46px", width: "80px" }}
                  type="primary"
                  onClick={handleRunFilter}
                >
                  Run
                </Button>
              </Space>
            </Col>
          </Row>
        </div>

        {isLoading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <LoadingOutlined style={{ fontSize: 24 }} />
          </div>
        ) : (
          <div ref={tableRef}>
            <style>{customStyles}</style>
            <Datatable
              key={tableKey}
              columns={displayedColumns}
              dataSource={dataSourceWithTotal}
              scroll={{ y: "calc(100vh - 320px)", x: "max-content" }}
              showCheckbox={false}
              rowSelection={rowSelection}
              showPagination={false}
              dynamicTableHeight={"calc(100vh - 300px)"}
            />
          </div>
        )}

        <Row
          justify="space-between"
          style={{
            alignItems: "center",
            marginTop: "40px",
            marginBottom: "16px",
          }}
        >
          <Col>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              style={{ width: 200, height: "46px", borderRadius: "4px" }}
            >
              <Option value={10}>10 entries/page</Option>
              <Option value={20}>20 entries/page</Option>
              <Option value={50}>50 entries/page</Option>
              <Option value={100}>100 entries/page</Option>
            </Select>
          </Col>

          {totalRecords >= 0 && (
            <Col>
              <Row justify="end" align="middle" style={{ gap: "16px" }}>
                <Col>
                  <span
                    style={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "rgba(0,0,0,.85)",
                    }}
                  >
                    Total {totalRecords} items
                  </span>
                </Col>
                <Col>
                  <Pagination
                    current={currentPage + 1}
                    pageSize={pageSize}
                    total={totalRecords}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                    itemRender={(page, type, originalElement) => {
                      if (type === "prev") {
                        const isDisabled = currentPage === 0;
                        return (
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                              color: isDisabled ? "#bbb" : "#2e56f2",
                              pointerEvents: isDisabled ? "none" : "auto",
                            }}
                          >
                            <LeftOutlined />
                            <span>Previous</span>
                          </a>
                        );
                      }
                      if (type === "next") {
                        const isDisabled =
                          currentPage ===
                          Math.ceil(totalRecords / pageSize) - 1;
                        return (
                          <a
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                              fontSize: "12px",
                              fontWeight: "700",
                              color: isDisabled ? "#bbb" : "#2e56f2",
                              pointerEvents: isDisabled ? "none" : "auto",
                            }}
                          >
                            <span>Next</span>
                            <RightOutlined />
                          </a>
                        );
                      }
                      return originalElement;
                    }}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  />
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Content>

      <ColumnsReportsModal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onSave={handleModalSave}
        availableColumns={availableColumnsForModal}
        initialSelectedColumns={selectedColumns}
        lastSelectedColumns={lastSelectedColumns}
        mandatoryColumns={mandatoryColumns}
      />
    </div>
  );
};

export default SalesSummaryReport;
