import React, { useEffect, useState } from "react";
import {
  Layout,
  Button,
  Input,
  Select,
  Row,
  Col,
  Switch,
  Menu,
  message,
  Modal,
  Checkbox,
  Pagination,
} from "antd";
import {
  DownloadOutlined,
  FilterOutlined,
  LeftOutlined,
  PlusOutlined,
  RightOutlined,
  SearchOutlined,
  SettingFilled,
  SketchOutlined,
  TruckOutlined,
  UnorderedListOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { createStyles } from "antd-style";
import { deleteData, get, post, put } from "../../services/Apiclient";
import Datatable from "../../components/Datatable";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Dropdown from "antd/es/dropdown/dropdown";
import Mainmodel from "../../components/model/Mainmodel";
import SupplierList from "../supplier/SupplierList";
import ConfirmDeleteModal from "../../components/model/DeleteModal";
import { useCurrency } from "../../contexts/CurrencyContext";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const { Option } = Select;
const { Content } = Layout;
interface ServiceData {
  key: React.Key;
  servicecode: string;
  servicename: string;
  category: string;
  price: string;
  servicetime: string;
  status: string;
  onlinebooking: boolean;
}

interface categoryData {
  key: React.Key;
  id: string;
  name: string;
  type: string;
  noofproduct: number;
  outlet_id: string;
  status: number;
}

//     title: "Service Code",
//     dataIndex: "servicecode",
//     key: "servicecode",
//     width: 150,
//     sorter: (a: ServiceData, b: ServiceData) =>
//       a.servicecode.localeCompare(b.servicecode),
//   },
//   {
//     title: "Service Name",
//     dataIndex: "servicename",
//     key: "servicename",
//     width: 200,
//     sorter: (a: ServiceData, b: ServiceData) =>
//       a.servicename.localeCompare(b.servicename),
//   },
//   {
//     title: "Category",
//     dataIndex: "category",
//     key: "category",
//     width: 150,
//   },
//   {
//     title: "Price",
//     dataIndex: "price",
//     key: "price",
//     width: 150,
//     sorter: (a: ServiceData, b: ServiceData) =>
//       parseFloat(a.price) - parseFloat(b.price),
//   },
//   {
//     title: "Service Time",
//     dataIndex: "servicetime",
//     key: "servicetime",
//     width: 150,
//   },
//   {
//     title: "Status",
//     dataIndex: "status",
//     key: "status",
//     width: 100,
//   },
//   {
//     title: "Online Booking",
//     dataIndex: "onlinebooking",
//     key: "onlinebooking",
//     width: 100,
//     render: (onlinebooking: boolean): React.ReactNode => (
//       <Switch checked={onlineBooking} onChange={handleToggle} />
//     )
//   },
//   {
//     title: "Action",
//     key: "operation",
//     fixed: "right" as "right",
//     width: 100,
//     render: (): React.ReactNode => <a>Action</a>,
//   },
// ];

const useStyle = createStyles(({ css }) => ({
  customTable: css`
    .ant-table {
      .ant-table-container {
        .ant-table-body,
        .ant-table-content {
          scrollbar-width: thin;
          scrollbar-color: unset;
        }
      }
    }
  `,
}));
function ServiceList() {
  const navigate = useNavigate();
  const { currencySymbol } = useCurrency();
  const [dataSource, setDataSource] = useState<ServiceData[]>([]);
  const [type, setType] = useState("");
  const [categoryVisible, setCategoryvisible] = useState(false);
  const [visibleCategory, setvisibleCategory] = useState(false);
  const [dataSourceCategory, setDataSourceCategory] = useState<categoryData[]>(
    []
  );
  const [categoryData, setCategoryData] = useState<any[]>([]);
  const [outletData, setOutletData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [onlineBooking, setOnlineBooking] = useState(false);
  const [categorycode, setCategorycode] = useState("");
  const [outletcode, setOutletcode] = useState(
    localStorage.getItem("outlet_id")
  );
  const [searchText, setSearchText] = useState("");

  const [status, setStatus] = useState<string[]>([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [valueChangeSearch, setValueChangeSearch] = useState(false);
  const [originalDataSource, setOriginalDataSource] = useState<ServiceData[]>(
    []
  );
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [editingRecord, setEditingRecord] = useState<any>(null);
  const filterIconStyle = {
    color: status.length > 0 ? "#1890FF" : "#aaa",
    cursor: "pointer",
    marginLeft: "8px",
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  const openDeleteModal = () => setIsDeleteModalVisible(true);
  const closeDeleteModal = () => {
    setIsDeleteModalVisible(false);
  };

  const handleDelete = async () => {
    if (editingRecord) {
      try {
        openDeleteModal();
      } catch (error) {
        message.error(`Error deleting ${type}`);
      }
    }
  };

  const handleConfirm = () => {
    handleDeleteApi(editingRecord.key);
    closeDeleteModal();
  };

  const handleDeleteApi = async (id: string) => {
    try {
      const response = await deleteData(`/service/remove/${id}`);
      if (response.data.success) {
        message.success("Service deleted successfully!");
        fetchData();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Error deleting Service");
    }
  };

  const handleServiceStatusChange = (checkedValues: any) => {
    setStatus(checkedValues);
  };
  const applyFilter = () => {
    if (status.length === 0) {
      setDataSource(originalDataSource);
    } else {
      const filteredData = originalDataSource.filter((item) =>
        status.includes(item.status)
      );
      setDataSource(filteredData);
    }
    setDropdownVisible(false);
  };
  const resetFilter = () => {
    setStatus([]);
    setDataSource(originalDataSource);
  };
  const serviceStatus = (
    <Menu>
      <Checkbox.Group
        style={{ padding: "10px" }}
        value={status}
        onChange={handleServiceStatusChange}
      >
        <Row>
          <Col span={24}>
            <Checkbox value="Active">Active</Checkbox>
          </Col>
          <Col span={24}>
            <Checkbox value="Inactive">Inactive</Checkbox>
          </Col>
        </Row>
      </Checkbox.Group>
      <Row justify="space-between" style={{ padding: "10px" }}>
        <Button onClick={resetFilter}>Reset</Button>
        <Button type="primary" onClick={applyFilter}>
          OK
        </Button>
      </Row>
    </Menu>
  );

  const columns = [
    {
      title: "Service Code",
      dataIndex: "servicecode",
      key: "servicecode",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Service Name",
      dataIndex: "servicename",
      key: "servicename",
      width: 300,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      width: 150,
      render: (text: any) => (
        <span
          style={{
            overflow: "hidden",
            textWrap: "wrap",
            wordBreak: "break-word",
            lineHeight:"normal"
          }}
        >
          {currencySymbol.CURRENCY_SYMBOL}
          {text}
        </span>
      ),
    },
    {
      title: "Service Time",
      dataIndex: "servicetime",
      key: "servicetime",
      width: 150,
    },
    {
      title: (
        <>
          Status
          <Dropdown
            overlay={serviceStatus}
            trigger={["click"]}
            visible={dropdownVisible}
            onVisibleChange={(flag) => setDropdownVisible(flag)}
            overlayStyle={{ width: "150px" }} // Adjust size here
          >
            <FilterOutlined style={filterIconStyle} />
          </Dropdown>
        </>
      ),
      dataIndex: "status",
      key: "status",
      width: 100,
    },
    {
      title: "Online Booking",
      dataIndex: "onlinebooking",
      key: "onlinebooking",
      width: 100,
      render: (
        onlinebooking: boolean,
        record: ServiceData
      ): React.ReactNode => (
        <Switch
          checked={onlinebooking}
          onChange={(checked) => handleToggle(record.key, checked)}
        />
      ),
    },
    {
      title: "Action",
      key: "operation",
      fixed: "right" as "right",
      width: 100,
      render: (text: any, record: ServiceData): React.ReactNode => (
        <>
          <a
            style={{
              color: "#2e56f2",
              textDecoration: "underline",
              textUnderlineOffset: "4px",
              fontWeight: 700,
              marginRight: "16px",
            }}
            onClick={() => navigate(`/settings/service/edit/${record.key}`)}
          >
            Edit
          </a>
          {/* <a style={{ color: 'red' }} onClick={() => handleDeleteClick(record)} > */}
          <a
            style={{
              color: "rgb(245, 63, 63)",
              fontWeight: 700,
              textDecoration: "underline",
              textUnderlineOffset: "4px",
            }}
            onClick={() => {
              setEditingRecord(record);
              handleDelete();
            }}
          >
            Delete
          </a>
        </>
      ),
    },
  ];

  const openCategorymodal = () => {
    setType("category");
    setCategoryvisible(true);
  };

  const closeCategorymodal = () => {
    setCategoryvisible(false);
    getServiceCatDropdown();
  };

  const openCategoryadd = () => {
    setvisibleCategory(true);
  };

  const closeCategoryadd = () => {
    setvisibleCategory(false);
    getServiceCatDropdown();
  };

  const handleCategoryStatusChange = async (checked: boolean, record: any) => {
    const updatedStatus = checked ? "true" : "false";
    try {
      const url = `/category/${record}`;
      const response = await put(url, { status: updatedStatus });
      if (response?.data?.success) {
        message.success("Status updated successfully");
        fetchCategory();
      } else {
        message.error("Failed to update status");
      }
    } catch (error) {
      console.error("Error updating status: ", error);
      message.error("Error updating status");
    }
  };

  const categoryColumns = [
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width: "20%",
      render: (text: any, record: any, index: number) => (
        <span>
          <span
            className="drag-handle-icon"
            style={{ marginRight: 3, fontWeight: "600" }}
          >
            {index + 1}.☰
          </span>
          {text}
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Count of services included",
      dataIndex: "noofproduct",
      key: "noofproduct",
      width: "20%",
      render: (count: number) => count || 0,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "10%",
      render: (onlinebooking: boolean, record: any) => (
        <Switch
          checked={onlinebooking}
          onChange={async (checked) => {
            try {
              await handleCategoryStatusChange(checked, record.key);
            } catch (error) {
              message.error("Failed to update brand status.");
              console.error("Error updating status:", error);
            }
          }}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "10%",
      render: () => <a>Edit</a>,
    },
  ];

  const menuItems = [
    {
      key: "1",
      icon: <UnorderedListOutlined />,
      label: "Category management",
      onClick: openCategorymodal,
    },
  ];

  const menu = <Menu items={menuItems} />;

  const handleToggle = async (key: React.Key, checked: boolean) => {
    try {
      let url = `/service/${key}`;

      let body = {
        onlinebooking: checked,
      };

      const response = await put(url, body);
      if (response && response.data) {
        if (response.data.success) {
          fetchData();
        } else {
          console.log("Error in api call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
    } catch (errors) {
      message.error("error occur in api call");
    }
  };

  const fetchCategory = async () => {
    try {
      const response = await get(
        `/category/getlistwithoutpagination/${localStorage.getItem(
          "outlet_id"
        )}/Service`
      );
      if (response.data.success) {
        const apiData = response.data.data.map((item: any) => ({
          key: item.id,
          code: item.code,
          name: item.name,
          type: item.type,
          noofproduct: item.noofproduct,
          status: item.status,
        }));

        // Update the data source state
        setDataSourceCategory(apiData);
      } else {
        message.error("Failed to fetch brands");
      }
    } catch (error) {
      console.error("Error fetching brands: ", error);
      message.error("Error fetching brands");
    }
  };

  const handleAddCategory = async (categoryName: string) => {
    const newCategory = {
      name: categoryName,
      outlet_id: localStorage.getItem("outlet_id"),
    };

    try {
      let url = "/category/addcategory/Service";
      const response = await post(url, newCategory);
      if (response && response.data) {
        if (response.data.success) {
          message.success("Form submitted successfully!");
          fetchCategory();
        } else {
          message.error(response.data.message);
          console.log("Error in api call: ", response.data.message);
        }
      } else {
        console.log("Response not found.");
      }
      // Update brand data source here
    } catch (error) {
      console.error("Error creating brand: ", error);
    }
  };

  const updateCategoriesSequence = async (updatedCategory: any[]) => {
    try {
      const url = `/category/updatesequence/Service`;
      const response = await put(url, {
        categories: updatedCategory,
      });
      if (response?.data?.success) {
        console.log("Category sequence updated successfully!");
      } else {
        message.error("Failed to update sequence");
      }
    } catch (error) {
      console.error("Error updating sequence: ", error);
      message.error("Error updating sequence");
    }
  };

  const handleEditCategory = async (id: string, brandName: string) => {
    try {
      const response = await put(`/category/${id}`, { name: brandName });
      if (response.data.success) {
        message.success("Category updated successfully!");
        fetchCategory();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Error updating Category");
    }
  };

  const handleDeleteCategory = async (id: string) => {
    try {
      const response = await deleteData(`/category/remove/${id}`);
      if (response.data.success) {
        message.success("Category deleted successfully!");
        fetchCategory();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error("Error deleting Category");
    }
  };

  const fetchCategoryDetails = async (id: string) => {
    try {
      const response = await get(`/category/${id}`);
      if (response.data.success) {
        return response.data.data;
      } else {
        message.error("Failed to fetch category details");
      }
    } catch (error) {
      message.error("Error fetching category details");
    }
  };

  const getOutletDropdown = async () => {
    try {
      let url = "/outlet/dropdown";
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: "null",
            name: "All ",
          });
          setOutletData(response.data.data);
          setOutletcode(localStorage.getItem("outlet_id") || "null");
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const getServiceCatDropdown = async () => {
    try {
      let url = `/category/dropdown/type/${localStorage.getItem(
        "outlet_id"
      )}/Service`;
      let response = await get(url);

      if (response && response.data) {
        if (response.data.success) {
          let data = response.data.data;
          data.unshift({
            id: "null",
            name: "All categories",
          });
          setCategoryData(data);
          setCategorycode("null");
        } else {
          console.log("Error in Api call: ", response.data.message);
        }
      } else {
        console.log("Response not found");
      }
    } catch (errors: any) {
      errors.inner.forEach((error: any) => {
        message.error(error.message);
      });
    }
  };

  const handleOutletChange = (value: string) => {
    setOutletcode(value);
  };

  const handleCategoryChange = (value: string) => {
    setCategorycode(value);
  };

  useEffect(() => {
    fetchData();
  }, [categorycode, outletcode]);

  useEffect(() => {
    getOutletDropdown();
    getServiceCatDropdown();
  }, []);

  useEffect(() => {
    if (categoryVisible) {
      fetchCategory();
    }
  }, [categoryVisible]);

  const fetchData = async () => {
    try {
      let outletid: any = outletcode;
      if (localStorage.getItem("userRole") !== "Merchant") {
        outletid = localStorage.getItem("outlet_id");
      }
      const url = `/service/list/${currentPage}/${pageSize}/servicename/DESC/${
        searchText ? searchText : "null"
      }/${categorycode == "" ? null : categorycode}/${
        outletid == "" ? null : outletid
      }`;
      const response = await get(url);
      if (response?.data?.success) {
        const apiData = response.data.data.map(
          (item: any): ServiceData => ({
            key: item.id,
            servicecode: item.servicecode,
            servicename: item.servicename,
            category: item.categoryname,
            price: item.costprice,
            servicetime: item.duration,
            status: item.status,
            onlinebooking: item.onlinebooking,
          })
        );
        setDataSource(apiData);
        setOriginalDataSource(apiData);
        setTotalRecords(response.data.totalRecords);
      } else {
        console.log("Error in API call: ", response);
      }
    } catch (error) {
      console.error("Fetch error: ", error);
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === "Enter") {
      fetchData();
    }
  };

  useEffect(() => {
    if (valueChangeSearch) {
      fetchData();
    }
  }, [valueChangeSearch]);

  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    await setSearchText(e.target.value);
    await setValueChangeSearch(e.target.value.length === 0);
  };

  const handleSearchClear = async () => {
    await setSearchText("");
    setValueChangeSearch(true);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize]);
  const handlePageChange = (page: number, pageSize?: number) => {
    setCurrentPage(page);
    if (pageSize) {
      setPageSize(pageSize);
    }
  };
  const { styles } = useStyle();
  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Content className="mainContainer">
        {/* Header Section */}
        <Row justify="space-between" align="middle">
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <div
                onClick={() => navigate("/settings")}
                style={{ cursor: "pointer" }}
              >
                <ArrowLeftOutlined
                  style={{ marginRight: 12, color: "#325DF2", fontSize: 18 }}
                />
              </div>
              <h2 style={{ margin: 0 }}>Service List</h2>
            </div>
          </Col>
          <Col>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button icon={<DownloadOutlined />} className="export-button">
                Import
              </Button>
              <Button icon={<UploadOutlined />} className="export-button">
                Export
              </Button>
              <Button className="quick-settings">Quick Settings</Button>
              <Button
                className="add-button"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate("/settings/service/add")}
              >
                Add
              </Button>
              <Dropdown overlay={menu} trigger={["click"]}>
                <Button
                  type="default"
                  icon={<SettingFilled />}
                  className="setting-icon"
                />
              </Dropdown>
            </div>
          </Col>
        </Row>

        {/* </Header> */}
        {/* Content Section */}
        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#F6F7F9",
            borderRadius: "12px",
            padding: "24px 24px 10px",
            border: "1px solid #E0E7FE",
            boxSizing: "border-box",
          }}
        >
          {/* Filters */}
          <Row gutter={16} style={{ marginBottom: "16px" }}>
            <Col>
              <Select
                placeholder="Select Outlet"
                value={outletcode}
                onChange={(value) => handleOutletChange(value)}
                className="dropdown"
                style={{
                  width: "198.8px",
                  height: "40px",
                  alignItems: "center",
                }}
                disabled={localStorage.getItem("userRole") !== "Merchant"}
              >
                {outletData.length > 0 &&
                  outletData.map((cate: any) => (
                    <Option value={cate.id} key={cate.id}>
                      {cate.name}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col>
              <Select
                placeholder="All categories"
                value={categorycode}
                onChange={(value) => handleCategoryChange(value)}
                style={{
                  width: "198.8px",
                  height: "40px",
                  alignItems: "center",
                }}
              >
                {categoryData.length > 0 &&
                  categoryData.map((cate: any) => (
                    <Option value={cate.id} key={cate.id}>
                      {cate.name}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col flex="auto">
              <Input
                placeholder="Search by service code / name"
                value={searchText}
                onChange={handleInputChange}
                onClear={handleSearchClear}
                allowClear
                onKeyPress={handleKeyPress}
                prefix={
                  <SearchOutlined
                    style={{
                      color: "rgba(0,0,0,.45)",
                      marginRight: "2px",
                      alignItems: "center",
                    }}
                  />
                }
                style={{ width: "280px", height: "44px", alignItems: "center" }}
              />
            </Col>
            <Col>
              <Button type="primary" onClick={() => fetchData()}>
                Search
              </Button>
            </Col>
          </Row>
          {/* Table */}
          <Datatable<ServiceData>
            columns={columns}
            dataSource={dataSource}
            showPagination={false}
            scroll={{ y: "calc(100vh - 280px)" }}
            showCheckbox={true}
            rowSelection={rowSelection}
            dynamicTableHeight="calc(100vh - 240px)"
          />
          <Row
            justify="space-between"
            style={{
              padding: "10px 20px",
              alignItems: "center",
              borderRadius: "8px",
            }}
          >
            {/* Left Buttons */}
            <Col>
              <Button
                disabled={selectedRowKeys.length === 0}
                type="default"
                style={{
                  marginRight: "10px",
                  width: "159px",
                  height: "40px",
                  fontWeight: "700",
                  color: selectedRowKeys.length === 0 ? "#d2d2d2" : "#f53f35",
                  cursor:
                    selectedRowKeys.length === 0 ? "not-allowed" : "pointer",
                }}
              >
                Delete all selected
              </Button>
              {localStorage.getItem("userRole") == "Merchant" && (
                <Button
                  type="default"
                  style={{
                    color: "#2e56f2",
                    border: "1px solid #2e56f2",
                    padding: "9px 18px",
                    height: "40px",
                    width: "184px",
                    fontWeight: "700",
                  }}
                >
                  Duplicate & Distribute
                </Button>
              )}
            </Col>

            {/* Right Section */}
            {totalRecords > 0 && (
              <Col>
                <Row justify="end" align="middle" style={{ gap: "16px" }}>
                  {/* Total Items Count */}
                  <Col>
                    <span
                      style={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "rgba(0,0,0,.85)",
                      }}
                    >
                      Total {totalRecords} items
                    </span>
                  </Col>

                  {/* Pagination */}
                  <Col>
                    <Pagination
                      current={currentPage}
                      pageSize={pageSize}
                      total={totalRecords}
                      onChange={handlePageChange}
                      showSizeChanger={false}
                      itemRender={(page, type, originalElement) => {
                        if (type === "prev") {
                          const isDisabled = currentPage === 1;
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <LeftOutlined />
                              <span>Previous</span>
                            </a>
                          );
                        }
                        if (type === "next") {
                          const isDisabled =
                            currentPage === Math.ceil(totalRecords / pageSize);
                          return (
                            <a
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                fontSize: "12px",
                                fontWeight: "700",
                                color: isDisabled ? "#bbb" : "#2e56f2",
                                pointerEvents: isDisabled ? "none" : "auto",
                              }}
                            >
                              <span>Next</span>
                              <RightOutlined />
                            </a>
                          );
                        }
                        return originalElement;
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            )}
          </Row>

          <Mainmodel
            title="Categories of Service"
            addModalTitle="Add New Category"
            label="Category Name"
            visible={categoryVisible}
            onCancel={closeCategorymodal}
            columns={categoryColumns}
            dataSource={dataSourceCategory}
            onAdd={closeCategoryadd}
            type={type}
            createApi={handleAddCategory}
            handleEditApi={handleEditCategory} // Function to call when editing
            handleDeleteApi={handleDeleteCategory}
            fetchDetails={fetchCategoryDetails}
            updateSequence={updateCategoriesSequence}
            confirmDeleteMessage="Delete the Sevice"
          />
        </Content>
        <ConfirmDeleteModal
          visible={isDeleteModalVisible}
          onCancel={closeDeleteModal}
          // onConfirm={() => handleDeleteApi(editingRecord.key)}
          onConfirm={handleConfirm}
          message="Delete the Service?"
        />
      </Content>
    </>
  );
}
export default ServiceList;
