import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Button, Col, Divider, Modal, Row } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";

interface AttendanceDetailsModalProps {
  visible: boolean;
  onCancel: () => void;
  staffData: any;
}

const AttendanceDetailsModal: React.FC<AttendanceDetailsModalProps> = ({
  visible,
  onCancel,
  staffData,
}) => {
  const [period, setPeriod] = useState<"day" | "week" | "month">("day");
  const [dateRange, setDateRange] = useState<[dayjs.Dayjs, dayjs.Dayjs] | null>(
    null
  );
  const [expandedItem, setExpandedItem] = useState<string | null>(null); // Track which item is expanded

  useEffect(() => {
    if (period === "week") {
      setDateRange([dayjs().startOf("week"), dayjs().endOf("week")]);
    } else if (period === "month") {
      setDateRange([dayjs().startOf("month"), dayjs().endOf("month")]);
    } else {
      setDateRange([dayjs(), dayjs()]);
    }
  }, [period]);

  const handleDateNavigation = (direction: "prev" | "next") => {
    if (!dateRange) return;
    let newStart, newEnd;
    if (period === "day") {
      newStart =
        direction === "prev"
          ? dateRange[0].subtract(1, "day")
          : dateRange[0].add(1, "day");
      newEnd = newStart;
    } else if (period === "week") {
      newStart =
        direction === "prev"
          ? dateRange[0].subtract(1, "week")
          : dateRange[0].add(1, "week");
      newEnd =
        direction === "prev"
          ? dateRange[1].subtract(1, "week")
          : dateRange[1].add(1, "week");
    } else {
      newStart =
        direction === "prev"
          ? dateRange[0].subtract(1, "month")
          : dateRange[0].add(1, "month");
      newEnd =
        direction === "prev"
          ? dateRange[1].subtract(1, "month")
          : dateRange[1].add(1, "month");
    }
    setDateRange([newStart, newEnd]);
  };

  const getTitle = () => {
    if (!dateRange) return "";
    if (period === "day") {
      return dateRange[0].format("dddd DD MMM, YYYY");
    } else if (period === "week") {
      return `${dateRange[0].format("DD")} - ${dateRange[1].format(
        "DD MMM, YYYY"
      )}`;
    } else {
      return dateRange[0].format("MMM, YYYY");
    }
  };

  const getDaysList = () => {
    const start = dateRange?.[0]?.toDate();
    const end = dateRange?.[1]?.toDate();
    const days = [];
    if (start && end) {
      let current = dayjs(start);
      while (current.isBefore(end) || current.isSame(end, "day")) {
        days.push(current.format("dddd, MMM DD, YYYY"));
        current = current.add(1, "day");
      }
    }
    return days;
  };

  // Mock data functions for each summary item
  const getRestDaysDetails = () => {
    const days = getDaysList();
    return days.slice(0, 6).map((day) => `${day} - Rest`); // 6 rest days
  };

  const getLateArrivalDetails = () => {
    const days = getDaysList();
    return days.slice(0, 3).map((day) => `${day} - Late Arrival`); // 3 late arrivals
  };

  const getEarlyDepartureDetails = () => {
    const days = getDaysList();
    return days.slice(0, 4).map((day) => `${day} - Early Departure`); // 4 early departures
  };

  const getMissingRecordsDetails = () => {
    const days = getDaysList();
    return days.slice(0, 2).map((day) => `${day} - Missing Record`); // 2 missing records
  };

  const getAbsenteeismDetails = () => {
    const days = getDaysList();
    return days.slice(0, 1).map((day) => `${day} - Absent`); // 1 absenteeism
  };

  const renderDayView = () => (
    <div style={{ marginTop: 16, height: "71vh" }}>
      <div style={{ marginBottom: 16 }}>
        <div style={{ marginTop: 8 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 4,
              alignItems: "center",
            }}
          >
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Clock-in time:
            </h3>
            <span>-</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 4,
              alignItems: "center",
            }}
          >
            <h3 style={{ marginLeft: "10px" }}>Clock-in method:</h3>
            <span>-</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 4,
              alignItems: "center",
            }}
          >
            <h3 style={{ marginLeft: "10px" }}>Note:</h3>
            <span>-</span>
          </div>
        </div>
      </div>
      <div>
        <div style={{ marginTop: 8 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 4,
              alignItems: "center",
            }}
          >
            <h3 style={{ display: "flex", alignItems: "center" }}>
              <span className="vertical-line"></span>Clock-out time:
            </h3>
            <span>-</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 4,
              alignItems: "center",
            }}
          >
            <h3 style={{ marginLeft: "10px" }}>Clock-in method:</h3>
            <span>-</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 4,
              alignItems: "center",
            }}
          >
            <h3 style={{ marginLeft: "10px" }}>Note:</h3>
            <span>-</span>
          </div>
        </div>
      </div>
    </div>
  );

  const renderWeekOrMonthView = () => {
    const daysList = getDaysList();
    const summaryData = {
      attendanceDays: 0,
      restDays: period === "week" ? 6 : 14,
      lateArrival: 3,
      earlyDeparture: 4,
      missingRecords: 2,
      absenteeism: 1,
    };

    const toggleSummary = (item: string) => {
      setExpandedItem(expandedItem === item ? null : item);
    };

    const renderSummaryItem = (
      label: string,
      value: number,
      detailsFunc: () => string[]
    ) => (
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 4,
            cursor: detailsFunc().length > 0 ? "pointer" : "default",
          }}
          onClick={() => detailsFunc().length > 0 && toggleSummary(label)}
        >
          <span>{label}</span>
          <span>
            {value} &nbsp;
            {detailsFunc() &&
              (expandedItem === label ? <UpOutlined /> : <DownOutlined />)}
          </span>
        </div>
        {expandedItem === label && (
          <div
            style={{
              marginBottom: 16,
              gap: "20px",
            }}
          >
            <Divider style={{ marginBottom: "10px" }} />
            {detailsFunc().map((detail, index) => (
              <div key={index} style={{ marginBottom: 12 }}>
                {detail}
              </div>
            ))}
          </div>
        )}
        <Divider />
      </div>
    );

    return (
      <div
        style={{
          marginTop: 16,
          height: "71vh",
          overflowY: "auto",
        }}
      >
        {/* Summary Section */}
        <div style={{ marginBottom: 16, marginTop: 16 }}>
          {renderSummaryItem(
            "Attendance days",
            summaryData.attendanceDays,
            () => []
          )}
          {renderSummaryItem(
            "Rest days",
            summaryData.restDays,
            getRestDaysDetails
          )}
          {renderSummaryItem(
            "Late arrival",
            summaryData.lateArrival,
            getLateArrivalDetails
          )}
          {renderSummaryItem(
            "Early departure",
            summaryData.earlyDeparture,
            getEarlyDepartureDetails
          )}
          {renderSummaryItem(
            "Missing records",
            summaryData.missingRecords,
            getMissingRecordsDetails
          )}
          {renderSummaryItem(
            "Absenteeism",
            summaryData.absenteeism,
            getAbsenteeismDetails
          )}
        </div>
      </div>
    );
  };

  return (
    <Modal
      title={staffData?.name || "Staff"}
      open={visible}
      onCancel={onCancel}
      footer={null}
      width={600}
      style={{ top: 20 }}
    >
      <Row
        justify="space-between"
        gutter={[16, 16]}
        style={{ marginBottom: 16, marginTop: 16 }}
      >
        <Col>
          <div
            style={{
              display: "flex",
              border: "1px solid #c4d8d6",
              borderRadius: "8px",
              padding: "4px",
            }}
          >
            <div
              onClick={() => setPeriod("day")}
              style={{
                padding: "8px 16px",
                borderRadius: period === "day" ? "6px" : "6px 0px 0px 6px",
                backgroundColor: period === "day" ? "#1890ff" : "#fff",
                color: period === "day" ? "#fff" : "#000",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              Day
            </div>
            <div
              onClick={() => setPeriod("week")}
              style={{
                padding: "8px 16px",
                borderRadius: period === "week" ? "6px" : "6px 0px 0px 6px",
                backgroundColor: period === "week" ? "#1890ff" : "#fff",
                color: period === "week" ? "#fff" : "#000",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              Week
            </div>
            <div
              onClick={() => setPeriod("month")}
              style={{
                padding: "8px 16px",
                borderRadius: period === "month" ? "6px" : "0px 6px 6px 0px",
                backgroundColor: period === "month" ? "#1890ff" : "#fff",
                color: period === "month" ? "#fff" : "#000",
                cursor: "pointer",
                transition: "background-color 0.3s ease",
              }}
            >
              Month
            </div>
          </div>
        </Col>
        <Col>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #c4d8d6",
              borderRadius: "8px",
              padding: "4px",
              height: "38px",
              width: "18rem",
              justifyContent: "space-between",
            }}
          >
            <Button
              icon={<ArrowLeftOutlined />}
              style={{ border: "none", color: "blue" }}
              onClick={() => handleDateNavigation("prev")}
            />
            <span style={{ fontSize: "16px", padding: "0 8px" }}>
              {getTitle()}
            </span>
            <Button
              icon={<ArrowRightOutlined />}
              style={{ border: "none", color: "blue" }}
              onClick={() => handleDateNavigation("next")}
            />
          </div>
        </Col>
      </Row>
      {period === "day" ? renderDayView() : renderWeekOrMonthView()}
    </Modal>
  );
};

export default AttendanceDetailsModal;
