import { UndoOutlined } from "@ant-design/icons";
import { Button, Checkbox, Divider, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

// Define the drag item type
const ItemTypes = {
  COLUMN: "column",
};

// Define the props for the draggable column row
interface DraggableColumnRowProps {
  column: { key: string; title: string };
  index: number;
  moveColumn: (dragIndex: number, hoverIndex: number) => void;
  handleCheckboxChange: (key: string) => void;
  selectedColumns: string[];
  mandatoryColumns: string[];
}

const DraggableColumnRow: React.FC<DraggableColumnRowProps> = ({
  column,
  index,
  moveColumn,
  handleCheckboxChange,
  selectedColumns,
  mandatoryColumns,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.COLUMN,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ItemTypes.COLUMN,
    hover: (item: { index: number }) => {
      if (item.index !== index) {
        moveColumn(item.index, index);
        item.index = index; // Update the index for continuous dragging
      }
    },
  });

  return (
    <div
      ref={(node) => drag(drop(node))}
      style={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        justifyContent: "space-between", // Moves the menu button to the end
        marginBottom: "8px",
        backgroundColor: "#e6ebfe",
        padding: "10px",
        borderRadius: "8px",
        opacity: mandatoryColumns.includes(column.key)
          ? 0.6
          : isDragging
          ? 0.5
          : 1,
        cursor: "move",
        pointerEvents: mandatoryColumns.includes(column.key) ? "none" : "auto",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
        <Checkbox
          checked={selectedColumns.includes(column.key)}
          onChange={() => handleCheckboxChange(column.key)}
        >
          <span>{column.title}</span>
        </Checkbox>
      </div>

      {/* Show menu button only if the row is NOT disabled */}
      {!mandatoryColumns.includes(column.key) && (
        <span style={{ cursor: "move" }}>☰</span>
      )}
    </div>
  );
};

interface ColumnsReportsModalProps {
  visible: boolean;
  onCancel: () => void;
  onSave: (
    selectedColumns: string[],
    reorderedColumns: { key: string; title: string }[]
  ) => void;
  availableColumns: { key: string; title: string }[];
  initialSelectedColumns: string[];
  lastSelectedColumns: string[];
  mandatoryColumns: string[];
}

const ColumnsReportsModal: React.FC<ColumnsReportsModalProps> = ({
  visible,
  onCancel,
  onSave,
  availableColumns,
  initialSelectedColumns,
  lastSelectedColumns,
  mandatoryColumns,
}) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>(
    initialSelectedColumns
  );
  const [columnsOrder, setColumnsOrder] = useState(availableColumns);
  const [originalColumnsOrder, setOriginalColumnsOrder] =
    useState(availableColumns); // Store the original column order

  // Store the original column order when the component mounts
  useEffect(() => {
    setOriginalColumnsOrder(availableColumns);
  }, []); // Empty dependency array ensures this runs only once on mount

  // Sync selectedColumns and columnsOrder with initial props when the modal opens
  useEffect(() => {
    if (visible) {
      setSelectedColumns(initialSelectedColumns);
      setColumnsOrder(availableColumns);
    }
  }, [visible, initialSelectedColumns, availableColumns]);

  // Function to handle checkbox toggle
  const handleCheckboxChange = (key: string) => {
    if (mandatoryColumns.includes(key)) {
      return;
    }
    setSelectedColumns((prev) =>
      prev.includes(key) ? prev.filter((col) => col !== key) : [...prev, key]
    );
  };

  // Function to toggle "All" checkbox
  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      setSelectedColumns(availableColumns.map((col) => col.key));
    } else {
      setSelectedColumns([...mandatoryColumns]);
    }
  };

  // Check if all checkboxes are selected
  const isAllSelected =
    selectedColumns.length === availableColumns.length &&
    availableColumns.length > 0;

  const moveColumn = (dragIndex: number, hoverIndex: number) => {
    const updatedColumns = [...columnsOrder];
    const dragItem = updatedColumns[dragIndex];

    // Prevent dragging a mandatory column
    if (mandatoryColumns.includes(dragItem.key)) return;

    // Find the fixed indexes of mandatory columns
    const mandatoryIndexes = updatedColumns
      .map((col, i) => (mandatoryColumns.includes(col.key) ? i : -1))
      .filter((i) => i !== -1);

    // Determine valid drop position (skip mandatory columns)
    let targetIndex = hoverIndex;
    while (
      mandatoryIndexes.includes(targetIndex) &&
      targetIndex >= 0 &&
      targetIndex < updatedColumns.length
    ) {
      targetIndex = dragIndex > hoverIndex ? targetIndex - 1 : targetIndex + 1;
    }

    // Prevent invalid moves
    if (
      targetIndex < 0 ||
      targetIndex >= updatedColumns.length ||
      mandatoryIndexes.includes(targetIndex)
    ) {
      return;
    }

    // Swap positions of non-mandatory columns only
    const newColumns = updatedColumns.filter(
      (col) => !mandatoryColumns.includes(col.key)
    );

    const draggedItem = newColumns.splice(
      newColumns.findIndex((col) => col.key === dragItem.key),
      1
    )[0];

    newColumns.splice(targetIndex, 0, draggedItem);

    // Reconstruct the final order with mandatory columns in place
    let finalColumns = [...updatedColumns];
    let nonMandatoryIndex = 0;

    for (let i = 0; i < updatedColumns.length; i++) {
      if (!mandatoryColumns.includes(updatedColumns[i].key)) {
        finalColumns[i] = newColumns[nonMandatoryIndex++];
      }
    }

    setColumnsOrder(finalColumns);
  };

  // Function to save selected columns and the new order
  const handleSave = () => {
    onSave(selectedColumns, columnsOrder);
    onCancel();
  };

  // Function to reset column selection and order to the original columns
  const handleReset = () => {
    setSelectedColumns(originalColumnsOrder.map((col) => col.key)); // Select all columns
    setColumnsOrder([...originalColumnsOrder]); // Reset to original order
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <Modal
        title="Columns"
        visible={visible}
        onCancel={onCancel}
        bodyStyle={{ height: "450px", overflowY: "auto" }}
        width={500}
        centered
        footer={[
          <>
            <Divider />
            <div style={{ display: "flex", width: "100%" }} key="footer">
              <Checkbox
                checked={isAllSelected}
                style={{ alignItems: "center" }}
                onChange={(e) => handleSelectAll(e.target.checked)}
              >
                All
              </Checkbox>
              <div style={{ marginLeft: "auto" }}>
                <Button
                  style={{
                    marginRight: "10px",
                    height: 40,
                    width: 70,
                    border: "0px",
                  }}
                  icon={<UndoOutlined />}
                  key="reset"
                  onClick={handleReset}
                >
                  Reset
                </Button>
                <Button
                  style={{ height: 40, width: 70 }}
                  key="save"
                  type="primary"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            </div>
          </>,
        ]}
      >
        <div style={{ overflowY: "auto", marginTop: "10px" }}>
          {columnsOrder.map((column, index) => (
            <DraggableColumnRow
              key={column.key}
              column={column}
              index={index}
              moveColumn={moveColumn}
              handleCheckboxChange={handleCheckboxChange}
              selectedColumns={selectedColumns}
              mandatoryColumns={mandatoryColumns}
            />
          ))}
        </div>
      </Modal>
    </DndProvider>
  );
};

export default ColumnsReportsModal;
