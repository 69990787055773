import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { get, post } from "../../../../services/Apiclient";

interface AddOvertimeModalProps {
  visible: boolean;
  onClose: () => void;
  onAdd: (newRecord: any) => void; // Callback to pass the new record to the parent
  serviceId: string | null;
  departmentId: string | null;
}

const AddOvertimeModal: React.FC<AddOvertimeModalProps> = ({
  visible,
  onClose,
  onAdd,
  serviceId,
  departmentId,
}) => {
  const [form] = Form.useForm();
  const [startTime, setStartTime] = useState<dayjs.Dayjs | null>(null);
  const [endTime, setEndTime] = useState<dayjs.Dayjs | null>(null);
  const [duration, setDuration] = useState<string>("");
  const [staffData, setStaffData] = useState<any[]>([]);

  const outletId = localStorage.getItem("outlet_id");

  // Fetch staff data when the modal is opened
  const getStaffData = async () => {
    if (!outletId) return;
    try {
      const response = await get(`/staff/dropdown/${outletId}`);
      if (response?.data?.success && response.data.data) {
        setStaffData(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching staff:", error);
      message.error("Failed to fetch staff data.");
    }
  };

  useEffect(() => {
    if (visible) {
      getStaffData(); // Fetch staff data when the modal opens
    }
  }, [visible]);

  // Calculate duration whenever startTime or endTime changes
  useEffect(() => {
    if (startTime && endTime) {
      const diffInMinutes = endTime.diff(startTime, "minute");
      if (diffInMinutes <= 0) {
        setDuration("");
        form.setFieldsValue({ duration: "" });
      } else {
        const hours = Math.floor(diffInMinutes / 60);
        const minutes = diffInMinutes % 60;
        const durationStr = `${hours}.${Math.round((minutes / 60) * 10)}`;
        setDuration(durationStr);
        form.setFieldsValue({ duration: durationStr });
      }
    } else {
      setDuration("");
      form.setFieldsValue({ duration: "" });
    }
  }, [startTime, endTime, form]);

  // Disable past dates
  const disabledDate = (current: dayjs.Dayjs) => {
    return current && current < dayjs().startOf("day");
  };

  const disabledEndDate = (current: dayjs.Dayjs) => {
    if (!startTime) return false;
    return current && current < startTime.startOf("day");
  };

  // Disable past times for startTime and endTime
  const disabledStartTime = (selectedDate: dayjs.Dayjs | null) => {
    const now = dayjs();
    // If no date is selected or the date is in the future, no time restrictions
    if (!selectedDate || selectedDate.isAfter(now, "day")) {
      return {};
    }

    // If the selected date is today, disable past hours and minutes
    if (selectedDate.isSame(now, "day")) {
      const currentHour = now.hour();
      const currentMinute = now.minute();

      return {
        disabledHours: () => Array.from({ length: currentHour }, (_, i) => i), // Disable hours before current hour
        disabledMinutes: (hour: number) =>
          hour === currentHour
            ? Array.from({ length: currentMinute }, (_, i) => i) // Disable minutes before current minute in current hour
            : [], // No minute restrictions for future hours
      };
    }

    return {};
  };

  // Disable times for endTime: cannot be before startTime
  const disabledEndTime = (selectedDate: dayjs.Dayjs | null) => {
    const now = dayjs();

    if (!startTime) {
      if (!selectedDate || selectedDate.isAfter(now, "day")) {
        return {};
      }

      if (selectedDate.isSame(now, "day")) {
        const currentHour = now.hour();
        const currentMinute = now.minute();

        return {
          disabledHours: () => Array.from({ length: currentHour }, (_, i) => i),
          disabledMinutes: (hour: number) =>
            hour === currentHour
              ? Array.from({ length: currentMinute }, (_, i) => i)
              : [],
        };
      }

      return {};
    }

    if (selectedDate && startTime && selectedDate.isSame(startTime, "day")) {
      const startHour = startTime.hour();
      const startMinute = startTime.minute();

      return {
        disabledHours: () => Array.from({ length: startHour + 1 }, (_, i) => i),
        disabledMinutes: (hour: number) =>
          hour === startHour
            ? Array.from({ length: startMinute + 1 }, (_, i) => i)
            : [],
      };
    }

    if (selectedDate && selectedDate.isAfter(startTime, "day")) {
      return {};
    }

    if (selectedDate && selectedDate.isSame(now, "day")) {
      const currentHour = now.hour();
      const currentMinute = now.minute();

      return {
        disabledHours: () => Array.from({ length: currentHour }, (_, i) => i),
        disabledMinutes: (hour: number) =>
          hour === currentHour
            ? Array.from({ length: currentMinute }, (_, i) => i)
            : [],
      };
    }

    return {};
  };

  const handleSubmit = async (values: any) => {
    if (!outletId || !serviceId || !departmentId) {
      message.error("Missing outlet, service, or department information.");
      return;
    }

    // Format the starttime and endtime as required by the API (DD/MM/YYYY HH:mm)
    const formattedStartTime = startTime
      ? startTime.format("DD/MM/YYYY HH:mm")
      : "";
    const formattedEndTime = endTime ? endTime.format("DD/MM/YYYY HH:mm") : "";

    const payload = {
      staffid: values.staff,
      starttime: formattedStartTime,
      endtime: formattedEndTime,
      reason: values.reason,
      outlet_id: outletId,
      serviceid: serviceId,
      departmentid: departmentId,
    };

    try {
      const response = await post("/overtime/addovertime", payload);
      if (response?.data?.success) {
        message.success("Overtime record added successfully!");
        // Create the new record to pass to the parent
        const newRecord = {
          id: Date.now().toString(), // Temporary ID; replace with actual ID from API response if available
          staffid: values.staff,
          staffName:
            staffData.find((staff) => staff.id === values.staff)?.name || "",
          starttime: formattedStartTime,
          endtime: formattedEndTime,
          duration: duration,
          reason: values.reason,
          created_at: new Date().toISOString(),
          status: "pending",
        };
        onAdd(newRecord); // Pass the new record to the parent
        onClose();
        form.resetFields();
        setStartTime(null);
        setEndTime(null);
        setDuration("");
      } else {
        message.error("Failed to add overtime record.");
      }
    } catch (error) {
      console.error("Error adding overtime:", error);
      message.error("Error adding overtime. Please try again.");
    }
  };

  return (
    <Modal
      style={{ top: "20px" }}
      title="Add Overtime"
      visible={visible}
      onCancel={() => {
        onClose();
        form.resetFields();
        setStartTime(null);
        setEndTime(null);
        setDuration("");
      }}
      footer={
        <>
          <Divider />
          <Row justify="end" gutter={16}>
            <Col>
              <Button
                style={{
                  height: "40px",
                  borderRadius: "4px",
                  border: "1px solid #d9d9d9",
                  fontWeight: "500",
                }}
                onClick={() => {
                  onClose();
                  form.resetFields();
                  setStartTime(null);
                  setEndTime(null);
                  setDuration("");
                }}
              >
                Cancel
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                style={{
                  height: "40px",
                  borderRadius: "4px",
                  background: "#2e56f2",
                  border: "none",
                  fontWeight: "500",
                }}
                onClick={() => form.submit()}
              >
                Save
              </Button>
            </Col>
          </Row>
        </>
      }
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        style={{ marginBottom: "40px" }}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="staff"
              label="Staff"
              rules={[
                { required: true, message: "Please select a staff member" },
              ]}
            >
              <Select
                style={{ width: "100%", height: "45px", borderRadius: "4px" }}
                placeholder="Please select"
              >
                {staffData.map((staff) => (
                  <Select.Option key={staff.id} value={staff.id}>
                    {staff.name} ({staff.staffcode})
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="startTime"
              label="Start time"
              rules={[
                { required: true, message: "Please select a start time" },
              ]}
            >
              <DatePicker
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                placeholder="Select date & time"
                style={{ width: "100%", height: "45px", borderRadius: "4px" }}
                onChange={(value) => setStartTime(value)}
                disabledDate={disabledDate}
                disabledTime={disabledStartTime}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="endTime"
              label="End time"
              rules={[{ required: true, message: "Please select an end time" }]}
            >
              <DatePicker
                showTime={{ format: "HH:mm" }}
                format="YYYY-MM-DD HH:mm"
                placeholder="Select date & time"
                style={{ width: "100%", height: "45px", borderRadius: "4px" }}
                onChange={(value) => setEndTime(value)}
                disabledDate={(current) =>
                  disabledDate(current) || disabledEndDate(current)
                }
                disabledTime={disabledEndTime}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="duration" label="Duration">
              <Input
                style={{ height: "45px", borderRadius: "4px" }}
                placeholder="0"
                value={duration}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="reason"
              label="Reason"
              rules={[{ required: true, message: "Please enter the reason" }]}
            >
              <Input.TextArea
                rows={4}
                style={{ borderRadius: "4px" }}
                placeholder="Please enter"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddOvertimeModal;
