import {
  PlusOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import { Button, Select, Row, Col, Badge, Spin } from "antd";
import { useRef, useState, useEffect } from "react";
import dayjs from "dayjs";
import AddAppointment from "../../components/model/booking/AddAppointment";
import { get } from "../../services/Apiclient";
import AppointmentDetailsModal from "../../components/model/booking/AppointmentDetailsModal";
import AppointmentListModal from "../../components/model/booking/AppointmentListModal";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
const StaffIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Empty_Avatar_EmpM.png`;
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const faviconIcon = `${process.env.PUBLIC_URL}/images/logo/Favicon.png`;

const { Option } = Select;

const Booking = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const addbooking = queryParams.get("add");
  const calendarRef = useRef<FullCalendar>(null);
  const [calendarView, setCalendarView] = useState("resourceTimeGridDay"); // Default view: Week
  const [selectedFilter, setSelectedFilter] = useState("null"); // Filter selection
  const [title, setTitle] = useState(""); // Holds formatted title
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dataAdded, setDataAdded] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [filteredEvents, setFilteredEvents] = useState<any[]>([]);

  const [allEvents, setAllEvents] = useState([]);

  const [selectedappointmentInfo, setSelectedappointmentInfo] =
    useState<any>(null);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [staffData, setStaffData] = useState<any[]>([]);

  const [isListModalVisible, setIsListModalVisible] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [status, setStatus] = useState("upcoming");

  const [calMinTime, setcalMinTime] = useState("08:00:00");
  const [calMaxTime, setcalMaxTime] = useState("20:00:00");

  const [hoveredEvent, setHoveredEvent] = useState<any>(null);
  const [hoverPosition, setHoverPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const [loading, setLoading] = useState(false);

  const handleViewAppointment = (appointment: any) => {
    setSelectedAppointment(appointment);
  };

  const [statusCounts, setStatusCounts] = useState({
    upcoming: 0,
    arrived: 0,
    paid: 0,
    noshow: 0,
    cancel: 0,
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = async () => {
    setIsModalVisible(false);
    await getCalendarData();
    setNowIndicator();
  };

  const handleDetailsCancel = async () => {
    setIsModalOpen(false);
    await getCalendarData();
    setNowIndicator();
  };

  const handleAppointmentAdd = () => {
    setDataAdded(!dataAdded);
  };

  // Filter resources based on selected filter
  const visibleResources = staffData
    .filter((staff) => staff.id !== "null") // Remove "All"
    .filter((staff) => selectedFilter === "null" || staff.id === selectedFilter)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((staff) => ({
      id: staff.id,
      title: staff.name,
      extendedProps: {
        profile_pic: staff.profile_pic || StaffIcon,
      },
    }));

  const getAggregatedEvents = (appointments: any[]) => {
    // Define the structure of groupedEvents
    const groupedEvents: Record<
      string,
      {
        upcoming: number;
        arrived: number;
        paid: number;
        noshow: number;
        cancel: number;
      }
    > = {};

    appointments.forEach((appointment) => {
      // Extract the date from the start time
      const date = dayjs(appointment.start).format("YYYY-MM-DD");
      const status = appointment.status || "unknown"; // Handle missing status

      // Ensure the date exists in groupedEvents with default values
      if (!groupedEvents[date]) {
        groupedEvents[date] = {
          upcoming: 0,
          arrived: 0,
          paid: 0,
          noshow: 0,
          cancel: 0,
        };
      }

      // Increment count only if the status exists in the predefined structure
      if (status in groupedEvents[date]) {
        groupedEvents[date][
          status as keyof (typeof groupedEvents)[string]
        ] += 1;
      }
    });

    // Convert grouped data into FullCalendar event objects
    return Object.keys(groupedEvents).map((date) => ({
      id: `summary-${date}`,
      title: "", // No title, since we only display status counts
      start: date,
      extendedProps: groupedEvents[date], // Attach aggregated status counts
    }));
  };

  // Filter events based on selection
  // const visibleEvents =
  //   calendarView === "dayGridMonth"
  //     ? getAggregatedEvents(
  //         selectedFilter === "null"
  //           ? allEvents
  //           : allEvents.filter(
  //               (event: any) => event.resourceId === selectedFilter
  //             )
  //       )
  //     : selectedFilter === "null"
  //     ? allEvents
  //     : allEvents.filter((event: any) => event.resourceId === selectedFilter);
  // const visibleEvents = useMemo(() => {
  //   if (calendarView === "dayGridMonth") {
  //     return getAggregatedEvents(
  //       selectedFilter === "null"
  //         ? allEvents
  //         : allEvents.filter(
  //             (event: any) => event.resourceId === selectedFilter
  //           )
  //     );
  //   }
  //   return selectedFilter === "null"
  //     ? allEvents
  //     : allEvents.filter((event: any) => event.resourceId === selectedFilter);
  // }, [calendarView, selectedFilter, allEvents]);

  useEffect(() => {
    const filterEvents = async () => {
      let filtered = [];

      if (calendarView == "dayGridMonth") {
        filtered = getAggregatedEvents(
          selectedFilter == "null"
            ? allEvents
            : allEvents.filter(
                (event: any) => event.resourceId == selectedFilter
              )
        );
      } else {
        filtered =
          selectedFilter == "null"
            ? allEvents
            : allEvents.filter(
                (event: any) => event.resourceId == selectedFilter
              );
      }

      // ✅ Set it to state
      setFilteredEvents(filtered);

      if (calendarRef.current) {
        const calendarApi = calendarRef.current.getApi();
        calendarApi.removeAllEvents();
        calendarApi.addEventSource(filteredEvents); // Optional, if using event sources
      }
    };

    filterEvents();
  }, [calendarView, selectedFilter, allEvents]);

  const handleViewChange = (value: string) => {
    const newView = value === "week" ? "timeGridWeek" : value; // Ensure week uses `timeGridWeek`
    setCalendarView(newView);
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      calendarApi.changeView(newView);
      updateTitle();
    }
    setNowIndicator();
  };

  // Function to update the title dynamically
  const updateTitle = () => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const view = calendarApi.view;

      const start = dayjs(view.currentStart).format("YYYY-MM-DD");
      const end = dayjs(view.currentEnd)
        .subtract(1, "day")
        .format("YYYY-MM-DD");

      setStartDate(start);
      setEndDate(end);

      if (view.type === "resourceTimeGridDay") {
        setTitle(dayjs(start).format("dddd D MMM YYYY"));
        let workinghours: any = localStorage.getItem("workinghours");
        let weekTimeData = JSON.parse(workinghours);
        const currentDayOfWeek = dayjs(start).day(); // 0 = Sunday, 1 = Monday...

        const todaySchedule: any = weekTimeData.find(
          (item: any) => item.dayofweek === currentDayOfWeek
        );

        const minTime =
          todaySchedule?.starttime === "closed" ||
          todaySchedule?.starttime === "null" ||
          !todaySchedule?.starttime
            ? "08:00:00"
            : todaySchedule.starttime;

        const maxTime =
          todaySchedule?.endtime === "closed" ||
          todaySchedule?.endtime === "null" ||
          !todaySchedule?.endtime
            ? "19:00:00"
            : todaySchedule.endtime;

        setcalMinTime(minTime);
        setcalMaxTime(maxTime);

        // setcalMinTime(todayStartTime);
        // setcalMaxTime(todayEndTime);
      } else if (view.type === "timeGridWeek") {
        setTitle(
          `${dayjs(start).format("D ")} - ${dayjs(end).format("D MMM YYYY")}`
        );
        let workinghours: any = localStorage.getItem("workinghours");
        let weekTimeData = JSON.parse(workinghours);

        const times = weekTimeData.filter(
          (day: any) => day.starttime !== "closed" && day.starttime !== "null"
        );

        // Extract starttimes and endtimes
        const startTimes = times.map((day: any) => day.starttime);
        const endTimes = times.map((day: any) => day.endtime);

        // Get min starttime (earliest)
        const earliestStartTime = startTimes.reduce(
          (min: any, time: any) => (time < min ? time : min),
          startTimes[0]
        );

        // Get max endtime (latest)
        const latestEndTime = endTimes.reduce(
          (max: any, time: any) => (time > max ? time : max),
          endTimes[0]
        );

        setcalMinTime(earliestStartTime);
        setcalMaxTime(latestEndTime);
      } else if (view.type === "dayGridMonth") {
        setTitle(dayjs(start).format("MMM YYYY"));
      }
    }
  };

  useEffect(() => {
    getStaffDropdown();
    // getCalendarData();
    updateTitle();
  }, [calendarView]);

  useEffect(() => {
    if (startDate && endDate) {
      getCalendarData();
    }
  }, [startDate, endDate, selectedFilter]);

  const getCalendarData = async () => {
    try {
      setLoading(true);

      let startdate: any = startDate;
      let enddate: any = endDate;
      let url = `/appointment/list/${localStorage.getItem(
        "outlet_id"
      )}/${selectedFilter}/${startdate}/${enddate}`;

      let response = await get(url);
      if (response?.data?.success) {
        let colorData: any = localStorage.getItem("colordata");
        let bookingStatusColorData = JSON.parse(colorData);
        const statusColorMap = bookingStatusColorData.reduce(
          (acc: any, statusObj: any) => {
            // Normalize: remove whitespace and lowercase
            const normalizedStatusName = statusObj.name
              .replace(/\s+/g, "")
              .toLowerCase();
            acc[normalizedStatusName] = statusObj.color;
            return acc;
          },
          {}
        );

        const formattedEvents = await response.data.data.appointmentDetails
          .filter((appointment: any) => appointment.staffid) // Ignore if no staff assigned
          .map((appointment: any) => {
            const [startTime, endTime] = appointment.time.split(" - "); // Extract start & end times
            const appointmentDate = dayjs(
              appointment.appointment_time,
              "DD/MM/YYYY hh:mma"
            ).format("YYYY-MM-DD"); // Extract and format date
            //show details in calendar
            const eventStatus = appointment.status
              ?.replace(/\s+/g, "")
              .toLowerCase();

            const eventColor = statusColorMap[eventStatus] || "#ccc";

            return {
              id: appointment.appointmentid,
              title: `${appointment.customername}`,
              servicename: appointment.servicename,
              start: dayjs(`${appointmentDate} ${startTime}`).toISOString(),
              end: dayjs(`${appointmentDate} ${endTime}`).toISOString(),
              resourceId: appointment.staffid,
              backgroundColor: eventColor,
              borderColor: eventColor,
              textColor: "#000",
              staffname: appointment.staffname,
              status: appointment.status,
              note: appointment.note !== null ? appointment.note : "",
            };
          });
        const counts = response.data.data.statusCounts;
        await setStatusCounts(counts);
        await setAllEvents(formattedEvents);
      } else {
        console.log("Error fetching appointments:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching calendar data:", error);
    } finally {
      setLoading(false); // ✅ Stop loader
    }
  };

  const getStaffDropdown = async () => {
    try {
      let url = `/staff/dropdown/${localStorage.getItem("outlet_id")}`;
      let response = await get(url);

      if (response?.data?.success) {
        let staffList = response.data.data.map((staff: any) => ({
          id: staff.id,
          name: staff.name,
          profile_pic: staff.profile_pic || StaffIcon,
        }));

        staffList.sort((a: any, b: any) => {
          return a.name.localeCompare(b.name);
        });

        setStaffData([
          { id: "null", name: "All", profile_pic: StaffIcon }, // "All" option
          ...staffList,
        ]);

        setSelectedFilter("null");
      } else {
        console.error("Error fetching staff:", response.data.message);
      }
    } catch (error) {
      console.error("Error in staff API call:", error);
    }
  };

  const handleEventClick = (info: any) => {
    let infodate = dayjs(info.event.startStr).format("DD-MM-YYYY");
    let eventdate = dayjs(info.event.startStr).format("YYYY-MM-DD");
    let infotime = dayjs(info.event.startStr).format("h:mma");
    let eventInfo = {
      id: info.event.id,
      date: infodate,
      eventinfodate: eventdate,
      time: infotime,
      staffid: info.event._def.resourceIds[0],
    };
    setSelectedappointmentInfo(eventInfo);
    if (calendarView === "dayGridMonth") {
      setIsListModalVisible(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const getStatusColor = (statusName: string) => {
    let bookingStatusColorData: any = localStorage.getItem("colordata");
    const colorData: any = JSON.parse(bookingStatusColorData) || [];
    const normalizedStatusName = statusName.replace(/\s+/g, "").toLowerCase();

    const status = colorData.find((item: any) => {
      const normalizedItemName = item.name.replace(/\s+/g, "").toLowerCase();
      return normalizedItemName === normalizedStatusName;
    });

    return status ? status.color : "#ccc";
  };

  const handleTodayClick = () => {
    if (calendarRef.current) {
      setNowIndicator();
      const calendarApi = calendarRef.current.getApi();
      calendarApi.today(); // 🔹 Move calendar to today's date
      updateTitle(); // 🔹 Update header title dynamically
    }
  };

  const renderWeekDayViewEvent = (eventInfo: any) => {
    const { title, start, end, staffname, backgroundColor, extendedProps } =
      eventInfo.event;
    const startTime = dayjs(start).format("hh:mm A");
    const endTime = dayjs(end).format("hh:mm A");

    const durationInMinutes = dayjs(end).diff(dayjs(start), "minute");
    const baseHeight = 49; // Base height for 30-minute events
    const heightPerMinute = baseHeight / 30; // Height per minute unit
    const eventHeight = durationInMinutes * heightPerMinute; // Final event height

    const eventColor = backgroundColor || "#fde68a";

    return (
      <>
        <Helmet>
          <title>Daco</title>
          <meta name="description" content={"Daco salon"} />
          <meta property="og:title" content={"Daco"} />
          <meta property="og:description" content={"Daco"} />
          <meta property="og:image" content={logoIcon} />
          <meta property="og:url" content={`https://new.suibox.my`} />
          <meta name="twitter:card" content="summary_large_image" />
          {/* Add more tags as needed */}
        </Helmet>
        <div
          style={{
            height: `${eventHeight}px`,
            overflow: "hidden",
            padding: "6px",
            backgroundColor: eventColor,
          }}
        >
          <span
            style={{
              display: "block",
              height: "6px",
              width: "100%",
              background: "#f59e0b",
              borderRadius: "6px",
              marginBottom: "6px",
            }}
          ></span>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            <b>{`${startTime} - ${endTime}`}</b>
          </div>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            {title}
          </div>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            {extendedProps.servicename}
          </div>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
          >
            staff:{extendedProps.staffname}
          </div>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              wordBreak: "break-word",
            }}
            className="two-line-text"
          >
            Note: {extendedProps.note || "-"}
          </div>
        </div>
      </>
    );
  };

  const renderMonthViewEvent = (eventInfo: any) => {
    const { extendedProps } = eventInfo.event;

    return (
      <div
        style={{
          textAlign: "left",
          padding: "4px",
          background: "#fff",
          gap: "5px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {extendedProps.upcoming > 0 && (
          <div
            style={{
              color: "#000",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              setStatus("upcoming");
            }}
          >
            <span>
              <span
                style={{
                  borderLeft: `4px solid ${getStatusColor("upcoming")}`,
                }}
              ></span>{" "}
              <span style={{ marginLeft: "5px" }}>Upcoming</span>
            </span>
            <span style={{ color: "blue" }}>{extendedProps.upcoming}</span>
          </div>
        )}
        {extendedProps.arrived > 0 && (
          <div
            style={{
              color: "#000",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              setStatus("arrived");
            }}
          >
            <span>
              <span
                style={{ borderLeft: `4px solid ${getStatusColor("arrived")}` }}
              ></span>
              <span style={{ marginLeft: "5px" }}>Arrived</span>
            </span>
            <span style={{ color: "blue" }}>{extendedProps.arrived}</span>
          </div>
        )}
        {extendedProps.paid > 0 && (
          <div
            style={{
              color: "#000",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              setStatus("paid");
            }}
          >
            <span>
              <span
                style={{ borderLeft: `4px solid ${getStatusColor("paid")}` }}
              ></span>
              <span style={{ marginLeft: "5px" }}>Paid</span>
            </span>
            <span style={{ color: "blue" }}>{extendedProps.paid}</span>
          </div>
        )}
        {extendedProps.noshow > 0 && (
          <div
            style={{
              color: "#000",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              setStatus("noshow");
            }}
          >
            <span>
              <span
                style={{ borderLeft: `4px solid ${getStatusColor("noshow")}` }}
              ></span>
              <span style={{ marginLeft: "5px" }}>No show</span>
            </span>
            <span style={{ color: "blue" }}>{extendedProps.noshow}</span>
          </div>
        )}
        {extendedProps.cancel > 0 && (
          <div
            style={{
              color: "#000",
              display: "flex",
              justifyContent: "space-between",
            }}
            onClick={() => {
              setStatus("cancel");
            }}
          >
            <span>
              <span
                style={{ borderLeft: `4px solid ${getStatusColor("cancel")}` }}
              ></span>
              <span style={{ marginLeft: "5px" }}>Cancel</span>
            </span>
            <span style={{ color: "blue" }}>{extendedProps.cancel}</span>
          </div>
        )}
      </div>
    );
  };

  const getCurrentTimeForScroll = () => {
    return dayjs().format("HH:mm:ss");
  };

  const setNowIndicator = async () => {
    document
      .querySelector(".fc-now-indicator")
      ?.scrollIntoView({ block: "center" });
    await window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    setNowIndicator();
    if (addbooking) {
      setIsModalVisible(true);
    }
  }, []);

  return (
    <>
      <Col
        style={{ display: "flex", alignItems: "center", background: "#f5f5f5" }}
      >
        <h2 style={{ lineHeight: "22px", fontSize: "18px" }}>
          <span className="vertical-line"></span>Booking
        </h2>
      </Col>
      <Spin spinning={loading} size="large">
        <div
          style={{
            padding: "8px 8px 0px 8px",
            background: "#f6f7f9",
            borderRadius: "8px",
            border: "2px solid #e5e7fd",
          }}
        >
          {/* Header Section */}
          <Row
            justify="space-between"
            align="middle"
            style={{ marginBottom: "3%" }}
          >
            {/* Left Dropdown - Select View Type */}
            <Col>
              <Select
                value={selectedFilter}
                style={{ width: 150 }}
                onChange={(value) => {
                  setSelectedFilter(value);
                  setNowIndicator();
                }}
              >
                {staffData.length > 0 &&
                  staffData.map((outdata: any) => (
                    <Option value={outdata.id} key={outdata.id}>
                      {outdata.name}
                    </Option>
                  ))}
              </Select>
            </Col>

            {/* Center Date Navigation */}
            <Col>
              <Row style={{ height: "200%" }} gutter={16} align="middle">
                <Col>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                      padding: "0px 10px",
                      background: "#fff",
                      border: "1px solid #e3e7fd",
                    }}
                  >
                    <Button
                      icon={
                        <ArrowLeftOutlined
                          style={{ color: "#2f57eb", fontWeight: "bold" }}
                        />
                      }
                      style={{ width: "20px", height: "45px", border: "none" }}
                      onClick={() => {
                        calendarRef.current?.getApi().prev();
                        updateTitle();
                        setNowIndicator();
                      }}
                    ></Button>
                    <span
                      style={{
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      {title}
                    </span>
                    <Button
                      icon={
                        <ArrowRightOutlined
                          style={{ color: "#2f57eb", fontWeight: "bold" }}
                        />
                      }
                      style={{ width: "20px", height: "45px", border: "none" }}
                      onClick={() => {
                        calendarRef.current?.getApi().next();
                        updateTitle();
                        setNowIndicator();
                      }}
                    ></Button>
                  </div>
                </Col>
                <Col>
                  <Button
                    style={{
                      width: "81px",
                      height: "45px",
                      color: "#2d56f6",
                      border: "none",
                      backgroundColor: "#e2e7fa",
                    }}
                    onClick={handleTodayClick}
                  >
                    Today
                  </Button>
                </Col>
              </Row>
            </Col>

            {/* Right View Switch & Add Button */}
            <Col>
              <Row gutter={16}>
                <Col>
                  <Select
                    defaultValue="resourceTimeGridDay"
                    style={{ width: 120 }}
                    onChange={handleViewChange}
                  >
                    <Select.Option value="resourceTimeGridDay">
                      Day
                    </Select.Option>
                    <Select.Option value="week">Week</Select.Option>
                    <Select.Option value="dayGridMonth">Month</Select.Option>
                  </Select>
                </Col>
                <Col>
                  <Button
                    type="primary"
                    style={{
                      background: "#2e56f2",
                      color: "#fff",
                      borderRadius: "6px",
                      width: "81px",
                      height: "45px",
                      fontWeight: 700,
                      fontSize: "15px",
                    }}
                    icon={<PlusOutlined />}
                    onClick={showModal}
                  >
                    Add
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {/* FullCalendar */}
          <div style={{ width: "100%", overflowX: "auto" }}>
            <FullCalendar
              ref={calendarRef}
              allDaySlot={false}
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                resourceTimeGridPlugin,
              ]}
              initialView={calendarView}
              headerToolbar={false} // Custom toolbar (not default)
              firstDay={1}
              // slotMinTime="07:00:00"
              // slotMaxTime="23:00:00"
              slotMinTime={calMinTime}
              slotMaxTime={calMaxTime}
              slotDuration="00:30:00" // ⏳ Sets time slots to 30 min
              slotLabelInterval="00:30:00"
              slotLabelFormat={{
                hour: "numeric",
                minute: "2-digit", // Ensures "00" in minutes
                meridiem: "short", // Shows AM/PM
              }}
              dayHeaderContent={(arg: any) => {
                const calendarApi = calendarRef.current?.getApi();
                const currentView = calendarApi?.view.type;

                return (
                  <div style={{ textAlign: "center" }}>
                    {currentView === "dayGridMonth"
                      ? dayjs(arg.date).format("dddd")
                      : dayjs(arg.date).format("D dddd")}
                  </div>
                );
              }}
              selectMirror
              dayMaxEvents
              resources={visibleResources}
              resourceAreaWidth="200px"
              resourceOrder="title"
              resourceLabelContent={(arg: any) => (
                <div style={{ textAlign: "center" }}>
                  <img
                    src={arg.resource.extendedProps.profile_pic}
                    alt={arg.resource.title}
                    style={{
                      width: "35px",
                      height: "35px",
                      borderRadius: "50%",
                    }}
                  />
                  <div>{arg.resource.title}</div>
                </div>
              )}
              eventContent={
                calendarView === "dayGridMonth"
                  ? renderMonthViewEvent
                  : renderWeekDayViewEvent
              } // Use different format per view
              events={filteredEvents}
              datesSet={updateTitle} // Update title dynamically
              height="auto"
              contentHeight="auto"
              // scrollTime="08:00:00"
              nowIndicator={true}
              scrollTime={getCurrentTimeForScroll()}
              timeZone="local"
              eventClick={(info: any) => handleEventClick(info)}
              nowIndicatorClassNames="fc-now-indicator"
              showNonCurrentDates={false}
              eventMouseEnter={(info: any) => {
                if (calendarView == "resourceTimeGridDay") {
                  const rect = info.el.getBoundingClientRect();
                  setHoverPosition({ x: rect.left + 40, y: rect.top });
                  setHoveredEvent(info.event);
                } else if (calendarView == "timeGridWeek") {
                  const rect = info.el.getBoundingClientRect();
                  setHoverPosition({ x: rect.left + 140, y: rect.top + 100 });
                  setHoveredEvent(info.event);
                }
              }}
              eventMouseLeave={() => {
                if (calendarView !== "dayGridMonth") {
                  setHoveredEvent(null);
                }
              }}
            />
            {hoveredEvent && (
              <div
                style={{
                  position: "fixed",
                  top: hoverPosition.y - 100, // adjust offset
                  left: hoverPosition.x + 20,
                  zIndex: 9999,
                  background: "#ffffe1",
                  padding: "10px",
                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                  borderRadius: "0px",
                  width: "200px",
                }}
              >
                <p style={{ margin: 0, padding: 0 }}>
                  {hoveredEvent.start.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}{" "}
                  -
                  {hoveredEvent.end.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </p>
                <p style={{ margin: 0, padding: 0 }}>
                  Customer: {hoveredEvent.title}
                </p>
                <p style={{ margin: 0, padding: 0 }}>
                  Service: {hoveredEvent.extendedProps.servicename}
                </p>
                <p style={{ margin: 0, padding: 0 }}>
                  Staff: {hoveredEvent.extendedProps.staffname}
                </p>
                <p style={{ margin: 0, padding: 0 }} className="two-line-text">
                  Note: {hoveredEvent.extendedProps.note || "-"}
                </p>
                {/* <p>Status: {hoveredEvent.extendedProps.status}</p> */}
              </div>
            )}
          </div>
        </div>
      </Spin>
      {/* Status Filter at Bottom */}
      <div
        style={{
          position: "fixed",
          bottom: "70px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          right: "70px",
          zIndex: 4,
        }}
      >
        <div
          style={{
            background: "white",
            borderRadius: "8px",
            padding: "0px 18px",
            gap: "12px",
            display: "flex",
            // color: "#fff",
            lineHeight: "36px",
          }}
        >
          <Badge
            color={getStatusColor("upcoming")}
            text={
              <span className="badge-text">
                Upcoming ({statusCounts.upcoming})
              </span>
            }
          />
          <Badge
            color={getStatusColor("arrived")}
            text={
              <span className="badge-text">
                Arrived ({statusCounts.arrived})
              </span>
            }
          />
          <Badge
            color={getStatusColor("paid")}
            text={
              <span className="badge-text">Paid ({statusCounts.paid})</span>
            }
          />
          <Badge
            color={getStatusColor("noshow")}
            text={
              <span className="badge-text">
                No show ({statusCounts.noshow})
              </span>
            }
          />
          <Badge
            color={getStatusColor("cancel")}
            text={
              <span className="badge-text">Cancel ({statusCounts.cancel})</span>
            }
          />
        </div>
      </div>
      <AddAppointment
        visible={isModalVisible}
        onCancel={handleCancel}
        // onSelect={handleAppointmentAdd}
      />
      <AppointmentDetailsModal
        visible={isModalOpen}
        eventData={selectedappointmentInfo}
        onCancel={handleDetailsCancel}
      />
      <AppointmentListModal
        visible={isListModalVisible}
        staffid={selectedFilter}
        selectedappointmentInfo={selectedappointmentInfo}
        onCancel={() => setIsListModalVisible(false)}
        onView={handleViewAppointment}
        status={status}
      />
      ;
    </>
  );
};

export default Booking;
