import {
  ArrowLeftOutlined,
  CameraOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  InputNumber,
  message,
  Row,
  Select,
  Switch,
  Typography,
  Upload,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import { ErrorMessage, Field, FieldProps, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import OpeningHoursSettings from "../../components/model/merchant/OpeningHoursSettings";
import { encryptPassword } from "../../constants/encryptionUtils";
import { get, post, put, uploadImg } from "../../services/Apiclient";
import GenerateAPKModel from "../../components/model/GenerateAPKModel";
import CryptoJS from "crypto-js";
import { Helmet } from "react-helmet";
const logoIcon = `${process.env.PUBLIC_URL}/images/logo/Logo_Dark.png`;
const defaultStoreImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Store.png`;
//import "./MerchantStyle.css";

const { Option } = Select;
const { Text, Link } = Typography;

interface OpeningHours {
  [key: string]: string;
}

interface FormValues {
  store_name: string;
  email: string;
  countrycode: string;
  phone_number: string;
  store_username: string;
  address: string;
  country: string;
  password: string;
  time_zone: string;
  language: string;
  currency: string;
  store_introduction: string;
  time_format: string;
  postal_code: string;
  website: string;
  facebook: string;
  youtube: string;
  latitude: string | null;
  longitude: string | null;
  googleMapKey: string | null;
  store_status: boolean;
  parent_store: string;
  tax_id?: string;
  storeimage: string;
  business_type: string[];
  opening_hours: OpeningHours;
  privacy_policy: string;
  termsofconditions: string;
  gstno: string;
  salestaxno: string;
  servicetaxno: string;
  brnno: string;
  tin: string;
  tourismno: string;
  exemptionno: string;
  einvoiceenable: boolean;
  pagetitle: string;
  invoicetype: string;
  homepage: boolean;
  customerpage: boolean;
  salespage: boolean;
  inventorypage: boolean;
  bookingpage: boolean;
  onlinestorepage: boolean;
  noticeboardpage: boolean;
  reportpage: boolean;
  einvoicepage: boolean;
  myinvoicepage: boolean;
}

const websiteIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Website.png`;
const facebookIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Facebook.png`;
const youtubeIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_Youtobe.png`;

const sectionTitleStyle = {
  fontWeight: "bold",
  fontSize: "16px",
  marginBottom: "16px",
  marginTop: "16px",
};

const buttonStyle = {
  backgroundColor: "#1890ff",
  color: "#fff",
  border: "none",
  borderRadius: "4px",
  padding: "6px 12px",
  margin: "5px",
};

const dayStyle = {
  display: "flex",
  flexDirection: "column" as const,
  alignItems: "center",
  marginBottom: "16px",
};

const MerchantForm: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const validationSchema = Yup.object().shape({
    store_name: Yup.string().required("Store name is required"),
    store_username: Yup.string().required("Store username is required"),
    password: Yup.string().required("Password is required"),
    currency: Yup.string().required("Currency is required"),
    language: Yup.string().required("Language is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone_number: Yup.string()
      .required("Phone number is required")
      .min(10, "Phone number must be at least 10 characters")
      .max(13, "Phone number must be at MAx 13 characters"),
    address: Yup.string().required("Address is required"),
    latitude: Yup.number()
      .typeError("Latitude must be a number")
      .required("Latitude is required"),
    longitude: Yup.number()
      .typeError("Longitude must be a number")
      .required("Longitude is required"),
    googleMapKey: Yup.lazy(() => {
      return isEditMode
        ? Yup.string().notRequired()
        : Yup.string().required("Google map key is required");
    }),
    invoicetype: Yup.string().required("eInvoice Feature is required"),
    atLeastOnePageSelected: Yup.boolean().test(
      "at-least-one-selected",
      "Please select at least one page",
      function (value) {
        const {
          homepage,
          customerpage,
          salespage,
          inventorypage,
          bookingpage,
          onlinestorepage,
          noticeboardpage,
          reportpage,
          einvoicepage,
          myinvoicepage,
        } = this.parent;
        return (
          homepage ||
          customerpage ||
          salespage ||
          inventorypage ||
          bookingpage ||
          onlinestorepage ||
          noticeboardpage ||
          reportpage ||
          einvoicepage ||
          myinvoicepage
        );
      }
    ),
  });
  // const initialValues: FormValues = {
  //   store_name: "",
  //   email: "",
  //   phone_number: "",
  //   address: "",
  //   country: "",
  //   postal_code: "",
  //   store_username: "",
  //   time_zone: "",
  //   password: "",
  //   store_introduction: "",
  //   time_format: "",
  //   currency: "",
  //   website: "",
  //   facebook: "",
  //   youtube: "",
  //   language: "",
  //   latitude: undefined,
  //   longitude: undefined,
  //   store_status: true,
  //   parent_store: "",
  //   tax_id: "",
  //   business_type: [],
  //   opening_hours: {
  //     sunday: "09:00-18:00",
  //     monday: "09:00-18:00",
  //     tuesday: "09:00-18:00",
  //     wednesday: "09:00-18:00",
  //     thursday: "09:00-18:00",
  //     friday: "09:00-18:00",
  //     saturday: "09:00-18:00",
  //   },
  // };

  const [initialValues, setInitialValues] = useState<FormValues>({
    store_name: "",
    email: "",
    countrycode: "60",
    phone_number: "",
    address: "",
    country: "",
    postal_code: "",
    store_username: "",
    time_zone: "",
    password: "",
    store_introduction: "",
    time_format: "",
    currency: "",
    website: "",
    facebook: "",
    youtube: "",
    language: "",
    latitude: "",
    longitude: "",
    googleMapKey: "",
    store_status: true,
    parent_store: "",
    tax_id: "",
    storeimage: "",
    business_type: [],
    opening_hours: {
      sunday: "09:00-18:00",
      monday: "09:00-18:00",
      tuesday: "09:00-18:00",
      wednesday: "09:00-18:00",
      thursday: "09:00-18:00",
      friday: "09:00-18:00",
      saturday: "09:00-18:00",
    },
    privacy_policy: "",
    termsofconditions: "",
    gstno: "",
    salestaxno: "",
    servicetaxno: "",
    brnno: "",
    tin: "",
    tourismno: "",
    exemptionno: "",
    einvoiceenable: true,
    pagetitle: "",
    invoicetype: "",
    homepage: true,
    customerpage: true,
    salespage: true,
    inventorypage: true,
    bookingpage: true,
    onlinestorepage: true,
    noticeboardpage: true,
    reportpage: true,
    einvoicepage: true,
    myinvoicepage: true,
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDay, setCurrentDay] = useState<string>("");
  const [openingHours, setOpeningHours] = useState<OpeningHours>(
    initialValues.opening_hours
  );
  const [businessOptions, setBusinessOptions] = useState([]);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [parentStoreOptions, setParentStoreOptions] = useState([]);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);

  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [imageArray, setImageArray] = useState<string[]>([]);

  const [storeLogoUrl, setStoreLogoUrl] = useState<string>("");
  const [dataAdded, setDataAdded] = useState(false);
  const [isGenerateAPKModalVisible, setIsGenerateAPKModalVisible] =
    useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [apkStatus, setApkStatus] = useState<string | null>(null);
  const [apkJobId, setApkJobId] = useState<string | null>(null);
  const [apkDownloadUrl, setApkDownloadUrl] = useState<string | undefined>();
  const [apkDownloadUrlAAB, setApkDownloadUrlAAB] = useState<
    string | undefined
  >();
  const [isReady, setIsReady] = useState(false);
  const [isAnyProcessing, setIsAnyProcessing] = useState(false);

  const [isApkDownloaded, setIsApkDownloaded] = useState(false);
  const [isAabDownloaded, setIsAabDownloaded] = useState(false);

  const [fetchMerchantFlag, setFetchMerchantFlag] = useState(false);

  const checkMerchantStatus = async () => {
    try {
      const response = await get(`/check-merchant/${id}`);
      if (response.data) {
        setFetchMerchantFlag(response.data.fetch);
        if (response.data.fetch) {
          setApkDownloadUrl(
            `https://tools.suibox.my/public/apk/${id}/app-release.apk`
          );
          setApkDownloadUrlAAB(
            `https://tools.suibox.my/public/aab/${id}/app-release.aab`
          );
        } else {
          setApkDownloadUrl(undefined);
          setApkDownloadUrlAAB(undefined);
        }
      }
    } catch (error) {
      console.error("Error checking merchant status:", error);
    }
  };

  const handleAPKAdd = () => {
    setDataAdded(!dataAdded);
  };

  const showModal = () => {
    setIsGenerateAPKModalVisible(true);
  };

  const handleGenerateAPKCancel = () => {
    setIsGenerateAPKModalVisible(false);
  };

  const navigate = useNavigate();

  const fetchTimeZoneList = async () => {
    try {
      const response = await get("/merchant/timezonelist");

      if (response.data.success) {
        const options = response.data.data.map((timeZone: any) => ({
          label: timeZone.name,
          value: timeZone.name,
        }));
        setTimeZoneOptions(options); // Update state with fetched time zone options
      } else {
        console.error("Failed to fetch time zone options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching time zone options:", error);
    }
  };

  const handleOpeningHoursSave = (updatedHours: any) => {
    setOpeningHours(updatedHours); // Update the opening hours state
  };

  const fetchParentStores = async () => {
    try {
      const response = await get("/outlet/dropdown");
      if (response.data.success) {
        const options = response.data.data.map((store: any) => ({
          label: store.name,
          value: store.id,
        }));
        setParentStoreOptions(options); // Update state with fetched options
      } else {
        console.error(
          "Failed to fetch parent store options:",
          response.message
        );
      }
    } catch (error) {
      console.error("Error fetching parent store options:", error);
    }
  };

  const fetchData = async () => {
    try {
      const url = `/business/list`;
      const response = await get(url);
      if (response.data.success) {
        const options = response.data.data.map((business: any) => ({
          label: business.name,
          value: business.id.toString(),
        }));
        setBusinessOptions(options);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCurrencyList = async () => {
    try {
      const response = await get("/merchant/currencylist");
      if (response.data.success) {
        const options = response.data.data.map((currency: any) => ({
          label: currency.name,
          value: currency.name,
        }));
        setCurrencyOptions(options); // Update state with fetched currency options
      } else {
        console.error("Failed to fetch currency options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching currency options:", error);
    }
  };

  const fetchMerchantById = async (merchantId: string) => {
    try {
      const response = await get(`/merchant/${merchantId}`);
      if (response.data.success) {
        const merchantData = response.data.data;
        const mappedOpeningHours = merchantData.opening_hours.reduce(
          (acc: any, curr: any) => {
            acc[curr.dayname] = `${curr.starttime}-${curr.endtime}`;
            return acc;
          },
          {}
        );

        const prefilledImages = merchantData.merchantshowcase.map(
          (img: any) => img.imageUrl
        );
        const prefilledImageNames = merchantData.merchantshowcase.map(
          (img: any) => img.name
        );

        setImageUrls(prefilledImages);
        setImageArray(prefilledImageNames);

        let password = decodeURIComponent(merchantData.password);
        const secretKey = "G7HqA5VCbMMi3KQN2anRckza";
        const bytes = CryptoJS.AES.decrypt(password, secretKey);
        password = bytes.toString(CryptoJS.enc.Utf8);

        setInitialValues({
          ...initialValues,
          store_name: merchantData.name,
          email: merchantData.email,
          countrycode: merchantData.countrycode || "60",
          phone_number:
            `${merchantData.countrycode}${merchantData.phone_number}` || "",
          address: merchantData.address,
          country: merchantData.country,
          postal_code: merchantData.postal_code,
          store_username: merchantData.username,
          time_zone: merchantData.time_zone,
          store_introduction: merchantData.store_introduction,
          time_format: merchantData.time_format,
          currency: merchantData.currency,
          website: merchantData.website,
          facebook: merchantData.facebook,
          youtube: merchantData.youtube,
          language: merchantData.language,
          latitude: merchantData.latitude,
          longitude: merchantData.longitude,
          store_status: merchantData.store_status,
          parent_store: merchantData.parentstore,
          tax_id: merchantData.tax_id,
          password: password,
          googleMapKey: merchantData.googleMapKey,
          business_type: merchantData.business_type.map((type: string) => type),
          opening_hours: mappedOpeningHours,
          storeimage: merchantData.storeimage,
          privacy_policy: merchantData.privacy_policy,
          termsofconditions: merchantData.termsofconditions,
          gstno: merchantData.gstno,
          salestaxno: merchantData.salestaxno,
          servicetaxno: merchantData.servicetaxno,
          brnno: merchantData.brnno,
          tin: merchantData.tin,
          tourismno: merchantData.tourismno,
          exemptionno: merchantData.exemptionno,
          einvoiceenable: merchantData.einvoiceenable,
          pagetitle: merchantData.pagetitle,
          invoicetype: merchantData.invoicetype,
          homepage: merchantData.homepage,
          customerpage: merchantData.customerpage,
          salespage: merchantData.salespage,
          inventorypage: merchantData.inventorypage,
          bookingpage: merchantData.bookingpage,
          onlinestorepage: merchantData.onlinestorepage,
          noticeboardpage: merchantData.noticeboardpage,
          reportpage: merchantData.reportpage,
          einvoicepage: merchantData.einvoicepage,
          myinvoicepage: merchantData.myinvoicepage,
        });
        setOpeningHours(mappedOpeningHours);
        setStoreLogoUrl(merchantData.storeimageurl);
      } else {
        console.error(
          "Failed to fetch merchant details:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error fetching merchant details:", error);
    }
  };

  // Fetch Language List
  const fetchLanguageList = async () => {
    try {
      const response = await get("/merchant/languagelist");

      if (response.data.success) {
        const options = response.data.data.map((language: any) => ({
          label: language.name,
          value: language.name,
        }));
        setLanguageOptions(options); // Update state with fetched language options
      } else {
        console.error("Failed to fetch language options:", response.message);
      }
    } catch (error) {
      console.error("Error fetching language options:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchTimeZoneList();
    // fetchParentStores();
    fetchCurrencyList();
    fetchLanguageList();
    if (id) {
      setIsEditMode(true);
      checkMerchantStatus();
      fetchMerchantById(id);
    }
  }, []);

  const checkAPKStatus = async (jobId: string) => {
    try {
      const response = await get(`/build-status/${jobId}`);

      if (response.data.success) {
        if (response.data.status == "completed") {
          setApkStatus("ready");
          setApkDownloadUrl(
            `https://tools.suibox.my/public/apk/${id}/app-release.apk`
          );
          setApkDownloadUrlAAB(
            `https://tools.suibox.my/public/aab/${id}/app-release.aab`
          );
          const storedJobs = JSON.parse(
            localStorage.getItem("apkJobs") || "[]"
          );
          const updatedJobs = storedJobs.map((job: any) =>
            job.merchantId === id ? { ...job, status: "completed" } : job
          );
          localStorage.setItem("apkJobs", JSON.stringify(updatedJobs));
          setIsAnyProcessing(
            updatedJobs.some((job: any) => job.status === "processing")
          );
        } else {
          setApkStatus("processing");
        }
      } else {
        const storedJobs = JSON.parse(localStorage.getItem("apkJobs") || "[]");
        const updatedJobs = storedJobs.map((job: any) =>
          job.merchantId === id ? { ...job, status: "failed" } : job
        );
        localStorage.setItem("apkJobs", JSON.stringify(updatedJobs));
        setApkStatus("generate");
      }
    } catch (error) {
      const storedJobs = JSON.parse(localStorage.getItem("apkJobs") || "[]");
      const updatedJobs = storedJobs.map((job: any) =>
        job.merchantId === id ? { ...job, status: "failed" } : job
      );
      localStorage.setItem("apkJobs", JSON.stringify(updatedJobs));
      console.error("Error checking APK build status:", error);
      setApkStatus("generate"); // Reset status on error
    }
  };

  useEffect(() => {
    const storedJobs = JSON.parse(localStorage.getItem("apkJobs") || "[]");
    const merchantJob = storedJobs.find(
      (job: any) => job.merchantId === id && job.status == "processing"
    );

    if (merchantJob) {
      setApkJobId(merchantJob.jobId);
      setApkStatus("processing");
      setIsGenerating(true);
      checkAPKStatus(merchantJob.jobId);
    }

    setIsAnyProcessing(
      storedJobs.some((job: any) => job.status === "processing")
    );

    // ✅ Run the interval every 60 seconds
    const interval = setInterval(() => {
      if (apkJobId && apkStatus !== "ready") {
        checkAPKStatus(apkJobId);
      }
    }, 60000); // 60 seconds

    return () => clearInterval(interval); // ✅ Clean up on unmount
  }, [apkJobId]);

  const startGeneratingAPK = () => {
    // setIsGenerating(true);
    // setApkStatus("processing"); // ✅ Change status only when button is clicked
    setIsGenerateAPKModalVisible(true);
  };

  // useEffect(() => {
  //   if (isGenerating) {
  //     setApkStatus("processing"); // ✅ Change status only when button is clicked
  //   } else {
  //     setApkStatus("generate"); // ✅ Change status only when button is clicked
  //   }
  // }, [isGenerating]);

  const setDownloadLinks = () => {
    setIsReady(true);
  };

  const showEditModal = () => {
    setIsModalVisible(true);
  };

  const handleTimeChange = (times: any) => {
    if (times && times[0] && times[1]) {
      const updatedHours = {
        ...openingHours,
        [currentDay]: `${times[0].format("HH:mm")}-${times[1].format("HH:mm")}`,
      };
      setOpeningHours(updatedHours);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSubmit = async (values: FormValues) => {
    const businessTypeArray = values.business_type.map((id) =>
      typeof id === "string" ? parseInt(id.trim(), 10) : id
    );

    const convertSchedule = (scheduleObj: { [key: string]: unknown }) => {
      return Object.entries(scheduleObj).map(([day, time]) => {
        if (typeof time === "string") {
          const [starttime, endtime] = time.split("-");
          return {
            day: day.charAt(0).toUpperCase() + day.slice(1),
            starttime,
            endtime,
          };
        } else {
          throw new Error(
            `Expected a string for time, but received ${typeof time}`
          );
        }
      });
    };

    let formattedPhoneNumber = values.phone_number;

    // Remove country code from phone_number
    if (values.countrycode) {
      formattedPhoneNumber = values.phone_number.replace(
        new RegExp(`^\\+?${values.countrycode}`),
        ""
      );
    }

    const filteredImageArray = imageArray.filter(
      (name) => name && name.trim() !== ""
    );

    let currency = values.currency.split("-");

    const formData = {
      name: values.store_name,
      username: values.store_username,
      email: values.email,
      countrycode: values.countrycode,
      phone_number: formattedPhoneNumber,
      password: values.password ? encryptPassword(values.password) : undefined,
      parentstore: values.parent_store || "-",
      store_status: values.store_status,
      address: values.address,
      business_type: businessTypeArray,
      merchantshowcase: filteredImageArray,
      opening_hours: convertSchedule(openingHours),
      time_zone: values.time_zone,
      tax_id: values.tax_id,
      currency: values.currency,
      currencysymbol: currency[2],
      currencycode: currency[1],
      website: values.website,
      facebook: values.facebook,
      youtube: values.youtube,
      time_format: values.time_format,
      latitude: values.latitude,
      longitude: values.longitude,
      language: values.language,
      googleMapKey: values.googleMapKey,
      storeimage: values.storeimage,
      store_introduction: values.store_introduction,
      privacy_policy: values.privacy_policy,
      termsofconditions: values.termsofconditions,
      gstno: values.gstno,
      salestaxno: values.salestaxno,
      servicetaxno: values.servicetaxno,
      brnno: values.brnno,
      tin: values.tin,
      tourismno: values.tourismno,
      exemptionno: values.exemptionno,
      einvoiceenable: values.einvoiceenable,
      pagetitle: values.pagetitle,
      invoicetype: values.invoicetype,
      homepage: values.homepage,
      customerpage: values.customerpage,
      salespage: values.salespage,
      inventorypage: values.inventorypage,
      bookingpage: values.bookingpage,
      onlinestorepage: values.onlinestorepage,
      noticeboardpage: values.noticeboardpage,
      reportpage: values.reportpage,
      einvoicepage: values.einvoicepage,
      myinvoicepage: values.myinvoicepage,
    };
    console.log("formdata", formData);

    try {
      const response = id
        ? await put(`/merchant/${id}`, formData) // Update merchant
        : await post("/merchant/addaccount", formData); // Create new merchant
      if ((id && response.status === 200) || (!id && response.status === 201)) {
        message.success("Form submitted successfully");
        navigate("/merchant/list");
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("An error occurred during form submission:", error);
    }
  };

  // Handle images selected for upload
  const handleImagesChange = (formData: any) => {
    setImageUrls(formData); // Store the image URLs
  };

  // Handle image deletion
  const handleDeleteImage = (images: any) => {
    const filenames = images.map((url: any) => url.split("/").pop());
    setImageArray(filenames); // Update image array after deletion
  };

  const handleStoreLogoUpload = async (fileList: File[]) => {
    console.log(fileList, "filelist");
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append("file", file); // Ensure this matches the API's expected field name
    });

    try {
      const response = await uploadImg(
        "/merchant/uploadimage/merchantlogo",
        formData
      );

      if (response?.data?.success) {
        const uploadedFileName = response.data.data[0]?.imagename;
        setInitialValues((prev) => ({
          ...prev,
          storeimage: uploadedFileName,
        }));

        setStoreLogoUrl(response.data.data[0]?.baseuri);
      } else {
        message.error(response.data.message || "Failed to upload store logo.");
      }
    } catch (error) {
      message.error("An error occurred while uploading the store logo.");
      console.error("Upload error:", error);
    }
  };

  const handleRemoveStoreLogoImage = () => {
    setStoreLogoUrl("");
    setInitialValues((prev) => ({
      ...prev,
      storeimage: "",
    }));
  };

  const uploadStoreLogoProps = {
    showUploadList: false,
    beforeUpload: (file: any, fileList: any[]) => {
      handleStoreLogoUpload(fileList); // Call handleUpload for custom behavior
      return false; // Prevent default upload
    },
  };

  const handleRemoveShowcaseImage = (index: number) => {
    setImageUrls((prev) => {
      const updatedUrls = [...prev];
      updatedUrls[index] = ""; // Clear the image at the specified index
      return updatedUrls;
    });

    setImageArray((prev) => {
      const updatedArray = [...prev];
      updatedArray[index] = ""; // Clear the corresponding image name
      return updatedArray;
    });
  };

  const handleShowCaseUpload = async (file: File, index: number) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await uploadImg(
        "/merchant/uploadimage/merchantshowcase",
        formData
      );

      if (response?.data?.success) {
        const uploadedImage = response.data.data[0];
        const uploadedUrl = uploadedImage.baseuri;
        const uploadedName = uploadedImage.imagename;

        setImageUrls((prev) => {
          const updatedUrls = [...prev];
          updatedUrls[index] = uploadedUrl; // Add the uploaded image at the correct index
          return updatedUrls;
        });

        setImageArray((prev) => {
          const updatedArray = [...prev];
          updatedArray[index] = uploadedName; // Maintain the image name array for API submission
          return updatedArray;
        });

        message.success("Image uploaded successfully!");
      } else {
        message.error(response.data.message || "Failed to upload image.");
      }
    } catch (error) {
      message.error("An error occurred during image upload.");
      console.error("Upload error:", error);
    }

    return false; // Prevent default upload behavior
  };

  const uploadStoreShowcaseProps = {
    showUploadList: false,
    beforeUpload: (file: any) => {
      return false; // Prevent default upload behavior
    },
  };

  const handleDownload = (url: string, type: "apk" | "aab") => {
    window.location.href = url;

    setTimeout(() => {
      if (type === "apk") setIsApkDownloaded(true);
      if (type === "aab") setIsAabDownloaded(true);

      if (isApkDownloaded && isAabDownloaded) {
        const storedJobs = JSON.parse(localStorage.getItem("apkJobs") || "[]");
        const updatedJobs = storedJobs.filter(
          (job: any) => job.merchantId !== id
        );
        localStorage.setItem("apkJobs", JSON.stringify(updatedJobs));

        setApkJobId(null);
        setApkStatus("generate"); // ✅ Reset to "Generate APK" after download
        setApkDownloadUrl(undefined);
        setApkDownloadUrlAAB(undefined);
        setIsApkDownloaded(false);
        setIsAabDownloaded(false);

        setIsAnyProcessing(
          updatedJobs.some((job: any) => job.status === "processing")
        );
      }

      message.success(`${type.toUpperCase()} downloaded successfully.`);
    }, 2000);
  };

  const handleGenerateNow = () => {
    setIsGenerateAPKModalVisible(true);
  };

  return (
    <>
      <Helmet>
        <title>Daco</title>
        <meta name="description" content={"Daco salon"} />
        <meta property="og:title" content={"Daco"} />
        <meta property="og:description" content={"Daco"} />
        <meta property="og:image" content={logoIcon} />
        <meta property="og:url" content={`https://new.suibox.my`} />
        <meta name="twitter:card" content="summary_large_image" />
        {/* Add more tags as needed */}
      </Helmet>
      <Content className="mainContainer">
        <Row justify="space-between" align="middle">
          <Col>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                onClick={() => navigate("/merchant/list")}
                style={{ cursor: "pointer" }}
              >
                <ArrowLeftOutlined
                  style={{ marginRight: 12, color: "#325df2", fontSize: 18 }}
                />
              </div>
              <h2 style={{ margin: 0 }}>
                {" "}
                {isEditMode ? "Edit Merchant" : "Add new Merchant"}
              </h2>
            </div>
          </Col>
          {/* <Col>
          {isEditMode && (
            <Button
              type="primary"
              style={{ fontWeight: "bold" }}
              onClick={showModal}
            >
              Generate APK
            </Button>
          )}
        </Col> */}
          {/* <Col>
          {!isReady ? (
            isEditMode && (
              <Button
                type="primary"
                style={{ fontWeight: "bold" }}
                onClick={startGeneratingAPK}
                disabled={isGenerating}
              >
                {isGenerating ? "Generate APK" : "Generate APK"}
              </Button>
            )
          ) : (
            <>
              {apkDownloadUrl && (
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "green",
                    borderColor: "green",
                    marginRight: "10px",
                  }}
                  onClick={() => {
                    localStorage.setItem("apkDownloaded", "true");
                    window.location.href = apkDownloadUrl;
                  }}
                >
                  <a href={apkDownloadUrl} download style={{ color: "#fff" }}>
                    Download APK
                  </a>
                </Button>
              )}

              {apkDownloadUrlAAB && (
                <Button
                  type="primary"
                  style={{
                    backgroundColor: "green",
                    borderColor: "green",
                  }}
                >
                  <a
                    href={apkDownloadUrlAAB}
                    download
                    style={{ color: "#fff" }}
                  >
                    Download AAB
                  </a>
                </Button>
              )}
            </>
          )}
        </Col> */}
          {/* <Col>
          {isEditMode && (
            <>
              {apkStatus == "ready" ? (
                <>
                  {apkDownloadUrl && (
                    <Button
                      style={{
                        backgroundColor: "green",
                        borderColor: "green",
                      }}
                      type="primary"
                      onClick={() => handleDownload(apkDownloadUrl, "apk")}
                    >
                      Download APK
                    </Button>
                  )}
                  {apkDownloadUrlAAB && (
                    <Button
                      style={{
                        marginLeft: "10px",
                        backgroundColor: "green",
                        borderColor: "green",
                      }}
                      type="primary"
                      onClick={() => handleDownload(apkDownloadUrlAAB, "aab")}
                    >
                      Download AAB
                    </Button>
                  )}
                </>
              ) : !isApkDownloaded && !isAabDownloaded ? (
                <Button
                  type="primary"
                  onClick={startGeneratingAPK}
                  disabled={isAnyProcessing || apkStatus === "processing"}
                >
                  {apkStatus === "processing"
                    ? "Processing..."
                    : "Generate APK"}
                </Button>
              ) : null}
            </>
          )}
        </Col> */}
          <Col>
            {isEditMode && (
              <>
                {fetchMerchantFlag ? (
                  <>
                    {apkDownloadUrl && (
                      <Button
                        style={{
                          backgroundColor: "green",
                          borderColor: "green",
                        }}
                        type="primary"
                        onClick={() => handleDownload(apkDownloadUrl, "apk")}
                      >
                        Downloaded APK
                      </Button>
                    )}
                    {apkDownloadUrlAAB && (
                      <Button
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "green",
                          borderColor: "green",
                        }}
                        type="primary"
                        onClick={() => handleDownload(apkDownloadUrlAAB, "aab")}
                      >
                        Downloaded AAB
                      </Button>
                    )}
                    <Button
                      type="primary"
                      style={{
                        marginLeft: "10px",
                      }}
                      onClick={startGeneratingAPK}
                      disabled={isAnyProcessing || apkStatus === "processing"}
                    >
                      {apkStatus === "processing"
                        ? "Processing..."
                        : "New Generate APK"}
                    </Button>
                  </>
                ) : (
                  <Button
                    type="primary"
                    onClick={startGeneratingAPK}
                    disabled={isAnyProcessing || apkStatus === "processing"}
                  >
                    {apkStatus === "processing"
                      ? "Processing..."
                      : "Generate APK"}
                  </Button>
                )}
              </>
            )}
          </Col>
        </Row>
        <Content
          style={{
            marginTop: "10px",
            backgroundColor: "#fafafa",
            borderRadius: "12px",
            border: "1px solid #e0e7fe",
            boxSizing: "border-box",
            height: "600px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="form-scrollable-content">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ setFieldValue, values, errors }: any) => (
                <Form
                  style={{
                    // maxWidth: "900px",
                    // margin: "auto",
                    // backgroundColor: "#fff",
                    // padding: "24px",
                    // borderRadius: "8px",
                    // boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                    paddingTop: "15px",
                  }}
                >
                  {/* Basic Info Section */}
                  <div style={sectionTitleStyle}>Basic Info</div>
                  <Row gutter={16} style={{ marginTop: "16px" }}>
                    {/* Store Logo */}
                    <Col span={3}>
                      <div style={{ marginTop: "30px" }}>
                        {values.storeimage !== "default.png" &&
                        storeLogoUrl !== "" &&
                        storeLogoUrl !== null &&
                        storeLogoUrl !== undefined ? (
                          <div className="image-preview">
                            <img src={storeLogoUrl} alt={`Uploaded image`} />
                            <CloseOutlined
                              className="remove-icon"
                              onClick={() => handleRemoveStoreLogoImage()}
                            />
                          </div>
                        ) : (
                          <>
                            {values.storeimage == "default.png" ? (
                              <>
                                <div className="image-preview">
                                  <img
                                    src={defaultStoreImage}
                                    alt={`Uploaded image`}
                                  />
                                  <CloseOutlined
                                    className="remove-icon"
                                    onClick={() => handleRemoveStoreLogoImage()}
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <Upload
                                  {...uploadStoreLogoProps}
                                  multiple={false}
                                  accept=".jpg,.png"
                                >
                                  <div className="upload-box">
                                    <CameraOutlined className="upload-icon" />
                                  </div>
                                </Upload>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </Col>

                    {/* Store Showcase Images */}
                    <Col span={21}>
                      <label>Store showcase images</label>
                      <div
                        style={{
                          display: "flex",
                          gap: "10px",
                          marginTop: "10px",
                          flexWrap: "wrap",
                        }}
                      >
                        {[...Array(6)].map((_, index) => (
                          <div key={index} className="image-preview">
                            {imageUrls[index] ? (
                              <>
                                <img
                                  src={imageUrls[index]}
                                  alt={`Showcase ${index + 1}`}
                                />
                                <CloseOutlined
                                  className="remove-icon"
                                  onClick={() =>
                                    handleRemoveShowcaseImage(index)
                                  }
                                />
                              </>
                            ) : (
                              <Upload
                                {...uploadStoreShowcaseProps}
                                multiple={false} // Allow only one file per upload
                                accept=".jpg,.png"
                                beforeUpload={(file) =>
                                  handleShowCaseUpload(file, index)
                                }
                              >
                                <div className="upload-box">
                                  <CameraOutlined className="upload-icon" />
                                </div>
                              </Upload>
                            )}
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ marginTop: "16px" }}>
                    <Col xs={24} sm={12} lg={8}>
                      <label>Store Name *</label>
                      <Field name="store_name">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                store_name: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="store_name">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                      <label>Store Username *</label>
                      <Field name="store_username">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                store_username: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="store_username">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col xs={24} sm={12} lg={8} style={{ marginTop: "2%" }}>
                      <label style={{ marginRight: "2.5%" }}>
                        Store Status
                      </label>
                      <Switch
                        checked={values.store_status}
                        onChange={(checked) => {
                          setFieldValue("store_status", checked);
                          setInitialValues((prevValues) => ({
                            ...prevValues,
                            store_status: checked, // Set to the field value
                          }));
                        }}
                      />
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ marginTop: "16px" }}>
                    <Col xs={24} sm={12} lg={8}>
                      <label>Email *</label>
                      <Field name="email">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                email: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="email">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                      <label>Password *</label>
                      <Field name="password">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                password: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="password">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    {/* <Col xs={24} sm={12} lg={8} style={{ marginTop: "2%" }}>
                      <label style={{ marginRight: "2.5%" }}>
                        Generate E invoice
                      </label>
                      <Switch
                        checked={values.einvoiceenable}
                        onChange={(checked) => {
                          setFieldValue("einvoiceenable", checked);
                          setInitialValues((prevValues) => ({
                            ...prevValues,
                            einvoiceenable: checked, // Set to the field value
                          }));
                        }}
                      />
                    </Col> */}
                  </Row>
                  <Row gutter={16} style={{ marginTop: "16px" }}>
                    <Col xs={24} sm={12} lg={8}>
                      <label>Phone Number *</label>
                      {/* <Field name="phone_number">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                      <Field name="phone_number">
                        {({ field, form }: any) => (
                          <PhoneInput
                            country={"my"} // Set default country code, change this as needed
                            value={
                              typeof field.value === "string" ? field.value : ""
                            }
                            enableSearch
                            countryCodeEditable={false}
                            onChange={(value: any, country: any) => {
                              form.setFieldValue(
                                "countrycode",
                                country.dialCode
                              );
                              form.setFieldValue("phone_number", value);
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                countrycode: country.dialCode, // Set to the field value
                                phone_number: value,
                              }));
                            }}
                            inputStyle={{
                              width: "100%",
                              height: "46px",
                              paddingLeft: "35px", // Adjust space for the flag
                              borderColor: "#e0e0e0", // Light border color for a softer look
                              borderRadius: "5px",
                              color: "#5c5c5c", // Softer text color
                              backgroundColor: "#f8f9fd", // Light background color
                            }}
                            containerStyle={{
                              width: "100%",
                            }}
                            placeholder="Please enter" // Optional: style customization
                          />
                        )}
                      </Field>
                      <ErrorMessage name="phone_number">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col span={8} xs={24} sm={12} lg={8}>
                      <label>Tax ID</label>
                      <Field name="tax_id">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                tax_id: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>

                    <Col span={8} xs={24} sm={12} lg={8}>
                      <label>Address *</label>
                      <Field name="address">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                address: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="address">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                  </Row>

                  <Row gutter={16} style={{ marginTop: "16px" }}>
                    <Col
                      span={8}
                      xs={24}
                      sm={12}
                      lg={8}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label>Time Zone</label>
                      <Select
                        value={values.time_zone}
                        onChange={(value) => {
                          setFieldValue("time_zone", value);
                          setInitialValues((prevValues) => ({
                            ...prevValues,
                            time_zone: value, // Set to the field value
                          }));
                        }}
                        placeholder="Select time zone"
                      >
                        {timeZoneOptions.map((option: any) => (
                          <Option key={`${option.value}`} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                    </Col>

                    <Col
                      span={8}
                      xs={24}
                      sm={12}
                      lg={8}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label>Time Format</label>
                      <Select
                        value={values.time_format}
                        onChange={(value) => {
                          setFieldValue("time_format", value);
                          setInitialValues((prevValues) => ({
                            ...prevValues,
                            time_format: value, // Set to the field value
                          }));
                        }}
                        placeholder="Select time format"
                      >
                        <Option value="12-hour">12-hour</Option>
                        <Option value="24-hour">24-hour</Option>
                      </Select>
                    </Col>

                    <Col
                      span={8}
                      xs={24}
                      sm={12}
                      lg={8}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label>Currency *</label>
                      <Select
                        value={values.currency}
                        onChange={(value) => {
                          setFieldValue("currency", value);
                          setInitialValues((prevValues) => ({
                            ...prevValues,
                            currency: value, // Set to the field value
                          }));
                        }}
                        placeholder="Select currency"
                      >
                        {currencyOptions.map((option: any) => (
                          <Option key={`${option.value}`} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                      <ErrorMessage name="currency">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ marginTop: "16px" }}>
                    <Col
                      span={8}
                      xs={24}
                      sm={12}
                      lg={8}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <label>Language *</label>
                      <Select
                        value={values.language}
                        onChange={(value) => {
                          setFieldValue("language", value);
                          setInitialValues((prevValues) => ({
                            ...prevValues,
                            language: value, // Set to the field value
                          }));
                        }}
                        placeholder="Select language"
                      >
                        {languageOptions.map((option: any) => (
                          <Option key={`${option.value}`} value={option.value}>
                            {option.label}
                          </Option>
                        ))}
                      </Select>
                      <ErrorMessage name="language">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                      <label>Latitude *</label>
                      <InputNumber
                        type="number"
                        value={values.latitude}
                        onChange={(value) => {
                          setFieldValue("latitude", value);
                          setInitialValues((prevValues) => ({
                            ...prevValues,
                            latitude: value, // Set to the field value
                          }));
                        }}
                        placeholder="Latitude"
                        style={{
                          width: "100%",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      />
                      <ErrorMessage name="latitude">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                      <label>Longitude *</label>
                      <InputNumber
                        type="number"
                        value={values.longitude}
                        onChange={(value) => {
                          setFieldValue("longitude", value);
                          setInitialValues((prevValues) => ({
                            ...prevValues,
                            longitude: value, // Set to the field value
                          }));
                        }}
                        placeholder="Longitude"
                        style={{
                          width: "100%",
                          height: "40px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      />
                      <ErrorMessage name="longitude">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                  </Row>

                  <Row gutter={16} style={{ marginTop: "16px" }}>
                    <Col xs={24} sm={12} lg={8}>
                      <label>Google map key *</label>
                      <Field name="googleMapKey">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                googleMapKey: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="googleMapKey">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                      <label>Login Page Title</label>
                      <Field name="pagetitle">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                pagetitle: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col xs={24} sm={12} lg={8}>
                      <label>eInvoice Feature *</label>
                      <Select
                        value={values.invoicetype}
                        onChange={(value) => {
                          setFieldValue("invoicetype", value);
                          setInitialValues((prevValues) => ({
                            ...prevValues,
                            invoicetype: value, // Set to the field value
                          }));
                        }}
                        placeholder="Select eInvoice Feature"
                      >
                        <Option key="No eInvoice" value="No eInvoice">
                          No eInvoice
                        </Option>
                        <Option
                          key="Bill to eInvoice Directly"
                          value="Bill to eInvoice Directly"
                        >
                          Bill to eInvoice Directly
                        </Option>
                        <Option
                          key="Manually eInvoice"
                          value="Manually eInvoice"
                        >
                          Manually eInvoice
                        </Option>
                        <Option key="QRCode eInvoice" value="QRCode eInvoice">
                          QRCode eInvoice
                        </Option>
                        <Option
                          key="Simplify eInvoice Tools"
                          value="Simplify eInvoice Tools"
                        >
                          Simplify eInvoice Tools
                        </Option>
                      </Select>
                      <ErrorMessage name="invoicetype">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>
                  </Row>

                  {/* More Business Info Section */}
                  <div style={sectionTitleStyle}>More Business Info</div>
                  <Row gutter={16} style={{ marginTop: "16px" }}>
                    <Col span={8} xs={24} sm={12} lg={8}>
                      <label>GST. No.</label>
                      <Field name="gstno">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                gstno: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>

                    <Col span={8} xs={24} sm={12} lg={8}>
                      {/* <label>Website</label>
                    <Field name="website">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                      <label>Sales Tax No.</label>
                      <Field name="salestaxno">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                salestaxno: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>

                    <Col span={8} xs={24} sm={12} lg={8}>
                      {/* <label>Facebook</label>
                    <Field name="facebook">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                      <label>Service Tax No</label>
                      <Field name="servicetaxno">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                servicetaxno: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col span={8} xs={24} sm={12} lg={8}>
                      {/* <label>YouTube</label>
                    <Field name="youtube">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                      <label>BRN (New) No.</label>
                      <Field name="brnno">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                brnno: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col span={8} xs={24} sm={12} lg={8}>
                      {/* <label>YouTube</label>
                    <Field name="youtube">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                      <label>TIN</label>
                      <Field name="tin">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                tin: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col span={8} xs={24} sm={12} lg={8}>
                      {/* <label>YouTube</label>
                    <Field name="youtube">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                      <label>Tourism No.</label>
                      <Field name="tourismno">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                tourismno: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col span={8} xs={24} sm={12} lg={8}>
                      {/* <label>YouTube</label>
                    <Field name="youtube">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                      <label>Exemption No.</label>
                      <Field name="exemptionno">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                exemptionno: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ marginTop: "16px" }}>
                    <Col span={8} xs={24} sm={12} lg={8}>
                      <label>Store Introduction</label>
                      <Field name="store_introduction">
                        {({ field }: any) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                store_introduction: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                      <ErrorMessage name="store_introduction">
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    </Col>

                    <Col span={8} xs={24} sm={12} lg={8}>
                      {/* <label>Website</label>
                    <Field name="website">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                      <label>Website</label>
                      <Field name="website">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            prefix={
                              <img
                                src={websiteIcon}
                                alt="Website"
                                style={{ width: 24 }}
                              />
                            }
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                website: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>

                    <Col span={8} xs={24} sm={12} lg={8}>
                      {/* <label>Facebook</label>
                    <Field name="facebook">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                      <label>Facebook</label>
                      <Field name="facebook">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            prefix={
                              <img
                                src={facebookIcon}
                                alt="Facebook"
                                style={{ width: 24 }}
                              />
                            }
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                facebook: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                    <Col span={8} xs={24} sm={12} lg={8}>
                      {/* <label>YouTube</label>
                    <Field name="youtube">
                      {({ field }: any) => (
                        <Input {...field} placeholder="Please enter" />
                      )}
                    </Field> */}
                      <label>YouTube</label>
                      <Field name="youtube">
                        {({ field }: FieldProps) => (
                          <Input
                            {...field}
                            placeholder="Please enter"
                            prefix={
                              <img
                                src={youtubeIcon}
                                alt="YouTube"
                                style={{ width: 24 }}
                              />
                            }
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                youtube: e.target.value, // Set to the field value
                              }));
                            }}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>
                  <Row gutter={16} style={{ marginTop: "16px" }}>
                    <Col span={24}>
                      <label style={{ marginRight: "16px" }}>
                        Business Type :{" "}
                      </label>
                      <Checkbox.Group
                        value={values.business_type} // Prefill from form values
                        options={businessOptions} // Use fetched options
                        onChange={(checkedValues: string[]) => {
                          setFieldValue("business_type", checkedValues); // Update form field with selected values
                          setInitialValues((prevValues) => ({
                            ...prevValues,
                            business_type: checkedValues, // Set to the field value
                          }));
                        }}
                      />
                    </Col>
                  </Row>

                  {/* Opening Hours Section */}
                  {/* <div style={{ marginTop: "24px" }}>
                  <label style={sectionTitleStyle}>Opening hours</label>
                  <Row>
                    {Object.keys(openingHours).map((day) => (
                      <Col
                        span={3}
                        style={dayStyle}
                        xs={12}
                        sm={8}
                        md={6}
                        lg={3}
                        key={day}
                      >
                        <Button
                          style={buttonStyle}
                          onClick={() => showEditModal(day)}
                        >
                          {day.charAt(0).toUpperCase() + day.slice(1)}
                        </Button>
                        <span>{openingHours[day]}</span>
                      </Col>
                    ))}
                  </Row>
                </div> */}
                  <div style={{ marginTop: "16px" }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "16px",
                      }}
                    >
                      <Text
                        strong
                        style={{ fontSize: "16px", marginRight: "8px" }}
                      >
                        Opening hours
                      </Text>
                      <Link
                        style={{
                          textDecoration: "underline",
                          textUnderlineOffset: "4px",
                        }}
                        onClick={() => showEditModal()}
                      >
                        Edit
                      </Link>
                    </div>
                    <Row gutter={16}>
                      {Object.keys(openingHours).map((day) => (
                        <Col xs={24} sm={12} md={6} lg={3} key={day}>
                          <Card
                            // className="day-card"
                            className={`${
                              openingHours[day] !== null &&
                              openingHours[day] !== "closed-null"
                                ? "day-card"
                                : "day-card-closed"
                            }`}
                            bodyStyle={{
                              padding: "0px 0px 20px 0px",
                              textAlign: "center",
                            }}
                          >
                            <div
                              className={`${
                                openingHours[day] !== null &&
                                openingHours[day] !== "closed-null"
                                  ? "day-header"
                                  : "day-header-closed"
                              }`}
                            >
                              {day.charAt(0).toUpperCase() + day.slice(1)}
                            </div>
                            <div style={{ paddingTop: "10px" }}>
                              {openingHours[day] !== null &&
                              openingHours[day] !== "closed-null"
                                ? openingHours[day]
                                : "Closed"}
                            </div>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </div>

                  <Row gutter={24} style={{ marginTop: "16px" }}>
                    <Col span={24} xs={24} sm={12} lg={24}>
                      <label>Privacy Policy</label>
                      <Field name="privacy_policy">
                        {({ field }: any) => (
                          <TextArea
                            rows={4}
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                privacy_policy: e.target.value,
                              }));
                            }}
                            style={{ width: "100%", resize: "none" }}
                            autoSize={{ minRows: 4, maxRows: 12 }}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginTop: "16px" }}>
                    <Col span={24} xs={24} sm={12} lg={24}>
                      <label>Terms of Conditions</label>
                      <Field name="termsofconditions">
                        {({ field }: any) => (
                          <TextArea
                            rows={4}
                            {...field}
                            placeholder="Please enter"
                            onChange={(e) => {
                              setInitialValues((prevValues) => ({
                                ...prevValues,
                                termsofconditions: e.target.value, // Set to the field value
                              }));
                            }}
                            style={{ width: "100%", resize: "none" }}
                            autoSize={{ minRows: 4, maxRows: 12 }}
                          />
                        )}
                      </Field>
                    </Col>
                  </Row>

                  <Row gutter={24} style={{ marginTop: "16px" }}>
                    <Col span={24} xs={24} sm={12} lg={24}>
                      <Text
                        strong
                        style={{ fontSize: "16px", marginRight: "8px" }}
                      >
                        Merchant Control Panel
                      </Text>
                      {errors.atLeastOnePageSelected && (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "16px",
                          }}
                        >
                          {errors.atLeastOnePageSelected}
                        </div>
                      )}
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "8px",
                          marginTop: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Field name="homepage">
                          {({ field }: any) => (
                            <Checkbox
                              {...field}
                              checked={initialValues.homepage}
                              onChange={(e) => {
                                setInitialValues((prevValues) => ({
                                  ...prevValues,
                                  homepage: e.target.checked, // Set to the field value
                                }));
                              }}
                            >
                              Home
                            </Checkbox>
                          )}
                        </Field>
                        <Field name="customerpage">
                          {({ field }: any) => (
                            <Checkbox
                              {...field}
                              checked={initialValues.customerpage}
                              onChange={(e) => {
                                setInitialValues((prevValues) => ({
                                  ...prevValues,
                                  customerpage: e.target.checked, // Set to the field value
                                }));
                              }}
                            >
                              Customer
                            </Checkbox>
                          )}
                        </Field>
                        <Field name="salespage">
                          {({ field }: any) => (
                            <Checkbox
                              {...field}
                              checked={initialValues.salespage}
                              onChange={(e) => {
                                setInitialValues((prevValues) => ({
                                  ...prevValues,
                                  salespage: e.target.checked, // Set to the field value
                                }));
                              }}
                            >
                              Sales
                            </Checkbox>
                          )}
                        </Field>
                        <Field name="inventorypage">
                          {({ field }: any) => (
                            <Checkbox
                              {...field}
                              checked={initialValues.inventorypage}
                              onChange={(e) => {
                                setInitialValues((prevValues) => ({
                                  ...prevValues,
                                  inventorypage: e.target.checked, // Set to the field value
                                }));
                              }}
                            >
                              Inventory
                            </Checkbox>
                          )}
                        </Field>
                        <Field name="bookingpage">
                          {({ field }: any) => (
                            <Checkbox
                              {...field}
                              checked={initialValues.bookingpage}
                              onChange={(e) => {
                                setInitialValues((prevValues) => ({
                                  ...prevValues,
                                  bookingpage: e.target.checked, // Set to the field value
                                }));
                              }}
                            >
                              Booking
                            </Checkbox>
                          )}
                        </Field>
                        <Field name="onlinestorepage">
                          {({ field }: any) => (
                            <Checkbox
                              {...field}
                              checked={initialValues.onlinestorepage}
                              onChange={(e) => {
                                setInitialValues((prevValues) => ({
                                  ...prevValues,
                                  onlinestorepage: e.target.checked, // Set to the field value
                                }));
                              }}
                            >
                              Online Store
                            </Checkbox>
                          )}
                        </Field>
                        <Field name="noticeboardpage">
                          {({ field }: any) => (
                            <Checkbox
                              {...field}
                              checked={initialValues.noticeboardpage}
                              onChange={(e) => {
                                setInitialValues((prevValues) => ({
                                  ...prevValues,
                                  noticeboardpage: e.target.checked, // Set to the field value
                                }));
                              }}
                            >
                              Notice Board
                            </Checkbox>
                          )}
                        </Field>
                        <Field name="reportpage">
                          {({ field }: any) => (
                            <Checkbox
                              {...field}
                              checked={initialValues.reportpage}
                              onChange={(e) => {
                                setInitialValues((prevValues) => ({
                                  ...prevValues,
                                  reportpage: e.target.checked, // Set to the field value
                                }));
                              }}
                            >
                              Report
                            </Checkbox>
                          )}
                        </Field>
                        <Field name="einvoicepage">
                          {({ field }: any) => (
                            <Checkbox
                              {...field}
                              checked={initialValues.einvoicepage}
                              onChange={(e) => {
                                setInitialValues((prevValues) => ({
                                  ...prevValues,
                                  einvoicepage: e.target.checked, // Set to the field value
                                }));
                              }}
                            >
                              einvoice
                            </Checkbox>
                          )}
                        </Field>
                        <Field name="myinvoicepage">
                          {({ field }: any) => (
                            <Checkbox
                              {...field}
                              checked={initialValues.myinvoicepage}
                              onChange={(e) => {
                                setInitialValues((prevValues) => ({
                                  ...prevValues,
                                  myinvoicepage: e.target.checked, // Set to the field value
                                }));
                              }}
                            >
                              My Invoice
                            </Checkbox>
                          )}
                        </Field>
                      </div>
                    </Col>
                  </Row>

                  <OpeningHoursSettings
                    visible={isModalVisible}
                    onClose={() => setIsModalVisible(false)}
                    openingHours={openingHours}
                    onSave={handleOpeningHoursSave}
                  />

                  {/* <Modal
                  title={`Edit ${
                    currentDay.charAt(0).toUpperCase() + currentDay.slice(1)
                  } Hours`}
                  visible={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                >
                  <TimePicker.RangePicker
                    format="HH:mm"
                    onChange={handleTimeChange}
                    defaultValue={[
                      dayjs(openingHours[currentDay]?.split("-")[0], "HH:mm"),
                      dayjs(openingHours[currentDay]?.split("-")[1], "HH:mm"),
                    ]}
                  />
                </Modal> */}

                  {/* <Row style={{ marginTop: "24px", textAlign: "right" }}>
                  <Col span={24}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      style={{ width: "70px" }}
                    >
                      Save
                    </Button>
                  </Col>
                </Row> */}
                  <div className="save-section">
                    <Button
                      type="default"
                      onClick={() => navigate("/merchant/list")}
                      style={{ marginRight: 8 }}
                    >
                      Cancel
                    </Button>
                    <Button type="primary" htmlType="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Content>
        <GenerateAPKModel
          visible={isGenerateAPKModalVisible}
          setVisible={setIsGenerateAPKModalVisible}
          setIsGenerating={setIsGenerating}
          setApkStatus={setApkStatus}
        />
        {/* Sticky Save Section */}
      </Content>
    </>
  );
};

export default MerchantForm;
