import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  Row,
  Col,
  Typography,
  Card,
  Divider,
  Tag,
  Spin,
} from "antd";
import {
  CloseOutlined,
  DownOutlined,
  EditOutlined,
  RightOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { get, put } from "../../../services/Apiclient";
import AddAppointment from "./AddAppointment";
import { useCurrency } from "../../../contexts/CurrencyContext";

const OrderCustomerIcon = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_OrderCustomer.png`;
const memberInfoPrivilege = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_MemberInfoPrivilege.png`;
const memberInfoGiftCard = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_MemberInfoGiftCard.png`;
const MemberInfoPackage = `${process.env.PUBLIC_URL}/images/logo/Def_Icon_MemberInfoPackage.png`;

const { Text } = Typography;

interface AppointmentDetailsModalProps {
  visible: boolean;
  eventData: any;
  onCancel: () => void;
}

interface appointmentData {
  appointment_type: string;
  note: string;
  que1: string;
  que2: string;
  customers: any[];
}

const AppointmentDetailsModal: React.FC<AppointmentDetailsModalProps> = ({
  visible,
  onCancel,
  eventData,
}) => {
  const { currencySymbol } = useCurrency();
  const [customerAppointmentData, setCustomerAppointmentData] =
    useState<appointmentData | null>(null);
  const [loading, setLoading] = useState(false);

  const [isModalVisible, setisModalVisible] = useState(false);

  const [isCustomerPrivilegeVisible, setIsCustomerPrivilegeVisible] =
    useState(false);
  const [customerCount, setCustomerCount] = useState<any[]>([]);

  const getStatusColor = (statusName: string) => {
    let bookingStatusColorData: any = localStorage.getItem("colordata");
    const colorData: any = JSON.parse(bookingStatusColorData) || [];
    const normalizedStatusName = statusName.replace(/\s+/g, "").toLowerCase();

    const status = colorData.find((item: any) => {
      const normalizedItemName = item.name.replace(/\s+/g, "").toLowerCase();
      return normalizedItemName === normalizedStatusName;
    });

    return status ? status.color : "#ccc";
  };

  const updateStatusArrival = async () => {
    try {
      let payload = {
        status: "arrived",
      };
      await put(
        `/appointment/status/${eventData.id}/${eventData.staffid}/${eventData.date}/${eventData.time}`,
        payload
      );
      onCancel();
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  const updateStatusNoshow = async () => {
    try {
      let payload = {
        status: "noshow",
      };
      await put(
        `/appointment/status/${eventData.id}/${eventData.staffid}/${eventData.date}/${eventData.time}`,
        payload
      );
      onCancel();
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  const updateStatusCancel = async () => {
    try {
      let payload = {
        status: "cancel",
      };
      await put(
        `/appointment/status/${eventData.id}/${eventData.staffid}/${eventData.date}/${eventData.time}`,
        payload
      );
      onCancel();
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  const updateStatusPaid = async () => {
    try {
      let payload = {
        status: "paid",
      };
      await put(
        `/appointment/status/${eventData.id}/${eventData.staffid}/${eventData.date}/${eventData.time}`,
        payload
      );
      onCancel();
    } catch (error) {
      console.error("Error fetching billing data:", error);
    }
  };

  const showAppointmentData = async () => {
    try {
      setLoading(true);
      const response = await get(
        `/appointment/${eventData.id}/${eventData.staffid}/${eventData.date}/${eventData.time}`
      );
      if (response.data.success) {
        setCustomerAppointmentData(response.data.data);
        await fetchCoustomerCountData(
          customerAppointmentData?.customers[0].customerid
        );
      } else {
        console.error(
          "Failed to fetch merchant details:",
          response.data.message
        );
      }
    } catch (error) {
      console.error("Error fetching merchant details:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchCoustomerCountData = async (id: any) => {
    const outlateid = localStorage.getItem("outlet_id");
    // const id = selectedCustomer?.id;
    if (outlateid && id) {
      try {
        const url = `/customer/privilege/${outlateid}/${id}`;
        const response = await get(url);
        if (response.data.success) {
          const countData = response.data.data;
          await setCustomerCount(countData);
        } else {
          console.error("Failed to fetch customer count.");
        }
      } catch (error) {
        console.error("Error fetching customer count: ", error);
      }
    }
  };

  useEffect(() => {
    if (visible) {
      setIsCustomerPrivilegeVisible(false);
      setCustomerCount([]);
      showAppointmentData();
    }
  }, [visible]);

  const onEdit = () => {
    setisModalVisible(true);
  };

  const handleCustomerToggle = () => {
    // setIsCustomerPrivilegeVisible(!isCustomerPrivilegeVisible);
    setIsCustomerPrivilegeVisible((prevState) => {
      const newState = !prevState;
      // Call the API only when the toggle is opened (newState becomes true)
      return newState;
    });
  };

  const handleCancel = () => {
    setisModalVisible(false);
    onCancel();
  };
  return (
    <>
      <Modal
        open={visible}
        className="normal-blue-modal"
        onCancel={onCancel}
        footer={
          <Row justify="space-between" style={{ marginTop: "15px" }}>
            <div>
              <Button
                style={{
                  marginRight: "8px",
                  width: "max-content",
                  border: "1px solid #c2c4c3",
                }}
                onClick={updateStatusCancel}
                // disabled={
                //   customerAppointmentData?.customers[0].services[0].status !==
                //   "upcoming"
                // }
              >
                Cancel appointment
              </Button>
              <Button
                type="primary"
                style={{
                  background: "#ff9d43",
                  borderColor: "#ff9d43",
                  width: "max-content",
                }}
                onClick={onEdit}
                // disabled={
                //   customerAppointmentData?.customers[0].services[0].status !==
                //   "upcoming"
                // }
              >
                Edit
              </Button>
            </div>
            <div>
              <Button
                style={{
                  background: "#fff",
                  color: "#2e56f1",
                  border: "1px solid #2e56f1",
                  width: "max-content",
                  marginRight: "8px",
                }}
                onClick={updateStatusArrival}
                // disabled={
                //   customerAppointmentData?.customers[0].services[0].status !==
                //   "upcoming"
                // }
              >
                Arrived
              </Button>
              <Button
                style={{
                  background: "#fff",
                  border: "1px solid #2e56f1",
                  color: "#2e56f1",
                  width: "max-content",
                  marginRight: "8px",
                }}
                onClick={updateStatusNoshow}
                // disabled={
                //   customerAppointmentData?.customers[0].services[0].status !==
                //   "upcoming"
                // }
              >
                No show
              </Button>
              <Button
                type="primary"
                style={{
                  width: "max-content",
                  background: "#2e56f1",
                  color: "#fff",
                }}
                onClick={updateStatusPaid}
                // disabled={
                //   customerAppointmentData?.customers[0].services[0].status !==
                //     "upcoming" ||
                //   customerAppointmentData?.customers[0].services[0].status !==
                //     "arrived"
                // }
              >
                Checkout
              </Button>
            </div>
          </Row>
        }
        width={600}
        closeIcon={
          <CloseOutlined style={{ color: "#3f3f3f", fontSize: "16px" }} />
        }
        style={{ top: "10px" }}
        bodyStyle={{
          height: "calc(100vh - 180px)",
          overflow: "hidden",
          overflowY: "auto",
        }}
        centered
      >
        {loading ? (
          <div
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              minHeight: "200px", // optional: to ensure there's space
            }}
          >
            <Spin size="large" />
          </div>
        ) : (
          <>
            {/* Header Section */}
            <Row justify="space-between" align="middle">
              <Col>
                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Text style={{ fontWeight: "bold", fontSize: "16px" }}>
                    Appointment Details
                  </Text>
                  {customerAppointmentData?.customers[0].services[0].status ==
                    "upcoming" && (
                    <Tag
                      color={`${getStatusColor(
                        customerAppointmentData?.customers[0].services[0].status
                      )}`}
                      style={{
                        fontWeight: "bold",
                        color: `${getStatusColor(
                          customerAppointmentData?.customers[0].services[0]
                            .status
                        )}`,
                        background: "rgb(252,236,163, 0.3)",
                        border: `1px solid ${getStatusColor(
                          customerAppointmentData?.customers[0].services[0]
                            .status
                        )}`,
                        borderRadius: "20px",
                        textTransform: "capitalize",
                      }}
                    >
                      {customerAppointmentData?.customers[0].services[0].status}
                    </Tag>
                  )}
                  {customerAppointmentData?.customers[0].services[0].status ==
                    "noshow" && (
                    <Tag
                      color={`${getStatusColor(
                        customerAppointmentData?.customers[0].services[0].status
                      )}`}
                      style={{
                        fontWeight: "bold",
                        color: `${getStatusColor(
                          customerAppointmentData?.customers[0].services[0]
                            .status
                        )}`,
                        background: "rgb(252,236,163, 0.3)",
                        border: `1px solid ${getStatusColor(
                          customerAppointmentData?.customers[0].services[0]
                            .status
                        )}`,
                        borderRadius: "20px",
                        textTransform: "capitalize",
                      }}
                    >
                      {customerAppointmentData?.customers[0].services[0].status}
                    </Tag>
                  )}
                  {customerAppointmentData?.customers[0].services[0].status ==
                    "arrived" && (
                    <Tag
                      color={`${getStatusColor(
                        customerAppointmentData?.customers[0].services[0].status
                      )}`}
                      style={{
                        fontWeight: "bold",
                        color: `${getStatusColor(
                          customerAppointmentData?.customers[0].services[0]
                            .status
                        )}`,
                        background: "rgb(252,236,163, 0.3)",
                        border: `1px solid ${getStatusColor(
                          customerAppointmentData?.customers[0].services[0]
                            .status
                        )}`,
                        borderRadius: "20px",
                        textTransform: "capitalize",
                      }}
                    >
                      {customerAppointmentData?.customers[0].services[0].status}
                    </Tag>
                  )}
                  {customerAppointmentData?.customers[0].services[0].status ==
                    "paid" && (
                    <Tag
                      color={`${getStatusColor(
                        customerAppointmentData?.customers[0].services[0].status
                      )}`}
                      style={{
                        fontWeight: "bold",
                        color: `${getStatusColor(
                          customerAppointmentData?.customers[0].services[0]
                            .status
                        )}`,
                        background: "rgb(252,236,163, 0.3)",
                        border: `1px solid ${getStatusColor(
                          customerAppointmentData?.customers[0].services[0]
                            .status
                        )}`,
                        borderRadius: "20px",
                        textTransform: "capitalize",
                      }}
                    >
                      {customerAppointmentData?.customers[0].services[0].status}
                    </Tag>
                  )}
                  {customerAppointmentData?.customers[0].services[0].status ==
                    "cancel" && (
                    <Tag
                      color={`${getStatusColor(
                        customerAppointmentData?.customers[0].services[0].status
                      )}`}
                      style={{
                        fontWeight: "bold",
                        color: `${getStatusColor(
                          customerAppointmentData?.customers[0].services[0]
                            .status
                        )}`,
                        background: "rgb(252,236,163, 0.3)",
                        border: `1px solid ${getStatusColor(
                          customerAppointmentData?.customers[0].services[0]
                            .status
                        )}`,
                        borderRadius: "20px",
                        textTransform: "capitalize",
                      }}
                    >
                      {customerAppointmentData?.customers[0].services[0].status}
                    </Tag>
                  )}
                </div>
              </Col>
            </Row>
            <div
              style={{
                // background: `transparent url(${OrderCustomerIcon}) 50% / 100% 100% no-repeat`,
                backgroundImage:
                  "radial-gradient(circle, rgba(126,130,153,1) 0%, rgba(85,90,110,1) 100%)",
                color: "white",
                marginTop: "10px",
                borderRadius: "8px",
                padding: "10px",
                overflow: "hidden", // ✅ Required for radius effect!
                border: "1px solid rgba(255, 255, 255, 0.2)", // Optional
              }}
            >
              <Row gutter={[16, 16]}>
                <Col span={24} xs={24} lg={24} sm={24} md={24}>
                  <Text style={{ color: "#fff", display: "block" }}>
                    <strong>Name:</strong>{" "}
                    {customerAppointmentData?.customers[0].firstname}{" "}
                    {customerAppointmentData?.customers[0].lastname}
                  </Text>
                </Col>
                <Col span={12} xs={24} lg={12} sm={24} md={24}>
                  <Text style={{ color: "#fff", display: "block" }}>
                    <strong>Email:</strong>{" "}
                    {customerAppointmentData?.customers[0].email || "-"}
                  </Text>
                </Col>
                <Col span={12} xs={24} lg={12} sm={24} md={24}>
                  <Text style={{ color: "#fff", display: "block" }}>
                    <strong>Birthday:</strong>{" "}
                    {customerAppointmentData?.customers[0].dob || "-"}
                  </Text>
                </Col>
                <Col span={12} xs={24} lg={12} sm={24} md={24}>
                  <Text style={{ color: "#fff", display: "block" }}>
                    <strong>Phone:</strong> +
                    {customerAppointmentData?.customers[0].countrycode}
                    {customerAppointmentData?.customers[0].phonenumber}
                  </Text>
                </Col>
                <Col span={12} xs={24} lg={12} sm={24} md={24}>
                  <Text style={{ color: "#fff", display: "block" }}>
                    <strong>Last Arrival:</strong>{" "}
                    {customerAppointmentData?.customers[0].lastArrivedDate ||
                      "-"}
                  </Text>
                </Col>
              </Row>
              <div
                style={{
                  marginTop: "5px",
                  borderTop: ".5px solid hsla(0,0%,100%,.3)",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  cursor: "pointer",
                }}
                onClick={handleCustomerToggle}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "2px",
                    alignItems: "center",
                  }}
                >
                  <img
                    className=""
                    style={{
                      width: "25%",
                      height: "25%",
                      objectFit: "contain",
                    }}
                    src={memberInfoPrivilege}
                    alt="img"
                  ></img>
                  <div>
                    <p
                      style={{
                        fontWeight: 700,
                      }}
                    >
                      {!isCustomerPrivilegeVisible ? "Privilege" : "Membership"}
                    </p>
                  </div>
                </div>

                {!isCustomerPrivilegeVisible ? (
                  <DownOutlined
                    style={{
                      fontSize: "12px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  <UpOutlined
                    style={{
                      fontSize: "12px",
                      color: "#fff",
                      cursor: "pointer",
                    }}
                  />
                )}
              </div>
              {isCustomerPrivilegeVisible && (
                <>
                  {/* ------ Membership details ------ */}
                  <Row>
                    <Col span={8}>
                      <Text
                        style={{
                          fontSize: "14px",
                          color: "hsla(0,0%,100%,.65)",
                        }}
                      >
                        Stored / Bonus balance:
                      </Text>
                    </Col>
                    <Col span={8}>
                      <div
                        style={{
                          marginLeft: "10px",
                          display: "flex",
                          alignItems: "center",
                          color: "#c0c5cf",
                          gap: "2px",
                          cursor: "pointer",
                        }}
                        // onClick={handleCustomerPrepaidModal}
                      >
                        <Text
                          style={{
                            fontSize: "14px",
                            color: "#fff",
                            fontWeight: 600,
                          }}
                        >
                          {currencySymbol.CURRENCY_SYMBOL}
                          {customerCount?.find(
                            (item) => item.type === "Prepaid"
                          )?.remaining_balance
                            ? Number.isInteger(
                                customerCount.find(
                                  (item) => item.type === "Prepaid"
                                )?.remaining_balance
                              )
                              ? customerCount.find(
                                  (item) => item.type === "Prepaid"
                                )?.remaining_balance
                              : customerCount
                                  .find((item) => item.type === "Prepaid")
                                  ?.remaining_balance.toFixed(2)
                            : 0}
                          /{currencySymbol.CURRENCY_SYMBOL}
                          {customerCount?.find(
                            (item) => item.type === "Prepaid"
                          )?.remaining_bouns
                            ? Number.isInteger(
                                customerCount.find(
                                  (item) => item.type === "Prepaid"
                                )?.remaining_bouns
                              )
                              ? customerCount.find(
                                  (item) => item.type === "Prepaid"
                                )?.remaining_bouns
                              : customerCount
                                  .find((item) => item.type === "Prepaid")
                                  ?.remaining_bouns.toFixed(2)
                            : 0}
                        </Text>
                      </div>
                    </Col>
                  </Row>

                  <div
                    style={{
                      display: "flex",
                      marginTop: "5px",
                    }}
                  >
                    <Col span={8}>
                      <Text
                        style={{
                          fontSize: "14px",
                          color: "hsla(0,0%,100%,.65)",
                        }}
                      >
                        Visit-based:
                      </Text>
                    </Col>
                    <Col span={4}>
                      <div
                        style={{
                          marginLeft: "10px",
                          display: "flex",
                          alignItems: "center",
                          color: "#c0c5cf",
                          gap: "2px",
                          cursor: "pointer",
                        }}
                        // onClick={handleCustomerVistbasedModal}
                      >
                        <Text
                          style={{
                            fontSize: "14px",
                            color: "#fff",
                          }}
                        >
                          {customerCount?.find(
                            (item) => item.type === "Visit-based"
                          )?.total_count || 0}
                        </Text>
                      </div>
                    </Col>
                    <Col span={4}>
                      <Text
                        style={{
                          fontSize: "14px",
                          color: "hsla(0,0%,100%,.65)",
                        }}
                      >
                        Discount:
                      </Text>
                    </Col>
                    <Col span={4}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#c0c5cf",
                          gap: "2px",
                          cursor: "pointer",
                        }}
                        // onClick={handleCustomerDiscountModal}
                      >
                        <Text
                          style={{
                            fontSize: "14px",
                            color: "#fff",
                          }}
                        >
                          {customerCount?.find(
                            (item) => item.type === "Discount"
                          )?.total_count || 0}
                        </Text>
                      </div>
                    </Col>
                  </div>

                  {/* ------ Debt details ------ */}
                  <Row
                    gutter={16}
                    style={{
                      marginTop: "10px",
                      marginLeft: "1px",
                    }}
                  >
                    <Col span={8}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          className=""
                          style={{
                            width: "16px",
                            height: "16px",
                            objectFit: "contain",
                          }}
                          src={memberInfoGiftCard}
                          alt="img"
                        ></img>
                        <div>
                          <Text
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              color: "#fff",
                              fontWeight: "700",
                            }}
                          >
                            Gift Card
                          </Text>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#c0c5cf",
                          gap: "2px",
                          cursor: "pointer",
                        }}
                        // onClick={handleCustomerGiftCardModal}
                      >
                        <Text
                          style={{
                            fontSize: "14px",
                            color: "#fff",
                          }}
                        >
                          {/* {selectedCustomer?.all_giftcard} */}
                          {customerCount?.find(
                            (item) => item.type === "Giftcard"
                          )?.total_count || 0}
                        </Text>
                      </div>
                    </Col>
                  </Row>

                  {/* ------ Package details ------ */}
                  <Row
                    gutter={16}
                    style={{
                      marginLeft: "1px",
                    }}
                  >
                    <Col span={8}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          className=""
                          style={{
                            width: "16px",
                            height: "16px",
                            objectFit: "contain",
                          }}
                          src={MemberInfoPackage}
                          alt="img"
                        ></img>
                        <div>
                          <Text
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              color: "#fff",
                              fontWeight: "700",
                            }}
                          >
                            Package
                          </Text>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#c0c5cf",
                          gap: "2px",
                          cursor: "pointer",
                        }}
                        // onClick={handleCustomerPackageModal}
                      >
                        <Text
                          style={{
                            fontSize: "14px",
                            color: "#fff",
                          }}
                        >
                          {customerCount?.find(
                            (item) => item.type === "Package"
                          )?.total_count || 0}
                        </Text>
                      </div>
                    </Col>
                  </Row>
                  {/* ------ Gift card details ------ */}
                  <Row
                    gutter={16}
                    style={{
                      marginLeft: "1px",
                    }}
                  >
                    <Col span={8}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          className=""
                          style={{
                            width: "16px",
                            height: "16px",
                            objectFit: "contain",
                          }}
                          src={memberInfoGiftCard}
                          alt="img"
                        ></img>
                        <div>
                          <Text
                            style={{
                              marginLeft: "10px",
                              fontSize: "14px",
                              color: "#fff",
                              fontWeight: "700",
                            }}
                          >
                            Due Balance
                          </Text>
                        </div>
                      </div>
                    </Col>
                    <Col span={8}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          color: "#c0c5cf",
                          gap: "2px",
                          cursor: "pointer",
                        }}
                        // onClick={handleCustomerDebtModal}
                      >
                        <Text
                          style={{
                            fontSize: "14px",
                            color: "#fff",
                          }}
                        >
                          {/* {selectedCustomer?.all_giftcard} */}
                          {customerCount?.find((item) => item.type === "Debt")
                            ?.total_count
                            ? Number.isInteger(
                                customerCount.find(
                                  (item) => item.type === "Debt"
                                )?.total_count
                              )
                              ? customerCount.find(
                                  (item) => item.type === "Debt"
                                )?.total_count
                              : parseFloat(
                                  customerCount.find(
                                    (item) => item.type === "Debt"
                                  )?.total_count
                                ).toFixed(2)
                            : 0}
                        </Text>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </div>

            {/* Service Section */}
            {customerAppointmentData?.customers[0].services.length > 0 && (
              <Row justify="space-between" style={{ marginTop: "10px" }}>
                <Col>
                  <Text style={{ fontSize: "16px", fontWeight: "bold" }}>
                    Service
                  </Text>
                </Col>
              </Row>
            )}

            {customerAppointmentData?.customers[0].services &&
              customerAppointmentData?.customers[0].services.length > 0 &&
              customerAppointmentData?.customers[0].services.map(
                (item: any, index: any) => (
                  <>
                    <div
                      style={{
                        background: "#f5f6fa",
                        marginTop: "10px",
                        padding: "10px",
                      }}
                    >
                      <Row gutter={16}>
                        <Col span={24}>
                          <Text strong>{item.servicename}</Text>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Text>{item.time}</Text>
                        </Col>
                        <Col span={4}>
                          <Text>{item.duration}min</Text>
                        </Col>
                        <Col span={3}>
                          <Text>{item.staffname}</Text>
                        </Col>
                      </Row>
                    </div>

                    <Row
                      style={{
                        background: "#f5f6fa",
                        marginTop: "10px",
                        padding: "10px",
                      }}
                    >
                      <Text strong>Note:</Text>
                      <Text style={{ marginLeft: "8px", color: "#888" }}>
                        {customerAppointmentData.note
                          ? customerAppointmentData.note
                          : "-"}
                      </Text>
                    </Row>
                  </>
                )
              )}

            {/* Form Section */}
            {(customerAppointmentData?.que1 ||
              customerAppointmentData?.que2) && (
              <Row style={{ marginTop: "10px" }}>
                <Text strong>Form</Text>
              </Row>
            )}
            {customerAppointmentData?.que1 && (
              <Row
                justify="space-between"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f5f6fa",
                  marginTop: "10px",
                }}
              >
                <Col span={24}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Text>Allergies Investigation</Text>
                    <RightOutlined />
                  </div>
                </Col>
              </Row>
            )}
            {customerAppointmentData?.que2 && (
              <Row
                justify="space-between"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f5f6fa",
                  marginTop: "10px",
                }}
              >
                <Col span={24}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Text>Preparations</Text>
                    <RightOutlined />
                  </div>
                </Col>
              </Row>
            )}
          </>
        )}
      </Modal>
      <AddAppointment
        visible={isModalVisible}
        onCancel={handleCancel}
        appintmentdata={eventData}
      />
    </>
  );
};

export default AppointmentDetailsModal;
