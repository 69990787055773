import {
  CalendarOutlined,
  CloseOutlined,
  FileTextOutlined,
  FormOutlined,
  RightOutlined,
} from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Flex,
  Form,
  Input,
  Radio,
  Row,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { get, post } from "../../services/Apiclient";
import Header from "../headerFooter/Header";
import Footer from "../headerFooter/footer";

const { Text } = Typography;
const defaultStoreImage = `${process.env.PUBLIC_URL}/images/logo/Def_Bc_Store.png`;

const imgStyle: React.CSSProperties = {
  display: "block",
  width: "70px",
  height: "70px",
  borderRadius: "30px",
};

const formItemStyle: React.CSSProperties = {
  marginBottom: "16px",
};

interface Profile {
  id: string;
  firstname: string;
  lastname: string;
  phonenumber: string;
  email: string;
  gender: string;
  dob: string;
  country: string;
  customerimage: string;
  countrycode: string;
  imagename: string;
}

interface Customer {
  id: string;
  name: string;
  phonenumber?: string;
  countrycode?: string;
}

interface Booking {
  customerId: string;
  customerName: string;
  selectedService?: {
    id: string;
    name: string;
    duration: string;
    sellprice: string;
  };
  selectedStaff?: {
    id: string;
    name: string;
    lastname: string;
    department_name: string;
  };
  selectedDate?: string;
  selectedTime?: string; // Stores "09:30am - 10:00am"
  selectedOutlet?: string;
}

const AppBooking: React.FC = () => {
  const navigate = useNavigate();
  const { merchantId } = useParams();
  const outletId = localStorage.getItem("outlet_id");
  const selectedOutletId = localStorage.getItem("selectedOutletId");
  const customerId = localStorage.getItem("customer_id");
  const location = useLocation();
  const [allergiesData, setAllergiesData] = useState<
    { faceType?: string; allergyDetails?: string } | undefined
  >(location.state?.allergiesData);

  const [quantity, setQuantity] = useState<number>(
    JSON.parse(localStorage.getItem("bookingQuantity") || "1")
  );
  const [validityType, setValidityType] = useState<"custom" | "group">(
    (localStorage.getItem("bookingValidityType") as "custom" | "group") ||
      "custom"
  );
  const [selectedCustomers, setSelectedCustomers] = useState<Customer[]>([]);
  const [profileDetails, setProfileDetails] = useState<Profile | null>(null);
  const [form] = Form.useForm();
  const [selectedBookings, setSelectedBookings] = useState<Booking[]>(
    JSON.parse(localStorage.getItem("bookingSelectedBookings") || "[]")
  );
  // const [isReschedule, setIsReschedule] = useState<boolean>(false); // Flag for reschedule mode
  // const [appointmentId, setAppointmentId] = useState<string | null>(null); // Store appointment ID for rescheduling

  // Fetch profile details
  const fetchProfile = async () => {
    if (!merchantId || !outletId) return;
    try {
      const response = await get(
        `/customerprofilemob/mobile/customer/${customerId}/${outletId}/${merchantId}`
      );
      if (response?.data?.success && response.data.data) {
        setProfileDetails(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching profile details:", error);
    }
  };

  useEffect(() => {
    fetchProfile();
  }, []);

  // Check if this is a reschedule operation
  // useEffect(() => {
  //   if (location.state?.appointmentId) {
  //     setIsReschedule(true);
  //     setAppointmentId(location.state.appointmentId);
  //     if (location.state.selectedBookings) {
  //       setSelectedBookings(location.state.selectedBookings);
  //       localStorage.setItem(
  //         "bookingSelectedBookings",
  //         JSON.stringify(location.state.selectedBookings)
  //       );
  //     }
  //     if (location.state.allergiesData) {
  //       setAllergiesData(location.state.allergiesData);
  //     }
  //   }
  // }, [location.state]);

  // Initialize customers and handle profile load
  useEffect(() => {
    if (profileDetails) {
      form.setFieldsValue({
        mobile: `${profileDetails?.countrycode}${profileDetails?.phonenumber}`,
        email: profileDetails?.email,
        name: profileDetails?.firstname,
      });
      const initialCustomers = Array.from({ length: quantity }, (_, i) => ({
        id: customerId!,
        name: `Customer ${i + 1}`,
        phonenumber: i === 0 ? profileDetails.phonenumber : undefined,
        countrycode: i === 0 ? profileDetails.countrycode : undefined,
      }));
      setSelectedCustomers(initialCustomers);
      localStorage.setItem(
        "bookingSelectedCustomers",
        JSON.stringify(initialCustomers)
      );
    }
  }, [profileDetails, form, quantity]);

  // Handle navigation state and booking updates
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (location.state) {
      if (location.state.validityType) {
        setValidityType(location.state.validityType);
        localStorage.setItem(
          "bookingValidityType",
          location.state.validityType
        );
      }
      if (location.state.selectedBookings) {
        const newBookings = location.state.selectedBookings;
        setSelectedBookings(newBookings);
        localStorage.setItem(
          "bookingSelectedBookings",
          JSON.stringify(newBookings)
        );
        console.log(
          "Updated selectedBookings from location.state:",
          newBookings
        );
      }
      if (location.state.allergiesData) {
        setAllergiesData(location.state.allergiesData);
        console.log(
          "Updated allergiesData from location.state:",
          location.state.allergiesData
        );
      }
    }
  }, [location.state]);

  // Update appointment for rescheduling
  // const updateAppointment = async () => {
  //   if (!validateCustomersHaveServices()) {
  //     return;
  //   }

  //   if (!merchantId || !outletId || !customerId || !appointmentId) return;

  //   const customersWithBookings = selectedCustomers.filter((customer) =>
  //     selectedBookings.some(
  //       (b) => b.customerId === customer.id && b.customerName === customer.name
  //     )
  //   );

  //   const effectiveAppointmentType =
  //     validityType === "group" && customersWithBookings.length > 1
  //       ? "group appointment"
  //       : "individual appointment";

  //   const appointmentPayload = {
  //     date: null,
  //     time: null,
  //     que1: allergiesData?.faceType ?? null,
  //     que2: allergiesData?.allergyDetails ?? null,
  //     note: null,
  //     appointment_type: effectiveAppointmentType,
  //     outlet_id: selectedOutletId,
  //     Customers: customersWithBookings.map((customer) => ({
  //       customerid: customer.id,
  //       services: selectedBookings
  //         .filter(
  //           (b) =>
  //             b.customerId === customer.id && b.customerName === customer.name
  //         )
  //         .map((booking) => {
  //           const startTime = booking.selectedTime?.split(" - ")[0];
  //           return {
  //             serviceid: booking.selectedService?.id,
  //             staffid: booking.selectedStaff?.id,
  //             duration: booking.selectedService?.duration,
  //             time: `${booking.selectedDate} ${startTime}`,
  //           };
  //         }),
  //     })),
  //   };

  //   try {
  //     // const response = await put(
  //     //   `/appointmentmob/update/${appointmentId}/${merchantId}`,
  //     //   appointmentPayload
  //     // );
  //     // if (response?.data?.success) {
  //     //   localStorage.removeItem("bookingQuantity");
  //     //   localStorage.removeItem("bookingValidityType");
  //     //   localStorage.removeItem("bookingSelectedCustomers");
  //     //   localStorage.removeItem("bookingSelectedBookings");
  //     //   localStorage.removeItem("selectedOutletId");
  //     //   localStorage.removeItem("selectedOutlet");
  //     //   navigate(`/app/appointmentsuccess/${merchantId}`);
  //     // }
  //     console.log("Updated appointment:", appointmentPayload);
  //   } catch (error) {
  //     console.error("Error updating appointment:", error);
  //     message.error("Failed to reschedule appointment");
  //   }
  // };

  // // Add or update appointment based on reschedule flag
  // const handleBooking = async () => {
  //   if (isReschedule) {
  //     await updateAppointment();
  //   } else {
  //     await addAppointment();
  //   }
  // };

  // Handlers for quantity
  const handleDecrementQuantity = () => {
    if (quantity > 1) {
      setQuantity((prev) => prev - 1);
      setSelectedCustomers((prev) => {
        const newCustomers = prev.slice(0, prev.length - 1);
        localStorage.setItem(
          "bookingSelectedCustomers",
          JSON.stringify(newCustomers)
        );
        return newCustomers;
      });
      setSelectedBookings((prev) => {
        const lastCustomerName = `Customer ${quantity}`;
        const updatedBookings = prev.filter(
          (b) => b.customerName !== lastCustomerName
        );
        localStorage.setItem(
          "bookingSelectedBookings",
          JSON.stringify(updatedBookings)
        );
        return updatedBookings;
      });
      localStorage.setItem("bookingQuantity", JSON.stringify(quantity - 1));
    }
  };

  const handleIncrementQuantity = () => {
    if (quantity) {
      setQuantity((prev) => prev + 1);
      setSelectedCustomers((prev) => {
        const newCustomerName = `Customer ${prev.length + 1}`;
        const newCustomers = [
          ...prev,
          { id: customerId!, name: newCustomerName },
        ];
        localStorage.setItem(
          "bookingSelectedCustomers",
          JSON.stringify(newCustomers)
        );
        return newCustomers;
      });
      localStorage.setItem("bookingQuantity", JSON.stringify(quantity + 1));
    }
  };

  // Remove booking details
  const removeBookingDetails = (
    customerId: string,
    customerName: string,
    bookingIndex: number
  ) => {
    setSelectedBookings((prev) => {
      const customerBookings = prev.filter(
        (b) => b.customerId === customerId && b.customerName === customerName
      );
      customerBookings.splice(bookingIndex, 1);
      const updatedBookings = [
        ...prev.filter(
          (b) =>
            !(b.customerId === customerId && b.customerName === customerName)
        ),
        ...customerBookings,
      ];
      localStorage.setItem(
        "bookingSelectedBookings",
        JSON.stringify(updatedBookings)
      );
      console.log("After remove:", updatedBookings);
      return updatedBookings;
    });
  };

  // Add another service
  const handleAddService = (customerId: string, customerName: string) => {
    navigate(`/app/servicestaff/list/${merchantId}`, {
      state: {
        selectedBookings,
        selectedCustomers,
        validityType,
        customerId,
        customerName,
        allergiesData,
      },
    });
  };

  // Redirect to edit booking details
  const editBookingDetails = (
    customerId: string,
    customerName: string,
    booking: Booking,
    index: number
  ) => {
    navigate(`/app/servicestaff/list/${merchantId}`, {
      state: {
        selectedBookings,
        selectedCustomers,
        validityType,
        customerId,
        customerName,
        selectedService: booking.selectedService,
        selectedStaff: booking.selectedStaff,
        selectedDate: booking.selectedDate,
        selectedTime: booking.selectedTime,
        selectedOutlet: booking.selectedOutlet,
        bookingIndex: index,
        allergiesData,
      },
    });
  };

  // Handle validity type change
  const handleValidityTypeChange = (newValidityType: "custom" | "group") => {
    setValidityType(newValidityType);
    localStorage.setItem("bookingValidityType", newValidityType);
    if (newValidityType === "custom") {
      const loggedInCustomer = selectedCustomers.find(
        (c) => c.id === customerId && c.name === "Customer 1"
      );
      const updatedCustomers = loggedInCustomer
        ? [loggedInCustomer]
        : [{ id: customerId!, name: "Customer 1" }];
      setSelectedCustomers(updatedCustomers);
      const updatedBookings = selectedBookings.filter(
        (b) => b.customerId === customerId && b.customerName === "Customer 1"
      );
      setSelectedBookings(updatedBookings);
      setQuantity(1);
      localStorage.setItem(
        "bookingSelectedCustomers",
        JSON.stringify(updatedCustomers)
      );
      localStorage.setItem(
        "bookingSelectedBookings",
        JSON.stringify(updatedBookings)
      );
      localStorage.setItem("bookingQuantity", JSON.stringify(1));
    } else {
      const initialCustomers = Array.from({ length: quantity }, (_, i) => ({
        id: customerId!,
        name: `Customer ${i + 1}`,
        phonenumber:
          i === 0 && profileDetails ? profileDetails.phonenumber : undefined,
        countrycode:
          i === 0 && profileDetails ? profileDetails.countrycode : undefined,
      }));
      setSelectedCustomers(initialCustomers);
      setSelectedBookings((prev) =>
        prev.filter((b) => b.customerName === "Customer 1")
      );
      localStorage.setItem(
        "bookingSelectedCustomers",
        JSON.stringify(initialCustomers)
      );
    }
  };

  // Validate customers have services before booking
  const validateCustomersHaveServices = () => {
    if (validityType !== "group") {
      return true;
    }

    const customersWithoutServices = selectedCustomers.filter(
      (customer) =>
        !selectedBookings.some(
          (b) =>
            b.customerId === customer.id && b.customerName === customer.name
        )
    );

    if (customersWithoutServices.length > 0) {
      const customerNames = customersWithoutServices
        .map((c) => c.name)
        .join(", ");
      message.error(`Please add at least one service for: ${customerNames}`);
      return false;
    }
    return true;
  };

  // Add appointment
  const addAppointment = async () => {
    if (!validateCustomersHaveServices()) {
      return;
    }

    if (!merchantId || !outletId || !customerId) return;

    const customersWithBookings = selectedCustomers.filter((customer) =>
      selectedBookings.some(
        (b) => b.customerId === customer.id && b.customerName === customer.name
      )
    );

    const effectiveAppointmentType =
      validityType === "group" && customersWithBookings.length > 1
        ? "group appointment"
        : "individual appointment";

    const appointmentPayload = {
      date: null,
      time: null,
      que1: allergiesData?.faceType ?? null,
      que2: allergiesData?.allergyDetails ?? null,
      note: null,
      appointment_type: effectiveAppointmentType,
      outlet_id: selectedOutletId,
      Customers: customersWithBookings.map((customer) => ({
        customerid: customer.id,
        services: selectedBookings
          .filter(
            (b) =>
              b.customerId === customer.id && b.customerName === customer.name
          )
          .map((booking) => {
            const startTime = booking.selectedTime?.split(" - ")[0]; // Extract only the start time
            return {
              serviceid: booking.selectedService?.id,
              staffid: booking.selectedStaff?.id,
              duration: booking.selectedService?.duration,
              time: `${booking.selectedDate} ${startTime}`, // Use only start time
            };
          }),
      })),
    };

    try {
      const response = await post(
        `/appointmentmob/add/${merchantId}`,
        appointmentPayload
      );
      if (response?.data?.success) {
        localStorage.removeItem("bookingQuantity");
        localStorage.removeItem("bookingValidityType");
        localStorage.removeItem("bookingSelectedCustomers");
        localStorage.removeItem("bookingSelectedBookings");
        localStorage.removeItem("selectedOutletId");
        localStorage.removeItem("selectedOutlet");
        navigate(`/app/appointmentsuccess/${merchantId}`);
      }
      console.log("book", appointmentPayload);
    } catch (error) {
      console.error("Error adding appointment:", error);
    }
  };

  return (
    <>
      <div style={{ minHeight: "100vh", backgroundColor: "#fff" }}>
        <Header
          title="Booking"
          onBack={() => navigate(`/app/servicestaff/list/${merchantId}`)}
          backgroundColor={"#fff"}
        />
        <div
          style={{ padding: "16px", paddingTop: "50px", paddingBottom: "80px" }}
        >
          <Form form={form} layout="vertical">
            <Typography.Title level={5}>Contact info.</Typography.Title>
            <Form.Item name="mobile" style={formItemStyle}>
              <PhoneInput
                country={"my"}
                enableSearch
                value={`${profileDetails?.countrycode}${profileDetails?.phonenumber}`}
                countryCodeEditable={false}
                disabled={true}
                inputStyle={{
                  width: "100%",
                  height: "46px",
                  paddingLeft: "35px",
                  borderColor: "#e0e0e0",
                  borderRadius: "5px",
                  color: "#5c5c5c",
                  backgroundColor: "#f8f9fd",
                }}
                containerStyle={{ width: "100%" }}
                placeholder="Mobile Number"
              />
            </Form.Item>
            <Form.Item name="email" style={formItemStyle}>
              <Input
                placeholder="Email address"
                value={profileDetails?.email}
                disabled={true}
              />
            </Form.Item>
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please enter your name" }]}
              style={formItemStyle}
            >
              <Input
                placeholder="Name (Required)"
                value={profileDetails?.firstname}
                disabled={true}
              />
            </Form.Item>
            <Form.Item name="validityType" initialValue={validityType}>
              <Radio.Group
                onChange={(e) => handleValidityTypeChange(e.target.value)}
                value={validityType}
              >
                <Radio
                  style={{
                    fontSize: "16px",
                    marginBottom: "10px",
                    display: "block",
                  }}
                  value="custom"
                >
                  Individual appointment
                </Radio>
                <Radio value="group" style={{ width: "100%" }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      gap: "20px",
                      width: "100%",
                      fontSize: "16px",
                    }}
                  >
                    Group appointment
                    {validityType === "group" && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Button
                          onClick={handleDecrementQuantity}
                          style={{ width: "32px", height: "32px" }}
                          disabled={quantity <= 1}
                        >
                          -
                        </Button>
                        <Text>{quantity}</Text>
                        <Button
                          onClick={handleIncrementQuantity}
                          style={{ width: "32px", height: "32px" }}
                        >
                          +
                        </Button>
                      </div>
                    )}
                  </div>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>

          {validityType === "group" && (
            <>
              {selectedCustomers.map((customer) => (
                <div key={customer.name}>
                  <Typography.Title level={5}>{customer.name}</Typography.Title>
                  {selectedBookings
                    .filter(
                      (b) =>
                        b.customerId === customer.id &&
                        b.customerName === customer.name
                    )
                    .map((booking, index) => (
                      <Row
                        key={index}
                        style={{
                          padding: "5px",
                          marginBottom: "14px",
                          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                      >
                        <Col style={{ padding: "12px" }} span={24}>
                          <Flex align="center" justify="space-between">
                            <Col
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <img
                                alt="avatar"
                                src={defaultStoreImage}
                                style={imgStyle}
                              />
                              <div style={{ flexGrow: 1 }}>
                                <Text
                                  style={{ fontWeight: 500, display: "block" }}
                                >
                                  {booking.selectedOutlet ||
                                    "No outlet selected"}
                                </Text>
                              </div>
                            </Col>
                            <CloseOutlined
                              onClick={() =>
                                removeBookingDetails(
                                  customer.id,
                                  customer.name,
                                  index
                                )
                              }
                            />
                          </Flex>
                        </Col>
                        {booking.selectedStaff && (
                          <>
                            <Divider style={{ margin: 0 }} />
                            <Col
                              style={{ padding: "12px", cursor: "pointer" }}
                              span={24}
                              onClick={(e) => {
                                e.stopPropagation();
                                editBookingDetails(
                                  customer.id,
                                  customer.name,
                                  booking,
                                  index
                                );
                              }}
                            >
                              <Flex align="center" justify="space-between">
                                <Col
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <img
                                    alt="avatar"
                                    src={defaultStoreImage}
                                    style={imgStyle}
                                  />
                                  <div style={{ flexGrow: 1 }}>
                                    <Text
                                      style={{
                                        fontWeight: 500,
                                        display: "block",
                                      }}
                                    >
                                      {booking.selectedStaff.name}{" "}
                                      {booking.selectedStaff.lastname}
                                    </Text>
                                    <Text style={{ display: "block" }}>
                                      {booking.selectedStaff.department_name}
                                    </Text>
                                  </div>
                                </Col>
                                <RightOutlined />
                              </Flex>
                            </Col>
                          </>
                        )}
                        {booking.selectedService && (
                          <>
                            <Divider style={{ margin: 0 }} />
                            <Col style={{ padding: "12px" }} span={24}>
                              <Flex align="center" justify="space-between">
                                <Col
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <img
                                    alt="avatar"
                                    src={defaultStoreImage}
                                    style={imgStyle}
                                  />
                                  <div style={{ flexGrow: 1 }}>
                                    <Text
                                      style={{
                                        fontWeight: 500,
                                        display: "block",
                                      }}
                                    >
                                      {booking.selectedService.name}
                                    </Text>
                                    <div style={{ display: "block" }}>
                                      <Text
                                        style={{
                                          color: "orange",
                                          fontWeight: 500,
                                        }}
                                      >
                                        RM {booking.selectedService.sellprice}{" "}
                                      </Text>
                                      <Text style={{ fontSize: "12px" }}>
                                        {booking.selectedService.duration}min
                                      </Text>
                                    </div>
                                  </div>
                                </Col>
                              </Flex>
                            </Col>
                          </>
                        )}
                        {booking.selectedDate && booking.selectedTime && (
                          <>
                            <Divider style={{ margin: 0 }} />
                            <Col
                              style={{ padding: "12px", cursor: "pointer" }}
                              span={24}
                              onClick={(e) => {
                                e.stopPropagation();
                                editBookingDetails(
                                  customer.id,
                                  customer.name,
                                  booking,
                                  index
                                );
                              }}
                            >
                              <Flex align="center" justify="space-between">
                                <Col
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "10px",
                                  }}
                                >
                                  <CalendarOutlined
                                    style={{
                                      fontSize: "70px",
                                      display: "block",
                                      borderRadius: "30px",
                                    }}
                                  />
                                  <div style={{ flexGrow: 1 }}>
                                    <Text
                                      style={{
                                        fontWeight: 500,
                                        display: "block",
                                      }}
                                    >
                                      {booking.selectedDate}
                                    </Text>
                                    <Text
                                      style={{
                                        color: "orange",
                                        fontWeight: 500,
                                      }}
                                    >
                                      {booking.selectedTime}{" "}
                                      {/* Displays "09:30am - 10:00am" */}
                                    </Text>
                                  </div>
                                </Col>
                                <RightOutlined />
                              </Flex>
                            </Col>
                          </>
                        )}
                      </Row>
                    ))}
                  <Text
                    onClick={() => handleAddService(customer.id, customer.name)}
                    style={{ color: "orange" }}
                  >
                    + Add another service
                  </Text>
                </div>
              ))}
            </>
          )}

          {validityType === "custom" && (
            <>
              <Typography.Title style={{ fontWeight: "bold" }} level={4}>
                Service
              </Typography.Title>
              <Typography.Title level={5}>Customer 1</Typography.Title>
              {selectedBookings
                .filter(
                  (b) =>
                    b.customerId === customerId &&
                    b.customerName === "Customer 1"
                )
                .map((booking, index) => (
                  <Row
                    key={index}
                    style={{
                      padding: "5px",
                      marginBottom: "14px",
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      borderRadius: "8px",
                      backgroundColor: "#f0f0f0",
                      cursor: "pointer",
                    }}
                  >
                    <Col style={{ padding: "12px" }} span={24}>
                      <Flex align="center" justify="space-between">
                        <Col
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <img
                            alt="avatar"
                            src={defaultStoreImage}
                            style={imgStyle}
                          />
                          <div style={{ flexGrow: 1 }}>
                            <Text style={{ fontWeight: 500, display: "block" }}>
                              {booking.selectedOutlet || "No outlet selected"}
                            </Text>
                          </div>
                        </Col>
                        <CloseOutlined
                          onClick={() =>
                            removeBookingDetails(
                              customerId!,
                              "Customer 1",
                              index
                            )
                          }
                        />
                      </Flex>
                    </Col>
                    {booking.selectedStaff && (
                      <>
                        <Divider style={{ margin: 0 }} />
                        <Col
                          style={{ padding: "12px", cursor: "pointer" }}
                          span={24}
                          onClick={(e) => {
                            e.stopPropagation();
                            editBookingDetails(
                              customerId!,
                              "Customer 1",
                              booking,
                              index
                            );
                          }}
                        >
                          <Flex align="center" justify="space-between">
                            <Col
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <img
                                alt="avatar"
                                src={defaultStoreImage}
                                style={imgStyle}
                              />
                              <div style={{ flexGrow: 1 }}>
                                <Text
                                  style={{ fontWeight: 500, display: "block" }}
                                >
                                  {booking.selectedStaff.name}{" "}
                                  {booking.selectedStaff.lastname}
                                </Text>
                                <Text style={{ display: "block" }}>
                                  {booking.selectedStaff.department_name}
                                </Text>
                              </div>
                            </Col>
                            <RightOutlined />
                          </Flex>
                        </Col>
                      </>
                    )}
                    {booking.selectedService && (
                      <>
                        <Divider style={{ margin: 0 }} />
                        <Col style={{ padding: "12px" }} span={24}>
                          <Flex align="center" justify="space-between">
                            <Col
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <img
                                alt="avatar"
                                src={defaultStoreImage}
                                style={imgStyle}
                              />
                              <div style={{ flexGrow: 1 }}>
                                <Text
                                  style={{ fontWeight: 500, display: "block" }}
                                >
                                  {booking.selectedService.name}
                                </Text>
                                <div style={{ display: "block" }}>
                                  <Text
                                    style={{ color: "orange", fontWeight: 500 }}
                                  >
                                    RM {booking.selectedService.sellprice}{" "}
                                  </Text>
                                  <Text style={{ fontSize: "12px" }}>
                                    {booking.selectedService.duration}min
                                  </Text>
                                </div>
                              </div>
                            </Col>
                          </Flex>
                        </Col>
                      </>
                    )}
                    {booking.selectedDate && booking.selectedTime && (
                      <>
                        <Divider style={{ margin: 0 }} />
                        <Col
                          style={{ padding: "12px", cursor: "pointer" }}
                          span={24}
                          onClick={(e) => {
                            e.stopPropagation();
                            editBookingDetails(
                              customerId!,
                              "Customer 1",
                              booking,
                              index
                            );
                          }}
                        >
                          <Flex align="center" justify="space-between">
                            <Col
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "10px",
                              }}
                            >
                              <CalendarOutlined
                                style={{
                                  fontSize: "70px",
                                  display: "block",
                                  borderRadius: "30px",
                                }}
                              />
                              <div style={{ flexGrow: 1 }}>
                                <Text
                                  style={{ fontWeight: 500, display: "block" }}
                                >
                                  {booking.selectedDate}
                                </Text>
                                <Text
                                  style={{ color: "orange", fontWeight: 500 }}
                                >
                                  {booking.selectedTime}{" "}
                                  {/* Displays "09:30am - 10:00am" */}
                                </Text>
                              </div>
                            </Col>
                            <RightOutlined />
                          </Flex>
                        </Col>
                      </>
                    )}
                  </Row>
                ))}
              <Text
                onClick={() => handleAddService(customerId!, "Customer 1")}
                style={{ color: "orange" }}
              >
                + Add another service
              </Text>
            </>
          )}

          <div>
            <Typography.Title level={5}>Forms required</Typography.Title>
            <Row
              style={{
                padding: "5px",
                marginBottom: "14px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                cursor: "pointer",
                backgroundColor: "#f0f0f0",
              }}
              onClick={() =>
                navigate(`/app/allergies/${merchantId}`, {
                  state: {
                    allergiesData,
                    selectedBookings,
                    selectedCustomers,
                    validityType,
                  },
                })
              }
            >
              <Col style={{ padding: "8px" }} span={24}>
                <Flex align="center" justify="space-between">
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FileTextOutlined
                      style={{
                        fontSize: "40px",
                        display: "block",
                        borderRadius: "30px",
                      }}
                    />
                    <div style={{ flexGrow: 1 }}>
                      <Text
                        style={{
                          fontWeight: 500,
                          display: "block",
                          marginLeft: "10px",
                        }}
                      >
                        Allergies Investigation
                      </Text>
                      <Text
                        style={{
                          color: "gray",
                          display: "block",
                          marginLeft: "10px",
                        }}
                      >
                        {allergiesData ? "Completed" : "To be Completed"}
                      </Text>
                    </div>
                  </Col>
                  <RightOutlined />
                </Flex>
              </Col>
            </Row>
            <Row
              style={{
                padding: "5px",
                marginBottom: "14px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                backgroundColor: "#f0f0f0",
              }}
            >
              <Col style={{ padding: "8px" }} span={24}>
                <Flex align="center" justify="space-between">
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FileTextOutlined
                      style={{
                        fontSize: "40px",
                        display: "block",
                        borderRadius: "30px",
                      }}
                    />
                    <div style={{ flexGrow: 1 }}>
                      <Text
                        style={{
                          fontWeight: 500,
                          display: "block",
                          marginLeft: "10px",
                        }}
                      >
                        Preparations
                      </Text>
                      <Text
                        style={{
                          color: "gray",
                          display: "block",
                          marginLeft: "10px",
                        }}
                      >
                        To be Complete
                      </Text>
                    </div>
                  </Col>
                  <RightOutlined />
                </Flex>
              </Col>
            </Row>
            <Divider />
            <Row
              style={{
                padding: "5px",
                marginBottom: "14px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                borderRadius: "8px",
                backgroundColor: "#f0f0f0",
              }}
            >
              <Col style={{ padding: "12px" }} span={24}>
                <Flex align="center" justify="space-between">
                  <Col
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <FormOutlined
                      style={{
                        fontSize: "40px",
                        display: "block",
                        borderRadius: "30px",
                      }}
                    />
                    <div style={{ flexGrow: 1 }}>
                      <Text
                        style={{
                          fontWeight: 500,
                          display: "block",
                          marginLeft: "10px",
                        }}
                      >
                        Leave a note (optional)
                      </Text>
                    </div>
                  </Col>
                  <RightOutlined />
                </Flex>
              </Col>
            </Row>
          </div>
          {selectedBookings.length > 0 && (
            <Button
              type="primary"
              size="large"
              block
              style={{
                backgroundColor: "orange",
                color: "white",
                fontSize: "16px",
                borderRadius: "8px",
                width: "100%",
              }}
              onClick={addAppointment}
              // onClick={handleBooking}
            >
              {/* {isReschedule ? "Update Booking" : "Book Now"} */}
              Book now
            </Button>
          )}
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AppBooking;
