import React, { useEffect, useState } from "react";
import {
  Modal,
  Row,
  Col,
  Checkbox,
  TimePicker,
  Button,
  Typography,
} from "antd";
import dayjs from "dayjs";

const { Text } = Typography;

interface OpeningHoursViewProps {
  visible: boolean;
  onClose: () => void;
  openingHours: any;
}

const OpeningHoursView: React.FC<OpeningHoursViewProps> = ({
  visible,
  onClose,
  openingHours,
}) => {
  const [tempHours, setTempHours] = useState<any>(openingHours);

  const handleCheckboxChange = (day: string, checked: boolean) => {
    setTempHours({
      ...tempHours,
      [day]: checked ? "09:00-18:00" : "closed-null",
    });
  };

  const handleTimeChange = (day: string, times: [dayjs.Dayjs, dayjs.Dayjs]) => {
    if (times && times[0] && times[1]) {
      setTempHours({
        ...tempHours,
        [day]: `${times[0].format("HH:mm")}-${times[1].format("HH:mm")}`,
      });
    }
  };

  useEffect(() => {
    setTempHours(openingHours); // Update tempHours when openingHours prop changes
  }, [openingHours]);

  return (
    <Modal
      title="Opening Hours"
      visible={visible}
      onCancel={onClose}
      footer={null}
      bodyStyle={{
        height: "calc(100vh - 340px)",
        overflowY: "auto",
        overflowX: "hidden",
        scrollbarWidth: "thin",
      }}
    >
      <Row
        style={{
          borderBottom: "1px solid #f0f0f0",
          paddingBottom: "8px",
          fontWeight: "bold",
          marginTop: "16px",
        }}
      >
        <Col span={6}>Week</Col>
        <Col span={9}>Opening time</Col>
        <Col span={9}>Closing time</Col>
      </Row>

      {tempHours &&
        Object.keys(tempHours).map((day) => (
          <Row key={day} align="middle" style={{ marginTop: "8px" }}>
            <Col span={6}>
              {tempHours[day] &&
                tempHours[day].dayname.charAt(0).toUpperCase() +
                  tempHours[day].dayname.slice(1)}
            </Col>
            <Col span={9}>
              {tempHours[day] && tempHours[day].starttime !== "closed" ? (
                <Text>{tempHours[day].starttime}</Text>
              ) : (
                <Text>Closed</Text>
              )}
            </Col>
            <Col span={9}>
              {tempHours[day] && tempHours[day].endtime !== "null" ? (
                <Text>{tempHours[day].endtime}</Text>
              ) : (
                <Text>-</Text>
              )}
            </Col>
          </Row>
        ))}
    </Modal>
  );
};

export default OpeningHoursView;
